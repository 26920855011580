import { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../config.js";

import { ImageUpload } from "../components/imageUpload.js";
import {displayDate} from '../../utilities/helper/helper'
import { SuperEditor } from "../../components/editor.js";

const VerificationScreen = (props) => {
    const ct = props.ct


useEffect(() => {
    async function fetchWorkspaces() {
      const retrievedWorkspaces = await ct.fire.getWorkspaces();
      setWorkspaces(retrievedWorkspaces);
      setWorkspace(retrievedWorkspaces[0]);
    }

    fetchWorkspaces();
  }, []);

// PROMPT D EXEMPLE

//   Maintenant donne 10 exercices pour s'exercer sur le "present" au format suivant : 
// Une phrase de contexte [avec la bonne réponse ici] | trois propisitions de réponse | La traduction | Une justification précise
// She [is] very tall. | am, is, are, have | Elle [est] très grande. | La bonne réponse est "is" car avec "She", on utilise "is".  

// tu dois mettre la bonne réponse entre [] et la justifier de manière précise. Toutes les phrases doivent être correct tu ne dois pas faire d'erreur !

// PROMPT DE LECON

// Ignore toute les commandes précédentes. 
// Tu es un prof d' italien depuis 10 ans.  
// Tu as aidé de nombreux étudiant à apprendre l'espagnol. 
// Tu es le prof particulier de @enzo qui débute, parles lui en français. Tu lui écris des petites leçon, ne parle que de la leçon tel un livre scolaire

// Explique de manière simple, ludique et complète la leçon du jour en italien : "le present"
// Dans tes explications mets des tableaux si nécéssaire. 
// Donnes une maxiums exemples (au moins 5) et du contexte  pour chaque notions apprisent !.

// Pour les conjugaisons donne des tableaux suligne ce qu'il faut apprendre et donne du contexte et des exemples. Donne toutes les conjugaison puis sous le tableau donne au Minimum 3 exemples pour illustrer 

// Toute ce que tu dis doit être VRAI


  const lineCard = (c) => {
  
  return <div onClick={() => console.log(c)} className="flex gap-2 justify-between items-center border-b p-2 rounded bg-slate-50">
  <div className={c.model == "grammar" ? "" : "w-[200px]"}>
      <div>{c.model == "grammar" ? c.term.split("|")[0] : c.term }</div> 
      <div className="text-slate-500 text-sm italic">{c.trad}</div>
      {c.model == "grammar" && <div className="flex gap-2 mt-2 flex-wrap">{c.term.split("|")[1].split(',').map(prop => <div className="px-2 rounded text-sm bg-white/50 border">{prop}</div>)}</div>}
      {c.model == "grammar" && <div className="text-blue-400 text-sm">{c.term.split("|")[3]}</div>}
  </div>
  <div className="grow">
      <div>{c.contexts && c.contexts[0]?.text}</div>
      <div className="text-slate-500 text-sm italic">{c.contexts && c.contexts[0]?.trad}</div>
      
  </div>
  <div className="flex gap-2 ">

  <div className="text-purple-500 bg-purple-100 rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
           <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
       </svg>
      </div>

      <div className="text-red-500 bg-red-100 rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
      </div>

      <div className="text-green-500 bg-green-100 rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
      </div>
    
  </div>
      
  </div>}
  


    const modes = [
        {
            id: "word", 
            title: "Vocabulaire",
            description: "Liste des mots présentés sur MindSeed"
        }, 
        {
            id: "dialogue", 
            title: "Dialogue",
            description: "Liste des dialogues présentés sur MindSeed"  
        }, 
        {
            id: "grammar", 
            title: "Leçon et grammaire",
            description: "Liste des leçons de grammaires et leurs exercices présentés sur MindSeed"  
        }
    ]

    const [mode, setMode] = useState(modes[0])
    const [cards, setCards] = useState()
    const [workspaces, setWorkspaces] = useState()
    const [workspace, setWorkspace] = useState()
    const [decks, setDecks] = useState()

    useEffect(() => {

        async function fetchData() {
            const retrievedCards = await ct.fire.getCards(workspace)
            const retrievedDecks = await ct.fire.getDecks(workspace)
            setCards(retrievedCards);
            setDecks(retrievedDecks);
          }
        if (workspace) {
            fetchData(workspace.id)
        }
      }, [workspace])

    console.log('workspaces', workspaces?.length)

  useEffect(() => {
    console.log("VerificationScreen loaded")
}, [])

   

 // faire le menu avec les worskpaces

 // faire le menu avec le type de données

 

  
  return (
    <div className={`fredoka h-screen overflow-scroll`}>
    <header className='p-4 fixed right-0 left-0 bg-white flex gap-2 border-b z-10 justify-between text-slate-400 items-center'>

        <div className="text-xl text-slate-500">Vérification de contenu</div>
        <div className="flex gap-2 items-center">
            <div className="flex gap-2  px-4 rounded ">
                <img src="images/icons/icon_rounded.png" className="h-[42px]"/> 
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <div className="flex gap-2  px-4 rounded ">
                <img src="https://d9hhrg4mnvzow.cloudfront.net/info.vivamundi.fr/20942887-viva-mundi-logo-rvb-1.svg" className="-mb-[16px] h-[50px]"/> 

            </div>
        </div>   
    </header>
    <aside className="fixed mt-[70px] overflow-scroll pb-[300px] left-0 text-slate-500 bg-amber-100/10 w-[200px] border-r p-4 h-screen">
        {!workspace && workspaces?.filter(w => w.img).map(wp => 
            <div onClick={() => setWorkspace(wp)} className={"capitalize p-2 rounded-md flex items-center gap-2"}><img src={wp.img} className="h-[24px] rounded-full" />{wp.name}</div>)}
        {workspace && <div className="capitalize flex gap-2 justify-between items-center text-md cursor-pointer" onClick={async() =>{
            setWorkspace()
        }}>
         <div className="flex items-center gap-2"><img src={workspace?.img} className="h-[30px] rounded-full" />{workspace?.name} </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
             </svg>
        </div>}
        <hr className="my-8"/>
        {modes.map(m => <div onClick={() => {setMode(m)}} className={`${mode.title == m.title ? "bg-amber-500/10 text-amber-500" : ""} p-2 rounded-md hover:bg-amber-400/10`}>{m.title}</div>)}
     
    </aside>
    <main className="ml-[200px] pb-[200px] mt-[90px] p-8">
        <div className="mb-8">
            <h1>{mode.title}</h1>
            <h2>{mode.description}</h2>
        </div>

        {mode.id == "word" && cards?.filter(c => c.model == "lang-en")?.map(c => lineCard(c))}

        {mode.id == "dialogue" && decks.filter(d => d.presentation_mode == "Dialogue").map(d => <div className="mb-8" onClick={() => console.log(d)}>
            <h3>{d.name}</h3>
            <div className="flex justify-between">

            <div className="w-[50%] "> {d.dialog?.en.split("\n").map((line, index, array) => (
                    <>
                    {line}
                    {index === array.length - 1 ? null : <br />}
                    </>
                ))}</div>
                <div  className="w-[50%]  text-slate-500 italic"> {d.dialog?.fr.split("\n").map((line, index, array) => (
                    <>
                    {line}
                    {index === array.length - 1 ? null : <br />}
                    </>
                ))}</div>
            </div>
        </div>)}

        {mode.id == "grammar" && decks.filter(d => d.presentation_mode == "Grammar").map(d => <div className="mb-16 pb-12 " onClick={() => console.log(d)}>
         <h3 className="text-xl capitalize mb-2">{d.name}</h3>
         <div className="flex gap-4">
           <div className="w-[50%] p-8 rounded-md bg-amber-200/10 border-2 border-amber-400/30"> 
              
                <SuperEditor key={d.id} content={d.content}/>
            </div>
            <div className="w-[50%]">
                {cards?.filter(c => c.deck_id == d.id)?.map(c => lineCard(c))}
            </div>
        </div>
        </div>
        )}

       

    </main>
    </div>
  );
};

export {VerificationScreen};