import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../config.js";

function compterJoursConsecutifs(jours) {
  const lastDate = jours[jours.length - 1]?.date;
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (lastDate < today) {return 0}
  let compteConsecutif = 1; // Commence à 1 car la liste n'est pas vide
  let maxConsecutif = 1; // Pour garder le compte maximum trouvé

  for (let i = 1; i < jours.length; i++) {
    // Vérifie si le jour actuel est consécutif au précédent
    if (jours[i].day === jours[i - 1].day + 1 && jours[i].xp > 6) {
      compteConsecutif++;
      // Met à jour le maximum si nécessaire
      maxConsecutif = Math.max(maxConsecutif, compteConsecutif);
    } else {
      // Réinitialise le compte si les jours ne sont pas consécutifs
      compteConsecutif = 1;
    }
  }

  return maxConsecutif;
}

function getDayOfWeek(timestamp) {
  const date = new Date(timestamp);
  const days = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
  return days[date.getDay()];
} 

const WeekSreak = (props) => {
  const ct = props.context
 
    const firstDate = ct.user?.updated?.toDate() || new Date()
    
    const xp_table_day = ct.userWorkspace?.xp_table?.map((exp) => ({...exp, 
      date: new Date(new Date(firstDate).setDate(firstDate.getDate() + exp.day)), 
      dayString: getDayOfWeek(new Date(new Date(firstDate).setDate(firstDate.getDate() + exp.day)))
    }))
    console.log('xp_table_day', xp_table_day)
    const streak = compterJoursConsecutifs(xp_table_day || [])
    useEffect(() => {
      console.log('streak2', streak)
      ct.setUserWorkspace(prev => ({...prev, streak }))
    },[streak])
   
    console.log('streak', streak)
    function getStartEndOfWeek(date) {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() ); // Lundi comme premier jour de la semaine
        startOfWeek.setHours(0, 0, 0, 0); // Début de la journée
    
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 7); // Ajouter 6 jours pour arriver à dimanche
        endOfWeek.setHours(23, 59, 59, 999); // Fin de la journée de dimanche
    
        return { startOfWeek, endOfWeek };
    }
    
    // Obtenir le début et la fin de la semaine actuelle
    const { startOfWeek, endOfWeek } = getStartEndOfWeek(new Date());
    // console.log('firstDate', firstDate)
    // console.log('xp_table_day', xp_table_day)
    const xp_table_day_filtered = xp_table_day?.filter(exp => {
      const expDate = new Date (firstDate)
      expDate.setDate(firstDate.getDate() + exp.day);
      // console.log('expDate', expDate)
      return expDate >= startOfWeek && expDate <= endOfWeek;
    });
    // console.log('xp_table_day_filtered', xp_table_day_filtered)
    const weeks = [{ short: 'lun'}, { short: 'mar'}, { short: 'mer'}, { short: 'jeu'}, { short: 'ven'}, { short: 'sam'}, { short: 'dim'}]

  

  return (
    <div onClick={() => {
      console.log('streak', streak)
      
    }} className={`px-4 py-2 -mt-6 relative pt-4 mb-6 text-white rounded-xl bg-black/20 w-full flex justify-between items-center  ${props.className}`}>
                
                    <div className='text-white text-sm  absolute -top-2 -left-2 gap-2 rounded-full px-3  pl-2 flex bg-[#aa4aff] items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
                    </svg>


                    <div> Mon combo</div>
                    </div>
                    <div className='  rounded-xl  w-full flex justify-between '>
                    {weeks.map((w) => <div>
                        <div>{w.short}</div>
                        <div className='text-black/30'>{xp_table_day_filtered?.find(exp => exp.dayString == w.short)?.xp > 12 ? 
                        <div>
                            <img src="images/deco/red_flamme.svg" className='h-[24px]'/>
                            <div className='text-white/40 text-xs mt-[2px]'><span className='text-amber-100 text-xs '>{xp_table_day_filtered?.find(exp => exp.dayString == w.short)?.xp}</span> xp</div>
                        </div> : xp_table_day_filtered?.find(exp => exp.dayString == w.short)?.xp > 0 ? 
                        <div>
                          <img src="images/deco/red_flamme.svg" className='h-[24px] grayscale opacity-[0.5]'/>
                        <div className='text-white/40 text-xs'><span className='text-amber-100 text-sm'>{xp_table_day_filtered?.find(exp => exp.dayString == w.short)?.xp}</span> xp</div></div> : ""}</div> 
                        </div>)}
                    </div>
                </div>
  );
};

export {WeekSreak, getDayOfWeek};

