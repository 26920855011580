import { doc, updateDoc } from "@firebase/firestore"
import { db } from "../../../config"
import { fire } from "../../🔥db"



const BlockedScreen = ({user, classes, userWorkspace, setUserWorkspace, setIsBlocked, setUser}) => {

    return  <div className='flex flex-col gap-4 items-center fredoka p-4 text-center justify-center fixed z-[50] bg-amber-50 top-0 left-0 right-0 bottom-0'>
              <img src="images/UX/tuto/cool_seed.png" className='h-[200px]'/>
              <h3>Hello {user?.name},</h3>
              <p>Pour accèder à ce cours entre ton code de classe</p>
              <input 
              onBlur={() => console.log('classes', classes)}
              onChange={(e) => {
              
                  if(classes.find(c => c.code.toLowerCase() == e.currentTarget.value.toLowerCase())) {
                      const currentClasseId = classes.find(c => c.code.toLowerCase() == e.currentTarget.value.toLowerCase()).id
                      const userClasses = [...user.class || [], currentClasseId]
                      const _userWorkspace = {...userWorkspace, classes: userClasses}
                      const _newUser = {...user, blocked: false, class: userClasses, last_workspace_id: user.last_workspace_id || null}
                      console.log('log1')
                      updateDoc(doc(db, 'users', user.id), _newUser);
                      console.log('log2')
                      setUser(_newUser)
                      console.log('log3')
                      setUserWorkspace(_userWorkspace)
                      console.log('log4')
                      fire.updateDb('user_workspace', {...userWorkspace, classes: userClasses})
                      console.log('log5')
                      setIsBlocked(false)
                      console.log('log6')
                  }
              }} placeholder='_____' className='bg-transparent text-center text-2xl border-2 px-2 py-1 mb-4 bg-white rounded-xl'/>
              {/* <p>Pas de code ?</p>
              <p onClick={() => {fire.updateUser({...user, asked_acces: true}); alert('Ta demande a bien été envoyée ! 🤌')}} className='px-4 py-2 rounded-xl  text-lg text-purple-500 '>Envoyer une demande d'accès</p> */}
              </div>
  }
  
  
  
export {BlockedScreen}  