import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, getDocs, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { db, app } from "../../config.js";

const ImageUpload = (props) => {
const [file, setFile] = useState(null);
const [imageURL, setImageURL] = useState("");
const [images, setImages] = useState([])
const [isCopied, setIsCopied] = useState(false);
const [imageURLocal, setImageURLocal] = useState(null);
const storage = getStorage(app);


const handleCopyToClipboard = () => {
  // navigator.clipboard.writeText(imageURL)
  //   .then(() => {
  //     setIsCopied(true);
  //     setTimeout(() => {
  //       setIsCopied(false);
  //     }, 1500);
  //   })
  //   .catch((error) => {
  //     console.error("Erreur lors de la copie dans le presse-papier :", error);
  //   });
};

const getImages = async () => {
  console.log('GET IMAGES for', props?.user?.id)
  const imgs = await getDocs(collection(db, "upload"), where('user_id', '==', props?.user?.id));
  console.log('images', imgs.docs.map(doc => doc.data()).filter(img => img.user_id == props.user.id))
  setImages(imgs.docs.map(doc => doc.data()).filter(img => img.user_id == props.user.id))
}

useEffect(() => {
  props?.user?.id && getImages()
}, [])

const handleFileChange = (event) => {
  const selectedFile = event.target.files[0];
  const objectURL = URL.createObjectURL(selectedFile);
  setImageURLocal(objectURL);
  setFile(selectedFile);
};

const handleUpload = () => {
  console.log('file', file)
  if (file) {
    console.log('storage', storage)
    const storageRef = ref(storage, `images/${file.name}`);
    const mountainsRef = ref(storage, 'mountains.jpg');

    console.log('mountainsRef', mountainsRef)

    uploadBytes(storageRef, file)
      .then((snapshot) => {
        console.log("Uploaded a blob or file!");
        // Récupérer l'URL de l'image uploadée
        console.log('snapshot', snapshot)
        return snapshot.ref.fullPath;
      })
      .then((downloadURL) => {
        // https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/images%2Fnet.png?alt=media
       
        const dataUrl = "https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/" + downloadURL.replace('images/', 'images%2F') + "?alt=media"
        console.log("Image URL:", downloadURL);
        setImageURL(dataUrl);
        const newImage = {
          url: dataUrl,
          user_id: props.user.id,
          type: "image",
          size: file.size,
          date: new Date()
        }
        setImages([...images, newImage])
        return addDoc(collection(db, "upload"), newImage);
      
      })
      .catch((error) => {
        console.error("Une erreur est survenue lors de l'upload de l'image :", error);
      });
  }
};

return (
<div className="w-full relative rounded-xl">
  {props.user?.id}
{props.user ? <div>
  
<div className='bg-indigo-100 border-2 border-dashed border-indigo-300 text-indigo-500  b cursor-pointer rounded-xl relative p-8'>
{!file ? "Ajouter une image" : "Changer d'image"}
{imageURLocal && <img src={imageURLocal} className='h-20 '/> }
<input type="file" onChange={handleFileChange} className='z-0 bg-purple-500 p-8 opacity-0 rounded-xl cursor-pointer  absolute top-0 left-0 right-0 bottom-0 '/>
{file && <button className={`z-10 bg-white text-purple-500 rounded inline-block mt-2  bottom-2 b text-50 px-4 py-1`} onClick={handleUpload}>Uploader l'image</button>}
</div>


{imageURL && <div className='p-4 rounded-xl mt-2  bg-green-100 flex gap-4'>
  <img src={imageURL} alt="Uploaded" className='h-12 ' />
  <div>
  <p>L'image a bien été uploadée ✅</p>
  <p onClick={handleCopyToClipboard} className='text-xs text-slate-400'>{imageURL}</p>
  </div>
  </div> 
}
<div className='grid grid-cols-3 gap-4 mt-4'>
  {images.map((img) => 
  <div className='p-4 rounded-xl border'>
    <img src={img.url} className="h-40 w-40"/>
    <p onClick={handleCopyToClipboard} className='p-2 rounded  italic text-[8px] text-slate-400'>{imageURL}</p>
  </div>)}
</div>
</div>

: <div>Vous devez être connecté pour uploader une image</div>
}

</div>
);
};

export { ImageUpload };