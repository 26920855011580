import { useEffect, useState } from "react"
import { speak, stopSound } from "../../helperFunctions"


const  AudioBtn = ({card, lang, speakText, className, color, autoSpeak}) => {
    const [audioPlaying, setAudioPlaying] = useState(false)

    const handleSpeak = async() => {
        stopSound()
        setAudioPlaying(true)
        await speak({text: speakText|| card.context, lang: lang})
        setAudioPlaying(false)
    }

    useEffect(() => {
        autoSpeak && handleSpeak()
    }, [card, autoSpeak])



    return <div className={`${className} mt-[10px] border-2 border-b-[6px] mb-4 border-[#2e084ce3] items-center h-[100px] w-[100px] mx-auto bg-center bg-cover text-slate-500 rounded-2xl relative`} style={{backgroundImage: `url('${card.img}')`}}>
    <div onClick={handleSpeak} className={`bg-gradient-to-b ${color} from-indigo-600/70 to-[#4a0382e6] flex items-center justify-center  p-2 px-4 h-full  bottom-0 left-0 right-0 rounded-xl`}>
       
        <div className="flex my-2 items-center justify-center">
            {audioPlaying ? <l-waveform
            
    size="32"
    speed="1.6" 
    color="white" 
  ></l-waveform> :<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-12 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
            </svg>}
        {/* <img src="/images/icons/sound_long.svg"/> */}
        </div>
        

    </div>
    </div>

}

export {AudioBtn}