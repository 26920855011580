import { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";

const ConcoursScreen = (props) => {
    const navigate = useNavigate()
    const menu = props.menu
  const ct = props.context
  useEffect(() => {
    console.log("ConcoursScreen loaded")
    ct.setShowBottomBar(true)
   
    console.clear()
    console.log('ct', ct.userWorkspace)
}, [])




    return <div className="px-4 rounded-xl mb-2 mt-12 ">
           <div className={`p-4 mt-4 rounded-xl bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">Coming Soon</div>
                  <div className="text-sm">La préparation au TOIEC est en cours de développement et sera bientôt disponible</div>
                </div>
           </div>
           {/* <div className="p-6 mb-2 mt-12 font-bold rounded-xl bg-gradient-to-r border-2 border-b-[5px] border-slate-900 from-green-500 to-cyan-600">
                <div onClick={() => {ct.setSelectedQuiz({id: "bJH5bdJ0rxCO1Y3NRMpC"})}} className="text-xl text-shadow">Faire un entrainement</div>
              
              </div>
              <div className="p-6 mb-6  font-bold  rounded-xl bg-gradient-to-r border-2 border-b-[5px] border-slate-900 from-purple-500 to-indigo-500">
                <div className="text-xl text-shadow">Faire un test blanc</div>
              </div>
   */}
      </div>

};

export {ConcoursScreen};