import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppV2 from './V2/🧠App';
import AppV3 from './V3/App';
import {ErrorBoundary, ErrorBoundaryWrapper} from './components/ErrorBoundary';
import { ContextProvider } from './V2/context';
import { StateProvider } from './V3/StateProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const searchParams = new URLSearchParams(window.location.href);
const version = searchParams.get("v")
const clientId = "1033205216984-kq2o5bdl3onf4uj2tf0v4p40q2k7511l.apps.googleusercontent.com"
let currentVersion = localStorage.getItem('new_version') || 'classic';

try {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        console.log('loading page');

        // Récupérer tous les service workers enregistrés
        const registrations = await navigator.serviceWorker.getRegistrations();

        // Désinscrire les anciens service workers
        if (registrations.length > 0) {
          await Promise.all(
            registrations.map(registration => registration.unregister())
          );

          // Nettoyer tous les caches
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map(cacheName => caches.delete(cacheName))
          );
        }

        // Enregistrer le nouveau service worker
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker registered successfully:', registration);

        // Mettre à jour le service worker si nécessaire
        if (registration.active) {
          await registration.update();
          console.log('Service Worker updated');
        }

      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    });
  } else {
    console.log('Service Workers are not supported');
  }
} catch (error) {
  console.error('Service Worker error:', error);
}

if (currentVersion !== "v2") {

  root.render(
    <ErrorBoundary>
      <ContextProvider>
        <AppV2 />
      </ContextProvider>
    </ErrorBoundary>,

  );
} else {
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <StateProvider> 
          <ErrorBoundaryWrapper> 
            <AppV3 />
          </ErrorBoundaryWrapper>
        </StateProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
