import { useState, useEffect } from "react";
import { collection, addDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { coachs, notificationChill, notificationNRV } from "../data/notification.js";
import { useNavigate } from "react-router-dom";
const functions = getFunctions(app);


const NotificationScreen = (props) => {
  const ct = props.context
  const navigate = useNavigate()

  const [selectedCoach, setSelectedCoach] = useState(ct.user?.coach)
  useEffect(() => {
    console.log("NotificationScreen loaded")
    // console.clear()
    console.log('user',ct.user)
    ct.setShowBottomBar(false)
}, [])

const svg = (color, scale) =>  <svg className={` ${scale} h-[100px] transition delay-75	 `} width="450" height="500" viewBox="0 0 163 171" fill={color} xmlns="http://www.w3.org/2000/svg">
<path d="M12.4595 38.224C39.4684 2.83298 112.491 -15.391 139.5 20C160.112 47.009 172.112 101.991 151.5 129C124.491 164.391 48.0089 187.891 21 152.5C0.387888 125.491 -8.15263 65.233 12.4595 38.224Z" fill={color} />
</svg>


function replaceKeywords(template, replacements) {
  return template.replace(/@(\w+)/g, (match, key) => replacements[key] || match);
}

const messaging = getMessaging(app);      // marche pas en local sur android
const vapidKey = "BCfw1h2IwNt4cj-YKrQtKrnZ8WatJwEvNCPoJXrmf-UmRWJfVvbVL7oiIrlOKow2tZx-TetGaoE54NM4bZzudOA"




const handleNotification = async () => {
  const info = document.getElementById('info');
  let _token = "";

  // Demander la permission pour les iPhones
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
    window.webkit.messageHandlers["push-permission-request"].postMessage(null);
  }

  if (window.myFcmToken) {
    _token = window.myFcmToken; // Récupération du token iPhone
  } else { // Pour Android
    info.innerHTML += 'ANDROID // <br/> ';
    const permission = await Notification?.requestPermission();

    if (permission === 'granted') {
      info.innerHTML += 'GRANTED // <br/> ';
      const newSw = await navigator.serviceWorker.register('service-worker.js');
      try {
        _token = await getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: newSw });
        info.innerHTML += 'GET TOKEN // <br/> ' + _token;
      } catch (error) {
        console.error('Unable to get token', error);
      }
    } else {
      console.log('Permission not granted for Notifications');
    }
  }
  console.log('token', _token);
  info.innerHTML += 'TOKEN == ' + _token;
  const userNew = { ...ct.user, fcmToken: _token, coach: selectedCoach}
  console.log('user NEW NEW', userNew)
  fire.updateUser(userNew, ct)

  fire.addNotifications(ct, coachs[selectedCoach], userNew)
  navigate("/home")
  ct.setAlerts([{title: "Bon choix ! Tu ne seras pas déçu" , content: "", time: 2600, icon: <img src={coachs[selectedCoach]?.img_round} className="h-[80px]" />}])
};




  
  return (
    <div className="w-full bg-gradient-to-b z-[120] fixed from-[#5422ce] to-[#391556] text-white max-h-[100vh] min-h-[100vh] fredoka pb-[200px] overflow-scroll relative p-6">
      <div className="max-w-[600px] mx-auto">
      <div onClick={() => setSelectedCoach(null)} className="font-bold text-center text-2xl px-2 py-1 rounded my-4 hover:scale-110">Choisis ton coach</div>
      <p className="text-center text-[16px] -mx-2">C’est lui qui sera chargé de te motiver dans ton apprentissage en t’envoyant des <span className="text-pink-300 font-semibold bg-black/10">notifications</span></p>
      <div className="grid grid-cols-2 mt-24 mb-20">
      {coachs.map(coach => <div className=" ">

        <div className="flex justify-center">
        <div onClick={() => setSelectedCoach(coach.id)}  className="hover:scale-110 relative ">
          
        <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}} className="absolute top-0 right-0  left-0  bottom-0 flex items-center justify-center">
         {svg(selectedCoach == coach.id ? coachs[selectedCoach]?.fill : "#291155"  , selectedCoach != coach.id ? "scale-[1.4]" : "scale-[2.2]")}
        </div>
        
        <img className={` ${selectedCoach == coach.id ? "scale-[2]" : "grayscale	"} transition  h-[110px] `} src={coach.img} />
       
        </div>
        </div>
        </div>)}
        </div>
      {selectedCoach != null ? <>
      <div className={`text-2xl font-semibold ${coachs[selectedCoach]?.color}`}>{coachs[selectedCoach]?.name}, <span className={`text-xl font-semibold ${coachs[selectedCoach]?.color}`}>{coachs[selectedCoach]?.sub}</span>
</div>

      <div className="">{coachs[selectedCoach]?.description}</div>
      <div className=" py-1 mt-3 px-2 border-l-2   border-white/40 ">
        <div className="text-sm  text-white/70">Exemple de message</div>
        <div className={`${coachs[selectedCoach]?.color} text-white `}>“{replaceKeywords(coachs[selectedCoach]?.exemple, {name: ct.user?.name, lang: ct.workspace?.name})}“</div>
      </div></> : <div className="text-center">
      <div className="mb-4 -mt-12 text-white/50 italic">Appuie sur un coach pour en savoir plus </div>
        La clé de l'apprentissage c'est la <u>répétition</u>. <br className="mb-2 block"/>
        Choisis celui qui te motivera pour atteindre ton objectif
      
        </div>}

        {/* {coachs[selectedCoach]?.notifications?.remind1.map(notif => <div  className="px-2 bg-white/20 rounded py-1 mt-2">{ replaceKeywords(notif, {name: ct.user?.name, lang: ct.workspace?.name})}</div>)} */}

     

      {/* <div onClick={() => fire.addNotifications(ct)} className="py-1 px-2 rounded border-2">Créer les 3 prochaines notifications</div> */}
        {/* seed: {ct.user.remind1_i} : {ct.user.remind2_i} : {ct.user.lastRemind_i} */}

      <div className="fixed bottom-[56px] z-[120] left-0 right-0 flex items-center justify-center">
      {selectedCoach != null ? <div onClick={handleNotification} className="bg-purple-500 border border-b-4 border-black px-4 py-2 rounded-full text-white text-xl hover:scale-110">Choisir {coachs[selectedCoach]?.name} → </div> : <div></div>}
      </div>


    <div className="hidden">
        <div id="info"></div>
        <div>mail: {ct.user?.email}</div>
        <div>User_id: {ct.user?.id}</div>
        <div>fmc : {ct.user?.fcmToken}</div>

        <div onClick={() => fire.getNotifications(ct)} className="rounded p-4">
        <div>Prochaines notifications prévues</div>
        {ct.notifications?.map(notif => <div className={`${notif.sended ? "text-green-500" : ""}`}>{notif.content}</div>)}
      </div>
    </div>
    </div>
    </div>
  );
};

export {NotificationScreen};