import { getTextBetweenBrackets } from "../../helperFunctions";

const HighlightText = ({brackedSentence, className, classNameText}) => {

    const parts = brackedSentence?.split(/\[(.*?)\]/);
    const text = getTextBetweenBrackets(brackedSentence)
  
  
    return (<div className={className}>
     
            {parts?.map((part, index) => {
                if (index % 2 === 1) {
                    return <span className={classNameText}>{part}</span>;
                }
                return <span key={index}>{part}</span>;
            })}
        
        
        </div>
        
    );
  };

  export {HighlightText}