import { useEffect, useState } from "react"
import { useStateContext } from "../StateProvider"
import { capitalizeFirstLetter, getCustomDayOfWeek, getWeekNumberAndYear } from "../helperFunctions";
import { formatDate } from "../../V2/components/learn/learn";
import { Link } from "react-router-dom/dist";
import { supabase } from "../../V2/screens/testScreen";


const UserLine = ({user, i, ct, yesterdayJM, todayJM, me}) => {

    const [extended, setExtended] = useState()
    const [userWorkspaces, setUserWorkspaces] = useState()
  
  

  
    return <div>
  
      <div className={`text-white fredoka py-2 border-b  border-white/20 px-4 ${user?.id == me?.id && "bg-indigo-400/20"} `}>
      <div onClick={() => {
        setExtended(e => !e)
        // getUserWorkspaces(user, setUserWorkspaces)
        }} >
          <div className="flex gap-4 items-center">
            <div className={`${i > 19 && (me?.lvl_lig > 1) && "text-red-300"} ${i < 10 && "text-green-300"} w-[20px]`}>{i + 1}</div>
            {user.photo ? 
              <img src={user.photo} className='h-[36px] w-[36px] rounded-full object-cover'/> 
            : <div className="h-[36px] w-[36px] rounded-full font-bold flex items-center justify-center bg-blue-500" style={{backgroundColor: user?.color}}>{user?.name?.[0]?.toUpperCase()}</div> }
             <div className="flex grow justify-between">
              <div className={`font-semibold ${user?.id == ct?.user?.id ? "text-amber-400" : ""}`}>
                {user?.name?.split(' ')?.[0]} {user?.name?.split(' ')?.[1]?.[0]} 
                {user?.streak && (user?.lds == yesterdayJM || user?.lds == todayJM) ? <span className={`${user?.lds != todayJM && "opacity-50"} text-amber-500 text-sm ml-2`}>{user?.streak} 🔥</span> : ""}
                <div className="text-xs font-[500] -ml-2">  
                  {(new Date()-user.last_connexion)/1000 < 60*5 ? <div className="flex "><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden  text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-green-500 block h-2 w-2 rounded-full"></span >En ligne</div></div> : 
                  (new Date()-user.last_connexion)/1000 < 60*180 ? <div className="flex "><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden  text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-amber-500 block h-2 w-2 rounded-full"></span >En ligne récemment</div></div> : ""}
                </div>
              </div>
              <div className="flex gap-2">
                <div className="text-amber-400 flex gap-1 items-center">+ {user?.week_table?.[`${todayWeek}`]?.xp || 0} <img src={"/images/icons/coin-xp.png"} className="h-4"/></div>
                {user?.id ? <div> 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${extended ? "rotate-[90deg]" : ""}`}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                  </div> : <div className="w-6"></div>}
              </div>
              </div>
            </div>
          </div>
          {extended && <div className="p-2">
            <div>{user?.id == me?.id ? <div>
              <div className="flex ml-[28px]" ><Link to={"/me"} className="py-2 mt-2 block bg-purple-500 border-2 border-b-4  border-black/90 px-4 rounded-xl">Modifier mon profil</Link></div>
            </div> : userWorkspaces
            ?.filter(uwp => uwp.total_xp  > 5)
            .sort((a, b) => b.total_xp - a.total_xp)
            .map(uwp => {
              const wp = ct.workspaces.find(wp => wp.id == uwp.workspace_id)
              return <Link to={`/forest?&uwp=${uwp.id}`} className="rounded-xl text-sm mt-1 p-2 relative flex gap-2 items-center bg-black/10 border-black/70 border-2 border-b-4 ">
              <img className="h-5" src={wp.img}/>{capitalizeFirstLetter(wp.name)}
                <div className="absolute py-1 bg-black/50  rounded-xl px-2 items-center right-1 flex gap-1">
                  <div>visiter</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5  h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
                <div className="grow text-right text-amber-300 mr-[90px] text-sm">{uwp.total_xp} <img src={"/images/icons/coin-xp.png"} className="h-5"/> </div>
              </Link>
              
              })}</div>
              {ct.user.admin && <div className="flex gap-1 mt-2">
                <div className="px-2 bg-black/10 rounded-xl border-black/70 border-2 border-b-4 rounded-full bg-white/10 " onClick={() => {
                // ct.setUser(user)
              }}>Impersonnate</div>
              <div onClick={async() => {
                //  const ref = doc(db, 'users',user.id);
                //  await updateDoc(ref, {beta: !user.beta})
                //  console.log('updated', user.id)
              }} className={`px-2 bg-black/10 rounded-xl border-black/70 border-2 border-b-4 ${user.beta ? "" : ""}`} >Beta</div>
              </div>}
  
            </div>}
    </div>
   
    {i == 9 && (me.lig_lvl < 2) && <div  className="flex justify-center bg-green-500/20 items-center border-b border-white/20 ">
      <div className="text-green-500 flex justify-center py-1 items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
          </svg>
          Zone de promotion <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
          </svg>
      </div>
  </div>}
    {i == 19 && (me.lig_lvl > 1) && <div className="flex justify-center items-center border-b bg-red-500/10   border-white/20 ">
    <div className="text-red-200 flex justify-center py-1 items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 rotate-[180deg]">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
          </svg>
          Zone de relégation 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 rotate-[180deg]">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
          </svg>
      </div>
      </div>}
  
    </div>
  }

const leagues = [
    { name: "Ligue de Bronze", img: "/images/UX/lig_1.png"},
    {name: "Ligue d'argent", img: "/images/UX/lig_2.png"},
    {name: "Ligue d'Or", img: "/images/UX/lig_3.png"}
  ]


  const today = new Date();
  const todayWeek = getWeekNumberAndYear(today);
  const todayDay = getCustomDayOfWeek(today);
  const lastWeekData = new Date(today);
  lastWeekData.setDate(today.getDate() - 7);
  const lastWeek = getWeekNumberAndYear(lastWeekData);

  const bots = [
    {name: ["Stef"],      id: "QeDXwmcsJGfTqCOVCuAl", streak: 0, xp_weeks: [12, 4, 12, 20, 4, 6, 10]},
    {name: ["Tanguy"],    id: "Blng6c7W1Kn3uWUPxTiG", streak: 0, xp_weeks: [12, 20, 12, 20, 12, 20, 12]},
    {name: ["Robin"],     id: "FGtyo7h44yceJGRYlbET", streak: 4, xp_weeks: [60, 40, 56, 60, 43, 46, 50]},
    {name: ["Cindy"],     id: "sZ38WYY7ioLVjiO6jBJD", streak: 2, xp_weeks: [12, 4, 20, 12, 20, 12, 12]},
    {name: ["Jason"],     id: "4NHoaMeS5Fv61ZSb7wPp", streak: 0, xp_weeks: [12, 20, 12, 33, 16, 12, 12]},
    {name: ["darkS"],     id: "XgyNzrk7C7QgmZoxAYo0", streak: 0, xp_weeks: [12, 12, 33, 40, 16, 12, 12]},
    {name: ["Minimator"], id: "Yr66TH24zKQmixyKbYiN", streak: 0, xp_weeks: [20, 12, 4, 20, 12, 33, 12]},
    {name: ["Tim L"],       id: "BnmfyFH8NH3ybNemuAav", streak: 0, xp_weeks: [10, 20, 12, 33, 6, 33, 12]},
    {name: ["Julien"],    id: "CV1E5AUOPf5QGXhaNOGg", streak: 1, xp_weeks: [12, 12, 20, 40, 33, 16, 12]},
    {name: ["CHRISTIAN"], id: "zL23EW3c92Tk7igzqAeH", streak: 0, xp_weeks: [17, 12, 6, 33, 12, 6, 12]},
    {name: ["Jule K"],      id: "7yt1ATrLTSr1cgcjmjw9", streak: 0, xp_weeks: [16, 20, 16, 12, 12, 12, 12]},
    {name: ["JP"],        id: "jc2b0HhcJebhDSg6BVe7", streak: 2, xp_weeks: [18, 4, 6, 20, 40, 12, 12]},
    {name: ["Maelle"],    id: "0wbF7lh1ulmMij3enVC0", streak: 0, xp_weeks: [19, 20, 12, 12, 6, 12, 12]},
    {name: ["Tbs"],       id: "RqXFTO4qVVOihb9cqQy5", streak: 4, xp_weeks: [12, 12, 16, 4, 12, 16, 12]},
    {name: ["Didi"],      id: "3XiyW5VkaThsDccjODv6", streak: 0, xp_weeks: [14, 6, 20, 12, 33, 40, 12]},
    {name: ["Marie P"],     id: "Jz51jRmV5UkxEpW6ZQwz", streak: 4, xp_weeks: [20, 12, 33, 16, 40, 12, 12]},
    {name: ["Manon"],     id: "5cXL5eM0bMj6nIEXR6wc", streak: 0, xp_weeks: [34, 4, 16, 6, 40, 12, 12]},
    {name: ["Charle D"],    id: "apIPcWnFga5un0GYIL8C", streak: 2, xp_weeks: [40, 12, 20, 12, 4, 33, 12]},
    {name: ["Noé"],       id: "fsuigWzm9cW181mhZwSi", streak: 1, xp_weeks: [22, 16, 12, 20, 40, 12, 12]},
    {name: ["Louis"],     id: "SeR0Ey1PEasZACLizTHm", streak: 0, xp_weeks: [25, 4, 33, 12, 6, 40, 12]},
    {name: ["Justine"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 16, 20, 12, 12, 33, 12]},
    {name: ["Juju"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 16, 20, 12, 12, 33, 12]},
    {name: ["Justine"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [10, 20, 70, 10, 20, 70, 30]},
    {name: ["Mattéo L"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [30, 30, 30, 30, 30, 30, 30]},
    {name: ["Lucien M"],   id: "AFWascK4slntdKHMelsS", streak: 0, xp_weeks: [10, 50, 10, 50, 10, 50, 10]},
  ].map(bot => (
    {...bot,
      name: bot.name[0],
      week_table: {
        [`${todayWeek}`]: {xp: bot.xp_weeks.slice(0, todayDay + 1).reduce((sum, num) => sum + num, 0) },
        [`${lastWeek}`]: {xp: bot.xp_weeks.reduce((sum, num) => sum + num, 0) }
      }
  }))



const getPlayers = async (lig_week, lig_lvl, lig_nb, set = () => {}) => {
  const { data, error } = await supabase
          .from('profiles')
          .select('*', { count: 'exact' })
          .contains('week_table', {
            [`${lig_week}`]: {
              'lig_lvl': lig_lvl,
              'lig_nb': lig_nb,
            }
          });
      
        if (error) {
          console.error('Erreur:', error);
          return null
        } else {
          console.log('Nombre de profils trouvés:', data);
          set(data)
          return data
          
        }
}

// récupérer les profils avec le même lig_lvl et lig_nb que moi cette semaine
const getCount = async(week, lig_lvl) => {
  const { count, error } = await supabase
      .from('profiles')
      .select('*', { count: 'exact', head: true })
      .contains('week_table', {
          [`${week}`]: {
              'lig_lvl': lig_lvl
          }
      });

  if (error) {
      console.error('Erreur:', error);
      return null;
  } else {
      console.log('Nombre de profils trouvés:', count);
      return count;
  }
}


const getLigLvl = async (currentWeek, players, profile) => {
  console.log('vérification de mon classement')
  const today = new Date();
  const todayJM = formatDate(today)
  const updatedBots = bots.map(b => ({...b, lds: todayJM}))
  const playersOfTheWeek = (players||[])
  .map(e => ({...e, 
    lig_lvl: e?.week_table?.[`${currentWeek}`]?.lig_lvl || 1,
  }))
  .concat(updatedBots?.slice(0, 30 - (players?.length || 0)))
  .sort((a, b) => (b?.week_table?.[`${currentWeek}`]?.xp || 0) - (a?.week_table?.[`${currentWeek}`]?.xp ||0))
  

  let lig_niv = profile?.week_table?.[currentWeek]?.lig_lvl || 1

  console.log('playersOfTheWeek', playersOfTheWeek)

  playersOfTheWeek.forEach((player, index) => {
    let niv = player.lig_lvl || 1
    if (index <= 10 )
    {
      niv ++
      if (niv > 2) niv = 3
    }
    if (index >= 20) {
      niv --
      if (niv < 1) niv = 1
    }
    if (player.id == profile.id) {
      console.log('update de mon niveau', niv)
      lig_niv = niv
    } 
  })

  console.log('lig_niv', lig_niv)
  return lig_niv
}


const updateLeague = async (currentWeek, lastWeek, profile, setProfile) => {
  console.log('updateLeague')

  if (profile?.week_table?.[currentWeek]?.lig_lvl) {
    console.log('déjà dans la ligue de la semaine !')
    return
  }
  else {
    console.log('pas de league cette semaine !')
    // on regarde le classement de la semaine dernière 
    let lig_lvl = 1

    if (profile?.week_table?.[lastWeek]?.lig_lvl) {
      console.log('déjà dans une ligue la semaine dernière')
      const lastWeekPlayers = await getPlayers(lastWeek, profile?.week_table?.[lastWeek]?.lig_lvl, profile?.week_table?.[lastWeek]?.lig_nb)
      console.log('lastWeekPlayers', lastWeekPlayers)
      lig_lvl = await getLigLvl(lastWeek, lastWeekPlayers, profile)
      console.log('lig_lvl updated par rapport à lastweek', lig_lvl)
    }
    else {
      console.log('pas de league la semaine dernière !')
    }

    const count = await getCount(currentWeek, lig_lvl)
    const tranche = Math.floor((count || 0) / 20) || 0

    const weekData = {...profile?.week_table?.[currentWeek]}


    const { data, error } = await supabase
    .rpc('update_week_xp', {
      profile_id: profile.id,
      new_tt_xp: profile.tt_xp || 0, 
      week_key: currentWeek,
      week_data: {...weekData, lig_lvl: lig_lvl, lig_nb: tranche}
    })

    setProfile(prev=> ({...prev, week_table: {...prev.week_table, [currentWeek]: {...prev?.week_table?.[currentWeek] || [], lig_lvl: lig_lvl, lig_nb: tranche}}}))


  }

}

const League = () => {
    const { user, setUser, profile, setProfile, setActiveScreen } = useStateContext()

    useEffect(() => {
        setActiveScreen("league")
        return () => { setActiveScreen("")}
    }, [])

    const today = new Date();
    const todayJM = formatDate(today)

    const colors = ["#577CFF", "#8357FF", "#D357FF", "#FF57A8", "#57D7FF"]
    function letterToNumber(letter) {
        return letter.toUpperCase().charCodeAt(0) - 64;
      }


  

    useEffect(() => {
      profile && updateLeague(currentWeek, lastWeek, profile, setProfile)
    }, [profile])

    useEffect(() => {
      
      getPlayers(lastWeek, profile?.week_table?.[lastWeek]?.lig_lvl, profile?.week_table?.[lastWeek]?.lig_nb, setReelPlayers)
    }, [profile])

    const [reelPlayers, setReelPlayers] = useState()

    const updatedBots = bots.map(b => ({...b, lds: todayJM}))
    const players = (reelPlayers||[])
    .concat(updatedBots?.slice(0, 30 - (reelPlayers?.length || 0)))
    .sort((a, b) => (b?.week_table?.[`${todayWeek}`]?.xp || 0) - (a?.week_table?.[`${todayWeek}`]?.xp ||0))
    .map(e => ({...e, color: colors[(letterToNumber(e.name?.[0] || "a")%colors?.length||0)]}))

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const yesterdayJM = formatDate(yesterday)

    const lastWeekData = new Date(today);
    lastWeekData.setDate(today.getDate() - 7);
    const currentWeek = getWeekNumberAndYear(today);
    const lastWeek = getWeekNumberAndYear(lastWeekData);


    const weekData = {...profile?.week_table?.[currentWeek]}

    return  <div className="min-h-screen text-white " style={{background: "linear-gradient(180deg, #353791 0%, #36206C 100%)"}}>

        <div className="h-[180px] text-center pt-[30px] pattern-triangle bg-yellow-200 "  
            style={{
                backgroundImage: "url('/images/seeds/bottom-store.png')",
                backgroundRepeat: "repeat-x",
                backgroundSize: "300px",
                backgroundPositionY: "100%"}}>
                              <div className="text-sm  text-slate-500/90 font-[400] mb-2 -mt-4">{leagues[(profile?.week_table?.[currentWeek]?.lig_lvl - 1) || 0].name}</div>

            <div className="flex items-center justify-center gap-2">

            {leagues.map((e,i) => <img src={e.img} className={` ${leagues[(profile?.week_table?.[currentWeek]?.lig_lvl - 1) || 0].name == e.name  ? "h-[80px]" : "h-[60px] opacity-[0.5]" }`}/>)}
        </div>
        <div className="m-4 mb-[70px]"></div>

  
        <div style={{maxHeight: 'calc(100vh - 270px)'}} className="h-screen  overflow-scroll">

        {profile && players?.map((u,i) => <UserLine i={i} todayJM={todayJM} me={profile} yesterdayJM={yesterdayJM} user={u} />)}
        </div>

        </div>
    </div>
}

export {League}