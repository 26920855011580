

import React, { useState, useEffect } from 'react';
import { supabase } from '../screens/testScreen';

function TodoTest() {
    const [todos, setTodos] = useState([]);
    const [newTask, setNewTask] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [themes, setThemes] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [editingText, setEditingText] = useState('');

    useEffect(() => {
        fetchTodos();
        fetchThemes();
    }, [searchTerm]);

    async function toggleTodo(id, is_completed) {
        const { error } = await supabase
            .from('todos')
            .update({ is_completed: !is_completed })
            .eq('id', id);
    
        if (error) console.log('error', error);
        else fetchTodos();
    }
    
    async function deleteTodo(id) {
        const { error } = await supabase
            .from('todos')
            .delete()
            .eq('id', id);
    
        if (error) console.log('error', error);
        else fetchTodos();
    }

    async function fetchThemes() {
        const { data, error } = await supabase
            .from('themes')
            .select('*')
            .order('name');
        
        if (error) console.log('error', error);
        else setThemes(data);
    }

// fonction pour compter les le nombres de tache par theme

async function test() {

        const { data, error } = await supabase
            .rpc('count_todos_by_theme');
            
        if (error) {
            console.log('error', error);
            return;
        }
    
        console.log('Nombre de todos par thème:', data);
        return data;
    
  }

    async function fetchTodos() {
        let query = supabase
            .from('todos')
            .select(`
                *,
                themes (
                    id,
                    name
                )
            `)
            .order('created_at', { ascending: true });

        if (searchTerm) {
            query = query.ilike('task', `%${searchTerm}%`);
        }

        const { data, error } = await query;
        
        if (error) console.log('error', error);

        else setTodos(data);
        console.log('data', data)
    }

    async function addTodo(e) {
        e.preventDefault();
        if (newTask.trim() === '') return;

        const { error } = await supabase
            .from('todos')
            .insert([
                { 
                    task: newTask, 
                    is_completed: false,
                    theme_id: selectedTheme || null
                }
            ]);

        if (error) console.log('error', error);
        else {
            setNewTask('');
            setSelectedTheme('');
            fetchTodos();
        }
    }

    async function updateTodoText(id) {
        const { error } = await supabase
            .from('todos')
            .update({ task: editingText })
            .eq('id', id);

        if (error) console.log('error', error);
        else {
            setEditingId(null);
            setEditingText('');
            fetchTodos();
        }
    }

    const startEditing = (todo) => {
        setEditingId(todo.id);
        setEditingText(todo.task);
    };

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
                Ma Todo List
            </h1>

            <div onClick={() => test()}>test</div>

            {/* Barre de recherche */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Rechercher une tâche..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>

            {/* Formulaire d'ajout */}
            <form onSubmit={addTodo} className="mb-8 space-y-3">
                <div className="flex gap-2">
                    <input
                        type="text"
                        placeholder="Nouvelle tâche"
                        value={newTask}
                        onChange={(e) => setNewTask(e.target.value)}
                        className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <select
                        value={selectedTheme}
                        onChange={(e) => setSelectedTheme(e.target.value)}
                        className="px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="">Sélectionner un thème</option>
                        {themes.map(theme => (
                            <option key={theme.id} value={theme.id}>{theme.name}</option>
                        ))}
                    </select>
                    <button
                        type="submit"
                        className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                    >
                        Ajouter
                    </button>
                </div>
            </form>

            {/* Liste des tâches */}
            <ul className="space-y-3">
                
                {todos.map((todo) => (
                    <li
                        key={todo.id}
                        className="flex items-center gap-3 p-4 bg-white rounded-lg shadow"
                    >
                        <input
                            type="checkbox"
                            checked={todo.is_completed}
                            onChange={() => toggleTodo(todo.id, todo.is_completed)}
                            className="w-5 h-5 rounded border-gray-300 focus:ring-blue-500"
                        />
                        {editingId === todo.id ? (
                            <div className="flex-1 flex gap-2">
                                <input
                                    type="text"
                                    value={editingText}
                                    onChange={(e) => setEditingText(e.target.value)}
                                    className="flex-1 px-2 py-1 border rounded"
                                />
                                <button
                                    onClick={() => updateTodoText(todo.id)}
                                    className="px-3 py-1 bg-green-500 text-white rounded"
                                >
                                    Sauvegarder
                                </button>
                                <button
                                    onClick={() => setEditingId(null)}
                                    className="px-3 py-1 bg-gray-500 text-white rounded"
                                >
                                    Annuler
                                </button>
                            </div>
                        ) : (
                            <>
                                <span
                                    className={`flex-1 ${
                                        todo.is_completed ? 'line-through text-gray-500' : 'text-gray-800'
                                    }`}
                                >
                                    {todo.task}
                                    {todo.themes && (
                                        <span className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded">
                                            {todo.themes.name}
                                        </span>
                                    )}
                                </span>
                                <button
                                    onClick={() => startEditing(todo)}
                                    className="px-3 py-1 text-sm text-blue-600 hover:bg-blue-100 rounded transition-colors"
                                >
                                    Éditer
                                </button>
                                <button
                                    onClick={() => deleteTodo(todo.id)}
                                    className="px-3 py-1 text-sm text-red-600 hover:bg-red-100 rounded transition-colors"
                                >
                                    Supprimer
                                </button>
                            </>
                        )}
                    </li>
                ))}
            </ul>

            {todos.length === 0 && (
                <p className="text-center text-gray-500 mt-4">
                    Aucune tâche trouvée
                </p>
            )}
        </div>
    );
}

export default TodoTest;