import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../config.js";

const ManagerScreen = (props) => {

 

  const user = {
    name: 'John Doe',
    email: "john.doe@gmail.com",
    id: '1234567890',
    img: "", 
    totalXp: 12,
    totalWord: 12,
    dates: [],
    totalTime: 2343,
    time_forest: 234,
    time_learn: 23,
    time_active: 23,
    time_action_learn: 23,
    dailySteak: 12,
    maxDailyStreak: 12,
    weeklyStreak: 12,
    forestLike: 12,
    lvl: 1,
    _valid: 34,
    _same: 34,
    _false: 12,
    action_forest: 12,
    action_main: 12,
    last_connection: [], // 7 dernières connections
    time_current_month: 12,
    time_current_week: 12,
    time_current_day: 12,
    time_mobile: 12,
    time_pwa: 12,
    time_web: 12,
    dailies: [
        {
            "2023": {
                time: 12,
                "01": {
                    time: 12,
                    "01": {
                        time: 12,
                        "time": 12,
                    }
                }
        }
    },
       
    ],
    scores: [ // les évaluations, entré checkpoints
        {state: 8}
    ]
  }

  const users = [user, user, user]

  return (
    <div className={``}>
        <div className='p-16 border-b-2 bg-green-100 '>
            <h1 className='text-green-500'>Manager </h1>
        </div>

        <div className='p-16'>
            <div className='mb-4'>
                <h2>Filter</h2>
                <div className='flex gap-4'>
                    <input placeholder='nom' className='p-2 rounded bg-slate-50 border'/>
                    <input placeholder='niveau' className='p-2 rounded bg-slate-50 border'/>
                </div>
            </div>
          
            <h2>Liste des utilisateurs </h2>
            <div className='my-2 rounded-2xl border overflow-hidden'>
                <div className='border-b-2 bg-slate-100 p-4 flex gap-2'>
                    <div className='w-[200px]'>Nom</div>
                    <div className=''>Temps passé</div>
                </div>
               
                {users.map(user => {
                    return <div className='border-b p-4 flex gap-4 hover:bg-blue-50'>
                        <div className=' w-[200px]'>{user.name}</div>
                        <div className=' w-[200px]'>{user.time_learn}</div>
                    </div>
                })}
            </div>
        </div>
    </div>
  );
};

export {ManagerScreen};