import { async } from "@firebase/util";
import { collection, addDoc, updateDoc, setDoc, getDoc, where, deleteDoc, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { db } from "../../../config.js";

import {models} from "../../../utilities/models.js"
import { getWeekNumberAndYear, reduceCard, reduceUC } from "../../../utilities/helper/helper.js";
import { getDayOfWeek } from "../weekStreak.js";
import { getDayNumber } from "../../screens/🏆leaderScreen.js";

function notnull(obj) {
  if (typeof obj === 'object' && obj !== null) {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
          if (value !== null) {
              newObj[key] = value;
          }
      }
      return newObj;
  } else {
      return obj;
  }
}

function parseDate(dayMonth) {
  const [day, month] = dayMonth.split('-').map(Number);
  const year = new Date().getFullYear(); // Utilise l'année courante
  return new Date(year, month - 1, day);
}

function daysBetween(date1, date2) {
  const firstDate = parseDate(date1);
  const secondDate = parseDate(date2);
  const differenceInTime = Math.abs(secondDate - firstDate); // Différence en millisecondes
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)); // Convertir en jours
  return differenceInDays;
}

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois vont de 0 à 11
  return `${day}-${month}`;
}

const handleStreak = (_user, setUser) => {
  const today = new Date()
  const todayDay = formatDate(today)


  // si le last day est aujourd'hui c'est tchao
  if (_user.lds == todayDay) return 

  // si le user n'est pas à jour
  let newStreak = (_user.streak || 0) + 1
  const newLds = todayDay

  const dayDiff = _user.lds ? daysBetween(todayDay, _user.lds) : 1
  if (dayDiff > 1) {
    newStreak = 1
  }

  console.log('newStreak', newStreak)

  setUser(u => ({...u, streak: newStreak, lds: newLds}))
  updateDoc(doc(db, "users", _user.id), {streak: newStreak, lds: newLds})
  // ajouter maxStreak et ldsMax

}


const updateDays = (days, index) => {
  // Convertir la chaîne 'days' en un tableau
  let daysArray = days.split(',');

  // Vérifier que l'index est valide
  if (index < 1 || index > daysArray.length) {
    console.error('Index hors limites');
    return;
  }

  // Remplacer l'élément à l'index (1-based) par '1'
  // Les index de tableau en JavaScript sont 0-based, donc on soustrait 1
  daysArray[index - 1] = '1';

  // Reconstruire la chaîne 'days' à partir du tableau mis à jour
  days = daysArray.join(',');

  return days

  // Optionnel : Afficher la chaîne mise à jour
}

const updateUserXp = (user, point, set, bonus = 0) => {
  console.log('updateUserXp 🟠🟠🟠🟠')
  const today = new Date();
  const todayWeek = getWeekNumberAndYear(today);
  const dayOfweek =  getDayNumber(today)
  const rawDays = user.week_table?.[todayWeek]?.d || "0,0,0,0,0,0,0";
  const days = updateDays(rawDays, dayOfweek)

  // Vérifiez si la clé existe et si oui, obtenez sa valeur, sinon définissez-la sur 0
  const currentXp = Math.max((user.week_table?.[todayWeek]?._xp || 0) ,0);
  const currentXpBonus = Math.max((user.week_table?.[todayWeek]?.xp || 0), 0);

  console.log('currentXp 🟠🟠🟠🟠', currentXp)

  // Incrémentez la valeur de l'expérience
  const newUserXp = currentXp + point;
  const newUserXPWithBonus = currentXpBonus + point + bonus
  console.log('newUserXp 🟠🟠🟠🟠', newUserXp)

  const newWeekTable = {
  ...user.week_table || [],
  [todayWeek]: {xp: newUserXPWithBonus, _xp: newUserXp, d: days} 
}

  console.log('newWeekTable 🟠🟠🟠🟠', newWeekTable)


  // Mettez à jour l'état avec la nouvelle valeur
  set(u => ({
    ...u,
    week_table: newWeekTable,
    d: days,
  }));
  updateDoc(doc(db, 'users', user.id), {week_table: newWeekTable, d: days})
};


const setUserWeekXp = (user, userWorkspaces, set) => {

  const allXpTable = userWorkspaces.reduce((acc, item) => {
    return acc.concat(item.xp_table);
  }, []).filter(e => e?.xp);


  const today = new Date()
  const dayOfweek =  getDayNumber(today)
  const todayWeek = getWeekNumberAndYear(today);
  const week_table = getUserWeekXp(user, allXpTable)
  const rawDays = user.week_table?.[todayWeek]?.d || "0,0,0,0,0,0,0";
  const days = updateDays(rawDays, dayOfweek)
  const newUser = {...user, week_table: week_table, d: days}



  console.log('newUser', newUser)
  set( u => {return {...u, week_table: week_table, d: days}})
  updateDoc(doc(db, 'users', newUser.id), {week_table: week_table, d: days})

  // update en ligne 

  return newUser
}

const getUserWeekXp = (user, xp_table) => {
  console.log('getUserWeekXp')


  const creationDate = (user?.updated?.seconds ? new Date(user?.updated?.seconds * 1000)  : user?.updated ) || new Date()
  console.log('creationDate', creationDate)
    
  const xp_table_day = xp_table?.map((exp) => ({...exp, 
    week: getWeekNumberAndYear(new Date(new Date(creationDate).setDate(creationDate.getDate() + exp.day) )), 
    date: new Date(new Date(creationDate).setDate(creationDate.getDate() + exp.day)  ),
    dayString: getDayOfWeek(new Date(new Date(creationDate).setDate(creationDate.getDate() + exp.day)))
  })

  )
  console.log('xp_table_day', xp_table_day.sort((a, b) => a.day - b.day).map(e => ({day: e.day, str: e.dayString, week: e.week})))


  const weekGrouped = xp_table_day.reduce((acc, item) => {
      const { week, xp, ts } = item;
      if (!acc[week]) {
          acc[week] = { xp: 0, ts: 0 };
      }
      acc[week].xp += xp;
      acc[week].ts += ts;
      return acc;
  }, {});

  console.log('weekGrouped', weekGrouped)

  return weekGrouped

}



function differenceInDays(date1, date2) {
  // Créer de nouvelles instances de Date à partir des dates fournies
  // et réinitialiser les heures, minutes, secondes et millisecondes à zéro
  const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

  const difference_ms = Math.abs(d1 - d2); // Calculer la différence absolue en millisecondes

  return Math.ceil(difference_ms / (1000 * 60 * 60 * 24)); // Convertir en jours et arrondir vers le haut
}

const learn = async (ct, card, opt) => {
 
    console.log('LEARN CARD 🟢', card.id)
    const userCardsRef = collection(db, 'user_cards');
    const originalCardRef = doc(db, 'cards', card.id);
    const model = models.find(m => m.name == (card.model || "lang-en"))
    
    let newDate = new Date();
    let lvlComp = 1

//    newDate.setSeconds(newDate.getMinutes() + boosters[0].time)
    const newDate2 = new Date();
    newDate2.setSeconds(newDate2.getSeconds() - 1);

    const validDate = new Date();
    validDate.setHours(validDate.getHours() + 24);

    
  
    if (card.model == "grammar"){
      newDate.setHours(validDate.getHours() + 6);
      if (!opt.isValid) {
        lvlComp = 0
        newDate = new Date()
      } else {lvlComp = 2}
    }

   

    if (opt?.name == "valid comp") {
      newDate.setHours(validDate.getHours() + 24);
      lvlComp = 2

    }


      // ajouter origin - dialog, gramar

    let goal_id = ct.userWorkspace?.goal_id || null

    const userCard = {
      ...opt.hint,
      card_id: card.id,
      rank: card.rank || null,
      workspace_id: ct.workspace.id,
      lvl: model.triggers.length > 1 ? 1 : lvlComp,
      user_id: ct.user.id,
      next_trigger: model.triggers.length > 1 ? opt.stacked ? 0 : 1 : 0,
      next_date: model.triggers.length > 1 ? newDate2 : newDate,
      last_update: new Date(),
      created_date: new Date(),
      archived: opt.archived || null,
      origin: opt.origin || ct.userWorkspace?.pathName || null,
      stacked: opt.stacked || null,
      sentence: opt.sentence || null,
      imgContext: opt.imgContext || null,
      sentence_trad: opt.sentence_trad || null,
      content: reduceCard(card), 
      path: opt.path || goal_id?.replace('perfect', "perf"),
      triggers: model.triggers.map((trigger, index) => {
        if (index == 0 ) return {...trigger, next_date: newDate, lvl: lvlComp}
        else return {...trigger, next_date: newDate2, lvl: 1}
      })
      // autres champs de la carte utilisateur
    }

    const new_user_card = reduceUC(userCard)
    console.log('new user_card', new_user_card)
   
    ct.nextCards?.length && ct.setNextCards(c => c?.filter(c => c.id != card.id))
  
    const userCardRef = await addDoc(userCardsRef, new_user_card);

    
    ct.setUserCards([...ct.userCards, {...new_user_card, id: userCardRef.id}])
    ct.fire.updateDaily({...ct.userDaily, new:  (ct.userDaily?.new|| 0) +1, xp: (ct.userDaily?.xp||0) + 1}, ct)
    const valid = true
    console.log('check 1')
    const total_xp = ct.userWorkspace?.total_xp || 0
    const xp_added = valid && !opt.archived ? 1 : 0
    const current_xp = (ct.userDaily?.xp||0) + xp_added
    const _xp_table =  ct.userWorkspace?.xp_table && ct.userWorkspace?.xp_table
    const firstDate = ct.user?.updated?.toDate() || new Date()
    const diffDay = differenceInDays(firstDate, new Date())
    const xp_exist =  _xp_table?.find(xp => xp.day == diffDay) 
    const timeSpend = ct.userDaily.ts || 0 // FAIRE ICI + PROGRAMME
    const xp_table = xp_exist ? _xp_table.map(exp => exp.day == diffDay ? {...exp, xp: current_xp, ts: timeSpend} : exp) : [...ct.userWorkspace?.xp_table || [], {day: diffDay, xp: current_xp, ts: timeSpend}]
    const _user_workspace = {...notnull(ct.userWorkspace), total_uc: ct.userCards?.length,  total_xp: total_xp + xp_added, xp_table: xp_table, coins: (ct.userWorkspace?.coins || 0) + 3, updatedDate: new Date() } 
    const _user_daily = {...ct.userDaily, xp: current_xp, new: (ct.userDaily?.new||0) + valid ? 1 : 0,  errors: (ct.userDaily?.errors||0) + !valid ? 1 : 0}
    console.log('check 2')
    ct.setUserWorkspace(_user_workspace)
    ct.fire.updateDaily(_user_daily, ct)
    const uwp_ref = doc(db, 'user_workspace', _user_workspace.id);
    console.log('check 3')
    updateDoc(uwp_ref, _user_workspace);

    console.log('check 4')
    if (opt.setStatus) {
      // sauf si tuto forest par encore terminé

      ct.setStatusCards(prev => [...prev || [], {userCard: new_user_card, card: card, status: opt.archived ? "archived" : "new"}])

    } else {
      ct.setStatusCards()
    }
    
    console.log('check 5')

    valid && !opt.archived && updateUserXp(ct.user, 1, ct.setUser)
    console.log('current_xp', current_xp)
    if (current_xp > 11) handleStreak(ct.user, ct.setUser)

    return {...new_user_card, id: userCardRef.id}
}



const 
nextUserCard =  (user_card, valid, trigger_index) => {
  const bonus = valid == 2 ? 1 : 0
  // console.log('bonus !!', bonus)
  // 
  console.log('VALID UPDATE CARD 🟣🟣🟣', user_card, trigger_index)
  // console.log('prev next date', user_card?.next_date)
  // console.log('prev next date', new Date(user_card?.next_date))
   
  const current_trigger_index = trigger_index != undefined ? trigger_index : user_card.next_trigger 
  // console.log('current_trigger_index', current_trigger_index)

  const current_trigger = user_card.triggers[current_trigger_index]
  // console.log("triggers 1", user_card.triggers)
  
  const triggers = user_card.triggers.map((trigger, index) => {
    if (index == current_trigger_index) {
      const newDate = new Date();
      // console.log('newDate', newDate)
      const lvl = valid ? current_trigger.lvl ? current_trigger.lvl + 1 + bonus: 1 +bonus: 0
      // console.log('lvl', lvl)
      // console.log('boosters', boosters)
     
      valid ? newDate.setMinutes(newDate.getMinutes() + boosters[current_trigger.lvl ? current_trigger.lvl + bonus : 0 + bonus]?.time || boosters[6].time) 
      : newDate.setSeconds(newDate.getSeconds() + 1)
      // console.log('newDate2', newDate)
     return {...trigger, next_date: newDate, lvl: lvl} 
    } else {
      const newDate = new Date();
      const today = new Date()
      const todayPlus3Hours = new Date(today.getTime() + 3 * 60 * 60 * 1000);
      newDate.setSeconds(newDate.getSeconds() + 0)


      const dateTrigger = trigger.next_date.seconds ? new Date(trigger.next_date.seconds*1000) : trigger.next_date
      // si le niveau du trigger est sup est 2 ou plus et que la prochaine date est de moins de 3h on ajoute 24h à newDate
      if (valid && trigger.lvl > 1  && dateTrigger < todayPlus3Hours ) {
        newDate.setTime(newDate.getTime() + 24 * 60 * 60 * 1000); // Ajoute 24 heures
        console.log('on ajoute 24h pour le prochain trigger !')
        // si le trigger est l'expression on augmente le niveau des autres trigger de 1 et la date en conséquence si le 
    }

      const _newDate = dateTrigger < todayPlus3Hours ? newDate : dateTrigger;

      return {...trigger, next_date: _newDate }
    }
 })
  const next_trigger_index = findOldestDateIndex(triggers)

  const next_trigger = triggers[next_trigger_index]
  let collected = true
  if (next_trigger.lvl > user_card.lvl && next_trigger.lvl > 1){
    collected = false
    // si la carte à évolué et qu'elle est pas dans la mémoire de travail alors on peut collecter son elixir
  }

  const updated_user_card = {...user_card, 
        lvl: next_trigger.lvl,
        last_update: new Date(),
        collected,
        next_date: next_trigger.next_date,
        next_trigger: next_trigger_index,
        triggers: triggers
  }

  return updated_user_card
}

const validCard = async (ct, user_card, valid, trigger_index, opt) => {
    let updated_user_card =  nextUserCard(user_card, valid, trigger_index)
    if (opt?.fastUptade) {
      if (user_card.triggers.length > 0) {
        updated_user_card =  nextUserCard(nextUserCard(user_card, valid, 0), valid, 1)
      } else {
        updated_user_card =  nextUserCard(user_card, valid, 0)
      }
    }

    if (opt?.unstack) {

      updated_user_card = {...updated_user_card, stacked: false, created_date: new Date()}

      if (opt.setStatus ) {
        ct.setStatusCards(prev => [...prev || [], {userCard: updated_user_card, card: ct.cards.find(c => c.id == updated_user_card.card_id), status: opt.archived ? "archived" : "new"}])
  
      }

    }

    
    console.log('user_card', user_card)
    console.log('updated_user_card', updated_user_card)
    console.log('opt', opt)
    updated_user_card = {
      ...updated_user_card,
      ...opt,
      created_date: updated_user_card.created_date || new Date('1996-06-10')
    };


    console.log('updated_user_card ☀️☀️☀️ ⚠️🎁', updated_user_card)

    const lvlTriggerUpdated = updated_user_card?.triggers[trigger_index]?.lvl
  
    ct.setUserCards(uc => (uc.map(uc => uc.id == user_card.id ? updated_user_card : uc)))
    const bonusElixir = lvlTriggerUpdated > 2 && valid && valid != 2 ? lvlTriggerUpdated - 2 : 0
    console.log('bonusElixir 🟣🟣', bonusElixir)
    const user_card_ref = doc(db, 'user_cards', updated_user_card.id);
    // console.log('user_card_ref', user_card_ref)
    
    updateDoc(user_card_ref, reduceUC(updated_user_card));
  
    const total_xp = ct.userWorkspace?.total_xp || 0
    const xp_added = valid ? 1 : 0
    const current_xp = (ct.userDaily?.xp||0) + xp_added
    const _xp_table =  ct.userWorkspace?.xp_table && ct.userWorkspace?.xp_table
    const firstDate = ct.user?.updated?.toDate() || new Date()
    const diffDay = differenceInDays(firstDate, new Date())
    const xp_exist =  _xp_table?.find(xp => xp.day == diffDay) 
    const timeSpend = ct.userDaily.ts || 0 
    const xp_table = xp_exist ? _xp_table.map(exp => exp.day == diffDay ? {...exp, xp: current_xp, ts: timeSpend} : exp) : [...ct.userWorkspace?.xp_table || [], {day: diffDay, xp: current_xp, ts: timeSpend}]
    const _user_workspace = {
      
      total_xp: total_xp + xp_added, 
      xp_table: xp_table,
      elixir: (ct.userWorkspace.elixir || 0) + bonusElixir, 
      tt_elx: (ct.userWorkspace.tt_elx || 0) + bonusElixir,
      coins: (ct.userWorkspace?.coins || 0) + 1,
      updatedDate: new Date() 
    } 
    const _user_daily = {...ct.userDaily, xp: current_xp ,up: (ct.userDaily?.up||0) + valid ? 1 : 0,  errors: (ct.userDaily?.errors||0) + !valid ? 1 : 0}
   
    ct.setUserWorkspace(uwp => ({...uwp,  ..._user_workspace}))
    ct.fire.updateDaily(_user_daily, ct)
    const uwp_ref = doc(db, 'user_workspace', ct.userWorkspace.id);
   
    updateDoc(uwp_ref, {..._user_workspace});
    valid && updateUserXp(ct.user, 1, ct.setUser, opt?.bonus || 0)
    console.log('current_xp', current_xp)
    if (current_xp > 12) handleStreak(ct.user, ct.setUser)

    return updated_user_card
  }

  // let isPwa = userWorkspace.isPwa || null
    // let isMobile = userWorkspace.isMobile || null
    // let isDesktop = userWorkspace.isDesktop || null
    // if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) { 
    //     isMobile = true
    // } else { isDesktop = true}

    // const __user_workspace = {...userWorkspace, isPwa, isMobile, isDesktop,  decks: newDecks, 
    //   timer_total: userWorkspace.timer_total + timer.total,
    //   timer_active: userWorkspace.timer_active + timer.active,
    // } 

    // createEvent({
    //     user_id: user.id, 
    //     user_name: user.name, 
    //     workspace_id: userWorkspace.workspace_id, 
    //     uwp_id: userWorkspace.id, 
    //     name: valid ? "refresh" : "fail", 
    //     card: card.original_card.id,
    //     card_name: card.original_card.term,
    //     platform: isPwa ? "pwa" : isMobile ? "mobile" : "desktop",
    //     info: info
    //   })


const boosters = [{
    name: "En cours d'apprentissage", 
    fr: "1 min",
    time: 1,
    // time: 1,
  },{
    name: "Travaille", 
    fr: "24h",
    time: 1440,
    // time: 1,
  },{
    name: "Bon", 
    fr: "72h",
    // time: 1,
    time: 4320,
  },{
    name: "Très bon", 
    fr: "5 jours",
    time: 10080,
  },{
    name: "Excellent", 
    fr: "1 mois",
    time: 43200,
  },{
    name: "Magistral", 
    fr: "6 mois",
    time: 259200,
  },{
    name: "Divin", 
    fr: "2 ans",
    time: 1036800,
  }] 


  function extractTextWithTags(input) {
    const temp = document.createElement("div");
    temp.innerHTML = input;
    return temp.innerText;
  }

  const updateUser = async (user) => {
    console.log('updateUser 🟣', user)
    const userRef = doc(db, 'users', user.id);
    const updated = new Date()
    await updateDoc(userRef, {...user, updated});
  }


  const createEvent = async (e) => {
    console.log('createEvent 🟣', e)
    const event = await addDoc(collection(db, 'event'), {...e, date: new Date()})

    return event.id
  }
  const creatUserWorkspace = async (uwp) => {
    console.log('creatUserWorkspace 🟣')
    const userWorkspace = await addDoc(collection(db, 'user_workspace'), uwp)

    return userWorkspace.id
  }

  const createUserWorkspaceElement = async (uwpe) => {
    console.log('createUserWorkspaceElement 🟣')
    const userWorkspaceElement = await addDoc(collection(db, 'user_workspace_element'), uwpe)

    return userWorkspaceElement.id
  }

  const deleteUserWorkspaceElement = async (id) => {
    try {
      console.log('deleteUserWorkspaceElement 🔴')
      await deleteDoc(doc(db, 'user_workspace_element', id))
      console.log('Document successfully deleted!')
    } catch (e) {
      console.error('Error removing document: ', e)
    }
  }

  const getPublicWorkspace = async () => {
    console.log('getPublicWorkspace 🟢')
    const q = query(collection(db, 'workspaces'), where('public', '==', true))
    const querySnapShot = await getDocs(q);
    const publicWorkspaces = querySnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return publicWorkspaces;
  }

  const getUser = async (user_id) => {
    console.log('getUser 🟢')
    const docRef = doc(db, 'workspaces', user_id);
    const snapshot = await getDoc(docRef);
    return {...snapshot.data(), id: snapshot.id}
  }

  const getUserWorkspace = async (uwp_id) => {
    console.log('getUserWorkspace 🟢', uwp_id)
    const docRef = doc(db, 'user_workspace', uwp_id);
    const snapshot = await getDoc(docRef);
    console.log('snapshot', snapshot)
    return {...snapshot.data(), id: snapshot.id}
  }

  const fetchCards = async (deck_id, workspace, user) => {
 
    console.log('fetching cards decks 🟢🟢🟢🟢🟢🟢🟢 ', deck_id);
    console.log(`wp: ${workspace.id} deck: ${deck_id}`)
    const q =  query(collection(db, 'cards'), where('workspace_id', '==', workspace.id), where("deck_id", "==", deck_id), orderBy('order')) 
    const querySnapshot = await getDocs(q);
    const _cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id }));
    console.log('fetched_cards >>>>>>>>>', _cards)
    if (_cards.length) {
        const userCardQuery = query(collection(db, 'user_cards'), where('workspace_id', '==', workspace.id), where('user_id', '==', user.id));
        const userCardQuerySnapshot = await getDocs(userCardQuery);
        const _user_cards = userCardQuerySnapshot.docs.map(doc => (
            {    ...doc.data(), 
                next_date: new Date(doc.data().next_date.seconds * 1000), 
                triggers: doc.data().triggers.map(trigger => ({...trigger, next_date: new Date(trigger.next_date?.seconds * 1000)})),
                id: doc.id
             }));

        const __cards = _cards.map(card => {
            const uc = _user_cards.find(user_card => user_card.card_id == card.id)
            return {original_card: card, user_card: uc}
        })   
     
       return __cards
     
    } else {
       return _cards
    }
   
}


  async function resetUserCards(cards, workspace, setCards, userWorkspace, setUserWorkspace) {
    console.log('RESET USER CARD 🟣')
    const q = query(collection(db, "user_cards"), where("workspace_id", "==", workspace.id), where("user_id", "==", userWorkspace.user_id));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db)

    querySnapshot.docs.forEach((doc) => {
        console.log(doc.id);
       batch.delete(doc.ref);
    })

   await batch.commit()
   
   
    const newDecks = userWorkspace?.decks?.map(d => ({...d, map: "", cards: [] }))

    const docRef = doc(db, "user_workspace", userWorkspace.id)
    const newUserWorkspace = {...userWorkspace, decks: [], biome_map: ""}
    await updateDoc(docRef, newUserWorkspace)
    console.log("userWorkspace", userWorkspace)
    setUserWorkspace(newUserWorkspace)
    console.log("newDecks", newDecks)
    console.log("newUserWorkspace", newUserWorkspace)
  
    setCards(cards?.map(card => {return {...card, user_card: null}}))

  }


 
const addUserDeck = async ({userWorkspace, deck_id, setUserWorkspace}) => {
  const userWorkspaceRef = doc(db, 'user_workspace', userWorkspace.id);
  const decks = userWorkspace.decks?.length ? userWorkspace.decks : []
  const existingDeck = decks?.find(d => d.id == deck_id)
  console.log("nouveau decks", decks)
  if (!existingDeck) {
    decks.push({id: deck_id})
    setUserWorkspace({...userWorkspace, decks})
    await updateDoc(userWorkspaceRef, {decks});
  } else { alert('le deck est déjà dans vos souvenirs')}
 
 
}

const removeUndefinedKeys = (collection) => Object.keys(collection).reduce((result, key) => (
  ![undefined, null].includes(collection[key])
    ? { ...result, [key]: collection[key] }
    : result
), {})

const updateUserWorkspace = async ({userWorkspace, setUserWorkspace}) => {
  const _userWorkspace = removeUndefinedKeys(userWorkspace)
  delete _userWorkspace.original;
  let isPwa = userWorkspace.isPwa || null
  let isMobile = userWorkspace.isMobile || null
  let isDesktop = userWorkspace.isDesktop || null
  if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) { isMobile = true
  } else { isDesktop = true}
  if (window.matchMedia('(display-mode: standalone)').matches) { isPwa = true }
  const __userWorkspace = {..._userWorkspace, isPwa,isMobile,isDesktop, decks: _userWorkspace.decks.map(d => d.cards == undefined ? {...d, cards: null} : d)}



  console.log('updateUserWorkspace  🟣', __userWorkspace)
  const userWorkspaceRef = doc(db, 'user_workspace', __userWorkspace.id);
  await updateDoc(userWorkspaceRef, __userWorkspace);
  setUserWorkspace(__userWorkspace)
}

const updateDeck = async({deck}) => {
  console.log('updateDeck 🟣', deck)
  const deckDoc = doc(db, 'decks', deck.id);
  updateDoc(deckDoc, deck)

}




  function findOldestDateIndex(triggers) {
    return triggers?.reduce((oldestIndex, trigger, currentIndex) => {
      if (trigger.next_date < triggers[oldestIndex].next_date) {
        return currentIndex;
      }
      return oldestIndex;
    }, 0);
  }

  export {
    updateUserWorkspace,
    fetchCards,
    updateDeck,
    extractTextWithTags,
    getUserWorkspace,
    getUser,
    validCard, 
    getPublicWorkspace,
    createEvent,
    findOldestDateIndex,
    handleStreak,
    updateUser,
    boosters, 
    creatUserWorkspace,
    getUserWeekXp,
    createUserWorkspaceElement,
    deleteUserWorkspaceElement,
    learn, 
    updateUserXp,
    addUserDeck,
    nextUserCard,
    resetUserCards,
    daysBetween,
    formatDate,
    setUserWeekXp}


