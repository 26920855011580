import { addDoc,query, collection, doc, getDocs, updateDoc, where, deleteDoc } from '@firebase/firestore';
import React, { useRef, useEffect, useState } from 'react';
import { db } from '../../config';
import Tree from 'react-d3-tree';
import { gpt } from '../../utilities/helper/text';


const prePromt = ({people, place, theme}) => `Donne uniquement la réponse. Sans Markdown. 
Créer un dialogue entre 2 personnes sur la thématique "${theme}", 
le dialogue se déroule dans : ${place}
le joueur discuter avec : ${people}
Les deux personnes on un dialogue et parle à la première personne

Le dialogue doit au moins faire 8 échanges avec une seul fin possible.
Avec plusieurs choix possibles à chaque échange dont un choix juste et 2 autres qui mène à cloture du dialogue avec "end_sentence".
Les choix ne doivent pas être évident et doivent être cohérent avec le contexte et de la même taille sans être trop long.
Suit le format suivant : 

{
  "context": "Tu es avec ta mamie dans une épicerie pour aller faire les courses ...",
  "start": {
    "text": "Regarde ces belles tomates ! Elles seraient parfaites pour notre salade ce soir, non ?",
   
    "options": [
      {
        "speak": "Bonne idée, Mamie ! Prenons-en quelques-unes.",
        "next": "rep1",
        "reaction": "😊", // doit être des emojis de visages en fonction de la réponse )
        "points": 1 //reponse meilleure donc 1 points
      },
      {
        "speak": "Je préfère les tomates cerises, elles sont plus sucrées.",
        "next": "rep1",
        "reaction": "😉",
        "points": 0 // reponse juste mais pas la meilleure donc 0 points
      },
      {
        "speak": "Les tomates ne sont pas de saison, on devrait prendre autre chose.",
        "end_sentence": "Oh, je vois. Peut-être devrions-nous rentrer si rien ne te tente.",
        "reaction": "😢",
        "points": -1
      }
    ]
  },
  "rep1": {
    "text": "Tu as raison, les tomates cerises ont l'air délicieuses aussi ! Et ces fraises, qu'en penses-tu ? Elles seraient parfaites pour le dessert.",
    "options": [
      {
        "speak": "Elles ont l'air appétissantes ! Prenons-en une barquette.",
        "next": "rep2",
        "reaction": "",
        "points": 1
      },
      {
        "speak": "Je préfère les framboises, elles sont moins sucrées.",
        "next": "rep2",
        "points": 0
        "reaction": "",
      },
      {
        "speak": "Les fruits rouges ne me tentent pas trop aujourd'hui.",
        "end_sentence": "D'accord, pas de problème. Peut-être devrions-nous écourter nos courses.",
        "reaction": "",
        "points": -1
      }
    ]
  },
  "rep2": {
    "text": "Regarde, ils ont du fromage frais de la ferme ! Ça te dirait qu'on en prenne pour le déjeuner ?",
    
    "options": [
      {
        "speak": "Excellente idée, ça sera délicieux avec du pain.",
        "next": "rep3",
        "points": 1
        "reaction": "",
      },
      {
        "speak": "Pourquoi ne pas essayer un nouveau type de fromage ?",
        "next": "rep3",
        "points": 0
        "reaction": "",
      },
      {
        "speak": "Le fromage frais n'est pas mon préféré, désolé.",
        "end_sentence": "Oh, je comprends. Peut-être que nous devrions rentrer si tu n'es pas inspiré.",
        "points": -1,
        "reaction": "",
      }
    ]
  },
  "rep3": {
    "text": "Et si on prenait des croissants pour le petit-déjeuner de demain ? Ils ont l'air tout frais sortis du four.",
    
    "options": [
      {
        "speak": "Oui, j'adore les croissants chauds le matin !",
        "next": "rep4",
        "points": 1
        "reaction": "",
      },
      {
        "speak": "Je préférerais des pains au chocolat, si ça te va.",
        "next": "rep4",
        "points": 0
        "reaction": "",
      },
      {
        "speak": "On a déjà du pain à la maison, ce n'est pas nécessaire.",
        "end_sentence": "Je vois. Peut-être que nous avons terminé nos courses alors.",
        "points": -1,
        "reaction": "",
      }
    ]
  },
 
...
"rep10": {...},
  "ending_good": {
    "text": "Tu es vraiment attentionné. Je suis fière de toi.",
    "end_game": true,
    "success": true
  },
}

`


const ScenarioNodeEditor = ({ nodeId, node, updateNode }) => {
  const [text, setText] = useState(node.text || '');
  const [context, setContext] = useState(node.context || '');
  const [ending, setEnding] = useState(node.ending || '');
  const [points, setPoints] = useState(node.points || 0);
  const [options, setOptions] = useState(node.options || []);


  

  const handleTextChange = (e) => {
    setText(e.target.value);
    updateNode(nodeId, { ...node, text: e.target.value });
  };

  const handleContextChange = (e) => {
    setContext(e.target.value);
    updateNode(nodeId, { ...node, context: e.target.value });
  };

  const handleEndingChange = (e) => {
    setEnding(e.target.value);
    updateNode(nodeId, { ...node, ending: e.target.value });
  };

  const handlePointsChange = (e) => {
    const newPoints = parseInt(e.target.value, 10);
    setPoints(newPoints);
    updateNode(nodeId, { ...node, points: newPoints });
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
    updateNode(nodeId, { ...node, options: newOptions });
  };

  const addOption = () => {
    const newOption = { text: '', next: '', points: 0 };
    setOptions([...options, newOption]);
    updateNode(nodeId, { ...node, options: [...options, newOption] });
  };

  const removeOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    updateNode(nodeId, { ...node, options: newOptions });
  };

  return (
    <div className='text-slate-600 rounded-xl p-2' style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>

      <div className='text-xs italic text-slate-500'>{nodeId}</div>
      <div>
        <label>
          <textarea className='text-xl text-purple-500' value={text} onChange={handleTextChange} rows={3} style={{ width: '100%' }} />
        </label>
        {/* <label>
          Context:
          <textarea value={context} onChange={handleContextChange} rows={3} style={{ width: '100%' }} />
        </label> */}
      </div>
      {node.ending !== undefined && (
        <div>
          <label>
            Fin:
            <input type="text" value={ending} onChange={handleEndingChange} style={{ width: '100%' }} />
          </label>
        </div>
      )}
      <div>
       
      </div>

      {options.map((option, index) => (
        <div className='p-2 rounded-xl border border-slate-300' key={index} style={{ marginBottom: '10px', borderBottom: '1px dashed #aaa' }}>
          <div>
           
              <input
                className=''
                type="text"
                value={option.speak}
                onChange={(e) => handleOptionChange(index, 'speak', e.target.value)}
                style={{ width: '100%' }}
              />
            
           
          </div>
          test: 
              <input
                className='text-red-500'
                type="text"
                value={option.end_sentence}
                onChange={(e) => handleOptionChange(index, 'end_sentence', e.target.value)}
                style={{ width: '100%' }}
              />
          <div className='flex text-xs mt-2 px-2 rounded-xl bg-black/10 gap-2'>
          <div className='flex '>
            <label>
              Next:
              <input
                type="text"
                value={option.next}
                className='bg-transparent w-[100px]'
                onChange={(e) => handleOptionChange(index, 'next', e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>
              Pt:
              <input
                type="number"
                value={option.points}
                className='bg-transparent w-[30px]'
                onChange={(e) => handleOptionChange(index, 'points', parseInt(e.target.value, 10))}
              />
            </label>
          </div>
          <div onClick={() => removeOption(index)}>Supprimer</div>
          </div>
        </div>
      ))}
      <button onClick={addOption}>Ajouter une option</button>
    </div>
  );
};


const DialogEdit = ({selectedSenario, workspace,  scenarios, setSenarios}) => {
  // État local pour le formulaire
  const [scenario, setScenario] = useState(selectedSenario);
  const [scenarioContent, setScenarioContent] = useState();
  const [treeData, setTreeData] = useState(null);
  const [view, setView] = useState("raw")
  const [custom, setCustom] = useState()
  const [customAnswer, setCustomAnswer] = useState()


  const views = [{
    id: "raw"
  }, 
{
  id: "tree"
}, {
  id: "edit"
}]

  useEffect(() => {
    setScenario(selectedSenario)
    console.log('selectedSenario content', selectedSenario?.content)
    const json = JSON.parse(selectedSenario?.content || "{}")
    console.log('json', json)
    setScenarioContent(json)
   
  }, [selectedSenario])

  // Fonction pour gérer la mise à jour des champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setScenario({
      ...scenario,
      [name]: value
    });
  };

  const validChange = async() => {
    setSenarios(scenarios.map(s => s.id === scenario.id? {...s,...scenario } : s))
    console.log('scenario', scenario)
    const devoirRef = doc(db, "scenarios", scenario.id)
    console.log('scenario', scenario)
    await updateDoc(devoirRef, scenario);  
  }

  const transformScenarioToTree = (scenario, currentNodeId, visited = new Set()) => {
    if (!currentNodeId || visited.has(currentNodeId)) {
      return null;
    }
    visited.add(currentNodeId);
  
    const node = scenario?.[currentNodeId];
    if (!node) {
      return { name: `"${currentNodeId}" not found` };
    }
  
    const children = [];
  
    if (node.options && node.options.length > 0) {
      node.options.forEach((option) => {
        const childNode = transformScenarioToTree(scenario, option.next, visited);
        if (childNode) {
          children.push({
            name: option.next,
            attributes: { pt: option.points, speak: option.speak },
            children: [childNode],
          });
        } 
        // else {
        //   children.push({
        //     name: option.next,
        //     attributes: { Points: option.points },
        //     children: [{ name: `"${option.next}" not found` }],
        //   });
        // }
      });
    } else if (node.ending) {
      children.push({
        name: `Ending: ${node.ending}`,
        attributes: { pt: node.points },
      });
    }

    console.log('children', children)
  
    return {
      name: currentNodeId,
      attributes: { Text: node.id },
      children: children,
    };
  };




  


  useEffect(() => {
    // Transformer le scénario en données pour l'arbre
    if (scenarioContent) {
      const data = transformScenarioToTree(scenarioContent, 'start');
      setTreeData(data);
    }
    
  }, [scenarioContent]);

  




  const updateNode = (nodeId, updatedNode) => {
    setScenarioContent((prevScenario) => ({
      ...prevScenario,
      [nodeId]: updatedNode,
    }));
  };


  const duplicateInOtherWp = async (wp_id, scenario) => {
    try {
      // Requête pour vérifier s'il existe déjà un document avec le wp_id et original
      const scenariosRef = collection(db, "scenarios");
      const q = query(scenariosRef, where("wp_id", "==", wp_id), where("original", "==", scenario?.original || "null"));
      const querySnapshot = await getDocs(q);


      const messages = [
        {"role": "system", "content": "Donne uniquemnent la réponse et sans markdown"},
        {"role": "user", "content": `traduit en ${workspace.name} : ${(scenario.content)}`}
    
    ]
      console.log('messages', messages)



      const translatedContent = await gpt(messages, true)
      const contentTranslated = translatedContent.choices[0].message.content


      console.log('contentTranslated', contentTranslated)
  
      // Crée un nouvel objet "newSC"
      const newSC = {
        ...scenario,
        date: new Date(),
        name: scenario.name,
        id: null,
      
      
        wp_id: wp_id,
        original: scenario.id,
        content: contentTranslated,
      };
  
      if (!querySnapshot.empty) {
        // Si un document existe, nous le mettons à jour
        querySnapshot.forEach(async (docSnap) => {
          const docRef = doc(db, "scenarios", docSnap.id);
          await updateDoc(docRef, {...newSC, id: docSnap.id});
          console.log("Document mis à jour avec ID: ", docSnap.id);
        });
      } else {
        // Si aucun document ne correspond, nous ajoutons un nouveau document
        const docRef = await addDoc(collection(db, "scenarios"), {...newSC});
        await updateDoc(docRef, { id: docRef.id });
        console.log("Nouveau document ajouté avec ID: ", docRef.id);
        setSenarios((prev) => [...prev || [], {...newSC, id: docRef.id}]);
      }
    } catch (error) {
      console.error("Erreur lors de la duplication ou mise à jour du document: ", error);
    }
  };

  if (!scenario) return <div>Selectionne un Scenarioue</div>

  return (
    <div>
      <label>
        Name: 
        <input
          key={"name_"+scenario.id}
          type="text"
          name="name"
          value={scenario?.name}
          onChange={handleChange}
          placeholder="Enter name"
        />
      </label>

      <label>
        Emo: 
        <input
          key={"emo_"+scenario.id}
          type="text"
          name="emo"
          value={scenario?.emo}
          onChange={handleChange}
          placeholder="Enter emo"
        />
      </label>

      <label>
        Place: 
        <input
          key={"place_"+scenario.id}
          type="text"
          name="place"
          value={scenario?.place}
          onChange={handleChange}
          placeholder="Enter place"
        />
      </label>

      <label>
        People: 
        <input
          key={"people_"+scenario.id}
          type="text"
          name="people"
          value={scenario?.people}
          onChange={handleChange}
          placeholder="Enter people"
        />
      </label>

      <label>
        face: 
        <input
          key={"face_"+scenario.id}
          type="text"
          name="face"
          value={scenario?.face}
          onChange={handleChange}
          placeholder="Enter face"
        />
      </label>

      <label>
        body: 
        <input
          key={"body_"+scenario.id}
          type="text"
          name="body"
          value={scenario?.body}
          onChange={handleChange}
          placeholder="Enter body"
        />
      </label>

      <label>
        bg: 
        <input
          key={"bg_"+scenario.id}
          type="text"
          name="bg"
          value={scenario?.bg}
          onChange={handleChange}
          placeholder="Enter bg"
        />
      </label>

      <div className='px-4 rounded-xl bg-purple-500 mt-12 game-btn' onClick={() => validChange()}>Valider</div>

      <div className='flex gap-2 rounded'>{views.map(e => <div className={`${view?.id == e.id ? "underline" : ""}`} onClick={() => setView(e.id)}>{e.id}</div>)}</div>


      {view == "raw" &&  <label>
        <textarea
          key={scenario.id}
          className='bg-[#2d064b] text-amber-500 text-xs w-full font-mono	h-[600px] rounded-xl p-4 overflow-scroll'
          type="text"
          name="content"
          value={scenario?.content}
          onChange={handleChange}
          placeholder="Enter content"
        />
      </label>}

     
    
      {view == "edit" && Object.keys(scenarioContent).map((nodeId) => (
        <ScenarioNodeEditor
          key={nodeId}
          nodeId={nodeId}
          node={scenarioContent[nodeId]}
          updateNode={updateNode}
        />
      ))}

      {view == "tree" && <div style={{ flex: '1', borderLeft: '1px solid #ccc', padding: '10px' }}>
        <h1>Visualisation de l'arbre</h1>
        {treeData && (
          <div className='bg-white' style={{ width: '100%', height: '50vh' }}>
            <Tree data={treeData} orientation="vertical" />
          </div>
        )}
      </div>}


      <div></div>



      <div onClick={() => setCustom(prePromt({people: scenario.people, place: scenario.place, theme: scenario.name}))}>reset</div>
      <textarea
          key={scenario.id}
          className='bg-slate-200 text-amber-500 text-xs w-full font-mono	h-[600px] rounded-xl p-4 overflow-scroll'
          type="text"
          name="content"
          value={custom}
          
          onChange={(e) => {setCustom(e.target.value)}}
          placeholder="Enter content"
        />
        <div className='bg-purple-500 px-4 rounded-xl' onClick={async() => {  
          
          const messages = [
            {"role": "user", "content": custom}
        
        ]
        console.log('messages', messages)



      const translatedContent = await gpt(messages, true, null, {model: "o1-mini"})
      const contentTranslated = translatedContent.choices[0].message.content
      setCustomAnswer(contentTranslated)


      console.log('custom answer', contentTranslated)

        }}>ASK GPT</div>
      <textarea className='max-h-[100px] bg-pink-100 rounded-xl p-2' value={customAnswer}>{customAnswer}</textarea>

      <div onClick={() => duplicateInOtherWp(workspace.id, selectedSenario)}>Ajouter en {workspace.id}</div>
      <div onClick={async () => {
          console.log('deleteUserWorkspaceElement 🔴', selectedSenario)
          const q = query(collection(db, "scenarios"), where("wp_id", "==", workspace.id), where("original", "==", scenario.original));
          const querySnapshot = await getDocs(q);
        


          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs?.map(e => ({...e, id: e.id}))?.[0]
            console.log('querySnapshot', querySnapshot)
            console.log('doc', doc)
            await deleteDoc(doc(db, 'scenarios', doc.id))
            console.log('Document successfully deleted!')
            setSenarios((prev) => prev.filter((s) => s.id!== doc.id));
          } else {
            
          }
        
      }}>Supprimer {selectedSenario.id} - {selectedSenario.original}</div>
    </div>  
  );
};


const DialogManager = ({ src, user, setUser, workspace}) => {



  

  useEffect(() => {
    getSc("français")
  }, [])

  useEffect(() => {
    workspace?.id && getSc(workspace?.id)
  }, [workspace?.id])

  const [scenarios, setSenarios] = useState()


  const getSc = async (wp_id) => {
    console.log('getSc 🟢', wp_id)
    const q = query(collection(db, 'scenarios'))
    const querySnapShot = await getDocs(q);
    console.log('querySnapShot', querySnapShot)
    const _uw = querySnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('_uw ---', _uw)
    setSenarios(_uw)
    return _uw;
  }

  const addInDb = async(wp_id = "français") => {

         
        console.log('addInDb')
        const newSC = {
          date: new Date,
          name: "--",
          wp_id: wp_id,
        }
        const docRef = await addDoc(collection(db, "scenarios"), newSC);
        console.log("Document written with ID: ", docRef.id);
        setSenarios(e => [...e || [], {...newSC, id: docRef.id} ])
    
  }

  const [selectedSenario, setSelectedSenario] = useState()

  return (

  
    <div className='relative'>

      <div className='absolute top-0 left-0 w-[200px] '>
        <div className='p-2 rounded-xl  border'>
          <div className='font-bold' onClick={() => {getSc("français")}}>Liste des scenarios (fr)</div>
          <div>{scenarios?.filter(e => e.wp_id == "français")?.map(e => <div className={`${selectedSenario?.id == e.id ? "bg-slate-100" : "bg-slate-100/10"} ml-1`} onClick={() => setSelectedSenario(e)}>{e.name} <span className='text-[10px] text-slate-400'>{e.id}</span></div>)}</div>
          <div className='text-slate-500 text-sm mt-2 text-center' onClick={() => addInDb()}>Ajouter fr</div>
        </div>

        <div className='p-2 mt-8 rounded-xl  border'>
          <div className='font-bold' onClick={() => {console.log(scenarios?.filter(e => e.wp_id == workspace.id))}}>Liste des scenarios ({workspace.id})</div>
          <div>{scenarios?.filter(e => e.wp_id == workspace.id)?.map(e => <div className={`${selectedSenario?.id == e.id ? "bg-slate-100" : "bg-slate-100/10"} ml-1`} onClick={() => setSelectedSenario(e)}>{e.name} <span className='text-[10px] text-slate-400'>{e.id}</span></div>)}</div>
          <div className='text-slate-500 text-sm mt-2 text-center' onClick={() => addInDb(workspace.id)}>Ajouter {workspace.id}</div>
        </div>
      </div>

      <div className='ml-[240px]'>
        <DialogEdit workspace={workspace} selectedSenario={selectedSenario} scenarios={scenarios} setSenarios={setSenarios}/>
      </div>




        

     

    </div>
  );
};

export {DialogManager};
