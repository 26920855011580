import React from 'react';
import { useStateContext } from '../V3/StateProvider';
import { sbCreate } from '../V3/DbFunction';


const ErrorBoundaryWrapper = ({ children }) => {
  const { user } = useStateContext();
  return <ErrorBoundary user={user}>{children}</ErrorBoundary>;
};

const clearCacheAndReload = () => {
    // Désactiver et réenregistrer le service worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (let registration of registrations) {
          registration.unregister();
        }
      }).catch(error => {
        console.error('Error unregistering service workers', error);
      });
    }
  
    // Nettoyer les caches
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        return Promise.all(
          cacheNames.map(cacheName => {
            return caches.delete(cacheName);
          })
        );
      }).then(() => {
        window.location.reload(true);
      }).catch(error => {
        console.error('Error clearing caches', error);
      });
    } else {
      window.location.reload(true);
    }
  };

  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false, 
        error: null, 
        errorInfo: null,
        logSent: false // Nouvel état pour tracker si le log a été envoyé
      };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      this.setState({ error, errorInfo }, () => {
        // Envoyer le log seulement si ce n'est pas déjà fait

        let user_id = this.props.user?.id 
        if (!user_id) {
          try {
          user_id = localStorage.getItem('userId')
          } catch (error) {
            console.error('Error getting user_id from localStorage', error);
          }
        }

        if (!this.state.logSent && error && errorInfo) {
          sbCreate('logs', {
            type: "error blank screen",
            text: error?.toString() + "---" + JSON.stringify(errorInfo),
            created_time: new Date(),
            user_id: user_id
          });
          
          // Marquer le log comme envoyé
          this.setState({ logSent: true });
        }
      });
      
      console.error("ErrorBoundary caught an error", error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <div className='fredoka mt- max-w-[700px] mx-auto p-8 text-center'>
            <img className='mx-auto max-h-[200px]' src="images/UX/coach_olivier.png" />
            <h1 className='text-purple-600'>Oops! Une erreur est survenue </h1>
            <p>Nous sommes désolés pour ce désagrément. Veuillez essayer de recharger la page.</p>
  
            <button onClick={clearCacheAndReload} className='px-4 py-1 rounded-xl border-2 border-b-4 mt-4'>
              Recharger
            </button>
            <details style={{ whiteSpace: 'pre-wrap' }} className='text-slate-500 mt-4 max-h-[200px] text-xs overflow-scroll bg-slate-100 rounded-xl p-2'>
              <summary>Détails</summary>
              <p>{this.state.error && this.state.error.toString()}</p>
              <p>{this.state.errorInfo && this.state.errorInfo.componentStack}</p>
            </details>
          </div>
        );
      }
  
      return this.props.children;
    }
}
  

export{ErrorBoundary, ErrorBoundaryWrapper};