import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray, speak, stopSound } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { addImg, arabicLetters, click_sound2, displayDuration, grammars, highlightLetterArabic, pinyinProps, randomSentences, removePinyinAccents, validSound2 } from '../../utilities/helper/helper.js';
import { badSound, validSound } from '../components/learn/learnContainer.js';
import { getStorage } from 'firebase/storage';
import { ct } from '../../utilities/data.js';
import { LetterCard } from '../components/learn/letterCard.js';
import { updateUserXp } from '../components/learn/learn.js';
import { ChallengeScreen } from './ChallengeScreen.js';



function scoreToNote(score) {
    // Plages de score avec les notes correspondantes
    const ranges = [
        { min: 0, max: 5, noteMin: 0, noteMax: 3 },        // Palier pour score très bas
        { min: 6, max: 10, noteMin: 3, noteMax: 5 },       // Palier pour score faible
        { min: 11, max: 15, noteMin: 5, noteMax: 6 },      // Palier pour score faible-moyen
        { min: 16, max: 20, noteMin: 6, noteMax: 7 },      // Palier pour score faible-moyen-supérieur
        { min: 21, max: 30, noteMin: 7, noteMax: 9 },      // Palier pour score moyen-bas
        { min: 31, max: 40, noteMin: 9, noteMax: 10 }, 
        { min: 31, max: 70, noteMin: 11, noteMax: 13 },  // 2e palier
        { min: 71, max: 110, noteMin: 13, noteMax: 15 }, // 3e palier
        { min: 111, max: 170, noteMin: 15, noteMax: 17 },// 4e palier
        { min: 171, max: 240, noteMin: 17, noteMax: 19 } // 5e palier
    ];

    // Trouver la plage correspondante pour le score donné
    for (let range of ranges) {
        if (score >= range.min && score <= range.max) {
            // Interpolation linéaire pour calculer la note
            let note = range.noteMin + (score - range.min) * (range.noteMax - range.noteMin) / (range.max - range.min);
            return Math.round(note); // Arrondir la note
        }
    }

    // Si le score dépasse le dernier palier
    if (score > 240) {
        return 20; // Niveau maximum
    }

    return null; // Cas de score inférieur à 0 (non prévu)
}


    const ExamScreen = ({ close, user, setUser, cards, workspace, hideMenuBar, lang}) => {

        const [showChallengeScreen, setShowChallengeScreen] = useState(false)
        const [randomizedCard, setRandomizedCard] = useState()
        const [randomizedSentence, setRandomizedSentence] = useState()



        const randomizeCard = (cards, lang) => {
            if (!cards.length) return
            setRandomizedCard(shuffleArray(cards))
            randomSentences?.[lang] && setRandomizedSentence(shuffleArray(randomSentences?.[lang]))
            if (workspace == "chinois") {
                setRandomizedSentence(shuffleArray(pinyinProps))
            }

        }

        useEffect(() => {
            console.log('randomizeCard cards', cards)
            cards.length && randomizeCard(cards, lang)
        }, [cards, lang])

        function getRandomElements(arr, count) {
            let shuffled = arr.sort(() => 0.5 - Math.random()); // Mélanger le tableau
            return shuffled.slice(0, count); // Prendre les `count` premiers éléments
        }

        // const cardsToData

        const r1 = randomizedCard?.map(card => ({
            show: card.letter,
            props: getRandomElements(cards.filter(c => c.latin != card.latin), 3).map(c => ({print: c.latin})),
            good: {print: card.latin, speak: card.letter}
        })).slice(0, 10)  

        const r2 = randomizedCard?.map(card => ({
            show: card.latin,
            type: "sound",
            props: getRandomElements(cards.filter(c => c.latin != card.latin), 3).map(c => ({print: c.letter})),
            good: {print: card.letter, speak: card.letter}
        })).slice(0, 10)  

        const r3 = randomizedCard?.map(card => ({
            show: card.word,
            props: getRandomElements(cards.filter(c => c.latin != card.latin), 3).map(c => ({print: c.word_latin})),
            good: {print: card.word_latin, speak: card.word}
        })).slice(0, 10)  

        const r4 = randomizedCard?.map(card => ({
            show: card.latin,
            type: "sound",
            props: getRandomElements(cards.filter(c => c.latin != card.latin), 3).map(c => ({print: c.word})),
            good: {print: card.word, speak: card.word}
        })).slice(0, 10)  

        const r5 = randomizedSentence?.map(sentence => ({
            show: sentence.word,
            type: "sound",
            input: "split",
            props: sentence?.props.map(c => ({print: c})),
            good: {print: sentence.word, speak: sentence.word}
        }))  

        let data = [
            {step: r1?.length, quiz: r1, point: 3, bonusTime: true, name: "Lecture des lettre", consign: "Choisis la bonne prononciation" },
            {step: r2?.length, quiz: r2, point: 4, bonusTime: true, name: "Ecoute des lettres",  consign: "Choisis la lettre correspondante" },
            {step: r3?.length, quiz: r3, point: 5, bonusTime: true, name: "Lecture des mots", consign: "Choisis la bonne prononciation" },
            {step: r4?.length, quiz: r4, point: 6, bonusTime: true, name: "Ecoute des mots", consign: "Choisis la mot correspondant" },
            {step: r5?.length, quiz: r5, point: 7, name: "Ecoute des phrases", consign: "Choisis la bonne réponse" },
        ]

        if (workspace == "chinois") {

            const r1 = randomizedCard?.map(card => ({
                show: card.letter,
                type: "sound",
                props: getRandomElements(cards.filter(c => c.latin != card.latin), 3).map(c => ({print: c.latin})),
                good: {print: card.latin, speak: card.letter}
            })).slice(0, 5)  

            console.log('randomizedSentence',randomizedSentence)

            const r2 = randomizedSentence?.map(sentence => ({
                    show: sentence.latin,
                    speak: sentence.word,
                    type: "sound",
                    props: sentence?.props.map(c => ({print: c})),
                    good: {print: sentence.latin, speak: sentence.word}
            })).slice(0, 10);

            const r3 = randomizedSentence?.map(sentence => ({
                show: sentence.latin,
                speak: sentence.word,
                type: "sound",
                input: "write",
                good: {print: sentence.latin, speak: sentence.word}
        }));
           

            data = [
                {step: r1?.length, quiz: r1, point: 3, bonusTime: true, name: "Pinyin", consign: "Choisis les pinyins correspondants à l'audio" },

                {step: r2?.length, quiz: r2, point: 5, bonusTime: true, name: "Ecoute des pinyin",  consign: "Choisis les pinyins correspondants à l'audio" },

                {step: r3?.length, quiz: r3, point: 7,   name: "Ecoute des pinyin",  consign: "Ecris ce que tu entends" },




            ]
   
        }
            

        const bots = [
            {name: "Zoé", score: 10},
            {name: "Julie", score: 30},
            {name: "Alex", score: 30},
            {name: "Tristan", score: 5},
            {name: "Benoit", score: 9},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "--", score: 0},
            {name: "Daniel", score: 50},
        ].map(e => ({...e, [`s_${workspace}`]: e.score}))


        const [players, setPlayers] = useState([])


        const getUsers = async() => {
            const usersCurrent = (await getDocs( query( collection(db, "users"), where(`s_${workspace}`, ">=", 0))))
            .docs.map(doc => ({...doc.data(), id: doc.id}))

           
        
            const _players = usersCurrent
            .concat(bots.slice(0, 30 - usersCurrent.length))
            .sort((a, b) => (b[`s_${workspace}`] - (a[`s_${workspace}`])))

            console.log('_players !!!', _players)

            setPlayers(_players)


        }


        

        useEffect(() => {
            getUsers()
        }, [])

        useEffect(() => {
      
            hideMenuBar(false) 

            return () => {
                hideMenuBar(true) 
            }

        }, [])




    const tabs = ["Monde", "Ma classe", "Cette semaine"]

    if (showChallengeScreen) {
        return <ChallengeScreen randomizeCard={() => randomizeCard(cards, lang)} lang={lang} user={user} hideMenuBar={hideMenuBar} workspace={workspace} setUser={setUser} data={data} close={() => {getUsers() ;randomizeCard(cards, lang); setShowChallengeScreen(false)}} />
    }
        return (
          <div className='bg-[#5e35bd] bg-cloud relative h-screen fredoka'>
            <div className='bg-[#5e35bd] bg-cloud p-4 '>
                            <div onClick={() => close()}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6">
  <path fillRule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clipRule="evenodd" />
</svg>

                </div>
            <div className='text-center mb-2 text-shadow text-2xl pt-2'>Meilleurs joueurs</div>
            <div className='p-4 backdrop-blur flex gap-4 rounded-xl justify-between  border-black/20 bg-black/10 '>
                <div className='flex gap-2 grow items-center justify-between'>
                    <div>
                    <div>Mon meilleur score</div>
                    <div className='text-sm text-white/70'>Note</div>
                </div>
                <div>
                    <div className='text-amber-400 bg-amber-100/10 rounded-xl px-2 font-[500]'>{user[`s_${workspace}`]}</div>
                    <div className='text-amber-100/70 text-sm text-center  font-[400]'>{scoreToNote(user[`s_${workspace}`])}/20</div>
                </div>
                </div>
                        {/* <div className='p-2 flex text-xs items-center gap-2 rounded text-center'>
                            
                        <div className='flex mt-2  justify-center'>
                            <div className='bg-black/10 border border-black/40  rounded-xl'>
                                <span className='text-amber-500 px-4 text-xl rounded-xl px-2'>{user[`s_${workspace}`]}</span> 
                                <div className='bg-black/10 px-4 py-1 rounded-b-xl w-full text-xs text-amber-400'>{scoreToNote(user[`s_${workspace}`])}/20</div></div>
                            </div>
                        </div> */}
                       
                    
                      
                    </div>
            </div>
            {/* <div onClick={() => getUsers()} className='flex justify-end mb-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer p-2 rounded bg-black/10  text-white/80 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div> */}
            <div className='p-2 bg-gradient-to-b from-[#36206c] to-[#221348] rounded-t-2xl px-4 overflow-auto h-screen pb-[400px] '>{players.map((e, i) => <div className={'flex border-b border-black/20 items-center py-1 justify-between'}>
                <div className='flex gap-2 items-center'>
                {e[`s_${workspace}`] ? <div className='text-white/70 text-xs'>{i + 1}</div> : <div></div>}
                {e.name != "--" ? <div>{e.name}</div> : <div className='text-white/30'>--</div>}
                </div>
                {e[`s_${workspace}`] ? <div className='text-amber-400 bg-amber-100/10/10 rounded-xl px-2 font-[500]'>{e[`s_${workspace}`]}</div> :<div className='text-white/20'>--</div> }
                </div>)}
                </div>

                <div className='flex mt-4 absolute bottom-10 right-0 left-0 justify-center'>
                            <div onClick={() => {setShowChallengeScreen(true)}} className='bg-indigo-500 fredoka font-[600] px-10 py-2 game-btn border-black text-shadow rounded-xl border-2 border-b-4'>Commencer</div>
                        
                    </div>
            <div className='absolute bottom-[px]  justify-center left-0 right-0'>
           
              
            </div>

           
            
          
          </div>
        );
      };

export {ExamScreen, scoreToNote};





