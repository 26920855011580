import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray, speak, stopSound, soundValid } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { addImg, arabicLetters, click_sound2, displayDuration, grammars, highlightLetterArabic, removePinyinAccents, validSound2 } from '../../utilities/helper/helper.js';
import { badSound, validSound } from '../components/learn/learnContainer.js';
import { getStorage } from 'firebase/storage';
import { ct } from '../../utilities/data.js';
import { AudioBtn, LetterCard } from '../components/learn/letterCard.js';
import { updateUserXp } from '../components/learn/learn.js';
import { Input, QCM, Split } from './Qcm.js';


waveform.register()

    const ChallengeScreen = ({ close, data, hideMenuBar, setUser, workspace, user, lang, randomizeCard}) => {
        const time = 58
        const [timeLeft, setTimeLeft] = useState(time); // Temps initial en secondes
        const [isRunning, setIsRunning] = useState(false);
        const [steps, setSteps] = useState(0);
        const [passedRound, setPassedRound] = useState(0);
        const [score, setScore] = useState(0);

        const [isSpeaking, setIsSpeaking] = useState(false);
        
        const [isPaused, setIsPaused] = useState(false); // Nouvel état pour gérer la pause
        const [initialTime, setInitialTime] = useState(time); // Temps initial pour calculer le pourcentage
      
        // Met à jour le temps initial lorsque le temps restant change et que le chronomètre n'est pas lancé


        const [showRound, setShowRound] = useState(false)
        const [showEndScreen, setShowEndScreen] = useState(false)


        const rounds = data.map(e => ({step: e.step, name: e.name, point: e.point, consign: e.consign, bonusTime: e.bonusTime}))
        const point = rounds[passedRound]?.point
        const bonusTime = rounds[passedRound]?.bonusTime
       

        const quiz = data[passedRound]?.quiz
       



        useEffect(() => {
          if (!isRunning && !isPaused) {
            setInitialTime(timeLeft);
          }
        }, [timeLeft, isRunning, isPaused]);
      
        // Gère le décompte du chronomètre
        useEffect(() => {
          let timer = null;
          if (isRunning && !isPaused) {
            timer = setInterval(() => {
              setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                  clearInterval(timer);
                  setIsRunning(false);
                  setIsPaused(false);
                  if (close) {
                    updateUser()
               
                  }
                  return 0;
                }
                return prevTime - 1;
              });
            }, 1000);
          }
          return () => {
            if (timer) {
              clearInterval(timer);
            }
          };
        }, [isRunning, isPaused, close]);
      
        // Démarre le chronomètre
        const startTimer = () => {
        setTimeLeft(time)
        setInitialTime(time)
        setSteps(0)
          if (!isRunning) {
            setIsRunning(true);
            setIsPaused(false);
          }
        };
      
        // Met en pause le chronomètre
        const pauseTimer = () => {
          if (isRunning && !isPaused) {
            setIsPaused(true);
          }
        };
      
        // Reprend le chronomètre
        const resumeTimer = () => {
          if (isRunning && isPaused) {
            setIsPaused(false);
          }
        };
      
        // Arrête le chronomètre et quitte
        const stopTimer = () => {
          if (isRunning || isPaused) {
            setIsRunning(false);
            setIsPaused(false);
          }
          setShowRound(false)
         
        };
      
        // Ajoute 1 seconde au temps restant

        


        const pass = async () => {
            setSteps(e => e + 1)
            subtractOneSecond()
            if (nextQuiz && nextQuiz.type == "sound"){
                setIsSpeaking(true)
                await speak({text: nextQuiz.good?.speak, mp3: "", lang: lang})
                setIsSpeaking(false)
            } 
        }

        const unvalidExercice = async() => {
            console.log('unvalidExercice')
            subtractOneSecond(2)
            setSteps(e => e + 1)
            setScore(e => e - point > 0 ? e - point : 0)
            if (nextQuiz && nextQuiz.type == "sound"){
                setIsSpeaking(true)
                await speak({text: nextQuiz.good?.speak, mp3: "", lang: lang})
                setIsSpeaking(false)
            } 
        }


        const validExercice = async () => {
            console.log('validExercice')
            addOneSecond()
            setSteps(e => e + 1)
            setScore(e => e + point)
            if (nextQuiz && nextQuiz.type == "sound"){
                setIsSpeaking(true)
                await speak({text: nextQuiz.good?.speak, mp3: "", lang: lang})
                setIsSpeaking(false)
            } 
        }

        const addOneSecond = () => {
            bonusTime && setTimeLeft((prevTime) => prevTime + 1);
         
        };
      
        // Enlève 1 seconde du temps restant
        const subtractOneSecond = (n = 1) => {
          if (timeLeft > n) {
            setTimeLeft((prevTime) => prevTime - n);
          }

         
        };
      
        // Affiche le temps restant sous forme de minutes et secondes si plus de 60 secondes
        const displayTime = () => {
          if (timeLeft > 60) {
            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;
            return `${minutes} min ${seconds} s`;
          } else {
            return `${timeLeft} s`;
          }
        };
      
        // Calcule le pourcentage du temps restant
        const timePercentage = () => {
          return ((timeLeft / initialTime) * 100).toFixed(2);
        };

       


        const updateUser = () => {
            setShowEndScreen(true)



                    const lastScoreBestScore = user[[`s_${workspace}`]]
                    console.log('lastScoreBestScore', lastScoreBestScore)
                    const newScore = lastScoreBestScore > score ? lastScoreBestScore : score
                    console.log('newScore', newScore)
                    setUser(e => ({...e, [`s_${workspace}`]: newScore}))
                    updateDoc(doc(db, "users", user.id), {[`s_${workspace}`]: newScore})

        }

        useEffect(() => {

            
            if (steps == rounds[passedRound]?.step) {
                if (rounds.length == passedRound + 1) {
                    updateUser()
                    
                    
                }
                randomizeCard()
                setShowRound(true)
            } else {
                setShowRound(false)
            }
            
        }, [steps, passedRound])

        useEffect(() => {

            if (showRound) {
                pauseTimer()
            }
            
        }, [showRound])

        useEffect(() => {
            startTimer()
            // hideMenuBar(false) 

            // return () => {
            //     hideMenuBar(true) 
            // }

        }, [])

        

        const cent = Math.ceil(steps*100/rounds[passedRound]?.step)
        const currentQuiz = quiz?.[steps]
        const nextQuiz = quiz?.[steps+1]

        // console.log('data', data)
        // console.log('focus --quiz', quiz)
        // console.log('round', rounds)
        // console.log('currentQuiz', currentQuiz?.props?.map(props => props.print))
        // console.log('nextQuiz', nextQuiz)

        const xpRef = useRef(null);
        const bonusRef = useRef(null)
    
        const handleAddXp = (e, point = 1, opt= {}) => {
    
           
            const container = opt?.element || e.target;
            const rect = container.getBoundingClientRect();    
            const current = xpRef.current
            const bonusRefCurrent = bonusRef.current
          
            let animation = 'add-xp' 
            let text = `+ ${point} pt`
    
         
            if (point < 0 ){
                animation = 'minus-xp';
                text = `${point} pt`
                badSound.play()
            } else {
                soundValid.play()
            }
       
    
    
    
            if (current) {
            current.style.left = `${rect.right - 50}px`;
            current.style.top = `${rect.top -90}px`;
            current.innerHTML = text
            current?.classList?.add(animation);
            score > 0 && bonusRefCurrent?.classList?.add('bonus-animation');
          
            
    
            
            
            setTimeout(() => {
                current?.classList?.remove(animation);
                score > 0 &&  bonusRefCurrent?.classList?.remove('bonus-animation');
            }, 600); // Durée de l'animation
            }
        };
    


        
      
        return (
          <div className='p-4 h-screen bg-topo relative bg-[#36206c] bg-gradient-to-b from-[#36206c] to-[#221348] '>
            <div className='flex items-center gap-2 top-0 right-0'>
                <div onClick={() =>close()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
                </div>
            {bonusTime ? <div className='h-2 rounded-full bg-black/10 w-full overflow-hidden '>
                    <div className='h-full bg-amber-500 game-btn rounded-xl' style={{width: `${cent}%`}}></div>
            </div> : <div className='grow'></div>}
            

          
                <div className='relative'>
              
                <CircularProgressbar
                                value={timePercentage()}
                                className='circularProgress  bg-[#2C196E]/10 rounded-full transition w-[44px] !h-[44px]'
                                styles={buildStyles({
                                strokeLinecap: 'butt',
                                strokeWidth: 2,
                                textSize: '0px',
                                pathColor: "#FFC70040",
                                textColor: 'red',
                                pathTransitionDuration: 0.5,
                                strokeLinecap: 'round', 
                                // trailColor: '#573ABB',
                                // backgroundColor: 'yellow',
                                transform: 'rotateX(37deg)'
                                })}
                                strokeWidth={8}

                        />
                        <div className='absolute fredoka top-0 left-0 right-0 text-xs text-white/80 mt-2 bottom-0 flex justify-center items-center'>{displayTime()}</div>
                </div>
                
               
            </div>
            <div className={`text-center ${bonusTime ? "-mt-2" : "-mt-8"}  fredoka text-amber-400`}>Score <b>{score} pt</b></div>

            <div className=' flex flex-col justify-center items-center h-full pb-[220px] pt-[20px]'>
                
            
           
           {showEndScreen && <div className='p-6 fredoka rounded-xl text-center'>
            Mon score : <span className='text-amber-500 text-xl'>{score}</span>
            <div className='mt-8 flex justify-center'><div className='px-4 py-2 rounded-xl border-black/70 border-2 border-b-4' onClick={() => {
                close()
            }}>Quitter</div></div>
            </div>}

            {showRound && rounds[passedRound + 1] && <div className='text-center fredoka mt-[100px]'>
                <div className='text-white/60 text-xs'>Niveau {passedRound + 2}</div>
                <div className='text-white/80 mb-8 text-sm'>{rounds[passedRound + 1]?.name}</div>
                <div className='mb-8'>{rounds[passedRound + 1]?.consign}</div>
                <div className='flex justify-center'>
                    <div className='px-4 rounded-xl py-1 border-2 border-b-4 border-black/50' onClick={() => {

                    if(isRunning || isPaused) {
                        resumeTimer()
                      
                    } else {
                        startTimer()
                       
                    }
                    setSteps(0)
                    setPassedRound(e => e+1)
                    setShowRound(false)
                    }}>{isRunning || isPaused ? "Continuer →" : "Commencer"}
                </div>
                </div>
                </div>}


            {isRunning && !showRound && currentQuiz?.good && <div className='fredoka text-center'>
                    
                    {currentQuiz?.type == "sound" && 
                    <div onClick={async() =>  {
                        setIsSpeaking(true)
                        await speak({text: currentQuiz?.good?.speak, mp3: "", lang: lang})
                        setIsSpeaking(false)
                         }} className={'h-[100px] mx-auto flex items-center justify-center w-[100px] rounded-[30px] bg-black/10 border-2 border-b-4 border-black/20'}>
                        {isSpeaking ?  <l-waveform
                            size="20"
                            stroke="3.5"
                            speed="1" 
                            color="white" 
                            ></l-waveform> : 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-pink-100 w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
                            }
                    </div>}

                    {!currentQuiz?.type && <div className={`text-4xl text-${workspace} font-sans mb-8 text-center`}>{currentQuiz?.show}</div>}

                    <div id="coucou" ref={xpRef} className='xpop text-xl text-right fredoka font-semibold w-[70px] text-shadow-orange text-yellow-200 top-10 right-10 rounded-xl z-[999] absolute px-1 opacity-0 '>--</div>
                        {currentQuiz?.input == "split" && <div className='mt-10 text-center grow'><Split workspace_id={workspace} point={point} isSpeaking={isSpeaking} handleAddXp={handleAddXp}  props={currentQuiz?.props} setCorrect={validExercice} setFalse={unvalidExercice} pass={pass} good={quiz[steps]?.good} /></div>}

                         {currentQuiz?.input == "write" && <div className='mt-10 text-center grow'><Input workspace_id={workspace} point={point} isSpeaking={isSpeaking} handleAddXp={handleAddXp}  props={currentQuiz?.props} setCorrect={validExercice} setFalse={unvalidExercice} pass={pass} good={quiz[steps]?.good} /></div>}
                        {!currentQuiz?.input && <div className='absolute p-4 bottom-[30px] left-0 right-0'><QCM workspace_id={workspace} point={point} handleAddXp={handleAddXp}  props={currentQuiz?.props} setCorrect={validExercice} setFalse={unvalidExercice} pass={pass} good={quiz[steps]?.good} /> </div>}
                    </div>}



                    
            </div>
      
            {/* <div>
              {!isRunning && !isPaused && <button onClick={startTimer}>Commencer</button>}
              {isRunning && !isPaused && <button onClick={pauseTimer}>Pause</button>}
              {isRunning && isPaused && <button onClick={resumeTimer}>Reprendre</button>}
              {(isRunning || isPaused) && <button onClick={stopTimer}>Quitter</button>}
            </div> */}


            {/* {!showRound && isRunning && <div className='flex gap-2'>
                <div>
                <button  className='px-4 rounded-xl py-1 border-2 border-b-4 border-black/50' onClick={addOneSecond}>Ajouter 1 s</button>
                </div>
                <div>
                <button  className='px-4 rounded-xl py-1 border-2 border-b-4 border-black/50' onClick={subtractOneSecond}>Enlever 1 s</button>
                </div>
            </div>} */}

          </div>
        );
      };






export {ChallengeScreen}