import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, writeBatch, doc} from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';

import { db } from "../../config.js";

import {learn, validCard, findOldestDateIndex} from '../components/learn/learn.js'
import {replaceBracketsWithUnderLine} from '../../utilities/helper/text'
import {displayDuration, displayDate} from '../../utilities/helper/helper'
import { SuperEditor } from '../../components/editor.js';
import { Dialog } from '../components/dialog.js';
import { MindMap } from '../components/mindMap.js';



const versionExt = "?&v=2"



const DeckScreen = (props) => {
    const [url, setUrl] = useState(window.location.href)
    const [mode, setMode] = useState()

    const searchParams = new URLSearchParams(url);
    const deck_id = props.deck_id ? props.deck_id : searchParams.get("deck_id")
    const ct = props.context

    const deck = ct.decks?.find(d => d.id == deck_id)
    const dialog = deck?.dialog
    const mindMap = deck?.mindcard
    const choicesLogo = [{name: "Dialogue", logo: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
  </svg>}, {name: "Cartes", logo: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" />
</svg>
}]
    const choices = ["Cartes"]
    if (dialog) {
        choices.unshift('Dialogue')
    }

    useEffect(() => {
        ct.setShowBottomBar(false)
        return ct.setShowBottomBar(true)
    }, [])
    const [selectedChoice, setSelectedChoice] = useState(choices[0])


    const deletedUserCards = async () => {
        const userCards = cardsInDecks.map(c => c.user_card)
        console.log('suppression de ', userCards)
        const batch = writeBatch(db);
        userCards.forEach(uc => {
            console.log('id', uc.id)
            uc.id && batch.delete(doc(db, "user_cards", uc.id))
        })
         await batch.commit()
         console.log('suppression terminé')
         
    }

    // console.log('deck', deck)
    const image = deck?.dialog?.img


    const cardsInDecks = ct.cards?.filter(c => c.deck_id == deck?.id)?.map(card => {
        const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
        const user_card = {..._user_card, 
            next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
            triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
                {...t, next_date: new Date(t.next_date.seconds * 1000)}
                ) : t)}
        const state = !user_card?.next_date ? "to_learn" : user_card.archived ? "archived" : user_card?.next_date > new Date() ? "mastered" : "to_review"
        const indexTrigger = user_card.next_date ? findOldestDateIndex(user_card?.triggers) : 42
        return {...card, user_card, state, indexTrigger}
    })

    const cardNotArchived = cardsInDecks?.filter(c => !c.user_card?.archived)
    

    const cardsToDiscover = cardNotArchived?.filter(c => !c.user_card || !c.user_card?.next_date)
    const cardToReview = cardNotArchived?.filter(c => c.user_card?.next_date < new Date()).map(card => ({...card, indexTrigger: findOldestDateIndex(card.user_card?.triggers)}))
    const cardMastered = cardNotArchived?.filter(c => c.user_card?.next_date > new Date())
    const cardComp = cardToReview?.filter(c => c.indexTrigger == 0)
    const cardExp = cardToReview?.filter(c => c.indexTrigger == 1)
    const nextCards = [...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardToReview || []].slice(0,20)
    // const nextCards = cardsToDiscover && cardsToDiscover[0] ?  [cardsToDiscover[0], cardsToDiscover[0], cardsToDiscover[0]] : []
    // const cardComp = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Compréhension ")?.next_date  < new Date())
    // const cardExp = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Expression")?.next_date  < new Date())


    const theS = (items) => items?.length > 1 ? "s" : ""
    
     const showCardList = (cards, mode) => {

        return cards?.map(c => {
            const user_card = c.user_card
            const modal = user_card?.lvl % 2 == 0 ? "audio" : "write"
            return <div key={c.id} onClick={() => ct.setShowCards([{...c, mode: mode == "exp" ? 1 : 0}])} className=' border-2 border-b-4 bg-white rounded-xl overflow-hidden border-gray-200 rounded-md flex flex-wrap items-center gap-2 mt-2'>
                <div className={`${mode == "comp" ? "bg-gray-200" : "bg-green-400"} relative flex items-center self-start justify-center h-[90px] bg-purple-500 w-[90px]`}>
                    { !(mode == "comp" || mode == "discover") && <img src={c.img} className='absolute top-0 left-0 right-0 bottom-0 object-cover w-full h-full ' />}
                    {mode == "comp" || mode == "discover" && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 text-slate-400 h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                    </svg>
            }
                </div>
                <div className='p-2 grow w-2/3 '>
          
               
                {mode == "discover" ? <div className='italic text-slate-500'>Réveler la carte</div> : <>

                    {mode != "exp"  && <SuperEditor  className="font-semibold text-slate-800 text-green-600" content={c.term || "terme"}/>}
                    {mode != "comp"  && mode != "mastered"  && <SuperEditor className="italic text-slate-500 " content={(c.trad) || "traduction"}/>}

         
                </>
                }
               

               
                {mode != "discover" && <>
                {mode != "exp"  &&   <div className='text-[#0A4D68] text-[14px]' dangerouslySetInnerHTML={{ __html:  replaceBracketsWithUnderLine(c?.contexts && c?.contexts[0].text) }}></div>}

                {mode != "comp" && mode != "mastered" && c.context_trad && <div className='text-slate-500 italic text-[14px]' dangerouslySetInnerHTML={{ __html:  replaceBracketsWithUnderLine(c.contexts[0].trad) }}></div>}
                </>  
                                // <SuperEditor  className="font-semibold text-[16px] text-slate-800" content={(c.context) || "context"}/>}

              
            }
               {/* trigger: {user_card.next_trigger == 0 ? "Comp" : "Expand"}  */}

               {/* {user_card.next_date ? 
                    <div className='flex gap-4 mt-2'>
                        <div onClick={(e) => {e.stopPropagation(); validCard(ct, user_card, false)}} className='b px-4 bg-purple-500 text-white inline-block rounded py-1'>A revoir</div>
                        <div onClick={(e) => {e.stopPropagation();validCard(ct, user_card, true)}} className='b px-4 bg-green-500 text-white inline-block rounded py-1'>Valider</div>
                    </div> 
                    : 
                    <div onClick={(e) => {e.stopPropagation();learn(ct, c)}} className=' b px-4 bg-yellow-500 text-white inline-block rounded py-1'>Apprendre</div>
                }   */}
                </div>
                {(user_card?.next_date ) && <div className=' bg-yellow-50 px-2 p-2 -mt-2 w-full rounded-b-xl text-amber-500 border-t'>
                    <div className='gap-4 items-center flex justify-between'>
                        <div className='italic flex gap-2 items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
</svg>
A améliorer dans {displayDuration(new Date(), user_card.next_date)} </div>
                        <div className='text-amber-500 flex gap-1 bg-yellow-200  items-center rounded-full border-amber-300 px-2 py-[1px] inline-block text-sm'>{user_card.lvl}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#ffcc1e" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>

                        </div>
                    </div>
                    <div>{user_card?.triggers?.map(trigger => (<div className={'text-gray-400 italic'}>- {trigger.name} : ({trigger.lvl}/5) - {displayDuration(new Date(), trigger.next_date)}</div> ))}</div>
                </div> }
                    
                </div>
                
        })
     }

  const addAlert = (alert) => {
    const _alerts = ct.alerts || []
    console.log('_alerts', _alerts)
    ct.setAlerts([..._alerts, {title: "hey", date: new Date()}])
  }

  return (
    <div className={`absolute z-50 overflow-scroll  rounded-xl  bg-white ${props.mode == "forest" ? "top-0 bottom-0 max-w-[700px] left-0 right-0": " top-0 bottom-0 left-0 right-0 border-none"}`}>
       
        <header className={`text-white relative flex items-center gap-4 p-4 mb-2 overflow-hidden `}>
        <Link className='text-slate-500' onClick={() => ct.setShowDeckId() } ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
        </Link>
            <div className='h-[10px] bg-slate-100 grow relative rounded-xl overflow-hidden'>
          
            <div className={`bg-purple-500  h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length + cardToReview?.length)/ cardsInDecks?.length * 100) + "%" }}></div>
            <div className={`bg-green-500 h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length)/ cardsInDecks?.length * 100) + "%" }}></div>


        </div>
        <div></div>
           
          
               {/* <div className='-z-10 absolute object-cover h-full w-full top-0 bg-red-200 left-0 right-0 bottom-0' style={{background: 'linear-gradient(180.3deg, rgba(0, 0, 0, 0.2) 0.26%, rgba(0, 0, 0, 0.5) 50.7%)' }} /> */}
           
        </header>
        <h1 className='text-[18px] text-center text-slate-500 mb-2 -mt-6 '>{deck?.name}</h1>
        <div className='px-8'>
        {image && <img src={image} alt="" className='-z-10 sm:h-40 w-full lg:h-[300px]  rounded-xl bg-purple-500 object-cover max-w-[500px] mx-auto' />}
        </div>
        
      
      
        {/* <div>Total : {cardsInDecks.length}</div>
        <div>Maitrisé : {cardMastered.length} - {Math.round(cardMastered.length / cardsInDecks.length * 100) }% </div>
        <div>A revoir : {cardToReview.length} - {Math.round((cardMastered.length + cardToReview.length)/ cardsInDecks.length * 100)} </div>
        <div>A découvrir : {cardsInDecks.length - (cardToReview.length + cardMastered.length) }</div> */}
        <main className='mb-[100px] '>

    
        <div className='p-4 max-w-[700px] mx-auto'>

        {mindMap && <MindMap  cardsInDecks={cardsInDecks} context={ct} deck={deck} cards={cardsInDecks} />}
           
        <div className='flex gap-4 justify-center -mt-3'>
            {choices?.length > 1 && choices.map(c => <div onClick={() => setSelectedChoice(c)} className={` ${selectedChoice == c ? "border-blue-400  text-blue-600" : "text-slate-400"} items-end text-[12px] p-2  pb-2 rounde border-b-2 b flex gap-2 `}>
                {choicesLogo?.find(cl => cl.name == c).logo} {c} </div>)}
        </div>
           
        {selectedChoice == "Cartes" && <>
            {cardComp?.length ?
                <div>
                    <h2 className='flex text-[16px] gap-[6px] mt-6 -mb-1 items-end text-purple-500'><div className='hidden text-indigo-500'>{cardComp?.length}</div>Compréhension{theS(cardComp)} à renforcer </h2>
                    {showCardList(cardComp, "comp")}
                </div> : <div></div>
            }
             
            {cardExp?.length ?
                <div>
                    <h2 className='flex text-[16px] gap-[6px] mt-6 -mb-1 items-end text-indigo-500'><div className=''>{cardExp?.length}</div>Expression{theS(cardExp)} à renforcer </h2>
                    {showCardList(cardExp, "exp")}
                </div> : <div></div>
            }
           

            {cardsToDiscover?.length ?
                <div>
                    <h2 className='flex text-[16px] gap-[6px] mt-6 -mb-1 items-end text-yellow-600'><div className=' text-yellow-500'>{cardsToDiscover?.length}</div>Nouvelle{theS(cardsToDiscover)} carte{theS(cardsToDiscover)} à découvrir</h2>
                    {showCardList(cardsToDiscover, "discover")}
                </div> : <div></div>
            }

            <hr class="w-1/3 my-10 mx-auto"/>

            {cardMastered?.length ?
                <div>
                    <h2 className='flex text-[16px] gap-[6px] mt-6 items-end text-green-600'><div className=' text-green-500'>{cardMastered?.length}</div>Carte{theS(cardMastered)} maitrisée{theS(cardMastered)}</h2>
                    {showCardList(cardMastered, "mastered")}
                </div> : <div></div>
            }
            </>} 

            {selectedChoice == "Dialogue" && <Dialog mode={"learn"} deck={deck} cards={cardsInDecks} context={ct} lang={"en"} />
        }


           
           
            <div>
          
            </div>
        
 
        </div>

        {/* <div className='flex flex-col gap-2 items-center justify-center'>
            <div onClick={() => addAlert({text: "hey"})} className='b text-blue-500 text-center px-4 py-1 border-b-4 border-2 border-blue-500 rounded-xl inline-block mx-auto'>Apprendre</div>
            <div onClick={() => deletedUserCards({text: "hey"})} className='b text-pink-500 text-center px-4 py-1 border-b-4 border-2 border-pink-500 rounded-xl inline-block mx-auto'>Recommencer</div>

        </div> */}
        </main>
        <div className={`fixed h-1 z-10 ${ct.pb ? "bottom-[20px]" : "bottom-[10px]" }  left-0 right-0 text-center gap-2 flex justify-center p-2 items-end`}>

        {nextCards.length > 0 &&  <div className=" rounded-xl border-2 overflow-hidden flex border-b-4 border-[#4E4433] bg-yellow-400 text-shadow text-white font-bold fredoka b">
       <div className="px-4 py-2" onClick={() => {(dialog || mindMap) ? ct.setActiveDeck(deck_id) : ct.setShowCards(nextCards)}}>Apprendre</div>
           <div className='p-2 bg-[#4E4433]'>
           {dialog ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
            </svg> : mindMap ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
</svg>
: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
</svg>
}
            </div>

                </div>}
                </div>
    </div>
  );
};

export {DeckScreen};    