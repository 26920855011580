import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export function StateProvider({ children }) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null)
  const [activeScreen, setActiveScreen] = useState(null)
  const [workspaces, setWorkspaces] = useState([])
  const [workspace, setWorkspace] = useState(null)
  const [mandatoryScreen, setMandatoryScreen] = useState(null)
  const [nextCards, setNextCards] = useState(null)
  const [learnedCards, setLearnedCards] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showBottomBar, setShowBottomBar] = useState(true)
  const [cardsToRefresh, setCardsToRefresh] = useState(null)
  const [cardsToDiscover, setCardsToDiscover] = useState(null)
  const [showTopBar, setShowTopBar] = useState(true)
  const [bubulle, setBubble] = useState()
  const [decks, setDecks] = useState([])
  const [daily, setDaily] = useState(null)
  const [profileDecoration, setProfileDecoration] = useState([])
  const [profileWorkspace, setProfileWorkspace] = useState()
  const [profileWorkspaces, setProfileWorkspaces] = useState([])
  const [showCategorie, setShowCategorie] = useState(false)
  const [alert, setAlert] = useState()
  const [tutoTriggers, setTutoTriggers] = useState([])
  //

  const value = {
    user,setUser,
    setDaily, daily,
    showCategorie, setShowCategorie,
    profile,setProfile,
    activeScreen, setActiveScreen,
    workspaces, setWorkspaces,
    workspace, setWorkspace,
    mandatoryScreen, setMandatoryScreen,
    nextCards, setNextCards,
    profileDecoration, setProfileDecoration,
    learnedCards, setLearnedCards,
    showModal, setShowModal,
    showBottomBar, setShowBottomBar,
    cardsToRefresh, setCardsToRefresh,
    cardsToDiscover, setCardsToDiscover,
    showTopBar, setShowTopBar,
    bubulle, setBubble,
    tutoTriggers, setTutoTriggers,
    profileWorkspace, setProfileWorkspace,
    profileWorkspaces, setProfileWorkspaces,
    decks, setDecks,
    alert, setAlert
  };

  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
    return useContext(StateContext);
  }