import { useState, useEffect } from "react";

import {displayDuration} from '../../utilities/helper/helper.js'
import { addDoc, doc, writeBatch, onSnapshot, query, collection,limit, getDocs, where, updateDoc,getDoc, deleteDoc } from "@firebase/firestore";

import { db } from "../../config.js";

import { ImageUpload } from "../components/imageUpload.js";
import {displayDate} from '../../utilities/helper/helper.js'

const TeacherScreen = (props) => {

  

  const ct = props.ct



const [workspaces, setWorkspaces] = useState()
const [workspace, setWorkspace] = useState()
const [classes, setClasses] = useState()

const [schools, setSchools] = useState()

const addClasses = async () => {
  const className = document.querySelector('#class_name')
  const classCode = document.querySelector('#class_code')
  const classSchool = document.querySelector('#class_school')
  const newClass = {name: className.value, code: classCode.value, school_id: classSchool.value, admin: [{user_id: ct.user.id, role: "admin"}]}
  console.log('new class', newClass)
  const docRef = await addDoc(collection(db, "class"), newClass);
  setClasses([...classes, {...newClass, id: docRef.id}])
}


const [users, setUsers] = useState([])
const [selectedClass, setSelectedClass] = useState()
const [userWorkspaces, setUserWorkspaces] = useState()

const renderUser = (user) => <div onClick={() => console.log('user', user)} className="p-2  mb-2 border border-amber-300/20 rounded-xl ">
  <div className="flex gap-2 items-center mb-2">
    <div>{user.name}</div>
    <div className="text-slate-500 text-sm grow ">{user.email}</div>
    <div className="text-slate-400 text-sm text-right">Dernière connexion il y a {displayDuration(new Date(user.last_connexion?.seconds*1000), new Date())}</div>

  </div>
  
  {/* <div>{user.last_workspace_id}</div> */}
  <div >
    <div className="gap-2 flex flex-wrap ">{userWorkspaces?.filter(uwp => uwp.user_id == user.id).map(uwp => <div className="px-2 py-[4px] items-center bg-white/40 mb-1 flex gap-2 rounded border">
      <img src={workspaces?.find(w => w.id == uwp.workspace_id).img} className="h-[20px] rounded-full"/>
      <div className="capitalize">{workspaces?.find(w => w.id == uwp.workspace_id).name}</div>
      <div className="text-sm text-slate-500">{displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpend*1000) ))}</div>
      </div>)}</div>
  </div>

  <div className="text-red-500 text-right" onClick={() => {deleteUser(user)}}>Supprimer</div>
  </div>

const renderUserWorkspace = (uwp) => {
  const user = users.find(u => u.id == uwp.user_id)
  if (!user) return
return <div onClick={() => console.log('uwp', uwp)} className="p-2 mb-2 border-t group ">
<div className="flex gap-2 justify-between items-center">

  <div className="flex gap-2">
    <img src={workspaces?.find(w => w.id == uwp.workspace_id).img} className="h-[20px] mt-1 rounded-full"/>
    <div>
      <div>{user.name} {uwp.streak && uwp.lastStreakDate.toDate() > new Date(Date.now() - 24 * 60 * 60 * 1000) && <span className="text-amber-500">{uwp.streak} 🔥</span>}</div>
      <div className="text-slate-500 text-sm grow ">{user.email}</div>
    </div>
  </div>
  <div className="text-right">
    <div className="text-slate-400 group-hover:block hidden text-sm text-right">le {displayDate(uwp.lastStreakDate?.toDate())}</div>

    <div className="text-slate-400 group-hover:hidden text-sm text-right">Dernière connexion il y a {displayDuration(uwp.lastStreakDate?.toDate(), new Date())}</div>
    <div className="text-sm text-slate-500">Temps passé : 
    {displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpend*1000) ))} 
     <span className="ml-1 text-green-500">{displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpendForest*1000) ))}</span>
    </div>
  </div>
</div>

{/* <div>{user.last_workspace_id}</div> */}
{/* <div >
  <div className="px-2 py-[4px] items-center bg-white/40 mb-1 flex gap-2 rounded border">
  
    <div className="capitalize">{workspaces?.find(w => w.id == uwp.workspace_id).name}</div>
    </div>
</div> */}

{/* <div className="text-red-500 text-right" onClick={() => {deleteUser(uwp)}}>Supprimer</div> */}
</div>
}
const fetchSchools = async () => (await getDocs(query(collection(db, "schools"), limit(1000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
const fetchClasses = async (id) => (await getDocs(query(collection(db, "class"), where("admin", "array-contains", {role: "admin", user_id: id }), limit(1000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
const fetchUser = async () => (await getDocs(query(collection(db, "users"), limit(5000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
const fetchUserWorkspace = async () => (await getDocs(query(collection(db, "user_workspace"), limit(5000)))).docs.map(doc => ({...doc.data(), id: doc.id}));


const deleteUser = async(user) => {
  console.log('deleteUser 🟢', user)
  console.log('user id', user.id)
  const batch = writeBatch(db);
  // deletes user_cards where user_card.user_id == user.id
  const q = query( collection(db, "user_cards"), where("user_id", "==", user.id))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    console.log('delete user_card')
    deleteDoc(doc.ref);
  });

  const qDeck = query( collection(db, "user_deck"), where("user_id", "==", user.id))
  const querySnapshotDeck = await getDocs(qDeck)
  querySnapshotDeck.forEach((doc) => {
    console.log('delete user_deck')
    deleteDoc(doc.ref);
  });


  const uwps = await getDocs(query(collection(db, "user_workspace"), where("user_id", "==", user.id))).docs?.map(doc => ({...doc.data(), id: doc.id}))

  const q1 = query(collection(db, 'user_workspace'), where('user_id', "==", user.id))
  const querySnapshot1 = await getDocs(q1);
  const _userWorkspaces = querySnapshot1.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('_userWorkspaces', _userWorkspaces)

  console.log('uwps', uwps)
  _userWorkspaces?.forEach(uwp => {
    console.log('delete user wp', uwp)
    batch.delete(doc(db, "user_workspace", uwp.id));
  })

   batch.delete(doc(db, "users", user.id));
   batch.commit();
}


useEffect(() => {
    if (selectedClass?.id) {
      const q = query(collection(db, "users"), where("class", "array-contains", selectedClass.id));
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const workspaces = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        // Process 'workspaces' data as needed
        console.log(workspaces);
        setUsers(workspaces)
      });
  
      // Clean up the subscription
      return () => unsubscribe();
    }
  }, [selectedClass]);


  useEffect(() => {
      async function fetchWorkspaces() {
        const retrievedWorkspaces = await ct.fire.getWorkspaces();
        setWorkspaces(retrievedWorkspaces);
        setWorkspace(retrievedWorkspaces[0]);
      }

      ct.setShowBottomBar(false)
  
      fetchWorkspaces();
    }, []);

    console.log('classes', classes)
let params = new URLSearchParams(document.location.search);
let id = params.get("id"); // is the string "Jonathan"

  useEffect(() => {
    console.clear()
    console.log("MySchool loaded", id)
    fetchSchools().then(data => setSchools(data))
    fetchClasses(id).then(data => setClasses(data));
}, [])




  
  return (
    <div className={`fredoka h-screen overflow-scroll`}>
   {!selectedClass && <header className='p-4 fixed right-0 left-0 bg-white flex gap-2 border-b z-10 justify-between text-slate-400 items-center'>

        <div className="text-xl text-slate-500">Mes classes</div>
        <div>{ct.user?.name}</div>
        {/* <div className="flex gap-2 items-center">
            <div className="flex gap-2  px-4 rounded ">
                <img src="images/icons/icon_rounded.png" className="h-[42px]"/> 
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <div className="flex gap-2  px-4 rounded ">
                <img src="https://d9hhrg4mnvzow.cloudfront.net/info.vivamundi.fr/20942887-viva-mundi-logo-rvb-1.svg" className="-mb-[16px] h-[50px]"/> 

            </div>
        </div>    */}
    </header> }
    {/* <aside className="fixed mt-[70px] overflow-scroll pb-[300px] left-0 text-slate-500 bg-amber-100/10 w-[200px] border-r p-4 h-screen">
        {!workspace && workspaces?.filter(w => w.img).map(wp => 
            <div onClick={() => setWorkspace(wp)} className={"capitalize p-2 rounded-md flex items-center gap-2"}><img src={wp.img} className="h-[24px] rounded-full" />{wp.name}</div>)}
        {workspace && <div className="capitalize flex gap-2 justify-between items-center text-md cursor-pointer" onClick={async() =>{
            setWorkspace()
        }}>
         <div className="flex items-center gap-2"><img src={workspace?.img} className="h-[30px] rounded-full" />{workspace?.name} </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
             </svg>
        </div>}
        <hr className="my-8"/>
     
    </aside> */}
   {!selectedClass && <main className="ml-[200px] pb-[200px] mt-[90px] p-8">
       <h1>Mes classes</h1>
       {
  // First, group the classes by school_id
  classes?.reduce((acc, c) => {
    acc[c.school_id] = acc[c.school_id] || [];
    acc[c.school_id].push(c);
    return acc;
  }, {}) && Object.entries(classes?.reduce((acc, c) => {
    acc[c.school_id] = acc[c.school_id] || [];
    acc[c.school_id].push(c);
    return acc;
  }, {})).map(([schoolId, classes]) => (
    <div key={schoolId}>
      <div className="font-bold mt-4 text-2xl mb-1">{schools.find(s => s.id == schoolId)?.name}</div>
      <div className="flex gap-2 flex-wrap">{classes.map(c => (
        <div onClick={() => setSelectedClass(c)} key={c.name} className="p-2 mb-2 border hover:scale-110 cursor-pointer bg-purple-500 text-white rounded-xl ">
          {c.name}
        </div>
      ))}</div>
    </div>
  ))
}

    </main> }

    {selectedClass && <main className="min-h-[100vh] bg-purple-500 text-white pb-[200px] ">
    <div className="bg-white/20 mt-8 ml-8  px-4 text-xl inline-block rounded-md py-1" onClick={() => setSelectedClass()}>← Retour</div>

        <br/>
        <div className="py-8 p-8 border-b flex items-center justify-between">
          <h1 className="text-3xl text-white">{selectedClass.name}</h1>
          <div>Code d'accès : <span className="text-6xl text-amber-400 font-bold ">{selectedClass.code}</span></div>
        </div>
        <div className="p-8 ">
          <div className="text-2xl mb-4 mt-8 flex items-center gap-2">Liste des utilisateurs
          <div className="flex px-4 gap-3  bg-white/20 rounded-md items-center py-1"> {users.length} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
</svg></div>
</div>
          
          {users.map(u => <div className="flex gap-4 justify-between px-4">
            <div className="flex gap-4 items-end">
              <p className="text-xl w-[250px]">{u.name}</p>
              <p className="text-xs ">{u.email}</p>
            </div>
            <div>Note de participation : - / 5</div>
            </div>)}
        </div>
        </main>}
    </div>
  );
};

export {TeacherScreen};