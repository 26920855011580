


const notificationNRV = {
    remind1: [
        "Oh @name! Tu as du boulot ! Ton @lang ne va pas s'apprendre tout seul.",
        'Tu te souviens de ce que "Arschloch" veut dire ? Bah non, vu que tu ne l\'as pas appris ! Allez, viens vite !',
        "Arrête de scroller sur Insta, viens plutôt faire quelque chose d'utile !",
        "Tu fais quoi là ? Eh bien, pas ton @lang en tout cas ! Allez, viens réviser sinon ça va barder.",
        "Hep Hep Hep ! Viens faire ta mission du jour, feignasse !",
        "Go Go Go ! Allez, on se sort les doigts du cul et on va apprendre @lang !",
        "10 min par jour, ce n'est pas la mort ! Allez, viens faire ta leçon ! Espèce de saloperie.",
        "Allez @name ! Ce n'est pas en glandant que tu vas devenir bilingue. Lance cette leçon, maintenant !",
        "Nom de dieu, @name ! Tu ne vas pas me faire croire que tu as autre chose à faire que de lancer cette leçon !",
        "@name ! MO-TI-VA-TION ! Viens faire ta leçon ",
        "Allez, champions, ce n'est pas le moment de flancher !"
    ],
    remind2: [
      
        "Qui est la feignasse qui n'a pas fait sa leçon hier ? C'est toi ! Viens vite sinon ça va barder.",
        "De toute ma carrière, je n'ai encore jamais vu une telle feignasse. Montre-moi que tu vaux mieux que ça.",
        "@name, si tu ne fais pas ta leçon aujourd'hui, je te jure que je te botte les fesses !",
    ],
    lastRemind: [
        "@name ? Ça y est, tu abandonnes déjà ? 10 min par jour, c'est trop pour toi ?",
        "Ça y est, tu as fini ta petite pause ? Rends-moi fière, fiston, et viens faire cette leçon."
    ],
}


const notificationChill = {
    remind1: [
        "Hey, il y a du nouveau dans ton apprentissage de @lang ! Pas de pression, mais une petite révision ne ferait pas de mal. 😉",
        "T'as des nouveaux mots à apprendre aujourd'hui, on se lance ?",
        "Pause le scroll sur Insta et fais le plein de connaissances à la place. Ton @lang t'attend !",
        "On dirait que tu as un moment de libre – c'est le moment parfait pour un peu de @lang !",
        "C'est l'heure de briller ! Prêt pour ta mission linguistique du jour ?",
        "Top départ pour une session express de @lang ! Juste 10 minutes, promis.",
        "10 minutes de @lang par jour, et tu deviendras un boss. On commence quand tu veux !",
        "Allez @name, on a tous besoin d'une pause productive. Que dirais-tu d'une petite leçon de @lang ?",
        "C'est l'heure, @name ! Ready to learn  ?",    
    ],
    remind2: [
        "Petit rappel : ta leçon de @lang d'hier t'attend toujours. Pas de stress, on y va à ton rythme !",
        "Je sais, je sais, parfois on préférerait glander... mais imagine la satisfaction après ta leçon de @lang ! Alors, on s'y met ?",
        "@name, une leçon de @lang et on est bons ! Tu ne le regretteras pas, je te le promets !",
        "Si tu viens pas faire tes leçons je vais être obligé de le dire un coach un peu plus sévère que moi !",
    ],
    lastRemind: [
        "@name, tu abandonnes déjà ? Ce n'est pas ton genre. Une petite session de 10 minutes, ça te dit ?",
        "La pause, c'est terminé ! Allons-y pour un petit boost de @lang. Tu vas être top !",
        "@name, ça fait plus de 4 jours que tu n'apprends plus rien. Je commence à me remettre en question..",
    ],
}



const coachs = [{
    id: 0,
    name: "Pascal",
    sub: "le coach qui te botte le cul",
    img: "/images/UX/coach_pascal.png",
    description: "Élevé à la dure, il ne fait pas dans la tendresse. Attends-toi à des messages bien épicés",
    exemple: "Hep hep hep, viens faire ta leçon, espèce de feignasse",
    color: "text-red-300",
    fill: "#ffcfaf",
    notifications: notificationNRV, 
    img_round: "https://mindseed.app/images/UX/pascal_mini.png"
}, 
{
    id: 1,
    name: "Basil",
    sub: "toujours le mot pour te motiver",
    img: "/images/UX/coach_olivier.png",
    description: "Il est gentil, mais il n'est pas là pour rigoler. Il va te motiver à fond.",
    exemple: "Hello @name, n'oublie pas ton objectif ! Ta leçon du jour t'attend !",
    fill: "#f1e2ff",
    color: "text-purple-300",
    notifications: notificationChill,
    img_round: "https://mindseed.app/images/UX/basil_mini.png"
}]



export { notificationNRV, notificationChill, coachs}