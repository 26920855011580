const paretoCards_en = [
    {
        "wt": "v",
        "infexions": [
            "be",
            "am",
            "are",
            "been",
            "being",
            "is",
            "was",
            "were"
        ],
        "term": "be",
        "inflexion": "be, am, are, been, being, is, was, were",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "be",
            "am",
            "are",
            "been",
            "being",
            "is",
            "was",
            "were"
        ],
        "rank": 0,
        "id": "MUxtZX42kR4QwCQhKTf5"
    },
    {
        "isPareto": true,
        "infexions": [
            "I"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "isVisual": true,
        "rank": 1,
        "inflexion": "I",
        "inflexions": [
            "I"
        ],
        "wt": "fw",
        "workspace_id": "anglais",
        "term": "I",
        "id": "YRopdYfp2leQOKFihCiW"
    },
    {
        "inflexions": [
            "you"
        ],
        "isPareto": true,
        "infexions": [
            "you"
        ],
        "rank": 2,
        "term": "you",
        "wt": "fw",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "context": "Hello, so do you know what we're going to do at the beach?",
        "isVisual": true,
        "id": "lbt8MiCVNjhGhlgWV4hW",
        "inflexion": "you",
        "order": 0,
        "model": "lang-en",
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "the"
        ],
        "isPareto": true,
        "infexions": [
            "the"
        ],
        "wt": "fw",
        "inflexion": "the",
        "workspace_id": "anglais",
        "term": "the",
        "model": "lang-en",
        "rank": 3,
        "id": "ZPY651mLIrqhCyompvY6"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "wt": "fw",
        "model": "lang-en",
        "inflexion": "a, an",
        "rank": 4,
        "infexions": [
            "a",
            "an"
        ],
        "workspace_id": "anglais",
        "term": "a",
        "inflexions": [
            "a",
            "an"
        ],
        "id": "mFwuIKgw217Q8znVEaO5"
    },
    {
        "model": "lang-en",
        "term": "to",
        "isPareto": true,
        "wt": "fw",
        "rank": 5,
        "inflexion": "to",
        "lang": "en-fr",
        "inflexions": [
            "to"
        ],
        "infexions": [
            "to"
        ],
        "workspace_id": "anglais",
        "id": "rMLJbm0LZyBQpbmsifhj"
    },
    {
        "model": "lang-en",
        "wt": "fw",
        "isPareto": true,
        "term": "it",
        "inflexions": [
            "it"
        ],
        "infexions": [
            "it"
        ],
        "inflexion": "it",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 6,
        "id": "Tx6Js1VCZYIUva3JUyQ2"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "not"
        ],
        "infexions": [
            "not"
        ],
        "inflexion": "not",
        "term": "not",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 7,
        "wt": "fw",
        "id": "DPImT0sz3BGNHmNiqsfW"
    },
    {
        "model": "lang-en",
        "inflexion": "that, those",
        "inflexions": [
            "that",
            "those"
        ],
        "infexions": [
            "that",
            "those"
        ],
        "wt": "fw",
        "term": "that",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 8,
        "isPareto": true,
        "id": "LAxaZEzbjd0Ks1P1Tru8"
    },
    {
        "term": "and",
        "workspace_id": "anglais",
        "wt": "fw",
        "inflexion": "and",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "and"
        ],
        "infexions": [
            "and"
        ],
        "model": "lang-en",
        "rank": 9,
        "id": "t9Q46LYmrdKnozjy70ok"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 10,
        "inflexions": [
            "of"
        ],
        "term": "of",
        "wt": "fw",
        "inflexion": "of",
        "lang": "en-fr",
        "infexions": [
            "of"
        ],
        "model": "lang-en",
        "id": "PnQ4kF6U1W013DCJovr5"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "do",
            "did",
            "does",
            "doing",
            "done"
        ],
        "isPareto": true,
        "term": "do",
        "inflexion": "do, did, does, doing, done",
        "inflexions": [
            "do",
            "did",
            "does",
            "doing",
            "done"
        ],
        "model": "lang-en",
        "wt": "v",
        "rank": 11,
        "id": "MghijvIoCEMwwGicgklq"
    },
    {
        "isPareto": true,
        "term": "have",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "have",
            "had",
            "has",
            "having"
        ],
        "infexions": [
            "have",
            "had",
            "has",
            "having"
        ],
        "rank": 12,
        "workspace_id": "anglais",
        "wt": "v",
        "inflexion": "have, had, has, having",
        "id": "44OQuiWFG0IeyndY2PZh"
    },
    {
        "inflexion": "what",
        "lang": "en-fr",
        "term": "what",
        "isPareto": true,
        "wt": "fw",
        "rank": 13,
        "inflexions": [
            "what"
        ],
        "infexions": [
            "what"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "1rdBwnDdqKd1PozgdBBj"
    },
    {
        "lang": "en-fr",
        "rank": 14,
        "inflexions": [
            "we"
        ],
        "infexions": [
            "we"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "we",
        "wt": "fw",
        "term": "we",
        "id": "hxqfxJUYI8srKIeG7Xrm"
    },
    {
        "rank": 15,
        "workspace_id": "anglais",
        "term": "in",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "in"
        ],
        "wt": "fw",
        "inflexion": "in",
        "inflexions": [
            "in"
        ],
        "isPareto": true,
        "id": "bBNbS2cIuMz00Mf9L01e"
    },
    {
        "inflexions": [
            "get",
            "gets",
            "getting",
            "got",
            "gotten"
        ],
        "isPareto": true,
        "inflexion": "get, gets, getting, got, gotten",
        "model": "lang-en",
        "rank": 16,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "get",
            "gets",
            "getting",
            "got",
            "gotten"
        ],
        "wt": "v",
        "term": "get",
        "id": "v2cKkvTst2FjKm2cwlgi"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "fw",
        "term": "this",
        "infexions": [
            "this",
            "these"
        ],
        "inflexions": [
            "this",
            "these"
        ],
        "model": "lang-en",
        "rank": 17,
        "inflexion": "this, these",
        "isPareto": true,
        "id": "NX7Qkde8Ot3FLKRuFR2J"
    },
    {
        "term": "my",
        "inflexions": [
            "my",
            "mine"
        ],
        "rank": 18,
        "workspace_id": "anglais",
        "infexions": [
            "my",
            "mine"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "my, mine",
        "wt": "fw",
        "id": "Gfc131N3ADWYzutnua4l"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "me",
        "isPareto": true,
        "wt": "fw",
        "model": "lang-en",
        "infexions": [
            "me"
        ],
        "term": "me",
        "lang": "en-fr",
        "inflexions": [
            "me"
        ],
        "rank": 19,
        "id": "bEgxZk6sVs7Y4QZdz2hF"
    },
    {
        "term": "go",
        "lang": "en-fr",
        "infexions": [
            "go",
            "goes",
            "going",
            "gone",
            "went"
        ],
        "model": "lang-en",
        "rank": 20,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "go",
            "goes",
            "going",
            "gone",
            "went"
        ],
        "inflexion": "go, goes, going, gone, went",
        "wt": "v",
        "id": "elM2owUTVuRAICaHZ4xx"
    },
    {
        "inflexions": [
            "can",
            "could",
            "cannot"
        ],
        "wt": "v",
        "rank": 21,
        "inflexion": "can, could, cannot",
        "isPareto": true,
        "term": "can",
        "infexions": [
            "can",
            "could",
            "cannot"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "gpkva5oud7WQVC1E1DHc"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "fw",
        "term": "no",
        "inflexions": [
            "no"
        ],
        "model": "lang-en",
        "infexions": [
            "no"
        ],
        "inflexion": "no",
        "rank": 22,
        "lang": "en-fr",
        "id": "KkrYhe6gvBK1SiJiDEbh"
    },
    {
        "wt": "fw",
        "term": "on",
        "rank": 23,
        "inflexions": [
            "on"
        ],
        "infexions": [
            "on"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "on",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "dtSQJn4cAmI9ciU2HmF6"
    },
    {
        "term": "for",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 24,
        "infexions": [
            "for"
        ],
        "inflexions": [
            "for"
        ],
        "inflexion": "for",
        "wt": "fw",
        "isPareto": true,
        "model": "lang-en",
        "id": "YpzGX5oZPqBsg7tQKpks"
    },
    {
        "order": 0,
        "wt": "v",
        "isPareto": true,
        "rank": 25,
        "lang": "en-fr",
        "inflexion": "know, knew, knowing, known, knows",
        "trad": "savoir",
        "context": "Hello, so do you know what we're going to do at the beach?",
        "def_img": "https://media1.giphy.com/media/d3mlE7uhX8KFgEmY/giphy.gif?cid=ecf05e47no0nf7z3o1qhon3q3idu4navkkug90iu6cvjwnou&rid=giphy.gif&ct=g",
        "workspace_id": "anglais",
        "infexions": [
            "know",
            "knew",
            "knowing",
            "known",
            "knows"
        ],
        "term": "know",
        "inflexions": [
            "know",
            "knew",
            "knowing",
            "known",
            "knows"
        ],
        "model": "lang-en",
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW",
        "id": "chGTJ5mUkm2jYHTyBPPD"
    },
    {
        "term": "just",
        "isPareto": true,
        "infexions": [
            "just"
        ],
        "inflexions": [
            "just"
        ],
        "workspace_id": "anglais",
        "wt": "r",
        "rank": 26,
        "lang": "en-fr",
        "inflexion": "just",
        "model": "lang-en",
        "id": "rUYoXdckf80keqe1bokF"
    },
    {
        "rank": 27,
        "inflexions": [
            "your"
        ],
        "inflexion": "your",
        "workspace_id": "anglais",
        "term": "your",
        "isPareto": true,
        "infexions": [
            "your"
        ],
        "lang": "en-fr",
        "wt": "fw",
        "model": "lang-en",
        "id": "w7pEpwJLiX72CjwLXxYM"
    },
    {
        "term": "all",
        "rank": 28,
        "workspace_id": "anglais",
        "inflexions": [
            "all"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "all"
        ],
        "wt": "fw",
        "inflexion": "all",
        "model": "lang-en",
        "id": "u3uQDG7sDImrje6RdCvM"
    },
    {
        "deck_id": "",
        "inflexion": "so",
        "inflexions": [
            "so"
        ],
        "term": "so",
        "infexions": [
            "so"
        ],
        "workspace_id": "anglais",
        "order": 0,
        "rank": 29,
        "lang": "en-fr",
        "wt": "r",
        "isPareto": true,
        "model": "lang-en",
        "id": "GMQ7BzA4GnP1MNurJ5ro"
    },
    {
        "term": "with",
        "lang": "en-fr",
        "rank": 30,
        "infexions": [
            "with"
        ],
        "isPareto": true,
        "inflexions": [
            "with"
        ],
        "inflexion": "with",
        "wt": "fw",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "Rv6pqquKAfeZR3c6eevK"
    },
    {
        "wt": "fw",
        "term": "he",
        "rank": 31,
        "inflexions": [
            "he"
        ],
        "infexions": [
            "he"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "he",
        "id": "x3JVXXFJs9B4MjNlafNH"
    },
    {
        "term": "but",
        "isPareto": true,
        "rank": 32,
        "lang": "en-fr",
        "wt": "fw",
        "inflexion": "but",
        "model": "lang-en",
        "inflexions": [
            "but"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "but"
        ],
        "id": "NPmZJ5kt7Vxrt3KUrfKk"
    },
    {
        "infexions": [
            "well"
        ],
        "isPareto": true,
        "wt": "r",
        "model": "lang-en",
        "inflexions": [
            "well"
        ],
        "workspace_id": "anglais",
        "term": "well",
        "lang": "en-fr",
        "inflexion": "well",
        "rank": 33,
        "id": "w1ixLpMIluo3AwNEdLCc"
    },
    {
        "term": "think",
        "wt": "v",
        "rank": 34,
        "isPareto": true,
        "inflexion": "think, thinking, thinks, thought",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "think",
            "thinking",
            "thinks",
            "thought"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "think",
            "thinking",
            "thinks",
            "thought"
        ],
        "id": "5Vsdk96W5Xk7nTjLrjS7"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 35,
        "term": "here",
        "isPareto": true,
        "inflexions": [
            "here"
        ],
        "infexions": [
            "here"
        ],
        "wt": "r",
        "lang": "en-fr",
        "inflexion": "here",
        "id": "zZAz4mGwF1jJdnuvYBhN"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "want, wanted, wanting, wants",
        "rank": 36,
        "model": "lang-en",
        "infexions": [
            "want",
            "wanted",
            "wanting",
            "wants"
        ],
        "inflexions": [
            "want",
            "wanted",
            "wanting",
            "wants"
        ],
        "lang": "en-fr",
        "wt": "v",
        "term": "want",
        "isPareto": true,
        "id": "M5ytghqTPkRemRQ7V6TA"
    },
    {
        "lang": "en-fr",
        "wt": "r",
        "model": "lang-en",
        "inflexion": "out",
        "isPareto": true,
        "infexions": [
            "out"
        ],
        "term": "out",
        "inflexions": [
            "out"
        ],
        "rank": 37,
        "workspace_id": "anglais",
        "id": "kcmDWcJjpiVFPBiDNnJS"
    },
    {
        "term": "about",
        "isPareto": true,
        "inflexion": "about",
        "inflexions": [
            "about"
        ],
        "infexions": [
            "about"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 38,
        "model": "lang-en",
        "wt": "fw",
        "id": "0ye9MUgGxwXFdFZ7gBvf"
    },
    {
        "wt": "j",
        "workspace_id": "anglais",
        "rank": 39,
        "isVisual": true,
        "lang": "en-fr",
        "inflexion": "good, better, best",
        "model": "lang-en",
        "isPareto": true,
        "term": "good",
        "inflexions": [
            "good",
            "better",
            "best"
        ],
        "infexions": [
            "good",
            "better",
            "best"
        ],
        "id": "Kp9bUd9COrbQ11vPg1rK"
    },
    {
        "infexions": [
            "come",
            "came",
            "comes",
            "coming"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "come",
            "came",
            "comes",
            "coming"
        ],
        "lang": "en-fr",
        "wt": "v",
        "inflexion": "come, came, comes, coming",
        "term": "come",
        "rank": 40,
        "id": "rZKbV4w2DC1SCmGNNmJX"
    },
    {
        "rank": 41,
        "isPareto": true,
        "lang": "en-fr",
        "term": "up",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "r",
        "inflexion": "up",
        "infexions": [
            "up"
        ],
        "isVisual": true,
        "inflexions": [
            "up"
        ],
        "id": "MyBhUPhMEx8B5GmXkf2p"
    },
    {
        "inflexions": [
            "say",
            "said",
            "saying",
            "says"
        ],
        "term": "say",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "say",
            "said",
            "saying",
            "says"
        ],
        "inflexion": "say, said, saying, says",
        "lang": "en-fr",
        "wt": "v",
        "rank": 42,
        "isPareto": true,
        "id": "pVioXFi5LHPGicBH5Bez"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "now"
        ],
        "term": "now",
        "workspace_id": "anglais",
        "inflexion": "now",
        "rank": 43,
        "inflexions": [
            "now"
        ],
        "lang": "en-fr",
        "wt": "r",
        "id": "HOWMUFPKJWNidU7ybDW7"
    },
    {
        "rank": 44,
        "inflexion": "at",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "at"
        ],
        "model": "lang-en",
        "wt": "fw",
        "workspace_id": "anglais",
        "term": "at",
        "infexions": [
            "at"
        ],
        "id": "UQjTL8EYYJ9xR3z3zmZW"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "one, ones",
        "wt": "m",
        "rank": 45,
        "term": "one",
        "infexions": [
            "one",
            "ones"
        ],
        "isVisual": true,
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "one",
            "ones"
        ],
        "model": "lang-en",
        "id": "uCHDHnTYmMkUQbpHt9u1"
    },
    {
        "wt": "u",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "hey"
        ],
        "term": "hey",
        "inflexions": [
            "hey"
        ],
        "inflexion": "hey",
        "rank": 46,
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "z7OA6R6qUMZxweQO49v2"
    },
    {
        "inflexion": "they",
        "wt": "fw",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "term": "they",
        "inflexions": [
            "they"
        ],
        "infexions": [
            "they"
        ],
        "workspace_id": "anglais",
        "rank": 47,
        "id": "t2jcyZ9Q2tpioUJEMUUu"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "see",
            "saw",
            "seeing",
            "seen",
            "sees"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "see, saw, seeing, seen, sees",
        "rank": 48,
        "wt": "v",
        "term": "see",
        "infexions": [
            "see",
            "saw",
            "seeing",
            "seen",
            "sees"
        ],
        "workspace_id": "anglais",
        "id": "XVS1vB5fWsTm0kxfvLha"
    },
    {
        "rank": 49,
        "infexions": [
            "if"
        ],
        "wt": "fw",
        "model": "lang-en",
        "inflexion": "if",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "if",
        "inflexions": [
            "if"
        ],
        "id": "iftCe31ZNRQr14SzIYXl"
    },
    {
        "inflexions": [
            "how"
        ],
        "wt": "r",
        "model": "lang-en",
        "term": "how",
        "workspace_id": "anglais",
        "infexions": [
            "how"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "rank": 50,
        "inflexion": "how",
        "id": "ii0EE4vAG6hNU8dYzc5X"
    },
    {
        "infexions": [
            "like",
            "liked",
            "likes",
            "liking"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "like",
        "rank": 51,
        "wt": "v",
        "inflexions": [
            "like",
            "liked",
            "likes",
            "liking"
        ],
        "lang": "en-fr",
        "inflexion": "like, liked, likes, liking",
        "isVisual": true,
        "def_img": "https://media2.giphy.com/media/2tSodgDfwCjIMCBY8h/giphy.gif?cid=ecf05e47m85tis4h6fa46683x8kkgnv6qmzseqam0g24nsev&rid=giphy.gif&ct=g",
        "isPareto": true,
        "id": "8wTtCcsoXKt21WMMrX3E"
    },
    {
        "rank": 52,
        "inflexions": [
            "she"
        ],
        "inflexion": "she",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "she"
        ],
        "wt": "fw",
        "isPareto": true,
        "term": "she",
        "workspace_id": "anglais",
        "id": "n6ljlud5B6A6DTYVL0Xw"
    },
    {
        "rank": 53,
        "workspace_id": "anglais",
        "infexions": [
            "look",
            "looked",
            "looking",
            "looks"
        ],
        "isPareto": true,
        "isVisual": true,
        "inflexions": [
            "look",
            "looked",
            "looking",
            "looks"
        ],
        "def_img": "https://media4.giphy.com/media/OAi4cRZ4vWJ5jnbAJ0/giphy.gif?cid=ecf05e47w25t8i5yrhpwkh8u6qooylf494ro021iam57hufe&rid=giphy.gif&ct=g",
        "model": "lang-en",
        "inflexion": "look, looked, looking, looks",
        "lang": "en-fr",
        "wt": "v",
        "term": "look",
        "id": "eOVOiFTQX6uFcIpxk0W2"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "make, made, makes, making",
        "term": "make",
        "wt": "v",
        "lang": "en-fr",
        "infexions": [
            "make",
            "made",
            "makes",
            "making"
        ],
        "rank": 54,
        "inflexions": [
            "make",
            "made",
            "makes",
            "making"
        ],
        "id": "6sIOdTccqNpgXSkdt462"
    },
    {
        "isVisual": true,
        "model": "lang-en",
        "rank": 55,
        "wt": "j",
        "inflexion": "right",
        "inflexions": [
            "right"
        ],
        "term": "right",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "right"
        ],
        "id": "nVQtgFjl9VLtpIjyhFQF"
    },
    {
        "inflexion": "guy, guys",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "rank": 56,
        "infexions": [
            "guy",
            "guys"
        ],
        "wt": "n",
        "inflexions": [
            "guy",
            "guys"
        ],
        "isVisual": false,
        "term": "guy",
        "workspace_id": "anglais",
        "id": "fUIAw8eJReZaL95m8lXX"
    },
    {
        "inflexion": "take, taken, takes, taking, took",
        "term": "take",
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "take",
            "taken",
            "takes",
            "taking",
            "took"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "take",
            "taken",
            "takes",
            "taking",
            "took"
        ],
        "rank": 57,
        "id": "HS62HGRbbIwZSgq4rDhu"
    },
    {
        "term": "let",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "wt": "v",
        "rank": 58,
        "inflexions": [
            "let",
            "lets",
            "letting"
        ],
        "infexions": [
            "let",
            "lets",
            "letting"
        ],
        "inflexion": "let, lets, letting",
        "id": "lYuxmNaZjgsaymcWOW9g"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "really"
        ],
        "infexions": [
            "really"
        ],
        "wt": "r",
        "model": "lang-en",
        "inflexion": "really",
        "term": "really",
        "lang": "en-fr",
        "rank": 59,
        "id": "8dRo3xIx7TgF5PbDnCJz"
    },
    {
        "inflexion": "her",
        "wt": "fw",
        "model": "lang-en",
        "infexions": [
            "her"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "her"
        ],
        "isPareto": true,
        "rank": 60,
        "term": "her",
        "lang": "en-fr",
        "id": "6ukUwbeumCN3nfglMdp7"
    },
    {
        "workspace_id": "anglais",
        "rank": 61,
        "term": "tell",
        "model": "lang-en",
        "inflexions": [
            "tell",
            "telling",
            "tells",
            "told"
        ],
        "wt": "v",
        "inflexion": "tell, telling, tells, told",
        "isPareto": true,
        "infexions": [
            "tell",
            "telling",
            "tells",
            "told"
        ],
        "lang": "en-fr",
        "id": "CFx6Ssrwb0m9dZFJDRYX"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "fw",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "him"
        ],
        "inflexions": [
            "him"
        ],
        "term": "him",
        "inflexion": "him",
        "rank": 62,
        "id": "bFklzavBlCwKhUPB3loB"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "why"
        ],
        "term": "why",
        "rank": 63,
        "isPareto": true,
        "wt": "r",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "why"
        ],
        "inflexion": "why",
        "id": "jittED9DKpmZJayZsuH3"
    },
    {
        "inflexions": [
            "there"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 64,
        "inflexion": "there",
        "wt": "r",
        "model": "lang-en",
        "lang": "en-fr",
        "term": "there",
        "infexions": [
            "there"
        ],
        "id": "dwDfT1MUdXvQJB297sdT"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "wt": "fw",
        "inflexions": [
            "who"
        ],
        "infexions": [
            "who"
        ],
        "term": "who",
        "rank": 65,
        "workspace_id": "anglais",
        "inflexion": "who",
        "model": "lang-en",
        "id": "c6GnAKMdCoMMPhE8Guwf"
    },
    {
        "workspace_id": "anglais",
        "rank": 66,
        "wt": "n",
        "inflexions": [
            "time",
            "times"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "time",
            "times"
        ],
        "term": "time",
        "isVisual": true,
        "isPareto": true,
        "def_img": "https://media0.giphy.com/media/l0MYOUI5XfRk4LLWM/giphy.gif?cid=ecf05e47w156l7on7ngzmcj4pk5eeoj0q7wgmtslhmp977u7&rid=giphy.gif&ct=g",
        "inflexion": "time, times",
        "id": "n7PQiWTDIRb7hnSGemEE"
    },
    {
        "rank": 67,
        "inflexion": "thing, things",
        "inflexions": [
            "thing",
            "things"
        ],
        "model": "lang-en",
        "isPareto": true,
        "term": "thing",
        "wt": "n",
        "infexions": [
            "thing",
            "things"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "h23pFlmtIXWhKY3pLOiz"
    },
    {
        "inflexions": [
            "from"
        ],
        "term": "from",
        "model": "lang-en",
        "isPareto": true,
        "rank": 68,
        "infexions": [
            "from"
        ],
        "wt": "fw",
        "workspace_id": "anglais",
        "inflexion": "from",
        "lang": "en-fr",
        "id": "7tvZYSlrashMEPRlxctG"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 69,
        "inflexions": [
            "will",
            "willing",
            "would"
        ],
        "term": "will",
        "lang": "en-fr",
        "inflexion": "will, willing, would",
        "model": "lang-en",
        "infexions": [
            "will",
            "willing",
            "would"
        ],
        "wt": "v",
        "id": "NZvqzaQwFkSQXmjXOvlF"
    },
    {
        "id": "1wvzYe77kVAcdfRGDRmg",
        "inflexions": [
            "like"
        ],
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW",
        "infexions": [
            "like"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "like",
        "context": "Maybe play some beach volleyball or something like that.",
        "model": "lang-en",
        "rank": 70,
        "order": 6,
        "inflexion": "like",
        "lang": "en-fr",
        "wt": "r"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "when"
        ],
        "isPareto": true,
        "term": "when",
        "rank": 71,
        "wt": "r",
        "model": "lang-en",
        "inflexions": [
            "when"
        ],
        "inflexion": "when",
        "lang": "en-fr",
        "id": "RqPne8nwmGJ7WH8aXfLu"
    },
    {
        "term": "as",
        "infexions": [
            "as"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "as"
        ],
        "wt": "fw",
        "rank": 72,
        "isPareto": true,
        "inflexion": "as",
        "lang": "en-fr",
        "id": "r5QbsVS6tDxJaYqAmPeW"
    },
    {
        "inflexions": [
            "because"
        ],
        "inflexion": "because",
        "rank": 73,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "because"
        ],
        "workspace_id": "anglais",
        "term": "because",
        "wt": "fw",
        "isPareto": true,
        "id": "Y2sR4Kn46goWdXTbjJe4"
    },
    {
        "workspace_id": "anglais",
        "rank": 74,
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "some"
        ],
        "wt": "fw",
        "isPareto": true,
        "term": "some",
        "inflexion": "some",
        "inflexions": [
            "some"
        ],
        "id": "Wz66SPhfIezcGIJxlRFn"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "our"
        ],
        "inflexion": "our",
        "inflexions": [
            "our"
        ],
        "wt": "fw",
        "rank": 75,
        "term": "our",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "id": "jBkdYD59KTkiscI5Okt3"
    },
    {
        "term": "yes",
        "inflexion": "yes",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "yes"
        ],
        "wt": "r",
        "lang": "en-fr",
        "rank": 76,
        "model": "lang-en",
        "infexions": [
            "yes"
        ],
        "id": "QTigKQIi6idf6O1PqQd0"
    },
    {
        "inflexions": [
            "there"
        ],
        "workspace_id": "anglais",
        "inflexion": "there",
        "isPareto": true,
        "term": "there",
        "wt": "fw",
        "lang": "en-fr",
        "rank": 77,
        "model": "lang-en",
        "infexions": [
            "there"
        ],
        "id": "ogRxeVsr5e91IKH02Olj"
    },
    {
        "wt": "r",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "back"
        ],
        "term": "back",
        "infexions": [
            "back"
        ],
        "rank": 78,
        "inflexion": "back",
        "model": "lang-en",
        "id": "2RboPQl7j8Hh2kavaKgB"
    },
    {
        "wt": "v",
        "rank": 79,
        "workspace_id": "anglais",
        "inflexion": "mean, meaning, means, meant",
        "model": "lang-en",
        "infexions": [
            "mean",
            "meaning",
            "means",
            "meant"
        ],
        "term": "mean",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "mean",
            "meaning",
            "means",
            "meant"
        ],
        "id": "YMBTU0p0XNODrNPyATPe"
    },
    {
        "inflexion": "man, men",
        "infexions": [
            "man",
            "men"
        ],
        "term": "man",
        "rank": 80,
        "isVisual": true,
        "inflexions": [
            "man",
            "men"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "id": "cb7baUnFfNB82eiYOhNf"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "little",
        "infexions": [
            "little"
        ],
        "inflexion": "little",
        "rank": 81,
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "inflexions": [
            "little"
        ],
        "id": "Zo1WXP9ptJ3xRYDNn2oA"
    },
    {
        "model": "lang-en",
        "wt": "v",
        "isPareto": true,
        "rank": 82,
        "infexions": [
            "give",
            "gave",
            "given",
            "gives",
            "giving"
        ],
        "lang": "en-fr",
        "term": "give",
        "inflexion": "give, gave, given, gives, giving",
        "inflexions": [
            "give",
            "gave",
            "given",
            "gives",
            "giving"
        ],
        "workspace_id": "anglais",
        "id": "URvqk8kS7Tt4FfzPVHSa"
    },
    {
        "infexions": [
            "his"
        ],
        "model": "lang-en",
        "inflexions": [
            "his"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "term": "his",
        "rank": 83,
        "workspace_id": "anglais",
        "wt": "fw",
        "inflexion": "his",
        "id": "5PhACcUSyUCatfmsiACH"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "us"
        ],
        "model": "lang-en",
        "inflexion": "us",
        "wt": "fw",
        "rank": 84,
        "term": "us",
        "lang": "en-fr",
        "inflexions": [
            "us"
        ],
        "isPareto": true,
        "id": "oFq6zB0Dm8SAG9y82osh"
    },
    {
        "isPareto": true,
        "wt": "fw",
        "rank": 85,
        "term": "them",
        "inflexion": "them",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "them"
        ],
        "infexions": [
            "them"
        ],
        "id": "d37fnyYPGXOvAKPRwM1x"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "need",
            "needed",
            "needs",
            "needing"
        ],
        "infexions": [
            "need",
            "needed",
            "needs",
            "needing"
        ],
        "term": "need",
        "rank": 86,
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "v",
        "inflexion": "need, needed, needs, needing",
        "id": "kzarNXy4LkSimbsSg0Oh"
    },
    {
        "inflexion": "then",
        "rank": 87,
        "infexions": [
            "then"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "wt": "r",
        "workspace_id": "anglais",
        "term": "then",
        "inflexions": [
            "then"
        ],
        "id": "o2FscvLkLJQiT9hfJeIu"
    },
    {
        "infexions": [
            "shall",
            "should"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "workspace_id": "anglais",
        "rank": 88,
        "inflexions": [
            "shall",
            "should"
        ],
        "inflexion": "shall, should",
        "model": "lang-en",
        "term": "shall",
        "id": "5dDxqLk9AyPAb1QtEqFf"
    },
    {
        "inflexions": [
            "or"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "or",
        "inflexion": "or",
        "rank": 89,
        "infexions": [
            "or"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "fw",
        "id": "Iw5ozrzll768jAwvqEbk"
    },
    {
        "rank": 90,
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "talk",
            "talked",
            "talking",
            "talks"
        ],
        "inflexions": [
            "talk",
            "talked",
            "talking",
            "talks"
        ],
        "workspace_id": "anglais",
        "inflexion": "talk, talked, talking, talks",
        "wt": "v",
        "term": "talk",
        "isVisual": true,
        "id": "EjsYhHdWxfSyNCP0rbKo"
    },
    {
        "infexions": [
            "okay",
            "OK"
        ],
        "wt": "j",
        "inflexion": "okay, OK",
        "model": "lang-en",
        "isPareto": true,
        "term": "okay",
        "workspace_id": "anglais",
        "rank": 91,
        "lang": "en-fr",
        "inflexions": [
            "okay",
            "OK"
        ],
        "id": "Am6w8frKqdlsEio88wrR"
    },
    {
        "rank": 92,
        "inflexion": "something",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "something"
        ],
        "lang": "en-fr",
        "infexions": [
            "something"
        ],
        "wt": "fw",
        "term": "something",
        "isPareto": true,
        "id": "CiywkooTMtA7NY2Ds8Qq"
    },
    {
        "isPareto": true,
        "inflexions": [
            "where"
        ],
        "inflexion": "where",
        "term": "where",
        "rank": 93,
        "wt": "r",
        "infexions": [
            "where"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "G52aiexOLeQ8YDjEGcds"
    },
    {
        "wt": "j",
        "isPareto": true,
        "isVisual": true,
        "term": "great",
        "infexions": [
            "great",
            "greater",
            "greatest"
        ],
        "workspace_id": "anglais",
        "rank": 94,
        "inflexions": [
            "great",
            "greater",
            "greatest"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "great, greater, greatest",
        "id": "ojpTGgEMHZb7CzQwO9QS"
    },
    {
        "model": "lang-en",
        "term": "way",
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 95,
        "inflexions": [
            "way",
            "ways"
        ],
        "inflexion": "way, ways",
        "workspace_id": "anglais",
        "infexions": [
            "way",
            "ways"
        ],
        "id": "NFGLJMda1fzpF8HoJxus"
    },
    {
        "term": "never",
        "wt": "r",
        "infexions": [
            "never"
        ],
        "rank": 96,
        "lang": "en-fr",
        "inflexion": "never",
        "model": "lang-en",
        "inflexions": [
            "never"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "ypRYU00o3PmmJaZp0KVm"
    },
    {
        "infexions": [
            "call",
            "called",
            "calling",
            "calls"
        ],
        "inflexions": [
            "call",
            "called",
            "calling",
            "calls"
        ],
        "rank": 97,
        "inflexion": "call, called, calling, calls",
        "term": "call",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "v",
        "lang": "en-fr",
        "isPareto": true,
        "id": "3lZ9Fd03jq1VhrKPo8U6"
    },
    {
        "wt": "r",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 98,
        "term": "too",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "too"
        ],
        "inflexions": [
            "too"
        ],
        "inflexion": "too",
        "id": "UCbZ6PCwpdtsuXb7fJMY"
    },
    {
        "isPareto": true,
        "rank": 99,
        "inflexions": [
            "by"
        ],
        "inflexion": "by",
        "wt": "fw",
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "by",
        "lang": "en-fr",
        "infexions": [
            "by"
        ],
        "id": "XcAGTyPc39OXqfyVWN0j"
    },
    {
        "rank": 100,
        "lang": "en-fr",
        "inflexion": "sorry",
        "wt": "j",
        "isVisual": true,
        "term": "sorry",
        "workspace_id": "anglais",
        "inflexions": [
            "sorry"
        ],
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "sorry"
        ],
        "id": "0qIKqkoXnuRbCibmV7No"
    },
    {
        "isPareto": true,
        "inflexion": "over",
        "inflexions": [
            "over"
        ],
        "term": "over",
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "over"
        ],
        "rank": 101,
        "wt": "fw",
        "id": "qmTa4S0P3G1Wv47p7zlC"
    },
    {
        "model": "lang-en",
        "isVisual": true,
        "wt": "v",
        "term": "love",
        "inflexions": [
            "love",
            "loved",
            "loves",
            "loving"
        ],
        "rank": 102,
        "inflexion": "love, loved, loves, loving",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "love",
            "loved",
            "loves",
            "loving"
        ],
        "isPareto": true,
        "id": "J3KXLO4TMbWuSESXdQZl"
    },
    {
        "lang": "en-fr",
        "inflexion": "wait, waited, waiting, waits",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "wait",
            "waited",
            "waiting",
            "waits"
        ],
        "term": "wait",
        "rank": 103,
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "wait",
            "waited",
            "waiting",
            "waits"
        ],
        "id": "dSIuSgr0dT80lYpRaY20"
    },
    {
        "isPareto": true,
        "term": "more",
        "inflexion": "more",
        "workspace_id": "anglais",
        "infexions": [
            "more"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 104,
        "inflexions": [
            "more"
        ],
        "wt": "fw",
        "isVisual": true,
        "id": "rQPk1BHgfkwzwBIup1KU"
    },
    {
        "term": "down",
        "workspace_id": "anglais",
        "wt": "r",
        "inflexions": [
            "down"
        ],
        "inflexion": "down",
        "infexions": [
            "down"
        ],
        "rank": 105,
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "isVisual": true,
        "id": "OUoRGPSTBViJIsUOHJQx"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "day",
            "days"
        ],
        "inflexions": [
            "day",
            "days"
        ],
        "isVisual": true,
        "workspace_id": "anglais",
        "rank": 106,
        "isPareto": true,
        "term": "day",
        "inflexion": "day, days",
        "model": "lang-en",
        "id": "AgnNSK5DGYlqN33LvHxD"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "two",
            "twos"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 107,
        "inflexion": "two, twos",
        "inflexions": [
            "two",
            "twos"
        ],
        "wt": "m",
        "model": "lang-en",
        "isVisual": true,
        "term": "two",
        "id": "FXqHIKlP72jOFvWduI4d"
    },
    {
        "inflexion": "people",
        "infexions": [
            "people"
        ],
        "workspace_id": "anglais",
        "rank": 108,
        "model": "lang-en",
        "inflexions": [
            "people"
        ],
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "term": "people",
        "id": "OMUrwh3A16wy5sd4Wd0G"
    },
    {
        "inflexions": [
            "God"
        ],
        "rank": 109,
        "infexions": [
            "God"
        ],
        "term": "God",
        "isVisual": true,
        "wt": "u",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "God",
        "id": "OuMjgND7JjXeV7jmfp8B"
    },
    {
        "rank": 110,
        "inflexions": [
            "very"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "r",
        "term": "very",
        "inflexion": "very",
        "isPareto": true,
        "infexions": [
            "very"
        ],
        "id": "zY5ilVSWUYVhWtc6phKZ"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "term": "off",
        "inflexion": "off",
        "infexions": [
            "off"
        ],
        "inflexions": [
            "off"
        ],
        "wt": "fw",
        "rank": 111,
        "workspace_id": "anglais",
        "id": "1OcpvNjPanikbq9qCPFY"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "work, worked, working, works",
        "inflexions": [
            "work",
            "worked",
            "working",
            "works"
        ],
        "isPareto": true,
        "infexions": [
            "work",
            "worked",
            "working",
            "works"
        ],
        "lang": "en-fr",
        "term": "work",
        "rank": 112,
        "model": "lang-en",
        "wt": "v",
        "id": "brfBgJVmhfoGM5Nus8yT"
    },
    {
        "workspace_id": "anglais",
        "isVisual": true,
        "inflexion": "thank, thanked, thanking, thanks",
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "thank",
            "thanked",
            "thanking",
            "thanks"
        ],
        "infexions": [
            "thank",
            "thanked",
            "thanking",
            "thanks"
        ],
        "term": "thank",
        "rank": 113,
        "isPareto": true,
        "lang": "en-fr",
        "id": "GvWvhav4joeaMQeX7KOY"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "big, bigger, biggest",
        "inflexions": [
            "big",
            "bigger",
            "biggest"
        ],
        "model": "lang-en",
        "isVisual": true,
        "rank": 114,
        "infexions": [
            "big",
            "bigger",
            "biggest"
        ],
        "wt": "j",
        "isPareto": true,
        "term": "big",
        "id": "kt1iNplgMse5gklwmJ3W"
    },
    {
        "infexions": [
            "try",
            "tried",
            "tries",
            "trying"
        ],
        "inflexion": "try, tried, tries, trying",
        "wt": "v",
        "rank": 115,
        "term": "try",
        "model": "lang-en",
        "inflexions": [
            "try",
            "tried",
            "tries",
            "trying"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "id": "nLBuaqmRFZ4NfrxnJtrS"
    },
    {
        "rank": 116,
        "inflexions": [
            "dad",
            "dads"
        ],
        "workspace_id": "anglais",
        "term": "dad",
        "infexions": [
            "dad",
            "dads"
        ],
        "wt": "n",
        "inflexion": "dad, dads",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "id": "SVnVBr3CKlBIjTjMRmBY"
    },
    {
        "infexions": [
            "maybe"
        ],
        "isPareto": true,
        "inflexions": [
            "maybe"
        ],
        "isVisual": true,
        "rank": 117,
        "inflexion": "maybe",
        "wt": "r",
        "lang": "en-fr",
        "term": "maybe",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "hfWhEJv3wdcelHhPYR7p"
    },
    {
        "isPareto": true,
        "wt": "v",
        "term": "feel",
        "rank": 118,
        "workspace_id": "anglais",
        "inflexion": "feel, feeling, feels, felt",
        "infexions": [
            "feel",
            "feeling",
            "feels",
            "felt"
        ],
        "inflexions": [
            "feel",
            "feeling",
            "feels",
            "felt"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "id": "rBa8W5dZYDjGFvOt4J7O"
    },
    {
        "inflexion": "friend, friends",
        "isPareto": true,
        "term": "friend",
        "infexions": [
            "friend",
            "friends"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "friend",
            "friends"
        ],
        "rank": 119,
        "wt": "n",
        "lang": "en-fr",
        "id": "FNe0IfcM2eZ3BI0dVy1V"
    },
    {
        "isPareto": true,
        "inflexion": "even",
        "workspace_id": "anglais",
        "inflexions": [
            "even"
        ],
        "rank": 120,
        "lang": "en-fr",
        "term": "even",
        "model": "lang-en",
        "infexions": [
            "even"
        ],
        "wt": "r",
        "id": "mls71wwttASSs5F3nG2b"
    },
    {
        "inflexion": "sure, surer, surest",
        "model": "lang-en",
        "inflexions": [
            "sure",
            "surer",
            "surest"
        ],
        "rank": 121,
        "infexions": [
            "sure",
            "surer",
            "surest"
        ],
        "wt": "j",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "sure",
        "id": "fVnwZIde67VUE5Y3v9DE"
    },
    {
        "rank": 122,
        "term": "find",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "find, finding, finds, found",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "find",
            "finding",
            "finds",
            "found"
        ],
        "inflexions": [
            "find",
            "finding",
            "finds",
            "found"
        ],
        "wt": "v",
        "id": "pB12GeqkruBQOd8tnqyO"
    },
    {
        "wt": "n",
        "rank": 123,
        "workspace_id": "anglais",
        "inflexions": [
            "kid",
            "kids"
        ],
        "term": "kid",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "kid",
            "kids"
        ],
        "inflexion": "kid, kids",
        "isPareto": true,
        "id": "ELkyDLVSqQnvuW7SoBjF"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "these"
        ],
        "rank": 124,
        "model": "lang-en",
        "infexions": [
            "these"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "these",
        "inflexion": "these",
        "wt": "fw",
        "id": "6UEnUqhdKFK8vriC3OSD"
    },
    {
        "inflexions": [
            "boy",
            "boys"
        ],
        "model": "lang-en",
        "wt": "n",
        "inflexion": "boy, boys",
        "lang": "en-fr",
        "isVisual": true,
        "infexions": [
            "boy",
            "boys"
        ],
        "isPareto": true,
        "rank": 125,
        "term": "boy",
        "workspace_id": "anglais",
        "id": "m2F9Iq2Uc1WL4Y1XGIXI"
    },
    {
        "inflexion": "put, puts, putting",
        "infexions": [
            "put",
            "puts",
            "putting"
        ],
        "wt": "v",
        "lang": "en-fr",
        "inflexions": [
            "put",
            "puts",
            "putting"
        ],
        "isPareto": true,
        "rank": 126,
        "term": "put",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "TM4vle0M63Lrw8ZFjUNY"
    },
    {
        "isPareto": true,
        "inflexions": [
            "please"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "r",
        "lang": "en-fr",
        "term": "please",
        "rank": 127,
        "inflexion": "please",
        "infexions": [
            "please"
        ],
        "id": "KSh4NlLATEL9lR7oCE9P"
    },
    {
        "wt": "v",
        "lang": "en-fr",
        "inflexions": [
            "happen",
            "happened",
            "happening",
            "happens"
        ],
        "rank": 128,
        "term": "happen",
        "model": "lang-en",
        "inflexion": "happen, happened, happening, happens",
        "workspace_id": "anglais",
        "infexions": [
            "happen",
            "happened",
            "happening",
            "happens"
        ],
        "isPareto": true,
        "id": "EVNk5I5wjwYWq2HkTUuR"
    },
    {
        "infexions": [
            "much"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "much",
        "lang": "en-fr",
        "inflexion": "much",
        "wt": "fw",
        "rank": 129,
        "inflexions": [
            "much"
        ],
        "model": "lang-en",
        "id": "6AvqTH0lruHpB5xxRGuT"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "v",
        "isVisual": true,
        "term": "stop",
        "inflexions": [
            "stop",
            "stopped",
            "stopping",
            "stops"
        ],
        "rank": 130,
        "inflexion": "stop, stopped, stopping, stops",
        "workspace_id": "anglais",
        "infexions": [
            "stop",
            "stopped",
            "stopping",
            "stops"
        ],
        "isPareto": true,
        "id": "KB7C8vYXmPKnUAtOvrsH"
    },
    {
        "isPareto": true,
        "infexions": [
            "night",
            "nights"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 131,
        "inflexion": "night, nights",
        "lang": "en-fr",
        "inflexions": [
            "night",
            "nights"
        ],
        "model": "lang-en",
        "term": "night",
        "id": "l1SZwhIOE6W1VxgPT1Rd"
    },
    {
        "infexions": [
            "bad",
            "worse",
            "worst"
        ],
        "isPareto": true,
        "model": "lang-en",
        "wt": "j",
        "rank": 132,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "bad, worse, worst",
        "term": "bad",
        "inflexions": [
            "bad",
            "worse",
            "worst"
        ],
        "id": "fxzMY31Ux4wA48Y6SI3c"
    },
    {
        "lang": "en-fr",
        "rank": 133,
        "inflexion": "into",
        "wt": "fw",
        "term": "into",
        "inflexions": [
            "into"
        ],
        "infexions": [
            "into"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "id": "5sA9t7HNK8dSugvniXen"
    },
    {
        "wt": "fw",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "those"
        ],
        "rank": 134,
        "infexions": [
            "those"
        ],
        "inflexion": "those",
        "term": "those",
        "isPareto": true,
        "id": "Wcq7zu4CURIiY5dTGwQu"
    },
    {
        "wt": "fw",
        "infexions": [
            "any"
        ],
        "inflexion": "any",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "any"
        ],
        "rank": 135,
        "isPareto": true,
        "term": "any",
        "lang": "en-fr",
        "id": "CX71a7YzsWXnzP8Klkrt"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "right",
        "inflexion": "right",
        "lang": "en-fr",
        "inflexions": [
            "right"
        ],
        "rank": 136,
        "isVisual": true,
        "model": "lang-en",
        "infexions": [
            "right"
        ],
        "wt": "u",
        "id": "Yh2t7so1ooAaWtEhm2Cq"
    },
    {
        "infexions": [
            "first"
        ],
        "wt": "m",
        "inflexions": [
            "first"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "first",
        "lang": "en-fr",
        "rank": 137,
        "workspace_id": "anglais",
        "term": "first",
        "id": "gN3MazovqTbB7XmKP0UT"
    },
    {
        "wt": "v",
        "inflexions": [
            "leave",
            "leaves",
            "leaving",
            "left"
        ],
        "model": "lang-en",
        "isPareto": true,
        "term": "leave",
        "rank": 138,
        "workspace_id": "anglais",
        "infexions": [
            "leave",
            "leaves",
            "leaving",
            "left"
        ],
        "inflexion": "leave, leaves, leaving, left",
        "lang": "en-fr",
        "id": "TmbGHTqh1lHMoKfUku6E"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "year",
            "years"
        ],
        "model": "lang-en",
        "wt": "n",
        "term": "year",
        "isPareto": true,
        "inflexion": "year, years",
        "infexions": [
            "year",
            "years"
        ],
        "workspace_id": "anglais",
        "rank": 139,
        "id": "3FbKad5dVE1jXBgsUked"
    },
    {
        "wt": "v",
        "term": "hear",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "hear",
            "heard",
            "hearing",
            "hears"
        ],
        "rank": 140,
        "isPareto": true,
        "inflexions": [
            "hear",
            "heard",
            "hearing",
            "hears"
        ],
        "inflexion": "hear, heard, hearing, hears",
        "workspace_id": "anglais",
        "id": "UmOvJvo0613sEuGMFEfD"
    },
    {
        "model": "lang-en",
        "inflexion": "right",
        "workspace_id": "anglais",
        "isVisual": true,
        "isPareto": true,
        "inflexions": [
            "right"
        ],
        "infexions": [
            "right"
        ],
        "wt": "r",
        "term": "right",
        "lang": "en-fr",
        "rank": 141,
        "id": "n3nltHYZaFE8VsP067uj"
    },
    {
        "inflexions": [
            "ever"
        ],
        "infexions": [
            "ever"
        ],
        "rank": 142,
        "lang": "en-fr",
        "wt": "r",
        "term": "ever",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "ever",
        "model": "lang-en",
        "id": "QkxtDwLqXPfzUKLTUh5S"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "again"
        ],
        "wt": "r",
        "rank": 143,
        "inflexion": "again",
        "inflexions": [
            "again"
        ],
        "lang": "en-fr",
        "term": "again",
        "workspace_id": "anglais",
        "id": "mm5wTpKmh3uNFXJSAIV4"
    },
    {
        "wt": "v",
        "lang": "en-fr",
        "inflexion": "use, used, uses, using",
        "rank": 144,
        "infexions": [
            "use",
            "used",
            "uses",
            "using"
        ],
        "inflexions": [
            "use",
            "used",
            "uses",
            "using"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "use",
        "model": "lang-en",
        "id": "m80ZpqKbLDsGtwenOF48"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "isVisual": true,
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 145,
        "inflexions": [
            "mom",
            "moms"
        ],
        "term": "mom",
        "inflexion": "mom, moms",
        "model": "lang-en",
        "infexions": [
            "mom",
            "moms"
        ],
        "id": "vV6fci7yrnt2seOPRsM8"
    },
    {
        "term": "may",
        "rank": 146,
        "inflexions": [
            "may",
            "might"
        ],
        "model": "lang-en",
        "inflexion": "may, might",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "v",
        "lang": "en-fr",
        "infexions": [
            "may",
            "might"
        ],
        "id": "kVZ2xmOUeUoXidD4BTXn"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "term": "hi",
        "wt": "u",
        "isPareto": true,
        "inflexion": "hi",
        "inflexions": [
            "hi"
        ],
        "rank": 147,
        "infexions": [
            "hi"
        ],
        "id": "81rXFOtU2aRejWsAfs8I"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "life, lives",
        "isVisual": true,
        "rank": 148,
        "term": "life",
        "infexions": [
            "life",
            "lives"
        ],
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "life",
            "lives"
        ],
        "isPareto": true,
        "id": "XYZhNbbx9kM1lte8iC1P"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "term": "nice",
        "workspace_id": "anglais",
        "inflexions": [
            "nice",
            "nicer",
            "nicest"
        ],
        "inflexion": "nice, nicer, nicest",
        "infexions": [
            "nice",
            "nicer",
            "nicest"
        ],
        "wt": "j",
        "rank": 149,
        "model": "lang-en",
        "id": "YDVa2Atg2JxHCbdAd69j"
    },
    {
        "infexions": [
            "new",
            "newer",
            "newest"
        ],
        "wt": "j",
        "term": "new",
        "inflexions": [
            "new",
            "newer",
            "newest"
        ],
        "workspace_id": "anglais",
        "inflexion": "new, newer, newest",
        "model": "lang-en",
        "rank": 150,
        "isPareto": true,
        "lang": "en-fr",
        "id": "8lZQnhrfadPtSA7wOMDw"
    },
    {
        "inflexion": "still",
        "model": "lang-en",
        "rank": 151,
        "inflexions": [
            "still"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "r",
        "infexions": [
            "still"
        ],
        "term": "still",
        "lang": "en-fr",
        "id": "araGpBfl5xMyPyp1vsFu"
    },
    {
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "kind",
            "kinds"
        ],
        "model": "lang-en",
        "isPareto": true,
        "term": "kind",
        "inflexion": "kind, kinds",
        "lang": "en-fr",
        "rank": 152,
        "infexions": [
            "kind",
            "kinds"
        ],
        "id": "D6JnykTw2ElRwrHJBPRl"
    },
    {
        "term": "anything",
        "model": "lang-en",
        "wt": "fw",
        "workspace_id": "anglais",
        "inflexion": "anything",
        "isPareto": true,
        "infexions": [
            "anything"
        ],
        "inflexions": [
            "anything"
        ],
        "lang": "en-fr",
        "rank": 153,
        "id": "MMJ29D44xULt0hsnzIJw"
    },
    {
        "inflexion": "only",
        "rank": 154,
        "term": "only",
        "workspace_id": "anglais",
        "wt": "r",
        "model": "lang-en",
        "infexions": [
            "only"
        ],
        "lang": "en-fr",
        "inflexions": [
            "only"
        ],
        "isPareto": true,
        "id": "zbjki2SACT11mTbxb2c2"
    },
    {
        "model": "lang-en",
        "inflexion": "baby, babies",
        "infexions": [
            "baby",
            "babies"
        ],
        "rank": 155,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "baby",
            "babies"
        ],
        "lang": "en-fr",
        "wt": "n",
        "term": "baby",
        "isVisual": true,
        "id": "Ia188Y9PCEIzZTfskZhX"
    },
    {
        "rank": 156,
        "inflexions": [
            "than"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "than"
        ],
        "term": "than",
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "fw",
        "inflexion": "than",
        "id": "OxMqhTA72GbE2bjD2O16"
    },
    {
        "infexions": [
            "fine",
            "finer",
            "finest"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "fine",
            "finer",
            "finest"
        ],
        "inflexion": "fine, finer, finest",
        "rank": 157,
        "wt": "j",
        "term": "fine",
        "workspace_id": "anglais",
        "id": "Z4yfJkxHAIhk2ldN3ifr"
    },
    {
        "inflexions": [
            "hello"
        ],
        "infexions": [
            "hello"
        ],
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW",
        "term": "hello",
        "isVisual": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "order": 0,
        "context": "Hello, so do you know what we're going to do at the beach?",
        "model": "lang-en",
        "isPareto": true,
        "wt": "u",
        "rank": 158,
        "inflexion": "hello",
        "id": "7sZpsd1OQWEpeOOpyMbx",
        "def_img": "https://media3.giphy.com/media/ASd0Ukj0y3qMM/giphy.gif?cid=ecf05e47klpmlghb25nks5wx6ew5uplrk4hjz7gc0rea7ga2&rid=giphy.gif&ct=g"
    },
    {
        "inflexion": "keep, keeping, keeps, kept",
        "rank": 159,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "keep",
        "model": "lang-en",
        "isPareto": true,
        "wt": "v",
        "inflexions": [
            "keep",
            "keeping",
            "keeps",
            "kept"
        ],
        "infexions": [
            "keep",
            "keeping",
            "keeps",
            "kept"
        ],
        "id": "Uf8QiQUOqxRBK5jePmyP"
    },
    {
        "isVisual": true,
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "girl",
            "girls"
        ],
        "rank": 160,
        "isPareto": true,
        "inflexion": "girl, girls",
        "workspace_id": "anglais",
        "inflexions": [
            "girl",
            "girls"
        ],
        "term": "girl",
        "wt": "n",
        "id": "QaxrMO50vLOhI6A8tnu5"
    },
    {
        "inflexion": "help, helped, helping, helps",
        "inflexions": [
            "help",
            "helped",
            "helping",
            "helps"
        ],
        "term": "help",
        "isPareto": true,
        "infexions": [
            "help",
            "helped",
            "helping",
            "helps"
        ],
        "wt": "v",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 161,
        "id": "45q9aMJgKMAW57hR4DBG"
    },
    {
        "term": "believe",
        "inflexions": [
            "believe",
            "believed",
            "believes",
            "believing"
        ],
        "infexions": [
            "believe",
            "believed",
            "believes",
            "believing"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "rank": 162,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "believe, believed, believes, believing",
        "id": "ljQooziFV08E05o8FDjv"
    },
    {
        "infexions": [
            "woman",
            "women"
        ],
        "lang": "en-fr",
        "rank": 163,
        "inflexions": [
            "woman",
            "women"
        ],
        "inflexion": "woman, women",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "n",
        "term": "woman",
        "id": "tf4EuI1MRFasvuzxNOpJ"
    },
    {
        "lang": "en-fr",
        "inflexion": "lot, lots",
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "lot",
        "infexions": [
            "lot",
            "lots"
        ],
        "inflexions": [
            "lot",
            "lots"
        ],
        "model": "lang-en",
        "rank": 164,
        "id": "VDfJtVUxGENDBw1GkDHd"
    },
    {
        "inflexions": [
            "play",
            "played",
            "playing",
            "plays"
        ],
        "term": "play",
        "wt": "v",
        "rank": 165,
        "lang": "en-fr",
        "isVisual": true,
        "isPareto": true,
        "inflexion": "play, played, playing, plays",
        "infexions": [
            "play",
            "played",
            "playing",
            "plays"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "5LkRti4HB9czbWNicnyX"
    },
    {
        "inflexions": [
            "ask",
            "asked",
            "asking",
            "asks"
        ],
        "isPareto": true,
        "model": "lang-en",
        "term": "ask",
        "wt": "v",
        "lang": "en-fr",
        "inflexion": "ask, asked, asking, asks",
        "rank": 166,
        "workspace_id": "anglais",
        "infexions": [
            "ask",
            "asked",
            "asking",
            "asks"
        ],
        "id": "kAXExUu0ieLrnfPKG34g"
    },
    {
        "term": "start",
        "isVisual": true,
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "start, started, starting, starts",
        "rank": 167,
        "infexions": [
            "start",
            "started",
            "starting",
            "starts"
        ],
        "inflexions": [
            "start",
            "started",
            "starting",
            "starts"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "id": "eDuia7SwBXVBwERB3d83"
    },
    {
        "inflexion": "home, homes",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "home",
        "infexions": [
            "home",
            "homes"
        ],
        "inflexions": [
            "home",
            "homes"
        ],
        "rank": 168,
        "isPareto": true,
        "model": "lang-en",
        "isVisual": true,
        "wt": "n",
        "id": "qmkR9PMz34ghb30Y3A3T"
    },
    {
        "inflexion": "nothing",
        "inflexions": [
            "nothing"
        ],
        "wt": "fw",
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 169,
        "lang": "en-fr",
        "infexions": [
            "nothing"
        ],
        "isVisual": true,
        "model": "lang-en",
        "term": "nothing",
        "id": "9XkdeCAFy2bMavVRu63z"
    },
    {
        "inflexion": "hmm, hm",
        "lang": "en-fr",
        "infexions": [
            "hmm",
            "hm"
        ],
        "isPareto": true,
        "inflexions": [
            "hmm",
            "hm"
        ],
        "wt": "u",
        "workspace_id": "anglais",
        "term": "hmm",
        "model": "lang-en",
        "rank": 170,
        "id": "KhHUUtnZuEfurJ7kV2Dr"
    },
    {
        "rank": 171,
        "workspace_id": "anglais",
        "inflexion": "their, theirs",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "wt": "fw",
        "inflexions": [
            "their",
            "theirs"
        ],
        "infexions": [
            "their",
            "theirs"
        ],
        "term": "their",
        "id": "xPcQqbNPMRPUUF9m7tQN"
    },
    {
        "workspace_id": "anglais",
        "term": "meet",
        "lang": "en-fr",
        "rank": 172,
        "inflexion": "meet, meeting, meets, met",
        "isPareto": true,
        "wt": "v",
        "inflexions": [
            "meet",
            "meeting",
            "meets",
            "met"
        ],
        "model": "lang-en",
        "infexions": [
            "meet",
            "meeting",
            "meets",
            "met"
        ],
        "id": "FE6wGdDui9mx4w4028wB"
    },
    {
        "inflexion": "show, showed, showing, shown, shows",
        "wt": "v",
        "rank": 173,
        "lang": "en-fr",
        "model": "lang-en",
        "term": "show",
        "inflexions": [
            "show",
            "showed",
            "showing",
            "shown",
            "shows"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "show",
            "showed",
            "showing",
            "shown",
            "shows"
        ],
        "isPareto": true,
        "id": "HMsolkxDIZ5QWr08Fi9Z"
    },
    {
        "infexions": [
            "around"
        ],
        "rank": 174,
        "workspace_id": "anglais",
        "inflexions": [
            "around"
        ],
        "term": "around",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "around",
        "wt": "fw",
        "id": "8UvzWjUoSeilX96ouDlc"
    },
    {
        "infexions": [
            "guess",
            "guessed",
            "guesses",
            "guessing"
        ],
        "model": "lang-en",
        "inflexion": "guess, guessed, guesses, guessing",
        "isPareto": true,
        "inflexions": [
            "guess",
            "guessed",
            "guesses",
            "guessing"
        ],
        "rank": 175,
        "lang": "en-fr",
        "wt": "v",
        "workspace_id": "anglais",
        "term": "guess",
        "id": "nO9QHqNgWc1nzMLpgSXk"
    },
    {
        "wt": "j",
        "inflexion": "old, older, oldest",
        "isPareto": true,
        "rank": 176,
        "model": "lang-en",
        "inflexions": [
            "old",
            "older",
            "oldest"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "old",
            "older",
            "oldest"
        ],
        "term": "old",
        "lang": "en-fr",
        "id": "pH73mfmieNDiK0af5lrL"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "hell"
        ],
        "isPareto": true,
        "isVisual": true,
        "inflexions": [
            "hell"
        ],
        "term": "hell",
        "inflexion": "hell",
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "rank": 177,
        "id": "msGqzMQpxSwlC0f0dLld"
    },
    {
        "rank": 178,
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "before",
        "term": "before",
        "inflexions": [
            "before"
        ],
        "infexions": [
            "before"
        ],
        "workspace_id": "anglais",
        "wt": "fw",
        "id": "QDvGwbBzrBhXVszfnk7T"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "always"
        ],
        "term": "always",
        "rank": 179,
        "isPareto": true,
        "wt": "r",
        "infexions": [
            "always"
        ],
        "workspace_id": "anglais",
        "inflexion": "always",
        "lang": "en-fr",
        "id": "JjdDpj2zdQm4KrzIMpJ5"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "three",
            "threes"
        ],
        "infexions": [
            "three",
            "threes"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "m",
        "isVisual": true,
        "term": "three",
        "inflexion": "three, threes",
        "isPareto": true,
        "rank": 180,
        "id": "Vs0QPKlt80e7Gw4QhIMb"
    },
    {
        "rank": 181,
        "isPareto": true,
        "term": "wow",
        "model": "lang-en",
        "inflexions": [
            "wow"
        ],
        "infexions": [
            "wow"
        ],
        "inflexion": "wow",
        "wt": "u",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "qPrwgOmGtY4dK5rV0CTR"
    },
    {
        "wt": "v",
        "inflexions": [
            "listen",
            "listened",
            "listening",
            "listens"
        ],
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "listen",
            "listened",
            "listening",
            "listens"
        ],
        "rank": 182,
        "inflexion": "listen, listened, listening, listens",
        "workspace_id": "anglais",
        "term": "listen",
        "id": "dAFOq34VHiie67iHWPMn"
    },
    {
        "inflexions": [
            "thanks"
        ],
        "term": "thanks",
        "isVisual": true,
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "thanks",
        "rank": 183,
        "infexions": [
            "thanks"
        ],
        "wt": "u",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "Q7k0exrs7OTOYvK5UVyn"
    },
    {
        "wt": "n",
        "inflexion": "minute, minutes",
        "isPareto": true,
        "inflexions": [
            "minute",
            "minutes"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "term": "minute",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 184,
        "infexions": [
            "minute",
            "minutes"
        ],
        "id": "lV6dgl4uIpndxur6N77k"
    },
    {
        "lang": "en-fr",
        "wt": "r",
        "inflexions": [
            "actually"
        ],
        "workspace_id": "anglais",
        "rank": 185,
        "inflexion": "actually",
        "infexions": [
            "actually"
        ],
        "term": "actually",
        "model": "lang-en",
        "isPareto": true,
        "id": "Qaas8T892JE1ZCTlElPJ"
    },
    {
        "infexions": [
            "eat",
            "ate",
            "eaten",
            "eating",
            "eats"
        ],
        "isVisual": true,
        "rank": 186,
        "model": "lang-en",
        "wt": "v",
        "term": "eat",
        "lang": "en-fr",
        "inflexion": "eat, ate, eaten, eating, eats",
        "isPareto": true,
        "inflexions": [
            "eat",
            "ate",
            "eaten",
            "eating",
            "eats"
        ],
        "workspace_id": "anglais",
        "id": "q3ixQVUphwgxrDdgxVr4"
    },
    {
        "inflexion": "place, places",
        "isVisual": true,
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "lang": "en-fr",
        "infexions": [
            "place",
            "places"
        ],
        "inflexions": [
            "place",
            "places"
        ],
        "term": "place",
        "rank": 187,
        "model": "lang-en",
        "id": "Bhk1tQ56uQ1o4Ja6GlYA"
    },
    {
        "inflexions": [
            "live",
            "lived",
            "lives",
            "living"
        ],
        "inflexion": "live, lived, lives, living",
        "model": "lang-en",
        "infexions": [
            "live",
            "lived",
            "lives",
            "living"
        ],
        "rank": 188,
        "wt": "v",
        "isPareto": true,
        "term": "live",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "NOnQAriAqcdgvCouXiWG"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "away"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "away"
        ],
        "inflexion": "away",
        "wt": "r",
        "rank": 189,
        "term": "away",
        "isPareto": true,
        "id": "ZAlwALTfSLcXSF7ahpDr"
    },
    {
        "term": "after",
        "inflexion": "after",
        "rank": 190,
        "model": "lang-en",
        "inflexions": [
            "after"
        ],
        "lang": "en-fr",
        "wt": "fw",
        "isPareto": true,
        "infexions": [
            "after"
        ],
        "workspace_id": "anglais",
        "id": "4IyoNRpi990iWFFIsAKJ"
    },
    {
        "rank": 191,
        "workspace_id": "anglais",
        "infexions": [
            "bring",
            "bringing",
            "brings",
            "brought"
        ],
        "model": "lang-en",
        "isPareto": true,
        "term": "bring",
        "wt": "v",
        "lang": "en-fr",
        "inflexion": "bring, bringing, brings, brought",
        "inflexions": [
            "bring",
            "bringing",
            "brings",
            "brought"
        ],
        "id": "drlQ7fZCNMPil61PMobE"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 192,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "every"
        ],
        "inflexions": [
            "every"
        ],
        "inflexion": "every",
        "wt": "fw",
        "term": "every",
        "id": "ubw1aHjFaEJcOuIT1IPq"
    },
    {
        "term": "everything",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "fw",
        "inflexions": [
            "everything"
        ],
        "inflexion": "everything",
        "isPareto": true,
        "infexions": [
            "everything"
        ],
        "lang": "en-fr",
        "rank": 193,
        "id": "9NOV5JeUTHaoLHB2R2Ap"
    },
    {
        "inflexion": "money",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 194,
        "wt": "n",
        "model": "lang-en",
        "inflexions": [
            "money"
        ],
        "lang": "en-fr",
        "term": "money",
        "infexions": [
            "money"
        ],
        "id": "QjHSzrc3R3kpY3Nhe5Ep"
    },
    {
        "wt": "n",
        "rank": 195,
        "term": "person",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "person",
            "people",
            "persons"
        ],
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "person",
            "people",
            "persons"
        ],
        "inflexion": "person, people, persons",
        "id": "tLpzYSkK4QQ4oUQhAvZ7"
    },
    {
        "rank": 196,
        "wt": "v",
        "inflexion": "watch, watched, watches, watching",
        "model": "lang-en",
        "infexions": [
            "watch",
            "watched",
            "watches",
            "watching"
        ],
        "isVisual": true,
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "watch",
        "lang": "en-fr",
        "inflexions": [
            "watch",
            "watched",
            "watches",
            "watching"
        ],
        "id": "2eOYKJKzo7rhMhdQbWvY"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "other",
        "infexions": [
            "other"
        ],
        "rank": 197,
        "workspace_id": "anglais",
        "inflexions": [
            "other"
        ],
        "wt": "j",
        "term": "other",
        "id": "uKNQoColPSwgdPv4Pc8D"
    },
    {
        "rank": 198,
        "term": "remember",
        "inflexion": "remember, remembered, remembering, remembers",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "v",
        "infexions": [
            "remember",
            "remembered",
            "remembering",
            "remembers"
        ],
        "isPareto": true,
        "inflexions": [
            "remember",
            "remembered",
            "remembering",
            "remembers"
        ],
        "isVisual": true,
        "id": "m6xcg65KCxD09IGelenZ"
    },
    {
        "isPareto": true,
        "isVisual": true,
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "house",
            "houses"
        ],
        "term": "house",
        "model": "lang-en",
        "inflexion": "house, houses",
        "rank": 199,
        "workspace_id": "anglais",
        "inflexions": [
            "house",
            "houses"
        ],
        "id": "sH904PCz3mFWvZqc1gdj"
    },
    {
        "infexions": [
            "wrong"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "j",
        "lang": "en-fr",
        "rank": 200,
        "term": "wrong",
        "isPareto": true,
        "inflexions": [
            "wrong"
        ],
        "isVisual": true,
        "inflexion": "wrong",
        "id": "gMKauEQ2HXTpR1YJRUQY"
    },
    {
        "lang": "en-fr",
        "rank": 201,
        "workspace_id": "anglais",
        "wt": "v",
        "inflexion": "kill, killed, killing, kills",
        "isPareto": true,
        "term": "kill",
        "model": "lang-en",
        "infexions": [
            "kill",
            "killed",
            "killing",
            "kills"
        ],
        "inflexions": [
            "kill",
            "killed",
            "killing",
            "kills"
        ],
        "id": "wdNHcIN1uSJ9KWcSpISo"
    },
    {
        "isPareto": true,
        "infexions": [
            "school",
            "schools"
        ],
        "inflexions": [
            "school",
            "schools"
        ],
        "model": "lang-en",
        "isVisual": true,
        "term": "school",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "school, schools",
        "wt": "n",
        "rank": 202,
        "id": "dRbrSDOBOZiRXNWm1Iy3"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 203,
        "model": "lang-en",
        "inflexion": "everyone",
        "term": "everyone",
        "wt": "fw",
        "infexions": [
            "everyone"
        ],
        "inflexions": [
            "everyone"
        ],
        "isPareto": true,
        "id": "Pm8vSyZ9pzngTRmyi8h5"
    },
    {
        "term": "run",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "run, ran, running, runs",
        "rank": 204,
        "wt": "v",
        "isVisual": true,
        "infexions": [
            "run",
            "ran",
            "running",
            "runs"
        ],
        "isPareto": true,
        "inflexions": [
            "run",
            "ran",
            "running",
            "runs"
        ],
        "id": "m59cOrrwFw16bYPqpMJP"
    },
    {
        "inflexions": [
            "late",
            "later",
            "latest"
        ],
        "rank": 205,
        "lang": "en-fr",
        "term": "late",
        "infexions": [
            "late",
            "later",
            "latest"
        ],
        "isPareto": true,
        "wt": "j",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "late, later, latest",
        "isVisual": true,
        "id": "zCk9LRjUgG0AGaYiClz3"
    },
    {
        "model": "lang-en",
        "inflexion": "care, cared, cares, caring",
        "wt": "v",
        "isPareto": true,
        "rank": 206,
        "term": "care",
        "inflexions": [
            "care",
            "cared",
            "cares",
            "caring"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "care",
            "cared",
            "cares",
            "caring"
        ],
        "id": "giZpXDALhhTGlmQFmr1G"
    },
    {
        "inflexions": [
            "car",
            "cars"
        ],
        "inflexion": "car, cars",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "lang": "en-fr",
        "isVisual": true,
        "rank": 207,
        "infexions": [
            "car",
            "cars"
        ],
        "term": "car",
        "id": "lfsdz9uDfHnr5Ei08XRf"
    },
    {
        "model": "lang-en",
        "infexions": [
            "move",
            "moved",
            "moves",
            "moving"
        ],
        "inflexions": [
            "move",
            "moved",
            "moves",
            "moving"
        ],
        "inflexion": "move, moved, moves, moving",
        "workspace_id": "anglais",
        "isVisual": true,
        "wt": "v",
        "rank": 208,
        "term": "move",
        "lang": "en-fr",
        "isPareto": true,
        "id": "ItU8Dz55bkgWYLxw4icV"
    },
    {
        "infexions": [
            ""
        ],
        "wt": "",
        "inflexion": "",
        "lang": "en-fr",
        "term": "",
        "inflexions": [
            ""
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 209,
        "isPareto": true,
        "id": "boVjNvSdVOoc58yP1NQ2"
    },
    {
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "idea",
            "ideas"
        ],
        "inflexion": "idea, ideas",
        "infexions": [
            "idea",
            "ideas"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "isPareto": true,
        "rank": 210,
        "term": "idea",
        "id": "FSKla8CacrRf3e8azuWM"
    },
    {
        "rank": 211,
        "inflexions": [
            "another"
        ],
        "term": "another",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "another"
        ],
        "isVisual": true,
        "wt": "fw",
        "inflexion": "another",
        "model": "lang-en",
        "id": "hOzi4DdKrcUYIbHXrc96"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "wt": "fw",
        "inflexion": "someone",
        "inflexions": [
            "someone"
        ],
        "term": "someone",
        "workspace_id": "anglais",
        "infexions": [
            "someone"
        ],
        "rank": 212,
        "model": "lang-en",
        "id": "ZSLADi5Zpcl6et5dMup5"
    },
    {
        "isVisual": true,
        "inflexions": [
            "today"
        ],
        "isPareto": true,
        "wt": "r",
        "infexions": [
            "today"
        ],
        "inflexion": "today",
        "rank": 213,
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "today",
        "id": "vSG9OqKQUfXsisLscx8p"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "turn, turned, turning, turns",
        "isPareto": true,
        "rank": 214,
        "infexions": [
            "turn",
            "turned",
            "turning",
            "turns"
        ],
        "lang": "en-fr",
        "inflexions": [
            "turn",
            "turned",
            "turning",
            "turns"
        ],
        "term": "turn",
        "wt": "v",
        "id": "9zI5xkk5w0BbsDnTJpsy"
    },
    {
        "inflexion": "real",
        "model": "lang-en",
        "rank": 215,
        "term": "real",
        "wt": "j",
        "inflexions": [
            "real"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "real"
        ],
        "id": "5wpXn9RM050Goz7PIU4e"
    },
    {
        "wt": "j",
        "workspace_id": "anglais",
        "term": "happy",
        "inflexions": [
            "happy",
            "happier",
            "happiest"
        ],
        "model": "lang-en",
        "inflexion": "happy, happier, happiest",
        "infexions": [
            "happy",
            "happier",
            "happiest"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "rank": 216,
        "id": "Zrf56gAF3LUOkftye6Al"
    },
    {
        "workspace_id": "anglais",
        "rank": 217,
        "wt": "j",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "whole"
        ],
        "inflexions": [
            "whole"
        ],
        "model": "lang-en",
        "inflexion": "whole",
        "term": "whole",
        "id": "8P2PsUEgoM7MOFuYPE9b"
    },
    {
        "term": "week",
        "inflexions": [
            "week",
            "weeks"
        ],
        "isVisual": true,
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 218,
        "inflexion": "week, weeks",
        "infexions": [
            "week",
            "weeks"
        ],
        "lang": "en-fr",
        "id": "sjC3cykfTgWGRHeLI9X5"
    },
    {
        "rank": 219,
        "infexions": [
            "job",
            "jobs"
        ],
        "inflexions": [
            "job",
            "jobs"
        ],
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "term": "job",
        "inflexion": "job, jobs",
        "workspace_id": "anglais",
        "id": "78TE1rCoudiZKUrJvGFA"
    },
    {
        "rank": 220,
        "inflexions": [
            "fun"
        ],
        "inflexion": "fun",
        "model": "lang-en",
        "infexions": [
            "fun"
        ],
        "isVisual": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "fun",
        "wt": "n",
        "id": "2NwyEVaf9n2AFWYJb1y8"
    },
    {
        "infexions": [
            "problem",
            "problems"
        ],
        "isVisual": true,
        "term": "problem",
        "inflexions": [
            "problem",
            "problems"
        ],
        "inflexion": "problem, problems",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "rank": 221,
        "lang": "en-fr",
        "model": "lang-en",
        "id": "55htBkog5VIX5T6n7aAc"
    },
    {
        "infexions": [
            "break",
            "breaking",
            "breaks",
            "broke",
            "broken"
        ],
        "inflexion": "break, breaking, breaks, broke, broken",
        "rank": 222,
        "isVisual": true,
        "term": "break",
        "workspace_id": "anglais",
        "inflexions": [
            "break",
            "breaking",
            "breaks",
            "broke",
            "broken"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "wt": "v",
        "id": "zxEk0yttRSMwz3Kx8mDn"
    },
    {
        "inflexions": [
            "world",
            "worlds"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "world",
        "lang": "en-fr",
        "rank": 223,
        "isPareto": true,
        "isVisual": true,
        "infexions": [
            "world",
            "worlds"
        ],
        "inflexion": "world, worlds",
        "wt": "n",
        "id": "z78EjhF2OLvItr0g4rOl"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 224,
        "term": "which",
        "lang": "en-fr",
        "infexions": [
            "which"
        ],
        "inflexions": [
            "which"
        ],
        "wt": "fw",
        "inflexion": "which",
        "id": "YmYfbUY7YhBN1jYiunZ2"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "term": "must",
        "workspace_id": "anglais",
        "rank": 225,
        "lang": "en-fr",
        "infexions": [
            "must"
        ],
        "inflexion": "must",
        "inflexions": [
            "must"
        ],
        "wt": "v",
        "id": "OWjK3dPcMydD5kzHJDgR"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "party",
            "parties"
        ],
        "term": "party",
        "wt": "n",
        "inflexions": [
            "party",
            "parties"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "rank": 226,
        "inflexion": "party, parties",
        "id": "Gu2FEXKMCadfTAVZ9ZNP"
    },
    {
        "inflexions": [
            "buy",
            "bought",
            "buying",
            "buys"
        ],
        "term": "buy",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "buy, bought, buying, buys",
        "workspace_id": "anglais",
        "infexions": [
            "buy",
            "bought",
            "buying",
            "buys"
        ],
        "rank": 227,
        "isVisual": false,
        "wt": "v",
        "id": "WefrKVZBJaM4q7Rd3rj9"
    },
    {
        "inflexion": "through",
        "isPareto": true,
        "wt": "fw",
        "model": "lang-en",
        "inflexions": [
            "through"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "through"
        ],
        "isVisual": true,
        "term": "through",
        "rank": 228,
        "id": "YZ5tzNWw3oRP0tMKyPeH"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "together"
        ],
        "wt": "r",
        "isPareto": true,
        "term": "together",
        "inflexion": "together",
        "model": "lang-en",
        "rank": 229,
        "infexions": [
            "together"
        ],
        "lang": "en-fr",
        "id": "lmZkxvun6TqMrtEH50KN"
    },
    {
        "model": "lang-en",
        "infexions": [
            "room",
            "rooms"
        ],
        "rank": 230,
        "inflexions": [
            "room",
            "rooms"
        ],
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "room, rooms",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "room",
        "id": "qau16FFw8JpaI39h2LLo"
    },
    {
        "inflexion": "family, families",
        "model": "lang-en",
        "term": "family",
        "workspace_id": "anglais",
        "inflexions": [
            "family",
            "families"
        ],
        "lang": "en-fr",
        "rank": 231,
        "wt": "n",
        "infexions": [
            "family",
            "families"
        ],
        "isPareto": true,
        "isVisual": true,
        "id": "yfZM3GosD5hDwH3pTucW"
    },
    {
        "infexions": [
            "stay",
            "stayed",
            "staying",
            "stays"
        ],
        "rank": 232,
        "lang": "en-fr",
        "inflexions": [
            "stay",
            "stayed",
            "staying",
            "stays"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "v",
        "term": "stay",
        "inflexion": "stay, stayed, staying, stays",
        "isPareto": true,
        "id": "AnkiCafCTqHhSAmZWXpl"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "lose",
            "loses",
            "losing",
            "lost"
        ],
        "rank": 233,
        "lang": "en-fr",
        "inflexion": "lose, loses, losing, lost",
        "term": "lose",
        "isPareto": true,
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "lose",
            "loses",
            "losing",
            "lost"
        ],
        "id": "L3kju16G0GmXHVe2xoQ5"
    },
    {
        "inflexions": [
            "stuff"
        ],
        "inflexion": "stuff",
        "workspace_id": "anglais",
        "infexions": [
            "stuff"
        ],
        "lang": "en-fr",
        "wt": "n",
        "rank": 234,
        "model": "lang-en",
        "id": "I2KzZNC1WXXVebo6k8cA",
        "context": "What do you say we go and get our stuff ready?",
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW",
        "term": "stuff",
        "isPareto": true,
        "order": 4
    },
    {
        "workspace_id": "anglais",
        "inflexion": "son, sons",
        "rank": 235,
        "model": "lang-en",
        "wt": "n",
        "isVisual": true,
        "term": "son",
        "lang": "en-fr",
        "infexions": [
            "son",
            "sons"
        ],
        "inflexions": [
            "son",
            "sons"
        ],
        "isPareto": true,
        "id": "lUF2AsyJpoWOR1KZrMLp"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "stupid",
        "inflexions": [
            "stupid",
            "stupider",
            "stupidest"
        ],
        "rank": 236,
        "isVisual": true,
        "model": "lang-en",
        "infexions": [
            "stupid",
            "stupider",
            "stupidest"
        ],
        "inflexion": "stupid, stupider, stupidest",
        "wt": "j",
        "id": "dSD0WALNfl9YTPcNrXqT"
    },
    {
        "inflexion": "name, names",
        "model": "lang-en",
        "rank": 237,
        "infexions": [
            "name",
            "names"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "name",
        "inflexions": [
            "name",
            "names"
        ],
        "wt": "n",
        "id": "DFhNpP2r7VIyjJhspf33"
    },
    {
        "infexions": [
            "everybody"
        ],
        "lang": "en-fr",
        "wt": "fw",
        "term": "everybody",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "everybody"
        ],
        "rank": 238,
        "inflexion": "everybody",
        "id": "itE1SkKVsNrga8whyBWj"
    },
    {
        "wt": "v",
        "infexions": [
            "last",
            "lasted",
            "lasting",
            "lasts"
        ],
        "rank": 239,
        "isVisual": true,
        "inflexion": "last, lasted, lasting, lasts",
        "term": "last",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "last",
            "lasted",
            "lasting",
            "lasts"
        ],
        "id": "bso3V1xjxJEXQJTtnKtg"
    },
    {
        "isVisual": true,
        "inflexions": [
            "long",
            "longer",
            "longest"
        ],
        "term": "long",
        "workspace_id": "anglais",
        "rank": 240,
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "long, longer, longest",
        "wt": "r",
        "infexions": [
            "long",
            "longer",
            "longest"
        ],
        "id": "6BBh274zuMB7FKIC29yJ"
    },
    {
        "isVisual": false,
        "infexions": [
            "tonight"
        ],
        "term": "tonight",
        "isPareto": true,
        "rank": 241,
        "inflexion": "tonight",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "tonight"
        ],
        "wt": "r",
        "id": "jhQnRGRCPEkv3P6RTHCP"
    },
    {
        "isPareto": true,
        "infexions": [
            "child",
            "children"
        ],
        "workspace_id": "anglais",
        "inflexion": "child, children",
        "model": "lang-en",
        "term": "child",
        "rank": 242,
        "inflexions": [
            "child",
            "children"
        ],
        "lang": "en-fr",
        "wt": "n",
        "id": "y5gt8a2J8Rno1zjnLLoF"
    },
    {
        "inflexion": "sit, sat, sits, sitting",
        "lang": "en-fr",
        "rank": 243,
        "wt": "v",
        "model": "lang-en",
        "infexions": [
            "sit",
            "sat",
            "sits",
            "sitting"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "sit",
        "inflexions": [
            "sit",
            "sat",
            "sits",
            "sitting"
        ],
        "id": "Jo7UzAPevfa8JO41Zz7h"
    },
    {
        "rank": 244,
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "course",
        "inflexions": [
            "course",
            "courses"
        ],
        "inflexion": "course, courses",
        "infexions": [
            "course",
            "courses"
        ],
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "id": "MBMC0XsGIz1hNJCaMVxL"
    },
    {
        "model": "lang-en",
        "wt": "j",
        "workspace_id": "anglais",
        "infexions": [
            "pretty",
            "prettier",
            "prettiest"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "pretty, prettier, prettiest",
        "rank": 245,
        "isVisual": true,
        "term": "pretty",
        "inflexions": [
            "pretty",
            "prettier",
            "prettiest"
        ],
        "id": "lklsfDNbf7yv6MwjHyhM"
    },
    {
        "rank": 246,
        "lang": "en-fr",
        "inflexion": "hold, held, holding, holds",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "hold",
            "held",
            "holding",
            "holds"
        ],
        "inflexions": [
            "hold",
            "held",
            "holding",
            "holds"
        ],
        "wt": "v",
        "term": "hold",
        "isPareto": true,
        "id": "DkquTXZsefSMcZFY4Ec3"
    },
    {
        "inflexion": "game, games",
        "term": "game",
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "isVisual": true,
        "wt": "n",
        "rank": 247,
        "inflexions": [
            "game",
            "games"
        ],
        "isPareto": true,
        "infexions": [
            "game",
            "games"
        ],
        "id": "Ecr3wBwkdaTe42V8SK0v"
    },
    {
        "rank": 248,
        "inflexions": [
            "forget",
            "forgets",
            "forgetting",
            "forgot",
            "forgotten"
        ],
        "inflexion": "forget, forgets, forgetting, forgot, forgotten",
        "wt": "v",
        "isPareto": true,
        "isVisual": false,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "forget",
            "forgets",
            "forgetting",
            "forgot",
            "forgotten"
        ],
        "model": "lang-en",
        "term": "forget",
        "id": "CQFkyqnM8lpnNYeVjx3H"
    },
    {
        "inflexion": "else",
        "isPareto": true,
        "inflexions": [
            "else"
        ],
        "model": "lang-en",
        "term": "else",
        "infexions": [
            "else"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "r",
        "rank": 249,
        "id": "Qn8cAOc9FZYZuYOKKtiw"
    },
    {
        "lang": "en-fr",
        "inflexion": "own",
        "inflexions": [
            "own"
        ],
        "workspace_id": "anglais",
        "wt": "j",
        "infexions": [
            "own"
        ],
        "term": "own",
        "model": "lang-en",
        "rank": 250,
        "isPareto": true,
        "id": "bgIpMpqhckyHvhrMfK8f"
    },
    {
        "wt": "m",
        "inflexions": [
            "five",
            "fives"
        ],
        "infexions": [
            "five",
            "fives"
        ],
        "isPareto": true,
        "rank": 251,
        "model": "lang-en",
        "term": "five",
        "workspace_id": "anglais",
        "inflexion": "five, fives",
        "lang": "en-fr",
        "id": "l290IHtjnM2HTrcB7ZcP"
    },
    {
        "workspace_id": "anglais",
        "wt": "m",
        "rank": 252,
        "inflexion": "second",
        "inflexions": [
            "second"
        ],
        "isPareto": true,
        "infexions": [
            "second"
        ],
        "isVisual": true,
        "model": "lang-en",
        "lang": "en-fr",
        "term": "second",
        "id": "Zu3SZUkBcHeRsgq1I39A"
    },
    {
        "isPareto": true,
        "term": "doctor",
        "wt": "n",
        "inflexions": [
            "doctor",
            "doctors"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "rank": 253,
        "workspace_id": "anglais",
        "infexions": [
            "doctor",
            "doctors"
        ],
        "model": "lang-en",
        "inflexion": "doctor, doctors",
        "id": "K812nRi0fYKSMBoyuVu8"
    },
    {
        "inflexion": "dollar, dollars",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "dollar",
            "dollars"
        ],
        "isPareto": true,
        "term": "dollar",
        "model": "lang-en",
        "rank": 254,
        "wt": "n",
        "infexions": [
            "dollar",
            "dollars"
        ],
        "id": "UBTp9SvScbizoAYfBOfw"
    },
    {
        "inflexions": [
            "enough"
        ],
        "wt": "fw",
        "term": "enough",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 255,
        "inflexion": "enough",
        "infexions": [
            "enough"
        ],
        "model": "lang-en",
        "id": "OGL4T1rxu2sY1cE1qxES"
    },
    {
        "wt": "n",
        "inflexions": [
            "dog",
            "dogs"
        ],
        "lang": "en-fr",
        "rank": 256,
        "model": "lang-en",
        "infexions": [
            "dog",
            "dogs"
        ],
        "inflexion": "dog, dogs",
        "isPareto": true,
        "term": "dog",
        "workspace_id": "anglais",
        "id": "Jqe8u9ADQg1HgSapjoaZ"
    },
    {
        "wt": "j",
        "model": "lang-en",
        "inflexion": "funny, funnier, funniest",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 257,
        "isVisual": true,
        "term": "funny",
        "infexions": [
            "funny",
            "funnier",
            "funniest"
        ],
        "lang": "en-fr",
        "inflexions": [
            "funny",
            "funnier",
            "funniest"
        ],
        "id": "wC4ccbeEXlSUtQXWQNON"
    },
    {
        "infexions": [
            "wear",
            "wearing",
            "wears",
            "wore",
            "worn"
        ],
        "term": "wear",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "wear",
            "wearing",
            "wears",
            "wore",
            "worn"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 258,
        "inflexion": "wear, wearing, wears, wore, worn",
        "wt": "v",
        "id": "77BTi4wkF15L0WB2JPR8"
    },
    {
        "isPareto": true,
        "rank": 259,
        "workspace_id": "anglais",
        "inflexion": "die, died, dies, dying",
        "term": "die",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "die",
            "died",
            "dies",
            "dying"
        ],
        "isVisual": true,
        "wt": "v",
        "inflexions": [
            "die",
            "died",
            "dies",
            "dying"
        ],
        "id": "gZLUEp62slSBDJ5C40xg"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "sir",
            "sirs"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "sir, sirs",
        "term": "sir",
        "isVisual": true,
        "isPareto": true,
        "wt": "n",
        "rank": 260,
        "inflexions": [
            "sir",
            "sirs"
        ],
        "id": "hWgLXpfdO7Hzqbf9Klnm"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "hard, harder, hardest",
        "isPareto": true,
        "term": "hard",
        "lang": "en-fr",
        "isVisual": true,
        "inflexions": [
            "hard",
            "harder",
            "hardest"
        ],
        "model": "lang-en",
        "wt": "j",
        "rank": 261,
        "infexions": [
            "hard",
            "harder",
            "hardest"
        ],
        "id": "SD49VOhTM7S1IwxpJsSP"
    },
    {
        "term": "honey",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "honey"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isVisual": true,
        "infexions": [
            "honey"
        ],
        "rank": 262,
        "inflexion": "honey",
        "wt": "n",
        "id": "lBMvB4MNLycDBFkfSsKd"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "sound",
            "sounded",
            "sounding",
            "sounds"
        ],
        "inflexions": [
            "sound",
            "sounded",
            "sounding",
            "sounds"
        ],
        "term": "sound",
        "rank": 263,
        "lang": "en-fr",
        "inflexion": "sound, sounded, sounding, sounds",
        "wt": "v",
        "isPareto": true,
        "model": "lang-en",
        "id": "4TGryCKcdr5sukglUCVA"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "term": "sex",
        "inflexion": "sex, sexes",
        "infexions": [
            "sex",
            "sexes"
        ],
        "rank": 264,
        "lang": "en-fr",
        "isVisual": true,
        "inflexions": [
            "sex",
            "sexes"
        ],
        "model": "lang-en",
        "isPareto": true,
        "id": "tI0G9WsW4uVLEp5LIUCQ"
    },
    {
        "term": "hate",
        "isPareto": true,
        "inflexion": "hate, hated, hates, hating",
        "workspace_id": "anglais",
        "rank": 265,
        "wt": "v",
        "infexions": [
            "hate",
            "hated",
            "hates",
            "hating"
        ],
        "lang": "en-fr",
        "inflexions": [
            "hate",
            "hated",
            "hates",
            "hating"
        ],
        "model": "lang-en",
        "id": "DpFSjxmUao04LYRKSnEo"
    },
    {
        "model": "lang-en",
        "inflexion": "suppose, supposed, supposes, supposing",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "suppose",
            "supposed",
            "supposes",
            "supposing"
        ],
        "term": "suppose",
        "rank": 266,
        "infexions": [
            "suppose",
            "supposed",
            "supposes",
            "supposing"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "id": "YV5VkiLoGl2h1vvNfTQ9"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "head",
            "heads"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "isPareto": true,
        "term": "head",
        "rank": 267,
        "wt": "n",
        "lang": "en-fr",
        "infexions": [
            "head",
            "heads"
        ],
        "inflexion": "head, heads",
        "id": "msdkwskuYwQi2hzr5q3O"
    },
    {
        "inflexions": [
            "understand",
            "understanding",
            "understands",
            "understood"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "understand",
            "understanding",
            "understands",
            "understood"
        ],
        "inflexion": "understand, understanding, understands, understood",
        "workspace_id": "anglais",
        "rank": 268,
        "wt": "v",
        "term": "understand",
        "id": "oGAjBL7l3NZfzvWO5zAr"
    },
    {
        "term": "movie",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "movie",
            "movies"
        ],
        "rank": 269,
        "workspace_id": "anglais",
        "inflexion": "movie, movies",
        "inflexions": [
            "movie",
            "movies"
        ],
        "isVisual": true,
        "isPareto": true,
        "wt": "n",
        "id": "WtrE838nxsLqdJ3sSgg1"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "worry",
            "worried",
            "worries",
            "worrying"
        ],
        "workspace_id": "anglais",
        "rank": 270,
        "model": "lang-en",
        "infexions": [
            "worry",
            "worried",
            "worries",
            "worrying"
        ],
        "inflexion": "worry, worried, worries, worrying",
        "wt": "v",
        "isVisual": true,
        "term": "worry",
        "id": "1uOnQ5zFOEGZkYndF8Z7"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "cool",
            "cooler",
            "coolest"
        ],
        "inflexion": "cool, cooler, coolest",
        "rank": 271,
        "infexions": [
            "cool",
            "cooler",
            "coolest"
        ],
        "model": "lang-en",
        "term": "cool",
        "lang": "en-fr",
        "isVisual": true,
        "isPareto": true,
        "wt": "j",
        "id": "rK8ryzulQC46Dky1xqp5"
    },
    {
        "wt": "v",
        "rank": 272,
        "model": "lang-en",
        "inflexion": "marry, married, marries, marrying",
        "isPareto": true,
        "isVisual": true,
        "infexions": [
            "marry",
            "married",
            "marries",
            "marrying"
        ],
        "inflexions": [
            "marry",
            "married",
            "marries",
            "marrying"
        ],
        "term": "marry",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "qBf0BkWDhcTEMWdhAKMY"
    },
    {
        "inflexion": "miss, missed, misses, missing",
        "isVisual": true,
        "isPareto": true,
        "model": "lang-en",
        "rank": 273,
        "infexions": [
            "miss",
            "missed",
            "misses",
            "missing"
        ],
        "term": "miss",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "v",
        "inflexions": [
            "miss",
            "missed",
            "misses",
            "missing"
        ],
        "id": "b9jPLjofxCWXrYiTeMXI"
    },
    {
        "wt": "v",
        "isVisual": true,
        "model": "lang-en",
        "inflexions": [
            "pay",
            "paid",
            "paying",
            "pays"
        ],
        "rank": 274,
        "inflexion": "pay, paid, paying, pays",
        "term": "pay",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "pay",
            "paid",
            "paying",
            "pays"
        ],
        "workspace_id": "anglais",
        "id": "1eHchs7sCpG89fs07wkb"
    },
    {
        "inflexion": "hour, hours",
        "isVisual": true,
        "model": "lang-en",
        "rank": 275,
        "lang": "en-fr",
        "inflexions": [
            "hour",
            "hours"
        ],
        "wt": "n",
        "workspace_id": "anglais",
        "term": "hour",
        "isPareto": true,
        "infexions": [
            "hour",
            "hours"
        ],
        "id": "4rQVxKbxb3Zznjkohk1t"
    },
    {
        "wt": "j",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "crazy",
            "crazier",
            "craziest"
        ],
        "isVisual": true,
        "isPareto": true,
        "inflexions": [
            "crazy",
            "crazier",
            "craziest"
        ],
        "inflexion": "crazy, crazier, craziest",
        "term": "crazy",
        "rank": 276,
        "model": "lang-en",
        "id": "2tSvmN3Df9ydsMaIlcid"
    },
    {
        "lang": "en-fr",
        "isVisual": true,
        "rank": 277,
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "change",
        "inflexions": [
            "change",
            "changed",
            "changes",
            "changing"
        ],
        "model": "lang-en",
        "infexions": [
            "change",
            "changed",
            "changes",
            "changing"
        ],
        "inflexion": "change, changed, changes, changing",
        "wt": "v",
        "id": "bjsyK135xs4GdQP398dI"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "hot",
            "hotter",
            "hottest"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "rank": 278,
        "isPareto": true,
        "term": "hot",
        "infexions": [
            "hot",
            "hotter",
            "hottest"
        ],
        "wt": "j",
        "inflexion": "hot, hotter, hottest",
        "id": "znpTSY7BvM4hK1og46bs"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "most",
        "term": "most",
        "model": "lang-en",
        "inflexions": [
            "most"
        ],
        "isPareto": true,
        "infexions": [
            "most"
        ],
        "wt": "fw",
        "rank": 279,
        "id": "mXGR3BudEGS0aEqpdigm"
    },
    {
        "workspace_id": "anglais",
        "term": "excuse",
        "infexions": [
            "excuse",
            "excused",
            "excuses",
            "excusing"
        ],
        "inflexion": "excuse, excused, excuses, excusing",
        "rank": 280,
        "isPareto": true,
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "excuse",
            "excused",
            "excuses",
            "excusing"
        ],
        "lang": "en-fr",
        "id": "jgaSmuXjEbcV70z9iZlb"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "isVisual": true,
        "inflexion": "mother, mothers",
        "wt": "n",
        "term": "mother",
        "inflexions": [
            "mother",
            "mothers"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 281,
        "infexions": [
            "mother",
            "mothers"
        ],
        "id": "7SyTKhAmRdRBf2Cvr7he"
    },
    {
        "model": "lang-en",
        "rank": 282,
        "isPareto": true,
        "lang": "en-fr",
        "term": "check",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "check",
            "checked",
            "checking",
            "checks"
        ],
        "infexions": [
            "check",
            "checked",
            "checking",
            "checks"
        ],
        "inflexion": "check, checked, checking, checks",
        "id": "ao5idNpQC5Gi6IaIXfB7"
    },
    {
        "lang": "en-fr",
        "inflexion": "pick, picked, picking, picks",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "pick",
        "infexions": [
            "pick",
            "picked",
            "picking",
            "picks"
        ],
        "rank": 283,
        "wt": "v",
        "inflexions": [
            "pick",
            "picked",
            "picking",
            "picks"
        ],
        "id": "C3YbL5rkGwbcQyflrZmD"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "word",
            "words"
        ],
        "wt": "n",
        "inflexion": "word, words",
        "inflexions": [
            "word",
            "words"
        ],
        "model": "lang-en",
        "rank": 284,
        "isPareto": true,
        "term": "word",
        "lang": "en-fr",
        "id": "9ZFBxKzG7hxOl7PUqVH7"
    },
    {
        "lang": "en-fr",
        "inflexion": "same, sameness",
        "term": "same",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "same",
            "sameness"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "same",
            "sameness"
        ],
        "wt": "j",
        "rank": 285,
        "id": "73czprPVASpTXNAr7SRz"
    },
    {
        "inflexions": [
            "yourself",
            "yourselves"
        ],
        "infexions": [
            "yourself",
            "yourselves"
        ],
        "isPareto": true,
        "inflexion": "yourself, yourselves",
        "model": "lang-en",
        "rank": 286,
        "wt": "fw",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "yourself",
        "id": "mrK5bol7kuqad47ceind"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "j",
        "inflexion": "ready, readier, readiest",
        "inflexions": [
            "ready",
            "readier",
            "readiest"
        ],
        "term": "ready",
        "rank": 287,
        "model": "lang-en",
        "infexions": [
            "ready",
            "readier",
            "readiest"
        ],
        "lang": "en-fr",
        "id": "dw2FvstIv9SM4Z2fsDDR"
    },
    {
        "inflexions": [
            "seem",
            "seemed",
            "seeming",
            "seems"
        ],
        "infexions": [
            "seem",
            "seemed",
            "seeming",
            "seems"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 288,
        "inflexion": "seem, seemed, seeming, seems",
        "wt": "v",
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "seem",
        "id": "163E7Werq3wMU00gIGAv"
    },
    {
        "wt": "v",
        "term": "win",
        "inflexions": [
            "win",
            "winning",
            "wins",
            "won"
        ],
        "model": "lang-en",
        "infexions": [
            "win",
            "winning",
            "wins",
            "won"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 289,
        "inflexion": "win, winning, wins, won",
        "lang": "en-fr",
        "id": "YAdQFVdDmKodjSMghXB3"
    },
    {
        "isPareto": true,
        "infexions": [
            "walk",
            "walked",
            "walking",
            "walks"
        ],
        "inflexions": [
            "walk",
            "walked",
            "walking",
            "walks"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "walk, walked, walking, walks",
        "wt": "v",
        "rank": 290,
        "term": "walk",
        "model": "lang-en",
        "id": "rMIJDM1VR0MABwFmquks"
    },
    {
        "inflexions": [
            "father",
            "fathers"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "infexions": [
            "father",
            "fathers"
        ],
        "lang": "en-fr",
        "inflexion": "father, fathers",
        "model": "lang-en",
        "wt": "n",
        "rank": 291,
        "isPareto": true,
        "term": "father",
        "id": "P5tYzcntrXYx8JP8jpEH"
    },
    {
        "infexions": [
            "story",
            "stories"
        ],
        "isPareto": true,
        "term": "story",
        "wt": "n",
        "rank": 292,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "story",
            "stories"
        ],
        "isVisual": true,
        "inflexion": "story, stories",
        "model": "lang-en",
        "id": "Gp2Nr4SLVz8wYn68ZU4G"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "already",
        "model": "lang-en",
        "inflexions": [
            "already"
        ],
        "infexions": [
            "already"
        ],
        "workspace_id": "anglais",
        "wt": "r",
        "rank": 293,
        "term": "already",
        "id": "17I71d0LvngHTO3ioudl"
    },
    {
        "wt": "v",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "hope, hoped, hopes, hoping",
        "infexions": [
            "hope",
            "hoped",
            "hopes",
            "hoping"
        ],
        "rank": 294,
        "term": "hope",
        "inflexions": [
            "hope",
            "hoped",
            "hopes",
            "hoping"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "id": "UG4qwCsNfwKSWoU0og2U"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "part",
            "parts"
        ],
        "term": "part",
        "workspace_id": "anglais",
        "rank": 295,
        "inflexion": "part, parts",
        "isPareto": true,
        "wt": "n",
        "model": "lang-en",
        "infexions": [
            "part",
            "parts"
        ],
        "id": "KNLrNSFGgJNoyjWOx556"
    },
    {
        "inflexions": [
            "open",
            "opened",
            "opening",
            "opens"
        ],
        "term": "open",
        "rank": 296,
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "open",
            "opened",
            "opening",
            "opens"
        ],
        "inflexion": "open, opened, opening, opens",
        "wt": "v",
        "id": "Rjd6ihFIYxDmYzfEj9JO"
    },
    {
        "model": "lang-en",
        "inflexion": "lady, ladies",
        "term": "lady",
        "rank": 297,
        "workspace_id": "anglais",
        "isVisual": true,
        "wt": "n",
        "isPareto": true,
        "infexions": [
            "lady",
            "ladies"
        ],
        "lang": "en-fr",
        "inflexions": [
            "lady",
            "ladies"
        ],
        "id": "kJweZ9eOXkKcuMZnVEra"
    },
    {
        "infexions": [
            "read",
            "reading",
            "reads"
        ],
        "rank": 298,
        "isVisual": true,
        "wt": "v",
        "model": "lang-en",
        "term": "read",
        "inflexion": "read, reading, reads",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "read",
            "reading",
            "reads"
        ],
        "lang": "en-fr",
        "id": "sRkcx09wY8qQUkNyPlQa"
    },
    {
        "infexions": [
            "drink",
            "drank",
            "drinking",
            "drinks",
            "drunk"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "model": "lang-en",
        "rank": 299,
        "inflexions": [
            "drink",
            "drank",
            "drinking",
            "drinks",
            "drunk"
        ],
        "inflexion": "drink, drank, drinking, drinks, drunk",
        "lang": "en-fr",
        "term": "drink",
        "isPareto": true,
        "wt": "v",
        "id": "fWTHuIaonmh2bX8oPlji"
    },
    {
        "model": "lang-en",
        "infexions": [
            "sleep",
            "sleeping",
            "sleeps",
            "slept"
        ],
        "inflexion": "sleep, sleeping, sleeps, slept",
        "term": "sleep",
        "lang": "en-fr",
        "wt": "v",
        "inflexions": [
            "sleep",
            "sleeping",
            "sleeps",
            "slept"
        ],
        "isVisual": false,
        "rank": 300,
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "x1VT234tCz3zpvpL4kQy"
    },
    {
        "term": "number",
        "wt": "n",
        "rank": 301,
        "workspace_id": "anglais",
        "isVisual": true,
        "inflexion": "number, numbers",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "number",
            "numbers"
        ],
        "inflexions": [
            "number",
            "numbers"
        ],
        "id": "W8l0ql2pDiEBzcmXaaVm"
    },
    {
        "inflexion": "write, writes, writing, written, wrote",
        "workspace_id": "anglais",
        "rank": 302,
        "isPareto": true,
        "wt": "v",
        "isVisual": true,
        "term": "write",
        "inflexions": [
            "write",
            "writes",
            "writing",
            "written",
            "wrote"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "write",
            "writes",
            "writing",
            "written",
            "wrote"
        ],
        "id": "5sU4O6oCrDcOy6l3mjAY"
    },
    {
        "isPareto": true,
        "isVisual": true,
        "inflexion": "morning, mornings",
        "wt": "n",
        "term": "morning",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "morning",
            "mornings"
        ],
        "lang": "en-fr",
        "infexions": [
            "morning",
            "mornings"
        ],
        "rank": 303,
        "id": "7A8KrRLQSqYvSFVIkwy1"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "tomorrow",
        "inflexions": [
            "tomorrow",
            "tomorrows"
        ],
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "tomorrow",
            "tomorrows"
        ],
        "rank": 304,
        "inflexion": "tomorrow, tomorrows",
        "id": "MesJbqfCgvnTus3ytvLB"
    },
    {
        "term": "next",
        "inflexion": "next",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "next"
        ],
        "wt": "j",
        "rank": 305,
        "workspace_id": "anglais",
        "infexions": [
            "next"
        ],
        "id": "zY3tXZc0lj1ftwywVsl9"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "wt": "n",
        "inflexions": [
            "phone",
            "phones",
            "telephone",
            "telephones"
        ],
        "term": "phone",
        "isVisual": true,
        "rank": 306,
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "phone",
            "phones",
            "telephone",
            "telephones"
        ],
        "inflexion": "phone, phones, telephone, telephones",
        "id": "Ty3HYRReu4GW4cQb33Ml"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "wt": "m",
        "workspace_id": "anglais",
        "rank": 307,
        "lang": "en-fr",
        "infexions": [
            "four",
            "fours"
        ],
        "term": "four",
        "inflexions": [
            "four",
            "fours"
        ],
        "inflexion": "four, fours",
        "id": "EBgE1GcAaQBNE2Fnfjeh"
    },
    {
        "infexions": [
            "last"
        ],
        "inflexion": "last",
        "model": "lang-en",
        "rank": 308,
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "m",
        "lang": "en-fr",
        "inflexions": [
            "last"
        ],
        "term": "last",
        "id": "F9N5QOfLOt1ztIoIeKuJ"
    },
    {
        "infexions": [
            "once"
        ],
        "term": "once",
        "lang": "en-fr",
        "rank": 309,
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "once",
        "inflexions": [
            "once"
        ],
        "wt": "r",
        "id": "wp8WCXDZXnXF13EsgM5u"
    },
    {
        "rank": 310,
        "model": "lang-en",
        "inflexions": [
            "somebody"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "somebody",
        "inflexion": "somebody",
        "wt": "fw",
        "infexions": [
            "somebody"
        ],
        "isPareto": true,
        "id": "wwBd0cLGihRcuAttbJkN"
    },
    {
        "inflexions": [
            "probably"
        ],
        "rank": 311,
        "infexions": [
            "probably"
        ],
        "inflexion": "probably",
        "term": "probably",
        "lang": "en-fr",
        "wt": "r",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "SZGJiQGcVPOnHgZi7eow"
    },
    {
        "inflexion": "without",
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 312,
        "term": "without",
        "inflexions": [
            "without"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "wt": "fw",
        "infexions": [
            "without"
        ],
        "id": "U1oypP8frubhLR3ONBCE"
    },
    {
        "rank": 313,
        "wt": "fw",
        "workspace_id": "anglais",
        "inflexions": [
            "many"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "term": "many",
        "inflexion": "many",
        "infexions": [
            "many"
        ],
        "id": "X2jr4JItyCF9bzgazMGY"
    },
    {
        "wt": "fw",
        "infexions": [
            "such"
        ],
        "lang": "en-fr",
        "term": "such",
        "isPareto": true,
        "rank": 314,
        "inflexion": "such",
        "inflexions": [
            "such"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "EkZt9SK7P2u0s4iL51zP"
    },
    {
        "infexions": [
            "eye",
            "eyes"
        ],
        "inflexion": "eye, eyes",
        "lang": "en-fr",
        "isPareto": true,
        "isVisual": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 315,
        "inflexions": [
            "eye",
            "eyes"
        ],
        "wt": "n",
        "term": "eye",
        "id": "4AuKo6JZDNrD2RjgdEBw"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isVisual": true,
        "inflexions": [
            "drive",
            "driven",
            "drives",
            "driving",
            "drove"
        ],
        "model": "lang-en",
        "rank": 316,
        "term": "drive",
        "infexions": [
            "drive",
            "driven",
            "drives",
            "driving",
            "drove"
        ],
        "isPareto": true,
        "wt": "v",
        "inflexion": "drive, driven, drives, driving, drove",
        "id": "YKf6i4KNOkNIneiNXEgt"
    },
    {
        "model": "lang-en",
        "infexions": [
            "wife",
            "wives"
        ],
        "inflexions": [
            "wife",
            "wives"
        ],
        "workspace_id": "anglais",
        "term": "wife",
        "isPareto": true,
        "isVisual": true,
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "wife, wives",
        "rank": 317,
        "id": "W8UISCs7RwGzdhPJdcNW"
    },
    {
        "rank": 318,
        "isPareto": true,
        "inflexion": "book, books",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "book",
            "books"
        ],
        "term": "book",
        "wt": "n",
        "infexions": [
            "book",
            "books"
        ],
        "id": "WiJqkCOzw5s0Nwor2MdL"
    },
    {
        "inflexions": [
            "hang",
            "hanged",
            "hanging",
            "hangs",
            "hung"
        ],
        "lang": "en-fr",
        "rank": 319,
        "workspace_id": "anglais",
        "wt": "v",
        "infexions": [
            "hang",
            "hanged",
            "hanging",
            "hangs",
            "hung"
        ],
        "isPareto": true,
        "inflexion": "hang, hanged, hanging, hangs, hung",
        "model": "lang-en",
        "term": "hang",
        "id": "kQT8xGvHWzuCfALCVr2t"
    },
    {
        "wt": "fw",
        "workspace_id": "anglais",
        "inflexion": "since",
        "lang": "en-fr",
        "infexions": [
            "since"
        ],
        "rank": 320,
        "inflexions": [
            "since"
        ],
        "isPareto": true,
        "model": "lang-en",
        "term": "since",
        "id": "822Bnuk551jEpZUbztrP"
    },
    {
        "isVisual": true,
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "throw",
            "threw",
            "throwing",
            "thrown",
            "throws"
        ],
        "rank": 321,
        "term": "throw",
        "inflexion": "throw, threw, throwing, thrown, throws",
        "model": "lang-en",
        "inflexions": [
            "throw",
            "threw",
            "throwing",
            "thrown",
            "throws"
        ],
        "lang": "en-fr",
        "wt": "v",
        "id": "aKG7r8abvMWV60IqgrdA"
    },
    {
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "name",
            "named",
            "naming",
            "names"
        ],
        "lang": "en-fr",
        "rank": 322,
        "inflexion": "name, named, naming, names",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "name",
        "infexions": [
            "name",
            "named",
            "naming",
            "names"
        ],
        "id": "PpgXQeAFDvddEDH8y4TR"
    },
    {
        "term": "dead",
        "inflexion": "dead",
        "rank": 323,
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "isVisual": true,
        "wt": "j",
        "inflexions": [
            "dead"
        ],
        "infexions": [
            "dead"
        ],
        "workspace_id": "anglais",
        "id": "4hCdS0sCyPIqq8utXvVK"
    },
    {
        "inflexion": "stand, standing, stands, stood",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "stand",
            "standing",
            "stands",
            "stood"
        ],
        "isVisual": true,
        "rank": 324,
        "workspace_id": "anglais",
        "term": "stand",
        "infexions": [
            "stand",
            "standing",
            "stands",
            "stood"
        ],
        "wt": "v",
        "id": "ab2HSeU9FaRNPEBZfU4d"
    },
    {
        "isPareto": true,
        "term": "myself",
        "infexions": [
            "myself"
        ],
        "model": "lang-en",
        "inflexion": "myself",
        "workspace_id": "anglais",
        "wt": "fw",
        "rank": 325,
        "inflexions": [
            "myself"
        ],
        "lang": "en-fr",
        "id": "v3zQwVX7CJJJBCL7q3Oa"
    },
    {
        "model": "lang-en",
        "infexions": [
            ""
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "",
        "rank": 326,
        "inflexions": [
            ""
        ],
        "isPareto": true,
        "inflexion": "",
        "wt": "",
        "id": "ggcvduNl6LITQw0ee83n"
    },
    {
        "inflexions": [
            "dinner",
            "dinners"
        ],
        "inflexion": "dinner, dinners",
        "isVisual": true,
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "n",
        "term": "dinner",
        "lang": "en-fr",
        "infexions": [
            "dinner",
            "dinners"
        ],
        "model": "lang-en",
        "rank": 327,
        "id": "jjMDDsD6Ahgi0lBKKsLn"
    },
    {
        "term": "anyone",
        "model": "lang-en",
        "inflexion": "anyone",
        "workspace_id": "anglais",
        "inflexions": [
            "anyone"
        ],
        "isPareto": true,
        "wt": "fw",
        "infexions": [
            "anyone"
        ],
        "lang": "en-fr",
        "rank": 328,
        "id": "1fyNcb54NKOTn1zXBFM2"
    },
    {
        "model": "lang-en",
        "rank": 329,
        "infexions": [
            "hand",
            "hands"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "hand",
            "hands"
        ],
        "wt": "n",
        "inflexion": "hand, hands",
        "term": "hand",
        "isPareto": true,
        "id": "BniMtulifomrxrbFuKX7"
    },
    {
        "infexions": [
            "each"
        ],
        "lang": "en-fr",
        "inflexion": "each",
        "rank": 330,
        "wt": "fw",
        "inflexions": [
            "each"
        ],
        "term": "each",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "I67NaomRP6mL7hxK1Nx5"
    },
    {
        "inflexion": "anyway",
        "workspace_id": "anglais",
        "infexions": [
            "anyway"
        ],
        "lang": "en-fr",
        "wt": "r",
        "isPareto": true,
        "model": "lang-en",
        "term": "anyway",
        "rank": 331,
        "inflexions": [
            "anyway"
        ],
        "id": "el62MuYm9GaesHZLHIRt"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "television, televisions",
        "model": "lang-en",
        "inflexions": [
            "television",
            "televisions"
        ],
        "wt": "n",
        "rank": 332,
        "term": "television",
        "infexions": [
            "television",
            "televisions"
        ],
        "lang": "en-fr",
        "id": "zD7X40abofiHaxjdlM8U"
    },
    {
        "inflexion": "learn, learned, learning, learns",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "learn",
            "learned",
            "learning",
            "learns"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "rank": 333,
        "infexions": [
            "learn",
            "learned",
            "learning",
            "learns"
        ],
        "term": "learn",
        "id": "aTWmoMdMJKMrEZEOTp6B"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "shut",
            "shuts",
            "shutting"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "shut",
            "shuts",
            "shutting"
        ],
        "inflexion": "shut, shuts, shutting",
        "term": "shut",
        "rank": 334,
        "wt": "v",
        "workspace_id": "anglais",
        "id": "VBoCUpPzxo4wjVjlGPIl"
    },
    {
        "isPareto": true,
        "inflexions": [
            "town",
            "towns"
        ],
        "infexions": [
            "town",
            "towns"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "term": "town",
        "inflexion": "town, towns",
        "rank": 335,
        "wt": "n",
        "workspace_id": "anglais",
        "isVisual": true,
        "id": "rXoQlrUQnoVc44Qk9B9M"
    },
    {
        "inflexion": "beautiful",
        "workspace_id": "anglais",
        "infexions": [
            "beautiful"
        ],
        "term": "beautiful",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "beautiful"
        ],
        "wt": "j",
        "rank": 336,
        "id": "1mCrDm98pBkJI7nNL7lm"
    },
    {
        "inflexion": "both",
        "rank": 337,
        "inflexions": [
            "both"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "term": "both",
        "infexions": [
            "both"
        ],
        "wt": "fw",
        "id": "D3dXWI5owpgiFEqLB45S"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 338,
        "wt": "n",
        "isPareto": true,
        "infexions": [
            "date",
            "dates"
        ],
        "inflexions": [
            "date",
            "dates"
        ],
        "lang": "en-fr",
        "term": "date",
        "inflexion": "date, dates",
        "id": "4eBKVDQfS59LaiLYdDVI"
    },
    {
        "wt": "v",
        "inflexion": "spend, spending, spends, spent",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "spend",
            "spending",
            "spends",
            "spent"
        ],
        "term": "spend",
        "infexions": [
            "spend",
            "spending",
            "spends",
            "spent"
        ],
        "rank": 339,
        "id": "o3vH1621iu8Y1IzNQIfm"
    },
    {
        "inflexion": "office, offices",
        "model": "lang-en",
        "isPareto": true,
        "rank": 340,
        "infexions": [
            "office",
            "offices"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "office",
            "offices"
        ],
        "term": "office",
        "lang": "en-fr",
        "id": "GY0SbRjEfgDfCyuZ5ZZl"
    },
    {
        "lang": "en-fr",
        "wt": "v",
        "term": "hit",
        "isPareto": true,
        "infexions": [
            "hit",
            "hits",
            "hitting"
        ],
        "inflexions": [
            "hit",
            "hits",
            "hitting"
        ],
        "workspace_id": "anglais",
        "rank": 341,
        "inflexion": "hit, hits, hitting",
        "model": "lang-en",
        "id": "NpzbmY7GXJvcddYT6UTx"
    },
    {
        "rank": 342,
        "workspace_id": "anglais",
        "inflexion": "yet",
        "isPareto": true,
        "infexions": [
            "yet"
        ],
        "model": "lang-en",
        "wt": "fw",
        "term": "yet",
        "lang": "en-fr",
        "inflexions": [
            "yet"
        ],
        "id": "jyhAiBAjwVnW4YX077fS"
    },
    {
        "lang": "en-fr",
        "inflexion": "save, saved, saves, saving",
        "model": "lang-en",
        "wt": "v",
        "rank": 343,
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "save",
        "infexions": [
            "save",
            "saved",
            "saves",
            "saving"
        ],
        "inflexions": [
            "save",
            "saved",
            "saves",
            "saving"
        ],
        "id": "hbpTosb2nhjXaRv9EXzx"
    },
    {
        "wt": "j",
        "model": "lang-en",
        "infexions": [
            "true",
            "truer",
            "truest"
        ],
        "workspace_id": "anglais",
        "term": "true",
        "inflexion": "true, truer, truest",
        "lang": "en-fr",
        "inflexions": [
            "true",
            "truer",
            "truest"
        ],
        "rank": 344,
        "isPareto": true,
        "id": "0tygFN38tzEWBqYSFND5"
    },
    {
        "inflexion": "sweet, sweeter, sweetest",
        "inflexions": [
            "sweet",
            "sweeter",
            "sweetest"
        ],
        "isPareto": true,
        "infexions": [
            "sweet",
            "sweeter",
            "sweetest"
        ],
        "term": "sweet",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "j",
        "isVisual": true,
        "model": "lang-en",
        "rank": 345,
        "id": "4IvALUgH1Q9Tbh5iixkP"
    },
    {
        "model": "lang-en",
        "wt": "fw",
        "isPareto": true,
        "term": "until",
        "inflexions": [
            "until"
        ],
        "rank": 346,
        "infexions": [
            "until"
        ],
        "inflexion": "until",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "idPFir4DQYa1z4SxoVJD"
    },
    {
        "model": "lang-en",
        "rank": 347,
        "inflexions": [
            "food",
            "foods"
        ],
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "food",
            "foods"
        ],
        "inflexion": "food, foods",
        "term": "food",
        "lang": "en-fr",
        "id": "RDX4Z6xnardE4Oo7xJYY"
    },
    {
        "infexions": [
            "while"
        ],
        "isPareto": true,
        "wt": "fw",
        "inflexions": [
            "while"
        ],
        "workspace_id": "anglais",
        "term": "while",
        "lang": "en-fr",
        "inflexion": "while",
        "model": "lang-en",
        "rank": 348,
        "id": "W3urCRIUzkJhkKuBVg6U"
    },
    {
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "send",
            "sending",
            "sends",
            "sent"
        ],
        "lang": "en-fr",
        "inflexion": "send, sending, sends, sent",
        "rank": 349,
        "infexions": [
            "send",
            "sending",
            "sends",
            "sent"
        ],
        "term": "send",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "NsgQjSvexEEpfBQD1JWJ"
    },
    {
        "inflexion": "high, higher, highest",
        "inflexions": [
            "high",
            "higher",
            "highest"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "infexions": [
            "high",
            "higher",
            "highest"
        ],
        "lang": "en-fr",
        "term": "high",
        "isPareto": true,
        "wt": "j",
        "model": "lang-en",
        "rank": 350,
        "id": "krkP434Lkz2lRdz7pIF2"
    },
    {
        "infexions": [
            "anymore"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "term": "anymore",
        "inflexions": [
            "anymore"
        ],
        "workspace_id": "anglais",
        "inflexion": "anymore",
        "wt": "r",
        "model": "lang-en",
        "rank": 351,
        "id": "qWByMQCZWLCMralKZQ0h"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 352,
        "isPareto": true,
        "wt": "r",
        "inflexion": "also",
        "model": "lang-en",
        "infexions": [
            "also"
        ],
        "term": "also",
        "inflexions": [
            "also"
        ],
        "id": "S4SHSjmt2yQPagVqeKGL"
    },
    {
        "infexions": [
            "news"
        ],
        "rank": 353,
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "news",
        "inflexion": "news",
        "inflexions": [
            "news"
        ],
        "id": "UpsxlG6BMUGiAd22FrU0"
    },
    {
        "inflexions": [
            "Christmas"
        ],
        "infexions": [
            "Christmas"
        ],
        "rank": 354,
        "term": "Christmas",
        "isPareto": true,
        "inflexion": "Christmas",
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isVisual": false,
        "wt": "K",
        "id": "BIuu5hwbGyGJH9BRMJSn"
    },
    {
        "wt": "m",
        "model": "lang-en",
        "isPareto": true,
        "rank": 355,
        "workspace_id": "anglais",
        "term": "ten",
        "inflexion": "ten",
        "lang": "en-fr",
        "infexions": [
            "ten"
        ],
        "inflexions": [
            "ten"
        ],
        "id": "68plYm4uipIFcudQ9150"
    },
    {
        "term": "ass",
        "inflexions": [
            "ass",
            "asses"
        ],
        "lang": "en-fr",
        "rank": 356,
        "infexions": [
            "ass",
            "asses"
        ],
        "inflexion": "ass, asses",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "isVisual": true,
        "id": "9i19AyXuTQO1Wrgg3SAV"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "business, businesses",
        "rank": 357,
        "isVisual": true,
        "infexions": [
            "business",
            "businesses"
        ],
        "lang": "en-fr",
        "term": "business",
        "wt": "n",
        "model": "lang-en",
        "inflexions": [
            "business",
            "businesses"
        ],
        "id": "yerZikiYQ0co0zZzi08G"
    },
    {
        "isPareto": true,
        "wt": "j",
        "lang": "en-fr",
        "rank": 358,
        "workspace_id": "anglais",
        "term": "only",
        "inflexions": [
            "only"
        ],
        "model": "lang-en",
        "infexions": [
            "only"
        ],
        "inflexion": "only",
        "id": "eErG960Vr6uzhV58KSls"
    },
    {
        "term": "couple",
        "inflexions": [
            "couple",
            "couples"
        ],
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 359,
        "inflexion": "couple, couples",
        "model": "lang-en",
        "infexions": [
            "couple",
            "couples"
        ],
        "id": "2eVOxTNviE9G7KuAk8gr"
    },
    {
        "term": "totally",
        "rank": 360,
        "inflexions": [
            "totally"
        ],
        "inflexion": "totally",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "r",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "totally"
        ],
        "id": "xUSOAjhoiOCeoegO1ACd"
    },
    {
        "rank": 361,
        "isVisual": true,
        "inflexion": "door, doors",
        "term": "door",
        "infexions": [
            "door",
            "doors"
        ],
        "isPareto": true,
        "inflexions": [
            "door",
            "doors"
        ],
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "SYBoeIL1QEcfnZCDgMlU"
    },
    {
        "isVisual": true,
        "isPareto": true,
        "inflexions": [
            "gay",
            "gayer",
            "gayest"
        ],
        "workspace_id": "anglais",
        "wt": "j",
        "infexions": [
            "gay",
            "gayer",
            "gayest"
        ],
        "context": "delete",
        "lang": "en-fr",
        "term": "gay",
        "rank": 362,
        "model": "lang-en",
        "inflexion": "gay, gayer, gayest",
        "id": "hNZMBiRKQPLxXQi6aueT"
    },
    {
        "wt": "r",
        "rank": 363,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "exactly",
        "model": "lang-en",
        "inflexion": "exactly",
        "inflexions": [
            "exactly"
        ],
        "isPareto": true,
        "infexions": [
            "exactly"
        ],
        "id": "MrxRPz259MQTjhaE9kac"
    },
    {
        "rank": 364,
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "term": "parent",
        "inflexion": "parent, parents",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "parent",
            "parents"
        ],
        "infexions": [
            "parent",
            "parents"
        ],
        "id": "ei0vJS8b85r6NR20UFVA"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "rank": 365,
        "inflexions": [
            "few",
            "fewer",
            "fewest"
        ],
        "wt": "fw",
        "infexions": [
            "few",
            "fewer",
            "fewest"
        ],
        "lang": "en-fr",
        "inflexion": "few, fewer, fewest",
        "term": "few",
        "workspace_id": "anglais",
        "id": "ip9Hip8bfy49xPZeOYof"
    },
    {
        "infexions": [
            "month",
            "months"
        ],
        "inflexion": "month, months",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "month",
            "months"
        ],
        "isPareto": true,
        "model": "lang-en",
        "wt": "n",
        "term": "month",
        "rank": 366,
        "id": "PqsZ1QiMYQ6mUBQUgBVp"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "term": "easy",
        "wt": "j",
        "inflexion": "easy, easier, easiest",
        "rank": 367,
        "lang": "en-fr",
        "infexions": [
            "easy",
            "easier",
            "easiest"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "easy",
            "easier",
            "easiest"
        ],
        "id": "8gvn7Iiihw8toGGkMDmF"
    },
    {
        "infexions": [
            "deal",
            "deals"
        ],
        "lang": "en-fr",
        "rank": 368,
        "inflexion": "deal, deals",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "deal",
            "deals"
        ],
        "term": "deal",
        "wt": "n",
        "isPareto": true,
        "id": "wghX0MaxWkgdgjQiddgQ"
    },
    {
        "rank": 369,
        "inflexion": "ow, ouch, yow",
        "term": "ow",
        "lang": "en-fr",
        "inflexions": [
            "ow",
            "ouch",
            "yow"
        ],
        "infexions": [
            "ow",
            "ouch",
            "yow"
        ],
        "wt": "u",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "tX1cIgwBVFho3S8Df7RG"
    },
    {
        "term": "hurt",
        "isPareto": true,
        "rank": 370,
        "lang": "en-fr",
        "infexions": [
            "hurt",
            "hurting",
            "hurts"
        ],
        "wt": "v",
        "model": "lang-en",
        "inflexion": "hurt, hurting, hurts",
        "inflexions": [
            "hurt",
            "hurting",
            "hurts"
        ],
        "workspace_id": "anglais",
        "isVisual": true,
        "id": "1mWKYKybAGqRbTZ8NJAd"
    },
    {
        "isPareto": true,
        "inflexion": "nobody",
        "wt": "fw",
        "infexions": [
            "nobody"
        ],
        "inflexions": [
            "nobody"
        ],
        "model": "lang-en",
        "rank": 371,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "nobody",
        "id": "PZd3RiaNytMUluzjKNT1"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "perfect"
        ],
        "inflexion": "perfect",
        "isPareto": true,
        "wt": "j",
        "term": "perfect",
        "infexions": [
            "perfect"
        ],
        "lang": "en-fr",
        "rank": 372,
        "model": "lang-en",
        "id": "0XTCYIULcXATYDw1AKPU"
    },
    {
        "rank": 373,
        "lang": "en-fr",
        "wt": "v",
        "inflexions": [
            "lie",
            "lay",
            "lied",
            "lies",
            "lying"
        ],
        "model": "lang-en",
        "infexions": [
            "lie",
            "lay",
            "lied",
            "lies",
            "lying"
        ],
        "inflexion": "lie, lay, lied, lies, lying",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "lie",
        "id": "zV11TjOd9izSRbztEuxr"
    },
    {
        "isPareto": true,
        "term": "free",
        "infexions": [
            "free",
            "freer",
            "freest"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "free",
            "freer",
            "freest"
        ],
        "wt": "j",
        "rank": 374,
        "model": "lang-en",
        "inflexion": "free, freer, freest",
        "id": "9S2nLEaIjrYrNj3vNzCe"
    },
    {
        "rank": 375,
        "lang": "en-fr",
        "wt": "j",
        "isVisual": true,
        "infexions": [
            "young",
            "younger",
            "youngest"
        ],
        "isPareto": true,
        "inflexion": "young, younger, youngest",
        "inflexions": [
            "young",
            "younger",
            "youngest"
        ],
        "model": "lang-en",
        "term": "young",
        "workspace_id": "anglais",
        "id": "I4WYXlSOU35S9Cct4YI0"
    },
    {
        "wt": "j",
        "inflexion": "weird, weirder, weirdest",
        "term": "weird",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "weird",
            "weirder",
            "weirdest"
        ],
        "isVisual": true,
        "rank": 376,
        "inflexions": [
            "weird",
            "weirder",
            "weirdest"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "id": "nRSuHdF3eLZWekAHOfbw"
    },
    {
        "infexions": [
            "whatever"
        ],
        "rank": 377,
        "inflexion": "whatever",
        "term": "whatever",
        "wt": "fw",
        "model": "lang-en",
        "inflexions": [
            "whatever"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "leJDdJ3DWbZiGMHgnrwz"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "brother",
            "brothers"
        ],
        "infexions": [
            "brother",
            "brothers"
        ],
        "inflexion": "brother, brothers",
        "model": "lang-en",
        "term": "brother",
        "rank": 378,
        "workspace_id": "anglais",
        "id": "W0vGM0POYSPDdPcEoJmU"
    },
    {
        "isVisual": true,
        "inflexions": [
            "work",
            "works"
        ],
        "infexions": [
            "work",
            "works"
        ],
        "isPareto": true,
        "inflexion": "work, works",
        "rank": 379,
        "term": "work",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "id": "Ya1Ue0GwtEmimdILmDGp"
    },
    {
        "wt": "v",
        "term": "kid",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "rank": 380,
        "isVisual": true,
        "inflexion": "kid, kids, kidding, kidded",
        "infexions": [
            "kid",
            "kids",
            "kidding",
            "kidded"
        ],
        "inflexions": [
            "kid",
            "kids",
            "kidding",
            "kidded"
        ],
        "workspace_id": "anglais",
        "id": "55tvZJUvhqerz2tZlsAC"
    },
    {
        "infexions": [
            "ago"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 381,
        "wt": "r",
        "lang": "en-fr",
        "inflexion": "ago",
        "model": "lang-en",
        "term": "ago",
        "inflexions": [
            "ago"
        ],
        "id": "gtFxzPwRpQ5Wa8flzg1V"
    },
    {
        "infexions": [
            "end",
            "ended",
            "ending",
            "ends"
        ],
        "rank": 382,
        "inflexions": [
            "end",
            "ended",
            "ending",
            "ends"
        ],
        "inflexion": "end, ended, ending, ends",
        "model": "lang-en",
        "wt": "v",
        "lang": "en-fr",
        "term": "end",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "p9MdRouqFA6qZXvHwmvD"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "other, others",
        "rank": 383,
        "model": "lang-en",
        "term": "other",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "other",
            "others"
        ],
        "inflexions": [
            "other",
            "others"
        ],
        "id": "bbDYsRVqeUPltZN7HL5b"
    },
    {
        "isVisual": true,
        "workspace_id": "anglais",
        "inflexions": [
            "ball",
            "balls"
        ],
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "term": "ball",
        "infexions": [
            "ball",
            "balls"
        ],
        "isPareto": true,
        "inflexion": "ball, balls",
        "rank": 384,
        "id": "ZjBDVddyHCkF9rYvY352"
    },
    {
        "inflexion": "finally",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "finally"
        ],
        "isPareto": true,
        "rank": 385,
        "wt": "r",
        "lang": "en-fr",
        "term": "finally",
        "infexions": [
            "finally"
        ],
        "id": "9v973zglWMcnGgUSv93e"
    },
    {
        "infexions": [
            "line",
            "lines"
        ],
        "rank": 386,
        "isPareto": true,
        "inflexions": [
            "line",
            "lines"
        ],
        "term": "line",
        "workspace_id": "anglais",
        "inflexion": "line, lines",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "tKCFRMby4aX60EIEgUHS"
    },
    {
        "workspace_id": "anglais",
        "term": "its",
        "model": "lang-en",
        "infexions": [
            "its"
        ],
        "inflexions": [
            "its"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "fw",
        "inflexion": "its",
        "rank": 387,
        "id": "lQEbNOFlz3L05Be29i6K"
    },
    {
        "wt": "j",
        "isVisual": true,
        "workspace_id": "anglais",
        "term": "important",
        "inflexions": [
            "important"
        ],
        "infexions": [
            "important"
        ],
        "model": "lang-en",
        "inflexion": "important",
        "rank": 388,
        "lang": "en-fr",
        "isPareto": true,
        "id": "okRG3E8IK32M0rZoelhg"
    },
    {
        "inflexions": [
            "fall",
            "fallen",
            "falling",
            "falls",
            "fell"
        ],
        "rank": 389,
        "wt": "v",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "fall, fallen, falling, falls, fell",
        "term": "fall",
        "workspace_id": "anglais",
        "infexions": [
            "fall",
            "fallen",
            "falling",
            "falls",
            "fell"
        ],
        "isVisual": true,
        "model": "lang-en",
        "id": "SXV2lmU1xLD2M0ajbB8F"
    },
    {
        "inflexion": "heart, hearts",
        "wt": "n",
        "inflexions": [
            "heart",
            "hearts"
        ],
        "workspace_id": "anglais",
        "term": "heart",
        "rank": 390,
        "infexions": [
            "heart",
            "hearts"
        ],
        "isPareto": true,
        "isVisual": true,
        "lang": "en-fr",
        "model": "lang-en",
        "id": "kogJXHlRtfs0Oph9FMK4"
    },
    {
        "inflexions": [
            "long",
            "longer",
            "longest"
        ],
        "term": "long",
        "workspace_id": "anglais",
        "rank": 391,
        "isVisual": true,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "long, longer, longest",
        "infexions": [
            "long",
            "longer",
            "longest"
        ],
        "wt": "j",
        "isPareto": true,
        "id": "Lutvm4RyWY54nURBDSaW"
    },
    {
        "term": "fat",
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "j",
        "rank": 392,
        "isPareto": true,
        "infexions": [
            "fat",
            "fatter",
            "fattest"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "fat",
            "fatter",
            "fattest"
        ],
        "inflexion": "fat, fatter, fattest",
        "isVisual": true,
        "id": "97uZqPYdfu1A0Pws8Rvq"
    },
    {
        "rank": 393,
        "term": "class",
        "isVisual": true,
        "inflexion": "class, classes",
        "model": "lang-en",
        "inflexions": [
            "class",
            "classes"
        ],
        "wt": "n",
        "infexions": [
            "class",
            "classes"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "AjEUPZwxe4FXSffypGb7"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "shoot",
            "shooting",
            "shoots",
            "shot"
        ],
        "term": "shoot",
        "workspace_id": "anglais",
        "inflexions": [
            "shoot",
            "shooting",
            "shoots",
            "shot"
        ],
        "inflexion": "shoot, shooting, shoots, shot",
        "model": "lang-en",
        "isVisual": true,
        "rank": 394,
        "wt": "v",
        "isPareto": true,
        "id": "2GPiMgQwCwMYJXiApB2M"
    },
    {
        "model": "lang-en",
        "infexions": [
            "picture",
            "pictures"
        ],
        "rank": 395,
        "inflexions": [
            "picture",
            "pictures"
        ],
        "isPareto": true,
        "inflexion": "picture, pictures",
        "workspace_id": "anglais",
        "wt": "n",
        "isVisual": true,
        "lang": "en-fr",
        "term": "picture",
        "id": "ZSoI3fLdoqlMN8YejycQ"
    },
    {
        "term": "sell",
        "infexions": [
            "sell",
            "sells",
            "selling",
            "sold"
        ],
        "isVisual": true,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "sell",
            "sells",
            "selling",
            "sold"
        ],
        "rank": 396,
        "wt": "v",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "sell, sells, selling, sold",
        "id": "7mBsn8EDpSZGVP1af0NS"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "side",
            "sides"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "inflexions": [
            "side",
            "sides"
        ],
        "inflexion": "side, sides",
        "rank": 397,
        "term": "side",
        "id": "et2Vj3ErgkBDkZfsQBkY"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "wish",
            "wished",
            "wishes",
            "wishing"
        ],
        "rank": 398,
        "term": "wish",
        "inflexion": "wish, wished, wishes, wishing",
        "isPareto": true,
        "lang": "en-fr",
        "wt": "v",
        "isVisual": true,
        "infexions": [
            "wish",
            "wished",
            "wishes",
            "wishing"
        ],
        "workspace_id": "anglais",
        "id": "GrAQj6Ca2RSpjJiKvaMp"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "love, loves",
        "isPareto": true,
        "term": "love",
        "wt": "n",
        "rank": 399,
        "workspace_id": "anglais",
        "infexions": [
            "love",
            "loves"
        ],
        "isVisual": true,
        "inflexions": [
            "love",
            "loves"
        ],
        "id": "tIsw4Rt4P3K8b58ZZf1R"
    },
    {
        "lang": "en-fr",
        "inflexion": "mind, minds",
        "model": "lang-en",
        "isPareto": true,
        "rank": 400,
        "wt": "n",
        "infexions": [
            "mind",
            "minds"
        ],
        "term": "mind",
        "inflexions": [
            "mind",
            "minds"
        ],
        "workspace_id": "anglais",
        "id": "sIThMQKEd9MKoPUVwfvh"
    },
    {
        "term": "hair",
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "hair",
            "hairs"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "hair",
            "hairs"
        ],
        "rank": 401,
        "inflexion": "hair, hairs",
        "id": "SOAzu4YxrQcOxlzaPfjG"
    },
    {
        "wt": "v",
        "inflexion": "cut, cuts, cutting",
        "workspace_id": "anglais",
        "inflexions": [
            "cut",
            "cuts",
            "cutting"
        ],
        "isVisual": true,
        "model": "lang-en",
        "term": "cut",
        "rank": 402,
        "lang": "en-fr",
        "infexions": [
            "cut",
            "cuts",
            "cutting"
        ],
        "isPareto": true,
        "id": "oXZkmJJiFeawo7rTJKOu"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "wedding",
            "weddings"
        ],
        "isVisual": true,
        "wt": "n",
        "isPareto": true,
        "inflexion": "wedding, weddings",
        "inflexions": [
            "wedding",
            "weddings"
        ],
        "term": "wedding",
        "rank": 403,
        "workspace_id": "anglais",
        "id": "a0PH7eDuyhCthHtUyeHY"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "reason, reasons",
        "inflexions": [
            "reason",
            "reasons"
        ],
        "model": "lang-en",
        "infexions": [
            "reason",
            "reasons"
        ],
        "isPareto": true,
        "term": "reason",
        "wt": "n",
        "lang": "en-fr",
        "rank": 404,
        "id": "6Pi97gueWU9Llcv3SWUV"
    },
    {
        "inflexions": [
            "become",
            "became",
            "becomes",
            "becoming"
        ],
        "isPareto": true,
        "term": "become",
        "inflexion": "become, became, becomes, becoming",
        "rank": 405,
        "wt": "v",
        "workspace_id": "anglais",
        "infexions": [
            "become",
            "became",
            "becomes",
            "becoming"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "id": "uZCj2vqJGouTIyW5oBia"
    },
    {
        "rank": 406,
        "term": "least",
        "wt": "fw",
        "inflexion": "least",
        "model": "lang-en",
        "inflexions": [
            "least"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "least"
        ],
        "id": "LFin5ni6O1domrfOAajf"
    },
    {
        "isVisual": true,
        "term": "look",
        "model": "lang-en",
        "inflexions": [
            "look",
            "looked",
            "looking",
            "looks"
        ],
        "wt": "n",
        "inflexion": "look, looked, looking, looks",
        "infexions": [
            "look",
            "looked",
            "looking",
            "looks"
        ],
        "rank": 407,
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "9DvRyCIFB7VX2DZdjKI3"
    },
    {
        "inflexions": [
            "bite",
            "bites",
            "biting",
            "bit",
            "bitten"
        ],
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "bite",
            "bites",
            "biting",
            "bit",
            "bitten"
        ],
        "lang": "en-fr",
        "wt": "v",
        "workspace_id": "anglais",
        "rank": 408,
        "isVisual": true,
        "term": "bite",
        "inflexion": "bite, bites, biting, bit, bitten",
        "id": "R5OWkrSKoThgdJI9b8Bi"
    },
    {
        "wt": "v",
        "model": "lang-en",
        "inflexion": "fuck, fucked, fucking, fucks",
        "infexions": [
            "fuck",
            "fucked",
            "fucking",
            "fucks"
        ],
        "term": "fuck",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 409,
        "inflexions": [
            "fuck",
            "fucked",
            "fucking",
            "fucks"
        ],
        "isVisual": true,
        "context": "",
        "lang": "en-fr",
        "id": "4Y6pBQAvbDjq0wTOFgoD"
    },
    {
        "isVisual": true,
        "rank": 410,
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "fw",
        "term": "under",
        "lang": "en-fr",
        "inflexion": "under",
        "inflexions": [
            "under"
        ],
        "model": "lang-en",
        "infexions": [
            "under"
        ],
        "id": "TbJxy5OBVr6gBq2IWfF9"
    },
    {
        "term": "bed",
        "inflexions": [
            "bed",
            "beds"
        ],
        "rank": 411,
        "inflexion": "bed, beds",
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "bed",
            "beds"
        ],
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isVisual": true,
        "id": "SIWPbciUttC9MTR9nvEy"
    },
    {
        "wt": "n",
        "rank": 412,
        "term": "paper",
        "isVisual": true,
        "infexions": [
            "paper",
            "papers"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "paper, papers",
        "inflexions": [
            "paper",
            "papers"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "BMzX6f1GSDfQxq0tAHjp"
    },
    {
        "workspace_id": "anglais",
        "term": "different",
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "rank": 413,
        "inflexion": "different",
        "inflexions": [
            "different"
        ],
        "infexions": [
            "different"
        ],
        "model": "lang-en",
        "id": "cAHO6z9AyYd1ITtTVf50"
    },
    {
        "workspace_id": "anglais",
        "wt": "v",
        "inflexion": "catch, catches, catching, caught",
        "isVisual": true,
        "inflexions": [
            "catch",
            "catches",
            "catching",
            "caught"
        ],
        "infexions": [
            "catch",
            "catches",
            "catching",
            "caught"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 414,
        "term": "catch",
        "model": "lang-en",
        "id": "o3DA379ZeW590pRT5HWF"
    },
    {
        "workspace_id": "anglais",
        "rank": 415,
        "wt": "fw",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "mine"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "inflexion": "mine",
        "term": "mine",
        "inflexions": [
            "mine"
        ],
        "id": "dFAg1tcdUheY98Wr51wj"
    },
    {
        "wt": "m",
        "inflexion": "six",
        "term": "six",
        "model": "lang-en",
        "rank": 416,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "six"
        ],
        "infexions": [
            "six"
        ],
        "isPareto": true,
        "id": "nk4DqmA4DmeODHQEUl2e"
    },
    {
        "isPareto": true,
        "inflexions": [
            "set",
            "sets",
            "setting"
        ],
        "rank": 417,
        "lang": "en-fr",
        "infexions": [
            "set",
            "sets",
            "setting"
        ],
        "workspace_id": "anglais",
        "inflexion": "set, sets, setting",
        "term": "set",
        "model": "lang-en",
        "wt": "v",
        "id": "5Ubq0LTfmvH47CnAis4O"
    },
    {
        "isVisual": true,
        "model": "lang-en",
        "infexions": [
            "face",
            "faces"
        ],
        "rank": 418,
        "inflexions": [
            "face",
            "faces"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "face",
        "wt": "n",
        "inflexion": "face, faces",
        "id": "EwmGYHffi3yO5CSGiro8"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "speak, speaks, speaking, spoke, spoken",
        "inflexions": [
            "speak",
            "speaks",
            "speaking",
            "spoke",
            "spoken"
        ],
        "infexions": [
            "speak",
            "speaks",
            "speaking",
            "spoke",
            "spoken"
        ],
        "rank": 419,
        "wt": "v",
        "term": "speak",
        "isVisual": false,
        "isPareto": true,
        "lang": "en-fr",
        "id": "kGfLoUjZGIBItGwJQqnV"
    },
    {
        "workspace_id": "anglais",
        "term": "suck",
        "inflexion": "suck, sucks, sucking, sucked",
        "inflexions": [
            "suck",
            "sucks",
            "sucking",
            "sucked"
        ],
        "wt": "v",
        "infexions": [
            "suck",
            "sucks",
            "sucking",
            "sucked"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "rank": 420,
        "model": "lang-en",
        "id": "GLscHybRDFfoX0WNJK4f"
    },
    {
        "inflexions": [
            "sometimes"
        ],
        "wt": "r",
        "inflexion": "sometimes",
        "isPareto": true,
        "model": "lang-en",
        "rank": 421,
        "workspace_id": "anglais",
        "isVisual": true,
        "lang": "en-fr",
        "infexions": [
            "sometimes"
        ],
        "term": "sometimes",
        "id": "0MMnVCpc5br7bHX7DcEX"
    },
    {
        "inflexions": [
            "city",
            "cities"
        ],
        "inflexion": "city, cities",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "city",
        "rank": 422,
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "city",
            "cities"
        ],
        "id": "vYKZQM4nmraK9OP9MWrL"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "special"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 423,
        "inflexions": [
            "special"
        ],
        "inflexion": "special",
        "wt": "j",
        "term": "special",
        "id": "J27ljxE0OlHIsIuttL0k"
    },
    {
        "model": "lang-en",
        "term": "stick",
        "inflexions": [
            "stick",
            "sticks",
            "sticking",
            "stuck"
        ],
        "isPareto": true,
        "rank": 424,
        "wt": "v",
        "workspace_id": "anglais",
        "isVisual": true,
        "inflexion": "stick, sticks, sticking, stuck",
        "lang": "en-fr",
        "infexions": [
            "stick",
            "sticks",
            "sticking",
            "stuck"
        ],
        "id": "Y2R4xqmRVW3YQvaAl1jc"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "rank": 425,
        "inflexion": "question, questions",
        "infexions": [
            "question",
            "questions"
        ],
        "isPareto": true,
        "inflexions": [
            "question",
            "questions"
        ],
        "term": "question",
        "id": "ZPKfiK1shfAG7mR7tRip"
    },
    {
        "inflexion": "dude, dudes",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "dude",
            "dudes"
        ],
        "term": "dude",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "dude",
            "dudes"
        ],
        "rank": 426,
        "id": "PPBUIyavdrRTKxUBZlQc"
    },
    {
        "inflexions": [
            "realize",
            "realized",
            "realizes",
            "realizing"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "realize",
            "realized",
            "realizes",
            "realizing"
        ],
        "isPareto": true,
        "wt": "v",
        "term": "realize",
        "inflexion": "realize, realized, realizes, realizing",
        "workspace_id": "anglais",
        "rank": 427,
        "id": "IHpD2Emr3KjCYmyQzWcg"
    },
    {
        "infexions": [
            "birthday",
            "birthdays"
        ],
        "inflexion": "birthday, birthdays",
        "rank": 428,
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "term": "birthday",
        "inflexions": [
            "birthday",
            "birthdays"
        ],
        "isVisual": true,
        "workspace_id": "anglais",
        "id": "qwGFi5C7k2KkvMxWbA6T"
    },
    {
        "infexions": [
            "point",
            "points"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "point",
            "points"
        ],
        "workspace_id": "anglais",
        "inflexion": "point, points",
        "wt": "n",
        "term": "point",
        "rank": 429,
        "model": "lang-en",
        "id": "Xf94Vn2Xvta96nIPc6xf"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "isVisual": true,
        "inflexions": [
            "enjoy",
            "enjoyed",
            "enjoying",
            "enjoys"
        ],
        "wt": "v",
        "term": "enjoy",
        "isPareto": true,
        "inflexion": "enjoy, enjoyed, enjoying, enjoys",
        "infexions": [
            "enjoy",
            "enjoyed",
            "enjoying",
            "enjoys"
        ],
        "rank": 430,
        "workspace_id": "anglais",
        "id": "vM7ta5sl7ZXTa8hjdh6I"
    },
    {
        "inflexions": [
            "fact",
            "facts"
        ],
        "rank": 431,
        "model": "lang-en",
        "infexions": [
            "fact",
            "facts"
        ],
        "term": "fact",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "fact, facts",
        "lang": "en-fr",
        "wt": "n",
        "id": "ce5ka2IFc4r7kfijLi02"
    },
    {
        "wt": "v",
        "isVisual": true,
        "term": "dance",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "dance",
            "danced",
            "dances",
            "dancing"
        ],
        "rank": 432,
        "isPareto": true,
        "inflexion": "dance, danced, dances, dancing",
        "lang": "en-fr",
        "inflexions": [
            "dance",
            "danced",
            "dances",
            "dancing"
        ],
        "id": "6hP9sDAABET6xF49oeUI"
    },
    {
        "isPareto": true,
        "term": "soon",
        "rank": 433,
        "wt": "r",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "soon",
        "infexions": [
            "soon"
        ],
        "lang": "en-fr",
        "inflexions": [
            "soon"
        ],
        "id": "jBYHSJkgNgZl5axWBLZ3"
    },
    {
        "inflexions": [
            "bar",
            "bars"
        ],
        "isVisual": true,
        "inflexion": "bar, bars",
        "term": "bar",
        "infexions": [
            "bar",
            "bars"
        ],
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 434,
        "model": "lang-en",
        "id": "uLlhQfgJRhyDERpBRnzQ"
    },
    {
        "rank": 435,
        "wt": "v",
        "workspace_id": "anglais",
        "inflexion": "wonder, wondered, wondering, wonders",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "wonder",
            "wondered",
            "wondering",
            "wonders"
        ],
        "model": "lang-en",
        "infexions": [
            "wonder",
            "wondered",
            "wondering",
            "wonders"
        ],
        "term": "wonder",
        "id": "Fn7CmbGdvTe4uBXalwbu"
    },
    {
        "term": "joke",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "joke",
            "jokes"
        ],
        "wt": "n",
        "rank": 436,
        "inflexions": [
            "joke",
            "jokes"
        ],
        "lang": "en-fr",
        "inflexion": "joke, jokes",
        "id": "uOLHD7OWTcNGHEQoODm0"
    },
    {
        "infexions": [
            "relationship",
            "relationships"
        ],
        "lang": "en-fr",
        "rank": 437,
        "workspace_id": "anglais",
        "term": "relationship",
        "isPareto": true,
        "inflexions": [
            "relationship",
            "relationships"
        ],
        "inflexion": "relationship, relationships",
        "model": "lang-en",
        "wt": "n",
        "id": "UGNKx65LYY2kOd4VKd1q"
    },
    {
        "inflexion": "chance, chances",
        "term": "chance",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "chance",
            "chances"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "chance",
            "chances"
        ],
        "rank": 438,
        "isVisual": true,
        "id": "V5pCXhIxXDxe3fWPhaRP"
    },
    {
        "inflexions": [
            "black",
            "blacker",
            "blackest"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "black",
            "blacker",
            "blackest"
        ],
        "rank": 439,
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "j",
        "term": "black",
        "inflexion": "black, blacker, blackest",
        "isPareto": true,
        "id": "wElk2WmQcW3BtJCnDHvX"
    },
    {
        "model": "lang-en",
        "inflexion": "almost",
        "wt": "r",
        "lang": "en-fr",
        "infexions": [
            "almost"
        ],
        "rank": 440,
        "workspace_id": "anglais",
        "inflexions": [
            "almost"
        ],
        "isPareto": true,
        "isVisual": true,
        "term": "almost",
        "id": "vrPfVxUINeMWnhjdyzzu"
    },
    {
        "isPareto": true,
        "inflexion": "fight, fights, fighting, fought",
        "wt": "v",
        "lang": "en-fr",
        "isVisual": true,
        "infexions": [
            "fight",
            "fights",
            "fighting",
            "fought"
        ],
        "rank": 441,
        "inflexions": [
            "fight",
            "fights",
            "fighting",
            "fought"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "fight",
        "id": "wPtdeUBA4iIZxEebGUMD"
    },
    {
        "isPareto": true,
        "term": "card",
        "model": "lang-en",
        "infexions": [
            "card",
            "cards"
        ],
        "isVisual": true,
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "card, cards",
        "inflexions": [
            "card",
            "cards"
        ],
        "rank": 442,
        "workspace_id": "anglais",
        "id": "NQqmEmyiOlSiZOVIXRwX"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "song, songs",
        "model": "lang-en",
        "infexions": [
            "song",
            "songs"
        ],
        "term": "song",
        "isPareto": true,
        "inflexions": [
            "song",
            "songs"
        ],
        "rank": 443,
        "isVisual": true,
        "workspace_id": "anglais",
        "id": "2ErWbXVcqFlLyK0Of4Fs"
    },
    {
        "term": "little",
        "inflexion": "little",
        "inflexions": [
            "little"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "little"
        ],
        "isVisual": true,
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 444,
        "wt": "fw",
        "id": "iqph9weHdamHAVmFb6AH"
    },
    {
        "infexions": [
            "bye",
            "bye"
        ],
        "wt": "u",
        "inflexions": [
            "bye",
            "bye"
        ],
        "inflexion": "bye, bye",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "bye",
        "lang": "en-fr",
        "isVisual": true,
        "rank": 445,
        "id": "v294YMwGdStWWZjQpHGL"
    },
    {
        "infexions": [
            "coffee",
            "coffees"
        ],
        "inflexion": "coffee, coffees",
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "coffee",
            "coffees"
        ],
        "rank": 446,
        "isPareto": true,
        "model": "lang-en",
        "term": "coffee",
        "id": "JQwfScejy2EmSawzTaUK"
    },
    {
        "isPareto": true,
        "term": "awesome",
        "inflexion": "awesome",
        "rank": 447,
        "model": "lang-en",
        "wt": "j",
        "infexions": [
            "awesome"
        ],
        "lang": "en-fr",
        "inflexions": [
            "awesome"
        ],
        "workspace_id": "anglais",
        "id": "DXwVwESnHI19P0DaxV79"
    },
    {
        "inflexions": [
            "sick",
            "sicker",
            "sickest"
        ],
        "infexions": [
            "sick",
            "sicker",
            "sickest"
        ],
        "inflexion": "sick, sicker, sickest",
        "isPareto": true,
        "isVisual": true,
        "wt": "j",
        "model": "lang-en",
        "lang": "en-fr",
        "term": "sick",
        "rank": 448,
        "workspace_id": "anglais",
        "id": "9vzqRhYWJWaXqBhM7yq0"
    },
    {
        "inflexions": [
            "apartment",
            "apartments"
        ],
        "inflexion": "apartment, apartments",
        "rank": 449,
        "workspace_id": "anglais",
        "infexions": [
            "apartment",
            "apartments"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "term": "apartment",
        "id": "pdO41cVUnKyeW7cQVOFZ"
    },
    {
        "rank": 450,
        "isPareto": true,
        "inflexion": "sorry",
        "lang": "en-fr",
        "wt": "u",
        "inflexions": [
            "sorry"
        ],
        "term": "sorry",
        "model": "lang-en",
        "isVisual": true,
        "workspace_id": "anglais",
        "infexions": [
            "sorry"
        ],
        "id": "3zzBnF3IyOWtsWz1s9YA"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "back, backs",
        "isVisual": true,
        "term": "back",
        "inflexions": [
            "back",
            "backs"
        ],
        "infexions": [
            "back",
            "backs"
        ],
        "wt": "n",
        "rank": 451,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "em4nqInDZPVyQkMS3X2n"
    },
    {
        "inflexions": [
            "figure",
            "figured",
            "figures",
            "figuring"
        ],
        "lang": "en-fr",
        "isVisual": true,
        "model": "lang-en",
        "wt": "v",
        "workspace_id": "anglais",
        "term": "figure",
        "infexions": [
            "figure",
            "figured",
            "figures",
            "figuring"
        ],
        "rank": 452,
        "isPareto": true,
        "inflexion": "figure, figured, figures, figuring",
        "id": "hX80vfh09H3NZiMO18GV"
    },
    {
        "infexions": [
            "pull",
            "pulled",
            "pulling",
            "pulls"
        ],
        "term": "pull",
        "inflexion": "pull, pulled, pulling, pulls",
        "isPareto": true,
        "rank": 453,
        "workspace_id": "anglais",
        "inflexions": [
            "pull",
            "pulled",
            "pulling",
            "pulls"
        ],
        "wt": "v",
        "model": "lang-en",
        "lang": "en-fr",
        "isVisual": true,
        "id": "WuzXXLVp2DNc4SIaeIrq"
    },
    {
        "infexions": [
            "box",
            "boxes"
        ],
        "rank": 454,
        "isPareto": true,
        "inflexion": "box, boxes",
        "isVisual": true,
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "box",
            "boxes"
        ],
        "term": "box",
        "id": "G0Kaxr4jiYi4ja97AM3H"
    },
    {
        "inflexions": [
            "dream",
            "dreams"
        ],
        "wt": "n",
        "lang": "en-fr",
        "infexions": [
            "dream",
            "dreams"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "dream",
        "isPareto": true,
        "rank": 455,
        "isVisual": true,
        "inflexion": "dream, dreams",
        "id": "85Z8doBAxUAMjr2TxIEP"
    },
    {
        "wt": "n",
        "isVisual": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "water, waters",
        "isPareto": true,
        "term": "water",
        "rank": 456,
        "lang": "en-fr",
        "infexions": [
            "water",
            "waters"
        ],
        "inflexions": [
            "water",
            "waters"
        ],
        "id": "ZuqspCbLtGRpMkoErzSj"
    },
    {
        "inflexions": [
            "decide",
            "decided",
            "decides",
            "deciding"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "v",
        "rank": 457,
        "model": "lang-en",
        "isVisual": false,
        "term": "decide",
        "infexions": [
            "decide",
            "decided",
            "decides",
            "deciding"
        ],
        "inflexion": "decide, decided, decides, deciding",
        "lang": "en-fr",
        "id": "VdSOcM6USrfmA0zRWB7Z"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "store",
            "stores"
        ],
        "term": "store",
        "inflexion": "store, stores",
        "infexions": [
            "store",
            "stores"
        ],
        "wt": "n",
        "model": "lang-en",
        "isVisual": true,
        "rank": 458,
        "id": "FPlSZvBmRPsnbEdth2Ef"
    },
    {
        "inflexion": "bet, bets, betting",
        "term": "bet",
        "workspace_id": "anglais",
        "wt": "v",
        "lang": "en-fr",
        "inflexions": [
            "bet",
            "bets",
            "betting"
        ],
        "isPareto": true,
        "rank": 459,
        "model": "lang-en",
        "infexions": [
            "bet",
            "bets",
            "betting"
        ],
        "isVisual": true,
        "id": "LeHduEnaTmpotdGbUDIm"
    },
    {
        "lang": "en-fr",
        "term": "lunch",
        "rank": 460,
        "infexions": [
            "lunch",
            "lunches"
        ],
        "inflexions": [
            "lunch",
            "lunches"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "inflexion": "lunch, lunches",
        "isVisual": true,
        "isPareto": true,
        "id": "YAG8dTTeGzAG65Ox8R6K"
    },
    {
        "infexions": [
            "face",
            "faces",
            "facing",
            "faced"
        ],
        "inflexion": "face, faces, facing, faced",
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "wt": "v",
        "term": "face",
        "rank": 461,
        "lang": "en-fr",
        "isVisual": true,
        "inflexions": [
            "face",
            "faces",
            "facing",
            "faced"
        ],
        "id": "6a8G8SAlDEz7hFdKvvR4"
    },
    {
        "isPareto": true,
        "wt": "fw",
        "rank": 462,
        "term": "anybody",
        "infexions": [
            "anybody"
        ],
        "lang": "en-fr",
        "inflexion": "anybody",
        "inflexions": [
            "anybody"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "UbyBIkGe9CNElJHZLUZO"
    },
    {
        "model": "lang-en",
        "inflexion": "afraid",
        "isPareto": true,
        "wt": "j",
        "term": "afraid",
        "workspace_id": "anglais",
        "rank": 463,
        "lang": "en-fr",
        "inflexions": [
            "afraid"
        ],
        "infexions": [
            "afraid"
        ],
        "id": "SvHCVjU8FU0UzmkDsmD6"
    },
    {
        "inflexion": "buddy, buddies",
        "isPareto": true,
        "rank": 464,
        "lang": "en-fr",
        "inflexions": [
            "buddy",
            "buddies"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "buddy",
            "buddies"
        ],
        "term": "buddy",
        "wt": "n",
        "model": "lang-en",
        "id": "wOtu1hTMVhRETROmI7k4"
    },
    {
        "inflexion": "cute, cuter, cutest",
        "model": "lang-en",
        "inflexions": [
            "cute",
            "cuter",
            "cutest"
        ],
        "rank": 465,
        "infexions": [
            "cute",
            "cuter",
            "cutest"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "isVisual": true,
        "term": "cute",
        "wt": "j",
        "workspace_id": "anglais",
        "id": "IZauEUw36jBOKfrJgUSv"
    },
    {
        "wt": "j",
        "rank": 466,
        "inflexions": [
            "close",
            "closer",
            "closest"
        ],
        "lang": "en-fr",
        "infexions": [
            "close",
            "closer",
            "closest"
        ],
        "term": "close",
        "isPareto": true,
        "model": "lang-en",
        "isVisual": true,
        "inflexion": "close, closer, closest",
        "workspace_id": "anglais",
        "id": "SDFd6lqctZbJTKKYpBBR"
    },
    {
        "term": "bathroom",
        "isVisual": true,
        "infexions": [
            "bathroom",
            "bathrooms"
        ],
        "inflexions": [
            "bathroom",
            "bathrooms"
        ],
        "workspace_id": "anglais",
        "rank": 467,
        "isPareto": true,
        "wt": "n",
        "model": "lang-en",
        "inflexion": "bathroom, bathrooms",
        "lang": "en-fr",
        "id": "VLsA0DA57RVy2JGM8644"
    },
    {
        "inflexions": [
            "show",
            "shows"
        ],
        "rank": 468,
        "infexions": [
            "show",
            "shows"
        ],
        "wt": "n",
        "inflexion": "show, shows",
        "term": "show",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "VqYEACXO74RsVOG7jJbi"
    },
    {
        "inflexions": [
            "mind",
            "minded",
            "minding",
            "minds"
        ],
        "lang": "en-fr",
        "term": "mind",
        "inflexion": "mind, minded, minding, minds",
        "model": "lang-en",
        "isPareto": true,
        "rank": 469,
        "wt": "v",
        "workspace_id": "anglais",
        "infexions": [
            "mind",
            "minded",
            "minding",
            "minds"
        ],
        "id": "iURWJgCQIrz1j1FnUJKy"
    },
    {
        "inflexions": [
            "steal",
            "steals",
            "stealing",
            "stole",
            "stolen"
        ],
        "isVisual": true,
        "workspace_id": "anglais",
        "inflexion": "steal, steals, stealing, stole, stolen",
        "wt": "v",
        "rank": 470,
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "term": "steal",
        "infexions": [
            "steal",
            "steals",
            "stealing",
            "stole",
            "stolen"
        ],
        "id": "JhwPNWsMLyHpxUkR8pi1"
    },
    {
        "inflexion": "full, fuller, fullest",
        "inflexions": [
            "full",
            "fuller",
            "fullest"
        ],
        "wt": "j",
        "term": "full",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "full",
            "fuller",
            "fullest"
        ],
        "rank": 471,
        "isPareto": true,
        "model": "lang-en",
        "id": "JL7WKySwvHzXFuaJqEij"
    },
    {
        "infexions": [
            "company",
            "companies"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "company",
            "companies"
        ],
        "rank": 472,
        "term": "company",
        "wt": "n",
        "isPareto": true,
        "inflexion": "company, companies",
        "lang": "en-fr",
        "id": "h3b1wnZO7b9ZJfkBxFN6"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 473,
        "isPareto": true,
        "wt": "n",
        "term": "front",
        "lang": "en-fr",
        "inflexion": "front, fronts",
        "infexions": [
            "front",
            "fronts"
        ],
        "inflexions": [
            "front",
            "fronts"
        ],
        "id": "7e5GqfwNhPYjVNAXLukv"
    },
    {
        "inflexions": [
            "ahead"
        ],
        "wt": "r",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "ahead",
        "infexions": [
            "ahead"
        ],
        "model": "lang-en",
        "rank": 474,
        "isPareto": true,
        "inflexion": "ahead",
        "id": "m4Z7a541ldffnWboZn2M"
    },
    {
        "term": "moment",
        "wt": "n",
        "inflexion": "moment, moments",
        "inflexions": [
            "moment",
            "moments"
        ],
        "infexions": [
            "moment",
            "moments"
        ],
        "lang": "en-fr",
        "rank": 475,
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "nFsuoYTg102s1dJgOSlY"
    },
    {
        "model": "lang-en",
        "inflexion": "case, cases",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "case",
            "cases"
        ],
        "lang": "en-fr",
        "term": "case",
        "workspace_id": "anglais",
        "rank": 476,
        "inflexions": [
            "case",
            "cases"
        ],
        "id": "8S5vqFcZFUxVeAjNQrv3"
    },
    {
        "infexions": [
            "date",
            "dated",
            "dates",
            "dating"
        ],
        "wt": "v",
        "lang": "en-fr",
        "term": "date",
        "model": "lang-en",
        "inflexion": "date, dated, dates, dating",
        "workspace_id": "anglais",
        "rank": 477,
        "isPareto": true,
        "inflexions": [
            "date",
            "dated",
            "dates",
            "dating"
        ],
        "id": "wd0SQbKMSGyfQVxTWmYj"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "though",
            "tho"
        ],
        "rank": 478,
        "term": "though",
        "wt": "fw",
        "workspace_id": "anglais",
        "inflexions": [
            "though",
            "tho"
        ],
        "inflexion": "though, tho",
        "id": "kUZ6ftg4kfGrr68PnE7N"
    },
    {
        "isVisual": true,
        "inflexion": "body, bodies",
        "term": "body",
        "model": "lang-en",
        "inflexions": [
            "body",
            "bodies"
        ],
        "wt": "n",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 479,
        "infexions": [
            "body",
            "bodies"
        ],
        "id": "Q8z4aFPs3g5HhB9Z1a6Z"
    },
    {
        "term": "pants",
        "rank": 480,
        "infexions": [
            "pants"
        ],
        "model": "lang-en",
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "pants"
        ],
        "inflexion": "pants",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "HYWuX7iTDAxbtobW1EFG"
    },
    {
        "lang": "en-fr",
        "term": "bitch",
        "infexions": [
            "bitch",
            "bitches"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "bitch",
            "bitches"
        ],
        "wt": "n",
        "rank": 481,
        "isPareto": true,
        "inflexion": "bitch, bitches",
        "id": "zD9SqQWeLmKWsqO4LHZm"
    },
    {
        "inflexion": "promise, promises, promising, promised",
        "wt": "v",
        "rank": 482,
        "lang": "en-fr",
        "isVisual": false,
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "promise",
        "inflexions": [
            "promise",
            "promises",
            "promising",
            "promised"
        ],
        "infexions": [
            "promise",
            "promises",
            "promising",
            "promised"
        ],
        "id": "o25t7C3Rf83AxL6g5xvP"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "glad",
            "gladder",
            "gladdest"
        ],
        "wt": "j",
        "isVisual": true,
        "lang": "en-fr",
        "term": "glad",
        "infexions": [
            "glad",
            "gladder",
            "gladdest"
        ],
        "rank": 483,
        "inflexion": "glad, gladder, gladdest",
        "workspace_id": "anglais",
        "id": "tLI6odX2PaetwlohENo2"
    },
    {
        "inflexions": [
            "kiss",
            "kissed",
            "kisses",
            "kissing"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "term": "kiss",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "kiss, kissed, kisses, kissing",
        "wt": "v",
        "isVisual": true,
        "infexions": [
            "kiss",
            "kissed",
            "kisses",
            "kissing"
        ],
        "rank": 484,
        "id": "IBTbLI6jYobB5Kks9OpV"
    },
    {
        "inflexions": [
            "either"
        ],
        "lang": "en-fr",
        "rank": 485,
        "inflexion": "either",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "fw",
        "infexions": [
            "either"
        ],
        "term": "either",
        "model": "lang-en",
        "id": "WvtHKFFSlKN2bF8W7rpi"
    },
    {
        "inflexions": [
            "fire",
            "fires",
            "firing",
            "fired"
        ],
        "isPareto": true,
        "isVisual": true,
        "infexions": [
            "fire",
            "fires",
            "firing",
            "fired"
        ],
        "rank": 486,
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "v",
        "inflexion": "fire, fires, firing, fired",
        "lang": "en-fr",
        "term": "fire",
        "id": "TQQ8aMCrwnrMa6b6l2Br"
    },
    {
        "workspace_id": "anglais",
        "isVisual": true,
        "inflexion": "grow, grows, growing, grew, grown",
        "infexions": [
            "grow",
            "grows",
            "growing",
            "grew",
            "grown"
        ],
        "wt": "v",
        "inflexions": [
            "grow",
            "grows",
            "growing",
            "grew",
            "grown"
        ],
        "rank": 487,
        "lang": "en-fr",
        "term": "grow",
        "model": "lang-en",
        "isPareto": true,
        "id": "uzXs0AgoRG9DEFgI4kmR"
    },
    {
        "infexions": [
            "build",
            "builds",
            "building",
            "built"
        ],
        "isVisual": true,
        "wt": "v",
        "inflexion": "build, builds, building, built",
        "lang": "en-fr",
        "rank": 488,
        "term": "build",
        "inflexions": [
            "build",
            "builds",
            "building",
            "built"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "id": "7bex7zhkZdRrzXCzppT0"
    },
    {
        "inflexion": "table, tables",
        "isVisual": true,
        "wt": "n",
        "workspace_id": "anglais",
        "term": "table",
        "inflexions": [
            "table",
            "tables"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "rank": 489,
        "model": "lang-en",
        "infexions": [
            "table",
            "tables"
        ],
        "id": "D5BQyCx5BYMtJEkEMM0o"
    },
    {
        "inflexion": "ticket, tickets",
        "model": "lang-en",
        "lang": "en-fr",
        "isVisual": true,
        "rank": 490,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "ticket",
            "tickets"
        ],
        "wt": "n",
        "infexions": [
            "ticket",
            "tickets"
        ],
        "term": "ticket",
        "id": "xDJH0MnGuIimUbpDxC4O"
    },
    {
        "term": "matter",
        "model": "lang-en",
        "wt": "v",
        "workspace_id": "anglais",
        "infexions": [
            "matter",
            "matters",
            "mattering",
            "mattered"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "matter",
            "matters",
            "mattering",
            "mattered"
        ],
        "inflexion": "matter, matters, mattering, mattered",
        "rank": 491,
        "id": "IhJpv7NuQ4xkbwLV9gEx"
    },
    {
        "infexions": [
            "teach",
            "teaches",
            "teaching",
            "taught"
        ],
        "isVisual": true,
        "wt": "v",
        "rank": 492,
        "term": "teach",
        "model": "lang-en",
        "inflexions": [
            "teach",
            "teaches",
            "teaching",
            "taught"
        ],
        "workspace_id": "anglais",
        "inflexion": "teach, teaches, teaching, taught",
        "lang": "en-fr",
        "isPareto": true,
        "id": "mL7ydzR2K9bmm1foAKQ9"
    },
    {
        "rank": 493,
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "cat",
            "cats"
        ],
        "term": "cat",
        "infexions": [
            "cat",
            "cats"
        ],
        "workspace_id": "anglais",
        "inflexion": "cat, cats",
        "lang": "en-fr",
        "wt": "n",
        "id": "Od3mZl8WdVtK1JzM13HQ"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "rank": 494,
        "lang": "en-fr",
        "infexions": [
            "sister",
            "sisters"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "sister, sisters",
        "inflexions": [
            "sister",
            "sisters"
        ],
        "term": "sister",
        "id": "KuxbsqV6pFmstsqbXSuE"
    },
    {
        "inflexion": "girlfriend, girlfriends",
        "workspace_id": "anglais",
        "infexions": [
            "girlfriend",
            "girlfriends"
        ],
        "lang": "en-fr",
        "inflexions": [
            "girlfriend",
            "girlfriends"
        ],
        "term": "girlfriend",
        "isPareto": true,
        "rank": 495,
        "wt": "n",
        "model": "lang-en",
        "id": "ZcyohaNzqF1VN3twmfAB"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "term": "hat",
        "inflexions": [
            "hat",
            "hats"
        ],
        "model": "lang-en",
        "wt": "n",
        "rank": 496,
        "infexions": [
            "hat",
            "hats"
        ],
        "isVisual": true,
        "inflexion": "hat, hats",
        "workspace_id": "anglais",
        "id": "xnJRRTiupYT8oU0YyuKd"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "touch",
            "touches",
            "touching",
            "touched"
        ],
        "rank": 497,
        "wt": "v",
        "inflexions": [
            "touch",
            "touches",
            "touching",
            "touched"
        ],
        "isVisual": true,
        "isPareto": true,
        "lang": "en-fr",
        "term": "touch",
        "inflexion": "touch, touches, touching, touched",
        "model": "lang-en",
        "id": "VgOrmrl2MjdcHi0VG5BA"
    },
    {
        "inflexions": [
            "terrible"
        ],
        "term": "terrible",
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "terrible",
        "isPareto": true,
        "infexions": [
            "terrible"
        ],
        "rank": 498,
        "wt": "j",
        "id": "flKjFdyCHxallMaEOmbx"
    },
    {
        "lang": "en-fr",
        "isVisual": true,
        "wt": "n",
        "rank": 499,
        "infexions": [
            "beer",
            "beers"
        ],
        "inflexion": "beer, beers",
        "inflexions": [
            "beer",
            "beers"
        ],
        "workspace_id": "anglais",
        "term": "beer",
        "isPareto": true,
        "model": "lang-en",
        "id": "fqHfrsFHZ1JKSH7NV1Vc"
    },
    {
        "isPareto": true,
        "wt": "u",
        "infexions": [
            "damn"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 500,
        "inflexions": [
            "damn"
        ],
        "workspace_id": "anglais",
        "inflexion": "damn",
        "term": "damn",
        "id": "2aGMaLCz0zM9ZYx61HH6"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "club, clubs",
        "term": "club",
        "infexions": [
            "club",
            "clubs"
        ],
        "lang": "en-fr",
        "wt": "n",
        "rank": 501,
        "isPareto": true,
        "inflexions": [
            "club",
            "clubs"
        ],
        "id": "1vYom9B0ekIu6hghRaSS"
    },
    {
        "inflexion": "amaze, amazes, amazing, amazed",
        "isPareto": true,
        "rank": 502,
        "wt": "v",
        "lang": "en-fr",
        "inflexions": [
            "amaze",
            "amazes",
            "amazing",
            "amazed"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "amaze",
            "amazes",
            "amazing",
            "amazed"
        ],
        "term": "amaze",
        "model": "lang-en",
        "id": "eyFmuVTW7ulfTdRElvbo"
    },
    {
        "inflexions": [
            "smell",
            "smells",
            "smelling",
            "smelled",
            "smelt"
        ],
        "lang": "en-fr",
        "inflexion": "smell, smells, smelling, smelled, smelt",
        "isVisual": true,
        "infexions": [
            "smell",
            "smells",
            "smelling",
            "smelled",
            "smelt"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "smell",
        "rank": 503,
        "wt": "v",
        "id": "anvc04rjUJMjhRIqI3dQ"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "gift",
            "gifts"
        ],
        "inflexion": "gift, gifts",
        "rank": 504,
        "model": "lang-en",
        "isPareto": true,
        "term": "gift",
        "isVisual": true,
        "lang": "en-fr",
        "inflexions": [
            "gift",
            "gifts"
        ],
        "id": "bmXNSH8PoFboNalqGNvN"
    },
    {
        "inflexion": "serious",
        "wt": "j",
        "model": "lang-en",
        "term": "serious",
        "inflexions": [
            "serious"
        ],
        "infexions": [
            "serious"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 505,
        "id": "mphypLSIwrYkPQ2MR5pq"
    },
    {
        "model": "lang-en",
        "inflexion": "plan, plans, planning, planned",
        "infexions": [
            "plan",
            "plans",
            "planning",
            "planned"
        ],
        "isPareto": true,
        "wt": "v",
        "term": "plan",
        "rank": 506,
        "inflexions": [
            "plan",
            "plans",
            "planning",
            "planned"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "5fq8UB1aHU3UQCZi7TV2"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "isVisual": true,
        "infexions": [
            "street",
            "streets"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 507,
        "inflexion": "street, streets",
        "inflexions": [
            "street",
            "streets"
        ],
        "term": "street",
        "model": "lang-en",
        "id": "LDsssPzENIDPjKNnJlOy"
    },
    {
        "infexions": [
            "team",
            "teams"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "team",
            "teams"
        ],
        "inflexion": "team, teams",
        "wt": "n",
        "isPareto": true,
        "term": "team",
        "rank": 508,
        "workspace_id": "anglais",
        "id": "Q1f0ir9gN5wqfGRQLuGY"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "term": "order",
        "rank": 509,
        "inflexion": "order, orders, ordering, ordered",
        "inflexions": [
            "order",
            "orders",
            "ordering",
            "ordered"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "order",
            "orders",
            "ordering",
            "ordered"
        ],
        "wt": "v",
        "id": "2wa730NkvqfwUK9EqEJ7"
    },
    {
        "isPareto": true,
        "isVisual": true,
        "inflexions": [
            "cry",
            "cries",
            "crying",
            "cried"
        ],
        "term": "cry",
        "lang": "en-fr",
        "inflexion": "cry, cries, crying, cried",
        "infexions": [
            "cry",
            "cries",
            "crying",
            "cried"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "rank": 510,
        "model": "lang-en",
        "id": "UsQAMGMPaSre69Oj8zt3"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "zero"
        ],
        "term": "zero",
        "model": "lang-en",
        "rank": 511,
        "inflexion": "zero",
        "isPareto": true,
        "infexions": [
            "zero"
        ],
        "wt": "m",
        "workspace_id": "anglais",
        "id": "qHH7Ybt3PjtIwaCOZNBh"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "drop, drops, dropping, dropped",
        "infexions": [
            "drop",
            "drops",
            "dropping",
            "dropped"
        ],
        "inflexions": [
            "drop",
            "drops",
            "dropping",
            "dropped"
        ],
        "model": "lang-en",
        "wt": "v",
        "term": "drop",
        "rank": 512,
        "isPareto": true,
        "id": "2oALtUp98c96NP12sllw"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "act",
            "acts",
            "acting",
            "acted"
        ],
        "workspace_id": "anglais",
        "term": "act",
        "infexions": [
            "act",
            "acts",
            "acting",
            "acted"
        ],
        "isPareto": true,
        "rank": 513,
        "isVisual": true,
        "inflexion": "act, acts, acting, acted",
        "wt": "v",
        "lang": "en-fr",
        "id": "Ctcb2buaIGPm7Ooq9MmC"
    },
    {
        "lang": "en-fr",
        "inflexion": "alone",
        "isPareto": true,
        "infexions": [
            "alone"
        ],
        "inflexions": [
            "alone"
        ],
        "rank": 514,
        "workspace_id": "anglais",
        "term": "alone",
        "wt": "j",
        "model": "lang-en",
        "id": "oAxTnL9V7J0haJHuV7qN"
    },
    {
        "workspace_id": "anglais",
        "term": "seat",
        "lang": "en-fr",
        "isPareto": true,
        "isVisual": true,
        "inflexions": [
            "seat",
            "seats"
        ],
        "rank": 515,
        "infexions": [
            "seat",
            "seats"
        ],
        "inflexion": "seat, seats",
        "wt": "n",
        "model": "lang-en",
        "id": "ITSN4Nn4FiPmXxHsFY5D"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "note": "del",
        "infexions": [
            "eh"
        ],
        "term": "eh",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "u",
        "inflexion": "eh",
        "rank": 516,
        "inflexions": [
            "eh"
        ],
        "id": "DAi0Opp3ThylafxzNcFx"
    },
    {
        "lang": "en-fr",
        "wt": "fw",
        "infexions": [
            "between"
        ],
        "inflexion": "between",
        "isVisual": true,
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 517,
        "inflexions": [
            "between"
        ],
        "term": "between",
        "model": "lang-en",
        "id": "3eqVHAMdtfkgIWAz7jjH"
    },
    {
        "term": "eight",
        "model": "lang-en",
        "inflexion": "eight",
        "isVisual": true,
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 518,
        "infexions": [
            "eight"
        ],
        "wt": "m",
        "lang": "en-fr",
        "inflexions": [
            "eight"
        ],
        "id": "pmFw5CxAdT7pow03lYN5"
    },
    {
        "rank": 519,
        "infexions": [
            "twenty"
        ],
        "inflexion": "twenty",
        "wt": "m",
        "lang": "en-fr",
        "isVisual": true,
        "isPareto": true,
        "term": "twenty",
        "inflexions": [
            "twenty"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "DurdpkvNFlenrwGhWUJc"
    },
    {
        "note": "",
        "inflexion": "foot, feet",
        "rank": 520,
        "isPareto": true,
        "isVisual": true,
        "workspace_id": "anglais",
        "infexions": [
            "foot",
            "feet"
        ],
        "term": "foot",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "foot",
            "feet"
        ],
        "id": "2Qvq4zddti6hDaktgwbO"
    },
    {
        "inflexion": "seven",
        "wt": "m",
        "isPareto": true,
        "term": "seven",
        "lang": "en-fr",
        "isVisual": true,
        "workspace_id": "anglais",
        "inflexions": [
            "seven"
        ],
        "model": "lang-en",
        "infexions": [
            "seven"
        ],
        "rank": 521,
        "id": "Cy1FjS9toBAmbuwqdoZr"
    },
    {
        "term": "finish",
        "lang": "en-fr",
        "wt": "v",
        "rank": 522,
        "infexions": [
            "finish",
            "finishes",
            "finishing",
            "finished"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "finish",
            "finishes",
            "finishing",
            "finished"
        ],
        "model": "lang-en",
        "isVisual": true,
        "inflexion": "finish, finishes, finishing, finished",
        "id": "XWuy15KZk3vBUnVtw7Bi"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "isVisual": true,
        "inflexions": [
            "gentleman",
            "gentlemen"
        ],
        "wt": "n",
        "inflexion": "gentleman, gentlemen",
        "term": "gentleman",
        "infexions": [
            "gentleman",
            "gentlemen"
        ],
        "isPareto": true,
        "rank": 523,
        "lang": "en-fr",
        "id": "sizl1uFni25oscvtCE5k"
    },
    {
        "inflexion": "hand, hands, handing, handed",
        "lang": "en-fr",
        "wt": "v",
        "infexions": [
            "hand",
            "hands",
            "handing",
            "handed"
        ],
        "rank": 524,
        "model": "lang-en",
        "term": "hand",
        "workspace_id": "anglais",
        "inflexions": [
            "hand",
            "hands",
            "handing",
            "handed"
        ],
        "isPareto": true,
        "isVisual": true,
        "id": "dVyQ7aN5eDpRyNQFaSPH"
    },
    {
        "wt": "v",
        "isPareto": true,
        "term": "point",
        "lang": "en-fr",
        "inflexion": "point, points, pointing, pointed",
        "inflexions": [
            "point",
            "points",
            "pointing",
            "pointed"
        ],
        "model": "lang-en",
        "infexions": [
            "point",
            "points",
            "pointing",
            "pointed"
        ],
        "rank": 525,
        "workspace_id": "anglais",
        "id": "5KOG7JhR1vPR8hHQhyyi"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 526,
        "inflexion": "blow, blows, blowing, blew, blown",
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "blow",
            "blows",
            "blowing",
            "blew",
            "blown"
        ],
        "infexions": [
            "blow",
            "blows",
            "blowing",
            "blew",
            "blown"
        ],
        "term": "blow",
        "id": "lCBxW6nM64UFJX8nQszL"
    },
    {
        "workspace_id": "anglais",
        "wt": "j",
        "infexions": [
            "small",
            "smaller",
            "smallest"
        ],
        "term": "small",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "small",
            "smaller",
            "smallest"
        ],
        "rank": 527,
        "inflexion": "small, smaller, smallest",
        "id": "Y6HxIzsqLwtb7FGqaoJX"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "trouble",
            "troubles"
        ],
        "infexions": [
            "trouble",
            "troubles"
        ],
        "lang": "en-fr",
        "rank": 528,
        "inflexion": "trouble, troubles",
        "wt": "n",
        "term": "trouble",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "laazPkPP3qYDrPMfpCGL"
    },
    {
        "infexions": [
            "sweetie",
            "sweeties"
        ],
        "rank": 529,
        "inflexions": [
            "sweetie",
            "sweeties"
        ],
        "inflexion": "sweetie, sweeties",
        "workspace_id": "anglais",
        "term": "sweetie",
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "id": "S6bmnispXIdm4ooeGWAc"
    },
    {
        "term": "sing",
        "model": "lang-en",
        "wt": "v",
        "rank": 530,
        "isPareto": true,
        "infexions": [
            "sing",
            "sings",
            "singing",
            "sang",
            "sung"
        ],
        "inflexion": "sing, sings, singing, sang, sung",
        "inflexions": [
            "sing",
            "sings",
            "singing",
            "sang",
            "sung"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "27dJDGQwgJxAzF6nn08w"
    },
    {
        "wt": "v",
        "rank": 531,
        "inflexion": "pass, passes, passing, passed",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "pass",
            "passes",
            "passing",
            "passed"
        ],
        "term": "pass",
        "model": "lang-en",
        "inflexions": [
            "pass",
            "passes",
            "passing",
            "passed"
        ],
        "workspace_id": "anglais",
        "id": "ugcRwheD3DYfeLORzpww"
    },
    {
        "inflexions": [
            "beat",
            "beats",
            "beating",
            "beaten"
        ],
        "term": "beat",
        "inflexion": "beat, beats, beating, beaten",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "beat",
            "beats",
            "beating",
            "beaten"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "rank": 532,
        "isPareto": true,
        "id": "JYqewuqJgCQ8dgZGZBIZ"
    },
    {
        "inflexion": "piece, pieces",
        "inflexions": [
            "piece",
            "pieces"
        ],
        "term": "piece",
        "infexions": [
            "piece",
            "pieces"
        ],
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 533,
        "id": "w8tmzoiwL8BaF4q5oBO4"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "shoe",
            "shoes"
        ],
        "inflexion": "shoe, shoes",
        "infexions": [
            "shoe",
            "shoes"
        ],
        "rank": 534,
        "term": "shoe",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "id": "rs1l6wZOXvm4ihpAiY25"
    },
    {
        "workspace_id": "anglais",
        "wt": "j",
        "rank": 535,
        "isPareto": true,
        "infexions": [
            "welcome"
        ],
        "term": "welcome",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "welcome",
        "inflexions": [
            "welcome"
        ],
        "id": "f36drYE0isJIzH9vxC34"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "j",
        "term": "white",
        "rank": 536,
        "isPareto": true,
        "inflexion": "white, whiter, whitest",
        "inflexions": [
            "white",
            "whiter",
            "whitest"
        ],
        "infexions": [
            "white",
            "whiter",
            "whitest"
        ],
        "model": "lang-en",
        "id": "isTVhQlQBvFn19kH2JKl"
    },
    {
        "inflexion": "kick, kicks, kicking, kicked",
        "isPareto": true,
        "lang": "en-fr",
        "rank": 537,
        "inflexions": [
            "kick",
            "kicks",
            "kicking",
            "kicked"
        ],
        "workspace_id": "anglais",
        "term": "kick",
        "infexions": [
            "kick",
            "kicks",
            "kicking",
            "kicked"
        ],
        "wt": "v",
        "model": "lang-en",
        "id": "Y9J8KP1VKgHgqfUyIhbh"
    },
    {
        "infexions": [
            "bag",
            "bags"
        ],
        "isPareto": true,
        "inflexion": "bag, bags",
        "term": "bag",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "bag",
            "bags"
        ],
        "rank": 538,
        "lang": "en-fr",
        "id": "k7WXPwsDhMKKBMcEaQdR"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "fire",
            "fires"
        ],
        "term": "fire",
        "inflexions": [
            "fire",
            "fires"
        ],
        "wt": "n",
        "inflexion": "fire, fires",
        "rank": 539,
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "Ki2LC9Z4osIEuFgVO1Ua"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "term": "early",
        "rank": 540,
        "infexions": [
            "early",
            "earlier",
            "earliest"
        ],
        "model": "lang-en",
        "wt": "j",
        "workspace_id": "anglais",
        "inflexion": "early, earlier, earliest",
        "inflexions": [
            "early",
            "earlier",
            "earliest"
        ],
        "id": "naNNlWkFIPajiq0pQiIB"
    },
    {
        "model": "lang-en",
        "rank": 541,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "excite",
        "inflexions": [
            "excite",
            "excites",
            "exciting",
            "excited"
        ],
        "wt": "v",
        "isPareto": true,
        "infexions": [
            "excite",
            "excites",
            "exciting",
            "excited"
        ],
        "inflexion": "excite, excites, exciting, excited",
        "id": "3oCB8reShjdq0ejXI147"
    },
    {
        "model": "lang-en",
        "rank": 542,
        "wt": "j",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "wonderful"
        ],
        "inflexions": [
            "wonderful"
        ],
        "inflexion": "wonderful",
        "term": "wonderful",
        "lang": "en-fr",
        "id": "Y5y61UQ520BOzw0s035D"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "seriously"
        ],
        "wt": "r",
        "inflexions": [
            "seriously"
        ],
        "inflexion": "seriously",
        "rank": 543,
        "workspace_id": "anglais",
        "term": "seriously",
        "lang": "en-fr",
        "id": "DRk60AIQ6VxpXFEOn3te"
    },
    {
        "inflexion": "country, countries",
        "model": "lang-en",
        "rank": 544,
        "infexions": [
            "country",
            "countries"
        ],
        "isPareto": true,
        "wt": "n",
        "inflexions": [
            "country",
            "countries"
        ],
        "term": "country",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "U1mbyVmomSuNMYyJ2NEM"
    },
    {
        "model": "lang-en",
        "infexions": [
            "mouth",
            "mouths"
        ],
        "isPareto": true,
        "inflexion": "mouth, mouths",
        "term": "mouth",
        "rank": 545,
        "wt": "n",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "mouth",
            "mouths"
        ],
        "id": "T6F3AFsBBTVGUOyFWx32"
    },
    {
        "isPareto": true,
        "infexions": [
            "question",
            "questions",
            "questioning",
            "questioned"
        ],
        "inflexion": "question, questions, questioning, questioned",
        "wt": "v",
        "inflexions": [
            "question",
            "questions",
            "questioning",
            "questioned"
        ],
        "rank": 546,
        "model": "lang-en",
        "term": "question",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "kShDzlAILYsLOx4bVLN5"
    },
    {
        "term": "dear",
        "inflexions": [
            "dear",
            "dearer",
            "dearest"
        ],
        "rank": 547,
        "inflexion": "dear, dearer, dearest",
        "model": "lang-en",
        "infexions": [
            "dear",
            "dearer",
            "dearest"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "id": "7FZjshpNjSejJxXQXZ1t"
    },
    {
        "lang": "en-fr",
        "rank": 548,
        "isPareto": true,
        "wt": "r",
        "inflexion": "quite",
        "model": "lang-en",
        "term": "quite",
        "inflexions": [
            "quite"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "quite"
        ],
        "id": "2kmSvd5WmfClgLV35lVZ"
    },
    {
        "rank": 549,
        "infexions": [
            "smart",
            "smarter",
            "smartest"
        ],
        "term": "smart",
        "inflexions": [
            "smart",
            "smarter",
            "smartest"
        ],
        "wt": "j",
        "inflexion": "smart, smarter, smartest",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "2wJ53G5WzkpWn5FQhSYY"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "term": "husband",
        "rank": 550,
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "husband",
            "husbands"
        ],
        "inflexion": "husband, husbands",
        "inflexions": [
            "husband",
            "husbands"
        ],
        "id": "NKBv4JdmyJof0WYOuRWU"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "invite",
        "inflexions": [
            "invite",
            "invites",
            "inviting",
            "invited"
        ],
        "isPareto": true,
        "infexions": [
            "invite",
            "invites",
            "inviting",
            "invited"
        ],
        "rank": 551,
        "inflexion": "invite, invites, inviting, invited",
        "wt": "v",
        "id": "PR3ACFCyAiYwS0BeGC79"
    },
    {
        "inflexion": "rest, rests",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "rest",
            "rests"
        ],
        "infexions": [
            "rest",
            "rests"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "rest",
        "wt": "n",
        "rank": 552,
        "id": "0qXjM6guTrajhZfaN1Za"
    },
    {
        "isPareto": true,
        "inflexion": "yours",
        "rank": 553,
        "infexions": [
            "yours"
        ],
        "inflexions": [
            "yours"
        ],
        "term": "yours",
        "wt": "fw",
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "T7Fw0PsE0wUutaw2uJwr"
    },
    {
        "workspace_id": "anglais",
        "term": "behind",
        "infexions": [
            "behind"
        ],
        "model": "lang-en",
        "rank": 554,
        "inflexion": "behind",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "behind"
        ],
        "wt": "fw",
        "id": "0njq90SOT6y6ss1MbNjO"
    },
    {
        "infexions": [
            "key",
            "keys"
        ],
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "key, keys",
        "rank": 555,
        "inflexions": [
            "key",
            "keys"
        ],
        "term": "key",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "F8lt6SY6RGhprJ31CSNx"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "rank": 556,
        "inflexions": [
            "end",
            "ends"
        ],
        "term": "end",
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "end",
            "ends"
        ],
        "inflexion": "end, ends",
        "workspace_id": "anglais",
        "id": "4owanPH28TGeXcNNkSDT"
    },
    {
        "term": "dress",
        "lang": "en-fr",
        "wt": "v",
        "model": "lang-en",
        "inflexion": "dress, dresses, dressing, dressed",
        "rank": 557,
        "workspace_id": "anglais",
        "infexions": [
            "dress",
            "dresses",
            "dressing",
            "dressed"
        ],
        "isPareto": true,
        "inflexions": [
            "dress",
            "dresses",
            "dressing",
            "dressed"
        ],
        "id": "GXcOlfsbxJdwPLcndJqP"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "red",
            "redder",
            "reddest"
        ],
        "rank": 558,
        "term": "red",
        "infexions": [
            "red",
            "redder",
            "reddest"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "red, redder, reddest",
        "wt": "j",
        "id": "8DMQnj4T7cAqQEKPIiwd"
    },
    {
        "isPareto": true,
        "term": "laugh",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 559,
        "infexions": [
            "laugh",
            "laughs",
            "laughing",
            "laughed"
        ],
        "lang": "en-fr",
        "inflexions": [
            "laugh",
            "laughs",
            "laughing",
            "laughed"
        ],
        "inflexion": "laugh, laughs, laughing, laughed",
        "wt": "v",
        "id": "HjheMD992nQw8u0g2Jjp"
    },
    {
        "inflexions": [
            "help"
        ],
        "rank": 560,
        "workspace_id": "anglais",
        "infexions": [
            "help"
        ],
        "term": "help",
        "isPareto": true,
        "wt": "n",
        "inflexion": "help",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "RlNRoxTfh5P437qWtosf"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "agree",
            "agrees",
            "agreeing",
            "agreed"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "rank": 561,
        "model": "lang-en",
        "inflexion": "agree, agrees, agreeing, agreed",
        "isPareto": true,
        "term": "agree",
        "infexions": [
            "agree",
            "agrees",
            "agreeing",
            "agreed"
        ],
        "id": "mIIGYgMTgRCmHq3n7ilq"
    },
    {
        "lang": "en-fr",
        "term": "machine",
        "rank": 562,
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "machine",
            "machines"
        ],
        "infexions": [
            "machine",
            "machines"
        ],
        "model": "lang-en",
        "inflexion": "machine, machines",
        "id": "L5FBfflxeJ3lGrc67X7Z"
    },
    {
        "inflexion": "yep",
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "yep"
        ],
        "inflexions": [
            "yep"
        ],
        "rank": 563,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "r",
        "term": "yep",
        "id": "DwyBoT9fTYdeu5Up498J"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "term": "return",
        "workspace_id": "anglais",
        "inflexion": "return, returns, returning, returned",
        "isPareto": true,
        "wt": "v",
        "rank": 564,
        "inflexions": [
            "return",
            "returns",
            "returning",
            "returned"
        ],
        "infexions": [
            "return",
            "returns",
            "returning",
            "returned"
        ],
        "id": "DEprhK8py2upjqMWHb1L"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "space",
            "spaces"
        ],
        "inflexions": [
            "space",
            "spaces"
        ],
        "lang": "en-fr",
        "term": "space",
        "isPareto": true,
        "wt": "n",
        "rank": 565,
        "model": "lang-en",
        "inflexion": "space, spaces",
        "id": "6I2xqXJutMMKaGJhe6wS"
    },
    {
        "rank": 566,
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "mad",
            "madder",
            "maddest"
        ],
        "inflexion": "mad, madder, maddest",
        "isPareto": true,
        "term": "mad",
        "inflexions": [
            "mad",
            "madder",
            "maddest"
        ],
        "lang": "en-fr",
        "wt": "j",
        "id": "H2gg3NChXWcsNaGRU5jf"
    },
    {
        "workspace_id": "anglais",
        "rank": 567,
        "wt": "n",
        "inflexions": [
            "truth",
            "truths"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "truth",
            "truths"
        ],
        "inflexion": "truth, truths",
        "term": "truth",
        "id": "C7Rmise7xfnmtVetFEOA"
    },
    {
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 568,
        "lang": "en-fr",
        "term": "ice",
        "inflexion": "ice, ices",
        "infexions": [
            "ice",
            "ices"
        ],
        "inflexions": [
            "ice",
            "ices"
        ],
        "id": "DdLb0Av1KtjXcMKPw0kT"
    },
    {
        "inflexion": "follow, follows, following, followed",
        "term": "follow",
        "wt": "v",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "follow",
            "follows",
            "following",
            "followed"
        ],
        "inflexions": [
            "follow",
            "follows",
            "following",
            "followed"
        ],
        "rank": 569,
        "id": "HQWD3jtyDJYNfbBSYVZW"
    },
    {
        "rank": 570,
        "model": "lang-en",
        "inflexion": "outside",
        "term": "outside",
        "wt": "fw",
        "infexions": [
            "outside"
        ],
        "isPareto": true,
        "inflexions": [
            "outside"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "VfbXfSx8IAJpW4eRkhkP"
    },
    {
        "inflexions": [
            "idiot",
            "idiots"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "idiot, idiots",
        "rank": 571,
        "infexions": [
            "idiot",
            "idiots"
        ],
        "term": "idiot",
        "workspace_id": "anglais",
        "wt": "n",
        "id": "WyictgzhJokwhu0Lo9eA"
    },
    {
        "wt": "n",
        "term": "rule",
        "model": "lang-en",
        "rank": 572,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "rule, rules",
        "inflexions": [
            "rule",
            "rules"
        ],
        "infexions": [
            "rule",
            "rules"
        ],
        "id": "6At0nFhLbNJ8vRvUWGSY"
    },
    {
        "wt": "r",
        "inflexion": "absolutely",
        "isPareto": true,
        "infexions": [
            "absolutely"
        ],
        "lang": "en-fr",
        "rank": 573,
        "workspace_id": "anglais",
        "term": "absolutely",
        "model": "lang-en",
        "inflexions": [
            "absolutely"
        ],
        "id": "CIvyEkflKlUsxVVCJZAd"
    },
    {
        "isPareto": true,
        "wt": "r",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "next",
        "inflexions": [
            "next"
        ],
        "rank": 574,
        "infexions": [
            "next"
        ],
        "term": "next",
        "lang": "en-fr",
        "id": "ulRYc8Yx6ESWUJ6MTims"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "rank": 575,
        "term": "against",
        "inflexion": "against",
        "model": "lang-en",
        "wt": "fw",
        "workspace_id": "anglais",
        "infexions": [
            "against"
        ],
        "inflexions": [
            "against"
        ],
        "id": "d9xQtJcH6dox15dCQzTV"
    },
    {
        "infexions": [
            "American",
            "Americans"
        ],
        "rank": 576,
        "model": "lang-en",
        "inflexions": [
            "American",
            "Americans"
        ],
        "inflexion": "American, Americans",
        "workspace_id": "anglais",
        "wt": "K",
        "lang": "en-fr",
        "term": "American",
        "isPareto": true,
        "id": "KRcJVReDody7jrBceAbC"
    },
    {
        "wt": "n",
        "inflexion": "need, needs",
        "model": "lang-en",
        "inflexions": [
            "need",
            "needs"
        ],
        "infexions": [
            "need",
            "needs"
        ],
        "workspace_id": "anglais",
        "rank": 577,
        "lang": "en-fr",
        "term": "need",
        "isPareto": true,
        "id": "8LommkDJRSDYpVo7os9t"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "scare",
            "scares",
            "scaring",
            "scared"
        ],
        "inflexion": "scare, scares, scaring, scared",
        "workspace_id": "anglais",
        "infexions": [
            "scare",
            "scares",
            "scaring",
            "scared"
        ],
        "term": "scare",
        "wt": "v",
        "rank": 578,
        "isPareto": true,
        "id": "HFnRnzYHBBBlkyNDy9yn"
    },
    {
        "term": "notice",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "notice, notices, noticing, noticed",
        "inflexions": [
            "notice",
            "notices",
            "noticing",
            "noticed"
        ],
        "wt": "v",
        "model": "lang-en",
        "infexions": [
            "notice",
            "notices",
            "noticing",
            "noticed"
        ],
        "rank": 579,
        "id": "G0v3BYWw7f4qyVW92vsX"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 580,
        "infexions": [
            "chicken",
            "chickens"
        ],
        "term": "chicken",
        "inflexions": [
            "chicken",
            "chickens"
        ],
        "inflexion": "chicken, chickens",
        "id": "kcCj69mz4emXgxlf922q"
    },
    {
        "inflexions": [
            "ride",
            "rides",
            "riding",
            "rode",
            "ridden"
        ],
        "infexions": [
            "ride",
            "rides",
            "riding",
            "rode",
            "ridden"
        ],
        "wt": "v",
        "lang": "en-fr",
        "inflexion": "ride, rides, riding, rode, ridden",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 581,
        "term": "ride",
        "id": "NqxY5uBMyrLERqwKCUbs"
    },
    {
        "rank": 582,
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "music"
        ],
        "term": "music",
        "inflexion": "music",
        "model": "lang-en",
        "infexions": [
            "music"
        ],
        "id": "2qcWi8DWmAH61pZchprl"
    },
    {
        "rank": 583,
        "inflexions": [
            "join",
            "joins",
            "joining",
            "joined"
        ],
        "wt": "v",
        "lang": "en-fr",
        "model": "lang-en",
        "term": "join",
        "isPareto": true,
        "infexions": [
            "join",
            "joins",
            "joining",
            "joined"
        ],
        "workspace_id": "anglais",
        "inflexion": "join, joins, joining, joined",
        "id": "922v4RM0AhbV3YwpjUYk"
    },
    {
        "rank": 584,
        "inflexions": [
            "next"
        ],
        "wt": "fw",
        "term": "next",
        "isPareto": true,
        "infexions": [
            "next"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "next",
        "workspace_id": "anglais",
        "id": "j4cGi1eHseG1dPVADNt5"
    },
    {
        "rank": 585,
        "workspace_id": "anglais",
        "term": "good-bye",
        "lang": "en-fr",
        "inflexions": [
            "good-bye",
            "goodbye"
        ],
        "inflexion": "good-bye, goodbye",
        "isPareto": true,
        "model": "lang-en",
        "wt": "u",
        "infexions": [
            "good-bye",
            "goodbye"
        ],
        "id": "d88uGPsnAtY1oAFcp1kX"
    },
    {
        "infexions": [
            "ruin",
            "ruins",
            "ruining",
            "ruined"
        ],
        "lang": "en-fr",
        "inflexions": [
            "ruin",
            "ruins",
            "ruining",
            "ruined"
        ],
        "model": "lang-en",
        "inflexion": "ruin, ruins, ruining, ruined",
        "rank": 586,
        "isPareto": true,
        "wt": "v",
        "term": "ruin",
        "workspace_id": "anglais",
        "id": "xYtOsohHvmGQXgj2QMep"
    },
    {
        "infexions": [
            "war",
            "wars"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "war, wars",
        "term": "war",
        "rank": 587,
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "war",
            "wars"
        ],
        "id": "vMMBmd5TSDKf6xgMTjmg"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "screw",
            "screws",
            "screwing",
            "screwed"
        ],
        "inflexion": "screw, screws, screwing, screwed",
        "rank": 588,
        "wt": "v",
        "term": "screw",
        "inflexions": [
            "screw",
            "screws",
            "screwing",
            "screwed"
        ],
        "lang": "en-fr",
        "id": "ghfl3XXqpTQcvAYpjV6v"
    },
    {
        "rank": 589,
        "model": "lang-en",
        "inflexions": [
            "light",
            "lights"
        ],
        "workspace_id": "anglais",
        "term": "light",
        "lang": "en-fr",
        "inflexion": "light, lights",
        "isPareto": true,
        "infexions": [
            "light",
            "lights"
        ],
        "wt": "n",
        "id": "SqYyiNAsTMM1MOAeUwv0"
    },
    {
        "inflexions": [
            "along"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 590,
        "term": "along",
        "infexions": [
            "along"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "along",
        "wt": "fw",
        "id": "NuZyph0NlmpKVvZhNuYj"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "poor, poorer, poorest",
        "infexions": [
            "poor",
            "poorer",
            "poorest"
        ],
        "workspace_id": "anglais",
        "term": "poor",
        "rank": 591,
        "inflexions": [
            "poor",
            "poorer",
            "poorest"
        ],
        "wt": "j",
        "lang": "en-fr",
        "id": "L92zSHXdujsGnQRVwvnl"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "sense",
            "senses"
        ],
        "term": "sense",
        "infexions": [
            "sense",
            "senses"
        ],
        "wt": "n",
        "rank": 592,
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "sense, senses",
        "id": "ZmqH60RvvaegwFzF1cM8"
    },
    {
        "wt": "j",
        "inflexion": "able, abler, ablest",
        "inflexions": [
            "able",
            "abler",
            "ablest"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "rank": 593,
        "infexions": [
            "able",
            "abler",
            "ablest"
        ],
        "term": "able",
        "lang": "en-fr",
        "id": "NFl3iebdmJ6jvNJRAXjw"
    },
    {
        "infexions": [
            "matter",
            "matters"
        ],
        "rank": 594,
        "term": "matter",
        "inflexion": "matter, matters",
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "matter",
            "matters"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "9prOU3EaykShIeA3SpRW"
    },
    {
        "inflexions": [
            "secret",
            "secrets"
        ],
        "infexions": [
            "secret",
            "secrets"
        ],
        "isPareto": true,
        "term": "secret",
        "model": "lang-en",
        "rank": 595,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "secret, secrets",
        "wt": "n",
        "id": "ESa55y9cWj41Ss26yxKZ"
    },
    {
        "isPareto": true,
        "inflexions": [
            "fly",
            "flies",
            "flying",
            "flew",
            "flown"
        ],
        "wt": "v",
        "inflexion": "fly, flies, flying, flew, flown",
        "workspace_id": "anglais",
        "rank": 596,
        "infexions": [
            "fly",
            "flies",
            "flying",
            "flew",
            "flown"
        ],
        "term": "fly",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "kTMSue1xHrEVygVTLiks"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "top",
            "tops"
        ],
        "inflexions": [
            "top",
            "tops"
        ],
        "rank": 597,
        "term": "top",
        "wt": "n",
        "inflexion": "top, tops",
        "id": "rxt1O1TzHpVDvKVpxUze"
    },
    {
        "inflexion": "sure",
        "workspace_id": "anglais",
        "infexions": [
            "sure"
        ],
        "wt": "r",
        "isPareto": true,
        "term": "sure",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "sure"
        ],
        "rank": 598,
        "id": "W93ocT5OtynKpOVNxiUi"
    },
    {
        "term": "fair",
        "rank": 599,
        "inflexions": [
            "fair",
            "fairer",
            "fairest"
        ],
        "isPareto": true,
        "inflexion": "fair, fairer, fairest",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "fair",
            "fairer",
            "fairest"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "id": "02apRz5gDoYXFNEUhIB2"
    },
    {
        "isPareto": true,
        "term": "relax",
        "lang": "en-fr",
        "wt": "v",
        "inflexion": "relax, relaxes, relaxing, relaxed",
        "infexions": [
            "relax",
            "relaxes",
            "relaxing",
            "relaxed"
        ],
        "inflexions": [
            "relax",
            "relaxes",
            "relaxing",
            "relaxed"
        ],
        "rank": 600,
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "U7NPukxbShqu4vDZoNT1"
    },
    {
        "term": "boyfriend",
        "rank": 601,
        "inflexions": [
            "boyfriend",
            "boyfriends"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "boyfriend",
            "boyfriends"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "boyfriend, boyfriends",
        "id": "Jjz47S1kFRotb46xSu4z"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "plan",
            "plans"
        ],
        "rank": 602,
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "term": "plan",
        "infexions": [
            "plan",
            "plans"
        ],
        "inflexion": "plan, plans",
        "id": "LOVCdNNm75ydiiy0pPgp"
    },
    {
        "inflexion": "mistake, mistakes",
        "wt": "n",
        "rank": 603,
        "term": "mistake",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "mistake",
            "mistakes"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "mistake",
            "mistakes"
        ],
        "id": "m8iBh0StHdp3ljlZPxYI"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "luck"
        ],
        "isPareto": true,
        "inflexion": "luck",
        "wt": "n",
        "term": "luck",
        "rank": 604,
        "model": "lang-en",
        "infexions": [
            "luck"
        ],
        "workspace_id": "anglais",
        "id": "64IKbIxaxqG8KKYqV8aO"
    },
    {
        "wt": "n",
        "term": "group",
        "isPareto": true,
        "inflexions": [
            "group",
            "groups"
        ],
        "lang": "en-fr",
        "rank": 605,
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "group",
            "groups"
        ],
        "inflexion": "group, groups",
        "id": "YkUkv7RC8YqVcGhcLk2b"
    },
    {
        "term": "daughter",
        "inflexions": [
            "daughter",
            "daughterly",
            "daughters"
        ],
        "infexions": [
            "daughter",
            "daughterly",
            "daughters"
        ],
        "inflexion": "daughter, daughterly, daughters",
        "rank": 606,
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "wt": "n",
        "workspace_id": "anglais",
        "id": "CLhuUNBrxK3ltgI4u4nF"
    },
    {
        "rank": 607,
        "inflexion": "college, colleges",
        "term": "college",
        "infexions": [
            "college",
            "colleges"
        ],
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "college",
            "colleges"
        ],
        "id": "KSpko4eEnMgQYyNPAmIn"
    },
    {
        "infexions": [
            "president",
            "presidents"
        ],
        "rank": 608,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "president",
            "presidents"
        ],
        "wt": "n",
        "isPareto": true,
        "term": "president",
        "inflexion": "president, presidents",
        "model": "lang-en",
        "id": "lwR7V19ZIIHOAHzWpALe"
    },
    {
        "infexions": [
            "far",
            "farther",
            "further",
            "farthest",
            "furthest"
        ],
        "term": "far",
        "inflexion": "far, farther, further, farthest, furthest",
        "inflexions": [
            "far",
            "farther",
            "further",
            "farthest",
            "furthest"
        ],
        "rank": 609,
        "lang": "en-fr",
        "wt": "r",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "z2lmETYhKTBLd7HGB9b4"
    },
    {
        "workspace_id": "anglais",
        "term": "sign",
        "inflexions": [
            "sign",
            "signs",
            "signing",
            "signed"
        ],
        "infexions": [
            "sign",
            "signs",
            "signing",
            "signed"
        ],
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "v",
        "rank": 610,
        "inflexion": "sign, signs, signing, signed",
        "id": "RsLm3iJyaIGNoRsvuA5f"
    },
    {
        "rank": 611,
        "term": "close",
        "infexions": [
            "close",
            "closes",
            "closing",
            "closed"
        ],
        "isPareto": true,
        "wt": "v",
        "inflexions": [
            "close",
            "closes",
            "closing",
            "closed"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "close, closes, closing, closed",
        "id": "ZEPXU1I4bNFRuT8df3SW"
    },
    {
        "infexions": [
            "message",
            "messages"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "term": "message",
        "isPareto": true,
        "inflexions": [
            "message",
            "messages"
        ],
        "inflexion": "message, messages",
        "wt": "n",
        "rank": 612,
        "workspace_id": "anglais",
        "id": "jvPe0ew5YWecj3goeYWA"
    },
    {
        "rank": 613,
        "term": "freak",
        "lang": "en-fr",
        "infexions": [
            "freak",
            "freaks",
            "freaking",
            "freaked"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "inflexions": [
            "freak",
            "freaks",
            "freaking",
            "freaked"
        ],
        "model": "lang-en",
        "inflexion": "freak, freaks, freaking, freaked",
        "isPareto": true,
        "id": "JiuAguMqghRiVXOEWQ8U"
    },
    {
        "inflexions": [
            "fault",
            "faulted",
            "faulting",
            "faultless",
            "faultlessly",
            "faultlessness",
            "faults"
        ],
        "lang": "en-fr",
        "rank": 614,
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "fault",
            "faulted",
            "faulting",
            "faultless",
            "faultlessly",
            "faultlessness",
            "faults"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "fault, faulted, faulting, faultless, faultlessly, faultlessness, faults",
        "term": "fault",
        "id": "b90UE1G9oJcOm9YFw2Yp"
    },
    {
        "workspace_id": "anglais",
        "term": "quick",
        "infexions": [
            "quick",
            "quicker",
            "quickest"
        ],
        "model": "lang-en",
        "wt": "j",
        "inflexions": [
            "quick",
            "quicker",
            "quickest"
        ],
        "rank": 615,
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "quick, quicker, quickest",
        "id": "4epnkpFdzrJk311c3Z9M"
    },
    {
        "rank": 616,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "till"
        ],
        "wt": "fw",
        "inflexions": [
            "till"
        ],
        "term": "till",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "till",
        "id": "RD7TW30fxBnkw8tVhn7b"
    },
    {
        "isPareto": true,
        "rank": 617,
        "infexions": [
            "except"
        ],
        "workspace_id": "anglais",
        "term": "except",
        "lang": "en-fr",
        "inflexion": "except",
        "inflexions": [
            "except"
        ],
        "wt": "fw",
        "model": "lang-en",
        "id": "SIPQOblKa4JYjeiVEtqb"
    },
    {
        "inflexion": "single",
        "inflexions": [
            "single"
        ],
        "lang": "en-fr",
        "term": "single",
        "infexions": [
            "single"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "rank": 618,
        "model": "lang-en",
        "isPareto": true,
        "id": "gG1bQ1mMU7Ihicn19KVe"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "shirt",
            "shirts"
        ],
        "rank": 619,
        "lang": "en-fr",
        "inflexion": "shirt, shirts",
        "wt": "n",
        "infexions": [
            "shirt",
            "shirts"
        ],
        "term": "shirt",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "dpHaDZTfvVHiBQG64HY8"
    },
    {
        "wt": "n",
        "infexions": [
            "choice",
            "choices"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "rank": 620,
        "inflexion": "choice, choices",
        "inflexions": [
            "choice",
            "choices"
        ],
        "term": "choice",
        "lang": "en-fr",
        "id": "ZMUyYrEhyWNjtkgxwRZ3"
    },
    {
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "attention"
        ],
        "infexions": [
            "attention"
        ],
        "term": "attention",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 621,
        "isPareto": true,
        "inflexion": "attention",
        "id": "EdBwSFcgNPZfLwYUp3UC"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "rank": 622,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "power",
        "inflexion": "power, powers",
        "wt": "n",
        "inflexions": [
            "power",
            "powers"
        ],
        "infexions": [
            "power",
            "powers"
        ],
        "id": "Snmo5Sa8YPHTBzTwIf3V"
    },
    {
        "inflexions": [
            "quit",
            "quits",
            "quitting",
            "quitted"
        ],
        "inflexion": "quit, quits, quitting, quitted",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "quit",
            "quits",
            "quitting",
            "quitted"
        ],
        "rank": 623,
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "quit",
        "wt": "v",
        "id": "TjtUqyaNUvUjCtMfeKvo"
    },
    {
        "isPareto": true,
        "inflexion": "answer, answers",
        "infexions": [
            "answer",
            "answers"
        ],
        "rank": 624,
        "inflexions": [
            "answer",
            "answers"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "answer",
        "wt": "n",
        "id": "kCj8bl35EpgjcKtNwSe7"
    },
    {
        "isPareto": true,
        "inflexions": [
            "trust",
            "trusts",
            "trusting",
            "trusted"
        ],
        "infexions": [
            "trust",
            "trusts",
            "trusting",
            "trusted"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 625,
        "inflexion": "trust, trusts, trusting, trusted",
        "term": "trust",
        "wt": "v",
        "id": "ucaVWVR9QFyC0OxtjyJh"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "fifty",
            "fifties"
        ],
        "infexions": [
            "fifty",
            "fifties"
        ],
        "term": "fifty",
        "lang": "en-fr",
        "inflexion": "fifty, fifties",
        "rank": 626,
        "wt": "m",
        "id": "aWTf0IULXdNCwK1eAhIz"
    },
    {
        "inflexions": [
            "leg",
            "legs"
        ],
        "lang": "en-fr",
        "term": "leg",
        "wt": "n",
        "rank": 627,
        "isPareto": true,
        "infexions": [
            "leg",
            "legs"
        ],
        "inflexion": "leg, legs",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "LEYOBznZsZVYhfdzHg6D"
    },
    {
        "rank": 628,
        "infexions": [
            "wake",
            "wakes",
            "waking",
            "waked",
            "woke",
            "woken"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "term": "wake",
        "inflexions": [
            "wake",
            "wakes",
            "waking",
            "waked",
            "woke",
            "woken"
        ],
        "inflexion": "wake, wakes, waking, waked, woke, woken",
        "wt": "v",
        "workspace_id": "anglais",
        "id": "TiTvuCoSd1qCqe5aWzu8"
    },
    {
        "inflexions": [
            "human"
        ],
        "inflexion": "human",
        "isPareto": true,
        "rank": 629,
        "lang": "en-fr",
        "infexions": [
            "human"
        ],
        "wt": "j",
        "term": "human",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "Yme4oww3E3QSS2UpLkOx"
    },
    {
        "infexions": [
            "star",
            "stars"
        ],
        "workspace_id": "anglais",
        "term": "star",
        "rank": 630,
        "lang": "en-fr",
        "inflexions": [
            "star",
            "stars"
        ],
        "wt": "n",
        "inflexion": "star, stars",
        "isPareto": true,
        "model": "lang-en",
        "id": "6SovEUP5QpHPofBdMd7w"
    },
    {
        "inflexions": [
            "student",
            "students"
        ],
        "inflexion": "student, students",
        "rank": 631,
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "student",
            "students"
        ],
        "term": "student",
        "wt": "n",
        "lang": "en-fr",
        "id": "dK7tJs9g7DEGjVoyOs5j"
    },
    {
        "isPareto": true,
        "wt": "n",
        "inflexions": [
            "air",
            "airs"
        ],
        "rank": 632,
        "model": "lang-en",
        "term": "air",
        "inflexion": "air, airs",
        "lang": "en-fr",
        "infexions": [
            "air",
            "airs"
        ],
        "workspace_id": "anglais",
        "id": "6a6sVpo4SK7un2S6aBD0"
    },
    {
        "rank": 633,
        "inflexion": "chair, chairs",
        "infexions": [
            "chair",
            "chairs"
        ],
        "term": "chair",
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "chair",
            "chairs"
        ],
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "vH2B3EUlePNxyqMFwoOG"
    },
    {
        "inflexion": "gun, guns",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "lang": "en-fr",
        "term": "gun",
        "infexions": [
            "gun",
            "guns"
        ],
        "rank": 634,
        "inflexions": [
            "gun",
            "guns"
        ],
        "id": "5G0NJFtovAxo0qJgCLWp"
    },
    {
        "inflexions": [
            "begin",
            "begins",
            "beginning",
            "began",
            "begun"
        ],
        "inflexion": "begin, begins, beginning, began, begun",
        "term": "begin",
        "isPareto": true,
        "rank": 635,
        "infexions": [
            "begin",
            "begins",
            "beginning",
            "began",
            "begun"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "sVowBdaaY0CsZNhO5sGC"
    },
    {
        "term": "death",
        "workspace_id": "anglais",
        "inflexion": "death, deaths",
        "infexions": [
            "death",
            "deaths"
        ],
        "inflexions": [
            "death",
            "deaths"
        ],
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "lang": "en-fr",
        "rank": 636,
        "id": "zTSL4TdSNiewzIm7Luu2"
    },
    {
        "isPareto": true,
        "inflexion": "tree, trees",
        "lang": "en-fr",
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "tree",
            "trees"
        ],
        "rank": 637,
        "model": "lang-en",
        "term": "tree",
        "inflexions": [
            "tree",
            "trees"
        ],
        "id": "9LAmzDBzjH8LKvEeBH7K"
    },
    {
        "model": "lang-en",
        "infexions": [
            "boss",
            "bosses"
        ],
        "rank": 638,
        "inflexions": [
            "boss",
            "bosses"
        ],
        "inflexion": "boss, bosses",
        "term": "boss",
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "fVUiInvxag9gmTUzKyHM"
    },
    {
        "rank": 639,
        "inflexion": "while, whiles",
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "while",
            "whiles"
        ],
        "term": "while",
        "model": "lang-en",
        "inflexions": [
            "while",
            "whiles"
        ],
        "lang": "en-fr",
        "id": "oz7Beenrq7WCpvjYZxF5"
    },
    {
        "term": "present",
        "inflexion": "present, presents",
        "inflexions": [
            "present",
            "presents"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 640,
        "infexions": [
            "present",
            "presents"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "id": "Hx9j5iao99xaRPop9Opr"
    },
    {
        "isPareto": true,
        "inflexions": [
            "instead"
        ],
        "infexions": [
            "instead"
        ],
        "inflexion": "instead",
        "wt": "r",
        "lang": "en-fr",
        "term": "instead",
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 641,
        "id": "YuqFnV3hH8NXmS7W9ABQ"
    },
    {
        "model": "lang-en",
        "infexions": [
            "lucky",
            "luckier",
            "luckiest"
        ],
        "term": "lucky",
        "inflexions": [
            "lucky",
            "luckier",
            "luckiest"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "lucky, luckier, luckiest",
        "rank": 642,
        "wt": "j",
        "id": "AvDYvuDeqsesbHkkqJvg"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "safe",
            "safer",
            "safest"
        ],
        "model": "lang-en",
        "rank": 643,
        "wt": "j",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "safe",
            "safer",
            "safest"
        ],
        "inflexion": "safe, safer, safest",
        "term": "safe",
        "id": "9vSVFlUHXPseqfVRrQLJ"
    },
    {
        "wt": "j",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "cold",
            "colder",
            "coldest"
        ],
        "rank": 644,
        "term": "cold",
        "inflexion": "cold, colder, coldest",
        "inflexions": [
            "cold",
            "colder",
            "coldest"
        ],
        "id": "3u7KmmWE9CaLx8TvZ0Tm"
    },
    {
        "infexions": [
            "explain",
            "explains",
            "explaining",
            "explained"
        ],
        "wt": "v",
        "isPareto": true,
        "term": "explain",
        "model": "lang-en",
        "inflexion": "explain, explains, explaining, explained",
        "workspace_id": "anglais",
        "rank": 645,
        "inflexions": [
            "explain",
            "explains",
            "explaining",
            "explained"
        ],
        "lang": "en-fr",
        "id": "FV7cJyJGgc0HkYWC8tWI"
    },
    {
        "lang": "en-fr",
        "inflexion": "video, videos",
        "inflexions": [
            "video",
            "videos"
        ],
        "model": "lang-en",
        "wt": "n",
        "infexions": [
            "video",
            "videos"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "video",
        "rank": 646,
        "id": "bgv3DavPkBfx2AePjzPD"
    },
    {
        "isPareto": true,
        "infexions": [
            "brain",
            "brains"
        ],
        "inflexion": "brain, brains",
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "brain",
            "brains"
        ],
        "term": "brain",
        "rank": 647,
        "id": "Wvrvmw0yoGyVLLHMRPh3"
    },
    {
        "inflexions": [
            "completely"
        ],
        "inflexion": "completely",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 648,
        "wt": "r",
        "term": "completely",
        "infexions": [
            "completely"
        ],
        "id": "uJMYjTD0lSKyImaw9A67"
    },
    {
        "inflexions": [
            "voice",
            "voices"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 649,
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "voice",
            "voices"
        ],
        "term": "voice",
        "inflexion": "voice, voices",
        "id": "abTjGb7OzEodZee6koXW"
    },
    {
        "term": "cake",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "cake",
            "cakes"
        ],
        "rank": 650,
        "isPareto": true,
        "infexions": [
            "cake",
            "cakes"
        ],
        "inflexion": "cake, cakes",
        "workspace_id": "anglais",
        "id": "c66pBtFYEbcfr78nH08i"
    },
    {
        "term": "drink",
        "lang": "en-fr",
        "infexions": [
            "drink",
            "drinks"
        ],
        "inflexion": "drink, drinks",
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "drink",
            "drinks"
        ],
        "rank": 651,
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "qYED4Q1572ZJ7YxUivL5"
    },
    {
        "inflexions": [
            "trip",
            "trips"
        ],
        "term": "trip",
        "workspace_id": "anglais",
        "infexions": [
            "trip",
            "trips"
        ],
        "inflexion": "trip, trips",
        "isPareto": true,
        "model": "lang-en",
        "rank": 652,
        "lang": "en-fr",
        "wt": "n",
        "id": "gBmC41ZDBA0pKLKbHQ5D"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "monkey",
            "monkeys"
        ],
        "infexions": [
            "monkey",
            "monkeys"
        ],
        "rank": 653,
        "wt": "n",
        "inflexion": "monkey, monkeys",
        "term": "monkey",
        "workspace_id": "anglais",
        "id": "tCNKIjs0M2dh32jgU2ZQ"
    },
    {
        "lang": "en-fr",
        "term": "hundred",
        "inflexion": "hundred, hundreds",
        "rank": 654,
        "wt": "m",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "hundred",
            "hundreds"
        ],
        "infexions": [
            "hundred",
            "hundreds"
        ],
        "workspace_id": "anglais",
        "id": "16vfH5IXHi2AcZN8Ix4m"
    },
    {
        "term": "fix",
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 655,
        "inflexion": "fix, fixes, fixing, fixed",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "fix",
            "fixes",
            "fixing",
            "fixed"
        ],
        "wt": "v",
        "infexions": [
            "fix",
            "fixes",
            "fixing",
            "fixed"
        ],
        "id": "jjpedebCTrP0wX3N5orW"
    },
    {
        "inflexions": [
            "entire"
        ],
        "isPareto": true,
        "term": "entire",
        "wt": "j",
        "lang": "en-fr",
        "infexions": [
            "entire"
        ],
        "workspace_id": "anglais",
        "rank": 656,
        "model": "lang-en",
        "inflexion": "entire",
        "id": "zoBmWpgzgsAUlJQ1l9t8"
    },
    {
        "inflexion": "expect, expected, expecting, expects",
        "lang": "en-fr",
        "term": "expect",
        "inflexions": [
            "expect",
            "expected",
            "expecting",
            "expects"
        ],
        "model": "lang-en",
        "infexions": [
            "expect",
            "expected",
            "expecting",
            "expects"
        ],
        "isPareto": true,
        "rank": 657,
        "workspace_id": "anglais",
        "wt": "v",
        "id": "CqDWMbZ4GABCslVXh9XP"
    },
    {
        "rank": 658,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "million, millions",
        "inflexions": [
            "million",
            "millions"
        ],
        "infexions": [
            "million",
            "millions"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "wt": "m",
        "term": "million",
        "id": "seVF55aKxkIrtrmdbzHs"
    },
    {
        "wt": "v",
        "isPareto": true,
        "inflexions": [
            "allow",
            "allows",
            "allowing",
            "allowed"
        ],
        "model": "lang-en",
        "infexions": [
            "allow",
            "allows",
            "allowing",
            "allowed"
        ],
        "workspace_id": "anglais",
        "term": "allow",
        "inflexion": "allow, allows, allowing, allowed",
        "rank": 659,
        "lang": "en-fr",
        "id": "oyz4DChei9YI2tBX7Lay"
    },
    {
        "term": "forever",
        "wt": "r",
        "isPareto": true,
        "inflexions": [
            "forever"
        ],
        "infexions": [
            "forever"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "forever",
        "rank": 660,
        "lang": "en-fr",
        "id": "vIbdjfRy90xwWl3hs4RN"
    },
    {
        "lang": "en-fr",
        "term": "hide",
        "wt": "v",
        "inflexion": "hide, hides, hiding, hid, hidden",
        "infexions": [
            "hide",
            "hides",
            "hiding",
            "hid",
            "hidden"
        ],
        "rank": 661,
        "inflexions": [
            "hide",
            "hides",
            "hiding",
            "hid",
            "hidden"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "id": "LeMAdwlpYUST0cpNzlsm"
    },
    {
        "term": "huge",
        "workspace_id": "anglais",
        "infexions": [
            "huge",
            "huger",
            "hugest"
        ],
        "model": "lang-en",
        "inflexions": [
            "huge",
            "huger",
            "hugest"
        ],
        "rank": 662,
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "inflexion": "huge, huger, hugest",
        "id": "ZHc7rhbonlx0IdG0LQTA"
    },
    {
        "rank": 663,
        "inflexion": "interesting",
        "term": "interesting",
        "inflexions": [
            "interesting"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "interesting"
        ],
        "wt": "j",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "0UMJJd4v2gTURMOLD8yX"
    },
    {
        "infexions": [
            "fight",
            "fights"
        ],
        "wt": "n",
        "inflexions": [
            "fight",
            "fights"
        ],
        "inflexion": "fight, fights",
        "term": "fight",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 664,
        "model": "lang-en",
        "isPareto": true,
        "id": "MGdvy3KXqWii38wV6xjf"
    },
    {
        "inflexion": "roll, rolls, rolling, rolled",
        "infexions": [
            "roll",
            "rolls",
            "rolling",
            "rolled"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "roll",
        "isPareto": true,
        "inflexions": [
            "roll",
            "rolls",
            "rolling",
            "rolled"
        ],
        "wt": "v",
        "rank": 665,
        "id": "hf8TFzen8qopnpg2LwI9"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "clothes"
        ],
        "rank": 666,
        "infexions": [
            "clothes"
        ],
        "wt": "n",
        "lang": "en-fr",
        "term": "clothes",
        "inflexion": "clothes",
        "id": "swlgTlm3LAPJmdB8E76W"
    },
    {
        "inflexion": "fast, faster, fastest",
        "rank": 667,
        "isPareto": true,
        "inflexions": [
            "fast",
            "faster",
            "fastest"
        ],
        "term": "fast",
        "wt": "r",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "fast",
            "faster",
            "fastest"
        ],
        "lang": "en-fr",
        "id": "u9M3KvzSU8ANQuvJYTHH"
    },
    {
        "wt": "v",
        "inflexion": "grab, grabs, grabbing, grabbed",
        "workspace_id": "anglais",
        "term": "grab",
        "isPareto": true,
        "infexions": [
            "grab",
            "grabs",
            "grabbing",
            "grabbed"
        ],
        "lang": "en-fr",
        "inflexions": [
            "grab",
            "grabs",
            "grabbing",
            "grabbed"
        ],
        "model": "lang-en",
        "rank": 668,
        "id": "iJV2BNBCAvmzlm80e8fR"
    },
    {
        "inflexion": "teacher, teachers",
        "inflexions": [
            "teacher",
            "teachers"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "rank": 669,
        "term": "teacher",
        "infexions": [
            "teacher",
            "teachers"
        ],
        "id": "G58DFwZQ5x3S1MiMQbfa"
    },
    {
        "rank": 670,
        "infexions": [
            "crap",
            "craps"
        ],
        "inflexion": "crap, craps",
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "crap",
        "inflexions": [
            "crap",
            "craps"
        ],
        "model": "lang-en",
        "id": "ddEud9FmLobmwINIZtXf"
    },
    {
        "term": "animal",
        "infexions": [
            "animal",
            "animals"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "animal",
            "animals"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "lang": "en-fr",
        "rank": 671,
        "inflexion": "animal, animals",
        "id": "CEYvFg7BgaiLA3nm5moK"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "term": "burn",
        "workspace_id": "anglais",
        "inflexion": "burn, burns, burning, burned, burnt",
        "rank": 672,
        "infexions": [
            "burn",
            "burns",
            "burning",
            "burned",
            "burnt"
        ],
        "wt": "v",
        "inflexions": [
            "burn",
            "burns",
            "burning",
            "burned",
            "burnt"
        ],
        "id": "ZxLORMKIRBlNiYGm3qSL"
    },
    {
        "isPareto": true,
        "inflexion": "tough, tougher, toughest",
        "term": "tough",
        "workspace_id": "anglais",
        "inflexions": [
            "tough",
            "tougher",
            "toughest"
        ],
        "wt": "j",
        "infexions": [
            "tough",
            "tougher",
            "toughest"
        ],
        "rank": 673,
        "lang": "en-fr",
        "model": "lang-en",
        "id": "tHnkJREk3qLV84mU0QMX"
    },
    {
        "inflexion": "restaurant, restaurants",
        "rank": 674,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "restaurant",
            "restaurants"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "n",
        "term": "restaurant",
        "infexions": [
            "restaurant",
            "restaurants"
        ],
        "id": "S0IZxNRt63A7kppv62dc"
    },
    {
        "inflexion": "sort, sorts",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "sort",
            "sorts"
        ],
        "inflexions": [
            "sort",
            "sorts"
        ],
        "term": "sort",
        "lang": "en-fr",
        "rank": 675,
        "id": "7FGnlleb5DtikgSUu1bE"
    },
    {
        "term": "tooth",
        "inflexion": "tooth, teeth",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "tooth",
            "teeth"
        ],
        "inflexions": [
            "tooth",
            "teeth"
        ],
        "wt": "n",
        "rank": 676,
        "workspace_id": "anglais",
        "id": "mWByOhkrThdPaMRpOWde"
    },
    {
        "term": "marriage",
        "inflexion": "marriage, marriages",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 677,
        "wt": "n",
        "inflexions": [
            "marriage",
            "marriages"
        ],
        "model": "lang-en",
        "infexions": [
            "marriage",
            "marriages"
        ],
        "id": "padvwWfj2050NdGVDb8q"
    },
    {
        "infexions": [
            "proud",
            "prouder",
            "proudest"
        ],
        "inflexion": "proud, prouder, proudest",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "term": "proud",
        "inflexions": [
            "proud",
            "prouder",
            "proudest"
        ],
        "wt": "j",
        "rank": 678,
        "id": "lUWO06WtMV9LAphO8PO1"
    },
    {
        "infexions": [
            "uncle",
            "uncles"
        ],
        "rank": 679,
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "term": "uncle",
        "inflexion": "uncle, uncles",
        "inflexions": [
            "uncle",
            "uncles"
        ],
        "lang": "en-fr",
        "id": "ORKPwdo41sjt1LE7aGd5"
    },
    {
        "inflexions": [
            "push",
            "pushes",
            "pushing",
            "pushed"
        ],
        "term": "push",
        "model": "lang-en",
        "rank": 680,
        "lang": "en-fr",
        "infexions": [
            "push",
            "pushes",
            "pushing",
            "pushed"
        ],
        "workspace_id": "anglais",
        "inflexion": "push, pushes, pushing, pushed",
        "isPareto": true,
        "wt": "v",
        "id": "MZ0tYayUX6bNs3T9H3OQ"
    },
    {
        "lang": "en-fr",
        "term": "butt",
        "infexions": [
            "butt",
            "butts"
        ],
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "rank": 681,
        "inflexion": "butt, butts",
        "inflexions": [
            "butt",
            "butts"
        ],
        "workspace_id": "anglais",
        "id": "6u3fv4AOsHAXQdbmWPCH"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "infexions": [
            "offer",
            "offers",
            "offering",
            "offered"
        ],
        "term": "offer",
        "rank": 682,
        "inflexion": "offer, offers, offering, offered",
        "inflexions": [
            "offer",
            "offers",
            "offering",
            "offered"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "IBnqxe3JOBcnr29rW6Yk"
    },
    {
        "inflexion": "lord, lords",
        "rank": 683,
        "infexions": [
            "lord",
            "lords"
        ],
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "lord",
        "inflexions": [
            "lord",
            "lords"
        ],
        "id": "aZXs0z1MjttmbWJY6I2N"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "term": "unless",
        "inflexion": "unless",
        "infexions": [
            "unless"
        ],
        "wt": "fw",
        "rank": 684,
        "workspace_id": "anglais",
        "inflexions": [
            "unless"
        ],
        "id": "B85yNqa1R3wvmI2oYAGI"
    },
    {
        "isPareto": true,
        "rank": 685,
        "model": "lang-en",
        "term": "feeling",
        "workspace_id": "anglais",
        "wt": "n",
        "inflexion": "feeling, feelings",
        "lang": "en-fr",
        "inflexions": [
            "feeling",
            "feelings"
        ],
        "infexions": [
            "feeling",
            "feelings"
        ],
        "id": "hU5YKX6YUUZXM4u51UXY"
    },
    {
        "model": "lang-en",
        "term": "suit",
        "infexions": [
            "suit",
            "suits"
        ],
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "suit, suits",
        "isPareto": true,
        "rank": 686,
        "workspace_id": "anglais",
        "inflexions": [
            "suit",
            "suits"
        ],
        "id": "XNyUZAXXB8bh9m1rEafc"
    },
    {
        "wt": "u",
        "inflexions": [
            "uh-huh"
        ],
        "rank": 687,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "uh-huh"
        ],
        "isPareto": true,
        "inflexion": "uh-huh",
        "term": "uh-huh",
        "model": "lang-en",
        "id": "IORy8pPTi9jsJo7CPYve"
    },
    {
        "inflexions": [
            "floor",
            "floors"
        ],
        "isPareto": true,
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "floor",
            "floors"
        ],
        "lang": "en-fr",
        "term": "floor",
        "inflexion": "floor, floors",
        "model": "lang-en",
        "rank": 688,
        "id": "3NEifPEC27cw3n82mHrQ"
    },
    {
        "term": "cream",
        "model": "lang-en",
        "inflexion": "cream, creams",
        "inflexions": [
            "cream",
            "creams"
        ],
        "infexions": [
            "cream",
            "creams"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 689,
        "isPareto": true,
        "lang": "en-fr",
        "id": "IPnNDqzMJTQFW1Dsn39l"
    },
    {
        "inflexions": [
            "favorite"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "favorite",
        "isPareto": true,
        "infexions": [
            "favorite"
        ],
        "inflexion": "favorite",
        "lang": "en-fr",
        "wt": "j",
        "rank": 690,
        "id": "0jkAca8bjyg57UfdVlXn"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "naked"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "term": "naked",
        "infexions": [
            "naked"
        ],
        "rank": 691,
        "inflexion": "naked",
        "id": "IBbiapEe3dy05K9924Si"
    },
    {
        "term": "list",
        "workspace_id": "anglais",
        "wt": "n",
        "inflexion": "list, lists",
        "lang": "en-fr",
        "rank": 692,
        "infexions": [
            "list",
            "lists"
        ],
        "model": "lang-en",
        "inflexions": [
            "list",
            "lists"
        ],
        "isPareto": true,
        "id": "sVRhJBzHAaWeGWILSsXC"
    },
    {
        "inflexions": [
            "ring",
            "rings"
        ],
        "workspace_id": "anglais",
        "inflexion": "ring, rings",
        "term": "ring",
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "rank": 693,
        "infexions": [
            "ring",
            "rings"
        ],
        "id": "iMsVaGIUynRz0m5rRYjX"
    },
    {
        "isPareto": true,
        "infexions": [
            "clean",
            "cleans",
            "cleaning",
            "cleaned"
        ],
        "inflexion": "clean, cleans, cleaning, cleaned",
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "clean",
            "cleans",
            "cleaning",
            "cleaned"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "clean",
        "rank": 694,
        "id": "ltnjK4OvSxqoJqgb66Pw"
    },
    {
        "infexions": [
            "apologize",
            "apologizing",
            "apologizes",
            "apologized"
        ],
        "rank": 695,
        "model": "lang-en",
        "term": "apologize",
        "lang": "en-fr",
        "wt": "v",
        "inflexion": "apologize, apologizing, apologizes, apologized",
        "workspace_id": "anglais",
        "inflexions": [
            "apologize",
            "apologizing",
            "apologizes",
            "apologized"
        ],
        "isPareto": true,
        "id": "hz5sjKk7Tv61pJBx73iY"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "j",
        "isPareto": true,
        "term": "clear",
        "infexions": [
            "clear",
            "clearer",
            "clearest"
        ],
        "rank": 696,
        "inflexion": "clear, clearer, clearest",
        "inflexions": [
            "clear",
            "clearer",
            "clearest"
        ],
        "model": "lang-en",
        "id": "kY8zor8e6LVk2Z1AwHXU"
    },
    {
        "infexions": [
            "Earth",
            "earth"
        ],
        "isPareto": true,
        "rank": 697,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "Earth, earth",
        "wt": "K",
        "term": "Earth",
        "workspace_id": "anglais",
        "inflexions": [
            "Earth",
            "earth"
        ],
        "id": "H8II9q3lisggwWXE87JM"
    },
    {
        "lang": "en-fr",
        "inflexion": "share, shares, sharing, shared",
        "isPareto": true,
        "model": "lang-en",
        "term": "share",
        "inflexions": [
            "share",
            "shares",
            "sharing",
            "shared"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "infexions": [
            "share",
            "shares",
            "sharing",
            "shared"
        ],
        "rank": 698,
        "id": "b8sS2xgJa7FbDaxp4HTa"
    },
    {
        "infexions": [
            "fill",
            "fills",
            "filling",
            "filled"
        ],
        "model": "lang-en",
        "term": "fill",
        "isPareto": true,
        "inflexions": [
            "fill",
            "fills",
            "filling",
            "filled"
        ],
        "lang": "en-fr",
        "wt": "v",
        "rank": 699,
        "inflexion": "fill, fills, filling, filled",
        "workspace_id": "anglais",
        "id": "VI83La1C1FmqLMHlokOv"
    },
    {
        "rank": 700,
        "lang": "en-fr",
        "inflexion": "cool",
        "wt": "u",
        "model": "lang-en",
        "inflexions": [
            "cool"
        ],
        "infexions": [
            "cool"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "cool",
        "id": "NQZMtbUOg583gCvuG5wL"
    },
    {
        "inflexions": [
            "pizza",
            "pizzas"
        ],
        "workspace_id": "anglais",
        "rank": 701,
        "inflexion": "pizza, pizzas",
        "wt": "n",
        "isPareto": true,
        "term": "pizza",
        "lang": "en-fr",
        "infexions": [
            "pizza",
            "pizzas"
        ],
        "model": "lang-en",
        "id": "hZwn4clO2uQgRpSNQHcN"
    },
    {
        "inflexion": "ridiculous",
        "rank": 702,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "ridiculous",
        "lang": "en-fr",
        "infexions": [
            "ridiculous"
        ],
        "inflexions": [
            "ridiculous"
        ],
        "wt": "j",
        "isPareto": true,
        "id": "GJBc0uataNZpo2hZ4HsI"
    },
    {
        "rank": 703,
        "term": "alive",
        "lang": "en-fr",
        "inflexions": [
            "alive"
        ],
        "model": "lang-en",
        "wt": "j",
        "infexions": [
            "alive"
        ],
        "inflexion": "alive",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "LAzvN3s49tKTfmcAAtNB"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "pretend",
            "pretends",
            "pretending",
            "pretended"
        ],
        "inflexion": "pretend, pretends, pretending, pretended",
        "wt": "v",
        "rank": 704,
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "pretend",
            "pretends",
            "pretending",
            "pretended"
        ],
        "term": "pretend",
        "workspace_id": "anglais",
        "id": "H9dgfIuFWgDB6jvwZBN9"
    },
    {
        "model": "lang-en",
        "term": "hospital",
        "inflexions": [
            "hospital",
            "hospitals"
        ],
        "inflexion": "hospital, hospitals",
        "infexions": [
            "hospital",
            "hospitals"
        ],
        "wt": "n",
        "rank": 705,
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "id": "zBSRtebBoHeV3J8l5WjA"
    },
    {
        "inflexions": [
            "sad",
            "sadder",
            "saddest"
        ],
        "wt": "j",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "sad, sadder, saddest",
        "isPareto": true,
        "term": "sad",
        "infexions": [
            "sad",
            "sadder",
            "saddest"
        ],
        "rank": 706,
        "workspace_id": "anglais",
        "id": "ko3zE063vpAeEBnm5Hho"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "bunch",
            "bunches"
        ],
        "workspace_id": "anglais",
        "term": "bunch",
        "rank": 707,
        "inflexions": [
            "bunch",
            "bunches"
        ],
        "inflexion": "bunch, bunches",
        "model": "lang-en",
        "wt": "n",
        "id": "b0fM0mZjLqhHmWC2Qx9S"
    },
    {
        "term": "half",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "half",
        "rank": 708,
        "infexions": [
            "half"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "half"
        ],
        "wt": "fw",
        "id": "E5dvh6Dp7RiCPePT8hmY"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "police"
        ],
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "rank": 709,
        "term": "police",
        "infexions": [
            "police"
        ],
        "inflexion": "police",
        "id": "atvwfMED4Znj2ANIbjv5"
    },
    {
        "inflexion": "fish, fishes",
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "fish",
            "fishes"
        ],
        "term": "fish",
        "model": "lang-en",
        "rank": 710,
        "lang": "en-fr",
        "infexions": [
            "fish",
            "fishes"
        ],
        "isPareto": true,
        "id": "JTjqFpfEgT4lW4wSStxV"
    },
    {
        "inflexion": "window, windows",
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "window",
            "windows"
        ],
        "inflexions": [
            "window",
            "windows"
        ],
        "rank": 711,
        "wt": "n",
        "term": "window",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "BDarpzaViWs18JmKrtyT"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "busy",
            "busier",
            "busiest"
        ],
        "isPareto": true,
        "rank": 712,
        "wt": "j",
        "inflexions": [
            "busy",
            "busier",
            "busiest"
        ],
        "inflexion": "busy, busier, busiest",
        "term": "busy",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "sAvZak6ttOH6qCYFUiTI"
    },
    {
        "term": "sign",
        "infexions": [
            "sign",
            "signs"
        ],
        "inflexions": [
            "sign",
            "signs"
        ],
        "rank": 713,
        "isPareto": true,
        "inflexion": "sign, signs",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "f3KkReblRzG2Aoe6haSf"
    },
    {
        "rank": 714,
        "model": "lang-en",
        "inflexions": [
            "call",
            "calls"
        ],
        "wt": "n",
        "term": "call",
        "infexions": [
            "call",
            "calls"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "call, calls",
        "lang": "en-fr",
        "id": "7ZgUBGnvjh26Rm3OVUAm"
    },
    {
        "rank": 715,
        "inflexions": [
            "pie",
            "pies"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "term": "pie",
        "inflexion": "pie, pies",
        "infexions": [
            "pie",
            "pies"
        ],
        "wt": "n",
        "isPareto": true,
        "id": "kp549jNZnWwNpiyZDVgG"
    },
    {
        "rank": 716,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "answer",
            "answers",
            "answering",
            "answered"
        ],
        "wt": "v",
        "isPareto": true,
        "inflexion": "answer, answers, answering, answered",
        "term": "answer",
        "infexions": [
            "answer",
            "answers",
            "answering",
            "answered"
        ],
        "workspace_id": "anglais",
        "id": "JzTxWpQoH57JhZBQCQhS"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "raise",
            "raises",
            "raising",
            "raised"
        ],
        "inflexion": "raise, raises, raising, raised",
        "lang": "en-fr",
        "term": "raise",
        "rank": 717,
        "inflexions": [
            "raise",
            "raises",
            "raising",
            "raised"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "id": "7fNZ5wCd2nof5gBlmAIq"
    },
    {
        "term": "somewhere",
        "inflexion": "somewhere",
        "workspace_id": "anglais",
        "rank": 718,
        "isPareto": true,
        "inflexions": [
            "somewhere"
        ],
        "infexions": [
            "somewhere"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "r",
        "id": "vCgiW4R0Zljvfbhdb9FD"
    },
    {
        "term": "sandwich",
        "rank": 719,
        "infexions": [
            "sandwich",
            "sandwiches"
        ],
        "inflexion": "sandwich, sandwiches",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "sandwich",
            "sandwiches"
        ],
        "wt": "n",
        "model": "lang-en",
        "id": "RcWZ3iwewV3HMBpUdciH"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "thirty",
            "thirties"
        ],
        "wt": "m",
        "rank": 720,
        "isPareto": true,
        "infexions": [
            "thirty",
            "thirties"
        ],
        "term": "thirty",
        "inflexion": "thirty, thirties",
        "id": "cZ2KgQRafQq04MweHFRv"
    },
    {
        "model": "lang-en",
        "term": "sale",
        "inflexion": "sale, sales",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "sale",
            "sales"
        ],
        "inflexions": [
            "sale",
            "sales"
        ],
        "rank": 721,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "lDoL0sqjMJdy1aLU4rVD"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "choose, chooses, choosing, chose, chosen",
        "inflexions": [
            "choose",
            "chooses",
            "choosing",
            "chose",
            "chosen"
        ],
        "wt": "v",
        "term": "choose",
        "infexions": [
            "choose",
            "chooses",
            "choosing",
            "chose",
            "chosen"
        ],
        "model": "lang-en",
        "rank": 722,
        "isPareto": true,
        "lang": "en-fr",
        "id": "xcRXAH52H4Mpm9DzJFcT"
    },
    {
        "inflexion": "definitely",
        "workspace_id": "anglais",
        "inflexions": [
            "definitely"
        ],
        "wt": "r",
        "model": "lang-en",
        "term": "definitely",
        "infexions": [
            "definitely"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 723,
        "id": "PuWNNaSwGkhUNDtD5WkT"
    },
    {
        "term": "swear",
        "inflexion": "swear, swears, swearing, swore, sworn",
        "wt": "v",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "swear",
            "swears",
            "swearing",
            "swore",
            "sworn"
        ],
        "infexions": [
            "swear",
            "swears",
            "swearing",
            "swore",
            "sworn"
        ],
        "rank": 724,
        "model": "lang-en",
        "id": "Bpk1u9cA45uxmU0Z7Z0T"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "pretty"
        ],
        "isPareto": true,
        "term": "pretty",
        "inflexion": "pretty",
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "r",
        "infexions": [
            "pretty"
        ],
        "rank": 725,
        "id": "1l446JWMAS4y4G4PW8LX"
    },
    {
        "isPareto": true,
        "wt": "n",
        "inflexion": "boat, boats",
        "infexions": [
            "boat",
            "boats"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "boat",
            "boats"
        ],
        "rank": 726,
        "term": "boat",
        "id": "vRlby9iNuBeIJvDoxZYe"
    },
    {
        "wt": "j",
        "isPareto": true,
        "lang": "en-fr",
        "rank": 727,
        "term": "tired",
        "workspace_id": "anglais",
        "inflexions": [
            "tired"
        ],
        "inflexion": "tired",
        "infexions": [
            "tired"
        ],
        "model": "lang-en",
        "id": "HAea3qlkL2dFXY8MSQgL"
    },
    {
        "term": "upset",
        "inflexions": [
            "upset"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "upset",
        "infexions": [
            "upset"
        ],
        "wt": "j",
        "model": "lang-en",
        "rank": 728,
        "workspace_id": "anglais",
        "id": "qm8G5cfktSNc4PximLaY"
    },
    {
        "inflexions": [
            "less"
        ],
        "lang": "en-fr",
        "rank": 729,
        "wt": "fw",
        "model": "lang-en",
        "infexions": [
            "less"
        ],
        "workspace_id": "anglais",
        "term": "less",
        "inflexion": "less",
        "isPareto": true,
        "id": "qLHIKtPYl0IhkYfGMRUE"
    },
    {
        "rank": 730,
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "nine",
            "nines"
        ],
        "inflexions": [
            "nine",
            "nines"
        ],
        "term": "nine",
        "inflexion": "nine, nines",
        "wt": "m",
        "id": "vYCSl40CqT192LNcJTwC"
    },
    {
        "wt": "m",
        "infexions": [
            "thousand",
            "thousands"
        ],
        "inflexion": "thousand, thousands",
        "model": "lang-en",
        "isPareto": true,
        "rank": 731,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "thousand",
        "inflexions": [
            "thousand",
            "thousands"
        ],
        "id": "A1uDgAHsK602RAsxfjkR"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "smoke",
            "smokes",
            "smoking",
            "smoked"
        ],
        "wt": "v",
        "isPareto": true,
        "infexions": [
            "smoke",
            "smokes",
            "smoking",
            "smoked"
        ],
        "workspace_id": "anglais",
        "inflexion": "smoke, smokes, smoking, smoked",
        "rank": 732,
        "term": "smoke",
        "model": "lang-en",
        "id": "v8Mf7laqoXN06bY1Se23"
    },
    {
        "wt": "v",
        "inflexions": [
            "embarrass",
            "embarrasses",
            "embarrassing",
            "embarrassed"
        ],
        "inflexion": "embarrass, embarrasses, embarrassing, embarrassed",
        "rank": 733,
        "term": "embarrass",
        "model": "lang-en",
        "infexions": [
            "embarrass",
            "embarrasses",
            "embarrassing",
            "embarrassed"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "sHaZJ01PDMQk1muY8WD8"
    },
    {
        "isPareto": true,
        "inflexions": [
            "band",
            "bands"
        ],
        "workspace_id": "anglais",
        "rank": 734,
        "term": "band",
        "infexions": [
            "band",
            "bands"
        ],
        "model": "lang-en",
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "band, bands",
        "id": "YWK4UhuFqUWBV9gBwJI9"
    },
    {
        "model": "lang-en",
        "rank": 735,
        "term": "bear",
        "inflexions": [
            "bear",
            "bears",
            "bearing",
            "bore",
            "borne",
            "born"
        ],
        "inflexion": "bear, bears, bearing, bore, borne, born",
        "wt": "v",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "bear",
            "bears",
            "bearing",
            "bore",
            "borne",
            "born"
        ],
        "id": "GzW0FPjyKqmvVV25fc74"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "rank": 736,
        "wt": "fw",
        "lang": "en-fr",
        "infexions": [
            "none"
        ],
        "inflexion": "none",
        "workspace_id": "anglais",
        "inflexions": [
            "none"
        ],
        "term": "none",
        "id": "w61xdF3c5BoBaNRpkfKY"
    },
    {
        "wt": "K",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 737,
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "Santa"
        ],
        "inflexion": "Santa",
        "infexions": [
            "Santa"
        ],
        "term": "Santa",
        "id": "oWkOMP5PhUwTrUauXoYY"
    },
    {
        "rank": 738,
        "isPareto": true,
        "inflexion": "strong, stronger, strongest",
        "wt": "j",
        "infexions": [
            "strong",
            "stronger",
            "strongest"
        ],
        "term": "strong",
        "workspace_id": "anglais",
        "inflexions": [
            "strong",
            "stronger",
            "strongest"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "id": "03X7M9cXzSNeKzsDBzz9"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "law",
            "laws"
        ],
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "law",
            "laws"
        ],
        "inflexion": "law, laws",
        "rank": 739,
        "isPareto": true,
        "term": "law",
        "id": "0n2lsQLR4rabMcoXk8lN"
    },
    {
        "inflexion": "toilet, toilets",
        "term": "toilet",
        "workspace_id": "anglais",
        "inflexions": [
            "toilet",
            "toilets"
        ],
        "infexions": [
            "toilet",
            "toilets"
        ],
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "rank": 740,
        "wt": "n",
        "id": "5DIlf6aPrvTosXYLSZT1"
    },
    {
        "inflexion": "jump, jumps, jumping, jumped",
        "model": "lang-en",
        "term": "jump",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "jump",
            "jumps",
            "jumping",
            "jumped"
        ],
        "rank": 741,
        "infexions": [
            "jump",
            "jumps",
            "jumping",
            "jumped"
        ],
        "wt": "v",
        "id": "cZ679eHjzKqXG8gqTN2G"
    },
    {
        "inflexions": [
            "count",
            "counts",
            "counting",
            "counted"
        ],
        "model": "lang-en",
        "inflexion": "count, counts, counting, counted",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "v",
        "term": "count",
        "infexions": [
            "count",
            "counts",
            "counting",
            "counted"
        ],
        "rank": 742,
        "id": "NyNFGkk7py82IEe63Ivi"
    },
    {
        "term": "egg",
        "isPareto": true,
        "infexions": [
            "egg",
            "eggs"
        ],
        "wt": "n",
        "rank": 743,
        "inflexion": "egg, eggs",
        "inflexions": [
            "egg",
            "eggs"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "uRRJ2H5xWsfjyatEm779"
    },
    {
        "rank": 744,
        "wt": "K",
        "term": "Saturday",
        "workspace_id": "anglais",
        "infexions": [
            "Saturday",
            "Saturdays"
        ],
        "inflexions": [
            "Saturday",
            "Saturdays"
        ],
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "Saturday, Saturdays",
        "id": "PgwSfOJUtQXJ1MwYrzcQ"
    },
    {
        "inflexions": [
            "blue",
            "bluer",
            "bluest"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "model": "lang-en",
        "inflexion": "blue, bluer, bluest",
        "term": "blue",
        "workspace_id": "anglais",
        "infexions": [
            "blue",
            "bluer",
            "bluest"
        ],
        "rank": 745,
        "id": "CIhwWU4KHghkBxR0lkhz"
    },
    {
        "infexions": [
            "horrible"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "horrible",
        "inflexions": [
            "horrible"
        ],
        "lang": "en-fr",
        "rank": 746,
        "model": "lang-en",
        "wt": "j",
        "term": "horrible",
        "id": "1QM1ql0aWb5M3IMS5aM0"
    },
    {
        "lang": "en-fr",
        "rank": 747,
        "inflexions": [
            "shit",
            "shits"
        ],
        "infexions": [
            "shit",
            "shits"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "shit",
        "wt": "n",
        "model": "lang-en",
        "inflexion": "shit, shits",
        "id": "FtH76omQnjwBRm6TclQN"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 748,
        "term": "favor",
        "wt": "n",
        "workspace_id": "anglais",
        "inflexion": "favor, favors",
        "inflexions": [
            "favor",
            "favors"
        ],
        "infexions": [
            "favor",
            "favors"
        ],
        "id": "tpAcguvYHCz1APyzbkcH"
    },
    {
        "inflexions": [
            "handle",
            "handles",
            "handling",
            "handled"
        ],
        "wt": "v",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "handle",
            "handles",
            "handling",
            "handled"
        ],
        "model": "lang-en",
        "term": "handle",
        "inflexion": "handle, handles, handling, handled",
        "lang": "en-fr",
        "rank": 749,
        "id": "ZwpZyI8ai7mZHUEO027q"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "wall",
            "walls"
        ],
        "inflexion": "wall, walls",
        "term": "wall",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "infexions": [
            "wall",
            "walls"
        ],
        "rank": 750,
        "id": "RmZEylJRznz9XTWok1mT"
    },
    {
        "wt": "n",
        "inflexion": "art, arts",
        "term": "art",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "art",
            "arts"
        ],
        "inflexions": [
            "art",
            "arts"
        ],
        "lang": "en-fr",
        "rank": 751,
        "isPareto": true,
        "id": "2wVbrECNYpl2OllBVhI7"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "rank": 752,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "cover",
        "inflexion": "cover, covers, covering, covered",
        "wt": "v",
        "inflexions": [
            "cover",
            "covers",
            "covering",
            "covered"
        ],
        "infexions": [
            "cover",
            "covers",
            "covering",
            "covered"
        ],
        "id": "P1pCm9qjJRNauuo3ZEoS"
    },
    {
        "inflexion": "arm, arms",
        "isPareto": true,
        "term": "arm",
        "inflexions": [
            "arm",
            "arms"
        ],
        "infexions": [
            "arm",
            "arms"
        ],
        "workspace_id": "anglais",
        "rank": 753,
        "wt": "n",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "plJzeagWcQR3C9cB5m2a"
    },
    {
        "rank": 754,
        "lang": "en-fr",
        "term": "perhaps",
        "isPareto": true,
        "inflexion": "perhaps",
        "inflexions": [
            "perhaps"
        ],
        "model": "lang-en",
        "wt": "r",
        "workspace_id": "anglais",
        "infexions": [
            "perhaps"
        ],
        "id": "Nj1Me8OxDwLUkYvF5MEl"
    },
    {
        "infexions": [
            "simple",
            "simpler",
            "simplest"
        ],
        "rank": 755,
        "term": "simple",
        "inflexion": "simple, simpler, simplest",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "j",
        "inflexions": [
            "simple",
            "simpler",
            "simplest"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "id": "1uDjJGdpC4SZTNVMXn5n"
    },
    {
        "inflexion": "bus, buses",
        "rank": 756,
        "wt": "n",
        "term": "bus",
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "bus",
            "buses"
        ],
        "inflexions": [
            "bus",
            "buses"
        ],
        "id": "mM3afEkRSadBbrdqWu1B"
    },
    {
        "wt": "v",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "appreciate",
            "appreciates",
            "appreciating",
            "appreciated"
        ],
        "inflexion": "appreciate, appreciates, appreciating, appreciated",
        "term": "appreciate",
        "infexions": [
            "appreciate",
            "appreciates",
            "appreciating",
            "appreciated"
        ],
        "rank": 757,
        "id": "7Lapwd9LYrRKFz8tQAlb"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "himself"
        ],
        "model": "lang-en",
        "infexions": [
            "himself"
        ],
        "wt": "fw",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "himself",
        "term": "himself",
        "rank": 758,
        "id": "8IOGyoxOIEXOxYSblevJ"
    },
    {
        "infexions": [
            "surprise",
            "surprises",
            "surprising",
            "surprised"
        ],
        "wt": "v",
        "term": "surprise",
        "isPareto": true,
        "inflexions": [
            "surprise",
            "surprises",
            "surprising",
            "surprised"
        ],
        "workspace_id": "anglais",
        "rank": 759,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "surprise, surprises, surprising, surprised",
        "id": "5xIlLLgJFEXhuO40MYpS"
    },
    {
        "infexions": [
            "candy",
            "candies"
        ],
        "term": "candy",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 760,
        "inflexions": [
            "candy",
            "candies"
        ],
        "inflexion": "candy, candies",
        "id": "iZjBmsUE4R3KDo0HaIfZ"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "finger, fingers",
        "inflexions": [
            "finger",
            "fingers"
        ],
        "term": "finger",
        "workspace_id": "anglais",
        "rank": 761,
        "infexions": [
            "finger",
            "fingers"
        ],
        "wt": "n",
        "id": "A5W7xJL6V6ujytX3Cg6B"
    },
    {
        "term": "worth",
        "workspace_id": "anglais",
        "infexions": [
            "worth"
        ],
        "wt": "j",
        "rank": 762,
        "lang": "en-fr",
        "inflexions": [
            "worth"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "worth",
        "id": "lC3i5ufZR1ecfLbUI1Zq"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "rank": 763,
        "inflexions": [
            "state",
            "states"
        ],
        "inflexion": "state, states",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "state",
        "infexions": [
            "state",
            "states"
        ],
        "id": "LD4ej0d00IMZdNV3kKW7"
    },
    {
        "model": "lang-en",
        "term": "possible",
        "infexions": [
            "possible"
        ],
        "rank": 764,
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "possible"
        ],
        "inflexion": "possible",
        "wt": "j",
        "id": "1YVey5S4Gt75rQwgxk5d"
    },
    {
        "inflexion": "rich, richer, richest",
        "lang": "en-fr",
        "wt": "j",
        "workspace_id": "anglais",
        "infexions": [
            "rich",
            "richer",
            "richest"
        ],
        "term": "rich",
        "inflexions": [
            "rich",
            "richer",
            "richest"
        ],
        "rank": 765,
        "isPareto": true,
        "model": "lang-en",
        "id": "uSABIEaHNlqjEOcbcsQ7"
    },
    {
        "term": "short",
        "model": "lang-en",
        "rank": 766,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "short, shorter, shortest",
        "infexions": [
            "short",
            "shorter",
            "shortest"
        ],
        "lang": "en-fr",
        "wt": "j",
        "inflexions": [
            "short",
            "shorter",
            "shortest"
        ],
        "id": "L5jjyHabRsIWrR4mVhY7"
    },
    {
        "workspace_id": "anglais",
        "term": "king",
        "infexions": [
            "king",
            "kings"
        ],
        "isPareto": true,
        "inflexion": "king, kings",
        "lang": "en-fr",
        "inflexions": [
            "king",
            "kings"
        ],
        "wt": "n",
        "rank": 767,
        "model": "lang-en",
        "id": "isAVYyibwa20Vrichlp4"
    },
    {
        "wt": "v",
        "lang": "en-fr",
        "term": "knock",
        "infexions": [
            "knock",
            "knocks",
            "knocking",
            "knocked"
        ],
        "inflexion": "knock, knocks, knocking, knocked",
        "isPareto": true,
        "inflexions": [
            "knock",
            "knocks",
            "knocking",
            "knocked"
        ],
        "model": "lang-en",
        "rank": 768,
        "workspace_id": "anglais",
        "id": "dz4gQo86uic3jYoIvqPy"
    },
    {
        "rank": 769,
        "inflexion": "cookie, cookies",
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "cookie",
            "cookies"
        ],
        "lang": "en-fr",
        "infexions": [
            "cookie",
            "cookies"
        ],
        "term": "cookie",
        "id": "smdHAxavTBgSVRv90Q9Q"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "penis",
            "penises",
            "penes"
        ],
        "inflexion": "penis, penises, penes",
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 770,
        "infexions": [
            "penis",
            "penises",
            "penes"
        ],
        "term": "penis",
        "workspace_id": "anglais",
        "id": "aauGk2COCKUXZiYItCFm"
    },
    {
        "inflexions": [
            "history",
            "histories"
        ],
        "workspace_id": "anglais",
        "term": "history",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "history, histories",
        "wt": "n",
        "model": "lang-en",
        "rank": 771,
        "infexions": [
            "history",
            "histories"
        ],
        "id": "8PqQXk4I5YO1FOi2RVbt"
    },
    {
        "inflexions": [
            "imagine",
            "imagines",
            "imagining",
            "imagined"
        ],
        "rank": 772,
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "v",
        "term": "imagine",
        "inflexion": "imagine, imagines, imagining, imagined",
        "model": "lang-en",
        "infexions": [
            "imagine",
            "imagines",
            "imagining",
            "imagined"
        ],
        "id": "MY2weF08obWz0EwdBzqM"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "third",
            "thirds"
        ],
        "inflexion": "third, thirds",
        "term": "third",
        "workspace_id": "anglais",
        "inflexions": [
            "third",
            "thirds"
        ],
        "rank": 773,
        "wt": "m",
        "id": "xAL7F3LlkiO2ei9TYIqZ"
    },
    {
        "inflexions": [
            "blood",
            "bloods"
        ],
        "rank": 774,
        "workspace_id": "anglais",
        "infexions": [
            "blood",
            "bloods"
        ],
        "term": "blood",
        "model": "lang-en",
        "inflexion": "blood, bloods",
        "wt": "n",
        "lang": "en-fr",
        "isPareto": true,
        "id": "dFd8LgYUPabMQOlzKvAP"
    },
    {
        "infexions": [
            "drug",
            "drugs"
        ],
        "isPareto": true,
        "wt": "n",
        "term": "drug",
        "workspace_id": "anglais",
        "rank": 775,
        "lang": "en-fr",
        "inflexion": "drug, drugs",
        "model": "lang-en",
        "inflexions": [
            "drug",
            "drugs"
        ],
        "id": "WgnB6WpIvgRB6WgVM9fQ"
    },
    {
        "inflexion": "future, futures",
        "wt": "n",
        "lang": "en-fr",
        "term": "future",
        "inflexions": [
            "future",
            "futures"
        ],
        "infexions": [
            "future",
            "futures"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 776,
        "isPareto": true,
        "id": "wOKYUrWCsixPqu9WIM1D"
    },
    {
        "inflexions": [
            "prove",
            "proves",
            "proving",
            "proved",
            "proven"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "prove",
            "proves",
            "proving",
            "proved",
            "proven"
        ],
        "model": "lang-en",
        "wt": "v",
        "rank": 777,
        "lang": "en-fr",
        "inflexion": "prove, proves, proving, proved, proven",
        "term": "prove",
        "isPareto": true,
        "id": "yGWMxjvwYNtO1Rua8PuY"
    },
    {
        "isPareto": true,
        "infexions": [
            "surprise",
            "surprises"
        ],
        "term": "surprise",
        "rank": 778,
        "inflexions": [
            "surprise",
            "surprises"
        ],
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "surprise, surprises",
        "lang": "en-fr",
        "id": "qpDEX4W0kiu0hvm0HmSH"
    },
    {
        "wt": "r",
        "inflexion": "alright",
        "inflexions": [
            "alright"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "alright"
        ],
        "isPareto": true,
        "term": "alright",
        "rank": 779,
        "lang": "en-fr",
        "id": "Am74KAGLOEGwPWexI7Cp"
    },
    {
        "isPareto": true,
        "infexions": [
            "captain",
            "captains"
        ],
        "wt": "n",
        "inflexions": [
            "captain",
            "captains"
        ],
        "term": "captain",
        "lang": "en-fr",
        "rank": 780,
        "inflexion": "captain, captains",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "jc4zOFLZBGZG5MkqNyVu"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "term": "evening",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 781,
        "inflexion": "evening, evenings",
        "wt": "n",
        "inflexions": [
            "evening",
            "evenings"
        ],
        "infexions": [
            "evening",
            "evenings"
        ],
        "id": "shJ0Sw0FkCSyTcUMATf8"
    },
    {
        "inflexion": "congratulations",
        "inflexions": [
            "congratulations"
        ],
        "rank": 782,
        "isPareto": true,
        "lang": "en-fr",
        "wt": "u",
        "workspace_id": "anglais",
        "term": "congratulations",
        "model": "lang-en",
        "infexions": [
            "congratulations"
        ],
        "id": "EYDv5rmYbvSrcEOc4BQo"
    },
    {
        "term": "system",
        "inflexion": "system, systems",
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 783,
        "isPareto": true,
        "wt": "n",
        "inflexions": [
            "system",
            "systems"
        ],
        "lang": "en-fr",
        "infexions": [
            "system",
            "systems"
        ],
        "id": "h560wHm7rr626witUyot"
    },
    {
        "model": "lang-en",
        "rank": 784,
        "inflexion": "record, records",
        "isPareto": true,
        "infexions": [
            "record",
            "records"
        ],
        "wt": "n",
        "inflexions": [
            "record",
            "records"
        ],
        "workspace_id": "anglais",
        "term": "record",
        "lang": "en-fr",
        "id": "sUIsgmbF00ByWFTl5mHO"
    },
    {
        "isPareto": true,
        "rank": 785,
        "infexions": [
            "age",
            "ages"
        ],
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "age, ages",
        "term": "age",
        "inflexions": [
            "age",
            "ages"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "nf7EzveWE0HYKFohHWsk"
    },
    {
        "term": "deserve",
        "inflexions": [
            "deserve",
            "deserves",
            "deserving",
            "deserved"
        ],
        "workspace_id": "anglais",
        "inflexion": "deserve, deserves, deserving, deserved",
        "infexions": [
            "deserve",
            "deserves",
            "deserving",
            "deserved"
        ],
        "isPareto": true,
        "rank": 786,
        "wt": "v",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "eJxyRctsniy1RlocEdQ6"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "rank": 787,
        "wt": "j",
        "infexions": [
            "normal"
        ],
        "inflexion": "normal",
        "inflexions": [
            "normal"
        ],
        "workspace_id": "anglais",
        "term": "normal",
        "id": "N64bTf2t4bVmGk9atoHZ"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "yesterday, yesterdays",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 788,
        "infexions": [
            "yesterday",
            "yesterdays"
        ],
        "inflexions": [
            "yesterday",
            "yesterdays"
        ],
        "term": "yesterday",
        "id": "XdYnvVcoaRBSFBIqXIII"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "term": "jerk",
        "inflexions": [
            "jerk",
            "jerks"
        ],
        "rank": 789,
        "infexions": [
            "jerk",
            "jerks"
        ],
        "inflexion": "jerk, jerks",
        "id": "tALh3Vt4FDcUC6CoStIc"
    },
    {
        "wt": "n",
        "isPareto": true,
        "rank": 790,
        "workspace_id": "anglais",
        "term": "bird",
        "inflexions": [
            "bird",
            "birds"
        ],
        "lang": "en-fr",
        "infexions": [
            "bird",
            "birds"
        ],
        "model": "lang-en",
        "inflexion": "bird, birds",
        "id": "bUBD1ItAENEeBmhmVRmE"
    },
    {
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "infexions": [
            "nose",
            "noses"
        ],
        "term": "nose",
        "inflexion": "nose, noses",
        "inflexions": [
            "nose",
            "noses"
        ],
        "rank": 791,
        "model": "lang-en",
        "lang": "en-fr",
        "id": "j93XNYEoqrVSYectwZjD"
    },
    {
        "term": "bother",
        "rank": 792,
        "inflexions": [
            "bother",
            "bothers",
            "bothering",
            "bothered"
        ],
        "model": "lang-en",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexion": "bother, bothers, bothering, bothered",
        "infexions": [
            "bother",
            "bothers",
            "bothering",
            "bothered"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "id": "Q9qGqvJxr4Q1ZYBADPKt"
    },
    {
        "infexions": [
            "fan",
            "fans"
        ],
        "term": "fan",
        "isPareto": true,
        "inflexion": "fan, fans",
        "rank": 793,
        "inflexions": [
            "fan",
            "fans"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "lang": "en-fr",
        "id": "EbFQsQMzHHHBbLBl6IIO"
    },
    {
        "isPareto": true,
        "inflexion": "letter, letters",
        "infexions": [
            "letter",
            "letters"
        ],
        "inflexions": [
            "letter",
            "letters"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "rank": 794,
        "term": "letter",
        "id": "Vn3DbUGfB5avwVDS3q57"
    },
    {
        "inflexions": [
            "rather"
        ],
        "rank": 795,
        "model": "lang-en",
        "inflexion": "rather",
        "lang": "en-fr",
        "wt": "r",
        "infexions": [
            "rather"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "rather",
        "id": "5XOrVeKRjRyiPGPBgtqS"
    },
    {
        "inflexion": "head, heads, heading, headed",
        "lang": "en-fr",
        "rank": 796,
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "head",
            "heads",
            "heading",
            "headed"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "head",
        "infexions": [
            "head",
            "heads",
            "heading",
            "headed"
        ],
        "id": "CsGRkSqOXdc5n248ZykQ"
    },
    {
        "infexions": [
            "interested"
        ],
        "model": "lang-en",
        "rank": 797,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "interested",
        "inflexion": "interested",
        "inflexions": [
            "interested"
        ],
        "wt": "j",
        "isPareto": true,
        "id": "CR2oIHtza9cKwqvwGUM3"
    },
    {
        "model": "lang-en",
        "term": "owe",
        "inflexions": [
            "owe",
            "owes",
            "owing",
            "owed"
        ],
        "inflexion": "owe, owes, owing, owed",
        "rank": 798,
        "lang": "en-fr",
        "wt": "v",
        "infexions": [
            "owe",
            "owes",
            "owing",
            "owed"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "aN4Sxn6PTsj2PNkG43L2"
    },
    {
        "infexions": [
            "pregnant"
        ],
        "inflexion": "pregnant",
        "isPareto": true,
        "inflexions": [
            "pregnant"
        ],
        "workspace_id": "anglais",
        "rank": 799,
        "wt": "j",
        "term": "pregnant",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "i7e3El1EItevr6FIkyOs"
    },
    {
        "isPareto": true,
        "rank": 800,
        "lang": "en-fr",
        "infexions": [
            "destroy",
            "destroys",
            "destroying",
            "destroyed"
        ],
        "inflexions": [
            "destroy",
            "destroys",
            "destroying",
            "destroyed"
        ],
        "wt": "v",
        "inflexion": "destroy, destroys, destroying, destroyed",
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "destroy",
        "id": "LDOhuIqka0nZ0LuzNj7F"
    },
    {
        "isPareto": true,
        "wt": "n",
        "rank": 801,
        "term": "bear",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "bear",
            "bears"
        ],
        "lang": "en-fr",
        "inflexions": [
            "bear",
            "bears"
        ],
        "inflexion": "bear, bears",
        "id": "PyHjeMSuAuvMY5w5wdzD"
    },
    {
        "inflexions": [
            "evil",
            "eviler",
            "eviller",
            "evilest",
            "evillest"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "evil, eviler, eviller, evilest, evillest",
        "lang": "en-fr",
        "rank": 802,
        "infexions": [
            "evil",
            "eviler",
            "eviller",
            "evilest",
            "evillest"
        ],
        "wt": "j",
        "term": "evil",
        "model": "lang-en",
        "id": "m1f9JbZrNFNKmmB52tx8"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "milk, milks",
        "term": "milk",
        "infexions": [
            "milk",
            "milks"
        ],
        "rank": 803,
        "model": "lang-en",
        "inflexions": [
            "milk",
            "milks"
        ],
        "id": "5lmiDmhyrsjddwt18hbG"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 804,
        "infexions": [
            "sport",
            "sports"
        ],
        "inflexion": "sport, sports",
        "inflexions": [
            "sport",
            "sports"
        ],
        "term": "sport",
        "isPareto": true,
        "id": "JAnH8p3wAA05Y0TQbzuY"
    },
    {
        "workspace_id": "anglais",
        "wt": "v",
        "inflexions": [
            "admit",
            "admits",
            "admitting",
            "admitted"
        ],
        "term": "admit",
        "infexions": [
            "admit",
            "admits",
            "admitting",
            "admitted"
        ],
        "isPareto": true,
        "inflexion": "admit, admits, admitting, admitted",
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 805,
        "id": "t7QptV8dYqqfD4kgZIg4"
    },
    {
        "rank": 806,
        "term": "apparently",
        "isPareto": true,
        "wt": "r",
        "inflexion": "apparently",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "apparently"
        ],
        "model": "lang-en",
        "infexions": [
            "apparently"
        ],
        "id": "jZqZytWLldRp73VwBevl"
    },
    {
        "isPareto": true,
        "inflexion": "conversation, conversations",
        "lang": "en-fr",
        "infexions": [
            "conversation",
            "conversations"
        ],
        "rank": 807,
        "model": "lang-en",
        "wt": "n",
        "workspace_id": "anglais",
        "term": "conversation",
        "inflexions": [
            "conversation",
            "conversations"
        ],
        "id": "Bf7JzjaVcQVEJiVJk6o8"
    },
    {
        "term": "obviously",
        "infexions": [
            "obviously"
        ],
        "workspace_id": "anglais",
        "inflexion": "obviously",
        "inflexions": [
            "obviously"
        ],
        "isPareto": true,
        "rank": 808,
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "r",
        "id": "CNZDJj9fseKvBruVFJoG"
    },
    {
        "rank": 809,
        "infexions": [
            "jacket",
            "jackets"
        ],
        "isPareto": true,
        "term": "jacket",
        "wt": "n",
        "model": "lang-en",
        "inflexion": "jacket, jackets",
        "inflexions": [
            "jacket",
            "jackets"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "GdrfVP4d9zafAGZmGZpY"
    },
    {
        "isPareto": true,
        "rank": 810,
        "workspace_id": "anglais",
        "term": "during",
        "inflexion": "during",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "during"
        ],
        "wt": "fw",
        "infexions": [
            "during"
        ],
        "id": "nHgHytjs21GAQxwSoAku"
    },
    {
        "workspace_id": "anglais",
        "wt": "v",
        "rank": 811,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "accept, accepts, accepting, accepted",
        "inflexions": [
            "accept",
            "accepts",
            "accepting",
            "accepted"
        ],
        "term": "accept",
        "infexions": [
            "accept",
            "accepts",
            "accepting",
            "accepted"
        ],
        "isPareto": true,
        "id": "4O6bmzDlfs3dQQg6jlrY"
    },
    {
        "inflexions": [
            "dumb",
            "dumber",
            "dumbest"
        ],
        "infexions": [
            "dumb",
            "dumber",
            "dumbest"
        ],
        "wt": "j",
        "term": "dumb",
        "lang": "en-fr",
        "inflexion": "dumb, dumber, dumbest",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 812,
        "isPareto": true,
        "id": "b8Cv3VTXAmpedg7kbAC5"
    },
    {
        "inflexion": "bit, bits",
        "wt": "n",
        "infexions": [
            "bit",
            "bits"
        ],
        "lang": "en-fr",
        "rank": 813,
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "bit",
        "inflexions": [
            "bit",
            "bits"
        ],
        "id": "fj4woiNDeEDsGQutW4zC"
    },
    {
        "inflexion": "consider, considers, considering, considered",
        "lang": "en-fr",
        "infexions": [
            "consider",
            "considers",
            "considering",
            "considered"
        ],
        "term": "consider",
        "inflexions": [
            "consider",
            "considers",
            "considering",
            "considered"
        ],
        "model": "lang-en",
        "isPareto": true,
        "rank": 814,
        "workspace_id": "anglais",
        "wt": "v",
        "id": "JaitMbF0lVu5O5Y0vgS8"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "mention",
            "mentions",
            "mentioning",
            "mentioned"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "term": "mention",
        "infexions": [
            "mention",
            "mentions",
            "mentioning",
            "mentioned"
        ],
        "rank": 815,
        "inflexion": "mention, mentions, mentioning, mentioned",
        "id": "vpZ1uxqnDwlOOLpVJoMV"
    },
    {
        "lang": "en-fr",
        "term": "step",
        "wt": "v",
        "infexions": [
            "step",
            "steps",
            "stepping",
            "stepped"
        ],
        "model": "lang-en",
        "inflexions": [
            "step",
            "steps",
            "stepping",
            "stepped"
        ],
        "inflexion": "step, steps, stepping, stepped",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 816,
        "id": "iYfNdSsSAKgTP1LGOyeq"
    },
    {
        "inflexion": "deal, deals, dealing, dealt",
        "lang": "en-fr",
        "term": "deal",
        "wt": "v",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "deal",
            "deals",
            "dealing",
            "dealt"
        ],
        "rank": 817,
        "model": "lang-en",
        "inflexions": [
            "deal",
            "deals",
            "dealing",
            "dealt"
        ],
        "id": "ORYVXWSD5cRXrHYLzsob"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "term": "hungry",
        "infexions": [
            "hungry",
            "hungrier",
            "hungriest"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 818,
        "inflexions": [
            "hungry",
            "hungrier",
            "hungriest"
        ],
        "inflexion": "hungry, hungrier, hungriest",
        "id": "kQ1A7Dozaq9PtCpE7KAD"
    },
    {
        "infexions": [
            "situation",
            "situations"
        ],
        "inflexion": "situation, situations",
        "inflexions": [
            "situation",
            "situations"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 819,
        "term": "situation",
        "wt": "n",
        "model": "lang-en",
        "id": "9nz5tAxaKWZQe4WaCPHT"
    },
    {
        "rank": 820,
        "infexions": [
            "lead",
            "leads",
            "leading",
            "leaded",
            "led"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "lead, leads, leading, leaded, led",
        "inflexions": [
            "lead",
            "leads",
            "leading",
            "leaded",
            "led"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "term": "lead",
        "id": "16IFj12dhmq5Jo76aJxc"
    },
    {
        "term": "twelve",
        "isPareto": true,
        "inflexions": [
            "twelve",
            "twelves"
        ],
        "rank": 821,
        "infexions": [
            "twelve",
            "twelves"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "twelve, twelves",
        "wt": "m",
        "id": "kstCbeH639aoFyigrlU8"
    },
    {
        "wt": "j",
        "rank": 822,
        "inflexions": [
            "fantastic"
        ],
        "workspace_id": "anglais",
        "term": "fantastic",
        "inflexion": "fantastic",
        "lang": "en-fr",
        "infexions": [
            "fantastic"
        ],
        "isPareto": true,
        "model": "lang-en",
        "id": "yd0cfFuBPzrsqEOXUp1Z"
    },
    {
        "model": "lang-en",
        "inflexion": "hole, holes",
        "term": "hole",
        "isPareto": true,
        "rank": 823,
        "workspace_id": "anglais",
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "hole",
            "holes"
        ],
        "infexions": [
            "hole",
            "holes"
        ],
        "id": "DvWnISvpDmM3J9Doi9CM"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "plus"
        ],
        "term": "plus",
        "infexions": [
            "plus"
        ],
        "workspace_id": "anglais",
        "wt": "fw",
        "rank": 824,
        "inflexion": "plus",
        "id": "Z0vOEw7hHEe7dnjWz9Aj"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "note",
            "notes"
        ],
        "inflexion": "note, notes",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 825,
        "term": "note",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "note",
            "notes"
        ],
        "id": "l4nYWlDsDZ8cpuUg3Jv6"
    },
    {
        "rank": 826,
        "term": "test",
        "infexions": [
            "test",
            "tests"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "test, tests",
        "inflexions": [
            "test",
            "tests"
        ],
        "id": "iWFke3NxCy88rHSCOWUH"
    },
    {
        "inflexion": "honest, honester, honestest",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 827,
        "inflexions": [
            "honest",
            "honester",
            "honestest"
        ],
        "infexions": [
            "honest",
            "honester",
            "honestest"
        ],
        "isPareto": true,
        "wt": "j",
        "term": "honest",
        "lang": "en-fr",
        "id": "rOhtZqjYDfGHPqGaVpQT"
    },
    {
        "lang": "en-fr",
        "inflexion": "character, characters",
        "term": "character",
        "model": "lang-en",
        "inflexions": [
            "character",
            "characters"
        ],
        "infexions": [
            "character",
            "characters"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 828,
        "wt": "n",
        "id": "gSjHFw2nfZUq4mLekwBB"
    },
    {
        "wt": "j",
        "rank": 829,
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "holy, holier, holiest",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "holy",
            "holier",
            "holiest"
        ],
        "term": "holy",
        "infexions": [
            "holy",
            "holier",
            "holiest"
        ],
        "id": "vSjxo3P1BI5k7w19O5gL"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "term": "manager",
        "lang": "en-fr",
        "infexions": [
            "manager",
            "managers"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "manager",
            "managers"
        ],
        "rank": 830,
        "isPareto": true,
        "inflexion": "manager, managers",
        "id": "SYcml5trOYB3gWQUI45E"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "lesson",
            "lessons"
        ],
        "inflexion": "lesson, lessons",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "lesson",
            "lessons"
        ],
        "isPareto": true,
        "wt": "n",
        "term": "lesson",
        "rank": 831,
        "id": "DlmrC17JrCavDqAkotvQ"
    },
    {
        "inflexion": "soul, souls",
        "wt": "n",
        "rank": 832,
        "infexions": [
            "soul",
            "souls"
        ],
        "lang": "en-fr",
        "inflexions": [
            "soul",
            "souls"
        ],
        "model": "lang-en",
        "term": "soul",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "LXYLjPwAzsGVUfsjdEH1"
    },
    {
        "inflexions": [
            "nope"
        ],
        "term": "nope",
        "infexions": [
            "nope"
        ],
        "inflexion": "nope",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "r",
        "rank": 833,
        "isPareto": true,
        "model": "lang-en",
        "id": "4WnCSi6Ijr467pwxrHzL"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "dark",
            "darker",
            "darkest"
        ],
        "inflexion": "dark, darker, darkest",
        "isPareto": true,
        "term": "dark",
        "inflexions": [
            "dark",
            "darker",
            "darkest"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "rank": 834,
        "id": "d0wAKm537PvGBn7CKzW9"
    },
    {
        "infexions": [
            "difference",
            "differences"
        ],
        "rank": 835,
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "difference",
            "differences"
        ],
        "inflexion": "difference, differences",
        "model": "lang-en",
        "term": "difference",
        "id": "1XAuqeJwLnfN375HK7qL"
    },
    {
        "wt": "n",
        "inflexions": [
            "wine",
            "wines"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 836,
        "term": "wine",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "wine, wines",
        "infexions": [
            "wine",
            "wines"
        ],
        "id": "bGK3ieFC4eqOdY3KDjQV"
    },
    {
        "rank": 837,
        "inflexions": [
            "road",
            "roads"
        ],
        "wt": "n",
        "isPareto": true,
        "inflexion": "road, roads",
        "term": "road",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "road",
            "roads"
        ],
        "workspace_id": "anglais",
        "id": "UBxEHqa2bHzw4RceJtW2"
    },
    {
        "rank": 838,
        "lang": "en-fr",
        "wt": "v",
        "infexions": [
            "calm",
            "calms",
            "calming",
            "calmed"
        ],
        "isPareto": true,
        "term": "calm",
        "inflexions": [
            "calm",
            "calms",
            "calming",
            "calmed"
        ],
        "model": "lang-en",
        "inflexion": "calm, calms, calming, calmed",
        "workspace_id": "anglais",
        "id": "7L6jsbjEejakmmIohzoL"
    },
    {
        "inflexions": [
            "personal"
        ],
        "inflexion": "personal",
        "infexions": [
            "personal"
        ],
        "workspace_id": "anglais",
        "wt": "j",
        "rank": 839,
        "term": "personal",
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "id": "0JWJs4saHfGP21NKXVIh"
    },
    {
        "inflexions": [
            "clown",
            "clowns"
        ],
        "workspace_id": "anglais",
        "inflexion": "clown, clowns",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "rank": 840,
        "infexions": [
            "clown",
            "clowns"
        ],
        "term": "clown",
        "id": "rvc9eHPavGFSbuJbMi8Z"
    },
    {
        "workspace_id": "anglais",
        "term": "computer",
        "wt": "n",
        "inflexions": [
            "computer",
            "computers"
        ],
        "inflexion": "computer, computers",
        "infexions": [
            "computer",
            "computers"
        ],
        "isPareto": true,
        "model": "lang-en",
        "rank": 841,
        "lang": "en-fr",
        "id": "E379E3AH7RwLD3W2UrEY"
    },
    {
        "lang": "en-fr",
        "rank": 842,
        "model": "lang-en",
        "wt": "n",
        "infexions": [
            "horse",
            "horses"
        ],
        "inflexions": [
            "horse",
            "horses"
        ],
        "term": "horse",
        "workspace_id": "anglais",
        "inflexion": "horse, horses",
        "isPareto": true,
        "id": "PFI7gPp1Ou3HHN5CO24c"
    },
    {
        "isPareto": true,
        "infexions": [
            "gosh"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "gosh",
        "wt": "u",
        "rank": 843,
        "inflexion": "gosh",
        "inflexions": [
            "gosh"
        ],
        "model": "lang-en",
        "id": "UBdDuQuXqe1mON5BU99U"
    },
    {
        "term": "mayor",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "mayor",
            "mayors"
        ],
        "inflexions": [
            "mayor",
            "mayors"
        ],
        "inflexion": "mayor, mayors",
        "isPareto": true,
        "wt": "n",
        "rank": 844,
        "lang": "en-fr",
        "id": "B3simaO82q4ToMYpeAVJ"
    },
    {
        "inflexions": [
            "inside"
        ],
        "term": "inside",
        "workspace_id": "anglais",
        "wt": "r",
        "inflexion": "inside",
        "isPareto": true,
        "rank": 845,
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "inside"
        ],
        "id": "klJCLN3WuX6dr1ExU3pU"
    },
    {
        "term": "sexy",
        "infexions": [
            "sexy",
            "sexier",
            "sexiest"
        ],
        "rank": 846,
        "isPareto": true,
        "inflexions": [
            "sexy",
            "sexier",
            "sexiest"
        ],
        "wt": "j",
        "inflexion": "sexy, sexier, sexiest",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "0GkgEItRT2mGnxdb12gb"
    },
    {
        "infexions": [
            "dirty",
            "dirtier",
            "dirtiest"
        ],
        "rank": 847,
        "workspace_id": "anglais",
        "inflexions": [
            "dirty",
            "dirtier",
            "dirtiest"
        ],
        "model": "lang-en",
        "inflexion": "dirty, dirtier, dirtiest",
        "lang": "en-fr",
        "wt": "j",
        "term": "dirty",
        "isPareto": true,
        "id": "W6gJAqOzfbKOUhRdIMP4"
    },
    {
        "rank": 848,
        "isPareto": true,
        "inflexions": [
            "carry",
            "carries",
            "carrying",
            "carried"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "carry",
            "carries",
            "carrying",
            "carried"
        ],
        "inflexion": "carry, carries, carrying, carried",
        "wt": "v",
        "term": "carry",
        "model": "lang-en",
        "id": "ITDhJ1Ts0BF7iBTOfZJ7"
    },
    {
        "rank": 849,
        "lang": "en-fr",
        "infexions": [
            "professor",
            "professors"
        ],
        "inflexion": "professor, professors",
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "term": "professor",
        "inflexions": [
            "professor",
            "professors"
        ],
        "workspace_id": "anglais",
        "id": "1J6Zx2xyFQA72kt6TwZx"
    },
    {
        "wt": "r",
        "infexions": [
            "usually"
        ],
        "term": "usually",
        "inflexion": "usually",
        "rank": 850,
        "inflexions": [
            "usually"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "tJXlHw99x5oHvlXzcyTW"
    },
    {
        "inflexions": [
            "bottle",
            "bottles"
        ],
        "term": "bottle",
        "inflexion": "bottle, bottles",
        "rank": 851,
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "bottle",
            "bottles"
        ],
        "isPareto": true,
        "id": "uY1dfOwMnCYzTWltmgpD"
    },
    {
        "inflexions": [
            "cheese",
            "cheeses"
        ],
        "inflexion": "cheese, cheeses",
        "wt": "n",
        "term": "cheese",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "cheese",
            "cheeses"
        ],
        "rank": 852,
        "model": "lang-en",
        "id": "zb7i2pQ72QigITxOFxY2"
    },
    {
        "rank": 853,
        "term": "summer",
        "inflexion": "summer, summers",
        "wt": "n",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "summer",
            "summers"
        ],
        "infexions": [
            "summer",
            "summers"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "v9Bo5rzZl7Peh8Nhpd3l"
    },
    {
        "infexions": [
            "remind",
            "reminds",
            "reminding",
            "reminded"
        ],
        "inflexion": "remind, reminds, reminding, reminded",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 854,
        "isPareto": true,
        "inflexions": [
            "remind",
            "reminds",
            "reminding",
            "reminded"
        ],
        "term": "remind",
        "wt": "v",
        "lang": "en-fr",
        "id": "Wy9U6TBlQfcNjMpWkv8K"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "afternoon",
            "afternoons"
        ],
        "inflexion": "afternoon, afternoons",
        "rank": 855,
        "term": "afternoon",
        "inflexions": [
            "afternoon",
            "afternoons"
        ],
        "isPareto": true,
        "id": "emQ5OxCutOCRAihukjd0"
    },
    {
        "isPareto": true,
        "infexions": [
            "pee",
            "pees",
            "peeing",
            "peed"
        ],
        "model": "lang-en",
        "term": "pee",
        "rank": 856,
        "workspace_id": "anglais",
        "inflexions": [
            "pee",
            "pees",
            "peeing",
            "peed"
        ],
        "lang": "en-fr",
        "wt": "v",
        "inflexion": "pee, pees, peeing, peed",
        "id": "maaGvuOtreDCLlRGzZHu"
    },
    {
        "term": "across",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "across"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "across"
        ],
        "rank": 857,
        "wt": "fw",
        "inflexion": "across",
        "id": "W9AceXRCbJCUXh53pAAp"
    },
    {
        "inflexions": [
            "angry",
            "angrier",
            "angriest"
        ],
        "model": "lang-en",
        "term": "angry",
        "inflexion": "angry, angrier, angriest",
        "workspace_id": "anglais",
        "wt": "j",
        "infexions": [
            "angry",
            "angrier",
            "angriest"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 858,
        "id": "E8RJrt10X8vQx0RX1APr"
    },
    {
        "inflexions": [
            "fella",
            "fellas"
        ],
        "infexions": [
            "fella",
            "fellas"
        ],
        "term": "fella",
        "rank": 859,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "fella, fellas",
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "id": "O30Hdb55tEex8Hm4x5hM"
    },
    {
        "inflexion": "film, films",
        "inflexions": [
            "film",
            "films"
        ],
        "rank": 860,
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "term": "film",
        "infexions": [
            "film",
            "films"
        ],
        "id": "pGtOiCg3g6ZWQmkwFV5k"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "reach",
            "reaches",
            "reaching",
            "reached"
        ],
        "term": "reach",
        "infexions": [
            "reach",
            "reaches",
            "reaching",
            "reached"
        ],
        "isPareto": true,
        "wt": "v",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "reach, reaches, reaching, reached",
        "rank": 861,
        "id": "6BPW9okfp476kzMZwqMv"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "infexions": [
            "shower",
            "showers"
        ],
        "rank": 862,
        "inflexion": "shower, showers",
        "isPareto": true,
        "inflexions": [
            "shower",
            "showers"
        ],
        "model": "lang-en",
        "wt": "n",
        "term": "shower",
        "id": "KcjhEKoStDm1PprZoALr"
    },
    {
        "isPareto": true,
        "wt": "n",
        "model": "lang-en",
        "inflexions": [
            "cup",
            "cups"
        ],
        "infexions": [
            "cup",
            "cups"
        ],
        "term": "cup",
        "inflexion": "cup, cups",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 863,
        "id": "TWIYtgYt83ZO3UNjM4fY"
    },
    {
        "isPareto": true,
        "rank": 864,
        "workspace_id": "anglais",
        "infexions": [
            "lawyer",
            "lawyers"
        ],
        "lang": "en-fr",
        "inflexion": "lawyer, lawyers",
        "inflexions": [
            "lawyer",
            "lawyers"
        ],
        "term": "lawyer",
        "model": "lang-en",
        "wt": "n",
        "id": "nMDYBioYUrOAXZe7UYky"
    },
    {
        "workspace_id": "anglais",
        "term": "camera",
        "wt": "n",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "camera",
            "cameras"
        ],
        "model": "lang-en",
        "infexions": [
            "camera",
            "cameras"
        ],
        "rank": 865,
        "inflexion": "camera, cameras",
        "id": "FYRrIcuai656zlxRD579"
    },
    {
        "term": "pain",
        "inflexions": [
            "pain",
            "pains"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "infexions": [
            "pain",
            "pains"
        ],
        "isPareto": true,
        "rank": 866,
        "inflexion": "pain, pains",
        "lang": "en-fr",
        "id": "ADH171rtRHvZvnIlgUZZ"
    },
    {
        "wt": "v",
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "hurry",
        "rank": 867,
        "model": "lang-en",
        "inflexion": "hurry, hurries, hurrying, hurried",
        "infexions": [
            "hurry",
            "hurries",
            "hurrying",
            "hurried"
        ],
        "inflexions": [
            "hurry",
            "hurries",
            "hurrying",
            "hurried"
        ],
        "id": "bcPrrfZOZrjvnuHhAbEU"
    },
    {
        "inflexion": "desk, desks",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "desk",
            "desks"
        ],
        "term": "desk",
        "inflexions": [
            "desk",
            "desks"
        ],
        "rank": 868,
        "wt": "n",
        "lang": "en-fr",
        "id": "29DTX61d3peOKogBsjld"
    },
    {
        "infexions": [
            "monster",
            "monsters"
        ],
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "monster, monsters",
        "term": "monster",
        "isPareto": true,
        "rank": 869,
        "model": "lang-en",
        "inflexions": [
            "monster",
            "monsters"
        ],
        "workspace_id": "anglais",
        "id": "kLjZTKxvdaa7va6ACQ0u"
    },
    {
        "wt": "r",
        "infexions": [
            "certainly"
        ],
        "inflexion": "certainly",
        "model": "lang-en",
        "isPareto": true,
        "rank": 870,
        "term": "certainly",
        "lang": "en-fr",
        "inflexions": [
            "certainly"
        ],
        "workspace_id": "anglais",
        "id": "kYZNMx4OVSC6XplJWcI0"
    },
    {
        "infexions": [
            "cop",
            "cops"
        ],
        "rank": 871,
        "wt": "n",
        "inflexions": [
            "cop",
            "cops"
        ],
        "term": "cop",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "cop, cops",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "KiuDyd4t8gsxGmIUmeW9"
    },
    {
        "inflexion": "credit, credits",
        "isPareto": true,
        "term": "credit",
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "credit",
            "credits"
        ],
        "lang": "en-fr",
        "rank": 872,
        "inflexions": [
            "credit",
            "credits"
        ],
        "model": "lang-en",
        "id": "NwhP0dQAIbaPhaA8MJPA"
    },
    {
        "wt": "v",
        "inflexion": "prepare, prepares, preparing, prepared",
        "inflexions": [
            "prepare",
            "prepares",
            "preparing",
            "prepared"
        ],
        "lang": "en-fr",
        "term": "prepare",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 873,
        "infexions": [
            "prepare",
            "prepares",
            "preparing",
            "prepared"
        ],
        "id": "A3OVy5aPdSGSVeXdShi8"
    },
    {
        "model": "lang-en",
        "wt": "r",
        "rank": 874,
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "anywhere"
        ],
        "term": "anywhere",
        "lang": "en-fr",
        "inflexions": [
            "anywhere"
        ],
        "inflexion": "anywhere",
        "id": "aiZXG8MXEnquwnfG3XM9"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 875,
        "model": "lang-en",
        "inflexion": "breakfast, breakfasts",
        "inflexions": [
            "breakfast",
            "breakfasts"
        ],
        "term": "breakfast",
        "infexions": [
            "breakfast",
            "breakfasts"
        ],
        "isPareto": true,
        "id": "AmNgXCoRwah4ssrnOAGh"
    },
    {
        "infexions": [
            "bastard",
            "bastards"
        ],
        "inflexions": [
            "bastard",
            "bastards"
        ],
        "term": "bastard",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "inflexion": "bastard, bastards",
        "isPareto": true,
        "rank": 876,
        "lang": "en-fr",
        "id": "6mSNOXdM4nnm7i5TbVxp"
    },
    {
        "term": "damn",
        "wt": "j",
        "model": "lang-en",
        "infexions": [
            "damn"
        ],
        "rank": 877,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "damn"
        ],
        "inflexion": "damn",
        "lang": "en-fr",
        "id": "4Jf7PTsW3xoE0Eo60Luq"
    },
    {
        "inflexions": [
            "church",
            "churches"
        ],
        "workspace_id": "anglais",
        "term": "church",
        "isPareto": true,
        "wt": "n",
        "inflexion": "church, churches",
        "rank": 878,
        "lang": "en-fr",
        "infexions": [
            "church",
            "churches"
        ],
        "model": "lang-en",
        "id": "WxFOBJeeIfv8IXaCW2bN"
    },
    {
        "isPareto": true,
        "term": "hire",
        "rank": 879,
        "inflexions": [
            "hire",
            "hires",
            "hiring",
            "hired"
        ],
        "lang": "en-fr",
        "wt": "v",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "hire",
            "hires",
            "hiring",
            "hired"
        ],
        "inflexion": "hire, hires, hiring, hired",
        "id": "KMjwsI5mQLxsfz43zCL7"
    },
    {
        "infexions": [
            "decision",
            "decisions"
        ],
        "lang": "en-fr",
        "term": "decision",
        "inflexions": [
            "decision",
            "decisions"
        ],
        "inflexion": "decision, decisions",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 880,
        "id": "zUKFgHPNuc9zK7A7U3j3"
    },
    {
        "inflexions": [
            "pig",
            "pigs"
        ],
        "model": "lang-en",
        "rank": 881,
        "term": "pig",
        "inflexion": "pig, pigs",
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "pig",
            "pigs"
        ],
        "workspace_id": "anglais",
        "id": "2YUYTFmiX8GrZjjGxm4J"
    },
    {
        "wt": "n",
        "infexions": [
            "loser",
            "losers"
        ],
        "inflexion": "loser, losers",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "loser",
            "losers"
        ],
        "rank": 882,
        "term": "loser",
        "id": "OHLJVSs2VOy04XOzMhLi"
    },
    {
        "term": "robot",
        "model": "lang-en",
        "infexions": [
            "robot",
            "robots"
        ],
        "inflexion": "robot, robots",
        "rank": 883,
        "wt": "n",
        "inflexions": [
            "robot",
            "robots"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "PLWIj9dpGMajoBVQk55s"
    },
    {
        "infexions": [
            "deep",
            "deeper",
            "deepest"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "deep, deeper, deepest",
        "inflexions": [
            "deep",
            "deeper",
            "deepest"
        ],
        "term": "deep",
        "wt": "j",
        "model": "lang-en",
        "rank": 884,
        "lang": "en-fr",
        "id": "PDGvnzh6qqHUlYGe5GvG"
    },
    {
        "rank": 885,
        "term": "park",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "park",
            "parks"
        ],
        "workspace_id": "anglais",
        "inflexion": "park, parks",
        "inflexions": [
            "park",
            "parks"
        ],
        "id": "gewXZswf4PEyv2pvYRAe"
    },
    {
        "infexions": [
            "chocolate",
            "chocolates"
        ],
        "isPareto": true,
        "term": "chocolate",
        "inflexion": "chocolate, chocolates",
        "rank": 886,
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "chocolate",
            "chocolates"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "id": "UsSwyeXbfSH72KShW60n"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "service",
            "services"
        ],
        "inflexion": "service, services",
        "inflexions": [
            "service",
            "services"
        ],
        "rank": 887,
        "term": "service",
        "id": "EeAF3doEuvaF19ad1btF"
    },
    {
        "infexions": [
            "Jew"
        ],
        "inflexion": "Jew",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "Jew"
        ],
        "wt": "K",
        "isPareto": true,
        "rank": 888,
        "term": "Jew",
        "model": "lang-en",
        "id": "6Af6ZSlMaskzLktoJUBA"
    },
    {
        "isPareto": true,
        "rank": 889,
        "infexions": [
            "tape",
            "tapes"
        ],
        "lang": "en-fr",
        "inflexions": [
            "tape",
            "tapes"
        ],
        "model": "lang-en",
        "wt": "n",
        "inflexion": "tape, tapes",
        "term": "tape",
        "workspace_id": "anglais",
        "id": "oxDqX9wttWYrPlUqquLU"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "term": "kitchen",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "kitchen",
            "kitchens"
        ],
        "inflexion": "kitchen, kitchens",
        "inflexions": [
            "kitchen",
            "kitchens"
        ],
        "rank": 890,
        "id": "tKnRWCUstnzkTEYaYl44"
    },
    {
        "model": "lang-en",
        "rank": 891,
        "isPareto": true,
        "inflexion": "half, halves",
        "term": "half",
        "inflexions": [
            "half",
            "halves"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "half",
            "halves"
        ],
        "wt": "m",
        "lang": "en-fr",
        "id": "GuNtNsuWc24i947s3cZR"
    },
    {
        "isPareto": true,
        "wt": "v",
        "inflexion": "rid, rids, riddings, ridded",
        "workspace_id": "anglais",
        "rank": 892,
        "term": "rid",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "rid",
            "rids",
            "riddings",
            "ridded"
        ],
        "inflexions": [
            "rid",
            "rids",
            "riddings",
            "ridded"
        ],
        "id": "99pcGWHKFHTElyTld9B2"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 893,
        "workspace_id": "anglais",
        "infexions": [
            "grandpa",
            "grandpas"
        ],
        "inflexion": "grandpa, grandpas",
        "inflexions": [
            "grandpa",
            "grandpas"
        ],
        "wt": "n",
        "term": "grandpa",
        "id": "ay2O7r3cP0fSJGGplNt0"
    },
    {
        "term": "hero",
        "model": "lang-en",
        "infexions": [
            "hero",
            "heroes"
        ],
        "inflexion": "hero, heroes",
        "workspace_id": "anglais",
        "rank": 894,
        "isPareto": true,
        "inflexions": [
            "hero",
            "heroes"
        ],
        "wt": "n",
        "lang": "en-fr",
        "id": "Bpb3iE7noADj6Jgt1vgu"
    },
    {
        "model": "lang-en",
        "infexions": [
            "shot",
            "shots"
        ],
        "wt": "n",
        "rank": 895,
        "term": "shot",
        "inflexions": [
            "shot",
            "shots"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "shot, shots",
        "id": "YiYSrgYEhmgTRsFq8Fvx"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 896,
        "isPareto": true,
        "inflexions": [
            "spot",
            "spots"
        ],
        "infexions": [
            "spot",
            "spots"
        ],
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "spot, spots",
        "term": "spot",
        "id": "cxlyGWqSs4cK6s9kF09S"
    },
    {
        "inflexions": [
            "folks"
        ],
        "inflexion": "folks",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "folks",
        "model": "lang-en",
        "wt": "n",
        "rank": 897,
        "infexions": [
            "folks"
        ],
        "isPareto": true,
        "id": "WNxOOxmKSLqBAAWcmstL"
    },
    {
        "inflexions": [
            "yell",
            "yells",
            "yelling",
            "yelled"
        ],
        "model": "lang-en",
        "term": "yell",
        "wt": "v",
        "infexions": [
            "yell",
            "yells",
            "yelling",
            "yelled"
        ],
        "inflexion": "yell, yells, yelling, yelled",
        "workspace_id": "anglais",
        "rank": 898,
        "lang": "en-fr",
        "isPareto": true,
        "id": "FU2S8reufYJtDmvX6f1E"
    },
    {
        "inflexions": [
            "awful",
            "awfuller",
            "awfullest"
        ],
        "model": "lang-en",
        "term": "awful",
        "isPareto": true,
        "wt": "j",
        "rank": 899,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "awful, awfuller, awfullest",
        "infexions": [
            "awful",
            "awfuller",
            "awfullest"
        ],
        "id": "DSS6A36rsDN0ijU2ef61"
    },
    {
        "infexions": [
            "scene",
            "scenes"
        ],
        "model": "lang-en",
        "rank": 900,
        "lang": "en-fr",
        "term": "scene",
        "inflexions": [
            "scene",
            "scenes"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "inflexion": "scene, scenes",
        "id": "nZA9lUKJeqPxialhoQtQ"
    },
    {
        "workspace_id": "anglais",
        "term": "trick",
        "lang": "en-fr",
        "rank": 901,
        "wt": "n",
        "model": "lang-en",
        "inflexion": "trick, tricks",
        "inflexions": [
            "trick",
            "tricks"
        ],
        "infexions": [
            "trick",
            "tricks"
        ],
        "isPareto": true,
        "id": "PvJhy5E4RwSkfyZwTyld"
    },
    {
        "wt": "n",
        "term": "asshole",
        "rank": 902,
        "inflexion": "asshole, assholes",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "asshole",
            "assholes"
        ],
        "inflexions": [
            "asshole",
            "assholes"
        ],
        "lang": "en-fr",
        "id": "eFSsl4CATanBsshe7iVy"
    },
    {
        "term": "issue",
        "wt": "n",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 903,
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "issue",
            "issues"
        ],
        "inflexions": [
            "issue",
            "issues"
        ],
        "inflexion": "issue, issues",
        "id": "31eqRNcSProiiMk5suuS"
    },
    {
        "infexions": [
            "lovely",
            "lovelier",
            "loveliest"
        ],
        "inflexions": [
            "lovely",
            "lovelier",
            "loveliest"
        ],
        "isPareto": true,
        "rank": 904,
        "workspace_id": "anglais",
        "wt": "j",
        "lang": "en-fr",
        "inflexion": "lovely, lovelier, loveliest",
        "model": "lang-en",
        "term": "lovely",
        "id": "uX81yvbNdU6qphLCLCzq"
    },
    {
        "infexions": [
            "visit",
            "visits",
            "visiting",
            "visited"
        ],
        "wt": "v",
        "isPareto": true,
        "model": "lang-en",
        "term": "visit",
        "rank": 905,
        "inflexions": [
            "visit",
            "visits",
            "visiting",
            "visited"
        ],
        "workspace_id": "anglais",
        "inflexion": "visit, visits, visiting, visited",
        "lang": "en-fr",
        "id": "kWe3TrlROEbJMg3Ahwaf"
    },
    {
        "infexions": [
            "honor",
            "honors"
        ],
        "model": "lang-en",
        "wt": "n",
        "rank": 906,
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "honor, honors",
        "term": "honor",
        "workspace_id": "anglais",
        "inflexions": [
            "honor",
            "honors"
        ],
        "id": "YtaurJRsr2mcO4tAt5Y9"
    },
    {
        "term": "clean",
        "model": "lang-en",
        "rank": 907,
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "clean",
            "cleaner",
            "cleanest"
        ],
        "wt": "j",
        "infexions": [
            "clean",
            "cleaner",
            "cleanest"
        ],
        "inflexion": "clean, cleaner, cleanest",
        "workspace_id": "anglais",
        "id": "GD9Oi8q2DU9u20nuoTZo"
    },
    {
        "infexions": [
            "second",
            "seconds"
        ],
        "workspace_id": "anglais",
        "term": "second",
        "inflexions": [
            "second",
            "seconds"
        ],
        "lang": "en-fr",
        "rank": 908,
        "wt": "n",
        "inflexion": "second, seconds",
        "model": "lang-en",
        "isPareto": true,
        "id": "admj2uGTt3fl21h3NaTy"
    },
    {
        "workspace_id": "anglais",
        "term": "chick",
        "inflexions": [
            "chick",
            "chicks"
        ],
        "infexions": [
            "chick",
            "chicks"
        ],
        "inflexion": "chick, chicks",
        "model": "lang-en",
        "wt": "n",
        "rank": 909,
        "lang": "en-fr",
        "isPareto": true,
        "id": "FDSXqPzLUWcQ5wWtXbt8"
    },
    {
        "wt": "n",
        "rank": 910,
        "infexions": [
            "costume",
            "costumes"
        ],
        "lang": "en-fr",
        "inflexions": [
            "costume",
            "costumes"
        ],
        "term": "costume",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "costume, costumes",
        "id": "shB8qjH28SSVbf1R9cDT"
    },
    {
        "infexions": [
            "Friday",
            "Fridays"
        ],
        "wt": "K",
        "rank": 911,
        "isPareto": true,
        "inflexion": "Friday, Fridays",
        "term": "Friday",
        "inflexions": [
            "Friday",
            "Fridays"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "oVdPRwWbT0VOdCdMWKF0"
    },
    {
        "isPareto": true,
        "infexions": [
            "hall",
            "halls"
        ],
        "inflexions": [
            "hall",
            "halls"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 912,
        "inflexion": "hall, halls",
        "term": "hall",
        "model": "lang-en",
        "id": "hq0fMAyH7NKl45j9d0fR"
    },
    {
        "workspace_id": "anglais",
        "rank": 913,
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "Ms",
            "Mss",
            "Mses"
        ],
        "wt": "n",
        "infexions": [
            "Ms",
            "Mss",
            "Mses"
        ],
        "term": "Ms",
        "inflexion": "Ms, Mss, Mses",
        "model": "lang-en",
        "id": "PiXOUmAB3srJapJqWe4x"
    },
    {
        "isPareto": true,
        "inflexion": "fake",
        "wt": "j",
        "model": "lang-en",
        "term": "fake",
        "infexions": [
            "fake"
        ],
        "inflexions": [
            "fake"
        ],
        "rank": 914,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "JnFdRua1XHKWmFtCcWKl"
    },
    {
        "wt": "v",
        "infexions": [
            "forgive",
            "forgives",
            "forgiving",
            "forgave",
            "forgiven"
        ],
        "isPareto": true,
        "model": "lang-en",
        "term": "forgive",
        "inflexion": "forgive, forgives, forgiving, forgave, forgiven",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "forgive",
            "forgives",
            "forgiving",
            "forgave",
            "forgiven"
        ],
        "rank": 915,
        "id": "cxMHLL3FhNX2aI4mKIn1"
    },
    {
        "inflexion": "grade, grades",
        "rank": 916,
        "lang": "en-fr",
        "term": "grade",
        "model": "lang-en",
        "infexions": [
            "grade",
            "grades"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "grade",
            "grades"
        ],
        "wt": "n",
        "id": "MHSgh6dORG7RNxkBcAfW"
    },
    {
        "isPareto": true,
        "wt": "m",
        "infexions": [
            "fifteen",
            "fifteens"
        ],
        "term": "fifteen",
        "inflexion": "fifteen, fifteens",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "fifteen",
            "fifteens"
        ],
        "rank": 917,
        "id": "HR4QtFqqOgg73N6BGypm"
    },
    {
        "rank": 918,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "ought",
        "infexions": [
            "ought"
        ],
        "inflexions": [
            "ought"
        ],
        "isPareto": true,
        "wt": "v",
        "lang": "en-fr",
        "term": "ought",
        "id": "4QFZJa3cb5u3V31dDPdH"
    },
    {
        "term": "cheat",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "v",
        "lang": "en-fr",
        "rank": 919,
        "infexions": [
            "cheat",
            "cheats",
            "cheating",
            "cheated"
        ],
        "inflexion": "cheat, cheats, cheating, cheated",
        "isPareto": true,
        "inflexions": [
            "cheat",
            "cheats",
            "cheating",
            "cheated"
        ],
        "id": "GuWbl38OJTtMbqbbHVF7"
    },
    {
        "infexions": [
            "Chinese"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "term": "Chinese",
        "rank": 920,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "Chinese"
        ],
        "inflexion": "Chinese",
        "wt": "K",
        "id": "BqAOsreKbJjjfXeHVjh5"
    },
    {
        "model": "lang-en",
        "inflexion": "crap, craps, crapping, crapped",
        "inflexions": [
            "crap",
            "craps",
            "crapping",
            "crapped"
        ],
        "term": "crap",
        "wt": "u",
        "isPareto": true,
        "rank": 921,
        "infexions": [
            "crap",
            "craps",
            "crapping",
            "crapped"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "5TisedopEEAP9vcLOKng"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "create",
        "isPareto": true,
        "rank": 922,
        "inflexion": "create, creates, creating, created",
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "create",
            "creates",
            "creating",
            "created"
        ],
        "infexions": [
            "create",
            "creates",
            "creating",
            "created"
        ],
        "id": "Hy2LqykUK9jLAdnx7oVa"
    },
    {
        "infexions": [
            "comfortable"
        ],
        "inflexion": "comfortable",
        "inflexions": [
            "comfortable"
        ],
        "term": "comfortable",
        "wt": "j",
        "lang": "en-fr",
        "rank": 923,
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "P6md9mwZCDzCowc62r99"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "hotel, hotels",
        "inflexions": [
            "hotel",
            "hotels"
        ],
        "infexions": [
            "hotel",
            "hotels"
        ],
        "isPareto": true,
        "rank": 924,
        "lang": "en-fr",
        "wt": "n",
        "term": "hotel",
        "id": "tQZaizrZuXPSC43Hkk0s"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 925,
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "magazine",
            "magazines"
        ],
        "term": "magazine",
        "inflexions": [
            "magazine",
            "magazines"
        ],
        "inflexion": "magazine, magazines",
        "workspace_id": "anglais",
        "id": "P1YEdOlEl0ztgEg13uCt"
    },
    {
        "isPareto": true,
        "inflexions": [
            "settle",
            "settles",
            "settling",
            "settled"
        ],
        "lang": "en-fr",
        "term": "settle",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "v",
        "rank": 926,
        "inflexion": "settle, settles, settling, settled",
        "infexions": [
            "settle",
            "settles",
            "settling",
            "settled"
        ],
        "id": "t26mj3cfQ9pxCRAYi1yE"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "workspace_id": "anglais",
        "inflexions": [
            "accident",
            "accidents"
        ],
        "rank": 927,
        "infexions": [
            "accident",
            "accidents"
        ],
        "inflexion": "accident, accidents",
        "term": "accident",
        "isPareto": true,
        "id": "rF6EAx3pwmGZVi5BfV6E"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "boob",
        "inflexions": [
            "boob",
            "boobs"
        ],
        "rank": 928,
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "boob",
            "boobs"
        ],
        "inflexion": "boob, boobs",
        "id": "KVN0tu1VcmZ0BXS2UqnX"
    },
    {
        "lang": "en-fr",
        "rank": 929,
        "infexions": [
            "excellent"
        ],
        "term": "excellent",
        "model": "lang-en",
        "wt": "j",
        "workspace_id": "anglais",
        "inflexions": [
            "excellent"
        ],
        "isPareto": true,
        "inflexion": "excellent",
        "id": "rWiPaZdGHL6sX1BHIrh3"
    },
    {
        "isPareto": true,
        "inflexions": [
            "neighbor",
            "neighbors"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "neighbor, neighbors",
        "rank": 930,
        "term": "neighbor",
        "wt": "n",
        "infexions": [
            "neighbor",
            "neighbors"
        ],
        "workspace_id": "anglais",
        "id": "O1ywDEAjqFhTAZHAkPE7"
    },
    {
        "inflexions": [
            "train",
            "trains"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "train, trains",
        "infexions": [
            "train",
            "trains"
        ],
        "term": "train",
        "rank": 931,
        "wt": "n",
        "workspace_id": "anglais",
        "id": "gsINBm6qZiOJHE2D1vyy"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "board",
            "boards"
        ],
        "model": "lang-en",
        "infexions": [
            "board",
            "boards"
        ],
        "rank": 932,
        "wt": "n",
        "inflexion": "board, boards",
        "isPareto": true,
        "term": "board",
        "workspace_id": "anglais",
        "id": "XYVV6odPorCrVqhSRzBj"
    },
    {
        "rank": 933,
        "inflexions": [
            "pop",
            "pops",
            "popping",
            "popped"
        ],
        "infexions": [
            "pop",
            "pops",
            "popping",
            "popped"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "pop",
        "inflexion": "pop, pops, popping, popped",
        "wt": "v",
        "id": "9f4op7XcYZPC2T0BGVvt"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "spirit",
            "spirits"
        ],
        "model": "lang-en",
        "rank": 934,
        "workspace_id": "anglais",
        "inflexions": [
            "spirit",
            "spirits"
        ],
        "term": "spirit",
        "wt": "n",
        "inflexion": "spirit, spirits",
        "isPareto": true,
        "id": "ZMqwAq73bTSbqAevnPT0"
    },
    {
        "inflexion": "cow, cows",
        "wt": "n",
        "inflexions": [
            "cow",
            "cows"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "cow",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "cow",
            "cows"
        ],
        "rank": 935,
        "id": "VOKL1VqoClS20cmfb6OA"
    },
    {
        "inflexion": "building, buildings",
        "term": "building",
        "inflexions": [
            "building",
            "buildings"
        ],
        "infexions": [
            "building",
            "buildings"
        ],
        "model": "lang-en",
        "rank": 936,
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "workspace_id": "anglais",
        "id": "cZpz4cOPnaBRWpL3wFR6"
    },
    {
        "infexions": [
            "ear",
            "ears"
        ],
        "inflexions": [
            "ear",
            "ears"
        ],
        "workspace_id": "anglais",
        "rank": 937,
        "lang": "en-fr",
        "wt": "n",
        "term": "ear",
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "ear, ears",
        "id": "ICvoyZkAsAtu2w9M9Tf3"
    },
    {
        "term": "giant",
        "wt": "j",
        "isPareto": true,
        "rank": 938,
        "lang": "en-fr",
        "infexions": [
            "giant",
            "giants"
        ],
        "inflexions": [
            "giant",
            "giants"
        ],
        "inflexion": "giant, giants",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "Uxvs3sInHp9ECgzgAlKs"
    },
    {
        "inflexion": "ugly, uglier, ugliest",
        "workspace_id": "anglais",
        "infexions": [
            "ugly",
            "uglier",
            "ugliest"
        ],
        "lang": "en-fr",
        "inflexions": [
            "ugly",
            "uglier",
            "ugliest"
        ],
        "model": "lang-en",
        "wt": "j",
        "term": "ugly",
        "rank": 939,
        "isPareto": true,
        "id": "hlTekt9BAgs4zwxPqwLM"
    },
    {
        "rank": 940,
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "inflexion": "toy, toys",
        "term": "toy",
        "model": "lang-en",
        "infexions": [
            "toy",
            "toys"
        ],
        "lang": "en-fr",
        "inflexions": [
            "toy",
            "toys"
        ],
        "id": "ez8CgWlh5GczTsrJai0C"
    },
    {
        "rank": 941,
        "workspace_id": "anglais",
        "infexions": [
            "cancel",
            "cancels",
            "canceling",
            "canceled"
        ],
        "inflexions": [
            "cancel",
            "cancels",
            "canceling",
            "canceled"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "term": "cancel",
        "isPareto": true,
        "wt": "v",
        "inflexion": "cancel, cancels, canceling, canceled",
        "id": "h6eCtHDyZblchM8e1UtG"
    },
    {
        "infexions": [
            "Internet",
            "Net"
        ],
        "inflexions": [
            "Internet",
            "Net"
        ],
        "inflexion": "Internet, Net",
        "rank": 942,
        "isPareto": true,
        "wt": "K",
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "Internet",
        "id": "1QVw9x6iI0ssDnJdjTUJ"
    },
    {
        "inflexions": [
            "strange",
            "stranger",
            "strangest"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "strange, stranger, strangest",
        "infexions": [
            "strange",
            "stranger",
            "strangest"
        ],
        "wt": "j",
        "rank": 943,
        "workspace_id": "anglais",
        "term": "strange",
        "id": "7gTowId9cRPZUa29fr0i"
    },
    {
        "isPareto": true,
        "infexions": [
            "aunt",
            "aunts"
        ],
        "model": "lang-en",
        "term": "aunt",
        "rank": 944,
        "workspace_id": "anglais",
        "inflexion": "aunt, aunts",
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "aunt",
            "aunts"
        ],
        "id": "1LR9qXAiaRIQxMP6HcuY"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "island",
            "islands"
        ],
        "rank": 945,
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "island",
            "islands"
        ],
        "inflexion": "island, islands",
        "term": "island",
        "isPareto": true,
        "model": "lang-en",
        "id": "qVj1oRhAQxU1ReMcVacI"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "extra",
        "term": "extra",
        "wt": "j",
        "rank": 946,
        "infexions": [
            "extra"
        ],
        "inflexions": [
            "extra"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "id": "Yx8Us38MwCSCWOQ3D58D"
    },
    {
        "wt": "v",
        "model": "lang-en",
        "rank": 947,
        "workspace_id": "anglais",
        "infexions": [
            "fit",
            "fits",
            "fitting",
            "fitted"
        ],
        "isPareto": true,
        "inflexions": [
            "fit",
            "fits",
            "fitting",
            "fitted"
        ],
        "inflexion": "fit, fits, fitting, fitted",
        "lang": "en-fr",
        "term": "fit",
        "id": "NwSXfrxDcjuGp0W0eazV"
    },
    {
        "inflexion": "rock, rocks",
        "model": "lang-en",
        "isPareto": true,
        "term": "rock",
        "rank": 948,
        "infexions": [
            "rock",
            "rocks"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "rock",
            "rocks"
        ],
        "lang": "en-fr",
        "wt": "n",
        "id": "HjCSwqcjPhpjzpfKlbBB"
    },
    {
        "inflexion": "step, steps",
        "infexions": [
            "step",
            "steps"
        ],
        "rank": 949,
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "term": "step",
        "inflexions": [
            "step",
            "steps"
        ],
        "id": "WrenTBFUev7xdKHoDUWJ"
    },
    {
        "term": "action",
        "model": "lang-en",
        "wt": "n",
        "rank": 950,
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "action, actions",
        "infexions": [
            "action",
            "actions"
        ],
        "inflexions": [
            "action",
            "actions"
        ],
        "lang": "en-fr",
        "id": "JrWrjw6v39scrd7cS05W"
    },
    {
        "inflexions": [
            "bill",
            "bills"
        ],
        "rank": 951,
        "model": "lang-en",
        "inflexion": "bill, bills",
        "isPareto": true,
        "lang": "en-fr",
        "term": "bill",
        "workspace_id": "anglais",
        "infexions": [
            "bill",
            "bills"
        ],
        "wt": "n",
        "id": "Lqme12gaWxItr3Um4byX"
    },
    {
        "term": "field",
        "wt": "n",
        "infexions": [
            "field",
            "fields"
        ],
        "lang": "en-fr",
        "inflexions": [
            "field",
            "fields"
        ],
        "inflexion": "field, fields",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 952,
        "id": "J04bbO9lGGA1JqFycJPz"
    },
    {
        "infexions": [
            "kiss",
            "kisses"
        ],
        "wt": "n",
        "inflexion": "kiss, kisses",
        "isPareto": true,
        "term": "kiss",
        "inflexions": [
            "kiss",
            "kisses"
        ],
        "rank": 953,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "id": "gmiP4R5EhVkqQXDoNDaz"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 954,
        "inflexions": [
            "fresh",
            "fresher",
            "freshest"
        ],
        "wt": "j",
        "inflexion": "fresh, fresher, freshest",
        "isPareto": true,
        "model": "lang-en",
        "term": "fresh",
        "infexions": [
            "fresh",
            "fresher",
            "freshest"
        ],
        "id": "S7Bhm64tmjeNE0BjUFKo"
    },
    {
        "model": "lang-en",
        "inflexion": "level, levels",
        "inflexions": [
            "level",
            "levels"
        ],
        "isPareto": true,
        "infexions": [
            "level",
            "levels"
        ],
        "term": "level",
        "wt": "n",
        "lang": "en-fr",
        "rank": 955,
        "workspace_id": "anglais",
        "id": "fp7cJUoTVqqhajDHs8mQ"
    },
    {
        "inflexions": [
            "cost",
            "costs",
            "costing",
            "costed"
        ],
        "term": "cost",
        "lang": "en-fr",
        "wt": "v",
        "workspace_id": "anglais",
        "inflexion": "cost, costs, costing, costed",
        "rank": 956,
        "infexions": [
            "cost",
            "costs",
            "costing",
            "costed"
        ],
        "isPareto": true,
        "model": "lang-en",
        "id": "QV4wMRhKkEAgRLZubGln"
    },
    {
        "inflexion": "size, sizes",
        "term": "size",
        "model": "lang-en",
        "isPareto": true,
        "rank": 957,
        "lang": "en-fr",
        "inflexions": [
            "size",
            "sizes"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "size",
            "sizes"
        ],
        "wt": "n",
        "id": "hMVzdwy8MHUl4cyXhoDj"
    },
    {
        "wt": "n",
        "inflexion": "cell, cells",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "cell",
            "cells"
        ],
        "term": "cell",
        "rank": 958,
        "lang": "en-fr",
        "inflexions": [
            "cell",
            "cells"
        ],
        "id": "OTQJOFbqCbLJIOTkr18t"
    },
    {
        "infexions": [
            "serve",
            "serves",
            "serving",
            "served"
        ],
        "model": "lang-en",
        "inflexions": [
            "serve",
            "serves",
            "serving",
            "served"
        ],
        "rank": 959,
        "lang": "en-fr",
        "term": "serve",
        "workspace_id": "anglais",
        "inflexion": "serve, serves, serving, served",
        "wt": "v",
        "isPareto": true,
        "id": "oQqS8OuOAF87l9Ka4Dnf"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 960,
        "term": "shake",
        "inflexion": "shake, shakes, shaking, shook, shaken",
        "wt": "v",
        "inflexions": [
            "shake",
            "shakes",
            "shaking",
            "shook",
            "shaken"
        ],
        "infexions": [
            "shake",
            "shakes",
            "shaking",
            "shook",
            "shaken"
        ],
        "id": "YRwiUDwxmU076o6fOnZj"
    },
    {
        "inflexion": "neck, necks",
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "neck",
            "necks"
        ],
        "infexions": [
            "neck",
            "necks"
        ],
        "rank": 961,
        "wt": "n",
        "isPareto": true,
        "lang": "en-fr",
        "term": "neck",
        "id": "RncRFnXFIW7JgPiFESkm"
    },
    {
        "wt": "n",
        "inflexions": [
            "bowl",
            "bowls"
        ],
        "inflexion": "bowl, bowls",
        "term": "bowl",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "bowl",
            "bowls"
        ],
        "lang": "en-fr",
        "rank": 962,
        "id": "Xf8IDXk1LHXrppytjxaz"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "control",
            "controls"
        ],
        "term": "control",
        "lang": "en-fr",
        "inflexion": "control, controls",
        "isPareto": true,
        "rank": 963,
        "infexions": [
            "control",
            "controls"
        ],
        "workspace_id": "anglais",
        "id": "OWgRycrqdwaNWRG6YW1N"
    },
    {
        "rank": 964,
        "term": "loud",
        "inflexion": "loud, louder, loudest",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "j",
        "lang": "en-fr",
        "infexions": [
            "loud",
            "louder",
            "loudest"
        ],
        "inflexions": [
            "loud",
            "louder",
            "loudest"
        ],
        "model": "lang-en",
        "id": "Tb456GgcfGkV21MgtEDW"
    },
    {
        "model": "lang-en",
        "infexions": [
            "bedroom",
            "bedrooms"
        ],
        "term": "bedroom",
        "inflexions": [
            "bedroom",
            "bedrooms"
        ],
        "rank": 965,
        "inflexion": "bedroom, bedrooms",
        "wt": "n",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "vp8S2kMvR5Mx66KWz0c1"
    },
    {
        "infexions": [
            "check",
            "checks"
        ],
        "workspace_id": "anglais",
        "rank": 966,
        "inflexion": "check, checks",
        "inflexions": [
            "check",
            "checks"
        ],
        "wt": "n",
        "lang": "en-fr",
        "term": "check",
        "isPareto": true,
        "model": "lang-en",
        "id": "1xhpjVpRxwNOW1dpAJ09"
    },
    {
        "term": "heaven",
        "inflexions": [
            "heaven",
            "heavens"
        ],
        "isPareto": true,
        "rank": 967,
        "inflexion": "heaven, heavens",
        "model": "lang-en",
        "wt": "n",
        "infexions": [
            "heaven",
            "heavens"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "iIpWZe8mFLQ19gAUgiGK"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "right, rights",
        "term": "right",
        "rank": 968,
        "infexions": [
            "right",
            "rights"
        ],
        "model": "lang-en",
        "inflexions": [
            "right",
            "rights"
        ],
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "id": "Dixul4mehn3ILWkmxafR"
    },
    {
        "infexions": [
            "commercial",
            "commercials"
        ],
        "term": "commercial",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "commercial, commercials",
        "rank": 969,
        "inflexions": [
            "commercial",
            "commercials"
        ],
        "workspace_id": "anglais",
        "id": "5UKaI4jVd7Nox2dcz6RW"
    },
    {
        "rank": 970,
        "inflexion": "draw, draws, drawing, drew, drawn",
        "isPareto": true,
        "term": "draw",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "v",
        "inflexions": [
            "draw",
            "draws",
            "drawing",
            "drew",
            "drawn"
        ],
        "infexions": [
            "draw",
            "draws",
            "drawing",
            "drew",
            "drawn"
        ],
        "id": "kdHyf9gaRFN8O3uWbOfw"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "guest",
            "guests"
        ],
        "inflexion": "guest, guests",
        "isPareto": true,
        "inflexions": [
            "guest",
            "guests"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 971,
        "term": "guest",
        "wt": "n",
        "id": "91LEX5sqbKpFvQSkIiWW"
    },
    {
        "term": "insane",
        "workspace_id": "anglais",
        "inflexions": [
            "insane",
            "insaner",
            "insanest"
        ],
        "rank": 972,
        "inflexion": "insane, insaner, insanest",
        "wt": "j",
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "insane",
            "insaner",
            "insanest"
        ],
        "lang": "en-fr",
        "id": "VHCWsnenPlzWyh7sWTsN"
    },
    {
        "inflexion": "fail, fails, failing, failed",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "term": "fail",
        "inflexions": [
            "fail",
            "fails",
            "failing",
            "failed"
        ],
        "rank": 973,
        "wt": "v",
        "infexions": [
            "fail",
            "fails",
            "failing",
            "failed"
        ],
        "id": "yScpGNufW2XKsZCDqkx5"
    },
    {
        "infexions": [
            "cook",
            "cooks",
            "cooking",
            "cooked"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "cook",
            "cooks",
            "cooking",
            "cooked"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "term": "cook",
        "rank": 974,
        "inflexion": "cook, cooks, cooking, cooked",
        "id": "UmmPw73cinsn9iC34Z0V"
    },
    {
        "term": "pleasure",
        "wt": "n",
        "workspace_id": "anglais",
        "inflexion": "pleasure, pleasures",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "pleasure",
            "pleasures"
        ],
        "rank": 975,
        "model": "lang-en",
        "inflexions": [
            "pleasure",
            "pleasures"
        ],
        "id": "hcXwvSWbMGFpFUFbHGaa"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "truck",
            "trucks"
        ],
        "wt": "n",
        "rank": 976,
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "truck, trucks",
        "workspace_id": "anglais",
        "term": "truck",
        "inflexions": [
            "truck",
            "trucks"
        ],
        "id": "CkpI2d9cmPNgGXdEIxmF"
    },
    {
        "inflexions": [
            "science",
            "sciences"
        ],
        "infexions": [
            "science",
            "sciences"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 977,
        "isPareto": true,
        "term": "science",
        "lang": "en-fr",
        "inflexion": "science, sciences",
        "id": "SJDahvwcbu5Jllvpm8Im"
    },
    {
        "rank": 978,
        "term": "careful",
        "workspace_id": "anglais",
        "wt": "j",
        "inflexions": [
            "careful"
        ],
        "infexions": [
            "careful"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "careful",
        "lang": "en-fr",
        "id": "tbVzBUYVfZWTLtJRNS8w"
    },
    {
        "term": "nervous",
        "wt": "j",
        "workspace_id": "anglais",
        "infexions": [
            "nervous"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "nervous"
        ],
        "isPareto": true,
        "rank": 979,
        "inflexion": "nervous",
        "id": "XTS3JsxRJIkAF4tluhW0"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "although",
        "rank": 980,
        "inflexion": "although, altho",
        "wt": "fw",
        "infexions": [
            "although",
            "altho"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "although",
            "altho"
        ],
        "id": "7YeRfbQKw4xuUXubaOE9"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "gas, gases, gasses",
        "infexions": [
            "gas",
            "gases",
            "gasses"
        ],
        "wt": "n",
        "rank": 981,
        "term": "gas",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "gas",
            "gases",
            "gasses"
        ],
        "id": "GzgeQDt5GDzhr9nLJJGn"
    },
    {
        "workspace_id": "anglais",
        "rank": 982,
        "isPareto": true,
        "term": "partner",
        "wt": "n",
        "model": "lang-en",
        "infexions": [
            "partner",
            "partners"
        ],
        "lang": "en-fr",
        "inflexion": "partner, partners",
        "inflexions": [
            "partner",
            "partners"
        ],
        "id": "TzGQIrfJx6JxHsPzYy6j"
    },
    {
        "model": "lang-en",
        "term": "scream",
        "inflexions": [
            "scream",
            "screams",
            "screaming",
            "screamed"
        ],
        "isPareto": true,
        "wt": "v",
        "rank": 983,
        "lang": "en-fr",
        "infexions": [
            "scream",
            "screams",
            "screaming",
            "screamed"
        ],
        "workspace_id": "anglais",
        "inflexion": "scream, screams, screaming, screamed",
        "id": "OWwRzfpvfNse863Da39r"
    },
    {
        "workspace_id": "anglais",
        "rank": 984,
        "inflexions": [
            "pool",
            "pools"
        ],
        "infexions": [
            "pool",
            "pools"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "term": "pool",
        "isPareto": true,
        "wt": "n",
        "inflexion": "pool, pools",
        "id": "uD0G9WtP3U8k7AZHoypp"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "appear, appears, appearing, appeared",
        "infexions": [
            "appear",
            "appears",
            "appearing",
            "appeared"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 985,
        "wt": "v",
        "term": "appear",
        "inflexions": [
            "appear",
            "appears",
            "appearing",
            "appeared"
        ],
        "id": "rxVYAx2G47lbIF1KN9ML"
    },
    {
        "inflexions": [
            "clearly"
        ],
        "model": "lang-en",
        "inflexion": "clearly",
        "wt": "r",
        "infexions": [
            "clearly"
        ],
        "lang": "en-fr",
        "term": "clearly",
        "workspace_id": "anglais",
        "rank": 986,
        "isPareto": true,
        "id": "PFU6FauzxGuMogaXxzbi"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "silly",
            "sillier",
            "silliest"
        ],
        "wt": "j",
        "infexions": [
            "silly",
            "sillier",
            "silliest"
        ],
        "rank": 987,
        "workspace_id": "anglais",
        "inflexion": "silly, sillier, silliest",
        "term": "silly",
        "isPareto": true,
        "id": "kGYtDEGKU03GsX1kNSNI"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "feed",
            "feeds",
            "feeding",
            "fed"
        ],
        "infexions": [
            "feed",
            "feeds",
            "feeding",
            "fed"
        ],
        "wt": "v",
        "isPareto": true,
        "inflexion": "feed, feeds, feeding, fed",
        "rank": 988,
        "lang": "en-fr",
        "term": "feed",
        "model": "lang-en",
        "id": "y2oFtKVtaONBeaGdn3yA"
    },
    {
        "lang": "en-fr",
        "inflexion": "charge, charges",
        "rank": 989,
        "model": "lang-en",
        "infexions": [
            "charge",
            "charges"
        ],
        "wt": "n",
        "term": "charge",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "charge",
            "charges"
        ],
        "id": "IwhQaT0f8qc91kKm9xUe"
    },
    {
        "wt": "fw",
        "inflexions": [
            "neither"
        ],
        "term": "neither",
        "rank": 990,
        "model": "lang-en",
        "infexions": [
            "neither"
        ],
        "inflexion": "neither",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "id": "T6I1DnS7tCV3rcRQODne"
    },
    {
        "inflexions": [
            "wash",
            "washes",
            "washing",
            "washed"
        ],
        "wt": "v",
        "rank": 991,
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "wash, washes, washing, washed",
        "term": "wash",
        "model": "lang-en",
        "infexions": [
            "wash",
            "washes",
            "washing",
            "washed"
        ],
        "id": "ZpWEhNBM4eTBHTaGG9c8"
    },
    {
        "infexions": [
            "stink",
            "stinks",
            "stinking",
            "stank",
            "stunk"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "stink",
            "stinks",
            "stinking",
            "stank",
            "stunk"
        ],
        "wt": "v",
        "inflexion": "stink, stinks, stinking, stank, stunk",
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 992,
        "term": "stink",
        "id": "6FFtd7XU4bvC0PREYl8e"
    },
    {
        "workspace_id": "anglais",
        "term": "magic",
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "inflexion": "magic",
        "rank": 993,
        "inflexions": [
            "magic"
        ],
        "model": "lang-en",
        "infexions": [
            "magic"
        ],
        "id": "1gL6V27sYstKwKcMufwB"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "plane",
        "wt": "n",
        "rank": 994,
        "inflexion": "plane, planes",
        "inflexions": [
            "plane",
            "planes"
        ],
        "infexions": [
            "plane",
            "planes"
        ],
        "id": "zBu6TDgUlfSZUVoS2woL"
    },
    {
        "inflexion": "tiny, tinier, tiniest",
        "term": "tiny",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexions": [
            "tiny",
            "tinier",
            "tiniest"
        ],
        "infexions": [
            "tiny",
            "tinier",
            "tiniest"
        ],
        "lang": "en-fr",
        "wt": "j",
        "rank": 995,
        "id": "ZjF50O7qdCXoKzKVSM8b"
    },
    {
        "workspace_id": "anglais",
        "term": "prison",
        "inflexions": [
            "prison",
            "prisons"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "prison, prisons",
        "infexions": [
            "prison",
            "prisons"
        ],
        "rank": 996,
        "lang": "en-fr",
        "wt": "n",
        "id": "LaR1Pm38MMaSoa0XycWm"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "cause, causes, causing, caused",
        "infexions": [
            "cause",
            "causes",
            "causing",
            "caused"
        ],
        "term": "cause",
        "rank": 997,
        "model": "lang-en",
        "wt": "v",
        "inflexions": [
            "cause",
            "causes",
            "causing",
            "caused"
        ],
        "isPareto": true,
        "id": "dVmc0SDEph1DiLdMCq70"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "photo",
            "photos",
            "photograph",
            "photographs"
        ],
        "isPareto": true,
        "inflexions": [
            "photo",
            "photos",
            "photograph",
            "photographs"
        ],
        "inflexion": "photo, photos, photograph, photographs",
        "term": "photo",
        "wt": "n",
        "rank": 998,
        "id": "q7wN2g7qsuc09d2vSltZ"
    },
    {
        "inflexion": "public",
        "isPareto": true,
        "rank": 999,
        "workspace_id": "anglais",
        "term": "public",
        "wt": "j",
        "inflexions": [
            "public"
        ],
        "infexions": [
            "public"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "id": "hKtdzHP4LBF6xtUOfqdt"
    },
    {
        "wt": "n",
        "rank": 1000,
        "isPareto": true,
        "inflexions": [
            "button",
            "buttons"
        ],
        "lang": "en-fr",
        "infexions": [
            "button",
            "buttons"
        ],
        "term": "button",
        "workspace_id": "anglais",
        "inflexion": "button, buttons",
        "model": "lang-en",
        "id": "1AfjyAOuBhSPqTVUGmvo"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "flower",
            "flowers"
        ],
        "rank": 1001,
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "flower, flowers",
        "term": "flower",
        "infexions": [
            "flower",
            "flowers"
        ],
        "id": "6lvoFGU1kmPb3ShjJXNW"
    },
    {
        "infexions": [
            "memory",
            "memories"
        ],
        "isPareto": true,
        "rank": 1002,
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "memory",
            "memories"
        ],
        "inflexion": "memory, memories",
        "model": "lang-en",
        "term": "memory",
        "workspace_id": "anglais",
        "id": "xnXyQny6u0w6W5sjsvSs"
    },
    {
        "rank": 1003,
        "inflexions": [
            "own",
            "owns",
            "owning",
            "owned"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "own",
        "isPareto": true,
        "wt": "v",
        "inflexion": "own, owns, owning, owned",
        "infexions": [
            "own",
            "owns",
            "owning",
            "owned"
        ],
        "model": "lang-en",
        "id": "qaxQzURD2bvoocFxbGCz"
    },
    {
        "infexions": [
            "fast",
            "faster",
            "fastest"
        ],
        "inflexions": [
            "fast",
            "faster",
            "fastest"
        ],
        "inflexion": "fast, faster, fastest",
        "term": "fast",
        "wt": "j",
        "model": "lang-en",
        "rank": 1004,
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "id": "zmf4GF6VoYCev0nQrdaE"
    },
    {
        "infexions": [
            "base",
            "bases",
            "basing",
            "based"
        ],
        "model": "lang-en",
        "inflexions": [
            "base",
            "bases",
            "basing",
            "based"
        ],
        "wt": "v",
        "isPareto": true,
        "term": "base",
        "inflexion": "base, bases, basing, based",
        "rank": 1005,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "035aVxClBwoIi4Xkvrh0"
    },
    {
        "inflexion": "involve, involves, involving, involved",
        "term": "involve",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1006,
        "inflexions": [
            "involve",
            "involves",
            "involving",
            "involved"
        ],
        "wt": "v",
        "infexions": [
            "involve",
            "involves",
            "involving",
            "involved"
        ],
        "model": "lang-en",
        "id": "BQ8Bn6KCk2i0pKOKp2ir"
    },
    {
        "model": "lang-en",
        "rank": 1007,
        "isPareto": true,
        "term": "madam",
        "inflexions": [
            "madam",
            "madams",
            "mesdames",
            "ma'am"
        ],
        "infexions": [
            "madam",
            "madams",
            "mesdames",
            "ma'am"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "inflexion": "madam, madams, mesdames, ma'am",
        "id": "JyjWAiJ2PUn7XUULD4nP"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "term": "blame",
        "inflexion": "blame, blames, blaming, blamed",
        "isPareto": true,
        "wt": "v",
        "inflexions": [
            "blame",
            "blames",
            "blaming",
            "blamed"
        ],
        "workspace_id": "anglais",
        "rank": 1008,
        "infexions": [
            "blame",
            "blames",
            "blaming",
            "blamed"
        ],
        "id": "ccrVeX0DTB6ekCVZyVo7"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "rank": 1009,
        "isPareto": true,
        "term": "tea",
        "model": "lang-en",
        "inflexion": "tea, teas",
        "infexions": [
            "tea",
            "teas"
        ],
        "inflexions": [
            "tea",
            "teas"
        ],
        "id": "8IeYBk0FNqdvgPJPFxfV"
    },
    {
        "inflexion": "whoo",
        "workspace_id": "anglais",
        "inflexions": [
            "whoo"
        ],
        "lang": "en-fr",
        "wt": "u",
        "isPareto": true,
        "infexions": [
            "whoo"
        ],
        "term": "whoo",
        "model": "lang-en",
        "rank": 1010,
        "id": "9fnWgmUx2f8B8rC7LUjV"
    },
    {
        "rank": 1011,
        "inflexions": [
            "bike",
            "bikes"
        ],
        "inflexion": "bike, bikes",
        "term": "bike",
        "wt": "n",
        "infexions": [
            "bike",
            "bikes"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "7VcqxUNChy7JhOmpwSvf"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "workspace_id": "anglais",
        "rank": 1012,
        "model": "lang-en",
        "inflexion": "freeze, freezes, freezing, froze, frozen",
        "inflexions": [
            "freeze",
            "freezes",
            "freezing",
            "froze",
            "frozen"
        ],
        "infexions": [
            "freeze",
            "freezes",
            "freezing",
            "froze",
            "frozen"
        ],
        "term": "freeze",
        "id": "PrLdgDB0A6POsqKcwubg"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 1013,
        "term": "sexual",
        "inflexions": [
            "sexual"
        ],
        "inflexion": "sexual",
        "infexions": [
            "sexual"
        ],
        "wt": "j",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "CTOVyyAgHawatccopb3a"
    },
    {
        "term": "code",
        "inflexion": "code, codes",
        "rank": 1014,
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "code",
            "codes"
        ],
        "infexions": [
            "code",
            "codes"
        ],
        "lang": "en-fr",
        "id": "F2sSxs7SrWq1O74ulgWg"
    },
    {
        "infexions": [
            "celebrate",
            "celebrates",
            "celebrating",
            "celebrated"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "v",
        "lang": "en-fr",
        "inflexion": "celebrate, celebrates, celebrating, celebrated",
        "model": "lang-en",
        "inflexions": [
            "celebrate",
            "celebrates",
            "celebrating",
            "celebrated"
        ],
        "rank": 1015,
        "term": "celebrate",
        "id": "GdYNjUu2t7UAgC8MbgaR"
    },
    {
        "term": "couch",
        "inflexions": [
            "couch",
            "couches"
        ],
        "infexions": [
            "couch",
            "couches"
        ],
        "inflexion": "couch, couches",
        "isPareto": true,
        "rank": 1016,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "id": "VrMVnuDXb98onEAgnXpc"
    },
    {
        "term": "inside",
        "workspace_id": "anglais",
        "wt": "fw",
        "infexions": [
            "inside"
        ],
        "inflexion": "inside",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1017,
        "inflexions": [
            "inside"
        ],
        "model": "lang-en",
        "id": "w1SVngd3uV6RdKWxiQXW"
    },
    {
        "inflexions": [
            "price",
            "prices"
        ],
        "model": "lang-en",
        "wt": "n",
        "rank": 1018,
        "infexions": [
            "price",
            "prices"
        ],
        "workspace_id": "anglais",
        "inflexion": "price, prices",
        "lang": "en-fr",
        "isPareto": true,
        "term": "price",
        "id": "8ssEWyBa3ifhcn9ydIsV"
    },
    {
        "isPareto": true,
        "inflexion": "assume, assumes, assuming, assumed",
        "wt": "v",
        "lang": "en-fr",
        "term": "assume",
        "infexions": [
            "assume",
            "assumes",
            "assuming",
            "assumed"
        ],
        "rank": 1019,
        "model": "lang-en",
        "inflexions": [
            "assume",
            "assumes",
            "assuming",
            "assumed"
        ],
        "workspace_id": "anglais",
        "id": "mnWiP7xOVaZfe3eGgxlo"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "delicious",
        "rank": 1020,
        "infexions": [
            "delicious"
        ],
        "term": "delicious",
        "lang": "en-fr",
        "wt": "j",
        "isPareto": true,
        "inflexions": [
            "delicious"
        ],
        "model": "lang-en",
        "id": "icF8oXb4v2VFAFGFq00D"
    },
    {
        "rank": 1021,
        "wt": "m",
        "inflexions": [
            "forty",
            "forties"
        ],
        "term": "forty",
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "forty, forties",
        "isPareto": true,
        "infexions": [
            "forty",
            "forties"
        ],
        "id": "XKKdzoKPVpRSRY7o9o4F"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "player",
            "players"
        ],
        "workspace_id": "anglais",
        "term": "player",
        "model": "lang-en",
        "inflexion": "player, players",
        "inflexions": [
            "player",
            "players"
        ],
        "isPareto": true,
        "rank": 1022,
        "id": "6q2BTMkswU5KjH4Cjs0W"
    },
    {
        "inflexions": [
            "soup",
            "soups"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 1023,
        "inflexion": "soup, soups",
        "wt": "n",
        "model": "lang-en",
        "term": "soup",
        "infexions": [
            "soup",
            "soups"
        ],
        "lang": "en-fr",
        "id": "6Fhn9oYjxz8FbpSTHOpT"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "waste",
            "wastes",
            "wasting",
            "wasted"
        ],
        "wt": "v",
        "lang": "en-fr",
        "term": "waste",
        "isPareto": true,
        "rank": 1024,
        "workspace_id": "anglais",
        "infexions": [
            "waste",
            "wastes",
            "wasting",
            "wasted"
        ],
        "inflexion": "waste, wastes, wasting, wasted",
        "id": "NRa04t1VcWwUuJE5iWjC"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "coat, coats",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "coat",
            "coats"
        ],
        "term": "coat",
        "rank": 1025,
        "lang": "en-fr",
        "inflexions": [
            "coat",
            "coats"
        ],
        "wt": "n",
        "id": "ha82e3mTiiOmPZdC3ona"
    },
    {
        "inflexion": "doll, dolls",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "doll",
            "dolls"
        ],
        "infexions": [
            "doll",
            "dolls"
        ],
        "model": "lang-en",
        "rank": 1026,
        "wt": "n",
        "workspace_id": "anglais",
        "term": "doll",
        "id": "Sq5bbCYhVS3bePnGOab1"
    },
    {
        "infexions": [
            "security",
            "securities"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "security, securities",
        "model": "lang-en",
        "inflexions": [
            "security",
            "securities"
        ],
        "lang": "en-fr",
        "term": "security",
        "rank": 1027,
        "wt": "n",
        "id": "BJX6UNMib8opqe2Ac52k"
    },
    {
        "model": "lang-en",
        "wt": "j",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "warm, warmer, warmest",
        "inflexions": [
            "warm",
            "warmer",
            "warmest"
        ],
        "infexions": [
            "warm",
            "warmer",
            "warmest"
        ],
        "workspace_id": "anglais",
        "rank": 1028,
        "term": "warm",
        "id": "DAXrTPX4jRW8yzVgg77T"
    },
    {
        "workspace_id": "anglais",
        "wt": "n",
        "term": "football",
        "rank": 1029,
        "inflexion": "football, footballs",
        "model": "lang-en",
        "inflexions": [
            "football",
            "footballs"
        ],
        "infexions": [
            "football",
            "footballs"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "id": "hxYGhFjaPzNdhr3SafyC"
    },
    {
        "inflexion": "model, models",
        "inflexions": [
            "model",
            "models"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1030,
        "wt": "n",
        "infexions": [
            "model",
            "models"
        ],
        "term": "model",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "erlSImsJl9uKx33Y5kcr"
    },
    {
        "inflexions": [
            "whose"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "whose"
        ],
        "inflexion": "whose",
        "model": "lang-en",
        "wt": "fw",
        "rank": 1031,
        "isPareto": true,
        "term": "whose",
        "lang": "en-fr",
        "id": "soV8UhZoI5s6FifL9NSO"
    },
    {
        "isPareto": true,
        "rank": 1032,
        "inflexions": [
            "besides"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "fw",
        "infexions": [
            "besides"
        ],
        "inflexion": "besides",
        "term": "besides",
        "id": "84FzfJtbuOVhCErijxEh"
    },
    {
        "rank": 1033,
        "workspace_id": "anglais",
        "infexions": [
            "middle",
            "middles"
        ],
        "term": "middle",
        "inflexion": "middle, middles",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "inflexions": [
            "middle",
            "middles"
        ],
        "wt": "n",
        "id": "uKvU4x7ehC1CzHrcgk14"
    },
    {
        "model": "lang-en",
        "inflexion": "shop, shops, shopping, shopped",
        "inflexions": [
            "shop",
            "shops",
            "shopping",
            "shopped"
        ],
        "infexions": [
            "shop",
            "shops",
            "shopping",
            "shopped"
        ],
        "rank": 1034,
        "isPareto": true,
        "wt": "v",
        "term": "shop",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "kqJdZkrrPHCQrUlTbk4g"
    },
    {
        "wt": "n",
        "inflexions": [
            "garbage"
        ],
        "infexions": [
            "garbage"
        ],
        "workspace_id": "anglais",
        "inflexion": "garbage",
        "isPareto": true,
        "term": "garbage",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 1035,
        "id": "yPs2MKSfpCbUdMLkqscT"
    },
    {
        "rank": 1036,
        "infexions": [
            "client",
            "clients"
        ],
        "lang": "en-fr",
        "term": "client",
        "inflexions": [
            "client",
            "clients"
        ],
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "workspace_id": "anglais",
        "inflexion": "client, clients",
        "id": "lGSZSv8BzY4EIVmCYHJD"
    },
    {
        "rank": 1037,
        "isPareto": true,
        "wt": "n",
        "lang": "en-fr",
        "infexions": [
            "ground"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "ground",
        "inflexions": [
            "ground"
        ],
        "inflexion": "ground",
        "id": "fpJkPjnK9Cvsv4mYbZHZ"
    },
    {
        "isPareto": true,
        "wt": "j",
        "term": "lame",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 1038,
        "inflexions": [
            "lame",
            "lamer",
            "lamest"
        ],
        "inflexion": "lame, lamer, lamest",
        "infexions": [
            "lame",
            "lamer",
            "lamest"
        ],
        "workspace_id": "anglais",
        "id": "I2cn7k20he7sp2Lf9ItE"
    },
    {
        "lang": "en-fr",
        "term": "project",
        "infexions": [
            "project",
            "projects"
        ],
        "inflexions": [
            "project",
            "projects"
        ],
        "workspace_id": "anglais",
        "rank": 1039,
        "model": "lang-en",
        "wt": "n",
        "inflexion": "project, projects",
        "isPareto": true,
        "id": "oLywPO6ooOznRrdKzbUG"
    },
    {
        "wt": "v",
        "term": "dare",
        "lang": "en-fr",
        "inflexion": "dare, dares, daring, dared",
        "workspace_id": "anglais",
        "rank": 1040,
        "model": "lang-en",
        "infexions": [
            "dare",
            "dares",
            "daring",
            "dared"
        ],
        "isPareto": true,
        "inflexions": [
            "dare",
            "dares",
            "daring",
            "dared"
        ],
        "id": "d2QZCzQTPm03MsaaD4zr"
    },
    {
        "inflexions": [
            "shop",
            "shops"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "wt": "n",
        "workspace_id": "anglais",
        "rank": 1041,
        "infexions": [
            "shop",
            "shops"
        ],
        "term": "shop",
        "inflexion": "shop, shops",
        "model": "lang-en",
        "id": "SeKNZuRQC1FiI78kMBi4"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "episode",
        "infexions": [
            "episode",
            "episodes"
        ],
        "inflexions": [
            "episode",
            "episodes"
        ],
        "inflexion": "episode, episodes",
        "isPareto": true,
        "wt": "n",
        "rank": 1042,
        "id": "17coXjq9ROYCiGjapM0i"
    },
    {
        "wt": "n",
        "isPareto": true,
        "term": "glass",
        "model": "lang-en",
        "inflexions": [
            "glass",
            "glasses"
        ],
        "inflexion": "glass, glasses",
        "rank": 1043,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "glass",
            "glasses"
        ],
        "id": "ICmLQkXDShr1IkWDtXmo"
    },
    {
        "inflexion": "green, greener, greenest",
        "inflexions": [
            "green",
            "greener",
            "greenest"
        ],
        "term": "green",
        "wt": "j",
        "infexions": [
            "green",
            "greener",
            "greenest"
        ],
        "rank": 1044,
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "Hh6Rm9uoRaWZrccnH1ID"
    },
    {
        "model": "lang-en",
        "term": "lock",
        "wt": "v",
        "infexions": [
            "lock",
            "locks",
            "locking",
            "locked"
        ],
        "inflexion": "lock, locks, locking, locked",
        "workspace_id": "anglais",
        "inflexions": [
            "lock",
            "locks",
            "locking",
            "locked"
        ],
        "isPareto": true,
        "rank": 1045,
        "lang": "en-fr",
        "id": "Yn7uiMqUV5IibhsTlU2x"
    },
    {
        "term": "award",
        "infexions": [
            "award",
            "awards"
        ],
        "inflexions": [
            "award",
            "awards"
        ],
        "rank": 1046,
        "inflexion": "award, awards",
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "id": "xl94iodAu3T4UTMRSqST"
    },
    {
        "model": "lang-en",
        "term": "straight",
        "workspace_id": "anglais",
        "wt": "j",
        "inflexions": [
            "straight",
            "straighter",
            "straightest"
        ],
        "lang": "en-fr",
        "rank": 1047,
        "inflexion": "straight, straighter, straightest",
        "infexions": [
            "straight",
            "straighter",
            "straightest"
        ],
        "isPareto": true,
        "id": "JuLJ53PzRhrQuFSMF13i"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "unbelievable"
        ],
        "inflexions": [
            "unbelievable"
        ],
        "rank": 1048,
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "unbelievable",
        "model": "lang-en",
        "wt": "j",
        "inflexion": "unbelievable",
        "id": "YziRRZ3pn9BPf6MRWMql"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "term": "court",
        "inflexions": [
            "court",
            "courts"
        ],
        "workspace_id": "anglais",
        "inflexion": "court, courts",
        "infexions": [
            "court",
            "courts"
        ],
        "rank": 1049,
        "id": "3FO2AdULbsyC6NOPgyWU"
    },
    {
        "infexions": [
            "experience",
            "experiences"
        ],
        "term": "experience",
        "model": "lang-en",
        "wt": "n",
        "rank": 1050,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "experience, experiences",
        "isPareto": true,
        "inflexions": [
            "experience",
            "experiences"
        ],
        "id": "C4z7kJxt4SJNG1i5mzfu"
    },
    {
        "inflexion": "final",
        "term": "final",
        "isPareto": true,
        "infexions": [
            "final"
        ],
        "rank": 1051,
        "wt": "j",
        "workspace_id": "anglais",
        "inflexions": [
            "final"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "id": "9jqXEY7eyMNJNB61JTlo"
    },
    {
        "rank": 1052,
        "infexions": [
            "large",
            "larger",
            "largest"
        ],
        "inflexions": [
            "large",
            "larger",
            "largest"
        ],
        "isPareto": true,
        "term": "large",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "large, larger, largest",
        "wt": "j",
        "workspace_id": "anglais",
        "id": "mFkqsyEceudn8ZAMGLzt"
    },
    {
        "rank": 1053,
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "salad",
        "lang": "en-fr",
        "infexions": [
            "salad",
            "salads"
        ],
        "inflexion": "salad, salads",
        "inflexions": [
            "salad",
            "salads"
        ],
        "wt": "n",
        "id": "rfa7KdtK5vbA2vu0TclE"
    },
    {
        "wt": "v",
        "inflexions": [
            "belong",
            "belongs",
            "belonging",
            "belonged"
        ],
        "infexions": [
            "belong",
            "belongs",
            "belonging",
            "belonged"
        ],
        "isPareto": true,
        "term": "belong",
        "inflexion": "belong, belongs, belonging, belonged",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 1054,
        "model": "lang-en",
        "id": "0ENpAkcKG4mN7kPaAjK3"
    },
    {
        "infexions": [
            "fuck",
            "fucks"
        ],
        "inflexions": [
            "fuck",
            "fucks"
        ],
        "isPareto": true,
        "model": "lang-en",
        "wt": "n",
        "term": "fuck",
        "inflexion": "fuck, fucks",
        "workspace_id": "anglais",
        "rank": 1055,
        "lang": "en-fr",
        "id": "biQABUS2OdN1taHd7heO"
    },
    {
        "rank": 1056,
        "workspace_id": "anglais",
        "term": "station",
        "inflexion": "station, stations",
        "wt": "n",
        "inflexions": [
            "station",
            "stations"
        ],
        "infexions": [
            "station",
            "stations"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "id": "I9QvCM1j7ICsdQ5Hke23"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "area",
            "areas"
        ],
        "isPareto": true,
        "inflexion": "area, areas",
        "rank": 1057,
        "wt": "n",
        "term": "area",
        "workspace_id": "anglais",
        "inflexions": [
            "area",
            "areas"
        ],
        "id": "dCIjxth27GcsdswyHiaf"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "v",
        "term": "vote",
        "rank": 1058,
        "inflexion": "vote, votes, voting, voted",
        "inflexions": [
            "vote",
            "votes",
            "voting",
            "voted"
        ],
        "infexions": [
            "vote",
            "votes",
            "voting",
            "voted"
        ],
        "id": "CYQKs4L0SD5DRPHTLlt0"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "crime, crimes",
        "term": "crime",
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "crime",
            "crimes"
        ],
        "rank": 1059,
        "inflexions": [
            "crime",
            "crimes"
        ],
        "id": "QHmo5hvVD7oDJWV63479"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "meat",
            "meats"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "meat",
            "meats"
        ],
        "rank": 1060,
        "term": "meat",
        "lang": "en-fr",
        "inflexion": "meat, meats",
        "id": "7kHine54g9ERFLTZxW3y"
    },
    {
        "term": "romantic",
        "rank": 1061,
        "inflexion": "romantic",
        "isPareto": true,
        "wt": "j",
        "model": "lang-en",
        "infexions": [
            "romantic"
        ],
        "inflexions": [
            "romantic"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "wDaUOiYY5Mlmx1q6qvJZ"
    },
    {
        "term": "treat",
        "infexions": [
            "treat",
            "treats",
            "treating",
            "treated"
        ],
        "inflexions": [
            "treat",
            "treats",
            "treating",
            "treated"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "treat, treats, treating, treated",
        "rank": 1062,
        "wt": "v",
        "workspace_id": "anglais",
        "id": "E4nO4jPzSMaZxlPr6veF"
    },
    {
        "term": "forward",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "forward"
        ],
        "lang": "en-fr",
        "inflexions": [
            "forward"
        ],
        "rank": 1063,
        "wt": "r",
        "model": "lang-en",
        "inflexion": "forward",
        "id": "JBOHr9pTQ2UdbOMTR5mY"
    },
    {
        "inflexions": [
            "glasses"
        ],
        "inflexion": "glasses",
        "term": "glasses",
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "rank": 1064,
        "workspace_id": "anglais",
        "infexions": [
            "glasses"
        ],
        "wt": "n",
        "id": "SBZflZUWbFE5XtTTEp0y"
    },
    {
        "infexions": [
            "lie",
            "lies"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "lie, lies",
        "term": "lie",
        "lang": "en-fr",
        "inflexions": [
            "lie",
            "lies"
        ],
        "wt": "n",
        "rank": 1065,
        "workspace_id": "anglais",
        "id": "nQLmnDJRxDUPo2aw5Rak"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "taste, tastes, tasting, tasted",
        "isPareto": true,
        "wt": "v",
        "infexions": [
            "taste",
            "tastes",
            "tasting",
            "tasted"
        ],
        "lang": "en-fr",
        "inflexions": [
            "taste",
            "tastes",
            "tasting",
            "tasted"
        ],
        "term": "taste",
        "model": "lang-en",
        "rank": 1066,
        "id": "Otl6HhpAl5s8DFIMob9c"
    },
    {
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "weight, weights",
        "infexions": [
            "weight",
            "weights"
        ],
        "rank": 1067,
        "lang": "en-fr",
        "term": "weight",
        "inflexions": [
            "weight",
            "weights"
        ],
        "id": "HomSIqR6MoF7yeASthHX"
    },
    {
        "infexions": [
            "mail",
            "mails"
        ],
        "inflexion": "mail, mails",
        "isPareto": true,
        "rank": 1068,
        "wt": "n",
        "workspace_id": "anglais",
        "inflexions": [
            "mail",
            "mails"
        ],
        "model": "lang-en",
        "term": "mail",
        "lang": "en-fr",
        "id": "2fqBMi22ci1IjEY08jXq"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "cab, cabs",
        "term": "cab",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1069,
        "inflexions": [
            "cab",
            "cabs"
        ],
        "wt": "n",
        "infexions": [
            "cab",
            "cabs"
        ],
        "id": "DhJBRbFLrhHYMAZvWngm"
    },
    {
        "inflexions": [
            "two hundred"
        ],
        "isPareto": true,
        "model": "lang-en",
        "term": "two hundred",
        "lang": "en-fr",
        "inflexion": "two hundred",
        "rank": 1070,
        "workspace_id": "anglais",
        "wt": "m",
        "infexions": [
            "two hundred"
        ],
        "id": "xm5bOFDUHzlTgN3BAOPT"
    },
    {
        "wt": "j",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "boring"
        ],
        "inflexion": "boring",
        "infexions": [
            "boring"
        ],
        "lang": "en-fr",
        "term": "boring",
        "workspace_id": "anglais",
        "rank": 1071,
        "id": "PYdR3uHtGyTET9nOw9Fh"
    },
    {
        "wt": "n",
        "inflexion": "information",
        "rank": 1072,
        "inflexions": [
            "information"
        ],
        "model": "lang-en",
        "infexions": [
            "information"
        ],
        "term": "information",
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "id": "hnRITguePPBCVEDGOXm1"
    },
    {
        "infexions": [
            "total"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 1073,
        "term": "total",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "total"
        ],
        "inflexion": "total",
        "wt": "j",
        "id": "TKZAIOGtxI5fCTbWFwtQ"
    },
    {
        "isPareto": true,
        "inflexions": [
            "channel",
            "channels"
        ],
        "rank": 1074,
        "term": "channel",
        "model": "lang-en",
        "inflexion": "channel, channels",
        "lang": "en-fr",
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "channel",
            "channels"
        ],
        "id": "ya8c3aOkopICAIYz8Jrb"
    },
    {
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "inflexions": [
            "page",
            "pages"
        ],
        "lang": "en-fr",
        "infexions": [
            "page",
            "pages"
        ],
        "isPareto": true,
        "rank": 1075,
        "inflexion": "page, pages",
        "term": "page",
        "id": "E5r0HJ2SRo6ad307W07a"
    },
    {
        "isPareto": true,
        "model": "lang-en",
        "wt": "r",
        "term": "suddenly",
        "rank": 1076,
        "inflexions": [
            "suddenly"
        ],
        "inflexion": "suddenly",
        "infexions": [
            "suddenly"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "id": "PAHXqY8Msi4lHdoaHK8g"
    },
    {
        "isPareto": true,
        "inflexion": "sake, sakes",
        "rank": 1077,
        "infexions": [
            "sake",
            "sakes"
        ],
        "term": "sake",
        "wt": "n",
        "inflexions": [
            "sake",
            "sakes"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "yVlNtDg7VxVjuJaYpypT"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "Thanksgiving",
            "thanksgiving",
            "thanksgivings"
        ],
        "rank": 1078,
        "model": "lang-en",
        "wt": "K",
        "workspace_id": "anglais",
        "infexions": [
            "Thanksgiving",
            "thanksgiving",
            "thanksgivings"
        ],
        "isPareto": true,
        "inflexion": "Thanksgiving, thanksgiving, thanksgivings",
        "term": "Thanksgiving",
        "id": "Ovo4yv8XzH7gaTaI3Ty1"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "term": "private",
        "infexions": [
            "private"
        ],
        "inflexion": "private",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1079,
        "wt": "j",
        "inflexions": [
            "private"
        ],
        "id": "5JcQsj0TaqKfZJt67V1g"
    },
    {
        "inflexions": [
            "French"
        ],
        "lang": "en-fr",
        "infexions": [
            "French"
        ],
        "inflexion": "French",
        "workspace_id": "anglais",
        "term": "French",
        "wt": "K",
        "rank": 1080,
        "model": "lang-en",
        "isPareto": true,
        "id": "xRhOpon1LDtkcinVtmiN"
    },
    {
        "wt": "n",
        "model": "lang-en",
        "rank": 1081,
        "workspace_id": "anglais",
        "infexions": [
            "winner",
            "winners"
        ],
        "lang": "en-fr",
        "inflexions": [
            "winner",
            "winners"
        ],
        "term": "winner",
        "inflexion": "winner, winners",
        "isPareto": true,
        "id": "AMgJN6S9GnUrw84JvMus"
    },
    {
        "infexions": [
            "past",
            "pasts"
        ],
        "inflexion": "past, pasts",
        "term": "past",
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "past",
            "pasts"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 1082,
        "id": "HnkUVMhb26bCnQJIPRLE"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "pen, pens",
        "lang": "en-fr",
        "infexions": [
            "pen",
            "pens"
        ],
        "rank": 1083,
        "workspace_id": "anglais",
        "inflexions": [
            "pen",
            "pens"
        ],
        "wt": "n",
        "term": "pen",
        "id": "caohfjAUzl4BNn7GIrmh"
    },
    {
        "wt": "r",
        "term": "twice",
        "inflexion": "twice",
        "infexions": [
            "twice"
        ],
        "rank": 1084,
        "lang": "en-fr",
        "inflexions": [
            "twice"
        ],
        "model": "lang-en",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "3l4d7dSvGA3VN5LRlIMd"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "rank": 1085,
        "infexions": [
            "cousin",
            "cousins"
        ],
        "inflexion": "cousin, cousins",
        "inflexions": [
            "cousin",
            "cousins"
        ],
        "term": "cousin",
        "isPareto": true,
        "id": "SscVrflGsLnmZRaExDij"
    },
    {
        "workspace_id": "anglais",
        "term": "jealous",
        "isPareto": true,
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "jealous"
        ],
        "wt": "j",
        "inflexions": [
            "jealous"
        ],
        "rank": 1086,
        "inflexion": "jealous",
        "id": "hBPuBjYPVTdduKxISZe5"
    },
    {
        "model": "lang-en",
        "rank": 1087,
        "wt": "v",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "mess, messes, messing, messed",
        "infexions": [
            "mess",
            "messes",
            "messing",
            "messed"
        ],
        "inflexions": [
            "mess",
            "messes",
            "messing",
            "messed"
        ],
        "term": "mess",
        "id": "bCPn3BU6pesbGY5Ar0vF"
    },
    {
        "rank": 1088,
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "term": "planet",
        "isPareto": true,
        "infexions": [
            "planet",
            "planets"
        ],
        "inflexion": "planet, planets",
        "workspace_id": "anglais",
        "inflexions": [
            "planet",
            "planets"
        ],
        "id": "knQAWkAMucixzSnrOtMH"
    },
    {
        "rank": 1089,
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "wt": "j",
        "term": "scary",
        "inflexion": "scary, scarier, scariest",
        "workspace_id": "anglais",
        "infexions": [
            "scary",
            "scarier",
            "scariest"
        ],
        "inflexions": [
            "scary",
            "scarier",
            "scariest"
        ],
        "id": "4d5LvrOyVjVOl79nSx47"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "universe",
            "universes"
        ],
        "isPareto": true,
        "infexions": [
            "universe",
            "universes"
        ],
        "wt": "n",
        "rank": 1090,
        "inflexion": "universe, universes",
        "workspace_id": "anglais",
        "term": "universe",
        "model": "lang-en",
        "id": "aZhaYZtFz3q2D7Fu7f0n"
    },
    {
        "isPareto": true,
        "term": "upstairs",
        "model": "lang-en",
        "inflexion": "upstairs",
        "infexions": [
            "upstairs"
        ],
        "inflexions": [
            "upstairs"
        ],
        "lang": "en-fr",
        "wt": "r",
        "workspace_id": "anglais",
        "rank": 1091,
        "id": "AsB6qvYO9aDfaLVmqPj9"
    },
    {
        "term": "genius",
        "wt": "n",
        "inflexions": [
            "genius",
            "geniuses",
            "genii"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "genius, geniuses, genii",
        "infexions": [
            "genius",
            "geniuses",
            "genii"
        ],
        "lang": "en-fr",
        "rank": 1092,
        "workspace_id": "anglais",
        "id": "G3PNc4dXU8Aq0UJMT2dO"
    },
    {
        "model": "lang-en",
        "term": "dangerous",
        "infexions": [
            "dangerous"
        ],
        "wt": "j",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1093,
        "inflexions": [
            "dangerous"
        ],
        "inflexion": "dangerous",
        "workspace_id": "anglais",
        "id": "xNP8pFfcYpnlW2SQIOVt"
    },
    {
        "rank": 1094,
        "wt": "j",
        "inflexions": [
            "nuts"
        ],
        "infexions": [
            "nuts"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "nuts",
        "term": "nuts",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "nrX125sEEJb6zeuDMzKH"
    },
    {
        "inflexions": [
            "ourselves"
        ],
        "model": "lang-en",
        "infexions": [
            "ourselves"
        ],
        "lang": "en-fr",
        "wt": "fw",
        "isPareto": true,
        "term": "ourselves",
        "inflexion": "ourselves",
        "workspace_id": "anglais",
        "rank": 1095,
        "id": "pH3z4kW3wJfyvfIOGsJL"
    },
    {
        "isPareto": true,
        "term": "race",
        "inflexions": [
            "race",
            "races"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexion": "race, races",
        "rank": 1096,
        "infexions": [
            "race",
            "races"
        ],
        "model": "lang-en",
        "wt": "n",
        "id": "NRm716zvfNGf9tx9nCzc"
    },
    {
        "rank": 1097,
        "model": "lang-en",
        "inflexion": "suggest, suggests, suggesting, suggested",
        "term": "suggest",
        "wt": "v",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "suggest",
            "suggests",
            "suggesting",
            "suggested"
        ],
        "infexions": [
            "suggest",
            "suggests",
            "suggesting",
            "suggested"
        ],
        "id": "aHLVGpjSlMjYrn4XQxmJ"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "turn",
            "turns"
        ],
        "inflexions": [
            "turn",
            "turns"
        ],
        "rank": 1098,
        "term": "turn",
        "isPareto": true,
        "inflexion": "turn, turns",
        "id": "VdCutauUnJOPqFR0JOJQ"
    },
    {
        "rank": 1099,
        "inflexion": "sea, seas",
        "isPareto": true,
        "wt": "n",
        "inflexions": [
            "sea",
            "seas"
        ],
        "model": "lang-en",
        "infexions": [
            "sea",
            "seas"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "sea",
        "id": "DQt6A4kvjHfWO8rrAEBU"
    },
    {
        "term": "officer",
        "infexions": [
            "officer",
            "officers"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1100,
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "officer",
            "officers"
        ],
        "inflexion": "officer, officers",
        "id": "1hdBWmsyBHfGG4lgIOGO"
    },
    {
        "inflexions": [
            "meal",
            "meals"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "meal",
            "meals"
        ],
        "inflexion": "meal, meals",
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "term": "meal",
        "wt": "n",
        "rank": 1101,
        "id": "nATxIVo5QS4Cb2Np2ut1"
    },
    {
        "inflexion": "popular",
        "model": "lang-en",
        "term": "popular",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "popular"
        ],
        "rank": 1102,
        "lang": "en-fr",
        "inflexions": [
            "popular"
        ],
        "wt": "j",
        "id": "SZR1sbMaFrRiUldAfwye"
    },
    {
        "model": "lang-en",
        "term": "report",
        "rank": 1103,
        "infexions": [
            "report",
            "reports"
        ],
        "isPareto": true,
        "inflexion": "report, reports",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "report",
            "reports"
        ],
        "id": "9TLN0URS5OKzfPUzEbzm"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "wt": "u",
        "inflexion": "welcome",
        "term": "welcome",
        "infexions": [
            "welcome"
        ],
        "model": "lang-en",
        "rank": 1104,
        "inflexions": [
            "welcome"
        ],
        "id": "Em8SpSFPeg1NBH1qjpMS"
    },
    {
        "inflexions": [
            "flight",
            "flights"
        ],
        "lang": "en-fr",
        "inflexion": "flight, flights",
        "term": "flight",
        "workspace_id": "anglais",
        "rank": 1105,
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "flight",
            "flights"
        ],
        "id": "HmlrDumZcVD3E4STd7AJ"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "term": "change",
        "infexions": [
            "change",
            "changes"
        ],
        "rank": 1106,
        "lang": "en-fr",
        "inflexion": "change, changes",
        "wt": "n",
        "inflexions": [
            "change",
            "changes"
        ],
        "workspace_id": "anglais",
        "id": "CM3PZOF5juQSEEYXbAvb"
    },
    {
        "rank": 1107,
        "infexions": [
            "driver",
            "drivers"
        ],
        "term": "driver",
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "driver",
            "drivers"
        ],
        "inflexion": "driver, drivers",
        "wt": "n",
        "id": "o6MvY4pjxei3s2KxQeHL"
    },
    {
        "isPareto": true,
        "term": "wheel",
        "infexions": [
            "wheel",
            "wheels"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "rank": 1108,
        "inflexion": "wheel, wheels",
        "inflexions": [
            "wheel",
            "wheels"
        ],
        "lang": "en-fr",
        "id": "UI2bEeNPHuw8lZ86llOM"
    },
    {
        "inflexion": "dump, dumps, dumping, dumped",
        "model": "lang-en",
        "wt": "v",
        "infexions": [
            "dump",
            "dumps",
            "dumping",
            "dumped"
        ],
        "lang": "en-fr",
        "inflexions": [
            "dump",
            "dumps",
            "dumping",
            "dumped"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "rank": 1109,
        "term": "dump",
        "id": "qJjWhwvfjibXmxnimyRH"
    },
    {
        "infexions": [
            "five hundred"
        ],
        "rank": 1110,
        "inflexion": "five hundred",
        "term": "five hundred",
        "lang": "en-fr",
        "wt": "m",
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "five hundred"
        ],
        "id": "jLRBbmQuBADQV4kdv6bJ"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1111,
        "workspace_id": "anglais",
        "inflexions": [
            "member",
            "members"
        ],
        "wt": "n",
        "infexions": [
            "member",
            "members"
        ],
        "term": "member",
        "inflexion": "member, members",
        "model": "lang-en",
        "id": "XnpB0MZDp6Wdvu9boJI3"
    },
    {
        "rank": 1112,
        "inflexion": "add, adds, adding, added",
        "infexions": [
            "add",
            "adds",
            "adding",
            "added"
        ],
        "lang": "en-fr",
        "wt": "v",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "add",
            "adds",
            "adding",
            "added"
        ],
        "workspace_id": "anglais",
        "term": "add",
        "id": "2wRBAr9Zw3f5jq0AceRG"
    },
    {
        "inflexion": "confuse, confuses, confusing, confused",
        "lang": "en-fr",
        "infexions": [
            "confuse",
            "confuses",
            "confusing",
            "confused"
        ],
        "inflexions": [
            "confuse",
            "confuses",
            "confusing",
            "confused"
        ],
        "term": "confuse",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1113,
        "wt": "v",
        "isPareto": true,
        "id": "InNGOhV6glOdGlB5bmiG"
    },
    {
        "term": "form",
        "inflexions": [
            "form",
            "forms"
        ],
        "infexions": [
            "form",
            "forms"
        ],
        "model": "lang-en",
        "rank": 1114,
        "inflexion": "form, forms",
        "wt": "n",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "id": "BGAB0rDowtsO6PEOIAiM"
    },
    {
        "inflexions": [
            "government",
            "governments"
        ],
        "inflexion": "government, governments",
        "isPareto": true,
        "term": "government",
        "rank": 1115,
        "infexions": [
            "government",
            "governments"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "s1fMfNPNTf9L9i9w4xoV"
    },
    {
        "lang": "en-fr",
        "term": "Sunday",
        "rank": 1116,
        "workspace_id": "anglais",
        "inflexions": [
            "Sunday",
            "Sundays"
        ],
        "wt": "K",
        "infexions": [
            "Sunday",
            "Sundays"
        ],
        "inflexion": "Sunday, Sundays",
        "isPareto": true,
        "model": "lang-en",
        "id": "DfaDouQrDrdtOyb6jWBD"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "order",
            "orders"
        ],
        "term": "order",
        "inflexion": "order, orders",
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "order",
            "orders"
        ],
        "rank": 1117,
        "id": "mTCdEqC6VyWtyj2A1809"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "wt": "v",
        "inflexions": [
            "continue",
            "continues",
            "continuing",
            "continued"
        ],
        "rank": 1118,
        "workspace_id": "anglais",
        "infexions": [
            "continue",
            "continues",
            "continuing",
            "continued"
        ],
        "term": "continue",
        "inflexion": "continue, continues, continuing, continued",
        "id": "S5kcThyukczDQu5szGpi"
    },
    {
        "model": "lang-en",
        "infexions": [
            "event",
            "events"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "event",
        "inflexions": [
            "event",
            "events"
        ],
        "isPareto": true,
        "rank": 1119,
        "inflexion": "event, events",
        "wt": "n",
        "id": "2z16CfgrO5nnhidBd070"
    },
    {
        "infexions": [
            "quiet",
            "quieter",
            "quietest"
        ],
        "inflexion": "quiet, quieter, quietest",
        "workspace_id": "anglais",
        "rank": 1120,
        "inflexions": [
            "quiet",
            "quieter",
            "quietest"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "j",
        "isPareto": true,
        "term": "quiet",
        "id": "S9vr6vop8ACBJrAZBnqi"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 1121,
        "term": "low",
        "inflexions": [
            "low",
            "lower",
            "lowest"
        ],
        "inflexion": "low, lower, lowest",
        "infexions": [
            "low",
            "lower",
            "lowest"
        ],
        "model": "lang-en",
        "wt": "j",
        "id": "VkBp91Uj0XvZhm2Pt5sY"
    },
    {
        "model": "lang-en",
        "term": "color",
        "lang": "en-fr",
        "infexions": [
            "color",
            "colors"
        ],
        "inflexion": "color, colors",
        "inflexions": [
            "color",
            "colors"
        ],
        "rank": 1122,
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "c0DgWdQ6iiIMUMtxNftv"
    },
    {
        "inflexions": [
            "Jewish",
            "jew"
        ],
        "wt": "K",
        "rank": 1123,
        "workspace_id": "anglais",
        "infexions": [
            "Jewish",
            "jew"
        ],
        "model": "lang-en",
        "term": "Jewish",
        "lang": "en-fr",
        "inflexion": "Jewish, jew",
        "isPareto": true,
        "id": "sfDtPj73DdQqJUYcNrwh"
    },
    {
        "inflexion": "",
        "wt": "",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "term": "",
        "inflexions": [
            ""
        ],
        "workspace_id": "anglais",
        "infexions": [
            ""
        ],
        "rank": 1124,
        "id": "5i5XaD1s4hoeOyTgf8vV"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "breast",
            "breasts"
        ],
        "wt": "n",
        "rank": 1125,
        "lang": "en-fr",
        "inflexion": "breast, breasts",
        "infexions": [
            "breast",
            "breasts"
        ],
        "term": "breast",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "Onjj6ba5SZDU6f0nukGW"
    },
    {
        "term": "bank",
        "model": "lang-en",
        "wt": "n",
        "inflexion": "bank, banks",
        "lang": "en-fr",
        "infexions": [
            "bank",
            "banks"
        ],
        "isPareto": true,
        "inflexions": [
            "bank",
            "banks"
        ],
        "workspace_id": "anglais",
        "rank": 1126,
        "id": "YkvHifFDQXCN96jbWeTv"
    },
    {
        "isPareto": true,
        "rank": 1127,
        "model": "lang-en",
        "infexions": [
            "parking",
            "parkings"
        ],
        "inflexion": "parking, parkings",
        "wt": "n",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "parking",
            "parkings"
        ],
        "term": "parking",
        "id": "CUKSaAYyWaPXMz7QDuey"
    },
    {
        "isPareto": true,
        "inflexions": [
            "ride",
            "rides"
        ],
        "infexions": [
            "ride",
            "rides"
        ],
        "workspace_id": "anglais",
        "rank": 1128,
        "wt": "n",
        "term": "ride",
        "lang": "en-fr",
        "inflexion": "ride, rides",
        "model": "lang-en",
        "id": "77f1yvdGEfhkuKHdGJtt"
    },
    {
        "isPareto": true,
        "inflexions": [
            "wild",
            "wilder",
            "wildest"
        ],
        "term": "wild",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "j",
        "model": "lang-en",
        "infexions": [
            "wild",
            "wilder",
            "wildest"
        ],
        "rank": 1129,
        "inflexion": "wild, wilder, wildest",
        "id": "nTXdEjZb2JUqFjALRi08"
    },
    {
        "infexions": [
            "turkey",
            "turkeys"
        ],
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "turkey, turkeys",
        "rank": 1130,
        "model": "lang-en",
        "term": "turkey",
        "inflexions": [
            "turkey",
            "turkeys"
        ],
        "isPareto": true,
        "id": "8k607bHL8C4qrJn8yVxL"
    },
    {
        "wt": "u",
        "workspace_id": "anglais",
        "infexions": [
            "blah"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "blah",
        "term": "blah",
        "rank": 1131,
        "lang": "en-fr",
        "inflexions": [
            "blah"
        ],
        "id": "A6mrCSR8adgkokvsa0lx"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "thought",
            "thoughts"
        ],
        "infexions": [
            "thought",
            "thoughts"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1132,
        "inflexion": "thought, thoughts",
        "term": "thought",
        "id": "FIT9TH617nfdrjLqhJSR"
    },
    {
        "isPareto": true,
        "rank": 1133,
        "model": "lang-en",
        "inflexion": "famous",
        "infexions": [
            "famous"
        ],
        "term": "famous",
        "wt": "j",
        "workspace_id": "anglais",
        "inflexions": [
            "famous"
        ],
        "lang": "en-fr",
        "id": "2yMLapee8U1YMCE1oQqG"
    },
    {
        "infexions": [
            "gold",
            "golds"
        ],
        "rank": 1134,
        "workspace_id": "anglais",
        "inflexion": "gold, golds",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "gold",
            "golds"
        ],
        "isPareto": true,
        "wt": "n",
        "term": "gold",
        "id": "PJo6uz98Mmj5h0kSPbOD"
    },
    {
        "rank": 1135,
        "infexions": [
            "pound",
            "pounds"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "pound",
            "pounds"
        ],
        "term": "pound",
        "model": "lang-en",
        "inflexion": "pound, pounds",
        "lang": "en-fr",
        "wt": "n",
        "id": "YU8IfT0vOPVKosTbCr6x"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "term": "skin",
        "inflexions": [
            "skin",
            "skins"
        ],
        "rank": 1136,
        "inflexion": "skin, skins",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "skin",
            "skins"
        ],
        "lang": "en-fr",
        "id": "SLJShBJmSoHymRstRW5p"
    },
    {
        "infexions": [
            "one hundred"
        ],
        "wt": "m",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "one hundred",
        "isPareto": true,
        "model": "lang-en",
        "rank": 1137,
        "inflexion": "one hundred",
        "inflexions": [
            "one hundred"
        ],
        "id": "RxWZdFFvP3DEA6FYpWEt"
    },
    {
        "inflexions": [
            "rat",
            "rats"
        ],
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "rat",
            "rats"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "term": "rat",
        "rank": 1138,
        "inflexion": "rat, rats",
        "id": "ttyZwLi6V7pDYoYPpmcd"
    },
    {
        "term": "switch",
        "infexions": [
            "switch",
            "switches",
            "switching",
            "switched"
        ],
        "workspace_id": "anglais",
        "wt": "v",
        "inflexion": "switch, switches, switching, switched",
        "model": "lang-en",
        "lang": "en-fr",
        "rank": 1139,
        "inflexions": [
            "switch",
            "switches",
            "switching",
            "switched"
        ],
        "isPareto": true,
        "id": "d6a3CwgBdgec0WCgzqgb"
    },
    {
        "isPareto": true,
        "rank": 1140,
        "infexions": [
            "tie",
            "ties"
        ],
        "lang": "en-fr",
        "inflexion": "tie, ties",
        "inflexions": [
            "tie",
            "ties"
        ],
        "term": "tie",
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "id": "xXnih10XpRGBYpiFgBM7"
    },
    {
        "lang": "en-fr",
        "rank": 1141,
        "infexions": [
            "career",
            "careers"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexion": "career, careers",
        "wt": "n",
        "inflexions": [
            "career",
            "careers"
        ],
        "term": "career",
        "isPareto": true,
        "id": "MapfCJyLMsTVDnQyZ5A3"
    },
    {
        "wt": "n",
        "isPareto": true,
        "model": "lang-en",
        "rank": 1142,
        "inflexions": [
            "juice",
            "juices"
        ],
        "inflexion": "juice, juices",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "juice",
        "infexions": [
            "juice",
            "juices"
        ],
        "id": "bHnCM38hvpKbeLMCp1Yb"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "fw",
        "lang": "en-fr",
        "isPareto": true,
        "term": "like",
        "inflexions": [
            "like"
        ],
        "rank": 1143,
        "infexions": [
            "like"
        ],
        "inflexion": "like",
        "id": "UqzBal8uHNszMX0GscbL"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "protect",
            "protects",
            "protecting",
            "protected"
        ],
        "wt": "v",
        "isPareto": true,
        "infexions": [
            "protect",
            "protects",
            "protecting",
            "protected"
        ],
        "model": "lang-en",
        "term": "protect",
        "rank": 1144,
        "workspace_id": "anglais",
        "inflexion": "protect, protects, protecting, protected",
        "id": "T6vm8CsweL3XhK6m9mCB"
    },
    {
        "inflexion": "shame, shames",
        "isPareto": true,
        "wt": "n",
        "workspace_id": "anglais",
        "inflexions": [
            "shame",
            "shames"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "term": "shame",
        "infexions": [
            "shame",
            "shames"
        ],
        "rank": 1145,
        "id": "E8t1edxNrTnINjnTcEmp"
    },
    {
        "inflexion": "bottom, bottoms",
        "workspace_id": "anglais",
        "wt": "n",
        "term": "bottom",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "bottom",
            "bottoms"
        ],
        "inflexions": [
            "bottom",
            "bottoms"
        ],
        "rank": 1146,
        "id": "RF9HFfqeC1lbgiEerXq9"
    },
    {
        "wt": "v",
        "inflexions": [
            "respect",
            "respects",
            "respecting",
            "respected"
        ],
        "term": "respect",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1147,
        "inflexion": "respect, respects, respecting, respected",
        "isPareto": true,
        "infexions": [
            "respect",
            "respects",
            "respecting",
            "respected"
        ],
        "lang": "en-fr",
        "id": "TDgpIMuUQsMD2exJRkYy"
    },
    {
        "isPareto": true,
        "inflexions": [
            "underwear",
            "underwears"
        ],
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "underwear",
            "underwears"
        ],
        "inflexion": "underwear, underwears",
        "rank": 1148,
        "term": "underwear",
        "model": "lang-en",
        "id": "rPIXOH2o9742RXwjK0XI"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "Indian"
        ],
        "inflexions": [
            "Indian"
        ],
        "rank": 1149,
        "wt": "K",
        "term": "Indian",
        "inflexion": "Indian",
        "model": "lang-en",
        "id": "zBWDk61Izp3lfaLev8a7"
    },
    {
        "inflexions": [
            "closet",
            "closets"
        ],
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "infexions": [
            "closet",
            "closets"
        ],
        "term": "closet",
        "rank": 1150,
        "isPareto": true,
        "inflexion": "closet, closets",
        "lang": "en-fr",
        "id": "VSm7GHbrzwDAt6AO8PI4"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "meeting",
            "meetings"
        ],
        "model": "lang-en",
        "term": "meeting",
        "rank": 1151,
        "wt": "n",
        "inflexions": [
            "meeting",
            "meetings"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "meeting, meetings",
        "id": "dx53loxrjb9c9dtfDNUN"
    },
    {
        "infexions": [
            "sun",
            "suns"
        ],
        "workspace_id": "anglais",
        "term": "sun",
        "inflexions": [
            "sun",
            "suns"
        ],
        "rank": 1152,
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "inflexion": "sun, suns",
        "isPareto": true,
        "id": "3BDcOlD5ruknU6uQhb8R"
    },
    {
        "term": "afford",
        "wt": "v",
        "inflexion": "afford, affords, affording, afforded",
        "infexions": [
            "afford",
            "affords",
            "affording",
            "afforded"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "afford",
            "affords",
            "affording",
            "afforded"
        ],
        "isPareto": true,
        "rank": 1153,
        "model": "lang-en",
        "id": "Uw3y6m5pw988NakNnuub"
    },
    {
        "rank": 1154,
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "bald, balder, baldest",
        "infexions": [
            "bald",
            "balder",
            "baldest"
        ],
        "term": "bald",
        "lang": "en-fr",
        "wt": "j",
        "inflexions": [
            "bald",
            "balder",
            "baldest"
        ],
        "id": "0JNwAKCn2IoX8M59NZzI"
    },
    {
        "inflexion": "engage, engages, engaging, engaged",
        "isPareto": true,
        "infexions": [
            "engage",
            "engages",
            "engaging",
            "engaged"
        ],
        "workspace_id": "anglais",
        "rank": 1155,
        "lang": "en-fr",
        "wt": "v",
        "inflexions": [
            "engage",
            "engages",
            "engaging",
            "engaged"
        ],
        "term": "engage",
        "model": "lang-en",
        "id": "IE3URqeO4YPZAFpsJIxM"
    },
    {
        "term": "plant",
        "isPareto": true,
        "inflexion": "plant, plants",
        "infexions": [
            "plant",
            "plants"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "plant",
            "plants"
        ],
        "rank": 1156,
        "workspace_id": "anglais",
        "id": "10GSvuxdGsHfdaUw5Ptb"
    },
    {
        "model": "lang-en",
        "rank": 1157,
        "lang": "en-fr",
        "inflexions": [
            "towel",
            "towels"
        ],
        "term": "towel",
        "inflexion": "towel, towels",
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "towel",
            "towels"
        ],
        "isPareto": true,
        "id": "abMN0kXqrjzfAqNyl6jW"
    },
    {
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "mall",
            "malls"
        ],
        "rank": 1158,
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "mall",
            "malls"
        ],
        "lang": "en-fr",
        "term": "mall",
        "inflexion": "mall, malls",
        "id": "b4HgKRnIjE2jVEVjc7Qo"
    },
    {
        "lang": "en-fr",
        "term": "regular",
        "rank": 1159,
        "infexions": [
            "regular"
        ],
        "wt": "j",
        "workspace_id": "anglais",
        "inflexion": "regular",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "regular"
        ],
        "id": "Srh1Km8UDlgtaEvXJh1v"
    },
    {
        "inflexions": [
            "bone",
            "bones"
        ],
        "wt": "n",
        "rank": 1160,
        "term": "bone",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "bone",
            "bones"
        ],
        "lang": "en-fr",
        "inflexion": "bone, bones",
        "model": "lang-en",
        "id": "iSyIJGBwJWWVQHasjqN6"
    },
    {
        "infexions": [
            "dig",
            "digs",
            "digging",
            "dug"
        ],
        "inflexions": [
            "dig",
            "digs",
            "digging",
            "dug"
        ],
        "model": "lang-en",
        "rank": 1161,
        "lang": "en-fr",
        "inflexion": "dig, digs, digging, dug",
        "wt": "v",
        "term": "dig",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "JLX2TWtCk2AcPLEtTDeH"
    },
    {
        "isPareto": true,
        "rank": 1162,
        "term": "fear",
        "infexions": [
            "fear",
            "fears"
        ],
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "fear, fears",
        "model": "lang-en",
        "inflexions": [
            "fear",
            "fears"
        ],
        "workspace_id": "anglais",
        "id": "GbV84ttpqveLbiMgYsZL"
    },
    {
        "rank": 1163,
        "model": "lang-en",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "cancer",
            "cancers"
        ],
        "infexions": [
            "cancer",
            "cancers"
        ],
        "term": "cancer",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "cancer, cancers",
        "id": "Nq62jXlvZJCOepS1Bp7l"
    },
    {
        "rank": 1164,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "term": "discuss",
        "infexions": [
            "discuss",
            "discusses",
            "discussing",
            "discussed"
        ],
        "inflexion": "discuss, discusses, discussing, discussed",
        "wt": "v",
        "model": "lang-en",
        "inflexions": [
            "discuss",
            "discusses",
            "discussing",
            "discussed"
        ],
        "id": "cXls8GNas7SsIU7eZ3hi"
    },
    {
        "infexions": [
            "fancy",
            "fancier",
            "fanciest"
        ],
        "rank": 1165,
        "lang": "en-fr",
        "wt": "j",
        "inflexions": [
            "fancy",
            "fancier",
            "fanciest"
        ],
        "model": "lang-en",
        "inflexion": "fancy, fancier, fanciest",
        "term": "fancy",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "yYlqKa2IMsjoaL5m759c"
    },
    {
        "infexions": [
            "control",
            "controls",
            "controlling",
            "controlled"
        ],
        "inflexions": [
            "control",
            "controls",
            "controlling",
            "controlled"
        ],
        "lang": "en-fr",
        "wt": "v",
        "model": "lang-en",
        "rank": 1166,
        "isPareto": true,
        "term": "control",
        "workspace_id": "anglais",
        "inflexion": "control, controls, controlling, controlled",
        "id": "ZxK13brUC3BZZ7Qz74p5"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "advice",
        "infexions": [
            "advice"
        ],
        "lang": "en-fr",
        "term": "advice",
        "wt": "n",
        "rank": 1167,
        "inflexions": [
            "advice"
        ],
        "isPareto": true,
        "model": "lang-en",
        "id": "VBRY4OeaKP02aQ3yrKwA"
    },
    {
        "lang": "en-fr",
        "term": "center",
        "inflexion": "center, centers",
        "rank": 1168,
        "isPareto": true,
        "wt": "n",
        "workspace_id": "anglais",
        "inflexions": [
            "center",
            "centers"
        ],
        "model": "lang-en",
        "infexions": [
            "center",
            "centers"
        ],
        "id": "rgcDc1f8qN9p1pDyW1Cj"
    },
    {
        "inflexions": [
            "apple",
            "apples"
        ],
        "infexions": [
            "apple",
            "apples"
        ],
        "inflexion": "apple, apples",
        "rank": 1169,
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "term": "apple",
        "workspace_id": "anglais",
        "id": "aKNPnkqQj8TbxGSDcPpJ"
    },
    {
        "term": "everywhere",
        "isPareto": true,
        "rank": 1170,
        "workspace_id": "anglais",
        "infexions": [
            "everywhere"
        ],
        "lang": "en-fr",
        "wt": "r",
        "inflexions": [
            "everywhere"
        ],
        "model": "lang-en",
        "inflexion": "everywhere",
        "id": "tsnu0rcOK0LBnhU8Zvgp"
    },
    {
        "model": "lang-en",
        "inflexions": [
            "holiday",
            "holidays"
        ],
        "wt": "n",
        "infexions": [
            "holiday",
            "holidays"
        ],
        "inflexion": "holiday, holidays",
        "rank": 1171,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "holiday",
        "isPareto": true,
        "id": "oPMllYBoaPaFe2zCueCs"
    },
    {
        "wt": "n",
        "term": "cash",
        "model": "lang-en",
        "rank": 1172,
        "inflexion": "cash",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "cash"
        ],
        "infexions": [
            "cash"
        ],
        "isPareto": true,
        "id": "ErGMZyCYw9KoO1E2b55d"
    },
    {
        "isPareto": true,
        "rank": 1173,
        "inflexion": "common, commoner, commonest",
        "wt": "j",
        "workspace_id": "anglais",
        "term": "common",
        "lang": "en-fr",
        "model": "lang-en",
        "infexions": [
            "common",
            "commoner",
            "commonest"
        ],
        "inflexions": [
            "common",
            "commoner",
            "commonest"
        ],
        "id": "PAGnoAeLcYVldOs9tyf2"
    },
    {
        "infexions": [
            "crush",
            "crushes"
        ],
        "inflexion": "crush, crushes",
        "lang": "en-fr",
        "isPareto": true,
        "inflexions": [
            "crush",
            "crushes"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "term": "crush",
        "rank": 1174,
        "wt": "n",
        "id": "8ATxnYkQiMmpDtfTWiH2"
    },
    {
        "wt": "n",
        "isPareto": true,
        "infexions": [
            "mess",
            "messes"
        ],
        "model": "lang-en",
        "inflexion": "mess, messes",
        "lang": "en-fr",
        "rank": 1175,
        "inflexions": [
            "mess",
            "messes"
        ],
        "term": "mess",
        "workspace_id": "anglais",
        "id": "JciPftziZUHMvOLZI8Ws"
    },
    {
        "term": "peace",
        "inflexions": [
            "peace"
        ],
        "isPareto": true,
        "rank": 1176,
        "infexions": [
            "peace"
        ],
        "inflexion": "peace",
        "workspace_id": "anglais",
        "wt": "n",
        "model": "lang-en",
        "lang": "en-fr",
        "id": "ztvZe8Y3HgZcVPWWXlP1"
    },
    {
        "inflexions": [
            "warn",
            "warns",
            "warning",
            "warned"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 1177,
        "isPareto": true,
        "wt": "v",
        "infexions": [
            "warn",
            "warns",
            "warning",
            "warned"
        ],
        "term": "warn",
        "inflexion": "warn, warns, warning, warned",
        "lang": "en-fr",
        "id": "BLbFbQe5Rq7JXJweM54o"
    },
    {
        "infexions": [
            "welcome",
            "welcomes",
            "welcoming",
            "welcomed"
        ],
        "rank": 1178,
        "inflexions": [
            "welcome",
            "welcomes",
            "welcoming",
            "welcomed"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "v",
        "isPareto": true,
        "model": "lang-en",
        "term": "welcome",
        "inflexion": "welcome, welcomes, welcoming, welcomed",
        "id": "4jIgluEiNoNXAkgA4fQl"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "adult, adults",
        "infexions": [
            "adult",
            "adults"
        ],
        "term": "adult",
        "wt": "n",
        "rank": 1179,
        "model": "lang-en",
        "inflexions": [
            "adult",
            "adults"
        ],
        "workspace_id": "anglais",
        "id": "O6NACVmSlfsfHYHh508R"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "inflexions": [
            "noise",
            "noises"
        ],
        "infexions": [
            "noise",
            "noises"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "noise",
        "lang": "en-fr",
        "inflexion": "noise, noises",
        "rank": 1180,
        "id": "BhV6RTtq3j088S7tRgEe"
    },
    {
        "model": "lang-en",
        "infexions": [
            "rock",
            "rocks",
            "rocking",
            "rocked"
        ],
        "workspace_id": "anglais",
        "rank": 1181,
        "wt": "v",
        "lang": "en-fr",
        "term": "rock",
        "isPareto": true,
        "inflexion": "rock, rocks, rocking, rocked",
        "inflexions": [
            "rock",
            "rocks",
            "rocking",
            "rocked"
        ],
        "id": "KStZZJMlhAuy0hsB6sgo"
    },
    {
        "inflexion": "super",
        "lang": "en-fr",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 1182,
        "term": "super",
        "model": "lang-en",
        "infexions": [
            "super"
        ],
        "wt": "r",
        "inflexions": [
            "super"
        ],
        "id": "w1RouKX0rEeNpxz98COc"
    },
    {
        "workspace_id": "anglais",
        "inflexions": [
            "bread",
            "breads"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "bread, breads",
        "wt": "n",
        "infexions": [
            "bread",
            "breads"
        ],
        "lang": "en-fr",
        "rank": 1183,
        "term": "bread",
        "id": "Comzdx0dWAc7pEHdga84"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "fucking"
        ],
        "inflexion": "fucking",
        "wt": "r",
        "rank": 1184,
        "term": "fucking",
        "lang": "en-fr",
        "isPareto": true,
        "model": "lang-en",
        "inflexions": [
            "fucking"
        ],
        "id": "6fDCxP6AeW2Yvq5C4e1H"
    },
    {
        "workspace_id": "anglais",
        "rank": 1185,
        "model": "lang-en",
        "inflexion": "porn, porns",
        "wt": "n",
        "infexions": [
            "porn",
            "porns"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "term": "porn",
        "inflexions": [
            "porn",
            "porns"
        ],
        "id": "jbnMTUDH9CJ2t5ISFbZl"
    },
    {
        "inflexions": [
            "type",
            "types"
        ],
        "inflexion": "type, types",
        "term": "type",
        "lang": "en-fr",
        "wt": "n",
        "model": "lang-en",
        "rank": 1186,
        "infexions": [
            "type",
            "types"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "fLXaORpxr6epTyoMWiSi"
    },
    {
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "borrow, borrows, borrowing, borrowed",
        "inflexions": [
            "borrow",
            "borrows",
            "borrowing",
            "borrowed"
        ],
        "infexions": [
            "borrow",
            "borrows",
            "borrowing",
            "borrowed"
        ],
        "rank": 1187,
        "workspace_id": "anglais",
        "wt": "v",
        "model": "lang-en",
        "term": "borrow",
        "id": "NAs0Lut9xBKiqyJ3459X"
    },
    {
        "rank": 1188,
        "term": "department",
        "wt": "n",
        "infexions": [
            "department",
            "departments"
        ],
        "isPareto": true,
        "inflexions": [
            "department",
            "departments"
        ],
        "lang": "en-fr",
        "inflexion": "department, departments",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "8l8kchzVlbrZgLufikbb"
    },
    {
        "rank": 1189,
        "term": "plate",
        "infexions": [
            "plate",
            "plates"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "plate",
            "plates"
        ],
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "plate, plates",
        "wt": "n",
        "id": "H5rZoLN3Pl19CXoW5wVj"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "breathe",
            "breathes",
            "breathing",
            "breathed"
        ],
        "wt": "v",
        "inflexion": "breathe, breathes, breathing, breathed",
        "term": "breathe",
        "rank": 1190,
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "breathe",
            "breathes",
            "breathing",
            "breathed"
        ],
        "id": "SLC3gH2nAGQivzYRyop3"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "classic"
        ],
        "model": "lang-en",
        "isPareto": true,
        "term": "classic",
        "infexions": [
            "classic"
        ],
        "inflexion": "classic",
        "rank": 1191,
        "wt": "j",
        "id": "Sk4dKzcFDjI4eBkJk20W"
    },
    {
        "inflexion": "farm, farms",
        "lang": "en-fr",
        "isPareto": true,
        "term": "farm",
        "wt": "n",
        "inflexions": [
            "farm",
            "farms"
        ],
        "model": "lang-en",
        "infexions": [
            "farm",
            "farms"
        ],
        "rank": 1192,
        "workspace_id": "anglais",
        "id": "vUarKuyoXkRaPB3BsoI7"
    },
    {
        "wt": "j",
        "infexions": [
            "disgusting"
        ],
        "inflexions": [
            "disgusting"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexion": "disgusting",
        "rank": 1193,
        "model": "lang-en",
        "term": "disgusting",
        "lang": "en-fr",
        "id": "nrKLutClhgvjJNYaROB9"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "infexions": [
            "tall",
            "taller",
            "tallest"
        ],
        "inflexions": [
            "tall",
            "taller",
            "tallest"
        ],
        "model": "lang-en",
        "wt": "j",
        "inflexion": "tall, taller, tallest",
        "term": "tall",
        "rank": 1194,
        "isPareto": true,
        "id": "fIvfIBjMPmKbuA75iRXc"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "lang": "en-fr",
        "term": "taste",
        "inflexion": "taste, tastes",
        "model": "lang-en",
        "inflexions": [
            "taste",
            "tastes"
        ],
        "wt": "n",
        "rank": 1195,
        "infexions": [
            "taste",
            "tastes"
        ],
        "id": "aMBDM23FVNYZcCwoUBJy"
    },
    {
        "isPareto": true,
        "wt": "v",
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "replace",
            "replaces",
            "replacing",
            "replaced"
        ],
        "workspace_id": "anglais",
        "term": "replace",
        "inflexion": "replace, replaces, replacing, replaced",
        "infexions": [
            "replace",
            "replaces",
            "replacing",
            "replaced"
        ],
        "rank": 1196,
        "id": "nN2BQpccg71Wzer1Mbim"
    },
    {
        "inflexion": "somehow",
        "wt": "r",
        "lang": "en-fr",
        "term": "somehow",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "somehow"
        ],
        "rank": 1197,
        "inflexions": [
            "somehow"
        ],
        "id": "4fJrkurY4qjtNtDKBVUE"
    },
    {
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "term": "theater",
        "rank": 1198,
        "lang": "en-fr",
        "inflexions": [
            "theater",
            "theaters"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "theater",
            "theaters"
        ],
        "inflexion": "theater, theaters",
        "id": "oaoFCo1pzwPJkztRs9DM"
    },
    {
        "rank": 1199,
        "infexions": [
            "discover",
            "discovers",
            "discovering",
            "discovered"
        ],
        "term": "discover",
        "lang": "en-fr",
        "inflexions": [
            "discover",
            "discovers",
            "discovering",
            "discovered"
        ],
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "discover, discovers, discovering, discovered",
        "wt": "v",
        "workspace_id": "anglais",
        "id": "9j5uJ5TbHwGsH4goKDno"
    },
    {
        "infexions": [
            "incredible"
        ],
        "inflexion": "incredible",
        "workspace_id": "anglais",
        "term": "incredible",
        "wt": "j",
        "model": "lang-en",
        "inflexions": [
            "incredible"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1200,
        "id": "fLMDYVRR8eQF5QrQnkcR"
    },
    {
        "lang": "en-fr",
        "isPareto": true,
        "wt": "n",
        "infexions": [
            "plenty",
            "plenties"
        ],
        "workspace_id": "anglais",
        "rank": 1201,
        "inflexion": "plenty, plenties",
        "inflexions": [
            "plenty",
            "plenties"
        ],
        "term": "plenty",
        "model": "lang-en",
        "id": "bu3ISolp8jz2GzELXtI0"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "rank": 1202,
        "inflexions": [
            "baseball",
            "baseballs"
        ],
        "term": "baseball",
        "isPareto": true,
        "model": "lang-en",
        "workspace_id": "anglais",
        "inflexion": "baseball, baseballs",
        "infexions": [
            "baseball",
            "baseballs"
        ],
        "id": "WZnouhXNkxxVRtRRTIjT"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "wt": "n",
        "term": "comedy",
        "inflexions": [
            "comedy",
            "comedies"
        ],
        "infexions": [
            "comedy",
            "comedies"
        ],
        "rank": 1203,
        "inflexion": "comedy, comedies",
        "lang": "en-fr",
        "id": "KK7S8fPubzTtLsVFFUkO"
    },
    {
        "lang": "en-fr",
        "inflexion": "enter, enters, entering, entered",
        "inflexions": [
            "enter",
            "enters",
            "entering",
            "entered"
        ],
        "model": "lang-en",
        "rank": 1204,
        "isPareto": true,
        "infexions": [
            "enter",
            "enters",
            "entering",
            "entered"
        ],
        "term": "enter",
        "workspace_id": "anglais",
        "wt": "v",
        "id": "lds8OcaSWIeAxJCyne4g"
    },
    {
        "workspace_id": "anglais",
        "isPareto": true,
        "wt": "v",
        "model": "lang-en",
        "infexions": [
            "introduce",
            "introduces",
            "introducing",
            "introduced"
        ],
        "term": "introduce",
        "inflexions": [
            "introduce",
            "introduces",
            "introducing",
            "introduced"
        ],
        "rank": 1205,
        "lang": "en-fr",
        "inflexion": "introduce, introduces, introducing, introduced",
        "id": "TG62jMEQ3HVeLf6rVHSU"
    },
    {
        "inflexion": "whenever",
        "wt": "fw",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1206,
        "infexions": [
            "whenever"
        ],
        "inflexions": [
            "whenever"
        ],
        "term": "whenever",
        "model": "lang-en",
        "id": "5jdrQyf2HdthvXT76h4U"
    },
    {
        "workspace_id": "anglais",
        "infexions": [
            "butter"
        ],
        "term": "butter",
        "wt": "n",
        "rank": 1207,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "butter",
        "isPareto": true,
        "inflexions": [
            "butter"
        ],
        "id": "aun5SvUKwDSDDSBZM6WS"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "possibly"
        ],
        "infexions": [
            "possibly"
        ],
        "model": "lang-en",
        "wt": "r",
        "inflexion": "possibly",
        "isPareto": true,
        "term": "possibly",
        "rank": 1208,
        "id": "72tBEiPUZ1jgs23VXO8u"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "sweetheart",
            "sweethearts"
        ],
        "inflexion": "sweetheart, sweethearts",
        "infexions": [
            "sweetheart",
            "sweethearts"
        ],
        "model": "lang-en",
        "term": "sweetheart",
        "workspace_id": "anglais",
        "rank": 1209,
        "id": "fpkjnsSHAgwycGjJafGF"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "inflexions": [
            "jail",
            "jails"
        ],
        "term": "jail",
        "wt": "n",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "jail, jails",
        "rank": 1210,
        "infexions": [
            "jail",
            "jails"
        ],
        "id": "xOl8NMtXcpB9BbGtW1DP"
    },
    {
        "rank": 1211,
        "infexions": [
            "season",
            "seasons"
        ],
        "wt": "n",
        "inflexions": [
            "season",
            "seasons"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "season, seasons",
        "term": "season",
        "lang": "en-fr",
        "id": "FlYYFadz8urpDlqKGPav"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "term": "study",
        "infexions": [
            "study",
            "studies",
            "studying",
            "studied"
        ],
        "inflexion": "study, studies, studying, studied",
        "isPareto": true,
        "model": "lang-en",
        "wt": "v",
        "rank": 1212,
        "inflexions": [
            "study",
            "studies",
            "studying",
            "studied"
        ],
        "id": "DBObVSAtMCHzG7oT8XM4"
    },
    {
        "inflexions": [
            "miracle",
            "miracles"
        ],
        "workspace_id": "anglais",
        "rank": 1213,
        "model": "lang-en",
        "infexions": [
            "miracle",
            "miracles"
        ],
        "inflexion": "miracle, miracles",
        "isPareto": true,
        "lang": "en-fr",
        "term": "miracle",
        "wt": "n",
        "id": "lkC9SWmJnqXRsXxGSZHX"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "j",
        "rank": 1214,
        "model": "lang-en",
        "term": "complete",
        "inflexion": "complete, completer, completest",
        "inflexions": [
            "complete",
            "completer",
            "completest"
        ],
        "isPareto": true,
        "infexions": [
            "complete",
            "completer",
            "completest"
        ],
        "id": "nhjZl29J1864Q4DELlPA"
    },
    {
        "model": "lang-en",
        "infexions": [
            "golf"
        ],
        "workspace_id": "anglais",
        "inflexion": "golf",
        "wt": "n",
        "inflexions": [
            "golf"
        ],
        "lang": "en-fr",
        "term": "golf",
        "isPareto": true,
        "rank": 1215,
        "id": "F6czhUeseM9iTRNzVuQ6"
    },
    {
        "lang": "en-fr",
        "rank": 1216,
        "model": "lang-en",
        "isPareto": true,
        "infexions": [
            "hook",
            "hooks",
            "hooking",
            "hooked"
        ],
        "wt": "v",
        "inflexions": [
            "hook",
            "hooks",
            "hooking",
            "hooked"
        ],
        "term": "hook",
        "inflexion": "hook, hooks, hooking, hooked",
        "workspace_id": "anglais",
        "id": "n8NMdPH2HyZfXzG2W9HX"
    },
    {
        "rank": 1217,
        "inflexions": [
            "perfectly"
        ],
        "infexions": [
            "perfectly"
        ],
        "workspace_id": "anglais",
        "inflexion": "perfectly",
        "wt": "r",
        "lang": "en-fr",
        "term": "perfectly",
        "isPareto": true,
        "model": "lang-en",
        "id": "p1oT2WjSLPoYQyYapknr"
    },
    {
        "term": "stare",
        "wt": "v",
        "infexions": [
            "stare",
            "stares",
            "staring",
            "stared"
        ],
        "inflexion": "stare, stares, staring, stared",
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "stare",
            "stares",
            "staring",
            "stared"
        ],
        "lang": "en-fr",
        "rank": 1218,
        "model": "lang-en",
        "id": "6QJ7QAUReRucLFe5CR4c"
    },
    {
        "inflexion": "flag, flags",
        "isPareto": true,
        "workspace_id": "anglais",
        "wt": "n",
        "term": "flag",
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "flag",
            "flags"
        ],
        "infexions": [
            "flag",
            "flags"
        ],
        "rank": 1219,
        "id": "RbQsUng3vhgWDImxD2a9"
    },
    {
        "lang": "en-fr",
        "inflexion": "jeez, geez",
        "workspace_id": "anglais",
        "inflexions": [
            "jeez",
            "geez"
        ],
        "model": "lang-en",
        "term": "jeez",
        "wt": "u",
        "rank": 1220,
        "isPareto": true,
        "infexions": [
            "jeez",
            "geez"
        ],
        "id": "00PS0yM5NQjLfnnfCDIt"
    },
    {
        "wt": "n",
        "term": "roommate",
        "isPareto": true,
        "rank": 1221,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexion": "roommate, roommates",
        "infexions": [
            "roommate",
            "roommates"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "roommate",
            "roommates"
        ],
        "id": "2LSkuGhTRhw2xvWa4ZmB"
    },
    {
        "inflexions": [
            "ship",
            "ships"
        ],
        "workspace_id": "anglais",
        "term": "ship",
        "inflexion": "ship, ships",
        "rank": 1222,
        "infexions": [
            "ship",
            "ships"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "id": "nkKbTe2hWrKPgiNOficG"
    },
    {
        "wt": "n",
        "inflexion": "wood, woods",
        "rank": 1223,
        "term": "wood",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "wood",
            "woods"
        ],
        "inflexions": [
            "wood",
            "woods"
        ],
        "model": "lang-en",
        "id": "lTQSeRv5xA97ykYzr3cL"
    },
    {
        "infexions": [
            "actor",
            "actors"
        ],
        "term": "actor",
        "rank": 1224,
        "inflexion": "actor, actors",
        "lang": "en-fr",
        "inflexions": [
            "actor",
            "actors"
        ],
        "wt": "n",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "40uHGQY2ivunp1whhoCQ"
    },
    {
        "infexions": [
            "break",
            "breaks"
        ],
        "rank": 1225,
        "term": "break",
        "workspace_id": "anglais",
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "break",
            "breaks"
        ],
        "inflexion": "break, breaks",
        "model": "lang-en",
        "isPareto": true,
        "id": "QYMROebaYpKTokb2OyQJ"
    },
    {
        "lang": "en-fr",
        "infexions": [
            "prize",
            "prizes"
        ],
        "inflexions": [
            "prize",
            "prizes"
        ],
        "inflexion": "prize, prizes",
        "workspace_id": "anglais",
        "rank": 1226,
        "isPareto": true,
        "wt": "n",
        "term": "prize",
        "model": "lang-en",
        "id": "jU7Q8E0ErPKNPHoNclgr"
    },
    {
        "rank": 1227,
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "thanks"
        ],
        "inflexion": "thanks",
        "infexions": [
            "thanks"
        ],
        "model": "lang-en",
        "term": "thanks",
        "wt": "fw",
        "id": "pX6F94pJLkL8EtSpHD4B"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "corner",
            "corners"
        ],
        "model": "lang-en",
        "inflexion": "corner, corners",
        "isPareto": true,
        "infexions": [
            "corner",
            "corners"
        ],
        "wt": "n",
        "term": "corner",
        "rank": 1228,
        "id": "Aku1mg0qURW48dkhn99W"
    },
    {
        "wt": "m",
        "inflexions": [
            "fourth",
            "fourths"
        ],
        "term": "fourth",
        "model": "lang-en",
        "inflexion": "fourth, fourths",
        "rank": 1229,
        "workspace_id": "anglais",
        "infexions": [
            "fourth",
            "fourths"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "id": "THhG3Va8fbZgVWqb4k30"
    },
    {
        "wt": "v",
        "lang": "en-fr",
        "isPareto": true,
        "infexions": [
            "sneak",
            "sneaks",
            "sneaking",
            "sneaked",
            "snuck"
        ],
        "rank": 1230,
        "inflexion": "sneak, sneaks, sneaking, sneaked, snuck",
        "term": "sneak",
        "inflexions": [
            "sneak",
            "sneaks",
            "sneaking",
            "sneaked",
            "snuck"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "FdSLxMR2E2ZVA18wsIlH"
    },
    {
        "workspace_id": "anglais",
        "term": "piss",
        "model": "lang-en",
        "wt": "v",
        "infexions": [
            "piss",
            "pisses",
            "pissing",
            "pissed"
        ],
        "isPareto": true,
        "rank": 1231,
        "inflexions": [
            "piss",
            "pisses",
            "pissing",
            "pissed"
        ],
        "lang": "en-fr",
        "inflexion": "piss, pisses, pissing, pissed",
        "id": "0frtxTW3ysMPBJMukewG"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "rank": 1232,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "pocket",
            "pockets"
        ],
        "isPareto": true,
        "inflexion": "pocket, pockets",
        "term": "pocket",
        "infexions": [
            "pocket",
            "pockets"
        ],
        "id": "4sBWrwyRVWqxTMhE4G6v"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 1233,
        "inflexion": "tip, tips",
        "isPareto": true,
        "infexions": [
            "tip",
            "tips"
        ],
        "term": "tip",
        "workspace_id": "anglais",
        "wt": "n",
        "inflexions": [
            "tip",
            "tips"
        ],
        "id": "5dmWbeeUTDmP4WtxvIcM"
    },
    {
        "wt": "r",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "alone"
        ],
        "term": "alone",
        "inflexion": "alone",
        "isPareto": true,
        "rank": 1234,
        "inflexions": [
            "alone"
        ],
        "id": "qg6N9ieMhIRZhagANLYn"
    },
    {
        "infexions": [
            "lately"
        ],
        "lang": "en-fr",
        "term": "lately",
        "rank": 1235,
        "inflexions": [
            "lately"
        ],
        "model": "lang-en",
        "wt": "r",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexion": "lately",
        "id": "dgDfIAOf8qPdgkChEji1"
    },
    {
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "term": "queen",
        "inflexions": [
            "queen",
            "queens"
        ],
        "model": "lang-en",
        "inflexion": "queen, queens",
        "rank": 1236,
        "infexions": [
            "queen",
            "queens"
        ],
        "id": "17RqWq34Z70HUrZ7qwzI"
    },
    {
        "wt": "v",
        "inflexions": [
            "tear",
            "tears",
            "tearing",
            "tore",
            "torn"
        ],
        "isPareto": true,
        "inflexion": "tear, tears, tearing, tore, torn",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1237,
        "infexions": [
            "tear",
            "tears",
            "tearing",
            "tore",
            "torn"
        ],
        "term": "tear",
        "lang": "en-fr",
        "id": "VRpRRdV9x4CR030p0SPJ"
    },
    {
        "inflexion": "especially",
        "wt": "r",
        "inflexions": [
            "especially"
        ],
        "isPareto": true,
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "especially",
        "rank": 1238,
        "model": "lang-en",
        "infexions": [
            "especially"
        ],
        "id": "0CPF8QdomAYT343Gdk8d"
    },
    {
        "isPareto": true,
        "rank": 1239,
        "wt": "v",
        "inflexion": "lay, lays, laying, laid",
        "term": "lay",
        "model": "lang-en",
        "infexions": [
            "lay",
            "lays",
            "laying",
            "laid"
        ],
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "lay",
            "lays",
            "laying",
            "laid"
        ],
        "id": "GMbq8uKYOPp5Kz9EoZ7W"
    },
    {
        "rank": 1240,
        "infexions": [
            "pressure",
            "pressures"
        ],
        "model": "lang-en",
        "wt": "n",
        "inflexion": "pressure, pressures",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "inflexions": [
            "pressure",
            "pressures"
        ],
        "term": "pressure",
        "isPareto": true,
        "id": "aQhyRCLHm8t25Qh8AFbh"
    },
    {
        "infexions": [
            "rip",
            "rips",
            "ripping",
            "ripped"
        ],
        "model": "lang-en",
        "rank": 1241,
        "inflexion": "rip, rips, ripping, ripped",
        "wt": "v",
        "lang": "en-fr",
        "inflexions": [
            "rip",
            "rips",
            "ripping",
            "ripped"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "term": "rip",
        "id": "4ZX8JMWxoQpuctOEAZAD"
    },
    {
        "term": "assistant",
        "isPareto": true,
        "lang": "en-fr",
        "infexions": [
            "assistant",
            "assistants"
        ],
        "inflexions": [
            "assistant",
            "assistants"
        ],
        "inflexion": "assistant, assistants",
        "rank": 1242,
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "id": "23EpqMpv21u1wJPP5tXY"
    },
    {
        "inflexion": "camp, camps",
        "inflexions": [
            "camp",
            "camps"
        ],
        "rank": 1243,
        "lang": "en-fr",
        "infexions": [
            "camp",
            "camps"
        ],
        "wt": "n",
        "model": "lang-en",
        "isPareto": true,
        "term": "camp",
        "workspace_id": "anglais",
        "id": "CfThkJujcKORP5gYkZmp"
    },
    {
        "lang": "en-fr",
        "model": "lang-en",
        "term": "judge",
        "rank": 1244,
        "inflexions": [
            "judge",
            "judges"
        ],
        "wt": "n",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "judge",
            "judges"
        ],
        "inflexion": "judge, judges",
        "id": "gpmhtM1qsan1sRKDFgUk"
    },
    {
        "rank": 1245,
        "inflexions": [
            "North",
            "north"
        ],
        "isPareto": true,
        "term": "North",
        "wt": "n",
        "workspace_id": "anglais",
        "infexions": [
            "North",
            "north"
        ],
        "model": "lang-en",
        "inflexion": "North, north",
        "lang": "en-fr",
        "id": "kHJ2IR4qILH0tI2pFMXd"
    },
    {
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "often",
            "oftener",
            "oftenest"
        ],
        "rank": 1246,
        "inflexion": "often, oftener, oftenest",
        "wt": "r",
        "inflexions": [
            "often",
            "oftener",
            "oftenest"
        ],
        "term": "often",
        "isPareto": true,
        "lang": "en-fr",
        "id": "aL2jz85bmrYweM1tG421"
    },
    {
        "inflexion": "Halloween, Halloweens",
        "rank": 1247,
        "lang": "en-fr",
        "infexions": [
            "Halloween",
            "Halloweens"
        ],
        "model": "lang-en",
        "inflexions": [
            "Halloween",
            "Halloweens"
        ],
        "term": "Halloween",
        "wt": "K",
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "wMiiZ9FhAdQxbuqGHiqO"
    },
    {
        "isPareto": true,
        "inflexions": [
            "vacation",
            "vacations"
        ],
        "rank": 1248,
        "lang": "en-fr",
        "term": "vacation",
        "infexions": [
            "vacation",
            "vacations"
        ],
        "inflexion": "vacation, vacations",
        "workspace_id": "anglais",
        "model": "lang-en",
        "wt": "n",
        "id": "Pd9rcOPWOiKyn27Vqq5q"
    },
    {
        "infexions": [
            "impossible"
        ],
        "rank": 1249,
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "impossible",
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "impossible",
        "inflexions": [
            "impossible"
        ],
        "wt": "j",
        "id": "YuidVERMVuPtEPimj4ex"
    },
    {
        "inflexions": [
            "square",
            "squares"
        ],
        "workspace_id": "anglais",
        "infexions": [
            "square",
            "squares"
        ],
        "wt": "n",
        "lang": "en-fr",
        "term": "square",
        "rank": 1250,
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "square, squares",
        "id": "u0rxnZkYWBk3sCgKpOxZ"
    },
    {
        "term": "left",
        "inflexion": "left, lefts",
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "left",
            "lefts"
        ],
        "lang": "en-fr",
        "rank": 1251,
        "model": "lang-en",
        "isPareto": true,
        "inflexions": [
            "left",
            "lefts"
        ],
        "id": "a5xzt1jR6ns3yp8chHxi"
    },
    {
        "term": "grandma",
        "workspace_id": "anglais",
        "rank": 1252,
        "model": "lang-en",
        "infexions": [
            "grandma",
            "grandmas"
        ],
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "grandma",
            "grandmas"
        ],
        "inflexion": "grandma, grandmas",
        "isPareto": true,
        "id": "hz0hXLe1vQFhkDKwFBYX"
    },
    {
        "isPareto": true,
        "rank": 1253,
        "inflexions": [
            "gross",
            "grosser",
            "grossest"
        ],
        "wt": "j",
        "model": "lang-en",
        "term": "gross",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexion": "gross, grosser, grossest",
        "infexions": [
            "gross",
            "grosser",
            "grossest"
        ],
        "id": "mTWl8Hab005IJMW9CFFx"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "rank": 1254,
        "lang": "en-fr",
        "inflexion": "pal, pals",
        "term": "pal",
        "infexions": [
            "pal",
            "pals"
        ],
        "workspace_id": "anglais",
        "inflexions": [
            "pal",
            "pals"
        ],
        "isPareto": true,
        "id": "Y4Gf7cPxeNmtjJUV4k34"
    },
    {
        "infexions": [
            "smile",
            "smiles",
            "smiling",
            "smiled"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "smile",
            "smiles",
            "smiling",
            "smiled"
        ],
        "lang": "en-fr",
        "wt": "v",
        "term": "smile",
        "rank": 1255,
        "isPareto": true,
        "inflexion": "smile, smiles, smiling, smiled",
        "id": "hzJcP6FKXEsr2YAULEz9"
    },
    {
        "wt": "n",
        "rank": 1256,
        "term": "speech",
        "model": "lang-en",
        "infexions": [
            "speech",
            "speeches"
        ],
        "lang": "en-fr",
        "inflexion": "speech, speeches",
        "inflexions": [
            "speech",
            "speeches"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "cy8Pt54F85Ot4f29zXt8"
    },
    {
        "rank": 1257,
        "workspace_id": "anglais",
        "term": "bust",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "bust, busts, busting, busted",
        "wt": "v",
        "inflexions": [
            "bust",
            "busts",
            "busting",
            "busted"
        ],
        "model": "lang-en",
        "infexions": [
            "bust",
            "busts",
            "busting",
            "busted"
        ],
        "id": "pMSE0KkEbevAmWUaulQm"
    },
    {
        "infexions": [
            "gee"
        ],
        "inflexion": "gee",
        "workspace_id": "anglais",
        "inflexions": [
            "gee"
        ],
        "isPareto": true,
        "model": "lang-en",
        "term": "gee",
        "wt": "u",
        "lang": "en-fr",
        "rank": 1258,
        "id": "nCl0FdGzRdKWKcvf87q5"
    },
    {
        "lang": "en-fr",
        "inflexion": "handsome, handsomer, handsomest",
        "isPareto": true,
        "rank": 1259,
        "model": "lang-en",
        "workspace_id": "anglais",
        "wt": "j",
        "inflexions": [
            "handsome",
            "handsomer",
            "handsomest"
        ],
        "term": "handsome",
        "infexions": [
            "handsome",
            "handsomer",
            "handsomest"
        ],
        "id": "3XPisz0XKFxNg1L7x2wA"
    },
    {
        "wt": "n",
        "isPareto": true,
        "inflexions": [
            "safety",
            "safeties"
        ],
        "term": "safety",
        "lang": "en-fr",
        "inflexion": "safety, safeties",
        "rank": 1260,
        "infexions": [
            "safety",
            "safeties"
        ],
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "laPdflmGV7Qd6o0N6rNI"
    },
    {
        "infexions": [
            "test",
            "tests",
            "testing",
            "tested"
        ],
        "inflexions": [
            "test",
            "tests",
            "testing",
            "tested"
        ],
        "term": "test",
        "rank": 1261,
        "wt": "v",
        "inflexion": "test, tests, testing, tested",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "isPareto": true,
        "model": "lang-en",
        "id": "veBAk35RMmDxSurb1BiC"
    },
    {
        "inflexion": "community, communities",
        "infexions": [
            "community",
            "communities"
        ],
        "inflexions": [
            "community",
            "communities"
        ],
        "rank": 1262,
        "isPareto": true,
        "term": "community",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "model": "lang-en",
        "wt": "n",
        "id": "KCc6NyM62dCQ80X9j1nT"
    },
    {
        "deck_id": "Y4zuh5dsqYTZJ9uGHcwW",
        "infexions": [
            "beach",
            "beaches"
        ],
        "rank": 1263,
        "inflexions": [
            "beach",
            "beaches"
        ],
        "isPareto": true,
        "term": "beach",
        "lang": "en-fr",
        "wt": "n",
        "order": 4,
        "model": "lang-en",
        "workspace_id": "anglais",
        "def_img": "https://media2.giphy.com/media/3oEjHZMFYibQnjvTq0/giphy.gif?cid=ecf05e47y3qfofapfg1girbnbz6jz9d81curhg15rcrg735h&rid=giphy.gif&ct=g",
        "inflexion": "beach, beaches",
        "id": "ZEPb2Ibmi4obsI06xwZD",
        "context": "<p>I would love to go to the beach this summer !</p>"
    },
    {
        "workspace_id": "anglais",
        "rank": 1264,
        "inflexion": "gym, gyms",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "term": "gym",
        "infexions": [
            "gym",
            "gyms"
        ],
        "inflexions": [
            "gym",
            "gyms"
        ],
        "id": "hCaw4ZmTHEbPijhlMvWZ"
    },
    {
        "infexions": [
            "toast",
            "toasts"
        ],
        "term": "toast",
        "model": "lang-en",
        "inflexion": "toast, toasts",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "n",
        "isPareto": true,
        "rank": 1265,
        "inflexions": [
            "toast",
            "toasts"
        ],
        "id": "PqyPx93qGkh9cE1esV6J"
    },
    {
        "inflexion": "disease, diseases",
        "model": "lang-en",
        "term": "disease",
        "workspace_id": "anglais",
        "isPareto": true,
        "infexions": [
            "disease",
            "diseases"
        ],
        "inflexions": [
            "disease",
            "diseases"
        ],
        "wt": "n",
        "rank": 1266,
        "lang": "en-fr",
        "id": "bhr4dDHtf79HhtA27osC"
    },
    {
        "wt": "v",
        "term": "paint",
        "infexions": [
            "paint",
            "paints",
            "painting",
            "painted"
        ],
        "inflexion": "paint, paints, painting, painted",
        "isPareto": true,
        "rank": 1267,
        "inflexions": [
            "paint",
            "paints",
            "painting",
            "painted"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "Z7NkgMkOc6t3AyvFYOz3"
    },
    {
        "rank": 1268,
        "infexions": [
            "themselves"
        ],
        "inflexions": [
            "themselves"
        ],
        "term": "themselves",
        "model": "lang-en",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "wt": "fw",
        "isPareto": true,
        "inflexion": "themselves",
        "id": "dZF1lBcv688KKo50s7VB"
    },
    {
        "term": "Monday",
        "lang": "en-fr",
        "infexions": [
            "Monday",
            "Mondays"
        ],
        "isPareto": true,
        "inflexion": "Monday, Mondays",
        "inflexions": [
            "Monday",
            "Mondays"
        ],
        "rank": 1269,
        "wt": "K",
        "model": "lang-en",
        "workspace_id": "anglais",
        "id": "ZFuuqNvuMxii56JYzhp7"
    },
    {
        "model": "lang-en",
        "infexions": [
            "pack",
            "packs",
            "packing",
            "packed"
        ],
        "wt": "v",
        "workspace_id": "anglais",
        "inflexion": "pack, packs, packing, packed",
        "term": "pack",
        "isPareto": true,
        "rank": 1270,
        "inflexions": [
            "pack",
            "packs",
            "packing",
            "packed"
        ],
        "lang": "en-fr",
        "id": "P9v6Clxusv5plaww06IK"
    },
    {
        "model": "lang-en",
        "workspace_id": "anglais",
        "rank": 1271,
        "term": "punch",
        "infexions": [
            "punch",
            "punches",
            "punching",
            "punched"
        ],
        "inflexion": "punch, punches, punching, punched",
        "isPareto": true,
        "inflexions": [
            "punch",
            "punches",
            "punching",
            "punched"
        ],
        "wt": "v",
        "lang": "en-fr",
        "id": "B0htcmRbMLQXZUFgMXPM"
    },
    {
        "infexions": [
            "customer",
            "customers"
        ],
        "workspace_id": "anglais",
        "term": "customer",
        "rank": 1272,
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "inflexion": "customer, customers",
        "wt": "n",
        "inflexions": [
            "customer",
            "customers"
        ],
        "id": "nSSgDJpiQbS3XNM8BKR6"
    },
    {
        "lang": "en-fr",
        "workspace_id": "anglais",
        "inflexions": [
            "healthy",
            "healthier",
            "healthiest"
        ],
        "inflexion": "healthy, healthier, healthiest",
        "isPareto": true,
        "rank": 1273,
        "infexions": [
            "healthy",
            "healthier",
            "healthiest"
        ],
        "wt": "j",
        "model": "lang-en",
        "term": "healthy",
        "id": "SQtqOHrpxOZDCo7NnVvF"
    },
    {
        "isPareto": true,
        "workspace_id": "anglais",
        "inflexions": [
            "invent",
            "invents",
            "inventing",
            "invented"
        ],
        "lang": "en-fr",
        "wt": "v",
        "rank": 1274,
        "model": "lang-en",
        "term": "invent",
        "inflexion": "invent, invents, inventing, invented",
        "infexions": [
            "invent",
            "invents",
            "inventing",
            "invented"
        ],
        "id": "3L2sH4iRpVCWpEGtp5oK"
    },
    {
        "rank": 1275,
        "wt": "n",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "term": "princess",
        "model": "lang-en",
        "inflexion": "princess, princesses",
        "isPareto": true,
        "infexions": [
            "princess",
            "princesses"
        ],
        "inflexions": [
            "princess",
            "princesses"
        ],
        "id": "pVv4ucusLMQyz2WraNCn"
    },
    {
        "rank": 1276,
        "infexions": [
            "merry",
            "merrier",
            "merriest"
        ],
        "workspace_id": "anglais",
        "inflexion": "merry, merrier, merriest",
        "wt": "j",
        "lang": "en-fr",
        "model": "lang-en",
        "term": "merry",
        "isPareto": true,
        "inflexions": [
            "merry",
            "merrier",
            "merriest"
        ],
        "id": "eRB2WCadWc9OmkfirCLY"
    },
    {
        "rank": 1277,
        "inflexion": "someday",
        "infexions": [
            "someday"
        ],
        "lang": "en-fr",
        "inflexions": [
            "someday"
        ],
        "term": "someday",
        "isPareto": true,
        "model": "lang-en",
        "wt": "r",
        "workspace_id": "anglais",
        "id": "0JjaUvQ8hziDXHR8VNCe"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "term": "blind",
        "inflexion": "blind, blinder, blindest",
        "isPareto": true,
        "inflexions": [
            "blind",
            "blinder",
            "blindest"
        ],
        "infexions": [
            "blind",
            "blinder",
            "blindest"
        ],
        "workspace_id": "anglais",
        "wt": "j",
        "rank": 1278,
        "id": "7YY8k4qC7ibyMjeKsp25"
    },
    {
        "inflexion": "certain",
        "inflexions": [
            "certain"
        ],
        "model": "lang-en",
        "lang": "en-fr",
        "infexions": [
            "certain"
        ],
        "rank": 1279,
        "isPareto": true,
        "term": "certain",
        "wt": "r",
        "workspace_id": "anglais",
        "id": "eJ0ANX7MFdRBkPxKOYuB"
    },
    {
        "wt": "v",
        "workspace_id": "anglais",
        "inflexion": "solve, solves, solving, solved",
        "rank": 1280,
        "inflexions": [
            "solve",
            "solves",
            "solving",
            "solved"
        ],
        "infexions": [
            "solve",
            "solves",
            "solving",
            "solved"
        ],
        "lang": "en-fr",
        "term": "solve",
        "model": "lang-en",
        "isPareto": true,
        "id": "VWueOyxUX2bJ17n72rtq"
    },
    {
        "wt": "n",
        "term": "study",
        "rank": 1281,
        "lang": "en-fr",
        "model": "lang-en",
        "inflexion": "study, studies",
        "isPareto": true,
        "workspace_id": "anglais",
        "infexions": [
            "study",
            "studies"
        ],
        "inflexions": [
            "study",
            "studies"
        ],
        "id": "X9gD31fcenlQVv8AS1OA"
    },
    {
        "wt": "n",
        "rank": 1282,
        "inflexion": "sweater, sweaters",
        "term": "sweater",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "sweater",
            "sweaters"
        ],
        "inflexions": [
            "sweater",
            "sweaters"
        ],
        "isPareto": true,
        "id": "KFfFCnpjBn83NAgrzA1v"
    },
    {
        "infexions": [
            "terrific"
        ],
        "lang": "en-fr",
        "term": "terrific",
        "isPareto": true,
        "workspace_id": "anglais",
        "rank": 1283,
        "model": "lang-en",
        "wt": "j",
        "inflexions": [
            "terrific"
        ],
        "inflexion": "terrific",
        "id": "Djd4JJr13QbYpE2CoZHp"
    },
    {
        "isPareto": true,
        "rank": 1284,
        "inflexion": "wet, wetter, wettest",
        "workspace_id": "anglais",
        "infexions": [
            "wet",
            "wetter",
            "wettest"
        ],
        "model": "lang-en",
        "term": "wet",
        "wt": "j",
        "lang": "en-fr",
        "inflexions": [
            "wet",
            "wetter",
            "wettest"
        ],
        "id": "CyhrsTg51jQzw40b9Fs5"
    },
    {
        "inflexions": [
            "attack",
            "attacks"
        ],
        "inflexion": "attack, attacks",
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1285,
        "isPareto": true,
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "attack",
            "attacks"
        ],
        "term": "attack",
        "id": "Mgbjp0yuZdP66L8xvwrk"
    },
    {
        "rank": 1286,
        "workspace_id": "anglais",
        "model": "lang-en",
        "inflexions": [
            "beg",
            "begs",
            "begging",
            "begged"
        ],
        "term": "beg",
        "infexions": [
            "beg",
            "begs",
            "begging",
            "begged"
        ],
        "isPareto": true,
        "inflexion": "beg, begs, begging, begged",
        "wt": "v",
        "lang": "en-fr",
        "id": "FamOzoZUG0NCnOSReWdr"
    },
    {
        "inflexions": [
            "cigarette",
            "cigaret",
            "cigarettes",
            "cigarets"
        ],
        "infexions": [
            "cigarette",
            "cigaret",
            "cigarettes",
            "cigarets"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "term": "cigarette",
        "wt": "n",
        "rank": 1287,
        "inflexion": "cigarette, cigaret, cigarettes, cigarets",
        "workspace_id": "anglais",
        "model": "lang-en",
        "id": "EuuIRlxMc81GyEXnzOgp"
    },
    {
        "workspace_id": "anglais",
        "inflexion": "sugar, sugars",
        "isPareto": true,
        "infexions": [
            "sugar",
            "sugars"
        ],
        "term": "sugar",
        "rank": 1288,
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "inflexions": [
            "sugar",
            "sugars"
        ],
        "id": "eUjMET75iXj0rc7juofJ"
    },
    {
        "rank": 1289,
        "lang": "en-fr",
        "isPareto": true,
        "wt": "u",
        "inflexion": "uhh",
        "term": "uhh",
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "uhh"
        ],
        "inflexions": [
            "uhh"
        ],
        "id": "clJGXeY1Tv3vkqBHn5eP"
    },
    {
        "inflexions": [
            "weekend",
            "weekends"
        ],
        "lang": "en-fr",
        "isPareto": true,
        "inflexion": "weekend, weekends",
        "term": "weekend",
        "wt": "n",
        "infexions": [
            "weekend",
            "weekends"
        ],
        "workspace_id": "anglais",
        "model": "lang-en",
        "rank": 1290,
        "id": "YMoo4iYoIVjgh3aMZKQb"
    },
    {
        "rank": 1291,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "r",
        "inflexions": [
            "damn"
        ],
        "isPareto": true,
        "model": "lang-en",
        "infexions": [
            "damn"
        ],
        "term": "damn",
        "inflexion": "damn",
        "id": "P1Nr8nYqVeD4qxzLAzaq"
    },
    {
        "infexions": [
            "sock",
            "socks",
            "sox"
        ],
        "rank": 1292,
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "sock",
            "socks",
            "sox"
        ],
        "model": "lang-en",
        "inflexion": "sock, socks, sox",
        "term": "sock",
        "isPareto": true,
        "workspace_id": "anglais",
        "id": "RTcQKkfrH73PKINNrB9l"
    },
    {
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "rank": 1293,
        "infexions": [
            "emergency",
            "emergencies"
        ],
        "workspace_id": "anglais",
        "inflexion": "emergency, emergencies",
        "term": "emergency",
        "inflexions": [
            "emergency",
            "emergencies"
        ],
        "isPareto": true,
        "id": "Tzve2kPFwcIzCa0bQxrz"
    },
    {
        "rank": 1294,
        "isPareto": true,
        "inflexion": "mood, moods",
        "workspace_id": "anglais",
        "model": "lang-en",
        "term": "mood",
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "mood",
            "moods"
        ],
        "inflexions": [
            "mood",
            "moods"
        ],
        "id": "rxuEQFahYK54OfrEyFRf"
    },
    {
        "term": "peanut",
        "infexions": [
            "peanut",
            "peanuts"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "isPareto": true,
        "wt": "n",
        "inflexion": "peanut, peanuts",
        "workspace_id": "anglais",
        "rank": 1295,
        "inflexions": [
            "peanut",
            "peanuts"
        ],
        "id": "7Ksx4ZuaXu3GwIEuMp2W"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "stage",
            "stages"
        ],
        "isPareto": true,
        "wt": "n",
        "rank": 1296,
        "workspace_id": "anglais",
        "model": "lang-en",
        "infexions": [
            "stage",
            "stages"
        ],
        "term": "stage",
        "inflexion": "stage, stages",
        "id": "M7p9oVO5GQDpPKtLaYeq"
    },
    {
        "lang": "en-fr",
        "inflexion": "tight, tighter, tightest",
        "term": "tight",
        "isPareto": true,
        "inflexions": [
            "tight",
            "tighter",
            "tightest"
        ],
        "model": "lang-en",
        "rank": 1297,
        "infexions": [
            "tight",
            "tighter",
            "tightest"
        ],
        "workspace_id": "anglais",
        "wt": "j",
        "id": "G2uGsuPbqZXMbFJ6gDir"
    },
    {
        "model": "lang-en",
        "wt": "n",
        "workspace_id": "anglais",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1298,
        "infexions": [
            "gum",
            "gums"
        ],
        "inflexion": "gum, gums",
        "inflexions": [
            "gum",
            "gums"
        ],
        "term": "gum",
        "id": "vLJ11RUnwQlt7DqVjcql"
    },
    {
        "term": "moon",
        "isPareto": true,
        "model": "lang-en",
        "lang": "en-fr",
        "inflexions": [
            "moon",
            "moons"
        ],
        "infexions": [
            "moon",
            "moons"
        ],
        "inflexion": "moon, moons",
        "workspace_id": "anglais",
        "wt": "n",
        "rank": 1299,
        "id": "dFLGiaMJpDwGmoRBqG64"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "nature",
            "natures"
        ],
        "rank": 1300,
        "isPareto": true,
        "model": "lang-en",
        "inflexion": "nature, natures",
        "workspace_id": "anglais",
        "wt": "n",
        "infexions": [
            "nature",
            "natures"
        ],
        "term": "nature",
        "id": "CiFRlPQnW85fKdt5JYN2"
    },
    {
        "isPareto": true,
        "term": "program",
        "workspace_id": "anglais",
        "inflexion": "program, programs",
        "infexions": [
            "program",
            "programs"
        ],
        "inflexions": [
            "program",
            "programs"
        ],
        "lang": "en-fr",
        "model": "lang-en",
        "rank": 1301,
        "wt": "n",
        "id": "84pad7nBxfeBQnZpoGer"
    },
    {
        "term": "straight",
        "lang": "en-fr",
        "workspace_id": "anglais",
        "rank": 1302,
        "infexions": [
            "straight"
        ],
        "inflexion": "straight",
        "inflexions": [
            "straight"
        ],
        "isPareto": true,
        "wt": "r",
        "model": "lang-en",
        "id": "ORizQTQ9yCDZr5adoKFJ"
    },
    {
        "term": "angel",
        "model": "lang-en",
        "workspace_id": "anglais",
        "isPareto": true,
        "inflexions": [
            "angel",
            "angels"
        ],
        "infexions": [
            "angel",
            "angels"
        ],
        "wt": "n",
        "lang": "en-fr",
        "inflexion": "angel, angels",
        "rank": 1303,
        "id": "bPvMDOyCq6wvzEJHf5BB"
    },
    {
        "inflexions": [
            "corporate"
        ],
        "infexions": [
            "corporate"
        ],
        "workspace_id": "anglais",
        "lang": "en-fr",
        "rank": 1304,
        "wt": "j",
        "inflexion": "corporate",
        "isPareto": true,
        "term": "corporate",
        "model": "lang-en",
        "id": "cfiapN3Kn0vX2CafKsrV"
    },
    {
        "term": "damn",
        "inflexion": "damn, damns, damning, damned",
        "model": "lang-en",
        "lang": "en-fr",
        "isPareto": true,
        "rank": 1305,
        "wt": "v",
        "workspace_id": "anglais",
        "inflexions": [
            "damn",
            "damns",
            "damning",
            "damned"
        ],
        "infexions": [
            "damn",
            "damns",
            "damning",
            "damned"
        ],
        "id": "giDJzUchqKh590MVQ26k"
    },
    {
        "infexions": [
            "exist",
            "exists",
            "existing",
            "existed"
        ],
        "isPareto": true,
        "workspace_id": "anglais",
        "model": "lang-en",
        "lang": "en-fr",
        "term": "exist",
        "inflexions": [
            "exist",
            "exists",
            "existing",
            "existed"
        ],
        "inflexion": "exist, exists, existing, existed",
        "wt": "v",
        "rank": 1306,
        "id": "IMOL7QZt0ZWjVSIZJWrW"
    },
    {
        "infexions": [
            "ghost",
            "ghosts"
        ],
        "lang": "en-fr",
        "rank": 1307,
        "inflexions": [
            "ghost",
            "ghosts"
        ],
        "workspace_id": "anglais",
        "wt": "n",
        "isPareto": true,
        "term": "ghost",
        "model": "lang-en",
        "inflexion": "ghost, ghosts",
        "id": "w8lGhqFY6Fi63MS51DWi"
    },
    {
        "rank": 1308,
        "isPareto": true,
        "inflexions": [
            "sauce",
            "sauces"
        ],
        "term": "sauce",
        "inflexion": "sauce, sauces",
        "model": "lang-en",
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "sauce",
            "sauces"
        ],
        "workspace_id": "anglais",
        "id": "KeYqv5g2q8xnLkaJIKkw"
    },
    {
        "wt": "n",
        "lang": "en-fr",
        "inflexions": [
            "stomach",
            "stomachs"
        ],
        "rank": 1309,
        "inflexion": "stomach, stomachs",
        "isPareto": true,
        "term": "stomach",
        "model": "lang-en",
        "infexions": [
            "stomach",
            "stomachs"
        ],
        "workspace_id": "anglais",
        "id": "t47smz1TGRHlLcCjIvu5"
    },
    {
        "infexions": [
            "twenty-five"
        ],
        "rank": 1310,
        "model": "lang-en",
        "inflexion": "twenty-five",
        "inflexions": [
            "twenty-five"
        ],
        "term": "twenty-five",
        "isPareto": true,
        "workspace_id": "anglais",
        "lang": "en-fr",
        "wt": "m",
        "id": "QsEzWwJKGFoCMvvmn2OO"
    },
    {
        "lang": "en-fr",
        "wt": "n",
        "infexions": [
            "block",
            "blocks"
        ],
        "term": "block",
        "isPareto": true,
        "rank": 1311,
        "workspace_id": "anglais",
        "inflexion": "block, blocks",
        "inflexions": [
            "block",
            "blocks"
        ],
        "model": "lang-en",
        "id": "8T5b4XUvHufC3oB6RHBb"
    },
    {
        "lang": "en-fr",
        "inflexions": [
            "herself"
        ],
        "model": "lang-en",
        "inflexion": "herself",
        "wt": "fw",
        "rank": 1312,
        "term": "herself",
        "infexions": [
            "herself"
        ],
        "workspace_id": "anglais",
        "isPareto": true,
        "id": "KXViWCQdTkrC9ePOUY64"
    },
    {
        "rank": 1313,
        "model": "lang-en",
        "isPareto": true,
        "lang": "en-fr",
        "inflexion": "murder, murders",
        "term": "murder",
        "workspace_id": "anglais",
        "inflexions": [
            "murder",
            "murders"
        ],
        "wt": "n",
        "infexions": [
            "murder",
            "murders"
        ],
        "id": "9dsUMWgqZNKkZ8YLMxU9"
    }
]


const list = paretoCards_en.map(c => c.inflexion.split(',')).flat()


export {list, paretoCards_en}