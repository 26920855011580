import { initializeApp} from "firebase/app";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
  };

// console.log("firebaseConfig", firebaseConfig)


  console.log('firebaseConfig', firebaseConfig)
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


console.log('app', app)
console.log('db', db)


export {firebaseConfig, app, db}