import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, getDocs, query, limit} from "firebase/firestore";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { db } from "../../../config.js";
import { missions } from '../../../utilities/helper/helper.js';

const ShowInfoPop = (props) => {
    const ct = props.context
    const content = props.context?.showInfo?.content
    const title = props.context?.showInfo?.title
    const icon = props.context?.showInfo?.icon
   
    useEffect(() => {
    
    },[])


  
  return (
    <div id="showInfoPop" onClick={() => ct.setShowInfo()} className={`fixed z-[999] fredoka top-0 flex flex-col items-center justify-center z-[150] left-0 right-0 backdrop-blur-md	 bottom-0 bg-black/40`}>
      
      <div className='bg-black/90 rounded-xl border-4 border-black w-[320px] mx-auto'>
        <div className=' px-4 py-2 text-white flex justify-between'>
           <div className='flex gap-2 items-center'>{icon} {title}</div>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>

            </div>
        <div className={`p-4 rounded-xl ${ct?.showInfo?.classContent}`}>
            {content}
        </div>
      </div>
    </div>
  );
};

export {ShowInfoPop};