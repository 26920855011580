import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";

import { db } from "../../config.js";

const Chronos = (props) => {
    // console.log('chronos')
    const ct = props.ct;

 
    const [timeSpend, setTimeSpend] = useState(ct.timeSpend);
    const [readyToSend, setReadyToSend] = useState(ct.timeSpend);
    const [timeSpendForest, setTimeSpendForest] = useState(ct.timeSpendForest); // less learning
    // mobile
    // desktop
    // active
    // total
    // liane 
    // visiting
    // learning



    useEffect(() => {
        // console.log('chronos 2')
        if (props.type == "general") ct.setTimeSpend(timeSpend);
        if (props.type == "forest") ct.setTimeSpendForest(timeSpendForest);
        setReadyToSend(true)
        
    }, [ct.getTime]) 

    useEffect(() => {
        if (readyToSend) {
            !ct.showCards && reset()
        }
    }, [readyToSend, ct.showCards])


   

    const reset = () => {
        setTimeSpend(0);
        setReadyToSend(false)
        setTimeSpendForest(0);
        ct.setTimeSpend(0);
        ct.setTimeSpendForest(0);
        if (props.type != "general") return

        // console.log("Enregistrement du temps passé 💡💡💡💡", ct.timeSpend)
        // console.log("Temps passé déjà 💡💡💡", ct.userDaily)

        const newUserWorkspace = {
           
            timeSpend: ct.timeSpend + (ct.userWorkspace?.timeSpend || 0), 
            timeSpendForest: ct.timeSpendForest + (ct.userWorkspace?.timeSpendForest || 0),
            updatedDate: new Date(),
        }
        ct.setUserWorkspace(uwp => ({...uwp, ...newUserWorkspace}))
        updateDoc(doc(db, 'user_workspace', ct.userWorkspace.id), {...newUserWorkspace});
        ct.fire.updateDaily({...ct.userDaily, ts: ct.timeSpend + (ct.userDaily?.ts|| 0) }, ct)
        
    }

    useEffect(() => {
       
        if (timeSpend > 12 || timeSpendForest > 12) {
            if (props.type != "general") return
            // console.log('maj setgettime', timeSpend)
             ct.setGetTime(new Date())
            // quand on est pas dans learn on recharge pas
            
          
        }
       
    }, [ct.lastActivity])

    // useEffect(() => {
    //     if (timeSpend > 30) {
    //         reset()

    //     }
    // }, [timeSpend])
    

    useEffect(() => {
      
       const interval = setInterval(() => {
        const currentTime = Date.now();
        const timeSinceLastActivity = currentTime - ct.lastActivity;

      
       // if (timeSinceLastActivity < 60000) {
            if (props.type == "general") setTimeSpend(prevTime => prevTime + 5);
            if (props.type == "forest") setTimeSpendForest(prevTime => prevTime + 5);
      //  }

       }, 5000);
 

       return () => clearInterval(interval);
    }, []);
 
    return (
       <div onClick={() => {console.log('uw', ct.userWorkspace)}} className={`fixed hidden left-0 z-[100] bg-red-400 top-0 `}>
           {/* <div>Temps passé G : {ct.timeSpend}</div>
           <div>Temps passé Forêt : {ct.timeSpendForest}</div>
           <div>TimeSpend : {timeSpend}</div>
           <div>GetTime : {ct.getTime?.toLocaleDateString()}</div> */}
          
          
       </div>
    );
 };
 
 export {Chronos};