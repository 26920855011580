
import { useState,useRef, useEffect } from "react";
import { db, app } from "../../config.js";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


import {displayDate} from '../../utilities/helper/helper'
import { collection, addDoc, updateDoc,deleteDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";

import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { WeekSreak } from "../components/weekStreak.js";
import { ParticipationNote } from "../components/participationNote.js";
import { Link } from "react-router-dom";



function transformText(text) {
    // Convertir en minuscules
    let lowercaseText = text?.toLowerCase();
  
    // Enlever toute ponctuation
    let cleanedText = lowercaseText?.replace(/[.,!?;¡:¿()“"'\r]/g, " ").trim().replaceAll('a:', '').replaceAll('b:', '')
  
    return cleanedText;
  }
function removeDuplicateWords(words) {
    return [...new Set(words)];
  }


  const removeDigits = (inputString) => inputString.replace(/\d+|\n|\r/g, '')
  const removeNoDigits = (inputString) => inputString.replace(/[^\d\n\r]/g, '');


  const worskpaces = [
    {
      lang: "es",
      name: "espagnol", 
      id: "esp"
    },
    {
        lang: "de",
        name: "allemand", 
        id: "allemand"
    },
    {
      lang: "en",
      name: "Anglais", 
      id: "anglais-gb"
  },
    {
      lang: "it",
      name: "italien", 
      id: "italien"
  }
  ]




const Pareto = (props) => {
  const ct = props.context

    //  console.log('props', props)
    const wp_id = ct.workspace?.id;
    const defaultIndexWorkspace = worskpaces.findIndex(workspace => workspace.id === wp_id) === -1 ? 0 : worskpaces.findIndex(workspace => workspace.id === wp_id);
    const workspace = worskpaces[defaultIndexWorkspace]



    const [story, setStory] = useState()
    const [stories, setStories] = useState()
    const [darkMode, setDarkMode] = useState(props.darkmode)
    const [animation, setAnimation] = useState(true)    
    const [typeContext, setTypeContext] = useState(0)


    const [dico, setDico] = useState(props.dico)
    const [dicoFormated, setDicoFormated] = useState()
    const [allDicoInflexion, setAllDicoInflexion] = useState()

    /// preselected pareto


    // useEffect(() => {
    //   setDico(props.dico)
    //   const words = props.dico?.split(';')
    //   setDicoFormated(words)
    //   const _inflexions = words.map(e => ({word: transformText(e.split('|')[1]), inflexions: [...e.split('|')[3]?.split(','), transformText(e.split('|')[1])]})).flat()
    //   console.log('_inflexions', _inflexions)
    //   setAllDicoInflexion(_inflexions)

    // }, [props.dico])


 

   
    const [deck, setDeck] = useState()
    const [deckWords, setDeckWords] = useState()


  const loadDico = async (wp_id) => {
    console.log('loadDico !!', wp_id)
    const q = query(collection(db, 'card_map'), where('wp_id', "==", wp_id))
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => doc.data())[0]
    setDico(data?.dico_term_inflexions)
    const words = data.dico_term_inflexions?.split(';')
    setDicoFormated(words)
    console.log('_inflexions 🐸')
    const _inflexions = words.map(e => ({word: transformText(e.split('|')[1]), inflexions: [...e.split('|')[3]?.split(',') || [], transformText(e.split('|')[1])]})).flat()
    console.log('_inflexions 🐸', _inflexions)
    setAllDicoInflexion(_inflexions)
  }

  
    

 
  const loadDeck = async (wp_id) => {
    if (darkMode) {
        setDeck(ct.decks)
        setDeckWords(ct.decks?.map(d => d.words).join(';').split(';').map(e => transformText(e)))
        return
    }
    const q = query(collection(db, 'decks'), where('workspace_id', "==", wp_id), where('presentation_mode', '==', 'Grammar'))
    const querySnapshot = await getDocs(q);
    const decks = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

    console.log('decks', decks)
    setDeck(decks)
    setDeckWords(decks?.map(d => d.words).join(';').split(';').map(e => transformText(e)))


  }


  const loadTexts = async (wp_name) => {
    const docRef = doc(db, "fq", wp_name);
    const docSnapshot = await getDoc(docRef);
  
    ct.setfqlist(docSnapshot.data().all)
    const stories = {
        dialogue: docSnapshot.data().dialogue, 
        article: docSnapshot.data().article,
        histoire: docSnapshot.data().histoire
    }
    setStories(stories)
    setStory(stories["dialogue"])
        
  }


//   console.log('ct', ct)
  useEffect(() => {
    if (workspace){
        loadTexts(workspace.name)
        loadDeck(workspace.id)
        loadDico(workspace.id)
    }
    ct.setShowBottomBar(false)

    return () => {
        ct.setShowBottomBar(true)
    }
  }, [workspace])

  const animateSlider = (startValue, endValue) => {
    const duration = darkMode ? 3000 :  10000; // Durée de l'animation en ms
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = elapsedTime / duration;

      const currentValue = startValue + (endValue - startValue) * Math.min(progress, 1);
      setNWords(currentValue);

      if (progress < 1) {
        animRef.current = requestAnimationFrame(animate);
      }
    };

    animRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && nWords === 0 && !darkMode && story) { // Vérifiez si l'animation est toujours active
            animateSlider(0, 1200); // Démarrez l'animation de 0 à 1200
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );
  
    const inputElement = inputRef.current;
    if (inputElement) {
      observer.observe(inputElement);
    }
  
    return () => {
      observer.unobserve(inputElement);
      if (animRef.current) {
        cancelAnimationFrame(animRef.current); // Nettoyez l'animation frame si active
      }
    };
  }, [animation, story]);

  


  const [nWords, setNWords] = useState(0)

  const inputRef = useRef(null);
  const animRef = useRef(null);
  
  
  let filteredCards, filteredDecks, deckWordsPrivate
  const nWordsCard = nWords - (deckWords?.length || 0) > 0 ? nWords - (deckWords?.length || 0) : 0
  // let allTermsWithInlexions2 = cards?.slice(0, nWordsCard).flatMap(c => c.inflexions?.map(inflexion => transformText(inflexion))).concat(cards?.slice(0, nWordsCard)?.map(card => transformText(card.term)));
  let allTermsWithInlexions = allDicoInflexion?.slice(0, nWordsCard).flatMap(c => c.inflexions?.map(inflexion => transformText(inflexion))).concat(allDicoInflexion?.slice(0, nWordsCard)?.map(card => transformText(card.word)));
  let allWordsCards = deckWords?.slice(0, nWords).concat(allTermsWithInlexions)

  if (darkMode && nWords == 0) {
    filteredCards = ct.cards?.map(card => {
        const user_card = ct.userCards?.find(uc => uc.card_id == card.id)
        return {...card, user_card}
      })

    filteredDecks = ct.decks?.map(deck => {
        const user_deck = ct.userDecks?.find(uc => uc.deck_id == deck.id)
        return {...deck, user_deck}
      })
    deckWordsPrivate = filteredDecks.filter(d => d.user_deck).map(d => d.words).join(';').split(';').map(e => transformText(e))
    
    allTermsWithInlexions = filteredCards?.flatMap(c => c.inflexions?.map(inflexion => transformText(inflexion))).concat(filteredCards?.map(card => transformText(card.term)));
    allWordsCards = deckWordsPrivate.concat(allTermsWithInlexions)
   
  }
 
  const arrayStory = (story)?.replaceAll("  ", " ").replace(/\n/g, ' \n ')?.split(" ").map(e => e == " " ? "" : e)
//   console.log('arrayStory', arrayStory)
  const storyWithData = arrayStory?.map(word => {

    const inCards =  allWordsCards?.includes(transformText(word.trim()))
    // console.log('index_50k', text_50k)
    return {w: word,inCards}
})

  const ttWords = arrayStory?.length
  const masteredWords = storyWithData?.filter(e => e.inCards)?.length
  const cent = Math.round(masteredWords*100/ttWords) || 0

  const contentType = ["dialogue", "article", "histoire"]
  
  const btnContent = <span onClick={() => {
    const newTypeContext = typeContext < 2 ? typeContext + 1 : 0
    setTypeContext(newTypeContext)
    setStory(stories[contentType[newTypeContext]])
  }}  className={`px-1  rounded ${darkMode ? "bg-purple-500 text-white/50" : "text-purple-900 bg-purple-200"}   opacity-[.8]`}>{contentType[typeContext]}</span>

  let title = <div onClick={() => {loadDeck(workspace?.id)}} className="lg:text-3xl  text-xl font-bold">Tu maîtriseras <span className="bg-green-100 lg:text-3xl inline-block text-center lg:w-[80px] w-[50px] rounded mr-1 text-green-600">{cent}%</span>de n'importe quel{typeContext == 2 ? "le":""} {btnContent} en {workspace?.name}</div>
  let title2 =  <div> Si tu connais les <span className="bg-green-100 inline-block text-center w-[105px] rounded  text-xl font-bold text-green-500">{Math.round(nWords)} mots</span>  les plus fréquents</div>

  if (darkMode) {
    title = <div onClick={() => {loadDeck(workspace.id)}} className="lg:text-3xl  text-xl font-bold">Tu maîtrises <span className="bg-green-500 text-green-900 inline-block text-center lg:w-[90px] w-[55px]  rounded lg:text-3xl text-xl  ">{cent}%</span> de n'importe quel{typeContext == 2 ? "le":""} {btnContent} en {workspace?.name}</div>
    title2 = <div>Grâce aux <span className="rounded inline-block text-center w-[85px]  font-bold bg-green-500 text-green-900">{nWords ? Math.round(nWords) : filteredCards?.filter(e => e.user_card)?.length} mots</span> que tu as appris</div>
  }


  

  return (<div className={`lg:p-8 p-4 fixed z-[999] fredoka overflow-scroll max-h-screen  ${darkMode ? "bg-indigo-900 pt-12 text-white/80 m-2 rounded-xl h-[98vh]" : "bg-[#fffef4] h-screen "}`}>
{/*    
    <div>allWordsCards: {allWordsCards?.length}</div>
    <div>deckWords: {deckWords?.length}</div>
    <div>index: {defaultIndexWorkspace}</div>
    
    <div>Nombre de mot dans le dico: {dicoFormated?.length} mots </div>
    <div onClick={() => console.log('dico', dico)} className="p-4 rounded-xl my-4 flex max-h-[200px] overflow-scroll flex-wrap gap-1">{dicoFormated?.map(word => <div className="px-1 rounded bg-black/10">{word.split('|')?.[1]} [{word.split('|')?.[3]?.split(',')?.length}] </div>)}</div>
    
     */}
    
    {darkMode ? <div onClick={() => ct.setShowPop()} className="absolute top-2 right-2 z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700" >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
    : ""}
    {title}

    <div className="my-4">
      <label htmlFor="nWordsSlider" className="block mb-2">
        {title2}
      </label>
      <input
        id="nWordsSlider"
        type="range"
        ref={inputRef}
        min="0"
        max={nWords ? 1500 : 1200}
        value={nWords || filteredCards?.filter(e => e.user_card)?.length}
        onChange={(e) => {setNWords(Number(e.target.value)); setAnimation(false)}}
        className={`w-full slider ${darkMode ? "dark" : ""} `}
      />
      <div className="bg-black/10 h-1 -mt-[6px] rounded-full">
        <div className={`${darkMode ? "bg-green-200" :"bg-green-400"}  h-1 rounded-full`} style={{width: `${nWords ? nWords*100/1500 : filteredCards?.filter(e => e.user_card)?.length*100/1200}%`}}></div>
      </div>
    </div>

     
     {story ? <div className="rounded my-8 flex gap-[2px] lg:max-h-[60vh] max-h-[42vh] overflow-scroll flex-wrap">{storyWithData?.map(e => {
        const br = (e.w.split(/\n/).length > 1) && <div className="grow block mt-2 min-w-[100px] w-full"></div>
        if (br) return br
        return <span className={` ${e.inCards ? 'bg-green-400 text-green-900' : ` ${darkMode ? "bg-purple-500 text-white/50" :"bg-purple-200 text-indigo-900"} `} px-[3px]  lg:px-[6px] rounded lg:text-base text-xs`}>{e.w}</span>
     })}</div> : <div className="flex items-center justify-center h-[200px]">Chargement...</div>}

     {!props.mini && <div className="flex flex-col items-start gap-2 ">
        <div className={`${darkMode ? "bg-purple-500 text-white/50" :"bg-purple-200"} bg-purple-200 text-purple-900 px-1 rounded`}>{ttWords - masteredWords} Mots à apprendre</div>
        <div className="flex flex-col items-start gap-1 rounded-xl ">
            <div onClick={() => {setDarkMode(!darkMode)}} className="bg-green-400 text-green-900 px-1 rounded mb-2">{masteredWords} Mots maitrisés</div>
            <div className={`${darkMode && nWords == 0 ? "" : "hidden"}`}>
                <div className=" text-sm py-[1px] text-green-500/80 px-1 rounded">- {deckWordsPrivate?.length} Mots de leçon de grammaire</div>
                <div className=" text-sm py-[1px] text-green-500/80 px-1 rounded">- {filteredCards?.filter(c => c.user_card?.archived).length} Mots déjà connus</div>
                <div className=" text-sm py-[1px] text-green-500/80 px-1 rounded">- {filteredCards?.filter(c => !c.user_card?.archived && c.user_card ).length} Mots appris avec les cartes</div>
            {/* <div className="ml-4 border border-green-500 text-green-500/80 px-1 rounded">{masteredWords} Mots à apprendre</div> */}
            </div>
            {darkMode && nWords != 0 ? <div onClick={() => setNWords(0)} className="">Revenir à mon score</div> : ""}

        </div>
     </div>}

   
   {/* {story} */}

     {/* {allWordsCards?.join(" ")} */}

     {/* <textarea id="content" className="p-2 rounded-xl w-full h-[100px]  bg-black/10 mt-2" placeholder="ajoute ton propre texte" onChange={(e) => {setStory(e.currentTarget.value)}}></textarea>
     <input id="type" defaultValue={"dialogue"} />
     <div onClick={async() => {
        const content = document.querySelector("#content").value
        const type = document.querySelector("#type").value
        console.log('content', content)
        console.log('type', type)
        console.log('workspace.name', workspace.name)
        const docRef = doc(db, "fq", workspace.name);
        console.log('docRef', docRef)
        const docSnapshot = await updateDoc(docRef, {[type]: content});
        console.log('docSnapshot', docSnapshot)


     }}>Envoyer</div>  */}
      </div>
  );
};

export {Pareto};

