import React, { createContext, useState } from 'react';

// Créez le contexte
const AppContext = createContext();

// Créez un fournisseur de contexte
const ContextProvider = ({ children }) => {
  // Définissez tous vos états ici



  let context = {
   
}

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, ContextProvider };