import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { collection, addDoc, query, getDocs, doc,getDoc, where, updateDoc, setDoc, deleteDoc, onSnapshot, Timestamp} from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import { db} from "../../config.js";


import { bg_sound, categories, centToStars, displayDuration, evolutionsQuest, getCardsData, getCardsFromIDS, getDate, getDeckData, getStarsFromTraining, getUserCardsData, images as imagesRaw, missingN, nToStars, pops, tutoForest} from '../../utilities/helper/helper';



import {getTitle, getCons, speak, capitalizeFirstLetter} from "../../utilities/helper/text.js"


import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { debounce, throttle } from "lodash";
import { ref } from "firebase/storage";
import {Howl, Howler} from 'howler';
// import { createAvatarUrl } from "../avatar/parts.js";





const SoundToggleButton = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  // Fonction pour jouer ou arrêter le son de fond
  const toggleBackgroundSound = () => {
    if (isPlaying) {
      bg_sound.pause();  // Pause le son
      bg_sound.currentTime = 0; // Remettre à zéro
    } else {
      bg_sound.play();  // Joue le son
    }
    setIsPlaying(!isPlaying);  // Inverse l'état
  };

  return (
    <div className="">
      <div onClick={toggleBackgroundSound}>
        {isPlaying ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
</svg>
}
      </div>
    </div>
  );
};




const delectedDoubleUserCard = (userCards) => {



  const seenCardIds = new Set();
  return userCards?.filter(userCard => {
    if (seenCardIds.has(userCard.card_id)) {
      console.log('suppresssion ❌', userCard.card_id)
      deleteDoc(doc(db, "user_cards", userCard.id))
      return false;
    } else {
      seenCardIds.add(userCard.card_id);
      return true;
    }
  });
  

}


function isPointInsideBiomes(pointX, pointY, biomes) {
  for (const biome of biomes) {
      // Vérifie si le point est à l'intérieur des limites du biome
      if (pointX >= biome.x && pointX < biome.x + biome.w && 
          pointY >= biome.y && pointY < biome.y + biome.h) {
          return true; // Le point est à l'intérieur de ce biome
      }
  }
  return false; // Le point n'est dans aucun biome
}


const layer1 = [ 
  {x: 3, y: 3, h: 9, w: 9, z:-3, name: "biome_1"},
  {x: -3, y: -3, h: 9, w: 9, z:-3,  name: "biome_1"},
  {x: -3, y: 3, h: 9, w: 9, z:-3, name: "biome_1"},
  {x: 3, y: -3, h: 9, w: 9 ,  z:-3,  name: "biome_1"}
]
const layer1_bis = [ 
  {x: -3, y: 3, h: 9, w: 9, z:-1, name: "biome_1"},
]

const layer2 = [
  {x: 6, y: 6, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 6, y: -6, h: 9, w: 9,   z:-1, name: "biome_1"},
  {x: -6, y: -6, h: 9, w: 9,  z:-1,name: "biome_1"},
  {x: -6, y: 6, h: 9, w: 9,  z:-1,name: "biome_1"},

  
  {x: -6, y: 3, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 6, y: 3, h: 9, w: 9, z:-1, name: "biome_1"},
  {x: 3, y: -6, h: 9, w: 9, z:-1, name: "biome_1"},
  {x: -3, y: 6, h: 9, w: 9, z:-1, name: "biome_1"},
]

const layer3 = [
  {x: 0, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},

  {x: 9, y: 9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 0, y: 9, h: 9, w: 9,   z:-1,name: "biome_1"},
  {x: 0, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},

 

]

const layer4 = [

  {x: 0, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},

  {x: 9, y: 9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 0, y: 9, h: 9, w: 9,   z:-1,name: "biome_1"},
  {x: 0, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},


  {x: -12, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: 0, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: -9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},

  {x: 12, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: 0, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: -9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},


  {x: -9, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 0, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 9, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},

  {x: -9, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 0, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 9, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},



]
const layer0 = [
  {x: 0, y: 0, h: 9, w: 9, z:-3,  name: "biome_1"},
]

let b = []







const versionExt = "?&v=2"

  const swipeFactor = 2



let dm = ""
let topX = 0
if (window.matchMedia('(display-mode: standalone)').matches) {
   dm = "standalone"
} else if (window.matchMedia('(display-mode: fullscreen)').matches) {
   dm = "fullscreen"
} else if (window.matchMedia('(display-mode: minimal-ui)').matches) {
   dm = "minimal-ui"
} else if (window.matchMedia('(display-mode: browser)').matches) {
   dm = "browser"
   topX = 100
   
} else {
   dm = "???"
}



const MiniCard = ({term}) => <div className="text-xs text-center pb-1">
<img src="/images/seeds/green-3.png" className={` ${term ? "-mt-4 -mb-1" : " -m-2 opacity-30 filter-grayscale"} h-[50px]   mx-auto`} />
<div className={`${
  term > 7
    ? term > 10
      ? 'text-[9px]'
      : 'text-[10px]'
    : ''
} overflow-hidden text-ellipsis whitespace-nowrap`}>
  {capitalizeFirstLetter(term)}
</div>
</div>

const renderInventoryItems = (evo, selectedCategories, userDecorationWithElement, evolutions) => {
  const cards = evo?.cards || [];
  const ingredients = evo?.ingredients || [];
  const recipe = evo.recipe
  console.log('evo', evo)
  // Ensure at least 4 items
  let items = [...(cards || [])];
  if (ingredients.length) {items = [...(ingredients || [])];}
  while (items.length < 4) {
    items.push({ card: { term: '' } });
  }

  console.log('userDecoration', userDecorationWithElement.map(ud => ud.id))
  console.log('items', items)
  const imagesName = items.map((item) => userDecorationWithElement.find(ud => ud.id == item && !ud.name.includes('seed'))?.element?.src || "seed")
  console.log('imagesName', imagesName)

  if (!ingredients.length) {

    if (recipe) {
     
      return items.map((item, index) => {
        const currentEvo = evolutions.find(evo => evo.name == recipe?.[index])
        return (
        <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
          {currentEvo?.name == "Fleur" ?   <PackedSeedImg className={"opacity-50 scale-[0.8]"} selectedCategories={selectedCategories} />  : <img className="opacity-50" src={currentEvo?.element.replace('@@@', selectedCategories.color_seed)}/>}
        </div>
      )});
    }
   
    return items.map((item, index) => (
      <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
        <MiniCard term={item?.card?.term} />
      </div>
    ));
  }
   // <PackedSeedImg selectedCategories={selectedCategories} />  
  return items.map((item, index) => (
    <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
     {(ingredients && !item.card) ?  
  ( 
    imagesName?.[index] != "seed" ? 
    <img src={imagesName?.[index]} className="h-[60px] mx-auto" /> :
      <PackedSeedImg selectedCategories={selectedCategories} /> 
      
  ) : 
  (
    <MiniCard term={item?.card?.term} />
  )
}
    </div>
  ));
};


const PackedSeedImg = ({selectedCategories, className}) => {

  return <div className={className+" relative pointer-events-none w-[45px] h-[45px] mx-auto"}>
  <img className="absolute -top-8 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -left-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -right-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute top-0 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />

</div>
}




const ForestScreen = (props) => {
    let url = window.location.href
    const searchParams = new URLSearchParams(url);
    const navigate = useNavigate()
    const visited_uwp = searchParams.get("uwp")
    let initialDistance;
    const setUserDecoration = props.setUserDecoration
    const userDecoration = props.userDecoration || []

    // Faire userDecorationAll ici et userDecoration en fonction du centre ou l'on se trouve 
    // Ajouter canvas dans un canvas pour des déplacement horizontal
    // Ajouter canvas ground et way dans une big image pour eviter les calculs inutiles pour le refresh 

    // Refresh ultime : tout mettre dans un canvas static et redraw uniquement les 9 cases autour du personnage 


    const deckData = props.deckData

    



 
    
  
    const ct = props.context || {};

    const userCardFast = deckData?.fastCards

    const [images, setImages] = useState([])

   
    const canvasRef = useRef(null);
    const visitedUWP = ct.visitedUWP
    const [showAlchimie, setShowAlchimie] = useState();
    const [key, setKey] = useState(0);
    const [showXP, setShowXP] = useState(false);
    const [packCards, setpackCards] = useState()
    const [_userCards, set_userCards] = useState()
    const [showSeed, setShowSeed] = useState()



    const lvls = [
      { exp: 10 },
      { exp: 30 },
      { exp: 80 },
      { exp: 140 },
      { exp: 280 }
    ];

    const lvlHouses = [
      { exp: 10 },
      { exp: 40 },
      { exp: 100 },
      { exp: 200 },
      { exp: 500 },
      { exp: 900 },
      { exp: 1200},
      { exp: 1800},
      { exp: 3000},
    ];

    const expDojo = getStarsFromTraining(ct.userTests)
    let currentLvlDojo = 0
    for (let i = 0; i < lvls.length; i++) { if (expDojo >= lvls[i].exp) { currentLvlDojo = i + 1; } else { break;}}
    let currentLvlHouse = 0
    const expHouse = _userCards?.length
    for (let i = 0; i < lvlHouses.length; i++) { if (expHouse >= lvlHouses[i].exp) { currentLvlHouse = i + 1; } else { break;}}
    

    const recharger = () => {
      setKey(prevKey => prevKey + 1);
    };
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [totalDx, setTotalDx] = useState(0) || [ct.totalDx || 0, ct.setTotalDx || 0]
    const [totalDy, setTotalDy] = useState(0) || [ct.totalDy || 0, ct.setTotalDy || 0]
    const [offX, setOffX] = useState(0);
    const [offY, setOffY] = useState(0);
    const [gridX, setGridX] = useState(0)
    const [gridY, setGridY] = useState(0)
    const [objs, setObjs] = useState([])
    const [showAnimation, setShowAnimation] = useState(true)
    const [editorMode, setEditorMode] = useState(false)
    const [loadingMAJCards, setLoadingMAJCards] = useState(false)

    const {selectedDeco, setSelectedDeco} = ct
    const [decorationMode, setDecorationMode] = useState()
    const [selectedCard, setSelectedCard] = useState()
    const [isZooming, setIsZooming]= useState(false)
    const [moveMode, setMoveMode] = useState(false)
    const loadedDecoration = ct.loadedDecoration

    const [talkMode, setTalkMode] = useState(false)


    const selectedDeck= ct.selectedDeck
    const setSelectedDeck = ct.setSelectedDeck


    const [popoverPos, setPopoverPos] = useState();
    const [hoverCard, setHoverCard] = useState()
    const [hoverContent, setHoverContent] = useState()

    const [selectedPos, setSelectedPos] = useState()
  
    const [hoverDeck, setHoverDeck] = useState()
    const [imgLoaded, setImgLoaded] = useState(false);
    const [userDecks, setUserDecks] = useState()
    const [canvasObj, setCanvasObj] = useState([])
    const [showQuest, setShowQuest] = useState()



    const [disco, setDisco] = useState(false)
    const [pinchDistance, setPinchDistance] = useState(false)
    const [pinchInitialDistance, setPinchItinialDistance] = useState(0)
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const initialTileSize = 65
    const [tileSize, setTileSize] = useState(65)
    const canvasOffset = 300
    const gridRows = 100
    const gridCols = 100

    const timeoutRef = useRef(null);

    const [nonPlacedCards, setNonPlacedCards] = useState()
    let packed_seeds = userDecoration?.filter(ed => ed.type == "pc").sort((a,b) => b.bc_id || -99 - a.bc_id || -99)
    // const nonPlacedCards = _userCards?.filter(uc => !(uc.x || uc.x ==0) && !(uc.y || uc.y ==0) && !(uc.ud_id)).filter(c => c)?.length
    const maxPack =  (nonPlacedCards - packed_seeds?.length*4)/4 

  let extLvl = visitedUWP ? (visitedUWP?.extLvl || 0) : ct.userWorkspace?.extLvl||0 

  let nb_biome = 1
  if (extLvl == 1) nb_biome = 5
  if (extLvl == 2) nb_biome = 13
  if (extLvl == 3) nb_biome = 20
  if (extLvl == 4) nb_biome = 28

  const priceExtension = [
    {gold: 200, packed: 16, seed: 64, elixir: 30}, 
    {gold: 500, packed: 40, seed: 180, elixir: 300},
    {gold: 1000, packed: 80, seed: 400, elixir: 1000},
    {gold: 3000, packed: 160, seed: 800, elixir: 5000},
    {gold: 5000, packed: 160, seed: 800, elixir: 7000},
    {gold: 7000, packed: 200, seed: 10000, elixir: 10000},
    {gold: 10000, packed: 300, seed: 12000, elixir: 12000},
    {gold: 20000, packed: 600, seed: 22000, elixir: 22000},
  ]






  // côté jeu avec notre personnage
 


  // niveau des étoiles qui évolue automatiquement


  // niveau du HDV qui permet de débloquer de nouvelle décoration

  let b = [...layer0]
  
  if (nb_biome == 5)  b = [...layer0,...layer1]
  if (nb_biome == 13) b = [...layer0,...layer1_bis,...layer2]
  if (nb_biome == 20) b = [...layer3]
  if (nb_biome == 28) b = [...layer4]

  // 20 packs (80) pour passer au niveau sup
  // 60 packs (240) pour passer au niveau sup
  // 120 packs (480) pour passer au niveau sup

  const [guests, setGuests] = useState([])
  const [msgs, setMsgs] = useState([])






   





  const resetForest = async () => {
    // lance la fonction init

    const plantedSeed = ct.userCards?.filter(uc => (uc.x || uc.y) || uc.x == 0 )
    const plantedTree = ct.userDecks?.filter(uc => (uc.x || uc.y) || uc.x == 0 )
    plantedSeed?.forEach(uc =>  ct.fire.updateUserCard({...uc, x: null, y: null}, ct))
    plantedTree?.forEach(uc =>  ct.fire.updateUserDeck({...uc, x: null, y: null}, ct))

    const decorations = objs?.filter(o => o.image?.deco)
    console.log('objs', objs)
    console.log('decorations', decorations)

    let totalMoney = decorations?.reduce((prev, o) => prev + o.image?.price, 0)    
    totalMoney =  totalMoney - 12
    totalMoney = totalMoney < 0 ? 0 : totalMoney
    
    decorations?.forEach(o => o.id && ct.fire.remove(o.id, "user_workspace_element"))
    ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: (ct.userWorkspace.coins || 0) +  totalMoney || 0}, ct)
    setObjs()
    alert('test 2')
    setUserDecoration(userDecoration?.filter(e => !decorations?.map(e => e.id).includes(e.id)))
    initForestObj(ct.userWorkspace?.id)
 
  }

  const hasHouse = objs?.find(o => o.image?.type == "house")
  // console.log("hasHouse", hasHouse)
  
  const initForestObj = async (uwp_id) => {
  
    let reload = false
    // creer deux chemins et la maison au centre
    const hasHouse = objs?.find(o => o.image?.type == "house")


  
    
    if (!hasHouse) {

      const house = images?.find(img => img.name == "house_0")
      const houseObjData = {x: 4, y: 4, name: house.name, uwp: ct.userWorkspace?.id}
      reload = true
      await createUserWorkspaceElement(houseObjData)
    }


    if (ct.workspace?.id == "anglais-gb") {
      
      const hasTemple = objs?.find(o => o.image?.type == "dojo")
      if (!hasTemple) {

        const dojo = images?.find(img => img.name == "temple_0")
        const dojoObjData = {x: 1, y: 1, name: dojo.name, uwp: ct.userWorkspace?.id}

        // await createUserWorkspaceElement(dojoObjData) !!creation dojo
      } else {
        console.log('temple déjà présent')
      }
      
    }

    if (reload) {
      // getUserWorkspaceElements(uwp_id)
    }

   

    // const way = images.find(img => img.name == "way")
    // const yValues = [5, 6, 7, 8]; // Array of y values

    // yValues.forEach(async y => {
    //     const wayObjData = { x: 4, y, name: way.name, uwp: ct.userWorkspace?.id };
    //     const wayObjPos = setPosObj([wayObjData], images, null, "deco")[0];
    //     if (!decorations.find(d => d.x == 4 && d.y == y) ) {

    //       await addDoc(collection(db, "user_workspace_element"), wayObjData);
    //       new_objs.push(wayObjPos);
    //     } else {}
      
    // });
   
    //ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: newCoins}, ct)
    //setObjs([...new_objs])
    
  
  }
  


   
  

    const updateUserCard = async (updated) => {
      console.log('updateUserCard 🟣', updated.id)
      ct.setUserCards(ct.userCards.map(uc => uc.id == updated.id ? {...uc, x: updated.x, y: updated.y} : uc))
      const docRef = await updateDoc(doc(db, "user_cards", updated.id), updated);
    
    }

    const createUserWorkspaceElement = async (element, userCards) => {
      console.log('new  createUserWorkspaceElement 🟢', element)
      console.log('move mode', moveMode)
      const id = (typeof moveMode === 'string' && moveMode.length > 5) ? moveMode : null;
      const newElement = { ...element };
      let docRef = null

      if (id) {
        console.log('deplacement en conservant id 🟣🟣🟣', id)
         docRef = await setDoc(doc(db, "user_workspace_element", id), newElement);
         docRef = {id}
         console.log('docRef', docRef)
      } else {
         docRef = await addDoc(collection(db, "user_workspace_element"), newElement);
      }
     
      setUserDecoration(prev => [...prev, {...element, id: docRef.id}])
      setSelectedEvo()

      if (userCards) {
        console.log('modification des user_card 🟣🟣🟣')
        
        userCards.forEach(ucA => {
          ct.setUserCards(ucB => ucB.map(uc => uc.id == ucA.id ? {...uc, ud_id: docRef.id} : uc))
          updateDoc(doc(db, "user_cards", ucA.id), {ud_id: docRef.id});
        })
        
      }
    }

   
    
    function sortArrayByXYSum(arr) {
      
      return arr?.filter(a => a && (a.x || a.x == 0)).sort((a, b) => {
       
        const sumA = a.x + a.y;
        const sumB = b.x + b.y;
        if (a.z === b.z) {
          return sumA - sumB;
        } else {
          return a.z -b.z;
        }
       
      });
    }


    
    
    
    const draw = (objs, dx = offX, dy = offY, i = 0, i2 = 0, extLvl) => {

      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      if (canvas) canvas.style.backgroundPosition = `${dx}px ${dy}px`;


      ctx?.clearRect(0, 0, canvas.width, canvas.height);

      objs?.forEach((el, index) => {fillObj(ctx, el,  {i, i2}) })


      drawGrid(ctx, {dx, dy});


     
      };


   
    
    const handleMouseDown = (e) => {
      setPopoverPos()
      setDragging(true);

     

      
     
      setStartX(e.clientX);
      
      setStartY(e.clientY);



 
      
    };




  

    const handleMouseUp = (e) => {

  
      const totalDiff = (e.clientX - startX) + (e.clientY - startY)

      const newDx = totalDx + (e.clientX - startX) * swipeFactor
      const newDy = totalDy + (e.clientY - startY) * swipeFactor
      console.log('handleMouseUp', totalDiff)

      
      setDragging(false);

      const maxDx = 4000*2 // 4000
      const minDx = -2500*2 // -2500
      const maxDy = 2500*2 // 2500
      const maxyDy = -2500
     
      setTotalDx(newDx > maxDx ? maxDx : newDx < minDx ? minDx : newDx) ;
      setTotalDy(newDy > maxDy ? maxDy : newDy < minDx ? minDx : newDy);
      // faire en sorte que ça soit par rapport à la position de mon personage si on est en mode personnage

      console.log('newDx', newDx)

      clearTimeout(timeoutRef.current);

     
    };
    
       

  
  

    const hoverElement = (clientX, clientY) => {
      var {top, left} = canvas?.getBoundingClientRect();
      const card = _userCards?.find(e => e.x == gridX && e.y == gridY)
      const deck = userDecks?.find(e => e.x == gridX && e.y == gridY)
      var mouseX = (clientX - offX) - left;
      var mouseY = (clientY - offY) - top;
      var [_gridX, _gridY] = isoToCar(mouseX, mouseY);
      const [_left, _top] = carToIso(gridX, gridY);

      setHoverCard(card)
      setHoverDeck(deck)
      setPopoverPos({left: _left + left - 120 + offX, top: _top + top + 50 + offY})
      
      setGridX(Math.round(_gridX*100)/100);
      setGridY(_gridY);
    }

    const handlePinchZoom = useCallback(
      (e) => {
        
        const x1 = e.touches[0].clientX;
        const y1 = e.touches[0].clientY;
        const x2 = e.touches[1].clientX;
        const y2 = e.touches[1].clientY;
        setIsZooming(true)
      
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const roundedDistance = Math.round(distance / 5) * 5;
      
        setPinchDistance(roundedDistance)
        if (pinchInitialDistance == null) {
          setPinchItinialDistance(roundedDistance)
          return;
        }
      
  
        // Détecter le zoom
        let newTileSize = tileSize
        if (roundedDistance > pinchInitialDistance) {
          
          if (tileSize < 100) {
            newTileSize = tileSize + 4
            setTileSize(tileSize + 4)
          }
      
        } else {
    
          if (tileSize > 10) {
            newTileSize = tileSize - 4
            setTileSize(tileSize - 4)
          }
        }
        const roundedTileSize = Math.round(newTileSize / 10) * 10;
        if (roundedTileSize != tileSize) setTileSize(newTileSize)
      
        //initialDistance = distance;
      
        if (roundedDistance != distance) setPinchItinialDistance(roundedDistance)

     
  
    
        // const zoomFactor = distance / pinchInitialDistance;
        // const newTileSize = Math.min(Math.max(initialTileSize * zoomFactor, 30), 100);
        // const roundedTileSize = Math.round(newTileSize / 5) * 5;

    
        // if(roundedTileSize != tileSize) setTileSize(roundedTileSize);

      },
     
      [initialTileSize, pinchInitialDistance]
    );

    const handleMouseMove = (e) => {
     
      if (dragging) {
        const dx = e.clientX - startX;
        const dy = e.clientY - startY;
        renderDraw(dx, dy)
        clearTimeout(timeoutRef.current);
        hoverElement(e.clientX, e.clientY )
        setPopoverPos()
      // Déplacer de 25% de plus que la distance de la souris
      }
    
      
    }

    const handleTouchStart = (e) => {
      // timeoutRef.current = setTimeout(longPress, 500);
      setPopoverPos()
      setDragging(true);
      if (e.touches.length === 2) {
        setPinchItinialDistance(null)
        setStartX((e.touches[0].clientX + e.touches[1].clientX) / 2);
        setStartY((e.touches[0].clientY + e.touches[1].clientY) / 2);
        setIsZooming(true)
      } else {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
      } 
    
     
    };
    
    const handleTouchMove = (e) => {

     

      if (dragging) {
        if (e.touches.length === 2) {
          handlePinchZoom(e)
          // const dx = e.touches[0].clientX - startX;
          // const dy = e.touches[0].clientY - startY;
          // renderDraw(dx, dy)
        } else {
          if (!isZooming){
          const dx = e.touches[0].clientX - startX;
          const dy = e.touches[0].clientY - startY;
          renderDraw(dx, dy)
          }
        }
       
      // Déplacer de 25% de plus que la distance de la souris
      }
      setPopoverPos()
      clearTimeout(timeoutRef.current);
    }
    
    const handleTouchEnd = (e) => {
      if (isZooming) {
        setDragging(false);
        setIsZooming(false)
        setTotalDx(ct.totalDx );
        setTotalDy(ct.totalDy);
      } else {
        setDragging(false);
    
        setTotalDx(totalDx + (e.changedTouches[0].clientX - startX) * swipeFactor);
        setTotalDy(totalDy + (e.changedTouches[0].clientY - startY) * swipeFactor);
      }
      clearTimeout(timeoutRef.current);
    };

    //const img = images.find(i => i.name == name).img


    const [bases, setBases] = useState([])
    const [availableBases, setAvailableBases] = useState()




    const starPoint = 3
    useEffect(() => {

      
      const _bases = []


      let size = 5
      if (extLvl == 0) size = 5
      if (extLvl == 1) size = 6
      if (extLvl == 2) size = 9
      if (extLvl == 3) size = 12
      if (extLvl == 4) size = 15
      if (extLvl == 5) size = 18
      if (extLvl == 6) size = 21
      if (extLvl == 7) size = 100

      


      let minus = (extLvl||1*2) + starPoint
      let counter = 0


      let gridRows = size; // Remplace par le nombre de lignes souhaité
      let gridCols = size; // Remplace par le nombre de colonnes souhaité

      for (let i = -size+minus ; i <= gridRows + starPoint; ++i) {
          for (let j = -size+minus ; j <= gridCols + starPoint ; ++j) {
            counter++
              // Ajouter chaque position à la liste
              _bases.push({ name: "base", x: j, y: i });
          }
      }


      setAvailableBases(counter)

      setBases(_bases)
    }, [extLvl])
   

    

    useEffect(() => {
      if (selectedDeck && !selectedDeck.deck) {
        const _userDecks = ct.userDecks?.filter(ut => !ut.x && ut.x != 0).map(c => {
         
          const deck = ct.decks?.find(deck => deck.id == c.deck_id)
          const image_name = `${deck?.type||"tree"}#1_${deck?.color||"green"}_5_mastered`
          const image = images.find(i => i.name == image_name)

          return {...c, deck, name: image_name, image}
        })

   
        const ud = _userDecks.find(e => e.id == selectedDeck.id)
        setSelectedDeck(ud)
      }

    }, [selectedDeck, key])
    
    const initForest = () => {
     
      const canvas = canvasRef?.current;
      if (!canvas) return 
      const ctx = canvas?.getContext('2d');

      const scaleFactor = 1.2;
      canvas.width = canvas.clientWidth * scaleFactor;
      canvas.height = canvas.clientHeight * scaleFactor;
      ctx.scale(scaleFactor, scaleFactor);
      draw(objs, offX, offY);
  }
  
  // Convertir les coordonnées de la souris ou d'autres coordonnées utilisateur en tenant compte de la mise à l'échelle
  const getMousePosition = (clientX, clientY, offX, offY, canvas) => {
      const rect = canvas.getBoundingClientRect();
      const scaleFactor = window.devicePixelRatio || 1;
  
      // Calculer la position de la souris par rapport à la mise à l'échelle
      const mouseX = (clientX - rect.left - offX) / scaleFactor;
      const mouseY = (clientY - rect.top - offY) / scaleFactor;
  
      // Convertir les coordonnées isométriques en coordonnées cartésiennes
      return isoToCar(mouseX, mouseY);
  }

    // var mouseX = (clientX - offX) - left;
    // var mouseY = (clientY - offY) - top;
    // var [_gridX, _gridY] = isoToCar(mouseX, mouseY);


    const initDataForest = (_packed_seeds) => {
      console.log('initDataForest 🌱🌱🌱🌱')

      if (_packed_seeds) {
        packed_seeds= _packed_seeds
        console.log('packseeds 🌱🌱🌱🌱', _packed_seeds)
      }


     



     

      let index_deck = 0
      const _userDecks = ct.userDecks?.filter(c => c.deck_id).map(c => {
        index_deck++
        const deck = ct.decks?.find(deck => deck.id == c.deck_id)
      
        const typeOfImage = deck?.type || deck?.presentation_mode == "Dialogue" ? "tree_s" : "tree"
        let color = "green"
        index_deck >= 1 && (color = index_deck%2==0 ? "blue" : "green")
        index_deck >= 4 && (color = index_deck%2==0 ? "yellow" : "blue")
        index_deck >= 8 && (color = index_deck%2==0 ? "pink" : "purple")
        index_deck >= 16 && (color = index_deck%2==0 ? "blue" : "green")
        
        const image_name = `${typeOfImage}#1_${deck?.color || color}_5_mastered`
        
        const image = images.find(i => i.name == image_name)

        return {...c, deck, name: image_name, image}
      })


      let currentColor = "purple"
      
      let indexPack = 0
      let indexCards = 0

      
      const defaultDate = new Date('1996-06-10');

      const globalDecksIDS = ct.globalDecks?.map(d => d.id) || []

      
      const __userCards = ct.userCards?.filter(uc => !uc.archived && uc.content).filter(uc => !uc.stacked).filter(uc => uc.content?.model != "grammar" && (globalDecksIDS.includes(uc.content.deck_id) || !uc.content.deck_id))
      .map(uc => ({...uc, created_date: uc?.created_date?.seconds ? new Date(uc.created_date.seconds * 1000) : uc?.created_date}))

      __userCards?.sort((a, b) => {
        const dateA = new Date(a.created_date || defaultDate);
        const dateB = new Date(b.created_date || defaultDate);
            return dateA - dateB;
        
    });


      let pack_index = 0
      let pack_icon_name = null

      const userCards1 = __userCards?.filter(uc => !uc.ud_id).map((c,i) => {
        const card = c.content
        let x = c.x
        let y = c.y
        if (!c.unpack) {
          x = null
          y = null
        }
        if (i%4==0) { pack_index ++ }
        
    
        const deck = ct.decks?.find(deck => deck.id == card?.deck_id)
        const pack = packed_seeds?.find(p => p.n == pack_index)
        // if (!pack) {console.log('pack not found', pack_index)}
        let params = null
       
        if (i%4 == 0) {
          indexPack++
          pack_icon_name = null
        }
       
        const next_date = c?.next_date?.seconds ? new Date(c.next_date.seconds * 1000) : c?.next_date
        const state = c?.archived ? "archived" : !next_date ? "to_learn" : next_date > new Date() ? "mastered" : "to_review"
        const halfDate = new Date(next_date);
        halfDate.setMinutes(halfDate.getMinutes() - ((60*24) - 3)); // Elle donne des elixirs 24h avant l'évolution si niveau 2 ou plus 
        const hasElixir = halfDate < new Date() && !c?.collected
        if (hasElixir) {
          pack_icon_name = "elixir_icon"
        }
      
        const icon_name = state == "to_review" ? "review_state" : null
        
        if (!card?.deck_id) {
          indexCards ++
        }
       

        currentColor = "green"

        const name =  state == "to_review" ?  `seed#1_${c.lvl}_review`: `seed#1_${currentColor}_${c.lvl}_mastered`
        
        //const name = "seed#1_purple_2_mastered"

        return {...c, card,x,y, deck, state, name, icon_name, pack, params, pack_index, pack_icon_name}
      }).filter(c => c.card) 

      pack_icon_name = null
      const userCards2 = __userCards?.filter(uc => uc.ud_id).map((c,i) => {
      
       

        const card = c.content
        let x = c.x
        let y = c.y
        if (!c.unpack) {
          x = null
          y = null
        }


       
        
    
        const deck = ct.decks?.find(deck => deck.id == card?.deck_id)
        const pack = userDecoration?.find(e => e.id == c.ud_id)
        const isSeed = pack?.name.includes('seed')
   
      
        currentColor = pack?.name.split('_')?.[1]


      
        // if (!pack) {console.log('pack not found', pack_index)}
        let params = null
       
   
        pack_icon_name = null
       
        const next_date = c?.next_date?.seconds ? new Date(c.next_date.seconds * 1000) : c?.next_date
        const state = c?.archived ? "archived" : !next_date ? "to_learn" : next_date > new Date() ? "mastered" : "to_review"
        const halfDate = new Date(next_date);
        halfDate.setMinutes(halfDate.getMinutes() - ((60*24) - 3)); // Elle donne des elixirs 24h avant l'évolution si niveau 2 ou plus 
        const hasElixir = halfDate < new Date() && !(c?.collected)
        const elixirDate = halfDate - ((1000*60*60*24) - (1000*60*3)) 
        const hasElixir2 = (elixirDate - new Date() < 0) && !(c?.collected)
       
        if (hasElixir) {
          pack_icon_name = "elixir_icon"
         
        }

        if (c.ud_id == "EehGCsO8JoqrKdHKSm94") console.log('LC ACARET !!', {c, pack_icon_name, hasElixir}) 

        
      
        const icon_name = state == "to_review" ? "review_state" : null
        const lvl = c.lvl < 1 ? 0 : c.lvl


     
       

        

        const name =  state == "to_review" ?  `seed#1_${c.lvl}_review`: `seed#1_${currentColor}_${c.lvl}_mastered`
        
        
        //const name = "seed#1_purple_2_mastered"


        return {...c, card,x,y, deck, state, name, icon_name, pack, params, pack_icon_name, v: !isSeed}
      })

   
   
     let params = null
     const ttCards = [...userCards1 || [], ...userCards2 || []]
      const userCards = ttCards?.sort((a, b) => (a.pack?.id || '').localeCompare(b.pack?.id || '')).map((c,i) => {
       
        let pack_icon_name
        
        const samePackCards = ttCards?.filter(uc => uc.pack?.id == c.pack?.id)
        const index = samePackCards?.findIndex(uc => uc.id == c.id);
        if (index % 4 == 0) { params = { packed: true, place: "top" }; }
        if (index % 4 == 1) { params = { packed: true, place: "left" }; }
        if (index % 4 == 2) { params = { packed: true, place: "right" }; }
        if (index % 4 == 3) { params = { packed: true, place: "bottom" }; }
      
      
       
        const packHasElixir = samePackCards?.find(uc => uc.pack_icon_name == "elixir_icon")
        const packHasReview = samePackCards?.find(uc => uc.state == "to_review")
       

        pack_icon_name = packHasReview ?  "review_state" : packHasElixir ? "elixir_icon" : ""


     
        

        return {...c, pack_icon_name, params}
      })


     




    //   if (maxPack < -1) {
    //   console.log('packed_seeds', packed_seeds);
  
    //   // Calculez le nombre d'éléments à supprimer
    //   const x = Math.floor(Math.abs(maxPack));
    //   console.log('Nombre d\'éléments à supprimer:', x);

    //   const seedsToRemove = packed_seeds.filter(pc => !pc.bc_id).slice(-x)
    //   seedsToRemove.forEach(pc => {
    //     setUserDecoration(ud => ud.filter(ud => ud.id != pc.id))
    //     ct.fire.remove(pc.id, "user_workspace_element")
    //     console.log('suppression packseed ❌', pc)
    //   })
    // }

      set_userCards(userCards)

      // console.log('uc', userCards[0])

      setUserDecks(_userDecks)

     

    

      const yellowDeco =  {x: selectedPos?.x, y: selectedPos?.y, afk: selectedPos?.date, c: 0, interface: true, name: selectedPos?.name, uwp: ct.userWorkspace?.id}




      const uds = [ yellowDeco,
        ...userDecoration?.map(ud => {
            if (ud.name === "temple_0") return {...ud, name: `temple_${currentLvlDojo}`};
            if (ud.name === "house_0") return {...ud, name: `house_${currentLvlHouse}`};
            if (ud.name?.includes("seed")) return {...ud, name: ""};
            // if (ud.avatar) return {...ud, avatar_id: ct.user.id};
            return ud;
        })
       
        // userAvatar
    ];

      // ajouter user ici 



      

    const base1 = setPosObj([{x: 10, y: 10, c: 0, name: "base"}], images, null, "deco", tileSize, canvasOffset)[0]
    const base2 = setPosObj([{x: 0, y: 10, c: 0,  name: "base"}], images, null, "deco", tileSize, canvasOffset)[0]
    const base3 = setPosObj([{x: -8, y: -20, c: 0, name: "champ-yellow"}], images, null, "deco", tileSize, canvasOffset)[0]
    const base4 = setPosObj([{x: -20, y: -8, c: 0, name: "seed#1_blue-2_2_mastered"}], images, null, "deco", tileSize, canvasOffset)[0]
    const base5 = setPosObj([{x: -17, y: -6, c: 0, name: "base1"}], images, null, "deco", tileSize, canvasOffset)[0]

    const test = [{x: 10, y: 10, c: 0, name: "base"}, {x: 0, y: 0, c: 0, name: "base"}]
     
      const __userDecks = setPosObj(_userDecks || [], images, null, "deck")
      const _objs = setPosObj(test || [], images, null,  "deco")
      // const yellowSquare = setPosObj(users?.filter())
      
      //ici ?





     const seeds = setPosObj(userCards?.filter(uc => uc.state !="archived" ) || [], images, "seed")

    




      // const seedsPacked = setPosObj(userCards || [], images, {place: "right", packed: true}, "seed")

     

     

    //  _objs.map((obj) => obj.name == "temple_0" ? {...obj, } : obj)


      // const temple = images?.find(img => img.name == "temple_4")
      // const templeObjData = {x: 8, y: 1, name: temple?.name, uwp: ct.userWorkspace?.id}
      // const templeObjPos = setPosObj([templeObjData], images, null, "deco")[0]
      
      // _objs?.push(templeObjPos)
      //setUserDecoration([...userDecoration, templeObjData])

      

      
   
     


      const allGround = userDecoration.filter(e => e.type == "ground").map(e => ({x: e.x, y: e.y}))
      const filteredBase = bases.filter(e => !allGround.find(g => g.x == e.x && g.y == e.y))
      const biomes = setPosObj(filteredBase || [], images, null, "biome")

      
    

    
      setObjs([biomes, _objs, seeds, __userDecks].flat())

    }



    
   


    useEffect(() => 
    {
      if (ct.showCards?.length) return 
      if (userDecoration?.length < 1 || !_userCards) return
      // console.log('userDecoration', userDecoration)
      let packed_seeds = userDecoration?.filter(ed => ed.type == "pc")
      const nonPlacedCards = _userCards?.filter(uc => !(uc.x || uc.x ==0) && !(uc.y || uc.y ==0) && !(uc.ud_id))?.length
      const maxPack =  (nonPlacedCards - packed_seeds?.length*4)/4 
      setNonPlacedCards(nonPlacedCards)
      
      console.log('changement de maxPack', maxPack)
      console.log('packed_seeds', packed_seeds.length)
      console.log('placedCard', nonPlacedCards)
      


      if (ct.statusCards?.length && ct.userWorkspace?.id ) {
        console.log('PLANTAGE DE GRAINE !! 🌳🌳🌳🌳')
        console.log('maxPack !!', maxPack)
       


        if (maxPack > 0) {
          ct.setStatusCards()
          setSelectedDeco({type: "pc", uwp: ct.userWorkspace?.id, n: missingN(packed_seeds)[0] })
        } else {
          setSelectedDeco()
          ct.setStatusCards()
          ct.setAlerts([{title: "Tous tes souvenirs sont déjà plantés" }])

        }
     
      }
    }, [ct.statusCards, ct.userWorkspace?.id, userDecoration, _userCards, ct.showCards])

   

   







    // essayer avec des guest

   












    /// faire un userEffect qui ajoute des images en fonction des guests avec leur ids 

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      });
    };

    const loadNewImage = async (newImage) => {
      try {
        // Charger l'image principale
        newImage.img = await loadImage(newImage.src);
    
        // Charger les variantes
        if (newImage.variants) {
          newImage.variant_img = {};
    
          for (const variantKey in newImage.variants) {
            newImage.variant_img[variantKey] = await Promise.all(
              newImage.variants[variantKey].map((variantSrc) => loadImage(variantSrc))
            );
          }
        }

        
    
        // Ajouter l'objet chargé à l'état images
        setImages((prevImages) => [...prevImages, newImage]);
        return newImage
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    
    useEffect(() => {
      let numImagesLoaded = 0;
      let numImagesFailed = 0;
      let imagesCopy = [...imagesRaw]; // Créez une copie de imagesRaw
    
      imagesCopy.forEach((img, imgIndex) => {
        // Charger l'image principale
        const image = new Image();
    
        if (img.avatar) {
          const avatarParameters = {
            c1: 'blue',
            c2: 'red'
          };
          
          // Utiliser une URL d'avatar si nécessaire
        //   image.src = createAvatarUrl(avatarParameters);
        } else {
          
          image.src = img.src;
        }
    
        image.onload = function () {
          numImagesLoaded++;
          img.img = image; // Mettre à jour la propriété img
          checkAllImagesLoaded();
        };
    
        image.onerror = function () {
          numImagesFailed++;
          checkAllImagesLoaded();
        };
    
        // Charger les images d'animation
        if (img.animations && img.animations.length > 0) {
          img.anim_img = [];
          img.animations.forEach((animSrc, index) => {
            const animImage = new Image();
            animImage.src = animSrc;
            animImage.onload = function () {
              numImagesLoaded++;
              img.anim_img[index] = animImage; // Stocker l'image dans anim_img à l'index correspondant
              checkAllImagesLoaded();
            };
            animImage.onerror = function () {
              numImagesFailed++;
              checkAllImagesLoaded();
            };
          });
        }
    
        // Charger les variantes
        if (img.variants) {
          img.variant_img = {}; // Initialiser le champ variant_img
          Object.keys(img.variants).forEach((variantKey) => {
            img.variant_img[variantKey] = [];
            img.variants[variantKey].forEach((variantSrc, index) => {
              const variantImage = new Image();
              variantImage.src = variantSrc;
              variantImage.onload = function () {
                numImagesLoaded++;
                img.variant_img[variantKey][index] = variantImage; // Stocker l'image dans variant_img à l'index correspondant
                checkAllImagesLoaded();
              };
              variantImage.onerror = function () {
                numImagesFailed++;
                checkAllImagesLoaded();
              };
            });
          });
        }
      });
    
      function checkAllImagesLoaded() {
        const totalImages = imagesRaw.length + imagesRaw.reduce((acc, img) => {
          const animationsCount = img.animations ? img.animations.length : 0;
          const variantsCount = img.variants ? Object.values(img.variants).flat().length : 0;
          return acc + animationsCount + variantsCount;
        }, 0);
    
        if (numImagesLoaded + numImagesFailed === totalImages) {
          setImages(imagesCopy); // Mettre à jour l'état avec les images chargées
          setImgLoaded(true);
          initForest();
          initDataForest();
        }
      }
    }, [window.innerWidth]);



    // modifier l'herbe des biomes, et ajouter une bordure très légères 

    // en fonction de la position x de y et je modifie le filtrage de user_decoration
    // je transforme tout les ground en canvas unique
    // je trie toute les user_cards qui apparaisse à l'écran 
    // ==> Performance max 
 

    useEffect(() => {
     
      console.log('changmenet image')
      const uds = userDecoration?.map(ud => {
        if (ud.name == "temple_0") return {...ud, name: `temple_${currentLvlDojo}`}
        if (ud.name == "house_0") return {...ud, name: `house_${currentLvlHouse}`}
        if (ud.name?.includes("seed")) return {...ud, name: ""}
        return ud
       })

       const _userDecks = ct.userDecks?.filter(c => c.deck_id).map((c, index_deck) => {

        const deck = ct.decks?.find(deck => deck.id == c.deck_id)
      
        const typeOfImage = deck?.type || deck?.presentation_mode == "Dialogue" ? "tree_s" : "tree"
        let color = "green"
        index_deck >= 1 && (color = index_deck%2==0 ? "blue" : "green")
        index_deck >= 4 && (color = index_deck%2==0 ? "yellow" : "blue")
        index_deck >= 8 && (color = index_deck%2==0 ? "pink" : "purple")
        index_deck >= 16 && (color = index_deck%2==0 ? "blue" : "green")
        
        const image_name = `${typeOfImage}#1_${deck?.color || color}_5_mastered`
        
        const image = images.find(i => i.name == image_name)

        return {...c, deck, name: image_name, image}
      })
      const seeds = setPosObj(_userCards?.filter(uc => uc.state !="archived" ) || [], images, "seed")
      // console.log('uds', uds)

          
      const allGround = userDecoration.filter(e => e.type == "ground").map(e => ({x: e.x, y: e.y}))
      const filteredBase = bases.filter(e => !allGround.find(g => g.x == e.x && g.y == e.y)) || []
    
      const biomes = setPosObj(filteredBase || [], images, null, "biome")
      const _objs = setPosObj(uds || [], images, null,  "deco")
      const __userDecks = setPosObj(_userDecks || [], images, null, "deck")
    
 

      setObjs([biomes, _objs, seeds, __userDecks].flat())
      renderDraw(null, null);

     
    }, [tileSize, images, bases, selectedDeco, moveMode]) 

    


  //   const refreshCanvasObjs = (newOffX, newOffY, i, i2) => {
  //     let _objs = updatePosDeck(objs, newOffX, newOffY);;
  //     const map = _objs?.map(e => ({ ...e, t: "obj" })) || [];
  //     const __objs = sortArrayByXYSum(map) || [];

  //     draw(__objs, newOffX, newOffY, i, i2);
  // };

  let cachedObjs = null;
  let lastOffX = null; 
  let lastOffY = null;
  let timeoutId = null; // ID du timeout pour le debounce
  
  const refreshCanvasObjs = (newOffX, newOffY, i, i2) => {
    if (timeoutId) {
        clearTimeout(timeoutId); // Annuler le timeout précédent si une nouvelle mise à jour est en cours
    }
    
    // Définir un nouveau timeout pour regrouper les mises à jour
    timeoutId = setTimeout(() => {
        // Seules les dernières valeurs sont prises en compte pour le recalcul
        if (newOffX !== lastOffX || newOffY !== lastOffY) {
                        // Mettre à jour les objets et les trier directement dans cachedObjs
            const updatedObjs = updatePosDeck(objs, newOffX, newOffY);
            cachedObjs = sortArrayByXYSum(updatedObjs.map(e => ({ ...e, t: "obj" })));

            lastOffX = newOffX; // Mettre à jour les dernières valeurs
            lastOffY = newOffY;
        }

        // Effectuer le rendu avec les objets triés mis à jour ou en cache
        draw(cachedObjs, newOffX, newOffY, i, i2);
    }, 25); // 25ms de délai avant d'appliquer la mise à jour, ajustable selon les besoins
};




    const renderDraw = (dx, dy, i, i2) => {



      if (!dx || !dy) refreshCanvasObjs(offX, offY, i, i2)
   
      const newOffX =  Math.round((totalDx + dx * swipeFactor)/5)*5
      const newOffY =  Math.round((totalDy + dy * swipeFactor)/5)*5
      setOffX(newOffX )
      setOffY(newOffY)
       if (newOffX != offX || newOffY != offY) {
        refreshCanvasObjs(newOffX, newOffY, i, i2)
       }
    }






    const velocity = 300
    const i = useRef(0);
    const i2 = useRef(0);
    const asc = useRef(true);
    const [steps, setSteps] = useState({}); // Objet qui contient les steps par avatar_id

    const [canceledAvatarIds, setCanceledAvatarIds] = useState([]); // Ajoutez cet état pour suivre les animations annulées

    
    const [animations, setAnimations] = useState([]);
    const animationQueue = useRef([]);
    const isAnimating = useRef(false);
    
    useEffect(() => {
      initDataForest();
      renderDraw(null, null, i.current, i2.current);
    
      // const interval = setInterval(() => {
      //   const packed_seeds = userDecoration?.filter(ed => ed.type === "pc");
      //   // renderDraw(null, null, i.current, i2.current);
      //   initDataForest(packed_seeds);
      // }, 2000);
    
      // return () => clearInterval(interval);
    }, []);
    



    
    const cancelAnimation = (avatarId) => {
      setCanceledAvatarIds(prev => [...prev, avatarId]);
      setSteps(prevSteps => {
        const newSteps = { ...prevSteps };
        delete newSteps[avatarId];
        return newSteps;
      });
    };



    useEffect(() => {
      // renderDraw(null, null, i.current, i2.current);
      if (dragging || isZooming || !showAnimation || ct.showCards) return;
    
      const fps = 30; // Frames per second
      const intervalTime = 1000 / fps; // Intervalle de temps entre les frames
      let animationInterval;
    
      const animateLoop = () => {
        // Animation de fond à 25 FPS
        renderDraw(null, null, i.current, i2.current);
        i2.current++;
    
        if (i.current === 24) asc.current = true;
        if (i.current === 0) asc.current = false;
        asc.current ? i.current-- : i.current++;
      };
    
      animationInterval = setInterval(animateLoop, intervalTime);
    
      return () => {
        clearInterval(animationInterval);
      };
    }, [dragging, isZooming, showAnimation, _userCards, objs]); 
    
  
    
    const animateTransition = useCallback((oldPlayerObj, newPlayerObj, duration) => {
      const steps = [];
      const stepCount = 7; // Nombre d'étapes d'interpolation souhaitées
      const interval = duration / stepCount; // Durée entre chaque étape
    
      for (let i = 0; i <= stepCount; i++) {
        const factor = i / stepCount;
        const stepTime = performance.now() + i * interval; // Calcul du temps pour chaque étape
    
        const interpolatedObj = {
          pos: {
            x1: oldPlayerObj.pos.x1 + (newPlayerObj.pos.x1 - oldPlayerObj.pos.x1) * factor,
            x2: oldPlayerObj.pos.x2 + (newPlayerObj.pos.x2 - oldPlayerObj.pos.x2) * factor,
            x3: oldPlayerObj.pos.x3 + (newPlayerObj.pos.x3 - oldPlayerObj.pos.x3) * factor,
            x4: oldPlayerObj.pos.x4 + (newPlayerObj.pos.x4 - oldPlayerObj.pos.x4) * factor,
            y1: oldPlayerObj.pos.y1 + (newPlayerObj.pos.y1 - oldPlayerObj.pos.y1) * factor,
            y2: oldPlayerObj.pos.y2 + (newPlayerObj.pos.y2 - oldPlayerObj.pos.y2) * factor,
            y3: oldPlayerObj.pos.y3 + (newPlayerObj.pos.y3 - oldPlayerObj.pos.y3) * factor,
            y4: oldPlayerObj.pos.y4 + (newPlayerObj.pos.y4 - oldPlayerObj.pos.y4) * factor,
          },
        };
    
        const img = newPlayerObj.image?.variant_img?.[newPlayerObj.variant]?.[Math.floor(factor * 6) % 6] || oldPlayerObj.img;
    
        steps.push({
          avatar_id: oldPlayerObj.avatar_id,
          startTime: stepTime,
          finalState: { ...interpolatedObj, img, reverse: (newPlayerObj.variant == "rt" || newPlayerObj.variant == "rb") , test:"cc", x: newPlayerObj.x, y: newPlayerObj.y, chat: newPlayerObj.chat },
        });
      }
    
      return steps;
    }, []);
    
    const getTransitionForOneStep = (direction, deco, accumulatedTime = 0) => {
    
      if (!deco) return [];
    
      let old_player_obj = setPosObj([deco], images, null, "deco")?.[0];

     
    
      switch (direction) {
        case "up":
          deco.y--;
          deco.variant = "lt";
          break;
        case "down":
          deco.y++;
          deco.variant = "lb";
          break;
        case "left":
          deco.x--;
          deco.variant = "rt";
          break;
        case "right":
          deco.x++;
          deco.variant = "rb";
          break;
        default:
          break;
      }
    
      let player_obj = setPosObj([deco], images, null, "deco")?.[0];
    

      const steps = animateTransition(old_player_obj, player_obj, velocity);
        
      // Ajuster le startTime en fonction du temps accumulé
      const adjustedSteps = steps.map(step => ({
        ...step,
        startTime: step.startTime + accumulatedTime
      }));
    
      return adjustedSteps;
    };



    // create an image
    


  


//     const createAvatarObj = async (newDeco, guest) => {
//       console.log('guest', guest);
//       console.log('create avatar obj 🤡', guest);
  
//       let c1 = "pink";
//       let c2 = "purple";
  
//       if (guest.user_id === "GxIUEOziPSGeDwpP7MCs") {
//           c1 = "green";
//           c2 = "blue";
//       }
  
//       // Générer les URLs des variantes de l'avatar de manière asynchrone
//       const src = await createAvatarUrl({ code: guest.avtc });
//       const variants = {
//           lb: await Promise.all([
//               createAvatarUrl({ code: guest.avtc, variant: "lb_0" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lb_1" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lb_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lb_3" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lb_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lb_1" })
//           ]),
//           lt: await Promise.all([
//               createAvatarUrl({ code: guest.avtc, variant: "lt_0" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lt_1" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lt_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lt_3" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lt_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "lt_1" })
//           ]),
//           rt: await Promise.all([
//               createAvatarUrl({ code: guest.avtc, variant: "rt_0" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rt_1" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rt_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rt_3" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rt_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rt_1" })
//           ]),
//           rb: await Promise.all([
//               createAvatarUrl({ code: guest.avtc, variant: "rb_0" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rb_1" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rb_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rb_3" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rb_2" }),
//               createAvatarUrl({ code: guest.avtc, variant: "rb_1" })
//           ])
//       };
  
//       const newImage = {
//           name: newDeco.avatar_id,
//           fr: newDeco.avatar_id,
//           admin: true,
//           h: 50,
//           w: 50,
//           price: 0,
//           offY: -10,
//           unlock: 0,
//           avatar: true,
//           deco: true,
//           src, // Utilisation de l'URL PNG générée
//           variants // Utilisation des variantes générées
//       };
  
//       const imageLoaded = await loadNewImage(newImage);
//       console.log('imageLoaded', imageLoaded);
  
//       setUserDecoration(prev => [...prev, newDeco]);
//   };




  

 



    
    
  /// opitmisation


  // soit on tente l'animation direct dans boucle guest et on les lances la bas 
  // soit on fait le système avec les frames

    



























  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        console.log('coucou');
        const sendBtn = document.getElementById('send_btn')
        sendBtn?.click()

        
      }
    };

    // Ajoutez l'écouteur d'événements lors du montage du composant
    window.addEventListener('keydown', handleKeyPress);

    // Nettoyez l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);




  function drawGrid(ctx, { dx, dy }) {
    let bonus = 0;
   

    // if (extLvl >= 0 && extLvl <= 6) {
    //     size = 3 * (extLvl + 1) + bonus;
    // }


    let size = 5
    if (extLvl == 0) size = 6
    if (extLvl == 1) size = 6
    if (extLvl == 2) size = 9
    if (extLvl == 3) size = 12
    if (extLvl == 4) size = 15
    if (extLvl == 5) size = 18
    if (extLvl == 6) size = 21

    size += 10


    let minus = (extLvl||1*2) + starPoint

    const max = -size + minus
    const min = size + 1

    ctx.beginPath();
    ctx.strokeStyle = "rgba(250, 250, 0, 0.5)";

    // Dessiner les lignes horizontales isométriques (diagonales descendantes)
    for (let i = max; i <= min; ++i) {
      const [x1, y1] = carToIso(max, i);
      const [x2, y2] = carToIso(min, i);
      ctx.moveTo(x1 + dx, y1 + dy);
      ctx.lineTo(x2 + dx, y2 + dy);
  }

  // Dessiner les colonnes isométriques (diagonales montantes)
  for (let i = max; i <= min; ++i) {
      const [x1, y1] = carToIso(i, max);
      const [x2, y2] = carToIso(i, min);
      ctx.moveTo(x1 + dx, y1 + dy);
      ctx.lineTo(x2 + dx, y2 + dy);
  }

    ctx.stroke();
}

function isOutGrid(x, y, bonus = 10) {

  let size = 5
  if (extLvl == 0) size = 6
  if (extLvl == 1) size = 6
  if (extLvl == 2) size = 9
  if (extLvl == 3) size = 12
  if (extLvl == 4) size = 15
  if (extLvl == 5) size = 18
  if (extLvl == 6) size = 21


  size += bonus

  let minus = (extLvl||1*2) + starPoint
  const min = -size + minus + 1
  const max = size

  if (x < min || x > max || y < min || y > max) {
      return true; // Le point est en dehors de la grille
  }
  return false; // Le point est à l'intérieur de la grille
}

    

        function fillObj(ctx, obj, params) {    
          const frame = 16;
          const f = obj.image?.animation ? 1 + params.i / (20 * frame) : 1;
          const slowFactor = 1; // Plus ce facteur est élevé, plus l'animation sera lente
      

        

          if (obj.afk && (obj.afk - new Date() < 0)) { 
              return 
            // return
          }
          
          const indexImg = obj.img_frame 
              ? Math.floor((params.i2 / slowFactor) % obj.img_frame.length)
              : 0;
      
          let img = obj.img_frame ? obj.img_frame[indexImg] : obj.img;
      
          if (obj.img && obj.type != "hidden" && img) {

            if (obj.reverse) {
             
   
              ctx.save(); 
    
              // Translater et inverser le contexte
              ctx.translate(obj.pos.top + obj.w, obj.pos.bottom + (obj.h - (obj.h * f)));
              ctx.scale(-1, 1);
              
              // Dessiner l'image inversée
              ctx.drawImage(img, 0, 0, obj.w, obj.h * f);
              
              // Restaurer le contexte
              ctx.restore();
            } else {
              ctx?.drawImage(img, obj.pos.top, obj.pos.bottom + (obj.h - (obj.h * f)), obj.w, obj.h * f);
            }
           
          }
          if (obj.icon && !visitedUWP && obj.iconObj) {
              ctx?.drawImage(obj.icon, obj.pos.x3 + obj.iconObj?.offX, obj.pos.y3 - obj.iconObj?.offY, obj.iconObj.width, obj.iconObj.height);
          }
          if (ctx && obj.pos && obj.h && obj.w && obj.avatar_id) {
             
              const guest = guests.find(g => g.user_id == obj.avatar_id);

              if (!guest) return;
            
              // Nom de l'invité
              ctx.font = "14px fredoka"; // Police et taille du texte
              ctx.textAlign = "center"; // Centrer le texte horizontalement
              ctx.lineWidth = 3; // Épaisseur du contour
      
              // Dessiner le contour noir du texte
              ctx.strokeStyle = "purple";
              // ctx.strokeText(guest.name, obj.pos.top + obj.w / 2, obj.pos.bottom + (obj.h - 40));
      
              // Dessiner le texte blanc par-dessus
              ctx.fillStyle = "white";
              // ctx.fillText(guest.name, obj.pos.top + obj.w / 2, obj.pos.bottom + (obj.h - 40));
      
              if (guest?.rmvc < new Date() || !guest?.chat) return;
      
            // Dessiner la bulle de chat
        const padding = 10;
        const radius = 8;

        // Mesurer la largeur et la hauteur du texte, et gérer les sauts de ligne
        const text = guest?.chat || "...";
        const words = text?.split(' ');
        const maxWidth = text.length > 12 ? 150 : 80; // Largeur maximale de la bulle

   
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);

        const lineHeight = parseInt(ctx.font, 10); // Taille de la police, utilisée comme hauteur de ligne
        const bubbleWidth = maxWidth + padding * 2;
        const bubbleHeight = lineHeight * lines.length + padding * 2;

        const bubbleX = obj.pos.top + obj.w / 2 - bubbleWidth / 2;
        const bubbleY = obj.pos.bottom + (obj.h - 60) - bubbleHeight / 2;

        // Dessiner un rectangle arrondi pour la bulle
        ctx.fillStyle = "#f5f5dc"; // Fond beige
        ctx.strokeStyle = "black"; // Contour noir
        ctx.lineWidth = 2;

        // Dessiner le rectangle arrondi
        ctx.beginPath();
        ctx.moveTo(bubbleX + radius, bubbleY);
        ctx.arcTo(bubbleX + bubbleWidth, bubbleY, bubbleX + bubbleWidth, bubbleY + bubbleHeight, radius);
        ctx.arcTo(bubbleX + bubbleWidth, bubbleY + bubbleHeight, bubbleX, bubbleY + bubbleHeight, radius);
        ctx.arcTo(bubbleX, bubbleY + bubbleHeight, bubbleX, bubbleY, radius);
        ctx.arcTo(bubbleX, bubbleY, bubbleX + bubbleWidth, bubbleY, radius);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        // Dessiner le texte du chat à l'intérieur de la bulle, ligne par ligne
        ctx.fillStyle = "black";
        for (let i = 0; i < lines.length; i++) {
            ctx.fillText(lines[i], obj.pos.top + obj.w / 2, bubbleY + padding + (i + 1) * lineHeight - 3);
        }
    }
}
     const base = tileSize/50



     const setPosObj = (o, images, params, type) => {

      return o.map(obj => {
        
        const x = obj?.pack?.x == 0 ? 0 : obj?.pack?.x || obj.x
        const y = obj?.pack?.y == 0 ? 0 : obj?.pack?.y || obj.y
        const w = obj.w || 1
        const h = obj.h || 1
      
  
        var [x1, y1] = carToIso(x, y)
        var [x2, y2] = carToIso((x + w), y)
        var [x3, y3] = carToIso((x + w), (y + h))
        var [x4, y4] = carToIso(x, (y + h))
  
        const image_name = obj?.name
        
        const image = images?.find(i => i.name == image_name)
        const img_frame = image?.anim_img
        // bomb
        const icon = images?.find(i => i.name == obj.icon_name || i.name == obj.pack_icon_name)
        let iconObj = null

        const z = obj?.z == 0 ? 0 : obj?.z || image?.z == 0 ? 0 : image?.z || 1

           
        const img = image?.img
        

        let height = image?.h || 100
        let width = image?.w || 100
        let offY = image?.offY * base || 0
        let offX = image?.offX * base || 0

        
        if (obj.params?.packed) {
         
          height = height/1.3
          width = width/1.4
          if (obj.params?.place == "top") { 
            offY = offY - base*20
          } 
          if (obj.params?.place == "left") { 
            offY = offY - base*10
            offX = offX - base*25
          } 
          if (obj.params?.place == "right") { 
            offY = offY - base*10
            offX = offX + base*25
          } 
          if(icon) {
       
            if(icon.name == "elixir_icon" || icon.name == "review_state") {
               if (obj.params?.place == "top") iconObj = {width: base*icon.size, height: base*icon.size, offY:  base*(30 + icon.offY), offX: base*(5+ icon.offX)}
            } else {
              iconObj = {width: base*icon.size, height: base*icon.size, offY:  base*(40 + icon.offY), offX: base*(5+ icon.offX)}

            }
          }
        } else {
          if(icon) iconObj = {width: base*icon.size+8, height: base*icon.size+8, offY:base*(10), offX: base*10}
        }

        const w_pos = width*(tileSize/55.5)
        const h_pos = height*(tileSize/55.5)
    
        const pos = {
          x1: x1 + offX, x2: x2 +offX, x3: x3 +offX, x4: x4 +offX , 
          y1: y1 +  offY, y2: y2 +  offY, y3: y3 + offY, y4: y4 + offY,
          top: x3 - obj.w/2, bottom: y3 + offY - obj.h
        }
  
  
        return {...obj, pos, x, y, z, img, img_frame, height, width, h: h_pos, w: w_pos, type: obj?.v ? "hidden" : type, icon: icon?.img, iconObj, image}
       
      })
  }

      const updatePosDeck = (objs, offX, offY) => {

        return objs.map(obj => {
          if (obj.pos) {
            const x3 = obj.pos.x3 + offX
            const y3 = obj.pos.y3 + offY
            return {...obj, pos: {
            x1: obj.pos.x1 + offX, x2: obj.pos.x2 + offX, x3: x3, x4: obj.pos.x4 + offX, x5: obj.pos.x5 + offX, x6: obj.pos.x6 + offX, 
            y1: obj.pos.y1 + offY, y2: obj.pos.y2 + offY, y3: y3, y4: obj.pos.y4 + offY, y5: obj.pos.y5 + offY, y6: obj.pos.y6 + offY, 
            top: x3 - (obj.w/2), bottom: y3 - obj.h
          }} } else return obj
        })
    }


    const carToIso = (x, y) => {
        x *= tileSize;
        y *= tileSize;
        return [
          Math.round((x - y) * Math.sqrt(3) / 2 + canvasOffset ),
          Math.round((x + y) / 2 )
        ];
        }
      
      const isoToCar = (a, b) => {
         // Convert isometric (a, b) to cartesian coordinates
          var xMinusY = (a - canvasOffset) / Math.sqrt(3) * 2;
          var xPlusY = (b) * 2;
          var x = (xMinusY + xPlusY) / 2;
          var y = xPlusY - x;
          return [
            Math.floor(x / tileSize), // scaling is here too
            Math.floor(y / tileSize)
          ];
        }

     

     





    // const allMasteredCards = _userCards?.filter(u => u.next_date > new Date()).length
    // const allCardToDo =  _userCards?.length - allMasteredCards 

    
  
  // const audio = new Audio('/assets/fleur.mp3');
  // const playAudio = () => {
    
   
  //   !disco ? audio.play() : audio.pause()
  // };

  const money = ct.userWorkspace?.coins || 0



  const imageWithData = images.filter(el => el.deco && (!el.wp || el.wp == ct.workspace?.id) ).reverse().map(t => {
    const canBuy = t?.price <= (ct.userWorkspace?.coins ||0)
    const canUnlock = t?.unlock <= (ct.userWorkspace?.elixir ||0)
    const unlockedDeco = userDecoration?.find(ud => ud.type == "unlock" && ud.name == t.name);
    const isLockedByExilir = t.unlock ? !(!!unlockedDeco) : false;
    const isLockedByLvl = false
    const isPurchasable = !isLockedByExilir && !isLockedByLvl
    const isAvailable = canBuy && isPurchasable
    return {...t, canBuy, canUnlock, unlockedDeco, unlockedDeco, isLockedByExilir, isLockedByLvl, isPurchasable, isAvailable}
  })

  







  











const [selectedEvo, setSelectedEvo] = useState()


const masteredCards = props.masteredCards

const lastMessageRef = useRef(null);




  const [usedBase, setUsedBase] = useState()

 
  // console.log('outerGround', outerGround)




  useEffect(() => {
    const waterBase = userDecoration?.filter(e => e.type == "ground" && e.name == "water")?.length
    const outerGround = userDecoration?.filter(e => e.type == "ground" && e.name != "water" && isOutGrid(e.x, e.y, 0))?.length
    const bonus = ((extLvl||0)+1)*9

    setUsedBase(outerGround  - (waterBase + bonus ))
  }, [userDecoration, extLvl])


const evolutions = [
  {name: "Fleur", n: 4, lvl: 1, pc: true,  eName: "seed#1_@@@_2_mastered", element: "/images/seeds/@@@-3.png"},
  {name: "Orchidée", n: 8, lvl: 2, eName: "orchidax-@@@", element: "/images/seeds/evo/orchidax-@@@.png", recipe: ["Fleur", "Fleur"]},
  {name: "Champignon", n: 16, lvl: 3, eName: "champ-@@@", element: "/images/seeds/evo/champ_@@@.png", recipe: ["Orchidée", "Orchidée"]},
  {name: "Jardin", n: 32, lvl: 4, eName: "forest-@@@", element: "/images/seeds/evo/forest-@@@.png", recipe: ["Champignon", "Champignon"]}
  ]


  console.log('props', props)

 

    return (
     
     <div className="fixed  w-full bg-blue-400 overflow-hidden by h-screen max-h-screen z-90 sb">
      
      

     


         

       

          


          
    
       <canvas 
          ref={canvasRef}
          className={`${disco && "disco"} no-last-time bg-water p-4 top-0 sb left-0 cursor-pointer`}
          id="hexgrid" 

          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}

          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
         
          
          ></canvas>

       

          

      

            
            <div className="absolute hidden bg-red-300 top-0 p-4">
              <div className="">{offX} - {offY} | {offX - startX} |  {gridX} - {gridY} | {dragging ? "yes" : "no"}</div>
            <div className="flex hidden gap-2">
            <div  onClick={() => setShowAnimation(prev => !prev)} className={`${showAnimation ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>animate</div>
            <div  onClick={() => setEditorMode(!editorMode)} className={`${editorMode ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>editor</div>
            <div  onClick={() => {setDisco(!disco);}} className={`${disco ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>disco</div>
            </div>
            </div>


            

           




 
  

  

 

      </div>
    );
  };
  
  export {ForestScreen};