import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { PaintableLetter } from './paintableLetter';
import { shuffleArray, speak, stopSound } from '../../../utilities/helper/text';
import { badSound, validSound } from './learnContainer';
import { KoreanCanvas, KoreanSVG, arabicLetters, click_sound2, highlightLetterArabic, highlightVowels, validSound2, wordsByRime } from '../../../utilities/helper/helper';
import HanziWriter from 'hanzi-writer';



const renderWordsByRime = (rimeGroup, card, lang) => {
    return rimeGroup.map(e => {
        const [sign, pinyinRaw] = e.word.split('(');
        const pinyin = pinyinRaw.slice(0, -1);
        const letter = pinyin[0];
        return { ...e, sign: sign.trim(), pinyin, letter };
    }).filter(e => e.letter === card.latin[0])
    .map(e => (
        <div className='px-2 rounded-xl bg-white/10' key={e.sign} onClick={() => speak({ text: e.sign, lang })}>
            {e.pinyin}
        </div>
    ));
};

const ChineseCharacterRender = ({ text }) => {
    const characterDivRef = useRef(null);
    const writerRef = useRef(null); // Stocke le writer pour un contrôle ultérieur
  
    useEffect(() => {
      if (characterDivRef.current) {
        const writer = HanziWriter.create(characterDivRef.current, text, {
          width: 120,
          height: 120,
          padding: 5,
          radicalColor: '#8DCFFF', // Couleur des radicaux
          strokeColor: '#BCB6FF', // Couleur des traits
          outlineColor: "#8B82F8", // Couleur des contours
          showOutline: true, // Affiche le contour du caractère
          strokeAnimationSpeed: 2, // Vitesse du tracé des traits
          delayBetweenStrokes: 300, // Délai entre les traits en millisecondes
          drawingColor: '#000', // Couleur du tracé
        });
  
        writerRef.current = writer; // Enregistre l'instance pour un accès ultérieur
        writer.loopCharacterAnimation(); // Démarre l'animation en boucle
      }
    }, [text]);
  
    return (
      <div
        className='p-2 rounded-xl flex items-center justify-center '
        ref={characterDivRef}
        style={{ width: 120, height: 120 }}
      ></div>
    );
  };
  


const ChineseCharacter = ({ text, next, showAnimation }) => {
    const characterDivRef = useRef(null);
    const writerRef = useRef(null); // Stocke le writer pour un contrôle ultérieur
    const [animationEnded, setAnimationEnded] = useState()
  
    useEffect(() => {
      if (characterDivRef.current) {
        const writer = HanziWriter.create(characterDivRef.current, text, {
          width: 200,
          height: 200,
          padding: 5,
          radicalColor: '#8DCFFF', // green
          strokeColor: '#BCB6FF', // pink
          outlineColor: "#493B9A",
          showOutline: true, // Affiche le contour du caractère
          strokeAnimationSpeed: 2, // Vitesse du tracé des traits (1 est normal, 0.5 est plus lent)
          delayBetweenStrokes: 0.3,   
          drawingColor: '#000', // Couleur du tracé
        });
        writerRef.current = writer; // Enregistre l'instance pour un accès ultérieur
        showAnimation ? animateCharacter() : startQuiz()
      }
      
    }, [text]);
  
    const startQuiz = () => {
        if (writerRef.current) {
          writerRef.current.quiz({
            leniency: 1.2,
            onCorrectStroke: () => {
              validSound.play();
            },
            onComplete: () => {
              writerRef.current.animateCharacter({
                showOutline: false,
                strokeAnimationSpeed: 10, // Augmente encore la vitesse de l'animation
                delayBetweenStrokes: 0, // Supprime le délai entre les traits
                radicalColor: '#337ab7'
              });
              next(); // Appelle la fonction next après l'animation
            }
          });
        }
      };
  
    const animateCharacter = () => {
      if (writerRef.current) {
        writerRef.current.animateCharacter({
            onComplete: () => {
                setAnimationEnded(true)
                startQuiz()
                 // Démarre le quiz interactif
            }
          });
        // Anime le caractère de manière automatique
      }
    };
  

  
    return (
      <div className="flex flex-col items-center space-y-4">
        <div
          className='p-2 rounded-xl flex items-center justify-center bg-draw'
          ref={characterDivRef}
          style={{ width: 200, height: 200 }}
        ></div>
        <div className="flex space-x-2">
          {/* <button
            onClick={startQuiz}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Dessiner la lettre
          </button> */}
          <button
            onClick={animateCharacter}
            className="border-2 mb-2 px-2 py-2 grow text-white/90 mt-2 rounded-xl border-black/40 border-b-4 text-center"
          >
            {showAnimation ? "Revoir" : "Voir la correction"}
          </button>
         
        </div>
      </div>
    );
  };
  

  // māo máo
  // shǒu shū shù

const tonsSimilar = [
    {mp3: "https://chine.in/mandarin/methode/mp3/2/shu1.mp3", pinyin: "shū", meaning: "livre"},
    {mp3: "https://chine.in/mandarin/methode/mp3/2/shu4.mp3", pinyin: "shū", meaning: "arbre"},

]

const tons = [
    {
        "letter": "妈",
        "latin": "mā",
        "name": "1er ton",
        "words": [
            {mp3: "https://chine.in/mandarin/methode/mp3/2/mao1.mp3", pinyin: "māo", meaning: "chat"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/che1.mp3", pinyin: "chē", meaning: "voiture"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/shu1.mp3", pinyin: "shū", meaning: "livre"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/taxi.mp3", pinyin: "chūzūchē", meaning: "taxi"},
        ],

        "svg": <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="257" height="257" rx="8" fill="white" fill-opacity="0.1"/>
        <line x1="47.9826" y1="60.0001" x2="219.983" y2="59" stroke="#FF62A4" stroke-width="6"/>
        <line x1="27.9883" y1="140.5" x2="220.012" y2="140.5" stroke="black" stroke-opacity="0.1" stroke-width="2" stroke-dasharray="8 8"/>
        </svg>
        ,
    },
    {
        "letter": "麻",
        "latin": "má",
        "name": "2ème ton",
        "words": [
            {mp3: "https://chine.in/mandarin/methode/mp3/2/ren2.mp3", pinyin: "rén", meaning: "personne"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/yang2.mp3", pinyin: "yáng", meaning: "mouton"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/cuillere.mp3", pinyin: "sháozi", meaning: "cuillère"},
        
        ],
        "svg": <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="257" height="257" rx="8" fill="white" fill-opacity="0.1"/>
        <line x1="33" y1="141" x2="225.023" y2="141" stroke="black" stroke-opacity="0.1" stroke-width="2" stroke-dasharray="8 8"/>
        <path d="M53 135L219.989 60.0032" stroke="#FF62A4" stroke-width="6"/>
        </svg>,
    },
    {
        "letter": "马",
        "latin": "mǎ",
        "name": "3ème ton",
        "words": [
            {mp3: "https://chine.in/mandarin/methode/mp3/2/gou3.mp3", pinyin: "gǒu", meaning: "chien"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/shou3.mp3", pinyin: "shǒu", meaning: "main"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/patate.mp3", pinyin: "tǔdòu", meaning: "pomme de terre"},
        ],
        
        "svg": <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="257" height="257" rx="8" fill="white" fill-opacity="0.1"/>
        <line x1="38.0117" y1="141.5" x2="230.035" y2="141.5" stroke="black" stroke-opacity="0.1" stroke-width="2" stroke-dasharray="8 8"/>
        <path d="M53.4304 192.996C118.269 284.14 154.862 258.523 220.419 117.999" stroke="#FF62A4" stroke-width="6"/>
        </svg>
        
        
    },
    {
        "letter": "骂",
        "latin": "mà",
        "name": "4ème ton",
        "words": [
            {mp3: "https://chine.in/mandarin/methode/mp3/2/shu4.mp3", pinyin: "shù", meaning: "arbre"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/yue4.mp3", pinyin: "yuè", meaning: "lune"},
            {mp3: "https://chine.in/mandarin/methode/mp3/2/patate.mp3", pinyin: "tǔdòu", meaning: "pomme de terre"},
        ],
        "svg": <svg width="257" height="257" viewBox="0 0 257 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="257" height="257" rx="8" fill="white" fill-opacity="0.1"/>
        <line x1="43.0234" y1="142" x2="235.047" y2="142" stroke="black" stroke-opacity="0.1" stroke-width="2" stroke-dasharray="8 8"/>
        <path d="M55.5 44L212.5 220" stroke="#FF62A4" stroke-width="6"/>
        </svg>
        
    }
   
]



const AudioBtn = ({text, lang, mp3}) => (
    <div onClick={() =>  speak({text: text, mp3: mp3, lang: lang})} className={'h-[100px] flex items-center justify-center w-[100px] rounded-[30px] bg-[#32246a] border-2 border-b-4 border-black/20'}>
            <svg   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-pink-100 w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

    </div>
)




const highlightText = (letter, text, text_latin, lang, className) => {
    const regex = new RegExp(`(${letter})`, 'g');
    const parts = text?.split(regex);

   

    if (lang == "ar-XA") {
        
        return <div>
            <div>{highlightLetterArabic([letter], text, className)}</div>
            {/* <div>{text}</div> */}
            </div>
    }

    return (
        <div className='text-4xl '>
            {parts.map((part, i) =>
                part === letter ? (
                    <span key={i} className={`` + className } >{part}</span>
                ) : (
                    <span key={i} className='text-white/50'>{part}</span>
                )
            )} 
        </div>
    );
}


const InputQCM = ({fied_id, difficulty, soundMode, array, correct, validAction, lang, card, setError, error, handleAddXp, getIndex, setBonus, isNew}) => {

    const answer = correct[`${fied_id}`]
    const [selectedProps, setSelectedProps] = useState()
    const [preSelectedProps, setPreSelectedProps] = useState()


    const [randomizedArray, setRandomizedArray] = useState()
    const selectedAnswer = randomizedArray?.[selectedProps]?.[`${fied_id}`]


    useEffect(() => {setRandomizedArray(shuffleArray(array))},[difficulty, array.length]) 


    console.log('randomizedArray', randomizedArray)
    console.log('array', array)






    console.log('array', array)

    useEffect(() => {
        if(!selectedAnswer) return
        console.log('selectedAnswer',selectedAnswer)
        console.log('answer',answer)
        if (selectedAnswer == answer) {
            validAction()
            validSound2.play()
            speak({text: (card.letter_speak || card.letter) + " . " + card.word , mp3: card.mp3, lang: lang})
            if (difficulty == 2) {
                setBonus(1)
            }
        } else {
            click_sound2.play()
            setError(e => (e || 0) + 1)
            // speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang})
        }

        console.log('array', array)
    }, [selectedAnswer])


    return <div className={`mt-4 p-1  rounded 

    `}>
        <div className={`grid text-xl  ${array.length%3 == 0 ? "grid-cols-3" : "grid-cols-2"}  gap-2`}>
        {randomizedArray?.map((e, i) => <div onClick={(ev) => {
            if (soundMode || isNew) {
            setPreSelectedProps(i)
            console.log('setPreSelectedProps', i)
            soundMode && speak({text: e?.[`letter_speak`] || e[`letter`]  , mp3: e[`mp3`], lang: lang}) 
            } else {
            const correct = randomizedArray?.[i]?.[`${fied_id}`] == answer
            console.log('answer', answer)

            
           
            console.log('correct', correct ? "ui" : "non")
            if (error == 0) {
                handleAddXp(ev, correct ? difficulty : difficulty == 2 ? -1 : 0)
            }
            if (difficulty == 2 && error == 0 && correct ) {
                setTimeout(() => {
                    setSelectedProps(i)
                }, [350])
                getIndex(true, 1, card.cat != "pinyin" ? 1 : 4)

            } else {
                setSelectedProps(i)
            }
            
            } 
        }} className={`border-2 ${ selectedProps == i ? answer == e[`${fied_id}`] ? "bg-green-500" : "bg-red-500" : ""} ${preSelectedProps == i  ? "bg-blue-500" : ""} px-2 py-2 grow rounded-xl border-black/40 border-b-4 text-3xl text-center`}>{
        soundMode ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
      </svg>
      : e[`${fied_id}`]
        }</div>)}
        </div>
        {(soundMode || isNew) ? <div onClick={(ev) => {
            const correct = randomizedArray?.[preSelectedProps]?.[`${fied_id}`] == answer
          
            if (error == 0) { handleAddXp(ev, correct ? difficulty : difficulty == 2 ? -1 : 0)}
            if (difficulty == 2 && error == 0 && correct) {
                getIndex(true, 1, card.cat != "pinyin" ? 1 : 4)
            } else {
                setSelectedProps(preSelectedProps)
            }
            }} className={` ${preSelectedProps == null ? "opacity-50" : ""} px-6 p-4 bg-black/10 border-2 mt-2 border-b-4 border-black/50 text-center fredoka rounded-xl`} >Valider</div> : ""}
    </div>
    }


const LetterCard = ({card, userCard, cards, defaultTrigger, getIndex, lang, handleAddXp}) => {   
    let fontClass = ""
    // let fontClass = 'fredoka'
    if (card.workspace_id == "Arabe") {
        fontClass = "vazirmatn"

    }

  
    const [triggerMode, setTriggerMode] = useState()
    const [inputState, setInputState] = useState(0)
    const [randomCards, setRandomCards] = useState([])
    const [error, setError] = useState(0)
    const [bonus, setBonus] = useState(0)
    const [randomN, setRandomN] = useState(0)
    const [randomPos, setRandomPos] = useState(0)

    const [difficulty, setDifficulty] = useState()

    const lvl_trigger = userCard?.triggers?.[userCard?.next_trigger]?.lvl
    const modeExercice = lvl_trigger > 4 ? lvl_trigger%2 == 0 ? 1 : 0 : 0

   





    useEffect(() => {
        setError(0)
        setBonus(0)
        setInputState(0)
        stopSound()
        setDifficulty()
        const random = Math.floor(Math.random() * (3 + 1))

        console.log('random???', random)
        setRandomN(random)

        
       
        const lvl_trigger = userCard?.triggers?.[userCard?.next_trigger]?.lvl
        let contextMode = lvl_trigger%2 == 0

        
       
        if (!userCard?.next_trigger) 
            {setTriggerMode("new_card")
            if (card.isTon) {
                setTriggerMode("ton_new")
                
            }

            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_new")
                
            }
        
        } 
            
    
        if (userCard?.next_trigger == 1) {
            if (card.isTon) {
                setTriggerMode("ton_audio")
                return
            }
            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_audio")
                return
            }

            setTriggerMode(contextMode ? "audio_letter_context": "audio_letter" )
            speak({text: contextMode ? card.word : card.letter , mp3: card.mp3, lang: lang}) 
        } 
        if (userCard?.next_trigger == 0)    {
            if (card.isTon) {
                setTriggerMode("ton_write")
                return
            }
            if (card.cat == "pinyin") {
                setTriggerMode("pinyin_latin")
                return
            }
            setTriggerMode(contextMode ?  "letter_latin" : "letter_latin_context" )
        }

       
           
    }, [userCard, card.id])


    useEffect(() => {
        let otherCards = shuffleArray(cards.filter(c => c.letter != card.letter)) 
        if (card.isTon) otherCards = shuffleArray(cards.filter(c => c.isTon).filter(c => c.letter != card.letter)) 
        if (card.pinyin_f) otherCards = shuffleArray(cards.filter(c => c.pinyin_f).filter(c => c.letter != card.letter))
      
      

   
        
        // prendre en priorité 2 cartes déjà apprisent
        
        let length = triggerMode == "letter_latin" ? 2 : 3
        if (difficulty == 0) { 
            length = 1
            
        }
        if (difficulty == 2) { 
            length = triggerMode == "letter_latin" ? 3 : 5
            
        }
        setRandomPos(Math.floor(Math.random() * (length)))

        const _randomCards = shuffleArray([card, ...otherCards.slice(0,length)]) 
        console.log('------_randomCards', _randomCards)
        setRandomCards(_randomCards)
        console.log('----cards', cards)
    }, 
    [cards?.length, card, difficulty, triggerMode])



    const inputOpen = (easyMode = false) => <div className='flex -mx-1 fredoka justify-between items-center  gap-1'>
    <div onClick={(e) => {
        setTimeout(() => {
            setDifficulty(0); 
            setInputState(e => e+1); 
            click_sound2.play()
        }, 300)
        }} className='border-2 game-btn bg-pink-500/20 mb-2 px-1 h-[66px] relative flex text-shadow font-[500] pt-[26px] text-white/90 items-center justify-center text-sm grow w-[100%] rounded-xl border-black/60 border-b-[4px] text-center'>Je sais pas
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 top-2 text-white/20 absolute">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>

    </div>
    <div onClick={(e) => {
        setTimeout(() => {setDifficulty(1); setInputState(e => e+1); click_sound2.play()}, 300)
        }} className='border-2 game-btn bg-purple-500/20 mb-2 px-1 h-[66px] relative flex text-shadow font-[500] pt-[26px] text-white/90 flex items-center justify-center text-sm grow w-[100%] rounded-xl border-black/60 border-b-[4px] text-center'>Je sais
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  className="h-6 top-2 text-white/20 absolute">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
        </svg>

        </div>
            {easyMode && <div onClick={(e) => {
                setTimeout(() => {setDifficulty(2); setInputState(e => e+1); click_sound2.play()}, 300)
                }} className='border-2 game-btn  bg-cyan-500/20 mb-2 px-1 h-[66px] relative flex text-shadow font-[500] pt-[26px] text-white/90 flex items-center justify-center text-sm grow w-[100%] rounded-xl border-black/60 border-b-[4px] text-center'>Trop facile
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 top-2 text-white/20 absolute">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
            </svg>
        </div>}
</div>



    const ton_audio = (n) => <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto mt-[100px] flex justify-center'>
        <svg  onClick={() =>  speak({text: card.letter_speak || card.letter , mp3: card.mp3, lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
    </div>
    <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka  mt-8'>Quelle ton fais ce son ?</div>

    <div  id="input" className={`text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>

        {n == 0 && <>
            <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word ,  lang: lang}) ; setTriggerMode()}} />
        </>}
        {n == 1 && <>
        {inputState == 0 && inputOpen() }
        {inputState == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </>}
    </div>
    </div>



            // faire les correlaction 4 sons à droite 4 lettre à gauche avec les sons (mais s'adapte pas au cerveau de l'utilisateur )
        const ton_write = (n) => <div className='p-4 relative h-full text-red-400'>

            
        <div className='mx-auto mt-[100px] flex justify-center'>
        <AudioBtn text={card.letter} mp3={card.mp3} lang={lang}/>
    </div>      
        <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka  mt-8'>Quelle ton fais ce son ?</div>

        <div  id="input" className={`text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>

            {n == 0 && <>
                <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />
            </>}
            {n == 1 && <>
            {inputState == 0 && inputOpen() }
            {inputState == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
            </>}
        </div>
        </div>

    const ton_new = (n) => <div className='p-4 relative h-full  text-red-400'>
        <div className='flex justify-center absolute top-2 left-0 right-0'><div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
     <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
   </svg>
   </div></div>
    <div className='mx-auto mt-[100px] flex justify-center'>
        <AudioBtn text={card.letter} mp3={card.mp3} lang={lang}/>
    </div>
    <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka  mt-8'>Quelle ton fais ce son ?</div>

    <div  id="input" className={`text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>

        {n == 0 && <>
            <InputQCM isNew={true} handleAddXp={handleAddXp} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />
        </>}
        {n == 1 && <>
        {inputState == 0 && inputOpen() }
        {inputState == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </>}
    </div>
    </div>



        const pinyin_new = <div className='p-4 relative h-full text-red-400'>
            
        <div className='flex justify-center'><div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
     <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
   </svg>
   </div></div>
           <div className='text-white/90'>
               <div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-8 mb-2'>Comment se prononce ce mot ?</div>
                   <div className='mx-auto text-[80px] mt-8 mb-4 flex justify-center text-white'>
                   {card.latin}
                   </div>
   
               <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={() => {}} card={card} lang={lang} soundMode={true} fied_id='latin' array={randomCards} correct={card} validAction={() => { speak({text: (card.letter_speak || card.letter) + ".  " + card.word , lang: lang}); setTriggerMode()}} />
               </div>
       </div>


        const pinyin_audio = (lvl, randomN) => {

            const rimes = ['ong', 'an', 'ai', "ou"]
            const rime = rimes[randomN]

            const word = wordsByRime?.[`${rime}`].map(e => {
                const [sign, pinyinRaw] = e.word.split('(');
                const pinyin = pinyinRaw.slice(0, -1);
                const letter = pinyin[0];
                return { ...e, sign: sign.trim(), pinyin, letter };
                }).find(e => e.letter === card.latin[0])

            const notWord = wordsByRime?.[`${rime}`].map(e => {
                const [sign, pinyinRaw] = e.word.split('(');
                const pinyin = pinyinRaw.slice(0, -1);
                const letter = pinyin[0];
                return { ...e, sign: sign.trim(), pinyin, letter };
                }).filter(e => e.letter != card.latin[0])

           const propsArray = [word, ...notWord?.slice(0,difficulty == 2 ? 5 : 3)]?.map(e => ({letter: e?.sign, latin: e?.pinyin}))


        return <div className='p-4 relative h-full text-red-400'>          
        <div className='mx-auto mt-[100px] flex justify-center'>
            {card.pinyin_i ? <AudioBtn text={word?.sign || card.letter} lang={lang} /> :
            <AudioBtn text={card.letter} lang={lang} />}
        </div>
        {/* <div className='' onClick={() => {console.log('randomCards', randomCards)}}>{randomCards.length}</div>
        <div>{propsArray.map(e => <span>{e.letter}</span>)}</div> */}
        {card.cat != "pinyin" &&<div onLoad={() => speak({text: (card.letter_speak || card.letter) , lang: lang}) } className='text-center'>{card.latin}</div>}
        <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka  mt-8'>Quelle lettre fais ce son ?</div>

        <div  id="input" className={`text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id={card.cat != "pinyin" ? 'letter' : "latin"} array={card.pinyin_i ? propsArray : randomCards} correct={card.pinyin_i ? {...word, letter: word.sign, latin: word.pinyin} : card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
        </div>
    </div>}
        
        
        const pinyin_latin = (lvl, randomN) => {

            const rimes = ['ong', 'an', 'ai', "ou"]
            const rime = rimes[randomN]

            const word = wordsByRime?.[`${rime}`].map(e => {
                const [sign, pinyinRaw] = e.word.split('(');
                const pinyin = pinyinRaw.slice(0, -1);
                const letter = pinyin[0];
                return { ...e, sign: sign.trim(), pinyin, letter };
                }).find(e => e.letter === card.latin[0])

            const notWord = wordsByRime?.[`${rime}`].map(e => {
                const [sign, pinyinRaw] = e.word.split('(');
                const pinyin = pinyinRaw.slice(0, -1);
                const letter = pinyin[0];
                return { ...e, sign: sign.trim(), pinyin, letter };
                }).filter(e => e.letter != card.latin[0])


           
           const propsArray = [word, ...notWord?.slice(0,3)]?.map(e => ({letter: e?.sign, latin: e?.pinyin}))
           const wordGood = {...word || {}, letter: word?.sign, latin: word?.pinyin}

            
            return <div className='p-4 relative h-full text-red-400'>
             <div className={'mx-auto text-[60px] mt-16 flex justify-center'}>
             {card.pinyin_i ? wordGood.latin || card.latin : card.latin}
          
        </div>
        <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-8'>Comment se prononce cette lettre ? </div>
    
        <div  id="input" className={`text-white absolute bg-black/10 bg-input rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && lvl == 0 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' soundMode={difficulty != 2 || card.cat == "pinyin"} array={card.pinyin_i ? propsArray : randomCards} correct={card.pinyin_i ? wordGood : card} validAction={() => setTriggerMode()} />}
        </div>
        </div>}



  


    const new_card = <div className='p-4 relative h-full text-red-400'>
     {/* <div className='flex justify-center'><div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-yellow/70 mt-2 text-xs flex gap-2 mx-auto'>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
</div></div> */}
        {inputState == 0 && <div>
           
            <div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}}  className=' fredoka text-center text-sm text-purple-300 text-white/70 mt-8 mb-2'>Dessine la lettre</div>
            <div className='flex justify-end gap-2'>
            <div onClick={() =>  speak({text: (card.letter_speak || card.letter) , lang: lang})} className='h-[60px] relative w-[60px] fredoka text-2xl  bg-pink-500/90  text-white rounded-full flex -mb-4 items-center justify-center'>
                <div className='font-sans	'>{card.latin}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 absolute top-0 right-0">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                    </svg>
            </div>

                </div>


            {card.isSign ? <ChineseCharacter text={card?.letter} next={() => setInputState(e => e+1)} /> : <PaintableLetter letter={card.letter}  />}
            {!card.isSign && <div onClick={() => {setInputState(e => e+1); validSound.play()}} className='border-2 mb-2 px-2 py-2 grow text-white/90 mt-2 rounded-xl border-black/40 border-b-4 text-center'>Suivant</div>}
            </div>}
        {inputState == 1 && <div>
            <div onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-8 mb-2'>Comment se prononce cette lettre ?</div>
                <div className='mx-auto text-[80px] mt-8 mb-4 flex justify-center text-white'>
                {card.letter}
                </div>

            <InputQCM  handleAddXp={handleAddXp} difficulty={difficulty} error={error} setError={() => {}} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => { speak({text: (card.letter_speak || card.letter) + ".  " + card.word , lang: lang}); setTriggerMode()}} />
            </div>} 
        

    </div>

    const audio_letter = <div className='p-4 relative h-full text-red-400'>
            <div className='mx-auto mt-[100px] flex justify-center'>
                <svg  onClick={() =>  speak({text: (card.letter_speak || card.letter) , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
            </div>
            {card.cat != "pinyin" &&<div onLoad={() => speak({text: (card.letter_speak || card.letter) , lang: lang}) } className='text-center'>{card.latin}</div>}
            <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka  mt-8'>Quelle lettre fais ce son ?</div>

            <div  id="input" className={`text-white absolute bg-input bg-black/10 border border-black/10 min-h-[130px] rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
                {inputState == 0 && inputOpen(true) }
                {inputState == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id={card.cat != "pinyin" ? 'letter' : "latin"} array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
            </div>
        </div>

        const audio_letter_context = (lvl = 0) => <div className='p-4 relative h-full text-red-400'>
                    <img className='h-[100px] w-[100px] rounded-full mx-auto bg-pink-100/10 border-[5px] border-white/20'  src={card.img}/>

        <div onLoad={() => speak({text: (card.letter_speak || card.letter), lang: lang}) }  className='mx-auto mt-2 flex justify-center'>
            <svg onClick={() =>  speak({text: card.word , lang: lang})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#FF62A4] w-12 h-12"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        
        </div>
        <div className='text-center text-xs fredoka'>{card.word_latin}</div>
        {lvl == 0 && <div  onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-4'>Quelle {card.letter_syllabe ? "caractère" : "lettre"} manque t-il ?</div> }
        {lvl == 1 && <div  onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-8'>Comment écrire ce mot ?</div> }
        {lvl == 0 && <div className='text-center text-4xl mt-2 text-white/90'>{highlightText(card.letter_syllabe || card.letter, card.word, card.word_latin, lang, "text-transparent px-1 border-b-2 border-red-200")}</div>}


        <div  id="input" className={`text-white absolute bg-black/10 border border-black/10 rounded-b-xl bg-input bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
            {inputState == 0 && inputOpen(true) }
            {inputState == 1 && lvl == 0 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id={card.letter_syllabe ? "letter_syllabe" : 'letter'} array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}
            {inputState == 1 && lvl == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='word' array={randomCards} correct={card} validAction={() => {speak({text: (card.letter_speak || card.letter) + " . " + card.word , lang: lang}) ; setTriggerMode()}} />}

        </div>
        </div>

    const letter_latin = (lvl = 0)=> <div className='p-4 relative h-full text-red-400'>
    <div className='mx-auto text-[60px] mt-16 flex justify-center'>
    {card.cat != "pinyin" ? card.letter : card.latin}
    </div>
    <div   onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-8'>Comment se prononce cette lettre ? </div>

    <div  id="input" className={`text-white absolute bg-black/10 bg-input rounded-b bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
        {inputState == 0 && inputOpen(true) }
        {inputState == 1 && lvl == 0 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' soundMode={card.cat != "pinyin"} array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        {inputState == 1 && lvl == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
    </div>
    </div>

    const letter_latin_context = (lvl = 0) => <div className='p-4 relative h-full text-red-400'>

        <img className='h-[140px] w-[140px] rounded-full mx-auto bg-pink-100/10 border-[5px] border-white/20'  src={card.img}/>

    <div className='mx-auto text-[60px] flex justify-center text-white/60'>
     {highlightText(card.letter_syllabe || card.letter, card.word, card.word_latin, lang, "text-pink-500")}
    </div>
    {lvl == 0 && <div  onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-2'>Comment se prononce la partie en rose ?</div>}
    {lvl == 1 && <div  onClick={() => {getIndex(true, 0, card.cat != "pinyin" ? 1 : 4)}} className='text-center text-white/70 fredoka mt-2'>Comment se prononce ce mot ?</div>}

    <div  id="input" className={`text-white absolute bottom-0 p-4 left-0 right-0 ${difficulty == 0 ? "bg-pink-500/20" : difficulty == 1 ? "bg-indigo-500/20" : difficulty== 2 ? "bg-cyan-500/20": ""}`}>
        {inputState == 0 && inputOpen(true) }
        {inputState == 1 && lvl == 0 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id={card.letter_syllabe ? "latin_syllabe" : 'latin'} array={randomCards} correct={card} validAction={() => setTriggerMode()} />}
        {inputState == 1 && lvl == 1 && <InputQCM handleAddXp={handleAddXp} getIndex={getIndex} difficulty={difficulty} error={error} setError={setError} bonus={bonus} setBonus={setBonus} card={card} lang={lang} fied_id='word_latin' array={randomCards} correct={card} validAction={() => setTriggerMode()} />}

    </div>
    </div>



    const varRare = card?.var?.split('!')?.length > 1 


    const ton = tons.find(e => e.letter == card.letter)
    const ton_back = <div className='p-4 relative overflow-scroll  text-center' style={{maxHeight: "calc(100% - 80px)"}}>
                <div className='fredoka'>{ton?.name || <div className='mb-12'>Ton neutre</div>}</div>
                <div className='mb-6 flex gap-4 mx-auto justify-center items-center'>

                    <div className='relative ' onClick={() => speak({text: card.word , mp3: card.mp3, lang: lang})}>
                            {ton?.svg ? <div className='mx-auto p-2 scale-[0.7] '>{ton?.svg}</div> : <div className='rounded h-[100px] flex items-center justify-center w-[100px] fredoka text-2xl '>{card.latin}</div>}   
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 p-2 rounded-full bg-pink-500 absolute top-1 right-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                                        </svg>
                    </div>
            <div className='relative'>  



            </div>


            </div>
                
            {ton && <><div className='-mt-8 text-sm text-white/50'>Mots utilisant le {ton.name} </div>
            <div className='flex gap-2 justify-center flex-wrap mt-2'>{ton?.words.map(e => <div   onClick={() =>  speak({text: (card.letter_speak || card.letter), mp3: e.mp3, lang: lang})}>
                <span className='relative mr-2'>{e.pinyin} 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-2 absolute top-0 -right-2">
                    <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                    <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
                    </svg>
                </span>
                <div className='text-xs italic text-white/50'>({e.meaning})</div>
            
</div>)}</div>
           
            </>}
            <div className='text-sm text-white/90 fredoka text-left mt-4 '>{card?.hint}</div>

            <div className='mt-2 text-sm text-white/50' onClick={() => {
                console.log('cards', cards)
                console.log('card', card)
                }}>Autres tons</div>
          
            <div className='flex gap-2 justify-center mt-2'>{cards.filter(e => e.isTon && e.latin != "ma").map(e => <div onClick={() =>  speak({text: (card.letter_speak || card.letter) , mp3: e.mp3, lang: lang})} className='bg-white/10 rounded-xl px-2'>{e.latin}</div>)}</div>

            </div>

          
       

    const back = <div className='p-4 relative overflow-scroll  text-center' style={{maxHeight: "calc(100% - 80px)"}}>
      
    <div className='mb-6 flex flex-col  mx-auto w-[120px] relative items-center' onClick={() =>  speak({text: (card.letter_speak || card.letter) , mp3: card.mp3, lang: lang})}>
        <div className='absolute top-0 -right-6'>
            <div className={`h-[50px] rounded-full w-[50px] relative flex items-center justify-center text-white ${card.voy ? "bg-blue-500" : "bg-pink-500"} `}>
                <div className='fredoka'>{card.latin}</div>
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={` ${card.voy ? "text-blue-500" : "text-pink-500"} bg-white p-1 absolute bottom-[-10px] -right-[10px] mt-1 rounded-full w-7 h-7`}><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>        

            </div>
        </div>
       
        {card.isSign ? <ChineseCharacterRender text={card.letter} /> :  <div className='relative -mt-4 text-[70px] min-w-[50px]  flex justify-center'>{card.letter}

            <div className='w-[160px] transition-all  hover:scale-110 z-[0] top-[-40px] left-[-66px] absolute h-[160px] bg-white/10 rounded-full '></div>
        </div>}
        {lang == "ru-RU" && <div className='pacifico text-4xl -mt-4'>{card.letter?.toLowerCase()}</div>}
       
    </div>

    <div className='mb-6 flex gap-4 items-center'>

       <div className='relative -ml-8 ' onClick={() => speak({text: card.word , mp3: card.mp3, lang: lang})}>
        <img className='h-[180px] w-[180px] rounded-full bg-pink-100/10 border-8 border-white/20'  src={card.img}/>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 p-2 rounded-full bg-pink-500 absolute top-4 right-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                    </svg>
        </div>
        <div className='relative'>  

            <div className={`text-[50px]`}>{highlightText(card.letter, card.word, card.word_latin, lang)}</div>
            <div className={`text-pink-300 font-sans mt-1 text-left ${card.cat == "pinyin" ? "text-xl" : "text-sm " }`}>{card.word_latin}</div>

            <div className='text-white/40 fredoka text-left'>{card.word_fr}</div>
        </div>

    </div>

    
     

    {card.pinyin_i && <>
        <div className='fredoka text-xs text-white/30'>Autre mots commencant par l'initial "{card.pinyin_i}"</div>
        <div className='flex gap-2 flex-wrap justify-center rounded-xl py-2 mt-2'>
            {Object.keys(wordsByRime).slice(0, 6).map(rimeKey => (
                renderWordsByRime(wordsByRime[rimeKey], card, lang)
            ))}
        </div>
    </>}

    {/* <div className='flex gap-2 flex-wrap'>{wordsByRime.ong.map(e => {
        const sign = e.word.split('(')[0]
        const pinyin = e.word.split('(')[1].slice(0, -1)
        const letter = pinyin[0]
   return <div className={`${letter == card.latin[0] ? "bg-green-500" : ""}`} onClick={() => {speak({text: sign, lang: lang})}}>({letter}) {pinyin}</div>})
}</div> */}
    {/* <div className='' onClick={() => {speak({text: "碰" , lang: lang})}}>碰</div>
    <div className='' onClick={() => {speak({text: "风" , lang: lang})}}>风</div>
    <div className='' onClick={() => {speak({text: "风" , lang: lang})}}>风</div>
    <div className='' onClick={() => {speak({text: "风" , lang: lang})}}>风</div>
    <div className='' onClick={() => {speak({text: "风" , lang: lang})}}>风</div>
    <div className='' onClick={(e) => {speak({text: e.currentTarget.innerText , lang: lang})}}>风胸蒙碰</div> */}
    


    {/* <div className='text-white/70 fredoka'>{card.sentence}</div>
    <div className='text-white/40 fredoka'>{card.sentence_fr}</div> */}



    {arabicLetters[card?.letter]?.length > 0 && <div>
    <div className='text-xs text-white/40 -mt-4 fredoka'>Variations</div>
    <div className='gap-4 flex justify-center'>{arabicLetters[card?.letter]?.slice(1, 4).map((v) => <div className='text-[45px]'>{v}</div>)}</div>


    


    {/* <div className='gap-2 flex justify-center'>{card.variants?.map((v) => <div className='text-3xl'>{v.letter}</div>)}</div> */}
    {/* {card.variants.map((v, index) => {
            const regex = new RegExp(`(${card.letter})`, 'g');
            const parts = v.word?.split(regex);
            return (
                <div key={index} className='text-2xl'>
                    {parts.map((part, i) =>
                        part === card.letter ? (
                            <span key={i} style={{ color: '#FF62A4' }}>{part}</span>
                        ) : (
                            <span key={i}>{part}</span>
                        )
                    )} <div className='text-xs text-white/40'>{v.word_latin}</div>
                </div>
            );
        })} */}
    </div>}

    {card.note && <div>
        {/* <div className='text-xs text-white/40 mt-2'></div> */}
        <div className='text-md text-white/90 fredoka text-left'>{card?.note}</div>
    </div>}
    {card.hint && <div>
        {/* <div className='text-xs text-white/40 mt-2'></div> */}
        <div className='text-sm text-pink-200/90 fredoka text-left'>{card?.hint}</div>
    </div>}

   

    {card?.var && <> 
    <div className='text-xs text-white/40 mt-4 fredoka'>Variations</div>
    <div className='mt-2 text-lg flex items-center justify-center gap-4'>
        <div onClick={() =>{
            speak({text: card?.var.split(',')[0]?.split('(')[0], lang: lang})
            }}>
                {card?.var.split(',')[0]?.split('(')[0]} 
                <span className='text-white/60 text-xs relative'>{card?.var.split(',')[0]?.split('(')[1].slice(0,-(varRare ? 3 : 1))} 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-2 absolute -top-2 -right-1">
                    <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                    <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
                    </svg>
                </span>
            </div>
        
        {card?.var.split(',')?.[1] && <div onClick={() =>{
            speak({text: card?.var.split(',')[1]?.split('(')[0], lang: lang})
            }}>
                {card?.var.split(',')[1]?.split('(')[0]}
                <span className='text-white/60 text-xs relative'>{card?.var.split(',')[1]?.split('(')[1].slice(0,-1)} 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-2 absolute -top-2 -right-1">
                    <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                    <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
                    </svg>
                </span>
            </div>}

            {varRare &&  <span className='text-white/60 text-xs relative'>très rarement utilisé</span>}
        
    </div>
      

        
        
        </>}
    {/* {card.tg_id && <div>
        <div className='text-xs text-white/40 mt-2'>gram</div>
        <div className='text-md text-white/90 '>{card?.tg_id}</div>
    </div>} */}
    
</div>

    

    const validationBtn = <div className='flex gap-2 mt-4 p-3 left-0 right-0 absolute bottom-0 ' >
        {error > 0 ? <div className='px-4 py-2 grow rounded-xl hover:scale-[1.1] cursor-pointer border-2  border-b-4 border-black/70 bg-white/10 text-center fredoka' onClick={() => {getIndex(false)}}>A revoir</div> : <div></div>}
        {error == 0 ? <div className='px-4 py-2 grow  rounded-xl hover:scale-[1.1] cursor-pointer border-2  border-b-4 border-black/70 bg-white/10 text-center  fredoka' onClick={() => {getIndex(true, bonus, card.cat != "pinyin" ? 1 : 4)}}>Suivant</div> : <div></div>}
    </div>

   


    if (defaultTrigger) {
       return  <div onClick={(e) => e.preventDefault()} className={`bg-[#433182] ${triggerMode == "null" ? "max-h-[69vh] h-[500px]" : "max-h-[80vh] h-[600px]"}  z-[5] ${fontClass}  relative mx-auto  w-[400px] max-w-[90vw]  border-4 border-b-8 border-black/50 rounded-2xl`}>

            {card.isTon ? ton_back : back}
        
       </div>

    }

    return <div onClick={(e) => e.preventDefault()} className={`bg-[#433182] ${triggerMode == "null" ? "max-h-[69vh] h-[500px]" : "max-h-[80vh] h-[600px]"} ${fontClass} relative mx-auto  w-[400px] max-w-[90vw]  overflow-auto border-4 border-b-8 border-black/50 rounded-2xl`}>
        {/* <div>info: current trigger lvl: {userCard ? userCard?.triggers?.[0]?.lvl : "new !!"} - next trigger: {userCard?.next_trigger}</div> */}
        {/* letter: {card.letter} isTon: {card.isTon ? "oui" : "non"} error: {error}; cards: {cards.length} mode: {modeExercice} lvl_trigger: {lvl_trigger} {lvl_trigger%2}%2 triggerMode: {triggerMode} inputState: {inputState} */}
        

        
        {!triggerMode && (card.isTon ? ton_back : back)}
        {triggerMode == "new_card" && new_card} 
        {triggerMode == "letter_latin" && letter_latin(lvl_trigger%2==1?  1 :0)}    
        {triggerMode == "audio_letter" && audio_letter}
        {triggerMode == "pinyin_new" && pinyin_new} 
        {triggerMode == "pinyin_audio" && pinyin_audio(0, randomN) }
        {triggerMode == "pinyin_latin" && pinyin_latin(0, randomN)}
        {triggerMode == "audio_letter_context" &&  audio_letter_context(modeExercice)}
        {triggerMode == "letter_latin_context" && letter_latin_context(modeExercice)}
        {triggerMode == "ton_audio" && ton_audio(0)}
        {triggerMode == "ton_write" && ton_write(0)}
        {triggerMode == "ton_new" && ton_new(0)}
        {!triggerMode && validationBtn}




       
       

        
    </div>
}

// modifier newCard pour les pinyin
// modifier audio_letter pour pinyin -- check
// modifier letter_latin pour pinyin 
// -- mettre audio plus difficile dans hardmode
// supprimer les context trigger pour pinyin

// attention au contexte
// afficher la lettre en surligné 

export {LetterCard, AudioBtn}