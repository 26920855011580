import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";

import { db, app } from "../../config.js";
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray, speak, stopSound } from "../../utilities/helper/text.js";


import { addImg, arabicLetters, click_sound2, displayDuration, grammars, highlightLetterArabic, removePinyinAccents, validSound2 } from '../../utilities/helper/helper.js';
import { getStorage } from 'firebase/storage';
import { ct } from '../../utilities/data.js';
// import { LetterCard } from '../components/learn/letterCard.js';
import { updateUserXp } from '../../V2/components/learn/learn.js';
import { ExamScreen, scoreToNote } from '../../V2/screens/examScreen.js';
import { LetterCard } from '../../V2/components/learn/letterCard.js';
import { useStateContext } from '../StateProvider.js';
import { goals } from '../helperData.js';
import { supabase } from '../../V2/screens/testScreen.js';
import { addXpToProfile, sbCreate, sbUpdate } from '../DbFunction.js';
// import { ExamScreen, scoreToNote } from './examScreen.js';


// new 
// letter
// letter


const rosettaText= {
    "Arabe": "هذه هي حجر رشيد. عند تعلمك حروف الأبجدية، ستتمكن من قراءة هذا النص. يحتوي على جميع الأصوات والحروف الضرورية لتقدمك في اللغة. استمر في الممارسة، وستتقن القراءة والكتابة قريبًا.",
    "hindi": "कभी कभी खेतों में हिरन दौड़ते हैं। आकाश में पक्षी उड़ते हैं और सूरज चमकता है। पहाड़ की चोटी पर चिड़िया बैठी होती है और नदी के किनारे गायें चरती हैं। किताबों में ज्ञान है और बगीचों में फूल खिलते हैं। जंगल में शेर गर्जता है और बारिश की बूंदें धरती पर गिरती हैं।",
    "Russe": "Вот камень Розетты. Изучая буквы алфавита, ты сможешь читать этот текст. Здесь есть все звуки и буквы, нужные для твоего прогресса в русском языке. Продолжай учиться, и скоро ты овладеешь чтением и письмом.",
    "ukrainien": "Сонце сідає за горизонт, і небо стає темним. Птахи летять додому, а діти грають на вулиці. Річка тече повз ліс, і вітер гойдає дерева. Коти гуляють по двору, а собаки гавкають. Місяць піднявся високо, і зірки з’явилися на небі. Всі засинають під спокійний шум природи.",
    "chinoishanzi": "这是罗塞塔石碑。通过学习汉字的拼音，你将能够阅读这个文本。这里包含了所有的声音和字母，有助于你进步。继续努力学习，很快你就能掌握读写了。",
    "chinoispinyin": "Zhè shì Luósāitǎ shíbēi. Tōngguò xuéxí hànzì de pīnyīn, nǐ jiāng nénggòu yuèdú zhège wénběn. Zhèlǐ bāohán le suǒyǒu de shēngyīn hé zìmǔ, yǒuzhù yú nǐ jìnbù. Jìxù nǔlì xuéxí, hěn kuài nǐ jiù néng zhǎngwò dúxiě le.",
    "japonaiskata": "これはロゼッタストーンです。カタカナを学んでいくと、この文章を読むことができるようになります。この文章には、すべてのカタカナが含まれています。練習を続ければ、すぐに読み書きができるようになります。",
    "japonaishira": "これはロゼッタストーンです。ひらがなを学んでいくと、この文章を読むことができるようになります。この文章には、すべてのひらがなが含まれています。継続して練習すれば、すぐに読み書きができるようになります。",
    "Coréen": "이것은 로제타 스톤입니다. 한글의 자음을 배우면서 이 글을 읽을 수 있게 될 거예요. 여기에는 모든 소리와 글자가 포함되어 있으며, 한국어를 배우는 데 필요합니다. 계속 연습하면 곧 읽기와 쓰기를 마스터할 수 있을 거예요.",

}




// orienter leçon mot par mot en montrant chaque spécificité 
// mettre linked_word dans mots pour ordonné les leçons de cette manière les exerices et les mots se suivront sans difficulté d'apprentissage
// faire cartes mots qui présente les nouvelles lettres
// faire les exercices de complétion par rapport au mot linked pour que (ça soit plus simple) (et pas de nouvelle lettre) à partir du trigger 6 on ajoute les autres mots 
// ajouter vidéo dans la grammaire ?
// si plus grand chose à mettre dans grammaire ajouter des points cultures 


// ajouter aussi des points mnémotechnique pour les lettres 

// exemple arabe
// apprendre le mot maison - puis les 4 lettres 
// apprendre le mot chameaux - puis les 4 lettres 
// apprendre le mot cheval puis les 2 lettres 


/// -- faire une présentation grammaire pour les nouveaux mots de la sessions
// -- faire une présentation grammaire d'un nouveau mots 

function highlightLetterHangul(letters, text) {
    const letterSet = new Set(letters);
  
    // Helper function to split a Hangul character into its components
    const splitHangul = (char) => {
      const initialCode = 44032; // '가'
      const charCode = char.charCodeAt(0) - initialCode;
  
      if (charCode < 0 || charCode > 11171) {
        // Not a Hangul syllable
        return null;
      }
  
      const initial = Math.floor(charCode / 588); // 초성 (initial consonant)
      const medial = Math.floor((charCode % 588) / 28); // 중성 (vowel)
      const final = charCode % 28; // 종성 (final consonant)
  
      const initials = "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ".split('');
      const medials = "ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅛㅜㅠㅡㅣ".split('');
      const finals = ['', 'ㄱ', 'ㄲ', 'ㄳ', 'ㄴ', 'ㄵ', 'ㄶ', 'ㄷ', 'ㄹ', 'ㄺ', 'ㄻ', 'ㄼ', 'ㄽ', 'ㄾ', 'ㄿ', 'ㅀ', 'ㅁ', 'ㅂ', 'ㅄ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ']
  
      return {
        initial: initials[initial],
        medial: medials[medial],
        final: finals[final]
      };
    };
  
    // Function to determine the color of a Hangul character
    const getColorForHangul = (charComponents) => {
      const { initial, medial, final } = charComponents;
      const components = [initial, medial, final].filter(Boolean); // Only keep non-empty components
      const allInSet = components.every(comp => letterSet.has(comp));
      const someInSet = components.some(comp => letterSet.has(comp));
  
      if (allInSet) {
        return 'text-[#FFCD4C]'; // All components are in letters, mark as green
      } else if (someInSet) {
        return 'text-yellow-100/80'; // Some components are in letters, mark as yellow
      } else {
        return 'text-white/50'; // None of the components are in letters, keep white
      }
    };
  
    return text?.split('').map((char, index) => {
      const charComponents = splitHangul(char);
  
      if (charComponents) {
        const colorClass = getColorForHangul(charComponents);
        return (
          <span key={index} className={colorClass}>{char}</span>
        );
      } else {
        // If it's not a Hangul character, just return the char as is
        return <span key={index} className="text-white">{char}</span>;
      }
    });
  }



function highlightLetter(letters, text) {

    console.log('---letters', letters)
    // Transform the array of letters to a Set for efficient lookup

    const letterSet = new Set(letters);
  
    // Map over each character in the text and wrap it in a span if it matches a letter in the set
    return text?.split('').map((char, index) => {
      if (letterSet.has(removePinyinAccents(char?.toLowerCase()))) {
        return (
          <span key={index} className={"text-[#FFCD4C]"}>{char}</span>
        );
      } else {
        return <span key={index}>{char}</span>;
      }
    });
  }

  async function getLetters(set, workspace, set2 = () => {}) {
    try {
      const { data, error } = await supabase
        .from('cards') // nom de votre table
        .select('*') // sélectionne toutes les colonnes
        .eq('workspace_id', workspace.id) // filtre sur workspace_id
        .eq('model', 'letter') // filtre sur le type


  
      if (error) {
        throw error
      }

      const dataCards = data.map(a => ({...a.data, id: a.id}))
  
      set(dataCards)
      set2(dataCards)
      console.log('data letter', dataCards)

      return data
    } catch (error) {
      console.error('Erreur lors de la recherche des cartes:', error.message)
      throw error
    }
  }
  

const getLetters2 = async(set, workspace, set2 = () => {}) => {
    console.log('get Letters 🟢🟢🟢')
    const q = query(collection(db, "cards"), where('workspace_id', '==', workspace?.old_id), where('type', '==', 'letter'))
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id }))
    set(data)
    set2(data)
    
    console.log('data', data)
}


// new A
// new B
// 
// audio A
// audio B
// audio A


// Round Keyword
///- The second syllable, 산, means mountain in Korean.

//ㅅ looks a bit like a mountain.





const EditCard = ({_card, fake, workspace, storage}) => {


    const [promptImg, setPromptImg] = useState()
    const [card, setCard] = useState(_card)


    const addCard = async (card) => {

        const newCard = {...card, id: null, workspace_id: workspace?.old_id, type: "letter"}
        const doc = await addDoc(collection(db, "cards"), newCard)
        console.log('doc', doc)
    }

    const delCard = async (card) => {
        await deleteDoc(doc(db, "cards", card.id))
        setCard()
    }
  

    const promptNote = (term) => `
    Donne uniquement la réponse. 
    Pour la lettre ${term} donne un texte explicatif sur l'utilisation de la lettre. Donne uniquement l'essentiel par besoin d'un faire trop.
    Exemple  : 
    "ㄱ se prononce de manière similaire au G.
    Cependant, elle peut être transcrite soit en G, soit en K. 
    Les mots kimchi et Gangnam commencent tous deux par ㄱ. 
    Ici, nous l'écrirons en G au début d'une syllabe et en K à la fin d'une syllabe. "

    "ㄴ se prononce comme un N. 
    Elle est utilisée pour représenter le son nasal [n] et apparaît dans des mots comme "nara" (pays) et "neun" (ceux)."


    `

    const promptHint = (term) => `
    Donne uniquement la réponse. 
    Pour la lettre ${term} donne une astuce pour retenir la lettre.
    Exemples  : 
    "ㅁ ressemble à un mur, et le mot mur commence par M."
    `

    const autoUpdate = async (card, prompt, field) => {
        const messages =[{"role": "user", "content": prompt}]
        const response = await gpt(messages, true, ct)
        const data_content = response?.choices[0]?.message?.content;
        console.log('data_content', data_content);

        const newCard = {...card, [`${field}`]: data_content}
        updateCard(newCard)

        console.log('newCard', newCard)

    }




    const updateCard = async (card) => {
        setCard(card)
        console.log('new card', card)
        const updatedCard = await updateDoc(doc(db, "cards", card.id), card)
    }

    const addImage = async (card) => {
     

        const prompt = promptImg || ` le mot suivant '${card.word_fr}'`
        const src = await addImg(`
        Donne moi une image (sans aucun texte) qui représente ${prompt}.
        Dans le style illustration jolie, moderne et minimaliste (sans trop de complexité) au format 1024x1024.
        Aucun mots ni écriture mots écris doit ne doit apparaitre. Aucun texte sur l'image.


        
        `, storage, card.id)
        const newCard = {...card, img: src}
        console.log('newCard', newCard)
        console.log('src', src)
        const updatedCard = await updateDoc(doc(db, "cards", card.id), newCard)
        setCard(newCard)
    }


  



    return <div onClick={() => console.log('card', card)} className={`${card?.voy ? "bg-blue-100/50" :"bg-white/50" }  w-[900px] gap-2 border-b-2 text-blue-800 flex`}>
            <div className='w-[50px] border-r'>
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, rank: parseInt(e.currentTarget.value)})}} defaultValue={card?.rank}/>
                <input className='w-[50px] text-green-500'  onBlur={(e) => {updateCard({...card, rank_l: parseInt(e.currentTarget.value)})}} defaultValue={card?.rank_l}/>

            </div>
            <div>
                <img onClick={() => addImage(card)} src={card?.img} className='h-[50px] w-[50px] min-w-[50px] bg-purple-100 rounded w-[50px]'/>
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, img: e.currentTarget.value})}} defaultValue={card?.img} placeholder={"img"}/> 

            </div>
            <div className='w-[50px] border-r'>
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, letter: e.currentTarget.value})}} defaultValue={card?.letter} placeholder={"letter"}/> 
                <input className='w-[50px]'  onBlur={(e) => {updateCard({...card, latin: e.currentTarget.value})}}  defaultValue={card?.latin} placeholder={"latin"}/>
            </div>
            <div  className='w-[100px] relative mr-2 group'>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word: e.currentTarget.value})}} defaultValue={card?.word} placeholder={"word"}/>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word_fr: e.currentTarget.value})}} defaultValue={card?.word_fr} placeholder={"word_fr"}/>
                <input className='w-[100px]' onBlur={(e) => {updateCard({...card, word_latin: e.currentTarget.value})}} defaultValue={card?.word_latin} placeholder={"word_latin"}/>
                <div className='absolute top-0 right-0 hover:scale-[1.5]' onClick={() => {
                    console.log('coucou edition')
                }}>🪄</div>
            </div>

            <div  className='w-[70px] relative mr-2 group'>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, part: e.currentTarget.value})}} placeholder='part' defaultValue={card?.part}/>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, cat: e.currentTarget.value})}} placeholder='cat' defaultValue={card?.cat}/>

            </div>

            {/* <div  className='w-[70px] relative mr-2 group'>
                <input className='w-[70px]'   onBlur={(e) => {updateCard({...card, letter_syllabe: e.currentTarget.value})}} placeholder={card?.word?.[0] || 'letter_syllabe'} defaultValue={card?.letter_syllabe}/>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, latin_syllabe: e.currentTarget.value})}} placeholder='latin_syllabe' defaultValue={card?.latin_syllabe}/>
            </div> */}

            <div  className='w-[200px] relative mr-2 group'>
                <textarea placeholder='note' onBlur={(e) => {updateCard({...card, note: e.currentTarget.value})}} className='w-[200px] text-slate-500 h-[70px] text-[10px]' defaultValue={card?.note}/>
               
                <div className='absolute top-0 right-0 hover:scale-[1.5]' onClick={() => {autoUpdate(card, promptNote(card.letter), "note")}}>🪄</div>
            </div>

            <div  className='w-[70px] relative mr-2 group'>
                <textarea placeholder='tg_id' onBlur={(e) => {updateCard({...card, tg_id: e.currentTarget.value})}} className='w-[70px] text-xs' defaultValue={card?.tg_id}/>
               

            </div>

            <div  className='w-[200px] relative mr-2 group'>
                <textarea placeholder='hint' onBlur={(e) => {updateCard({...card, hint: e.currentTarget.value})}} className='w-[200px] h-[70px] text-pink-500  text-[10px]' defaultValue={card?.hint}/>
               
                <div className='absolute top-0 right-0 hover:scale-[1.5]' onClick={() => {autoUpdate(card, promptHint(card.letter), "hint")}}>🪄</div>
            </div>

            <textarea onChange={(e) => setPromptImg(e.target.value)} className='bg-yellow-400 min-w-[100px] h-[50px]' placeholder='help for image'></textarea>


            <div  className='w-[70px] relative mr-2 group'>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, var: e.currentTarget.value})}} placeholder={`${card?.letter} variation`} defaultValue={card?.var}/>

            </div>
            <div className="flex items-center mt-2">
                <input 
                type="checkbox" 
                checked={card?.voy || false} 
                onChange={(e) => {updateCard({...card, voy: e.currentTarget.checked})}}
                />
                <label className="ml-2">Is Voyelle</label>
            </div>

            <div  className='w-[70px] relative mr-2 group'>
                <input className='w-[70px]'  onBlur={(e) => {updateCard({...card, letter_speak: e.currentTarget.value})}} placeholder={`letter_speak`} defaultValue={card?.letter_speak}/>

            </div>

        



          


            {fake && <div onClick={() => addCard(card)}>Ajouter</div>}
            {_card?.id && <div className='text-red-300' onClick={() => delCard(card)}>supprimer</div>}
           
            
        </div>
}

function filterUniqueLetters(cards) {
    const seenLetters = new Set();
    const uniqueCards = [];
  
    for (const card of cards) {
      if (!seenLetters.has(card.letter)) {
        seenLetters.add(card.letter);
        uniqueCards.push(card);
      }
    }
  
    return uniqueCards;
  }



  const mdToText = (text) => {
    return text
      ? text
          .replace(/\n/g, '<div class="mt-2" />') // Remplace les sauts de ligne par des <br/>
          .replace(/\*(.*?)\*/g, '<span class="text-pink-400 font-semibold">$1</span>') // Remplace les *texte* par <b>texte</b>
      : '';
  };


const GrammarCard = ({card, lang}) => {

    return <div className='fredoka text-left -mt-4  grow '>
        {/* <div className='text-xs mb-2'>Nouvelle lettre</div> */}
        <div className='flex flex-col justify-center items-center'>
            <div onClick={() => speak({text: card.letter , mp3: card.mp3, lang: lang})} className='h-[40px] ml-[100px] w-[40px] flex items-center justify-center rounded-full bg-pink-500 text-white relative'>
                <div>{card.latin}</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 absolute top-0 right-0" >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                </svg>

            </div>

        <div className='text-[80px]'>{card.letter}</div>
        </div>
        <div className='flex gap-2 justify-between  mb-4'>
            <div className='text-white/50 italic text-xs'>Exemple d'utilisation ({card.word_fr}) </div>
        <div className='flex gap-2 items-center ' onClick={() => speak({text: card.word , mp3: card.mp3, lang: lang})}>
            <div className='relative'>
            <img src={card.img} className='rounded-full h-[70px] w-[70px] min-w-[70px] border-2 border-white/20' />
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 p-1 rounded-full bg-pink-500 absolute top-0 right-0">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
                </svg>
            </div>

            
            <span className='relative grow' style={{width: "-webkit-fill-available"}}  >
                <div className='text-3xl' style={{width: "-webkit-fill-available", textWrap: "nowrap"}} >{card.word}</div>
                <div className={`text-pink-300 font-sans	 ${card.cat == "pinyin" ? "text-xl" : "text-sm " }`}>{card.word_latin}</div>
                {/* <div className=''>({card.word_fr})</div> */}
            </span> 
            </div>
        </div>
        <div className=' text-sm mb-4'>{card.note}</div>
       
        <div className='text-pink-200 text-sm'>{card.hint}</div>
    </div>
}


const ShowGrammar = ({id, close, newCards, lang}) => {
  const currentGram = grammars.find(e => e.name == id && e.lang == lang)

 

  const [currentChunkIndex, setCurrentChunkIndex] = useState(0)
  const [currentCardIndex, setCurrentCardIndex] = useState(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const page = currentGram?.pages?.[currentPageIndex]
  const texts = page?.text?.split(/\n\s*\n/)?.map(e => e.trim())?.filter(e => e != "")

  const handleNext = () => {
    if (newCards?.length) {

        if (newCards.length == currentCardIndex + 1) {
            close()
        }
        
        setCurrentCardIndex(e => e+1)
    }
    if (texts) {
        
        if (texts.length == currentChunkIndex + 1) {
            if (currentGram?.pages.length == currentPageIndex + 1) {
                close()
            } else {
                setCurrentPageIndex(e => e+1)
                setCurrentChunkIndex(0)
            }
            
        }
       
        setCurrentChunkIndex(e => e+1)
    }
  }

  return <div onClick={() => {
    console.log('currentGram', currentGram)
    console.log('page', page)
    console.log('id', id)
    console.log('texts', texts)
    console.log('newCards', newCards)
    console.log('currentPageIndex', currentPageIndex)
    console.log('currentChunkIndex', currentChunkIndex)

  }} className='fixed top-[90px] fredoka  left-0 right-0 bottom-0 z-[10]'>

    <div style={{height: "calc(90vh - 100px)"}} className='p-4 h-[400px] relative rounded-xl mx-6 flex  items-center'>
        <div style={{backgroundColor: "rgb(255 255 255 / 0.036)"}} className={`transition rounded-full h-[300px] w-[300px] -right-[-150px] ${texts?.[currentChunkIndex] ? "" : "-translate-y-[100px] -translate-x-[-160px]"}  absolute`}></div>
        <div className='text-xl fredoka' dangerouslySetInnerHTML={{ __html: mdToText(texts?.[currentChunkIndex])}}></div>
        {newCards?.[currentCardIndex] && <GrammarCard lang={lang} card={newCards?.[currentCardIndex]} />}
        </div>
    
    <div className='flex justify-end px-4'><div onClick={handleNext} className='game-bt py-1 border-2 border-b-4 border-black/10 bg-indigo-500/20 rounded-xl px-4 mt-4 text-right'>Suivant →</div></div>
  
    {/* <div onClick={close} className='game-bt fixed top-4  px-4 mt-4'>Fermer</div> */}

    
  </div>
}


const LearnView = ({cardToLearn, setCardToLearn, setUserCards, userCards, setUser, user, workspace, otherCards}) => {

    const [cards, setCards] = useState()
    const [indexCard, setIndexCard] = useState(0)
    const [showGrammar, setShowGrammar] = useState()
    const [totalBonus, setTotalBonus] = useState(0)
    const [newCards, setNewCards] = useState()

    const {profile, setProfile} = useStateContext()


    const currentElement = cards?.[indexCard]
    let cardOnly = [...cardToLearn.map(c => c.card), ...otherCards].slice(0, 40);
  
 
    
   
    const currentCard = currentElement?.card
    const currentUserCard = userCards?.find(uc => uc.card_id == currentCard?.id) 
    const lang = workspace?.lang

    
    console.log('')

    useEffect(() => {
        const _newCards = cardToLearn.filter(e => !e.userCard).map(e => ({...e.card})).filter(c => !c.isTon).filter((card, index, self) => index === self.findIndex(c => c.id === card.id));
      setNewCards(_newCards)
    }, [cardToLearn])

    useEffect(() => {
        setCards(cardToLearn.map(c => ({...c, done: 0})))
    }, [])




    useEffect(() => {
        !currentUserCard && setShowGrammar(currentCard?.tg_id)
     }, [currentCard?.tg_id, currentUserCard])
    

    // séparer lecture de écriture ? ecriture izi
    const getIndex = async (valid, bonus = 0, factor = 1) => {

        valid ? validSound2.play() : click_sound2.play()
        // trouver les cartes avec done == null
        // trouve la carte avec done le plus bas


        // const currentTrigger = ((currentElement?.userCard?.triggers?.[0]?.lvl || 0) + 1) % 2 || 0
        const currentTrigger = currentElement?.userCard?.next_trigger





        let new_userCard = {
            ...currentElement.userCard,
            id: currentElement?.userCard?.id,
            card_id: currentElement.card.id,
            profile_id: profile.id, 
            workspace_id: workspace?.id,
            last_update: new Date(),
            from: "letter", 
            valid,
            next_trigger: currentElement?.userCard?.next_trigger == 0 ? 1 : 0,
            triggers: [
                {
                  lvl: Math.max(
                    0,
                    (currentElement?.userCard?.triggers?.[0]?.lvl || 0) +
                    (valid && currentTrigger == 0 ? (1 + bonus)*factor
                    :!valid && currentTrigger == 0 ? -2 : 0)
                  )
                },
                {
                  lvl: Math.max(
                    0,
                    (currentElement?.userCard?.triggers?.[1]?.lvl || 0) +
                    (valid && currentTrigger == 1 ?  (1 + bonus)*factor
                  : !valid && currentTrigger == 1 ? -2 : 0)
                  )
                }
              ]
            
          }


        






      


        //   console.log('currentElement', currentElement)
          console.log('currentUserCard', currentUserCard)
          console.log('new_userCard', new_userCard)
          console.log('currentElement', currentElement)
          let id = null
        if (!currentUserCard) {
            console.log('création de user_card !!')
            // création user cards
            // const ref = await addDoc(collection(db, "user_cards"), new_userCard)
            // id = ref.id
            // console.log('ref', ref)
            // new_userCard = {...new_userCard, id: ref.id}
            const { valid, ...userCardWithoutValid } = new_userCard
            const __newProfilCard = await sbCreate('profiles_cards', {...userCardWithoutValid})

            console.log('__newProfilCard create', __newProfilCard)
      
            setUserCards(uc => [...uc, {...new_userCard, id: __newProfilCard?.id , valid: null}])
            
        } else {
            console.log('update de user_card !!', new_userCard)
            // const ref = await updateDoc(doc(db, "user_cards", new_userCard.id), new_userCard)
           
            // console.log('ref', ref)

            const found = userCards?.find(e => e.card_id == new_userCard.card_id)
            console.log('found',found)
            
            const { valid, ...userCardWithoutValid } = new_userCard

            const __newProfilCard= await sbUpdate('profiles_cards', new_userCard?.id || found?.id, {...userCardWithoutValid})

            console.log('__newProfilCard', __newProfilCard)
         
            setUserCards(uc => uc.map(u => u.card_id == new_userCard.card_id ? {...new_userCard, id: __newProfilCard?.id, valid: null} : u))
           
        }

        const new_card = {
            ...currentElement,
            userCard: new_userCard
          };
        
       


        console.log('new_card', new_card)
        console.log('cards', cards)

        const updatedCards = cards.map((c,i) => c.card.id == new_card.card?.id ? {...c, userCard: new_userCard} : c)
        console.log('updatedCards', updatedCards)
        const _new_cards = updatedCards.map((c,i) => i == indexCard ? {...c, done: c.done + 1, valid: valid} : c)
        console.log('_new_cards', _new_cards)



        setCards(_new_cards)
        const new_index = cards
        .map((card, index) => ({ ...card, originalIndex: index })) // Ajoute l'index original à chaque carte
        .filter(card => !card.valid && indexCard != card.originalIndex) // Filtre les cartes non valides
        .sort((a, b) => a.done - b.done) // Trie par valeur croissante de 'done'
        .map(card => card.originalIndex) // Récupère l'index original de la carte triée
        .shift(); // Prend l'index de la première carte (celle avec le 'done' le plus faible)



        if (new_index == -1 || new_index == undefined) {
            setCardToLearn()
        
            
            return }
    
        setIndexCard(new_index)
        addXpToProfile(profile, valid ? 1 : 0, bonus ? 1 : 0, setProfile)
    
        
            
        
    }



    


    // mettre le trigger automatique dans LetterCard
    // compter point double pour carte new 

   

    const validCard = cards?.filter(card => card.valid)?.length
    const cent = Math.round(validCard * 100 / (cards?.length - 1))

    const progressBar = <div className='bg-black/20 transition-all  grow border relative overflow-hidden border-black/20 border-b-2 h-4 rounded-xl'>
        <div className='bg-white/40 h-[3px] transition-all absolute mx-2 mt-[2px] rounded-xl' style={{width: `calc(${cent}% - 10px)`}}></div>
        <div className='bg-black/10 h-[5px] transition-all absolute mx-2 mt-[6px] rounded-xl' style={{width: `calc(${cent}% - 10px)`}}></div>


        <div className='bg-yellow-500 transition-all h-4 rounded-xl' style={{width: `${cent}%`}}></div>
    </div>

    const xpRef = useRef(null);
    const bonusRef = useRef(null)

    const handleAddXp = (e, valid = 1) => {

        console.log('xpRef', xpRef)
        console.log('e', e)
        console.log('valid----', valid)

        const container = e.target;
        

        // Obtenir les coordonnées du clic par rapport à l'élément conteneur
        const rect = container.getBoundingClientRect();
        const offsetX = e.clientX - rect.right;
        const offsetY = e.clientY - rect.top;

        console.log('rect left', rect.left)
        console.log('rect top', rect.top)

        // Obtenir les dimensions de l'élément conteneur
        const containerWidth = rect.width;
        const containerHeight = rect.height;

        // Calculer les positions relatives en pourcentage
        const leftPercentage = (offsetX / containerWidth) * 100;
        const topPercentage = (offsetY / containerHeight) * 100;

        console.log('Left:', leftPercentage + '%', 'Top:', topPercentage + '%');
        const current = xpRef.current
        const bonusRefCurrent = bonusRef.current

      
        let animation = 'add-xp' 
        let text = "+ 1xp"


        if (valid == 1) {
            updateUserXp(user, 1, setUser, 0)
        }
        
        if (valid == 2){
            text = `<div class="w-[100px] text-center mx-auto">
                        <div>+ 1 xp</div>
                        <div class="text-center text-blink text-shadow-50" style="text-shadow: none !important">+ bonus xp</div>
                    </div>`
            setTotalBonus(b=>(b||0)+1)
            updateUserXp(user, 1, setUser, 1)
        }

        if (valid == 0 ){
            animation = 'minus-xp';
            text = "- 1xp"
            setTotalBonus(b=>(b||0)-1)
            updateUserXp(user, 0, setUser, -1)
        }
        if (valid == -1) {
            animation = 'minus-xp';
            text = "- 2xp"
            setTotalBonus(b=>(b||0)-2)
            updateUserXp(user, 0, setUser, -2)
        }



        if (current) {
        current.style.left = `${rect.right - 50}px`;
        current.style.top = `${rect.top -90}px`;
        current.innerHTML = text
        current?.classList?.add(animation);
        valid == 2 && bonusRefCurrent?.classList?.add('bonus-animation');
        console.log('animation')
        

        
        
        setTimeout(() => {
            current?.classList?.remove(animation);
            valid == 2 &&  bonusRefCurrent?.classList?.remove('bonus-animation');
        }, 1000); // Durée de l'animation
        }
    };


    return <div className={`bg-purple-900 z-[10] flex flex-col justify-between items-center p-4 ${(showGrammar || newCards?.length) ? "bg-rosette2": "bg-rosette"}  transition-all fixed top-0 left-0 right-0 bottom-0 backdrop-blur-md`}>



    
      <div onClick={() => console.log('user learn', user)} className='flex gap-2 items-center  w-full'>
            <div onClick={() => setCardToLearn()}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
                </div>
            {showGrammar || newCards?.length ? "" : progressBar}


            <div></div>
        </div>
        <div ref={bonusRef} className={`fredoka mt-1 text-sm flex gap-2 text-shadow px-3 bg-cyan-900/50 py-1 items-center rounded-xl text-cyan-500  ${totalBonus < 1 ? "opacity-0" : ""}`}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
 bonus: {totalBonus > 0 ? "+" : ""} {totalBonus} xp 
</div>
        {/* <div className='flex gap-1'>{cards?.map((e, i) => <div className={`${i == indexCard ? "text-yellow-500 " : e.valid && "text-green-500"}`}>{e.done}</div>)}</div> */}
        {/* index: {indexCard} 
        total: {cardToLearn?.length}  */}

{(showGrammar || newCards?.length) ? <>
{showGrammar && <ShowGrammar lang={workspace?.lang} close={() => setShowGrammar()} id={showGrammar || null} />}
{!showGrammar && <ShowGrammar lang={workspace?.lang} close={() => setNewCards(null)} newCards={newCards}  />}

</> : <>


        <div className='grow flex flex-col justify-center'>
        {currentCard && <LetterCard  lang={lang} getIndex={getIndex} trigger={"new_card"} 
        cards={filterUniqueLetters(cardOnly)} 
        handleAddXp={handleAddXp}
        // cards={cards}
        card={currentCard} userCard={currentUserCard} />}
        </div>

        <div ref={xpRef} className='xpop text-xl text-right fredoka font-semibold w-[70px] text-shadow-orange text-yellow-200 top-10 right-10 rounded-xl z-[999]  px-1 opacity-0 absolute'>
          
        </div>
        </>}
     
    </div>

}



// charactère chinois dans la pièrre de rosette ? 
// apprendre uniquement le pinyin ici
// puis 10 premiers caractères (ici ou direct dans le cours)


const LetterMini = ({index, card, userCard, setShowCard, goalLvl, lang, isAdmin}) => {

    const round = (Math.ceil((index+1)/4) || 1) - 1
    const lvlMax = goalLvl - round


    let print = card.letter
    
    if (card.isTon) print = card.latin
    if (card.pinyin_i) print = card.pinyin_i
    if (card.pinyin_f) print = card.pinyin_f


    const t1Lvl = userCard?.triggers?.[0].lvl || 0
    const t2Lvl = userCard?.triggers?.[1].lvl || 0

    const cent = (t1Lvl + t2Lvl)*100/16


    return <div onClick={async() => {
        const endSound = await speak({text: card.letter_speak || card.letter , mp3: card.mp3, lang: lang})
        if (endSound) {
            setShowCard({card: card, userCard: userCard});
        }
       
        // console.log('card ??', card)
        // stopSound()
        // card.isSign ?  speak({text: card.letter , mp3: card.mp3, lang: lang}) : speak({text: card.letter + "; ... " + card.word ,  mp3: card.mp3, lang: lang});
        }} className={`border-2 bg-rosette3 backdrop-blur-xl text-center px-2 rounded-xl  ${userCard ? `border-black/30 ${card.voy ? "bg-cyan-900/20" : "bg-indigo-800/20"} `  : "border-black/20"}  py-1 border-b-4 `}>
        <div className={`text-4xl ${lang == 'ar-XA' && "vazirmatn"} text-shadow ${card.voy ? userCard ? "text-cyan-200" : "text-cyan-200/50" : userCard ? "text-fuchsia-200" : "text-fuchsia-200/50"}`}>{print}</div>
         
        {lang == "ja-JP" && <div className='text-white/40 fredoka'>{card.isTon ? "" : card.latin}</div>}


        <div className='text-white/30 text-xs flex gap-2 justify-center h-[12px] items-center'>
            {/* <span>#{card.rank}</span> */}
   
            {isAdmin ? <div className='text-[10px] gap-2 '>
                <span className='text-green-200/50'>{userCard?.triggers?.[0].lvl || 0}/{goalLvl}</span>
                <span className='text-purple-200/50'>{userCard?.triggers?.[1].lvl || 0}/{goalLvl}</span>
                <span>{cent}%</span>
            </div>  : cent > 0 ? <div className='h-2 mt-1 w-full border overflow-hidden border-black/10 rounded bg-black/10'>
                <div className='game-btn h-full bg-amber-500 rounded-xl' style={{width: cent + "%"}}></div>
                </div> : ""
            }

            
        </div>
        </div>
}


const RosetaScreen = (props) => {
    const navigate = useNavigate()
    const {user, setUser, setBubble, setShowBottomBar, profileWorkspace, setProfileWorkspace, profile, setProfile, workspace, setWorkspace, learnedCards, setActiveScreen} = useStateContext()

    const [lastCall, setLastCall] = useState()
    const menu = props.menu
    const [selectedCat, setSelectedCat] = useState(null)




    const [showExam, setShowExam] = useState()


    const dico = {
        fr: {
            "voy": "Voyelles",
            "ini": "Initiales",
            "final": "Finales",
            "cons": "Consonnes",
            "ton": "Ton",
        }

    }
    


    const [cards, setCards] = useState([])

    useEffect(() => {
        workspace?.old_id && getLetters(setCards, workspace)
    } , [workspace?.old_id])

    const fetchFireUserCards = async (user_id, wp_id) => (await getDocs(query(collection(db, "user_cards"), where("user_id", "==", user_id), where("workspace_id", "==", wp_id), where("letter", "==", true) ))).docs.map(doc => ({...doc.data(), id: doc.id}));

    const getUserCards = async(user_id, wp_id) => {
        console.log('----getUserCards wp_id ', wp_id)
        console.log('----getUserCards user_id', user_id)
    
        const { data, error } = await supabase
        .from('profiles_cards')
        .select('*')
        .eq('workspace_id', wp_id)
        .eq('from', "letter")
        .eq('profile_id', user_id);
        const userCards = await fetchFireUserCards(user.id, workspace.old_id)
        console.log('diff', userCards)
        console.log('userCards data', data)
        console.log('error', error)
        setUserCards(data || [])
    } 

    useEffect(() => {
        user?.id && workspace?.old_id && getUserCards(profile?.id, workspace?.id)
    },[profile?.id, workspace?.old_id])
     
    
   

    const [userCards, setUserCards] = useState([])
   


    // console.log('userCards', userCards)
    // cards.sort((a,b) => a.rank - b.rank).forEach((e,i) => console.log(i + " : " + e.letter))


    
    const cardWithData = cards?.map(e => ({card: e, userCard: userCards?.find(uc => uc.card_id == e.id)}))
    .filter(e => (!selectedCat || (e.card.cat == selectedCat.id)))
    .sort((a,b) => a.card.rank - b.card.rank)

    console.log('cardWithData', cardWithData)
    console.log('--cards', cards)

    const cardWithDataAll = cards?.map(e => ({card: e, userCard: userCards?.find(uc => uc.card_id == e.id)}))
    .sort((a,b) => a.card.rank - b.card.rank)
    
     // on format en user_card

     


    const [showCard, setShowCard] = useState()
    const [cardToLearn, setCardToLearn] = useState()
   

    useEffect(() => {
        console.log("StoryScreen loaded")
        props.hideMenuBar(!cardToLearn?.length)

    }, [cardToLearn])

    // console.log('cardWithData', cardWithData)

 



    // interface rosetta avec plusieurs phrases qui non aucun sens. et contienne toutes les lettres de l'alphabet.
    // mettre pierre de rosette déchiffré à 80%. Tu es maitrise x% de l'alphabet russe..
    // !! impossible de le faire avec le chinois.
    // voir tuto dans chaque langue, qui explique les spécificités.

    // comment faire pour les signes ? 
    // pinying 
    // pierre de rosette hsk1 ? ou juste les 10 premiers signes 


    const newCards = [...cardWithData.filter(e => !e.userCard)]
    const cardToReview = [...cardWithData.filter(e => e.userCard)]
    const cardLearned = [...cardWithData.filter(e => e.userCard)]


    let blancks = []

    if (workspace?.old_id == "japonais" ) {
        blancks = {36:1, 37:1, 44:3, 45:4}
    }
    if (workspace?.old_id == "Arabe" ) {
      blancks = {3:1, 6:1}
    }




    console.log('cardWithData', cardWithData)
    console.log('cardToReview', cardToReview)


    // ajouter reviewCards (card avec des userCards)


    // on prends les cards "news", 


    // ajouter les dates et si tout est bon niveau date remettre système de stacking
    const goalLvl = Math.min(Math.round((cardLearned?.length / 4) + 1), 8)
    const cardToReviewT1 = [...cardWithData.filter(e => e.userCard?.triggers[0].lvl < goalLvl)]
    const cardToReviewT2 = [...cardWithData.filter(e => e.userCard?.triggers[1].lvl < goalLvl)]
    // 4 -> 1
    // 8 -> 2
    // 12 -> 3



    
    

    let lettersToHighlight = cardWithDataAll.filter(c => c.userCard).map(c => removePinyinAccents(c.card.cat == "pinyin" ? (c.card.pinyin_i || c.card.pinyin_f) : c.card.letter?.toLowerCase()))

    if (selectedCat?.id == "pinyin") {
        if (cardWithData?.filter(c => c.userCard).length > 8) {lettersToHighlight.push('s')}
        if (cardWithData?.filter(c => c.userCard).length > 12) {lettersToHighlight.push('w')}
        if (cardWithData?.filter(c => c.userCard).length > 16) {lettersToHighlight.push('y')}
    }
   


      

      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    
    function shuffleWithoutConsecutiveDuplicates(cards) {
        let shuffledCards = shuffleArray(cards);
    
        for (let i = 1; i < shuffledCards.length; i++) {
            if (shuffledCards[i].card.id === shuffledCards[i - 1].card.id) {
                // Rechercher un autre index avec une carte différente pour échanger
                let swapIndex = -1;
                for (let j = i + 1; j < shuffledCards.length; j++) {
                    if (shuffledCards[j].card.id !== shuffledCards[i].card.id) {
                        swapIndex = j;
                        break;
                    }
                }
                if (swapIndex !== -1) {
                    // Échanger les cartes pour éviter les doublons consécutifs
                    [shuffledCards[i], shuffledCards[swapIndex]] = [shuffledCards[swapIndex], shuffledCards[i]];
                }
            }
        }
    
        return shuffledCards;
    }
    
    let allCardToReview = [
        ...cardToReviewT1,
        ...cardToReviewT2,
        ...cardToReviewT1,
        ...cardToReviewT2,
    ];

    let newLesson = [
        newCards?.[0], 
        newCards?.[1], 
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[0],
        newCards?.[1],
        newCards?.[2],
        newCards?.[3],
        newCards?.[2],
        newCards?.[3],
        newCards?.[3],
    ]

    if (selectedCat?.id == "pinyin") {
        allCardToReview = [
            ...cardToReviewT1,
            ...cardToReviewT2,
        ];

        newLesson = [
            newCards?.[0], 
            newCards?.[1], 
            newCards?.[0],
            newCards?.[1],
            newCards?.[2],
            newCards?.[3],
            newCards?.[1],
            newCards?.[2],
            newCards?.[3],
            newCards?.[2],
            newCards?.[3],
            newCards?.[0],
        ]
    }


    
    // Mélanger et organiser les cartes
    const randomizedCards = shuffleWithoutConsecutiveDuplicates(allCardToReview);

    console.log('randomizedCards', randomizedCards)


    const nextLetter = [
        // ajouter les révisions ici 
        // si pas de revision ajouter revision de demain ici 
        ...randomizedCards,
        ...newLesson

    ].filter(Boolean)
        ?.slice(0, 20)


        console.log('nextLetter', nextLetter)


        // faire aussi exercice dérevision

        const showModes = [
            {id: "letter", fr: "Lettre", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
          }, 
            {id: "pierre", fr: "Pierre", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
          }, 
            {id: "edit", fr: "Editer", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
          }, 
       ]

        const [showMode, setShowMode] = useState(showModes[0].id)

        const dbCards = null


       

       
        const storage = getStorage(app);

    
    

        console.log('ct user',  ct.user)
        console.log('user ',  user)
        console.log('props user ',  props.user)
     

       


        let categories = []

        if (workspace?.old_id == "japonais") {
            categories = [{id: "hira", fr: "Hiragana"}, {id: "kata", fr: "Katakana"}]
        }

        if (workspace?.old_id == "chinois") {
            categories = [
                {id: "pinyin", fr: "Pinyin"}, 
                {id: "hanzi", fr: "Hanzi"},
                // {id: "radicaux", fr: "Radicaux"}
            ]
        }   


        useEffect(() => {
            setSelectedCat(categories[0])
        }, [workspace])

        const groupByCategory = (data) => {
            return data.reduce((acc, item) => {
              if (!acc[item.card.part]) {
                acc[item.card.part] = [];
              }
              acc[item.card.part].push(item);
              return acc;
            }, {});
          };

          const groupedCard = groupByCategory(cardWithData.filter(e => (!selectedCat || (e.card.cat == selectedCat.id))));


          console.log('groupedCard', groupedCard)

          const [adminMode, setAdminMode] = useState()

          let fontSizeRosette = 'text-xs'

          if (selectedCat?.id == "hanzi") {
            fontSizeRosette = "text-xl "
          }
          if (workspace?.old_id == "Coréen"){
            fontSizeRosette = "text-sm "
          }


          const cent = Math.ceil(cardLearned?.length*100/cards?.length) || 0

    if (showExam) {
      return <div className="fixed top-0 left-0 right-0 bottom-0 text-white bg-purple-900 ">
          <ExamScreen user={user} workspace={workspace?.old_id} setUser={props.setUser} hideMenuBar={props.hideMenuBar} cards={cards} lang={workspace?.lang} close={() => setShowExam(false)}/>
          </div>
    }


    return <div className="text-white  sticky max-h-screen overflow-scroll  ">

        <div style={{
                backgroundImage: "url('/images/seeds/bottom-store.png')",
                backgroundRepeat: "repeat-x",
                backgroundPositionY: "100%"
            }} className="overflow-hidden h-[300px] p-4 top-0 left-0 right-0 bg-blue-500 relative">
            <div className="flex justify-center">
                <div className="flex fredoka relative justify-center gap-2 px-2 rounded-xl bg-white/10">
                   <div onClick={() => {setProfileWorkspace()}} className="flex gap-2 items-center"><img src={workspace?.img} className="h-4 rounded-full" /></div><div onClick={() => setProfileWorkspace(prev => ({...prev, goal_id: null}))}>{goals.find(e => e.goal_id == profileWorkspace?.goal_id)?.short || "Choisis un parcours"}</div>
                </div>
            </div>
            <div className='absolute z-10 top-[105px] -mt-8 h-[300px] right-0 left-0 bottom-[2px]'>
            <div className={`top-[-10px] fredoka mb-1 ${cent > 0 ? "text-[#3B3434]/40" : "text-[#645D5B]"}  pl-[40px] mx-auto font-[500]  w-[165px] text-xs`}><div className='w-[23px] text-center'>{cent}<span className='text-[8px]'>%</span></div></div>

            <div className={`arab   font-sans relative text-white/40 noto mx-auto w-[165px] mb-[70px] overflow-hidden h-[170px] text-[#645D5B]/80 font-semibold ${fontSizeRosette}`}>
               test:  {workspace?.old_id == "Coréen" ? 
                highlightLetterHangul(lettersToHighlight, rosettaText[workspace?.old_id +  (selectedCat?.id ? selectedCat?.id : "")]) 
                :
                workspace?.old_id == "Arabe" ?  <div className='text-[19px] text-right pr-1 pt-1 -ml-1'>{highlightLetterArabic(lettersToHighlight, rosettaText[workspace?.old_id +  (selectedCat?.id ? selectedCat?.id : "")], "text-[#FFCD4C]") }</div> : highlightLetter(lettersToHighlight, rosettaText[workspace?.old_id +  (selectedCat?.id ? selectedCat?.id : "")]) 
                }        
            </div>
            </div>
            <div className="mx-auto absolute z-0 -mt-8 h-[300px] right-0 left-0 -bottom-[42px] flex  justify-center bg-[#5B99F8]/10 "><img src="/images/UX/stone_r.png" /></div>
            <div class="absolute z-[1]  h-[100px] right-0 -bottom-[12px] left-0" style={{backgroundImage: "url(/images/seeds/bottom-store.png)", backgroundRepeat: "repeat-x", backgroundPositionY: "100%"}}></div>
            </div>

        

       

        <div className='bg-topo pb-[140px] z-0 p-4   bg-gradient-to-b from-[#36206c] to-[#221348]'>


        

       {categories.length > 0 &&  <div className='flex fredoka items-center justify-center my-4'><div className='flex bg-white/10 rounded-xl px-3  items-center justify-center gap-2'>
            {categories.map(c => <div onClick={() => setSelectedCat(c)} className={`${c.id == selectedCat?.id ? "text-white/90" :  "text-white/40"}`}>{c.fr}</div>)}
        </div></div>}

        {user.admin && <svg onClick={() => {setShowMode(e => e == "letter" ? "edit" : "letter")}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${showMode == "letter"?  "opacity-[0.2]" : "opacity-50"} h-6 absolute top-2 right-2`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>}

          {user.admin && <svg onClick={() => {setAdminMode(e => !e)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  className={`${adminMode?  "opacity-[0.2]" : "opacity-50"} h-6 absolute top-10 right-2`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
</svg>
}

        {/* <div className='flex gap-2 mb-6 justify-center '>{showModes.map(e => <div className={`${e.id == showMode ? "text-white/90 underline" : "text-white/30" } px-2 `} onClick={() => setShowMode(e.id)}>{e.icon}</div>)}</div> */}
    

        {/* <PaintableLetter letter={"ج"}  /> */}
        {/* <DrawingCanvas letter={"ج"} /> */}

       

    

        <div className='fixed fredoka text-shadow z-[2] bottom-[80px] left-0 right-0 flex items-center justify-center'><div className='px-8 rounded-xl bg-purple-500 border-2 border-b-4 py-2 font-semibold  text-center border-black game-btn bg-rosette3'  onClick={() => setCardToLearn(nextLetter)}>Apprendre</div></div>

        {cardToLearn && <LearnView otherCards={cards} setUser={setUser} user={user} workspace={workspace} userCards={userCards} setUserCards={setUserCards} cardToLearn={cardToLearn} setCardToLearn={setCardToLearn} />}

        {showCard && <div onClick={() => {setShowCard()}} className='fixed bg-black/20 z-[50] flex items-center justify-center top-0 left-0 right-0 bottom-0'>
            <div onClick={(e) => e.stopPropagation()}><LetterCard  defaultTrigger={"null"} lang={workspace?.lang} cards={cards} card={showCard.card} userCard={showCard.userCard} /></div>
            </div>}

       {/* <div className='text-center fredoka mb-4 text-2xl'>Pierre de rosette</div> */}

       

       {showMode == "letter" && 
       <div className='text-white'>
       {Object.keys(groupedCard).map((category, i, self) => (
           <div key={category}>
           <div onClick={()=> console.log('Object.keys(groupedCard)', Object.keys(groupedCard))} className='mt-4 mb-2 fredoka'>{dico.fr[`${category}`] ? dico.fr[`${category}`] : Object.keys(groupedCard).length > 1 ?  "Autre" : ""} </div>
           <ul>
               <div className={`${workspace?.old_id == "japonais" ? 'grid-cols-5' : 'grid-cols-4' } grid gap-2 flex-wrap`}>
                   {groupedCard[category].sort((a,b) => a.card.rank - b.card.rank).filter(e => (!selectedCat || (e.card.cat == selectedCat.id))).map((e, i) => <>{blancks[i] && [...Array(blancks[i])].map((_, i) => <div key={i}></div>)}<LetterMini isAdmin={adminMode} index={i} lang={workspace?.lang} goalLvl={goalLvl} setShowCard={setShowCard} userCard={e.userCard} card={e.card} /></>)}
                   </div>

               {/* {groupedCard[category].map((item, index) => (
               <li onClick={() => {console.log('items', item)}} key={index}>- {item?.letter}</li>
               ))} */}
           </ul>
           </div>
       ))}
       </div>
        // <div className='grid grid-cols-4 gap-2 flex-wrap'>{cardWithData.sort((a,b) => a.card.rank - b.card.rank).filter(e => (!selectedCat || (e.card.cat == selectedCat.id))).map((e, i) => <LetterMini index={i} lang={workspace?.lang} goalLvl={goalLvl} setShowCard={setShowCard} userCard={e.userCard} card={e.card} />)}</div>
       }

        <div onClick={() => {setShowExam(e => !e)}} className='mt-4 border-2 fredoka border-b-4 flex gap-2 justify-between rounded-xl border-black/50 items-center px-4 py-2 bg-cyan-900/20'>
          <div>
            <div className='text-[16px]'>Teste ton niveau</div>
            <div className='text-xs text-white/70'>Valide tes compétences et obtient le meilleur score</div>
          </div>
          <div className='min-w-[60px]'>
            <div>
            <div onClick={() => console.log('user', user)} className='text-sm text-white/90'>Ma note</div>
            <div className='text-amber-300 text-lg text-shadow'>{scoreToNote(user[`s_${workspace?.old_id}`])}/20</div>
            </div>
          </div>
        </div>
       

       
       {user.admin && <div>
       {showMode == "edit" && <div className='p-4 bg-white  fixed  left-0 right-0 top-[140px] max-h-[90vh] pb-[500px] overflow-scroll rounded border-2 text-blue-700'>

     
        <div className='border-b-4 '>fake: </div>

        {/* {Fakecards?.map(e => <EditCard workspace={workspace} setShowCard={setShowCard} _card={e} fake={true} />)} */}
        <div onClick={() =>   getLetters(setCards, workspace)}>getLetters</div>
        <div onClick={() => {
            cardWithData.forEach(c => {
                const card= c.card
                console.log(`${card.letter} [${card.latin}] : ${card.word} [${card.word_latin}] ${card.word_fr}
note: "${card.note}"
astuce: "${card.hint}"`)
            
            
            })

            cardWithData.forEach(c => {
                const card= c.card
                console.log(`[${card.pinyin_i || card.pinyin_f}] -- ${card.letter} [${card.latin}] : ${card.word} [${card.word_latin}] ${card.word_fr}`)
            
            
            })


            // cardWithData.filter(e => e.card.pinyin_f).forEach(c => {
            //     const card= c.card
            //     console.log(`${card.pinyin_f}`)
            
            // })
 

            }}>print </div>
        {cardWithData.sort((a,b) => a.card.rank - b.card.rank).map(e => <EditCard  storage={storage} workspace={workspace} setShowCard={setShowCard} _card={e.card}  />)}
        <EditCard storage={storage} setShowCard={setShowCard} />
        </div>}

        <div onClick={() => {console.log('user_card', userCards)}}>console</div>
        <div 
            className='mt-16 p-3 fredoka text-center text-red-100' 
            onClick={async () => {
                try {
                const { error } = await supabase
                    .from('profiles_cards')
                    .delete()
                    .in('id', userCards.map(card => card.id))

                if (error) throw error
                setUserCards([])
                } catch (error) {
                console.error('Erreur lors de la suppression des cartes:', error)
                }
            }}
            >
            Réinitialiser {userCards?.length} cartes
            </div>
        </div>
        }





       {/* <div>goal {goalLvl}</div>
        <div>learned: {cardLearned.length}</div>

        Liste des stacks
        {nextLetter?.map( e => <div className='flex gap-2'><div className='w-12'>{e?.card?.letter} </div> 
        <div>
            <span className='text-green-500'>{e.userCard?.triggers?.[0]?.lvl}</span> - 
            <span className='text-purple-300'>{e.userCard?.triggers?.[1]?.lvl}</span>
            <span className='text-purple-300'>next_t: {e.userCard?.next_trigger}</span>
        </div>
        </div>) } */}

        </div>
  </div>

};

export {RosetaScreen};





