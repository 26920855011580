import { doc, updateDoc } from '@firebase/firestore';
import React, { useRef, useEffect, useState } from 'react';
import { db } from '../../config';

const FSVideo = ({ src, user, setUser}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    // if (video) {

    //   video.addEventListener('timeupdate', handleTimeUpdate);
    //   video.addEventListener('seeking', preventSeeking);

    //   return () => {
    //     video.removeEventListener('timeupdate', handleTimeUpdate);
    //     video.removeEventListener('seeking', preventSeeking);
    //   };
    // }
  }, []);

  const [lastTime, setLastTime] = useState(0);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (video) {
      setLastTime(video.currentTime);
     
    }
  };

  const preventSeeking = () => {
    const video = videoRef.current;
    if (video) {
      if (video.currentTime > lastTime) {
        video.currentTime = lastTime;
        
      }
    }
  };

  const handleVideoClick = () => {
    const video = videoRef.current;
    console.log('user', user)

    
    if (video) {
      if (isPlaying) {
        video.pause();
        setIsPlaying(false);
      } else {
        // Lancer la vidéo en plein écran
        const requestFullscreen =
          video.requestFullscreen ||
          video.mozRequestFullScreen ||
          video.webkitRequestFullscreen ||
          video.msRequestFullscreen ||
          video.webkitEnterFullscreen; // Ajout pour iOS/Android

        if (requestFullscreen) {
          requestFullscreen.call(video);
        }

        // Démarrer la lecture
        video.play();
        setIsPlaying(true);
        setTimeout(() => {
          setUser(u => ({...u, tt_sv: true}))
          updateDoc(doc(db, "users", user.id), {tt_sv: true})





        }, [500])
        // Update du user avec le nouveau statut de vidéo en plein écran
      }
    }
  };

  return (
    <div className="video-container" style={{ position: 'relative' }}>
      <video
        ref={videoRef}
        onClick={handleVideoClick}
        poster="/images/UX/tuto/preview_tuto.png"
        disablePictureInPicture
        controlsList="nodownload noremoteplayback noprogressbar"
        className="border-2 border-b-4 border-blue-900 bg-blue-500 rounded-xl mb-4"
        onEnded={() => {
          console.log('user', user)
        }}
        style={{
          width: '100%',
          height: '400px',
          backgroundColor: '#000',
        }}
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/video%2FTuto_ms_2.mp4?alt=media&token=8c338e38-c398-4e39-a34b-89b817d51c75"
          type="video/mp4"
        />
        Votre navigateur ne supporte pas la lecture de vidéos.
      </video>
      {/* Bouton personnalisé */}
      {!isPlaying && <button
        onClick={handleVideoClick}
        className='flex justify-center items-center'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          height: 70,
          width: 70,

          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.7)',
          border: 'none',
          borderRadius: '500px',
          padding: '20px',
          color: '#fff',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      >
        {isPlaying ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
</svg>
}
      </button>}
    </div>
  );
};

export default FSVideo;
