import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { collection, addDoc, query, getDocs, doc,getDoc, where, updateDoc, setDoc, deleteDoc, onSnapshot, Timestamp} from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';

import { db} from "../../config.js";

import { ImageUpload } from "../components/imageUpload.js";
import { bg_sound, categories, centToStars, displayDuration, evolutionsQuest, getCardsData, getCardsFromIDS, getDate, getDeckData, getStarsFromTraining, getUserCardsData, images as imagesRaw, missingN, nToStars, pops, tutoForest} from '../../utilities/helper/helper';
import {Popover} from '../components/forest/popover.js' 
import { Chronos } from "../components/chronos.js";
import { Quest } from "../components/forest/quest.js";
import {getTitle, getCons, speak, capitalizeFirstLetter} from "../../utilities/helper/text.js"
import { Cadena, LockScreen } from "./perfectScreen.js";
import { InteractivePage } from "../components/interactivePages.js";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { debounce, throttle } from "lodash";
import { ref } from "firebase/storage";
import {Howl, Howler} from 'howler';
import { createAvatarUrl } from "../avatar/parts.js";
import { formatDate } from "../components/learn/learn.js";




const SoundToggleButton = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  // Fonction pour jouer ou arrêter le son de fond
  const toggleBackgroundSound = () => {
    if (isPlaying) {
      bg_sound.pause();  // Pause le son
      bg_sound.currentTime = 0; // Remettre à zéro
    } else {
      bg_sound.play();  // Joue le son
    }
    setIsPlaying(!isPlaying);  // Inverse l'état
  };

  return (
    <div className="">
      <div onClick={toggleBackgroundSound}>
        {isPlaying ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
</svg>
}
      </div>
    </div>
  );
};


const deleteUnusedPack = (packed_seeds, userCards, ct, setUserDecoration) => {
  const searchParams = new URLSearchParams(window.location.href);
  const visited_uwp = searchParams.get("uwp")


  if(visited_uwp) return
  !ct.showCards?.length && userCards?.length > 3 &&  packed_seeds.length > 1 && packed_seeds?.forEach((pack, i) => {
    const cards = userCards?.filter(uc => !uc.ud_id).filter(uc => uc.pack?.id == pack?.id ) 
    
    if (cards?.length == 0) {

      console.log('suppression packseed ❌', pack)
      ct.setAlerts([{title: "Pack de graine neutre supprimé " +  i}])
     
      setUserDecoration(ud => ud.filter(ud => ud.id != pack.id))
      ct.fire.remove(pack.id, "user_workspace_element")
    }
})
}

const delectedDoubleUserCard = (userCards) => {



  const seenCardIds = new Set();
  return userCards?.filter(userCard => {
    if (seenCardIds.has(userCard.card_id)) {
      console.log('suppresssion ❌', userCard.card_id)
      deleteDoc(doc(db, "user_cards", userCard.id))
      return false;
    } else {
      seenCardIds.add(userCard.card_id);
      return true;
    }
  });
  

}


function isPointInsideBiomes(pointX, pointY, biomes) {
  for (const biome of biomes) {
      // Vérifie si le point est à l'intérieur des limites du biome
      if (pointX >= biome.x && pointX < biome.x + biome.w && 
          pointY >= biome.y && pointY < biome.y + biome.h) {
          return true; // Le point est à l'intérieur de ce biome
      }
  }
  return false; // Le point n'est dans aucun biome
}


const layer1 = [ 
  {x: 3, y: 3, h: 9, w: 9, z:-3, name: "biome_1"},
  {x: -3, y: -3, h: 9, w: 9, z:-3,  name: "biome_1"},
  {x: -3, y: 3, h: 9, w: 9, z:-3, name: "biome_1"},
  {x: 3, y: -3, h: 9, w: 9 ,  z:-3,  name: "biome_1"}
]
const layer1_bis = [ 
  {x: -3, y: 3, h: 9, w: 9, z:-1, name: "biome_1"},
]

const layer2 = [
  {x: 6, y: 6, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 6, y: -6, h: 9, w: 9,   z:-1, name: "biome_1"},
  {x: -6, y: -6, h: 9, w: 9,  z:-1,name: "biome_1"},
  {x: -6, y: 6, h: 9, w: 9,  z:-1,name: "biome_1"},

  
  {x: -6, y: 3, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 6, y: 3, h: 9, w: 9, z:-1, name: "biome_1"},
  {x: 3, y: -6, h: 9, w: 9, z:-1, name: "biome_1"},
  {x: -3, y: 6, h: 9, w: 9, z:-1, name: "biome_1"},
]

const layer3 = [
  {x: 0, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},

  {x: 9, y: 9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 0, y: 9, h: 9, w: 9,   z:-1,name: "biome_1"},
  {x: 0, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},

 

]

const layer4 = [

  {x: 0, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},

  {x: 9, y: 9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 0, y: 9, h: 9, w: 9,   z:-1,name: "biome_1"},
  {x: 0, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: 0, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: 9, y: -9, h: 9, w: 9,  z:-1, name: "biome_1"},
  {x: -9, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},


  {x: -12, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: 0, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: -9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: -12, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},

  {x: 12, y: 9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: 0, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: -9, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 12, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},


  {x: -9, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 0, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 9, y: 12, h: 9, w: 9, z:-1,  name: "biome_1"},

  {x: -9, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 0, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},
  {x: 9, y: -12, h: 9, w: 9, z:-1,  name: "biome_1"},



]
const layer0 = [
  {x: 0, y: 0, h: 9, w: 9, z:-3,  name: "biome_1"},
]

let b = []







const versionExt = "?&v=2"

  const swipeFactor = 2



let dm = ""
let topX = 0
if (window.matchMedia('(display-mode: standalone)').matches) {
   dm = "standalone"
} else if (window.matchMedia('(display-mode: fullscreen)').matches) {
   dm = "fullscreen"
} else if (window.matchMedia('(display-mode: minimal-ui)').matches) {
   dm = "minimal-ui"
} else if (window.matchMedia('(display-mode: browser)').matches) {
   dm = "browser"
   topX = 100
   
} else {
   dm = "???"
}



const MiniCard = ({term}) => <div className="text-xs text-center pb-1">
<img src="/images/seeds/green-3.png" className={` ${term ? "-mt-4 -mb-1" : " -m-2 opacity-30 filter-grayscale"} h-[50px]   mx-auto`} />
<div className={`${
  term > 7
    ? term > 10
      ? 'text-[9px]'
      : 'text-[10px]'
    : ''
} overflow-hidden text-ellipsis whitespace-nowrap`}>
  {capitalizeFirstLetter(term)}
</div>
</div>

const renderInventoryItems = (evo, selectedCategories, userDecorationWithElement, evolutions) => {
  const cards = evo?.cards || [];
  const ingredients = evo?.ingredients || [];
  const recipe = evo.recipe
  console.log('evo', evo)
  // Ensure at least 4 items
  let items = [...(cards || [])];
  if (ingredients.length) {items = [...(ingredients || [])];}
  while (items.length < 4) {
    items.push({ card: { term: '' } });
  }

  console.log('userDecoration', userDecorationWithElement.map(ud => ud.id))
  console.log('items', items)
  const imagesName = items.map((item) => userDecorationWithElement.find(ud => ud.id == item && !ud.name.includes('seed'))?.element?.src || "seed")
  console.log('imagesName', imagesName)

  if (!ingredients.length) {

    if (recipe) {
     
      return items.map((item, index) => {
        const currentEvo = evolutions.find(evo => evo.name == recipe?.[index])
        return (
        <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
          {currentEvo?.name == "Fleur" ?   <PackedSeedImg className={"opacity-50 scale-[0.8]"} selectedCategories={selectedCategories} />  : <img className="opacity-50" src={currentEvo?.element.replace('@@@', selectedCategories.color_seed)}/>}
        </div>
      )});
    }
   
    return items.map((item, index) => (
      <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
        <MiniCard term={item?.card?.term} />
      </div>
    ));
  }
   // <PackedSeedImg selectedCategories={selectedCategories} />  
  return items.map((item, index) => (
    <div key={index} className="e-inventaire bg-[#422A94] h-[50px] rounded-md">
     {(ingredients && !item.card) ?  
  ( 
    imagesName?.[index] != "seed" ? 
    <img src={imagesName?.[index]} className="h-[60px] mx-auto" /> :
      <PackedSeedImg selectedCategories={selectedCategories} /> 
      
  ) : 
  (
    <MiniCard term={item?.card?.term} />
  )
}
    </div>
  ));
};


const PackedSeedImg = ({selectedCategories, className}) => {

  return <div className={className+" relative pointer-events-none w-[45px] h-[45px] mx-auto"}>
  <img className="absolute -top-8 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -left-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute -top-4 -right-4 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />
  <img className="absolute top-0 bottom-2 h-[60px]" src={"/images/seeds/@@@-3.png".replace('@@@', selectedCategories.color_seed)} />

</div>
}


const AlchimieScreen = ({
  ct, 
  setShowAlchimie,
  masteredCards,
  userDecoration,
  setUserDecoration,
  images,
  packed_seeds,
  setSelectedDeco,
  selectedEvo,
  setSelectedEvo,
  evolutions
})=> {

    const [chaudronPage, setChaudronPage] = useState(false)
    const selectedCategories = categories?.[selectedEvo?.bc_id]
    const cardsData = getUserCardsData(ct, masteredCards)
    const currentCategoryCards = cardsData?.filter( c=> c.card?.bc_id == selectedEvo?.bc_id)
    const currentCategoryFreeCards = currentCategoryCards?.filter(c=> !userDecoration?.find(ud => ud.id == c.ud_id) ) // a adapter avec evo

    const decoName = selectedEvo?.eName?.replace("@@@", selectedCategories.color_seed)
    const userDecorationWithElement =  userDecoration?.filter(e => e.type == "evo").map(e => ({...e, element: images?.find(i => i.name == e.name)})).filter(e => e.element?.bc_id == selectedEvo?.bc_id)
    const seedMode = selectedEvo?.name == "Fleur"

    useEffect(() => {
    
      seedMode && setSelectedEvo(ev => ({...ev, cards: [...ev?.cards || [], ...currentCategoryFreeCards?.slice(0, 4) || [] ]}))
      console.log('auto card', [...selectedEvo?.cards || [], ...currentCategoryFreeCards?.slice(0, 4) || [] ])
    }, [seedMode, selectedEvo?.eName])


    const nbEvo = categories?.map((cat, i) => evolutions?.filter(ev => ev.lvl <= ct.userWorkspace?.["bc_"+i+"_lvl"]) )?.flat()?.length || 0


return <div className="h-screen pb-20 fredoka text-white fixed top-0 bottom-0 z-[100] left-0 right-0 bg-[#433182]">
  
  {!selectedEvo && <div>
    
  <div className="p-4  bg-[#58459d] items-center justify-between flex gap-2">
    <img src="/images/deco/alchimie_logo.png" className="h-[40px] -ml-2 mx-auto" />

    <div className="mb-1 grow text-center text-2xl">Alchimie</div>
     

    <div onClick={(e) => {setShowAlchimie(false); console.log('test')}}  className=""><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8  bg-red-500 rounded">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg>
  </div>  
   
  </div>
  </div>}
  <div className="p-4 overflow-scroll pb-[200px] max-h-screen ">

    <div className="p-2 px-4 text-sm bg-[#513D9A] my-4 rounded-xl">Donne de la <span className="text-[#FFC700]">couleur</span> à tes souvenirs en fonction de leur <span className="text-[#FFC700]">thématique</span>, et  <span className="text-[#FFC700]">groupe</span> les ensemble pour mieux organiser tes connaissance</div>

  
    


    {selectedEvo && <div className="bg-[#412B8E] z-[10] max-h-screen  overflow-scroll fixed bg-[#433182] top-0 bottom-0 right-0 left-0 ">
    
      <div className=" px-4 py-2  text-center">
        <div className="my-2 text-left flex items-center gap-2" onClick={() => setSelectedEvo()}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
</svg>
 Retour </div>
 
        <div className="flex justify-end -mt-2 items-center text-right gap-4">
          <div>
            <div className="text text-shadow font-sembold "><span style={{borderColor: selectedCategories.color}} className="inline-block ">{categories?.[selectedEvo.bc_id].evo_name_fr[selectedEvo.lvl -1]}</span></div>
            <div className="text-sm text-white/60">Fusionne 8 souvenirs dans la même thématique</div>
          </div>
          <div className="relative mt-4">
              <svg className="h-[80px] absolute -bottom-8" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="41" cy="39" r="37" fill="#573ABB" stroke="#2C196E" stroke-width="4"/>
                  <path d="M33.7129 73.7031L13.1401 79.6635L9.99392 56.6617" fill="#573ABB"/>
                  <path d="M33.7129 73.7031L13.1401 79.6635L9.99392 56.6617" stroke="#2C196E" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            <div><img src={selectedEvo?.element.replace('@@@', selectedCategories.color_seed)} className="h-[100px] -mb-4 relative"/></div>
          </div>
        </div>
        {/* <div>Fais évoluer tes graines neutre en graine de <span style={{color: selectedCategories.color}}>{categories?.[selectedEvo.bc_id].name_fr}</span></div> */}
      </div>
      {/* <div>Selected Evo : {selectedEvo?.id}</div> */}
      <main className="">
      <div>
        {/* <img src="/images/deco/alchimie_logo.png" className="h-[160px] -mt-16 mx-auto" /> */}
      </div>
      <div className="flex  justify-center gap-4 items-center">
        <div className="bg-[#2C196E] p-1 rounded-xl -mr-4">
          <div className="p-1 rounded-xl text-sm text-center">Ma selection</div>
          <div className="bg-[#573ABB] gap-1 rounded-xl w-[120px] p-1 grid-cols-2 grid">
            {renderInventoryItems(selectedEvo, selectedCategories, userDecorationWithElement, evolutions)}
            {/* {selectedEvo?.ingredients?.map(e => <div>{
              <PackedSeedImg evo={evolutions[0]} selectedCategories={selectedCategories} />
            
            }</div>)} */}

          </div>
        </div>
        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="h-8 text-[#9279EB]">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg> */}
<svg width="66" height="55" viewBox="0 0 66 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.7552 44.6068L46.0912 18.2816L41.2912 31.4632L26.5639 35.696L52.7552 44.6068Z" fill="#231163"/>
<path d="M9.46106 13.8857C14.4497 12.2037 35.9432 19.1545 45.4856 29.3858L36.0648 35.7833C31.1974 23.1085 7.86703 14.5266 6.13524 16.3042C6.13524 16.3042 4.47241 15.5676 9.46106 13.8857Z" fill="#231163"/>
</svg>


<div className="relative -ml-6 mt-6 ">
    <CircularProgressbar
                            value={Math.ceil(selectedEvo.cards?.length*100/selectedEvo?.n) || 0}
                            className='absolute circularProgress !scale-x-[2.4] bg-[#2C196E] rounded-full left-[30px] bottom-[-30px]  transition w-[80px] !h-[80px]'
                            styles={buildStyles({
                              strokeLinecap: 'butt',
                              strokeWidth: 2,
                              textSize: '0px',
                              pathColor: "#FFC700",
                              textColor: 'red',
                              pathTransitionDuration: 0.5,
                              strokeLinecap: 'round', 
                              trailColor: '#573ABB',
                              backgroundColor: 'yellow',
                              transform: 'rotateX(37deg)'
                            })}
                            strokeWidth={8}

                    />
<img src="/images/UX/alchimie_logo.png" className="h-[160px] chaudron relative z-[10] mx-auto" />

                    </div>
      
      </div>

      <div className="bg-[#573ABB] border-t-4 border-black/70 mt-[70px] p-3 pb-[120px]  overflow-hidden p-1 ">
        <div className="">

          <div className="text-xs mb-2 text-white/70">{!seedMode ? "" :"Graines neutres >"} <span className=" border-b-[1.5px]" style={{borderColor: selectedCategories.color}}>{categories?.[selectedEvo.bc_id].name_fr}</span></div>
          </div>
         
      <div className="text-xs bg-[#573ABB]/0  justify-center rounded mt-2 grid gap-[4px] grid-cols-[repeat(auto-fit,_minmax(50px,_1fr))]">
   
        {!seedMode && userDecorationWithElement?.map(pc => <div
         onClick={() => {
         
          const cards = ct.userCards.filter(uc => uc.ud_id == pc.id).map(e => ({...e, card: e.content})) || []
          console.log('pc', pc)
          console.log('cards', cards)
          selectedEvo?.ingredients?.find(i => i == pc.id) ?   
            setSelectedEvo(ev => ({...ev, 
              cards: ev.cards.filter(c => !cards.find(e => e.id == c.id) ) || [], 
              ingredients: ev.ingredients.filter(i => i != pc.id)})) 
            : setSelectedEvo(ev => ({...ev, 
              cards: [...ev.cards || [], ...cards], 
              ingredients: [...ev.ingredients || [], pc.id]})) 
        }}
         className={`${ selectedEvo?.ingredients?.find(i => i == pc.id) ? "border-yellow-500" : ""} relative h-[55px] text-xs mb-1 pointer-events-auto bg-[#422A94] text-center rounded border-2 border-black/20`}>
          
          {pc.name.includes('seed') ? <PackedSeedImg evo={evolutions[0]} selectedCategories={selectedCategories} /> : <img src={pc?.element.src} className="h-[60px] mx-auto" />}
            </div>)}
        
        {!seedMode && Array.from({ length: 10 - userDecorationWithElement.length }, (_, index) => (<div className="bg-[#422A94]/40 h-[55px] rounded"></div>))}
       
        {
      seedMode && currentCategoryFreeCards?.filter(c => c.card?.bc_id == selectedEvo?.bc_id )
        .map(c => 
          <div 
            onClick={() => {
              if(selectedEvo?.cards?.map(uc => uc.card_id).includes(c.card_id)) {
                setSelectedEvo(ev => ({...ev, cards: ev.cards?.filter(ev => ev.card_id != c.card_id)|| []}))
              } else {
                setSelectedEvo(ev => ({...ev, cards: [...ev.cards || [], c]}))
              }}} 
            // style={{borderColor: categories?.[selectedEvo.bc_id].color}} 
            className={`${c.ud_id ? "bg-red-500/10" : ""} ${selectedEvo?.cards?.map(uc => uc.card_id).includes(c.card_id) ? "opacity-100 border-yellow-500":"opacity-70"} relative h-[55px] text-xs  bg-[#422A94] text-center rounded border-2 border-black/20`}>
              <div style={{ backgroundColor: selectedCategories.color }} className="absolute z-0 rounded  h-[4px]  opacity-[50%] left-[1px] right-[1px] bottom-[2px] "></div>
              <img src="/images/seeds/green-3.png" className="h-[50px] relative -mt-4 -mb-1 mx-auto" />
              <div className={` ${c.card.term.length > 7 ? c.card.term.length > 10 ? "text-[9px]" :  "text-[10px]" : ""} relative overflow-hidden  text-ellipsis whitespace-nowrap`}>{capitalizeFirstLetter(c.card.term)}</div>
          </div>)}
          {seedMode && Array.from({ length: 12 - currentCategoryFreeCards.length }, (_, index) => (<div className="bg-[#422A94]/40 h-[55px] rounded"></div>))}
          </div>
      </div>
      <div></div>
      <div className="flex fixed bottom-0 left-0 right-0 bg-[#573ABB] p-4">
        <div className={`${(selectedEvo?.n - selectedEvo.cards?.length) == 0 ? "scale-[1.2] bg-[#FFC700]" : "pointer-events-none grayscale-[80%] bg-black/20 opacity-[60%] "} flex gap-2 justify-between items-center border-black transition-all  text-shadow font-semibold px-4 border-b-[8px] border-2  py-2 rounded-xl mx-auto`} 
      onClick={() => {
        console.log('selected evo', selectedEvo.cards)
        const new_deco = {
          "c": 0,
          "name": decoName,
          "type": "evo",
          "render_card": true, // render_card
          "bc_id": selectedEvo?.bc_id,
          size: 8,
          cd: new Date(),
          "uwp": ct.userWorkspace?.id
        }
       
        setSelectedDeco(new_deco)
        setShowAlchimie(false)
        


        // on supprime les ingrédients
        console.log('selectedEvo', selectedEvo)
        selectedEvo?.ingredients?.forEach(i => {
          const selectedObj = userDecoration.find(e => e.id == i)
          ct.fire.remove(selectedObj.id, "user_workspace_element")
        })
       
        setUserDecoration((userDecoration.filter(e => !selectedEvo?.ingredients?.includes(e.id))))


        }}>Fusionner
        <img src="images/UX/magic.png" className="h-[40px] -mt-4 -mr-4" />
         </div>
        
        </div>
      </main>
    </div>}

    <div></div>
    <div className="flex mt-8 -mb-2"><div className="rounded-full" onClick={() => {console.log('index_Car')}}>Evolution disponible</div></div>

    {nbEvo == 0 ?  <div className="p-2 px-4 rounded-xl bg-black/30 border-2 border-black/30 my-2 mt-8 ">Apprends plus de mots de la même catégorie pour débloquer une recette d'évolution.</div>: ""}


    <div className=" pb-1 pt-[20px] ">
    {categories.slice(0, 12).map((c,i) => {
      const totalSeed = cardsData?.filter(c => c.card?.bc_id == i)?.length
      const totalSeedNonPlanted = cardsData?.filter(c => c.card?.bc_id == i && !c.ud_id )?.length
      const totalSeedPlanted = cardsData?.filter(c => c.card?.bc_id == i && c.ud_id )?.length
      const evoAvailable = evolutions.filter(ev => ev.lvl <= ct.userWorkspace?.["bc_"+i+"_lvl"]) || 0
     

      if (evoAvailable.length == 0) return
      
      return <div><div className="text-sm mb-1 text-white/70">{c.evo_name_fr[0]}</div><div className="grid grid-cols-4 gap-2 mb-1"> 
        {evoAvailable.map(ev => {


      const isAvailable = ev?.n == 4 ? totalSeedNonPlanted > 3 : totalSeedPlanted > (ev?.n - 1)
      const id = ev.name + "_" + i
       // si plante on vérifie juste que c'est possible qu'il y a 4 cartes
       // si orchidé on vérifie qu'il y a assez de cartes - le nb d'orchidé plantés 
       // si champi on vérifie qu'il y a assez de cartes - nb de champi 
       //borderColor: c.color+'80'

        return <div onClick={() => setSelectedEvo({...ev, id: id, bc_id: i})} style={{color: c.text_color, }} className={`h-[70px] bg-[#291B5B] border-b-[5px] ${!isAvailable ? "opacity-50" : ""} flex  items-center border-black/60 rounded-xl border-2 p-1 w-[80px] text-xs text-center `}>
          <div>
            <div><img  src={ev.element.replace('@@@', c.color_seed)} />
            {/* {totalSeedPlanted}/{ev?.n} */}
            </div>
            {/* <div>{ev.name}</div> */}
            {/* <div>x{ev.n}</div> */}
          </div>
          </div>})
    
    }
    {Array.from({ length: 4 - evoAvailable.length }, (_, index) => (<div className="h-[70px] border-b-[5px] bg-[#392778] border-none flex items-center justify-center items-center border-black/60 rounded-xl border-2 p-1 w-[80px] text-xs text-center   "> 
    <svg className="h-10 grayscale-[50%] opacity-50 rotate-[-10deg]" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6967 9.73285C16.1664 4.71999 21.4728 1.81961 26.4857 3.28928C31.3376 4.71178 34.2106 9.72871 33.0574 14.5948L30.1727 13.7491C30.8668 10.4671 28.912 7.12689 25.6417 6.16811C22.21 5.162 18.5816 7.14517 17.5755 10.5769L16.5908 13.9355L13.712 13.0915L14.6967 9.73285Z" fill="#424242" stroke="white"/>
<path d="M27.0289 44.0897L3.9983 37.3376C2.15214 36.7963 1.08309 34.8404 1.62435 32.9942L6.68844 15.7213C7.22969 13.8751 9.1856 12.8061 11.0317 13.3473L34.0624 20.0995C35.9085 20.6407 36.9776 22.5966 36.4363 24.4428L31.3722 41.7157C30.831 43.5619 28.8751 44.6309 27.0289 44.0897Z" fill={"#625398"} stroke="white"/>
<path d="M18.1869 31.5978C19.7768 32.0639 21.4436 31.1529 21.9097 29.5629C22.3759 27.973 21.4649 26.3062 19.8749 25.8401C18.285 25.374 16.6182 26.285 16.1521 27.8749C15.6859 29.4649 16.597 31.1316 18.1869 31.5978Z" fill="white"/>
<path d="M21.4299 29.4223C21.0415 30.7472 19.6525 31.5064 18.3276 31.118C17.0026 30.7295 16.2434 29.3405 16.6319 28.0156C17.0203 26.6906 18.4093 25.9315 19.7343 26.3199C21.0592 26.7084 21.8184 28.0973 21.4299 29.4223Z" stroke="white" stroke-opacity="0.32"/>
</svg>
</div>))}
    </div></div>}
    )}
    </div>

    <div className="flex mt-8 -mb-4"><div className="rounded-full" onClick={() => {console.log('index_Car')}}>Quêtes d'évolutions</div></div>
    <div className="pt-[20px]  rounded-xl">
    {categories.slice(0, 12).map((e, i) => {
    const mc = cardsData?.filter(c => c.card?.bc_id == i)?.length
    const planted_seed = packed_seeds.filter(e => e.bc_id == i)?.length
    const totalCards = evolutionsQuest?.[ct.userWorkspace?.["bc_"+i+"_lvl"] || 0]?.card
    const lvl = ct.userWorkspace?.["bc_"+i+"_lvl"] || 0

    let cent = Math.ceil(mc*100/totalCards) || 0
    if (cent > 99) cent = 100
    const isMax = cent == 100 && lvl == 4
    return <div className={`border-2 flex border-black/40 bg-[#291B5B] gap-2 p-2 rounded-xl mb-2 `}>
      <div className="h-[55px] flex justify-center items-center w-[55px] bg-black/20 rounded-xl">
      <svg width="30" height="40" viewBox="0 0 74 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.3911 9.5L31.8911 17.5L50.8911 24.5L53.8911 17.5L34.3911 9.5Z" fill="#94A7C5"/>
        <path d="M30.8905 29.5L34.8905 18.5L46.8905 23.5L42.8905 33.5C71.4444 60.8744 42.7207 92.0162 17.891 82.5C-7.86941 72.6271 -7.71465 28.8995 30.8905 29.5Z" fill="#CEDFF8"/>
        <path d="M50.3921 58.4883L8.89213 43.4883C2.08907 46.87 0.349449 71.7682 19.8921 77.9883C39.5717 84.252 53.8231 63.0783 50.3921 58.4883Z" fill={e.color}/>
        <circle cx="13.3911" cy="57" r="3" fill="white" fill-opacity="0.3"/>
        <circle cx="29.3911" cy="69" r="5" fill="white" fill-opacity="0.3"/>
        <circle cx="41.3911" cy="63" r="3" fill="white" fill-opacity="0.3"/>
        <path d="M40.8911 4L38.3911 11L49.8911 15.5L52.3911 8L40.8911 4Z" fill="#7D889E"/>
        <path d="M36.8911 32.5L41.8911 21L47.3911 23L43.3911 34C71.4555 62.8139 45.5058 92.0933 14.8911 81.5C54.1669 77.4882 58.9983 58.0079 36.8911 32.5Z" fill="#C0D3F0"/>
        <path d="M16.3911 4.5C22.5231 6.44101 24.4953 4.96086 26.3911 0C24.8552 5.24785 26.6974 7.60793 31.8911 10.5C26.1539 8.0822 22.9848 10.0773 20.8911 15.5C22.7472 10.6491 21.7308 6.93732 16.3911 4.5Z" fill="#FFCC57"/>
        <path d="M16.3911 4.5C22.5231 6.44101 24.4953 4.96086 26.3911 0C24.8552 5.24785 26.6974 7.60793 31.8911 10.5C26.1539 8.0822 22.9848 10.0773 20.8911 15.5C22.7472 10.6491 21.7308 6.93732 16.3911 4.5Z" fill="#FFCC57"/>
        <path d="M11.3911 29.5C17.5231 31.441 19.4953 29.9609 21.3911 25C19.8552 30.2478 21.6974 32.6079 26.8911 35.5C21.1539 33.0822 17.9848 35.0773 15.8911 40.5C17.7472 35.6491 16.7308 31.9373 11.3911 29.5Z" fill="#FFCC57"/>
        <path d="M11.3911 29.5C17.5231 31.441 19.4953 29.9609 21.3911 25C19.8552 30.2478 21.6974 32.6079 26.8911 35.5C21.1539 33.0822 17.9848 35.0773 15.8911 40.5C17.7472 35.6491 16.7308 31.9373 11.3911 29.5Z" fill="#FFCC57"/>
        <path d="M51.3911 25.6774C60.0946 28.5576 62.8939 26.3613 65.5847 19C63.4047 26.7871 66.0194 30.2892 73.3911 34.5806C65.248 30.9929 60.7499 33.9534 57.7782 42C60.4126 34.802 58.97 29.2941 51.3911 25.6774Z" fill="#FFCC57"/>
        <path d="M51.3911 25.6774C60.0946 28.5576 62.8939 26.3613 65.5847 19C63.4047 26.7871 66.0194 30.2892 73.3911 34.5806C65.248 30.9929 60.7499 33.9534 57.7782 42C60.4126 34.802 58.97 29.2941 51.3911 25.6774Z" fill="#FFCC57"/>
      </svg>
      </div>

        <div className="grow">
      <div className="flex items-center gap-1">{e.name_fr} <span className="scale-[0.8]">{nToStars(ct.userWorkspace?.["bc_"+i+"_lvl"] || 0, 4)}</span> </div>
       {/* <span className="px-2 rounded-full text-sm bg-black/20">niv. {ct.userWorkspace?.["bc_"+i+"_lvl"] || 0} </span> */}
      {!(cent == 100 && !isMax) && <div className="flex gap-2">
      <div className={`bg-black/40 mt-2 rounded-full h-[10px] w-full`}>
        <div className="h-[10px] rounded-xl" style={{width: cent+"%", backgroundColor: e.color}}>{}</div>
      </div>
      {isMax ? <div className="text-xs mt-1 text-white/50" >{mc}</div> : <div className="text-xs mt-1 text-white/50">{mc}/{totalCards}</div>}
      </div>}
      {/* <div>{planted_seed} déjà planté</div> */}
      {cent == 100 && !isMax ? <div className="flex mt-2 justify-end"><div className="px-4 grow py-1 bg-[#6144C8] inline-block border-black/90 rounded-xl border-2 border-b-4 flex items-center justify-between gap-4 " onClick={() => {
        ct.setUserWorkspace(uc => ({ ...uc, ["bc_" + i + "_lvl"]: (uc["bc_" + i + "_lvl"] || 0) +1 }))
        const docRef = doc(db, 'user_workspace', ct.userWorkspace.id);
        updateDoc(docRef, {["bc_" + i + "_lvl"]: (ct.userWorkspace["bc_" + i + "_lvl"] || 0) +1})

        }}>
        Débloquer l'évolution
        <svg className="h-6" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6967 9.73285C16.1664 4.71999 21.4728 1.81961 26.4857 3.28928C31.3376 4.71178 34.2106 9.72871 33.0574 14.5948L30.1727 13.7491C30.8668 10.4671 28.912 7.12689 25.6417 6.16811C22.21 5.162 18.5816 7.14517 17.5755 10.5769L16.5908 13.9355L13.712 13.0915L14.6967 9.73285Z" fill="#424242" stroke="black"/>
<path d="M27.0289 44.0897L3.9983 37.3376C2.15214 36.7963 1.08309 34.8404 1.62435 32.9942L6.68844 15.7213C7.22969 13.8751 9.1856 12.8061 11.0317 13.3473L34.0624 20.0995C35.9085 20.6407 36.9776 22.5966 36.4363 24.4428L31.3722 41.7157C30.831 43.5619 28.8751 44.6309 27.0289 44.0897Z" fill="#FB8C00" stroke="black"/>
<path d="M18.1869 31.5978C19.7768 32.0639 21.4436 31.1529 21.9097 29.5629C22.3759 27.973 21.4649 26.3062 19.8749 25.8401C18.285 25.374 16.6182 26.285 16.1521 27.8749C15.6859 29.4649 16.597 31.1316 18.1869 31.5978Z" fill="#C76E00"/>
<path d="M21.4299 29.4223C21.0415 30.7472 19.6525 31.5064 18.3276 31.118C17.0026 30.7295 16.2434 29.3405 16.6319 28.0156C17.0203 26.6906 18.4093 25.9315 19.7343 26.3199C21.0592 26.7084 21.8184 28.0973 21.4299 29.4223Z" stroke="black" stroke-opacity="0.32"/>
</svg>


        </div> </div>: ""}
   
      </div>
      </div>})}</div>
  </div>
  </div>}

const ForestScreen = (props) => {
    let url = window.location.href
    const searchParams = new URLSearchParams(url);
    const navigate = useNavigate()
    const visited_uwp = searchParams.get("uwp")
    let initialDistance;
    const setUserDecoration = props.setUserDecoration
    const userDecoration = props.userDecoration

    // Faire userDecorationAll ici et userDecoration en fonction du centre ou l'on se trouve 
    // Ajouter canvas dans un canvas pour des déplacement horizontal
    // Ajouter canvas ground et way dans une big image pour eviter les calculs inutiles pour le refresh 

    // Refresh ultime : tout mettre dans un canvas static et redraw uniquement les 9 cases autour du personnage 


    const deckData = props.deckData

    



 
    
  
    const ct = props.context;

    const userCardFast = deckData?.fastCards

    const [images, setImages] = useState([])

   
    const canvasRef = useRef(null);
    const visitedUWP = ct.visitedUWP
    const [showAlchimie, setShowAlchimie] = useState();
    const [key, setKey] = useState(0);
    const [showXP, setShowXP] = useState(false);
    const [packCards, setpackCards] = useState()
    const [_userCards, set_userCards] = useState()
    const [showSeed, setShowSeed] = useState()



    const lvls = [
      { exp: 10 },
      { exp: 30 },
      { exp: 80 },
      { exp: 140 },
      { exp: 280 }
    ];

    const lvlHouses = [
      { exp: 10 },
      { exp: 40 },
      { exp: 100 },
      { exp: 200 },
      { exp: 500 },
      { exp: 900 },
      { exp: 1200},
      { exp: 1800},
      { exp: 3000},
    ];

    const expDojo = getStarsFromTraining(ct.userTests)
    let currentLvlDojo = 0
    for (let i = 0; i < lvls.length; i++) { if (expDojo >= lvls[i].exp) { currentLvlDojo = i + 1; } else { break;}}
    let currentLvlHouse = 0
    const expHouse = _userCards?.length
    for (let i = 0; i < lvlHouses.length; i++) { if (expHouse >= lvlHouses[i].exp) { currentLvlHouse = i + 1; } else { break;}}
    

    const recharger = () => {
      setKey(prevKey => prevKey + 1);
    };
    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [totalDx, setTotalDx] = [ct.totalDx, ct.setTotalDx]
    const [totalDy, setTotalDy] = [ct.totalDy, ct.setTotalDy]
    const [offX, setOffX] = useState(0);
    const [offY, setOffY] = useState(0);
    const [gridX, setGridX] = useState(0)
    const [gridY, setGridY] = useState(0)
    const [objs, setObjs] = useState([])
    const [showAnimation, setShowAnimation] = useState(true)
    const [editorMode, setEditorMode] = useState(false)
    const [loadingMAJCards, setLoadingMAJCards] = useState(false)

    const {selectedDeco, setSelectedDeco} = ct
    const [decorationMode, setDecorationMode] = useState()
    const [selectedCard, setSelectedCard] = useState()
    const [isZooming, setIsZooming]= useState(false)
    const [moveMode, setMoveMode] = useState(false)
    const loadedDecoration = ct.loadedDecoration

    const [talkMode, setTalkMode] = useState(false)


    const selectedDeck= ct.selectedDeck
    const setSelectedDeck = ct.setSelectedDeck


    const [popoverPos, setPopoverPos] = useState();
    const [hoverCard, setHoverCard] = useState()
    const [hoverContent, setHoverContent] = useState()

    const [selectedPos, setSelectedPos] = useState()
  
    const [hoverDeck, setHoverDeck] = useState()
    const [imgLoaded, setImgLoaded] = useState(false);
    const [userDecks, setUserDecks] = useState()
    const [canvasObj, setCanvasObj] = useState([])
    const [showQuest, setShowQuest] = useState()



    const [disco, setDisco] = useState(false)
    const [pinchDistance, setPinchDistance] = useState(false)
    const [pinchInitialDistance, setPinchItinialDistance] = useState(0)
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const initialTileSize = 65
    const [tileSize, setTileSize] = useState(65)
    const canvasOffset = 300
    const gridRows = 100
    const gridCols = 100

    const timeoutRef = useRef(null);

    const [nonPlacedCards, setNonPlacedCards] = useState()
    let packed_seeds = userDecoration?.filter(ed => ed.type == "pc").sort((a,b) => b.bc_id || -99 - a.bc_id || -99)
    // const nonPlacedCards = _userCards?.filter(uc => !(uc.x || uc.x ==0) && !(uc.y || uc.y ==0) && !(uc.ud_id)).filter(c => c)?.length
    const maxPack =  (nonPlacedCards - packed_seeds?.length*4)/4 

  let extLvl = visitedUWP ? (visitedUWP?.extLvl || 0) : ct.userWorkspace?.extLvl||0 

  let nb_biome = 1
  if (extLvl == 1) nb_biome = 5
  if (extLvl == 2) nb_biome = 13
  if (extLvl == 3) nb_biome = 20
  if (extLvl == 4) nb_biome = 28

  const priceExtension = [
    {gold: 200, packed: 16, seed: 64, elixir: 30}, 
    {gold: 500, packed: 40, seed: 180, elixir: 300},
    {gold: 1000, packed: 80, seed: 400, elixir: 1000},
    {gold: 3000, packed: 160, seed: 800, elixir: 5000},
    {gold: 5000, packed: 160, seed: 800, elixir: 7000},
    {gold: 7000, packed: 200, seed: 10000, elixir: 10000},
    {gold: 10000, packed: 300, seed: 12000, elixir: 12000},
    {gold: 20000, packed: 600, seed: 22000, elixir: 22000},
  ]


  useEffect(() =>{
    setTalkMode(visitedUWP)
  }, [visitedUWP])



  // côté jeu avec notre personnage
 


  // niveau des étoiles qui évolue automatiquement


  // niveau du HDV qui permet de débloquer de nouvelle décoration

  let b = [...layer0]
  
  if (nb_biome == 5)  b = [...layer0,...layer1]
  if (nb_biome == 13) b = [...layer0,...layer1_bis,...layer2]
  if (nb_biome == 20) b = [...layer3]
  if (nb_biome == 28) b = [...layer4]

  // 20 packs (80) pour passer au niveau sup
  // 60 packs (240) pour passer au niveau sup
  // 120 packs (480) pour passer au niveau sup

  const [guests, setGuests] = useState([])
  const [msgs, setMsgs] = useState([])


  useEffect(() => {

        if (ct.showCards?.length > 1) return
        const _guest = []
        const usersRef = collection(db, 'users')

   

        // Requête pour obtenir les utilisateurs avec visiting_uwp = "test" et last_move > fiveMinutesAgo
        const currentUwp = visited_uwp || ct.userWorkspace?.id
        console.log('currentUwp', currentUwp)

        const currentTime = Timestamp.now();

        // Calculez le timestamp pour il y a 5 minutes
        const fiveMinutesAgo = new Date(currentTime.toDate().getTime() - 5 * 60 * 1000);
        
        // Convertissez-le en Timestamp Firestore
        const fiveMinutesAgoTimestamp = Timestamp.fromDate(fiveMinutesAgo);
        
        // Créez la requête pour obtenir les utilisateurs avec 'visit_last' dans les 5 dernières minutes
        const testUsersQuery = query(
            usersRef,
            where('visiting_uwp', "==", currentUwp),
            where('visit_last', '>=', fiveMinutesAgoTimestamp)
        );
        // mettre l uwd de la forêt visible et supprimer l user lui meme



        const unsubscribe = onSnapshot(testUsersQuery, (snapshot) => {
          snapshot.forEach((doc) => {
              const userData = doc.data();

              // console.log(`User ${doc.id} moved to x: ${userData.x}, y: ${userData.y}`);
              const foundGuest = guests.find(g => g.user_id == doc.id) || _guest.find(g => g.user_id == doc.id)
              const _guest_updated = {
                user_id: doc.id, 
                name: userData.name, 
                x: userData.x, y: userData.y, _x: foundGuest?.x, _y: foundGuest?.y, 
                last_move: userData.last_move, 
                chat: userData.chat, 
                rmvc: userData?.rmvc?.toDate(),
                avtc: userData?.avtc

              }

              setMsgs(prevMsgs => {
                // Créer une copie du tableau des messages actuels
                const updatedMsgs = [...prevMsgs || []];
          
                // Vérifier si le message est déjà présent dans l'historique
                const isDuplicate = updatedMsgs.some(
                  msg => msg.chat === userData.chat && msg.date.getTime() === _guest_updated.rmvc.getTime()
                );
          
                if (!isDuplicate && userData.chat) {
                  // Ajouter le nouveau message avec rmvc comme date
                  const newMessage = {
                    chat: userData.chat,
                    name: userData.name,
                    date: _guest_updated.rmvc,
                  };
                  
                  // Ajouter le nouveau message à l'historique
                  return [...updatedMsgs, newMessage];
                }
          
                // Si le message est un duplicata, retourner les messages existants
                return prevMsgs;
              });


              console.log('msgs', msgs)



              // console.log('_guest_updated', _guest_updated)
              // console.log('guest', guests)
              // console.log('_guest', _guest)
            

              if (foundGuest) {
                // console.log('updated')
               
                setGuests(prevGuests => prevGuests.map(g => 
                  g.user_id === doc.id ? _guest_updated : g
              ));
                // faire un system from et to pour les animations 
              } else {
                // console.log('new!!')
                _guest.push(_guest_updated)
                setGuests(g => [...g, _guest_updated])
              }
             
          });
      });

        // Nettoyage pour éviter les fuites de mémoire
        return () => unsubscribe();
    }, [ct.showCards]);



   

  





  const resetForest = async () => {
    // lance la fonction init

    const plantedSeed = ct.userCards?.filter(uc => (uc.x || uc.y) || uc.x == 0 )
    const plantedTree = ct.userDecks?.filter(uc => (uc.x || uc.y) || uc.x == 0 )
    plantedSeed?.forEach(uc =>  ct.fire.updateUserCard({...uc, x: null, y: null}, ct))
    plantedTree?.forEach(uc =>  ct.fire.updateUserDeck({...uc, x: null, y: null}, ct))

    const decorations = objs?.filter(o => o.image?.deco)
    console.log('objs', objs)
    console.log('decorations', decorations)

    let totalMoney = decorations?.reduce((prev, o) => prev + o.image?.price, 0)    
    totalMoney =  totalMoney - 12
    totalMoney = totalMoney < 0 ? 0 : totalMoney
    
    decorations?.forEach(o => o.id && ct.fire.remove(o.id, "user_workspace_element"))
    ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: (ct.userWorkspace.coins || 0) +  totalMoney || 0}, ct)
    setObjs()
    alert('test 2')
    setUserDecoration(userDecoration?.filter(e => !decorations?.map(e => e.id).includes(e.id)))
    initForestObj(ct.userWorkspace?.id)
 
  }

  const hasHouse = objs?.find(o => o.image?.type == "house")
  // console.log("hasHouse", hasHouse)
  
  const initForestObj = async (uwp_id) => {
  
    let reload = false
    // creer deux chemins et la maison au centre
    const hasHouse = objs?.find(o => o.image?.type == "house")


  
    
    if (!hasHouse) {

      const house = images?.find(img => img.name == "house_0")
      const houseObjData = {x: 4, y: 4, name: house.name, uwp: ct.userWorkspace?.id}
      reload = true
      await createUserWorkspaceElement(houseObjData)
    }


    if (ct.workspace?.id == "anglais-gb") {
      
      const hasTemple = objs?.find(o => o.image?.type == "dojo")
      if (!hasTemple) {

        const dojo = images?.find(img => img.name == "temple_0")
        const dojoObjData = {x: 1, y: 1, name: dojo.name, uwp: ct.userWorkspace?.id}

        // await createUserWorkspaceElement(dojoObjData) !!creation dojo
      } else {
        console.log('temple déjà présent')
      }
      
    }

    if (reload) {
      // getUserWorkspaceElements(uwp_id)
    }

   

    // const way = images.find(img => img.name == "way")
    // const yValues = [5, 6, 7, 8]; // Array of y values

    // yValues.forEach(async y => {
    //     const wayObjData = { x: 4, y, name: way.name, uwp: ct.userWorkspace?.id };
    //     const wayObjPos = setPosObj([wayObjData], images, null, "deco")[0];
    //     if (!decorations.find(d => d.x == 4 && d.y == y) ) {

    //       await addDoc(collection(db, "user_workspace_element"), wayObjData);
    //       new_objs.push(wayObjPos);
    //     } else {}
      
    // });
   
    //ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: newCoins}, ct)
    //setObjs([...new_objs])
    
  
  }
  


    useEffect(() => {
      if (!ct.user?.tt_for && ct.cardsToPlace?.length) {
        ct.fire.updateUser({...ct.user, tt_for: true}, ct)
        if(!ct.user?.tt_for) ct.setShowTutoBook("all")
      }

     

    }, [ct.setCardsToPlace, key])
  

    const updateUserCard = async (updated) => {
      console.log('updateUserCard 🟣', updated.id)
      ct.setUserCards(ct.userCards.map(uc => uc.id == updated.id ? {...uc, x: updated.x, y: updated.y} : uc))
      const docRef = await updateDoc(doc(db, "user_cards", updated.id), updated);
    
    }

    const createUserWorkspaceElement = async (element, userCards) => {
      console.log('new  createUserWorkspaceElement 🟢', element)
      console.log('move mode', moveMode)
      const id = (typeof moveMode === 'string' && moveMode.length > 5) ? moveMode : null;
      const newElement = { ...element };
      let docRef = null

      if (id) {
        console.log('deplacement en conservant id 🟣🟣🟣', id)
         docRef = await setDoc(doc(db, "user_workspace_element", id), newElement);
         docRef = {id}
         console.log('docRef', docRef)
      } else {
         docRef = await addDoc(collection(db, "user_workspace_element"), newElement);
      }
     
      setUserDecoration(prev => [...prev, {...element, id: docRef.id}])
      setSelectedEvo()

      if (userCards) {
        console.log('modification des user_card 🟣🟣🟣')
        
        userCards.forEach(ucA => {
          ct.setUserCards(ucB => ucB.map(uc => uc.id == ucA.id ? {...uc, ud_id: docRef.id} : uc))
          updateDoc(doc(db, "user_cards", ucA.id), {ud_id: docRef.id});
        })
        
      }
    }

   
    
    function sortArrayByXYSum(arr) {
      
      return arr?.filter(a => a && (a.x || a.x == 0)).sort((a, b) => {
       
        const sumA = a.x + a.y;
        const sumB = b.x + b.y;
        if (a.z === b.z) {
          return sumA - sumB;
        } else {
          return a.z -b.z;
        }
       
      });
    }


    
    
    
    const draw = (objs, dx = offX, dy = offY, i = 0, i2 = 0, extLvl) => {

      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      if (canvas) canvas.style.backgroundPosition = `${dx}px ${dy}px`;


      ctx?.clearRect(0, 0, canvas.width, canvas.height);

      objs?.forEach((el, index) => {fillObj(ctx, el,  {i, i2}) })


      ctx && (moveMode ||  selectedDeco) && drawGrid(ctx, {dx, dy});
      // drawGrid(ctx, {dx, dy});

     
      };


   
    
    const handleMouseDown = (e) => {
      setPopoverPos()
      setDragging(true);

     

      timeoutRef.current = setTimeout(() => longPress(e.clientX, e.clientY), 900);
      
     
      setStartX(e.clientX);
      
      setStartY(e.clientY);



 
      
    };



    const positionDeck = ({_gridX, _gridY, selectedDeck}) => {
      let deck 
      const pos = {x: _gridX, y: _gridY, id: selectedDeck.id}
      const newDeck = setPosObj([{...selectedDeck, x: pos.x, y: pos.y}], images, null, "deck")[0]
      const new_objs = objs
      const deckExist = selectedDeck.x 
    
      if (deckExist) {
      
       
        setObjs( new_objs.map(ob => ob.id == newDeck.id ? newDeck : ob))
      } else {

        new_objs.push(newDeck)
        setObjs(new_objs)
        deck = {...selectedDeck, x: pos.x, y: pos.y}
      }
      const new_user_deck = {...selectedDeck, x: pos.x, y: pos.y}
      ct.fire.updateUserDeck({id: selectedDeck.id, x: pos.x, y: pos.y})
    
      ct.setUserDecks(ct.userDecks.map(d => d.id === selectedDeck.id ? new_user_deck : d))
    
     
      setSelectedDeck(null)
      

    }   
    // console.log('packed_seeds', packed_seeds)


    const handleClick = (clientX, clientY) => {
    
      
      const diffX = (clientX - startX)
      const diffY = (clientY - startY)
      const diff = Math.abs(diffX) + Math.abs(diffY)
    
      var {top, left} = canvas.getBoundingClientRect();
 
      
      var mouseX = (clientX - offX) - left;
      var mouseY = (clientY - offY) - top;
      var [_gridX, _gridY] = isoToCar(mouseX, mouseY)
    
      const [_left, _top] = carToIso(_gridX, _gridY);
      let card = _userCards?.find(e => e.x == _gridX && e.y == _gridY)
      let deck = userDecks?.find(e => e.x == _gridX && e.y == _gridY)

      const outGrid = isOutGrid(_gridX, _gridY)
      if (outGrid) {
        ct.setAlerts([{title: "Cette zone n'est pas disponible"}])
        return 
      }
    
      // si je suis pas sur l'ile 
      console.log('pos', `${_gridX}, ${_gridY}`)
      console.log('getMousePosition',)

      // exectuteMove(_gridX, _gridY, ct.user.id)
      const userDecorationWithElement = userDecoration?.map(ud => ({...ud, element: images?.find(i => i.name == ud.name)}))
      .map(e => ({...e, spreadPos: e.element?.spread?.map(arr => ({x: e.x - arr[0], y: e.y - arr[1]}))}))
     

      const element = images?.find(e => selectedDeco?.name == e.name)
      const spreadsPos = element?.spread?.map(arr => ({x: _gridX - arr[0], y: _gridY - arr[1]}))
      const positionBlock = userDecorationWithElement?.filter(e => (e.x == _gridX && e.y == _gridY))
      let  baseGround = userDecorationWithElement?.find(e => (e.x == _gridX && e.y == _gridY) && e.element?.ground)
      let base = bases.find(e => (e.x == _gridX && e.y == _gridY))
      let ground = base  ||  baseGround
      let waterGround = userDecorationWithElement?.find(e => (e.x == _gridX && e.y == _gridY) && e.element?.void)
      let deco = userDecorationWithElement?.sort((a,b) => (b.z||1) - (a.z||1)).find(e =>                        ((e.x == _gridX && e.y == _gridY) || (e.spreadPos?.find(e => e.x == _gridX && e.y == _gridY)) || spreadsPos?.find(spread => spread.x == e.x && spread.y == e.y)) && !e.avatar_id && e.element && !e.element.ground ) || baseGround
      let decoEvo = userDecorationWithElement?.find(e => e.type == "evo" && ((e.x == _gridX && e.y == _gridY) || (e.spreadPos?.find(e => e.x == _gridX && e.y == _gridY)) || spreadsPos?.find(spread => spread.x == e.x && spread.y == e.y)))
      let decoPC = userDecorationWithElement?.find(e => e.type == "pc" && (e.x == _gridX && e.y == _gridY) || spreadsPos?.find(spread => spread.x == e.x && spread.y == e.y))
      let pc = packed_seeds.find(e => (e.x == _gridX && e.y == _gridY))
      // const isWater = !isPointInsideBiomes(_gridX, _gridY, b.slice(0, nb_biome)) && !deco
      const isWater = !ground || waterGround


      const targetDeco = decoEvo|| decoPC || deco 



      console.log('----')
      console.log('positionBlock', positionBlock)
      console.log('deco', deco)
      console.log('waterGround', waterGround)
      console.log('ground', ground)
      console.log('isWater', isWater)
      console.log('targetDeco', targetDeco)
      console.log('max', maxPack)
      console.log('----')

     
     
      // if (visitedUWP || guests.length) { 
      if (!selectedDeco && !moveMode && !editorMode) {
      
        if ((!deco || deco.element?.ground || deco.name == "way") && !pc) {
          console.log('pc', pc)

          !isWater && updateDoc(doc(db, "users", ct.user.id), {visiting_uwp: (visited_uwp || ct.userWorkspace?.id), visit_last: new Date(), x: _gridX, y: _gridY}) 
          console.log('changement de posittions')
        }
      }
      // } 

     

      if (false) {

       
      } else {
       

        setGridX(_gridX);
        setGridY(_gridY);

            // ajout du carré jaune
          // ajout du carré jaune
          const afkDate = new Date();
          afkDate.setSeconds(afkDate.getSeconds() + 3);

          const yellowDeco = {x: _gridX, y: _gridY, c: 0, date: afkDate, interface: true, name: "target_yellow", uwp: ct.userWorkspace?.id}
          const yellowObj = setPosObj([yellowDeco], images, null, "deco")[0]
          
          console.log('yellowObj', yellowObj)
          console.log('deco', deco)
          setSelectedPos({x: _gridX, y: _gridY, date: afkDate, name: deco ? "target_yellow" : "target_white"})
          setObjs(prev => [...prev.filter(e => e.name != "target_yellow"), yellowObj])
          // setUserDecoration(prev => [...prev, yellowDeco])

          // // fais en sorte que userDecoration supprime yellowDeco avec interface true dans 200 ms et meme chose pour setObjs
          // setTimeout(() => {
          //   // Supprimer l'objet de la liste des objets
          //   setObjs(prev => prev.filter(obj => obj !== yellowObj));
            
          //   // Supprimer yellowDeco de userDecoration (si nécessaire)
          //   setUserDecoration(prev => prev.filter(deco => deco !== yellowDeco));
          // }, 500);

          

        
       
        if (selectedDeco) {
         
          let rock2 = {x: _gridX, y: _gridY, c: selectedDeco?.price||0, name: selectedDeco.name || null, uwp: ct.userWorkspace?.id, type: selectedDeco.type || null}
          if (selectedDeco.bc_id) rock2.bc_id = selectedDeco.bc_id
          if (selectedDeco.cd) rock2.bc_id = selectedDeco.cd
          let rock2_pos = setPosObj([rock2], images, null, "deco")[0]
          console.log('selectedDeco', selectedDeco)
          
          const new_objs = objs

          const selectedObj =  ([targetDeco] || objs.filter(o => (o.x == _gridX && o.y == _gridY) && o.type == "deco" || userDecorationWithElement?.find(usd => o.id == usd.id)?.spreadPos?.find(e => e.x == _gridX && e.y == _gridY))).filter(e => e?.id)

      

          if (selectedDeco.void) {

            if (!targetDeco) {
              if (base) {
              //  ct.setAlerts([{title: "ajouter eau sur la base"}])
                const waterDeco = {x: _gridX, y: _gridY, name: "water", uwp: ct.userWorkspace?.id, type: "ground"}
                let waterDecoObj = setPosObj([waterDeco], images, null, "deco")[0]
                new_objs.push(waterDecoObj)
                console.log('createUserWorkspaceElement 1')
                createUserWorkspaceElement(waterDeco, selectedEvo?.cards)

                setObjs([...new_objs])


              } 
              
            } else {
              // ct.setAlerts([{title: "supprimer"}])
              // const new_objs = objs.filter(o => (o.x != _gridX || o.y != _gridY) )
              // setObjs([...new_objs])
              console.log('selectedObj', selectedObj)
              console.log('selectedObj.type ', selectedObj.filter(o => o?.id && o.type == "ground") )
      
                
                selectedObj.filter(o => o?.id && o.type == "ground" && o.name != "water").forEach(o => o.id && ct.fire.remove(o.id, "user_workspace_element"))
                const ids = selectedObj.filter(o => o.type == "ground" && o.name != "water").map(o => o.id)
                console.log('ids deleted', ids)
             
                ids && setUserDecoration(userDecoration?.filter(o => !ids.includes(o.id)) || [])
         
             

            }
           
            return
          }
     

          
          if (selectedDeco.empty == "empty" || selectedDeco.name == "move") {

            if (waterGround) { return}
            
         
            if (targetDeco && (targetDeco.name == "temple_0" || targetDeco.name == "biome_0")) return
 

            const new_objs = objs.filter(o => (o.x != _gridX || o.y != _gridY) )
            const newCoins = (ct.userWorkspace?.coins || 0) + (selectedObj[0]?.element?.price || 0)


            console.log('targetDeco', targetDeco)
            console.log('isWater', isWater)
            console.log('selectedDeco', selectedDeco)

          

            if (!targetDeco && !isWater && selectedDeco != "move" && !selectedDeco.void  && !selectedCard && !selectedDeck) {
              // ct.setAlerts([{title: "Base selectionnée"}])
              
              setSelectedDeco(images.find(i => i.name == "base"))


              const waterDeco = {x: _gridX, y: _gridY, name: "water", uwp: ct.userWorkspace?.id, type: "ground"}
              let waterDecoObj = setPosObj([waterDeco], images, null, "deco")[0]
              new_objs.push(waterDecoObj)
              console.log('createUserWorkspaceElement 2')
              createUserWorkspaceElement(waterDeco, selectedEvo?.cards)

              setObjs([...new_objs])

              // ajouter une case vide 
              

            }

            // if (targetDeco && selectedDeco.void) {
            //   ct.setAlerts([{title: "suprimer non base"}])
            //   setObjs([...new_objs])
            //   return 
            // }



            if (card) {
              const pos = {x: null, y: null, id: card.id}
              updateUserCard(pos)
            }

            if (deck) {
              const new_user_deck = {...deck, x:null, y: null}
              ct.fire.updateUserDeck({id: deck.id, x:null, y: null})
              ct.setUserDecks(ct.userDecks.map(d => d.id === deck.id ? new_user_deck : d))
            }

            if (selectedDeco.name == "empty") {
              

              ct.userCards?.forEach(uc => uc.ud_id === selectedObj[0]?.id &&  ct.fire.updateUserCard({ ...uc, ud_id: null }, ct));
              ct.setUserCards(uc => uc.map(uc_2 => uc_2.ud_id == selectedObj[0]?.id ? {...uc_2, ud_id: null} : uc_2));
              console.log('suppression de', ct.userCards?.filter(uc_2 => uc_2.ud_id !== selectedObj[0]?.id))
              
            
            }

        
            if (moveMode) setMoveMode(targetDeco?.id || true) 
            ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: newCoins}, ct)

            console.log('selectedObj', selectedObj)
            selectedObj.filter(o => o?.id).forEach(o => o.id && ct.fire.remove(o.id, "user_workspace_element"))
          
            setObjs(new_objs)
           
            setUserDecoration(ud =>(ud.filter(e => !selectedObj.map(e => e.id).includes(e.id))))

           

            if (selectedDeco.name == "move") {
              if (waterGround) {
                // setSelectedDeco(waterGround)
              }

              if (isWater) {
                ct.setAlerts([{title: "Tu ne peux pas déplacer un souvenir dans l'eau"}])
                return
              }
              if (deck) {
                console.log('deck', deck)
                //positionDeck({_gridX, _gridY, deck})
                const elDeck = ct.userDecks?.find(d => d.id == deck.id)
                console.log('elDeck', elDeck)
                setSelectedDeck(elDeck)
                setSelectedDeco()
                setDecorationMode()
                return
              }


            
            
             
              

              if (targetDeco?.type == "evo"){
                console.log('deplacement evo', targetDeco)
               
                setSelectedDeco(targetDeco)

              }

             
           
              if (targetDeco?.element) {
                
                setSelectedDeco(targetDeco.element)
              } 
              if (targetDeco?.type == "pc") {
                setSelectedDeco({type: "pc", uwp: ct.userWorkspace?.id, n: targetDeco.n})
              }
              
            }
           

          
          } else { // si c'est pas move
            console.log('selectedDeco', selectedDeco)
            if (isWater && !selectedDeco.onWater && !selectedDeco.void ) {
              ct.setAlerts([{title: "Vous ne pouvez pas placer de souvenir dans l'eau de l'oubli "}])
              return
    
            }
          
           
            const newCoins = ct.userWorkspace?.coins - (selectedDeco.price||0)

            if (selectedDeco.type == "pc") {
              if (card || (deco && !deco?.element?.seed) || decoEvo || decoPC || deck) {

                console.log('deco', deco)
                console.log('decoPC', decoPC)
                ct.setAlerts([{title: "Il y a déjà un souvenir présent ici !"}])
                return
              }
            
              const seedPacked = userDecoration.filter(ud => ud.type == "pc")
              
              let rock2 = {...selectedDeco, n: missingN(seedPacked)[0], x: _gridX, y: _gridY, name: selectedDeco?.name || null, uwp: ct.userWorkspace?.id}
              console.log('selectedDeco pc', selectedDeco)
              let rock2_pos = setPosObj([rock2], images, null, "deco")[0]
             
              // setUserDecoration([...userDecoration, rock2])
              new_objs.push(rock2_pos)
             
              console.log('createUserWorkspaceElement 3')
              createUserWorkspaceElement(rock2, selectedEvo?.cards)
              ct.fire.updateUserWorkspace({...ct.userWorkspace, pc: seedPacked.length || 0}, ct)
            
              setObjs([...new_objs])

             
            
              if (maxPack <= 1) {
                console.log('HEY')
                setSelectedDeco()
                setDecorationMode()
              }

              if ((element?.unique || selectedDeco?.bc_id != null)){
                setSelectedDeco()
                setDecorationMode()
              }

              if (moveMode) {
                setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })
              }

              
              return
           
             
               
            }

            console.log('colision', decoPC|| deco || card || deck )
            console.log('deco', deco)
            console.log('selected', selectedDeco)

            const decoNoSeed = deco?.type != "pc" && deco?.type != "evo"? deco : null
            const collisionSeed =  selectedDeco.seed ? null : (decoPC || decoEvo)
            console.log('decoNoSeed', decoNoSeed)

            const canBeOnSeed = deco?.element?.seed

   

          

            if ( (decoNoSeed && !canBeOnSeed && !ground ) || card || deck) {
              console.log('colision', decoPC|| deco || card || deck)
              console.log('decoNoSeed', decoNoSeed)
              ct.setAlerts([{title: "L'emplacement est déjà utilisé " }])
              return
            }

            if (selectedDeco?.name == "water" && (decoPC || decoEvo || positionBlock.find(e => !e.element?.ground))){
              console.log('positionBlock.find(e => !e.ground)', positionBlock.find(e => !e.ground))
              ct.setAlerts([{title: "L'emplacement est déjà utilisé " }])
              return 
            }


            console.log('isWater', isWater ? "ui" : "no")
            console.log('not water', !isWater)
            if (selectedDeco.onlyWater && !isWater){
              ct.setAlerts([{title: "Cet élément doit être placé dans l'eau"}])
              return 
            }

            console.log('selectedDeco', selectedDeco)

            if (collisionSeed)  {
              console.log('decoPC', decoPC )
              ct.setAlerts([{title: "L'emplacement est déjà utilisé par des plantes" }])
              return
            }

           
   
            if (newCoins > 0 ){


              // console.log('userDecoration',userDecoration.filter(e => e.type == "ground"))
              // console.log('userDecorationWithElement',userDecorationWithElement)
              // userDecorationWithElement.filter(e => e.element?.ground).forEach(e => {
              //   console.log('e', e)
              //   ct.fire.remove(e?.id, "user_workspace_element")

              // })
              // setUserDecoration(prev=> prev?.filter(e => e.name !="base") || [])

              if (waterGround && rock2.name != "water") {
                ct.setAlerts([{title: "Il y a déjà de l'eau" }])
                console.log('waterGround', waterGround)
                ct.fire.remove(waterGround.id, "user_workspace_element")
                console.log('find deco',  userDecoration.filter(o => o.id == waterGround.id))
                setUserDecoration(prev=> prev?.filter(o => o.id != waterGround.id) || [])
                // console.log('find obj',  objs.filter(o => o.id == waterGround.id))
                // const new_objs = objs.filter(o => (o.id != waterGround.id))
                // setObjs(new_objs)
                if (moveMode) {
                  setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })
                  setDecorationMode()
                }
                return
              }

              if (positionBlock.find(o => o.name == rock2.name)){
                ct.setAlerts([{title: "Il y a déjà un élément de ce type présent ici" }])
                return
              }

              if (baseGround && rock2.type == "ground"){
                ct.setAlerts([{title: "Il y a déjà un baseGround" }])
                console.log('baseGround',baseGround)
                ct.fire.remove(baseGround.id, "user_workspace_element")
                console.log('ud', userDecoration.filter(o => o.id == baseGround.id))
                setUserDecoration(userDecoration?.filter(o => o.id != baseGround.id) || [])
                
                if (moveMode) {
                  setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })
                  setDecorationMode()
                }
                
              }

              if (base && rock2.name == "base") {
                ct.setAlerts([{title: "Il y a déjà une base" }])
                console.log('deco', deco)
                ct.fire.remove(deco.id, "user_workspace_element")
                setUserDecoration(prev=> prev?.filter(o => o.id != deco.id) || [])

                if (moveMode) {
                  setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })
                  setDecorationMode()
                } 
                
                return
              }
             

              if (rock2.type == "ground" && !base) {
              
                if (usedBase > -1) {
                  ct.setAlerts([{title: "Tu as utilisé tous les blocs disponibles, agrandis ton île pour en avoir plus" }])
                  setSelectedDeco()
                  setDecorationMode()
                  return
                }
               
              }
              new_objs.push(rock2_pos)
              console.log('newCoins > 0', selectedEvo)
              
              console.log('createUserWorkspaceElement 4')
              createUserWorkspaceElement(rock2, selectedEvo?.cards)

              if (selectedEvo) {
                deleteUnusedPack(packed_seeds, _userCards, ct, setUserDecoration)
              }


              /// pour controller le nombre de base posé
             


              

             


              ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: newCoins}, ct)
              setObjs([...new_objs])
              if (moveMode) {
                setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })
                setDecorationMode()
              }
             
            } else {

              ct.setAlerts([{title: "Tu n'as pas assez d'argent pour acheter cet objet", content: "Continue d'apprendre pour gagner de l'argent !", time: 3000}])
              
            }

          
            // spread
            console.log('element !! ', element)
            console.log('selectedDeco !! ', element)
            if ((element?.unique || selectedDeco?.bc_id != null ) && !moveMode ){
              setSelectedDeco()
              setDecorationMode()
            }
            return
            
   
        
            
          }
         
        }

     

        if (isWater) {
          console.log(`x: ${gridX}, y: ${gridY}`)
          ct.setAlerts([{title: "Vous ne pouvez pas placer de souvenir dans l'eau de l'oubli"}])
          return

        }

        if (selectedCard) {
          console.log('decoPC2', decoPC)
           if (card || (deco && !deco?.element?.seed) || decoPC || deck) {
            console.log('deco', deco)
            console.log('decoPC', decoPC)
            ct.setAlerts([{title: "Il y a déjà un souvenir présent ici !"}])
            return
          }
          const pos = {x: _gridX, y: _gridY, id: selectedCard.id}
          const card2_pos = setPosObj([{...selectedCard, x: pos.x, y: pos.y}], images, null, "seed")[0]
          const new_objs = objs
          new_objs.push(card2_pos)
          updateUserCard(pos)
          setObjs(new_objs)
          !editorMode && setSelectedCard(null)
          // cardsToPlaceHandle()
         
   
       
       
        } 

        if (selectedDeck) { 
          if (card || deco || deck) {
            ct.setAlerts([{title: "Il y a déjà un souvenir présent ici"}])
            return
          }
          positionDeck({_gridX ,_gridY, selectedDeck})
          if (moveMode) setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png",                })

          
        

         
        }
        setEditorMode(false)
    
       
        setHoverCard(card)
        console.log('setHoverCard', card)
        setHoverDeck(deck)

       



        if (pc){




          let packedCards = _userCards.filter(o => o.pack_index == pc.n).map(o => ({...o.card, user_card: {...o, next_date: 
            o.next_date instanceof Date ?  o.next_date : o.next_date.toDate()
          } }))
        
         
          const nextCard = getDeckData(ct, packedCards)?.nextCards

          console.log('nextCards',nextCard)
          console.log('packedCards',packedCards)
          
          setpackCards(nextCard)

          ct.setSelectedPack(packedCards)
          !moveMode && setPopoverPos({left: _left + left - 120 + offX, cursor:  _left + left - 50 + offX, top: _top + top + 50 + offY})

        
        } else {
          setpackCards()
          ct.setSelectedPack()
        }

        if (decoEvo) {
          console.log('decoEvo', decoEvo)
          const packedCards = ct.userCards.filter(o => o.ud_id == decoEvo.id).map(uc => ({...uc, card: uc.content})).map(o => ({...o.card, user_card: {...o, next_date: 
            o.next_date instanceof Date ?  o.next_date : o.next_date.toDate()
            
          } })).filter(c => c.term)


          const nextCard = getDeckData(ct, packedCards)?.nextCards
          console.log('packedCards',packedCards)
          console.log('nextCards evo',nextCard)
          setpackCards(nextCard)

          !moveMode && setPopoverPos({left: _left + left - 120 + offX, cursor:  _left + left - 50 + offX, top: _top + top + 50 + offY})
          ct.setSelectedPack(packedCards)
      
        }
        
     


      
        if (card && card.next_date < new Date() ) {
          
          !visitedUWP && ct.setShowCards([{...card.card, user_card: card}])
        } else {
    
          !moveMode && setPopoverPos({left: _left + left - 120 + offX, cursor:  _left + left - 50 + offX, top: _top + top + 50 + offY})
        }

        if (deco?.element?.popUp) {
          !moveMode && setPopoverPos({left: _left + left - 120 + offX, cursor:  _left + left - 50 + offX, top: _top + top + 50 + offY})

   
         
          const hoverHouse = <div className="bg-purple-500 text-white p-4 rounded-xl border-2 border-b-4 border-purple-900">
            
            <div className="flex justify-between items-end mb-2">
              <h2 className="text-white">Maison du savoir</h2>
              <div className="text-sm">Niveau {currentLvlHouse}</div>
            </div>
            <div className="bg-black/30 relative rounded-xl h-[10px] overflow-hidden ">
              <div className="absolute left-0 rounded-xl bg-amber-300 top-0 bottom-0" style={{width: `${Math.round(expHouse*100/lvlHouses[currentLvlHouse]?.exp)}%`}}></div>
              <div className="absolute -left-1 rounded-xl bg-amber-100 top-[1px] bottom-[5px]" style={{width: `${Math.round(expHouse*100/lvlHouses[currentLvlHouse]?.exp)}%`}}></div>
            </div>
            <div className="text-sm text-right">{Math.round(expHouse||0)}/{lvlHouses[currentLvlHouse].exp}</div>

            <div className="text-xs mt-2">Apprends plus de carte pour l'améliorer et faire grandir ta forêt et débloquer des nouvelles décorations</div>
            <div className="flex gap-2">
              <div onClick={() => ct.setShowModalWords(true)} className="bg-black/30 inline-block rounded-xl border-2 border-b-4 border-black/70 px-3 text-sm py-1 mt-2">{ct.userCards.length} cartes collectées</div>
              {ct.workspace?.fq || true ? <div onClick={() => ct.setShowPop("pareto")} className="bg-black/30 inline-block rounded-xl border-2 border-b-4 border-black/70 px-3 text-sm py-1 mt-2">Voir le % de maitrise</div> : ""}
            </div>
          </div>

          const hoverTemple = <div className="bg-purple-500 text-white p-4 rounded-xl border-2 border-b-4 border-purple-900">
                      
          <div className="flex justify-between items-end mb-2">
            <h2 className="text-white">Dojo du TOEIC</h2>
            <div className="text-sm">Niveau {currentLvlDojo + 1}</div>
          </div>
          <div className="bg-black/30 relative rounded-xl h-[10px] overflow-hidden ">
            <div className="absolute left-0 rounded-xl bg-amber-300 top-0 bottom-0" style={{width: `${Math.round(expDojo*100/lvls[currentLvlDojo].exp)}%`}}></div>
            <div className="absolute -left-1 rounded-xl bg-amber-100 top-[1px] bottom-[5px]" style={{width: `${Math.round(expDojo*100/lvls[currentLvlDojo].exp)}%`}}></div>
          </div>
          <div className="text-sm text-right">{Math.round(expDojo||0)}/{lvls[currentLvlDojo].exp}</div>
          <div className="text-xs mt-2">Fais plus d'entrainement pour le TOEIC pour débloquer de nouvelles décorations</div>
          <div onClick={() => ct.setShowModalTraining(true)} className="bg-black/30 inline-block rounded-xl border-2 border-b-4 border-black/70 px-4 py-1 mt-2">S'entrainer</div>

          </div>
          setHoverContent(deco?.element?.popUp == "house" ? hoverHouse : hoverTemple)

        } else {setHoverContent()}
       
       
        
      
      }
      
    }


    const longPress = (mouseX, mouseY ) => {

      var [_gridX, _gridY] = isoToCar(mouseX, mouseY);


      const card = _userCards?.find(e => e.x == _gridX && e.y == _gridY)
      const deck = userDecks?.find(e => e.x == _gridX && e.y == _gridY)
      setSelectedCard(card)
      setSelectedDeck(deck)

      setPopoverPos()
      setEditorMode(true)
    };

    const handleMouseUp = (e) => {

  
      const totalDiff = (e.clientX - startX) + (e.clientY - startY)

      const newDx = totalDx + (e.clientX - startX) * swipeFactor
      const newDy = totalDy + (e.clientY - startY) * swipeFactor
      console.log('handleMouseUp', totalDiff)

      totalDiff == 0 &&  handleClick(e.clientX, e.clientY)
      setDragging(false);

      const maxDx = 4000*2 // 4000
      const minDx = -2500*2 // -2500
      const maxDy = 2500*2 // 2500
      const maxyDy = -2500
     
      setTotalDx(newDx > maxDx ? maxDx : newDx < minDx ? minDx : newDx) ;
      setTotalDy(newDy > maxDy ? maxDy : newDy < minDx ? minDx : newDy);
      // faire en sorte que ça soit par rapport à la position de mon personage si on est en mode personnage

      console.log('newDx', newDx)

      clearTimeout(timeoutRef.current);

     
    };
    
       

  
  

    const hoverElement = (clientX, clientY) => {
      var {top, left} = canvas?.getBoundingClientRect();
      const card = _userCards?.find(e => e.x == gridX && e.y == gridY)
      const deck = userDecks?.find(e => e.x == gridX && e.y == gridY)
      var mouseX = (clientX - offX) - left;
      var mouseY = (clientY - offY) - top;
      var [_gridX, _gridY] = isoToCar(mouseX, mouseY);
      const [_left, _top] = carToIso(gridX, gridY);

      setHoverCard(card)
      setHoverDeck(deck)
      setPopoverPos({left: _left + left - 120 + offX, top: _top + top + 50 + offY})
      
      setGridX(Math.round(_gridX*100)/100);
      setGridY(_gridY);
    }

    const handlePinchZoom = useCallback(
      (e) => {
        
        const x1 = e.touches[0].clientX;
        const y1 = e.touches[0].clientY;
        const x2 = e.touches[1].clientX;
        const y2 = e.touches[1].clientY;
        setIsZooming(true)
      
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const roundedDistance = Math.round(distance / 5) * 5;
      
        setPinchDistance(roundedDistance)
        if (pinchInitialDistance == null) {
          setPinchItinialDistance(roundedDistance)
          return;
        }
      
  
        // Détecter le zoom
        let newTileSize = tileSize
        if (roundedDistance > pinchInitialDistance) {
          
          if (tileSize < 100) {
            newTileSize = tileSize + 4
            setTileSize(tileSize + 4)
          }
      
        } else {
    
          if (tileSize > 10) {
            newTileSize = tileSize - 4
            setTileSize(tileSize - 4)
          }
        }
        const roundedTileSize = Math.round(newTileSize / 10) * 10;
        if (roundedTileSize != tileSize) setTileSize(newTileSize)
      
        //initialDistance = distance;
      
        if (roundedDistance != distance) setPinchItinialDistance(roundedDistance)

     
  
    
        // const zoomFactor = distance / pinchInitialDistance;
        // const newTileSize = Math.min(Math.max(initialTileSize * zoomFactor, 30), 100);
        // const roundedTileSize = Math.round(newTileSize / 5) * 5;

    
        // if(roundedTileSize != tileSize) setTileSize(roundedTileSize);

      },
     
      [initialTileSize, pinchInitialDistance]
    );

    const handleMouseMove = (e) => {
     
      if (dragging) {
        const dx = e.clientX - startX;
        const dy = e.clientY - startY;
        renderDraw(dx, dy)
        clearTimeout(timeoutRef.current);
        hoverElement(e.clientX, e.clientY )
        setPopoverPos()
      // Déplacer de 25% de plus que la distance de la souris
      }
    
      
    }

    const handleTouchStart = (e) => {
      // timeoutRef.current = setTimeout(longPress, 500);
      setPopoverPos()
      setDragging(true);
      if (e.touches.length === 2) {
        setPinchItinialDistance(null)
        setStartX((e.touches[0].clientX + e.touches[1].clientX) / 2);
        setStartY((e.touches[0].clientY + e.touches[1].clientY) / 2);
        setIsZooming(true)
      } else {
        timeoutRef.current = setTimeout(() => longPress(e.touches[0].clientX, e.touches[0].clientY), 900);
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
      } 
    
     
    };
    
    const handleTouchMove = (e) => {

     

      if (dragging) {
        if (e.touches.length === 2) {
          handlePinchZoom(e)
          // const dx = e.touches[0].clientX - startX;
          // const dy = e.touches[0].clientY - startY;
          // renderDraw(dx, dy)
        } else {
          if (!isZooming){
          const dx = e.touches[0].clientX - startX;
          const dy = e.touches[0].clientY - startY;
          renderDraw(dx, dy)
          }
        }
       
      // Déplacer de 25% de plus que la distance de la souris
      }
      setPopoverPos()
      clearTimeout(timeoutRef.current);
    }
    
    const handleTouchEnd = (e) => {
      if (isZooming) {
        setDragging(false);
        setIsZooming(false)
        setTotalDx(ct.totalDx );
        setTotalDy(ct.totalDy);
      } else {
        setDragging(false);
    
        setTotalDx(totalDx + (e.changedTouches[0].clientX - startX) * swipeFactor);
        setTotalDy(totalDy + (e.changedTouches[0].clientY - startY) * swipeFactor);
      }
      clearTimeout(timeoutRef.current);
    };

    //const img = images.find(i => i.name == name).img


    const [bases, setBases] = useState([])
    const [availableBases, setAvailableBases] = useState()




    const starPoint = 3
    useEffect(() => {

      
      const _bases = []


      let size = 5
      if (extLvl == 0) size = 5
      if (extLvl == 1) size = 6
      if (extLvl == 2) size = 9
      if (extLvl == 3) size = 12
      if (extLvl == 4) size = 15
      if (extLvl == 5) size = 18
      if (extLvl == 6) size = 21
      if (extLvl == 7) size = 100

      


      let minus = (extLvl||1*2) + starPoint
      let counter = 0


      let gridRows = size; // Remplace par le nombre de lignes souhaité
      let gridCols = size; // Remplace par le nombre de colonnes souhaité

      for (let i = -size+minus ; i <= gridRows + starPoint; ++i) {
          for (let j = -size+minus ; j <= gridCols + starPoint ; ++j) {
            counter++
              // Ajouter chaque position à la liste
              _bases.push({ name: "base", x: j, y: i });
          }
      }


      setAvailableBases(counter)

      setBases(_bases)
    }, [extLvl])
   

    

    useEffect(() => {
      if (selectedDeck && !selectedDeck.deck) {
        const _userDecks = ct.userDecks?.filter(ut => !ut.x && ut.x != 0).map(c => {
         
          const deck = ct.decks?.find(deck => deck.id == c.deck_id)
          const image_name = `${deck?.type||"tree"}#1_${deck?.color||"green"}_5_mastered`
          const image = images.find(i => i.name == image_name)

          return {...c, deck, name: image_name, image}
        })

   
        const ud = _userDecks.find(e => e.id == selectedDeck.id)
        setSelectedDeck(ud)
      }

    }, [selectedDeck, key])
    
    const initForest = () => {
     
      const canvas = canvasRef?.current;
      if (!canvas) return 
      const ctx = canvas?.getContext('2d');

      const scaleFactor = 1.2;
      canvas.width = canvas.clientWidth * scaleFactor;
      canvas.height = canvas.clientHeight * scaleFactor;
      ctx.scale(scaleFactor, scaleFactor);
      draw(objs, offX, offY);
  }
  
  // Convertir les coordonnées de la souris ou d'autres coordonnées utilisateur en tenant compte de la mise à l'échelle
  const getMousePosition = (clientX, clientY, offX, offY, canvas) => {
      const rect = canvas.getBoundingClientRect();
      const scaleFactor = window.devicePixelRatio || 1;
  
      // Calculer la position de la souris par rapport à la mise à l'échelle
      const mouseX = (clientX - rect.left - offX) / scaleFactor;
      const mouseY = (clientY - rect.top - offY) / scaleFactor;
  
      // Convertir les coordonnées isométriques en coordonnées cartésiennes
      return isoToCar(mouseX, mouseY);
  }

    // var mouseX = (clientX - offX) - left;
    // var mouseY = (clientY - offY) - top;
    // var [_gridX, _gridY] = isoToCar(mouseX, mouseY);


    const initDataForest = (_packed_seeds) => {
      console.log('initDataForest 🌱🌱🌱🌱')

      if (_packed_seeds) {
        packed_seeds= _packed_seeds
        console.log('packseeds 🌱🌱🌱🌱', _packed_seeds)
      }


     



     

      let index_deck = 0
      const _userDecks = ct.userDecks?.filter(c => c.deck_id).map(c => {
        index_deck++
        const deck = ct.decks?.find(deck => deck.id == c.deck_id)
      
        const typeOfImage = deck?.type || deck?.presentation_mode == "Dialogue" ? "tree_s" : "tree"
        let color = "green"
        index_deck >= 1 && (color = index_deck%2==0 ? "blue" : "green")
        index_deck >= 4 && (color = index_deck%2==0 ? "yellow" : "blue")
        index_deck >= 8 && (color = index_deck%2==0 ? "pink" : "purple")
        index_deck >= 16 && (color = index_deck%2==0 ? "blue" : "green")
        
        const image_name = `${typeOfImage}#1_${deck?.color || color}_5_mastered`
        
        const image = images.find(i => i.name == image_name)

        return {...c, deck, name: image_name, image}
      })


      let currentColor = "purple"
      
      let indexPack = 0
      let indexCards = 0

      
      const defaultDate = new Date('1996-06-10');

      const globalDecksIDS = ct.globalDecks?.map(d => d.id) || []

      
      const __userCards = ct.userCards?.filter(uc => !uc.archived && uc.content).filter(uc => !uc.stacked).filter(uc => uc.content?.model != "grammar" && (globalDecksIDS.includes(uc.content.deck_id) || !uc.content.deck_id))
      .map(uc => ({...uc, created_date: uc?.created_date?.seconds ? new Date(uc.created_date.seconds * 1000) : uc?.created_date}))

      __userCards?.sort((a, b) => {
        const dateA = new Date(a.created_date || defaultDate);
        const dateB = new Date(b.created_date || defaultDate);
            return dateA - dateB;
        
    });


      let pack_index = 0
      let pack_icon_name = null

      const userCards1 = __userCards?.filter(uc => !uc.ud_id).map((c,i) => {
        const card = c.content
        let x = c.x
        let y = c.y
        if (!c.unpack) {
          x = null
          y = null
        }
        if (i%4==0) { pack_index ++ }
        
    
        const deck = ct.decks?.find(deck => deck.id == card?.deck_id)
        const pack = packed_seeds?.find(p => p.n == pack_index)
        // if (!pack) {console.log('pack not found', pack_index)}
        let params = null
       
        if (i%4 == 0) {
          indexPack++
          pack_icon_name = null
        }
       
        const next_date = c?.next_date?.seconds ? new Date(c.next_date.seconds * 1000) : c?.next_date
        const state = c?.archived ? "archived" : !next_date ? "to_learn" : next_date > new Date() ? "mastered" : "to_review"
        const halfDate = new Date(next_date);
        halfDate.setMinutes(halfDate.getMinutes() - ((60*24) - 3)); // Elle donne des elixirs 24h avant l'évolution si niveau 2 ou plus 
        const hasElixir = halfDate < new Date() && !c?.collected
        if (hasElixir) {
          pack_icon_name = "elixir_icon"
        }
      
        const icon_name = state == "to_review" ? "review_state" : null
        
        if (!card?.deck_id) {
          indexCards ++
        }
       

        currentColor = "green"

        const name =  state == "to_review" ?  `seed#1_${c.lvl}_review`: `seed#1_${currentColor}_${c.lvl}_mastered`
        
        //const name = "seed#1_purple_2_mastered"

        return {...c, card,x,y, deck, state, name, icon_name, pack, params, pack_index, pack_icon_name}
      }).filter(c => c.card) 

      pack_icon_name = null
      const userCards2 = __userCards?.filter(uc => uc.ud_id).map((c,i) => {
      
       

        const card = c.content
        let x = c.x
        let y = c.y
        if (!c.unpack) {
          x = null
          y = null
        }


       
        
    
        const deck = ct.decks?.find(deck => deck.id == card?.deck_id)
        const pack = userDecoration?.find(e => e.id == c.ud_id)
        const isSeed = pack?.name.includes('seed')
   
      
        currentColor = pack?.name.split('_')?.[1]


      
        // if (!pack) {console.log('pack not found', pack_index)}
        let params = null
       
   
        pack_icon_name = null
       
        const next_date = c?.next_date?.seconds ? new Date(c.next_date.seconds * 1000) : c?.next_date
        const state = c?.archived ? "archived" : !next_date ? "to_learn" : next_date > new Date() ? "mastered" : "to_review"
        const halfDate = new Date(next_date);
        halfDate.setMinutes(halfDate.getMinutes() - ((60*24) - 3)); // Elle donne des elixirs 24h avant l'évolution si niveau 2 ou plus 
        const hasElixir = halfDate < new Date() && !(c?.collected)
        const elixirDate = halfDate - ((1000*60*60*24) - (1000*60*3)) 
        const hasElixir2 = (elixirDate - new Date() < 0) && !(c?.collected)
       
        if (hasElixir) {
          pack_icon_name = "elixir_icon"
         
        }

        if (c.ud_id == "EehGCsO8JoqrKdHKSm94") console.log('LC ACARET !!', {c, pack_icon_name, hasElixir}) 

        
      
        const icon_name = state == "to_review" ? "review_state" : null
        const lvl = c.lvl < 1 ? 0 : c.lvl


     
       

        

        const name =  state == "to_review" ?  `seed#1_${c.lvl}_review`: `seed#1_${currentColor}_${c.lvl}_mastered`
        
        
        //const name = "seed#1_purple_2_mastered"


        return {...c, card,x,y, deck, state, name, icon_name, pack, params, pack_icon_name, v: !isSeed}
      })

   
   
     let params = null
     const ttCards = [...userCards1 || [], ...userCards2 || []]
      const userCards = ttCards?.sort((a, b) => (a.pack?.id || '').localeCompare(b.pack?.id || '')).map((c,i) => {
       
        let pack_icon_name
        
        const samePackCards = ttCards?.filter(uc => uc.pack?.id == c.pack?.id)
        const index = samePackCards?.findIndex(uc => uc.id == c.id);
        if (index % 4 == 0) { params = { packed: true, place: "top" }; }
        if (index % 4 == 1) { params = { packed: true, place: "left" }; }
        if (index % 4 == 2) { params = { packed: true, place: "right" }; }
        if (index % 4 == 3) { params = { packed: true, place: "bottom" }; }
      
      
       
        const packHasElixir = samePackCards?.find(uc => uc.pack_icon_name == "elixir_icon")
        const packHasReview = samePackCards?.find(uc => uc.state == "to_review")
       

        pack_icon_name = packHasReview ?  "review_state" : packHasElixir ? "elixir_icon" : ""


     
        

        return {...c, pack_icon_name, params}
      })


     




    //   if (maxPack < -1) {
    //   console.log('packed_seeds', packed_seeds);
  
    //   // Calculez le nombre d'éléments à supprimer
    //   const x = Math.floor(Math.abs(maxPack));
    //   console.log('Nombre d\'éléments à supprimer:', x);

    //   const seedsToRemove = packed_seeds.filter(pc => !pc.bc_id).slice(-x)
    //   seedsToRemove.forEach(pc => {
    //     setUserDecoration(ud => ud.filter(ud => ud.id != pc.id))
    //     ct.fire.remove(pc.id, "user_workspace_element")
    //     console.log('suppression packseed ❌', pc)
    //   })
    // }

      set_userCards(userCards)

      // console.log('uc', userCards[0])

      setUserDecks(_userDecks)

     

    

      const yellowDeco =  {x: selectedPos?.x, y: selectedPos?.y, afk: selectedPos?.date, c: 0, interface: true, name: selectedPos?.name, uwp: ct.userWorkspace?.id}




      const uds = [ yellowDeco,
        ...userDecoration?.map(ud => {
            if (ud.name === "temple_0") return {...ud, name: `temple_${currentLvlDojo}`};
            if (ud.name === "house_0") return {...ud, name: `house_${currentLvlHouse}`};
            if (ud.name?.includes("seed")) return {...ud, name: ""};
            // if (ud.avatar) return {...ud, avatar_id: ct.user.id};
            return ud;
        })
       
        // userAvatar
    ];

      // ajouter user ici 



     
      const __userDecks = setPosObj(_userDecks || [], images, null, "deck")
      const _objs = setPosObj(uds || [], images, null,  "deco")
      // const yellowSquare = setPosObj(users?.filter())
      
      //ici ?





     const seeds = setPosObj(userCards?.filter(uc => uc.state !="archived" ) || [], images, "seed")

    




      // const seedsPacked = setPosObj(userCards || [], images, {place: "right", packed: true}, "seed")

     

     

    //  _objs.map((obj) => obj.name == "temple_0" ? {...obj, } : obj)


      // const temple = images?.find(img => img.name == "temple_4")
      // const templeObjData = {x: 8, y: 1, name: temple?.name, uwp: ct.userWorkspace?.id}
      // const templeObjPos = setPosObj([templeObjData], images, null, "deco")[0]
      
      // _objs?.push(templeObjPos)
      //setUserDecoration([...userDecoration, templeObjData])

      

      
   
     


      const allGround = userDecoration.filter(e => e.type == "ground").map(e => ({x: e.x, y: e.y}))
      const filteredBase = bases.filter(e => !allGround.find(g => g.x == e.x && g.y == e.y))
      const biomes = setPosObj(filteredBase || [], images, null, "biome")
    

    
      setObjs([biomes, _objs, seeds, __userDecks].flat())

    }



    
   


    useEffect(() => 
    {
      if (ct.showCards?.length) return 
      if (userDecoration.length < 1 || !_userCards) return
      // console.log('userDecoration', userDecoration)
      let packed_seeds = userDecoration?.filter(ed => ed.type == "pc")
      const nonPlacedCards = _userCards?.filter(uc => !(uc.x || uc.x ==0) && !(uc.y || uc.y ==0) && !(uc.ud_id))?.length
      const maxPack =  (nonPlacedCards - packed_seeds?.length*4)/4 
      setNonPlacedCards(nonPlacedCards)
      
      console.log('changement de maxPack', maxPack)
      console.log('packed_seeds', packed_seeds.length)
      console.log('placedCard', nonPlacedCards)
      


      if (ct.statusCards?.length && ct.userWorkspace?.id ) {
        console.log('PLANTAGE DE GRAINE !! 🌳🌳🌳🌳')
        console.log('maxPack !!', maxPack)
       


        if (maxPack > 0) {
          ct.setStatusCards()
          setSelectedDeco({type: "pc", uwp: ct.userWorkspace?.id, n: missingN(packed_seeds)[0] })
        } else {
          setSelectedDeco()
          ct.setStatusCards()
          ct.setAlerts([{title: "Tous tes souvenirs sont déjà plantés" }])

        }
     
      }
    }, [ct.statusCards, ct.userWorkspace?.id, userDecoration, _userCards, ct.showCards])

    useEffect(() => {
        console.log('visited_uwp', visited_uwp)
       
        if (visited_uwp || talkMode) {
          ct.setShowBottomBar(false)
        }   
        
    }, [visited_uwp, key, talkMode])

   

    useEffect(() => {
       ct.setActiveScreen('forest')
       ct.setSelectedPack()
    }, [key])

    useEffect(() => {
      delectedDoubleUserCard(_userCards)
    }, [_userCards?.length])





    // essayer avec des guest

   












    /// faire un userEffect qui ajoute des images en fonction des guests avec leur ids 

    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      });
    };

    const loadNewImage = async (newImage) => {
      try {
        // Charger l'image principale
        newImage.img = await loadImage(newImage.src);
    
        // Charger les variantes
        if (newImage.variants) {
          newImage.variant_img = {};
    
          for (const variantKey in newImage.variants) {
            newImage.variant_img[variantKey] = await Promise.all(
              newImage.variants[variantKey].map((variantSrc) => loadImage(variantSrc))
            );
          }
        }

        
    
        // Ajouter l'objet chargé à l'état images
        setImages((prevImages) => [...prevImages, newImage]);
        return newImage
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    
    useEffect(() => {
      let numImagesLoaded = 0;
      let numImagesFailed = 0;
      let imagesCopy = [...imagesRaw]; // Créez une copie de imagesRaw
    
      imagesCopy.forEach((img, imgIndex) => {
        // Charger l'image principale
        const image = new Image();
    
        if (img.avatar) {
          const avatarParameters = {
            c1: 'blue',
            c2: 'red'
          };
          
          // Utiliser une URL d'avatar si nécessaire
          image.src = createAvatarUrl(avatarParameters);
        } else {
          
          image.src = img.src;
        }
    
        image.onload = function () {
          numImagesLoaded++;
          img.img = image; // Mettre à jour la propriété img
          checkAllImagesLoaded();
        };
    
        image.onerror = function () {
          numImagesFailed++;
          checkAllImagesLoaded();
        };
    
        // Charger les images d'animation
        if (img.animations && img.animations.length > 0) {
          img.anim_img = [];
          img.animations.forEach((animSrc, index) => {
            const animImage = new Image();
            animImage.src = animSrc;
            animImage.onload = function () {
              numImagesLoaded++;
              img.anim_img[index] = animImage; // Stocker l'image dans anim_img à l'index correspondant
              checkAllImagesLoaded();
            };
            animImage.onerror = function () {
              numImagesFailed++;
              checkAllImagesLoaded();
            };
          });
        }
    
        // Charger les variantes
        if (img.variants) {
          img.variant_img = {}; // Initialiser le champ variant_img
          Object.keys(img.variants).forEach((variantKey) => {
            img.variant_img[variantKey] = [];
            img.variants[variantKey].forEach((variantSrc, index) => {
              const variantImage = new Image();
              variantImage.src = variantSrc;
              variantImage.onload = function () {
                numImagesLoaded++;
                img.variant_img[variantKey][index] = variantImage; // Stocker l'image dans variant_img à l'index correspondant
                checkAllImagesLoaded();
              };
              variantImage.onerror = function () {
                numImagesFailed++;
                checkAllImagesLoaded();
              };
            });
          });
        }
      });
    
      function checkAllImagesLoaded() {
        const totalImages = imagesRaw.length + imagesRaw.reduce((acc, img) => {
          const animationsCount = img.animations ? img.animations.length : 0;
          const variantsCount = img.variants ? Object.values(img.variants).flat().length : 0;
          return acc + animationsCount + variantsCount;
        }, 0);
    
        if (numImagesLoaded + numImagesFailed === totalImages) {
          setImages(imagesCopy); // Mettre à jour l'état avec les images chargées
          setImgLoaded(true);
          initForest();
          initDataForest();
        }
      }
    }, [window.innerWidth]);



    // modifier l'herbe des biomes, et ajouter une bordure très légères 

    // en fonction de la position x de y et je modifie le filtrage de user_decoration
    // je transforme tout les ground en canvas unique
    // je trie toute les user_cards qui apparaisse à l'écran 
    // ==> Performance max 
 

    useEffect(() => {
     
      console.log('changmenet image')
      const uds = userDecoration?.map(ud => {
        if (ud.name == "temple_0") return {...ud, name: `temple_${currentLvlDojo}`}
        if (ud.name == "house_0") return {...ud, name: `house_${currentLvlHouse}`}
        if (ud.name?.includes("seed")) return {...ud, name: ""}
        return ud
       })

       const _userDecks = ct.userDecks?.filter(c => c.deck_id).map((c, index_deck) => {

        const deck = ct.decks?.find(deck => deck.id == c.deck_id)
      
        const typeOfImage = deck?.type || deck?.presentation_mode == "Dialogue" ? "tree_s" : "tree"
        let color = "green"
        index_deck >= 1 && (color = index_deck%2==0 ? "blue" : "green")
        index_deck >= 4 && (color = index_deck%2==0 ? "yellow" : "blue")
        index_deck >= 8 && (color = index_deck%2==0 ? "pink" : "purple")
        index_deck >= 16 && (color = index_deck%2==0 ? "blue" : "green")
        
        const image_name = `${typeOfImage}#1_${deck?.color || color}_5_mastered`
        
        const image = images.find(i => i.name == image_name)

        return {...c, deck, name: image_name, image}
      })
      const seeds = setPosObj(_userCards?.filter(uc => uc.state !="archived" ) || [], images, "seed")
      // console.log('uds', uds)

          
      const allGround = userDecoration.filter(e => e.type == "ground").map(e => ({x: e.x, y: e.y}))
      const filteredBase = bases.filter(e => !allGround.find(g => g.x == e.x && g.y == e.y)) || []
    
      const biomes = setPosObj(filteredBase || [], images, null, "biome")
      const _objs = setPosObj(uds || [], images, null,  "deco")
      const __userDecks = setPosObj(_userDecks || [], images, null, "deck")
    
 

      setObjs([biomes, _objs, seeds, __userDecks].flat())
      renderDraw(null, null);

     
    }, [tileSize, images, bases, selectedDeco, moveMode]) 

    


  //   const refreshCanvasObjs = (newOffX, newOffY, i, i2) => {
  //     let _objs = updatePosDeck(objs, newOffX, newOffY);;
  //     const map = _objs?.map(e => ({ ...e, t: "obj" })) || [];
  //     const __objs = sortArrayByXYSum(map) || [];

  //     draw(__objs, newOffX, newOffY, i, i2);
  // };

  let cachedObjs = null;
  let lastOffX = null; 
  let lastOffY = null;
  let timeoutId = null; // ID du timeout pour le debounce
  
  const refreshCanvasObjs = (newOffX, newOffY, i, i2) => {
    if (timeoutId) {
        clearTimeout(timeoutId); // Annuler le timeout précédent si une nouvelle mise à jour est en cours
    }
    
    // Définir un nouveau timeout pour regrouper les mises à jour
    timeoutId = setTimeout(() => {
        // Seules les dernières valeurs sont prises en compte pour le recalcul
        if (newOffX !== lastOffX || newOffY !== lastOffY) {
                        // Mettre à jour les objets et les trier directement dans cachedObjs
            const updatedObjs = updatePosDeck(objs, newOffX, newOffY);
            cachedObjs = sortArrayByXYSum(updatedObjs.map(e => ({ ...e, t: "obj" })));

            lastOffX = newOffX; // Mettre à jour les dernières valeurs
            lastOffY = newOffY;
        }

        // Effectuer le rendu avec les objets triés mis à jour ou en cache
        draw(cachedObjs, newOffX, newOffY, i, i2);
    }, 25); // 25ms de délai avant d'appliquer la mise à jour, ajustable selon les besoins
};




    const renderDraw = (dx, dy, i, i2) => {



      if (!dx || !dy) refreshCanvasObjs(offX, offY, i, i2)
   
      const newOffX =  Math.round((totalDx + dx * swipeFactor)/5)*5
      const newOffY =  Math.round((totalDy + dy * swipeFactor)/5)*5
      setOffX(newOffX )
      setOffY(newOffY)
       if (newOffX != offX || newOffY != offY) {
        refreshCanvasObjs(newOffX, newOffY, i, i2)
       }
    }






    const velocity = 300
    const i = useRef(0);
    const i2 = useRef(0);
    const asc = useRef(true);
    const [steps, setSteps] = useState({}); // Objet qui contient les steps par avatar_id

    const [canceledAvatarIds, setCanceledAvatarIds] = useState([]); // Ajoutez cet état pour suivre les animations annulées

    
    const [animations, setAnimations] = useState([]);
    const animationQueue = useRef([]);
    const isAnimating = useRef(false);
    
    useEffect(() => {
      initDataForest();
      renderDraw(null, null, i.current, i2.current);
    
      // const interval = setInterval(() => {
      //   const packed_seeds = userDecoration?.filter(ed => ed.type === "pc");
      //   // renderDraw(null, null, i.current, i2.current);
      //   initDataForest(packed_seeds);
      // }, 2000);
    
      // return () => clearInterval(interval);
    }, [userDecoration, ct.userCards]);
    


    useEffect(() => {
      const intervalHandlers = {};
    
      Object.keys(steps).forEach((avatarId) => {
        let stepIndex = 0;
    

        console.log('hey')
        const interval = setInterval(() => {
          if (stepIndex >= steps[avatarId].length) {
            clearInterval(interval);
            delete intervalHandlers[avatarId];
            return;
          }
    
          const step = steps[avatarId][stepIndex];
          const currentTime = performance.now();
    
          if (canceledAvatarIds.includes(step.avatar_id)) {
            clearInterval(interval);
            delete intervalHandlers[avatarId];
            return;
          }
    
          if (currentTime >= step.startTime) {
            // console.log('steps', step)
            // Forcer la mise à jour de l'état pour le premier mouvement
            setObjs((objs) =>
              objs.map((o) =>
                o.avatar_id === step.avatar_id
                  ? { ...o, ...step.finalState }
                  : o
              )
            );
    

    
            stepIndex += 1; // Passer à l'étape suivante
          }
        }, 1000 / 20); // 20 FPS
    
        intervalHandlers[avatarId] = interval;
      });
    
      return () => {
        Object.values(intervalHandlers).forEach(clearInterval);
      };
    }, [steps, canceledAvatarIds]);
    
    const cancelAnimation = (avatarId) => {
      setCanceledAvatarIds(prev => [...prev, avatarId]);
      setSteps(prevSteps => {
        const newSteps = { ...prevSteps };
        delete newSteps[avatarId];
        return newSteps;
      });
    };


    // useEffect(() => {
    
    //   renderDraw(null, null)
    //  // draw(canvasObj, offX, offY)
    // }, [objs, editorMode, key])

    useEffect(() => {
      // renderDraw(null, null, i.current, i2.current);
      if (dragging || isZooming || !showAnimation || ct.showCards) return;
    
      const fps = 30; // Frames per second
      const intervalTime = 1000 / fps; // Intervalle de temps entre les frames
      let animationInterval;
    
      const animateLoop = () => {
        // Animation de fond à 25 FPS
        renderDraw(null, null, i.current, i2.current);
        i2.current++;
    
        if (i.current === 24) asc.current = true;
        if (i.current === 0) asc.current = false;
        asc.current ? i.current-- : i.current++;
      };
    
      animationInterval = setInterval(animateLoop, intervalTime);
    
      return () => {
        clearInterval(animationInterval);
      };
    }, [dragging, isZooming, showAnimation, _userCards, objs]); 
    
  
    
    const animateTransition = useCallback((oldPlayerObj, newPlayerObj, duration) => {
      const steps = [];
      const stepCount = 7; // Nombre d'étapes d'interpolation souhaitées
      const interval = duration / stepCount; // Durée entre chaque étape
    
      for (let i = 0; i <= stepCount; i++) {
        const factor = i / stepCount;
        const stepTime = performance.now() + i * interval; // Calcul du temps pour chaque étape
    
        const interpolatedObj = {
          pos: {
            x1: oldPlayerObj.pos.x1 + (newPlayerObj.pos.x1 - oldPlayerObj.pos.x1) * factor,
            x2: oldPlayerObj.pos.x2 + (newPlayerObj.pos.x2 - oldPlayerObj.pos.x2) * factor,
            x3: oldPlayerObj.pos.x3 + (newPlayerObj.pos.x3 - oldPlayerObj.pos.x3) * factor,
            x4: oldPlayerObj.pos.x4 + (newPlayerObj.pos.x4 - oldPlayerObj.pos.x4) * factor,
            y1: oldPlayerObj.pos.y1 + (newPlayerObj.pos.y1 - oldPlayerObj.pos.y1) * factor,
            y2: oldPlayerObj.pos.y2 + (newPlayerObj.pos.y2 - oldPlayerObj.pos.y2) * factor,
            y3: oldPlayerObj.pos.y3 + (newPlayerObj.pos.y3 - oldPlayerObj.pos.y3) * factor,
            y4: oldPlayerObj.pos.y4 + (newPlayerObj.pos.y4 - oldPlayerObj.pos.y4) * factor,
          },
        };
    
        const img = newPlayerObj.image?.variant_img?.[newPlayerObj.variant]?.[Math.floor(factor * 6) % 6] || oldPlayerObj.img;
    
        steps.push({
          avatar_id: oldPlayerObj.avatar_id,
          startTime: stepTime,
          finalState: { ...interpolatedObj, img, reverse: (newPlayerObj.variant == "rt" || newPlayerObj.variant == "rb") , test:"cc", x: newPlayerObj.x, y: newPlayerObj.y, chat: newPlayerObj.chat },
        });
      }
    
      return steps;
    }, []);
    
    const getTransitionForOneStep = (direction, deco, accumulatedTime = 0) => {
    
      if (!deco) return [];
    
      let old_player_obj = setPosObj([deco], images, null, "deco")?.[0];

     
    
      switch (direction) {
        case "up":
          deco.y--;
          deco.variant = "lt";
          break;
        case "down":
          deco.y++;
          deco.variant = "lb";
          break;
        case "left":
          deco.x--;
          deco.variant = "rt";
          break;
        case "right":
          deco.x++;
          deco.variant = "rb";
          break;
        default:
          break;
      }
    
      let player_obj = setPosObj([deco], images, null, "deco")?.[0];
    

      const steps = animateTransition(old_player_obj, player_obj, velocity);
        
      // Ajuster le startTime en fonction du temps accumulé
      const adjustedSteps = steps.map(step => ({
        ...step,
        startTime: step.startTime + accumulatedTime
      }));
    
      return adjustedSteps;
    };



    // create an image
    


    
    useEffect(() => {

    
      guests.forEach(guest => {
        const newDeco = {
          name: guest.user_id,
          x: guest.x,
          y: guest.y,
          avatar_id: guest.user_id,
          chat: guest.chat || guest.name || "",
          rmvc: guest.rmvc,
         
        };
    
        let foundDeco = userDecoration.find(o => o.avatar_id === newDeco.avatar_id);
        const foundObj = objs?.find(o => o.avatar_id === newDeco.avatar_id)

   
          const updatedDeco = {
            ...foundDeco,
            x: foundObj?.x,
            y: foundObj?.y,
            // Ajouter d'autres propriétés si nécessaire pour synchroniser complètement
          };
        
         
        
      
        // const foundObj = objs.find()

    
        if (foundDeco) {
          const foundImage = images?.find(i => i.name == guest.user_id)
          console.log('foundImage', foundImage)

          if (!foundImage && guest.user_id) createAvatarObj(updatedDeco, guest)
       
         
            setFuturSteps(guest.x, guest.y, updatedDeco)
       
          
          if (foundDeco.chat !== guest.chat) {
            // setUserDecoration(prev =>
            //   prev.map(deco =>
            //     deco.avatar_id === guest.user_id ? { ...deco, chat: guest.chat } : deco
            //   )
            // );
            setObjs(prevObjs =>
              prevObjs.map(o =>
                o.avatar_id === guest.user_id ? { ...o, chat: guest.chat } : o
              )
            );
          }
        } else {
          console.log('LOG NO EXSITANT')
          createAvatarObj(newDeco, guest)
          // il faut créer l'image à ce moment
         
        }
      });
    

    }, [guests]);


    const createAvatarObj = async (newDeco, guest) => {
      console.log('guest', guest);
      console.log('create avatar obj 🤡', guest);
  
      let c1 = "pink";
      let c2 = "purple";
  
      if (guest.user_id === "GxIUEOziPSGeDwpP7MCs") {
          c1 = "green";
          c2 = "blue";
      }
  
      // Générer les URLs des variantes de l'avatar de manière asynchrone
      const src = await createAvatarUrl({ code: guest.avtc });
      const variants = {
          lb: await Promise.all([
              createAvatarUrl({ code: guest.avtc, variant: "lb_0" }),
              createAvatarUrl({ code: guest.avtc, variant: "lb_1" }),
              createAvatarUrl({ code: guest.avtc, variant: "lb_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "lb_3" }),
              createAvatarUrl({ code: guest.avtc, variant: "lb_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "lb_1" })
          ]),
          lt: await Promise.all([
              createAvatarUrl({ code: guest.avtc, variant: "lt_0" }),
              createAvatarUrl({ code: guest.avtc, variant: "lt_1" }),
              createAvatarUrl({ code: guest.avtc, variant: "lt_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "lt_3" }),
              createAvatarUrl({ code: guest.avtc, variant: "lt_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "lt_1" })
          ]),
          rt: await Promise.all([
              createAvatarUrl({ code: guest.avtc, variant: "rt_0" }),
              createAvatarUrl({ code: guest.avtc, variant: "rt_1" }),
              createAvatarUrl({ code: guest.avtc, variant: "rt_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "rt_3" }),
              createAvatarUrl({ code: guest.avtc, variant: "rt_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "rt_1" })
          ]),
          rb: await Promise.all([
              createAvatarUrl({ code: guest.avtc, variant: "rb_0" }),
              createAvatarUrl({ code: guest.avtc, variant: "rb_1" }),
              createAvatarUrl({ code: guest.avtc, variant: "rb_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "rb_3" }),
              createAvatarUrl({ code: guest.avtc, variant: "rb_2" }),
              createAvatarUrl({ code: guest.avtc, variant: "rb_1" })
          ])
      };
  
      const newImage = {
          name: newDeco.avatar_id,
          fr: newDeco.avatar_id,
          admin: true,
          h: 50,
          w: 50,
          price: 0,
          offY: -10,
          unlock: 0,
          avatar: true,
          deco: true,
          src, // Utilisation de l'URL PNG générée
          variants // Utilisation des variantes générées
      };
  
      const imageLoaded = await loadNewImage(newImage);
      console.log('imageLoaded', imageLoaded);
  
      setUserDecoration(prev => [...prev, newDeco]);
  };




  

    const setFuturSteps = (x, y, deco) => {
      if (!deco || !deco.avatar_id) return;
    
      cancelAnimation(deco.avatar_id);
    
      const moves = getNextMove(
        { x: deco.x, y: deco.y },
        { x: x, y: y }
      );
    
      const allSteps = [];
      let accumulatedTime = 0;
      let currentDeco = { ...deco };
    
      moves.forEach((m) => {

        const steps = getTransitionForOneStep(m.direction, currentDeco, accumulatedTime);
        allSteps.push(...steps);
    
        currentDeco.x = m.x;
        currentDeco.y = m.y;
    
        accumulatedTime += velocity; // Chaque mouvement prend 1000ms
      });
    
      setSteps(prevSteps => ({
        ...prevSteps,
        [deco.avatar_id]: allSteps, // Stocke les steps pour cet avatar spécifique
      }));
    

      // console.log('steps', steps)
      // console.log('my steps', steps?.["4hGyTyBi9ae0qpDW2Q2h"]?.map(s => s.finalState))
      setUserDecoration(prev => 
        prev.map(dec => dec.avatar_id === deco.avatar_id ? deco : dec)
      );
    
      setCanceledAvatarIds(prev => prev.filter(id => id !== deco.avatar_id));
    };
  
    const getNextMove = (currentPos, targetPos) => {
      const nextMoves = [];
      let x = currentPos.x;
      let y = currentPos.y;
  
      while (x !== targetPos.x || y !== targetPos.y) {
        let direction = "";
  
        if (x < targetPos.x) {
          x++;
          direction = "right";
        } else if (x > targetPos.x) {
          x--;
          direction = "left";
        } 
        else if (y < targetPos.y) {
          y++;
          direction = "down";
        } else if (y > targetPos.y) {
          y--;
          direction = "up";
        }
  
        nextMoves.push({ x, y, direction });
      }
  
      return nextMoves;
    }



    
    
  /// opitmisation


  // soit on tente l'animation direct dans boucle guest et on les lances la bas 
  // soit on fait le système avec les frames

    



























  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        console.log('coucou');
        const sendBtn = document.getElementById('send_btn')
        sendBtn?.click()

        
      }
    };

    // Ajoutez l'écouteur d'événements lors du montage du composant
    window.addEventListener('keydown', handleKeyPress);

    // Nettoyez l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);




  function drawGrid(ctx, { dx, dy }) {
    let bonus = 0;
   

    // if (extLvl >= 0 && extLvl <= 6) {
    //     size = 3 * (extLvl + 1) + bonus;
    // }


    let size = 5
    if (extLvl == 0) size = 6
    if (extLvl == 1) size = 6
    if (extLvl == 2) size = 9
    if (extLvl == 3) size = 12
    if (extLvl == 4) size = 15
    if (extLvl == 5) size = 18
    if (extLvl == 6) size = 21

    size += 10


    let minus = (extLvl||1*2) + starPoint

    const max = -size + minus
    const min = size + 1

    ctx.beginPath();
    ctx.strokeStyle = "rgba(250, 250, 0, 0.5)";

    // Dessiner les lignes horizontales isométriques (diagonales descendantes)
    for (let i = max; i <= min; ++i) {
      const [x1, y1] = carToIso(max, i);
      const [x2, y2] = carToIso(min, i);
      ctx.moveTo(x1 + dx, y1 + dy);
      ctx.lineTo(x2 + dx, y2 + dy);
  }

  // Dessiner les colonnes isométriques (diagonales montantes)
  for (let i = max; i <= min; ++i) {
      const [x1, y1] = carToIso(i, max);
      const [x2, y2] = carToIso(i, min);
      ctx.moveTo(x1 + dx, y1 + dy);
      ctx.lineTo(x2 + dx, y2 + dy);
  }

    ctx.stroke();
}

function isOutGrid(x, y, bonus = 10) {

  let size = 5
  if (extLvl == 0) size = 6
  if (extLvl == 1) size = 6
  if (extLvl == 2) size = 9
  if (extLvl == 3) size = 12
  if (extLvl == 4) size = 15
  if (extLvl == 5) size = 18
  if (extLvl == 6) size = 21


  size += bonus

  let minus = (extLvl||1*2) + starPoint
  const min = -size + minus + 1
  const max = size

  if (x < min || x > max || y < min || y > max) {
      return true; // Le point est en dehors de la grille
  }
  return false; // Le point est à l'intérieur de la grille
}

    

        function fillObj(ctx, obj, params) {    
          const frame = 16;
          const f = obj.image?.animation ? 1 + params.i / (20 * frame) : 1;
          const slowFactor = 1; // Plus ce facteur est élevé, plus l'animation sera lente
      

        

          if (obj.afk && (obj.afk - new Date() < 0)) { 
              return 
            // return
          }
          
          const indexImg = obj.img_frame 
              ? Math.floor((params.i2 / slowFactor) % obj.img_frame.length)
              : 0;
      
          let img = obj.img_frame ? obj.img_frame[indexImg] : obj.img;
      
          if (obj.img && obj.type != "hidden" && img) {

            if (obj.reverse) {
             
   
              ctx.save(); 
    
              // Translater et inverser le contexte
              ctx.translate(obj.pos.top + obj.w, obj.pos.bottom + (obj.h - (obj.h * f)));
              ctx.scale(-1, 1);
              
              // Dessiner l'image inversée
              ctx.drawImage(img, 0, 0, obj.w, obj.h * f);
              
              // Restaurer le contexte
              ctx.restore();
            } else {
              ctx?.drawImage(img, obj.pos.top, obj.pos.bottom + (obj.h - (obj.h * f)), obj.w, obj.h * f);
            }
           
          }
          if (obj.icon && !visitedUWP && obj.iconObj) {
              ctx?.drawImage(obj.icon, obj.pos.x3 + obj.iconObj?.offX, obj.pos.y3 - obj.iconObj?.offY, obj.iconObj.width, obj.iconObj.height);
          }
          if (ctx && obj.pos && obj.h && obj.w && obj.avatar_id) {
             
              const guest = guests.find(g => g.user_id == obj.avatar_id);

              if (!guest) return;
            
              // Nom de l'invité
              ctx.font = "14px fredoka"; // Police et taille du texte
              ctx.textAlign = "center"; // Centrer le texte horizontalement
              ctx.lineWidth = 3; // Épaisseur du contour
      
              // Dessiner le contour noir du texte
              ctx.strokeStyle = "purple";
              // ctx.strokeText(guest.name, obj.pos.top + obj.w / 2, obj.pos.bottom + (obj.h - 40));
      
              // Dessiner le texte blanc par-dessus
              ctx.fillStyle = "white";
              // ctx.fillText(guest.name, obj.pos.top + obj.w / 2, obj.pos.bottom + (obj.h - 40));
      
              if (guest?.rmvc < new Date() || !guest?.chat) return;
      
            // Dessiner la bulle de chat
        const padding = 10;
        const radius = 8;

        // Mesurer la largeur et la hauteur du texte, et gérer les sauts de ligne
        const text = guest?.chat || "...";
        const words = text?.split(' ');
        const maxWidth = text.length > 12 ? 150 : 80; // Largeur maximale de la bulle

   
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = ctx.measureText(currentLine + " " + word).width;
            if (width < maxWidth) {
                currentLine += " " + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);

        const lineHeight = parseInt(ctx.font, 10); // Taille de la police, utilisée comme hauteur de ligne
        const bubbleWidth = maxWidth + padding * 2;
        const bubbleHeight = lineHeight * lines.length + padding * 2;

        const bubbleX = obj.pos.top + obj.w / 2 - bubbleWidth / 2;
        const bubbleY = obj.pos.bottom + (obj.h - 60) - bubbleHeight / 2;

        // Dessiner un rectangle arrondi pour la bulle
        ctx.fillStyle = "#f5f5dc"; // Fond beige
        ctx.strokeStyle = "black"; // Contour noir
        ctx.lineWidth = 2;

        // Dessiner le rectangle arrondi
        ctx.beginPath();
        ctx.moveTo(bubbleX + radius, bubbleY);
        ctx.arcTo(bubbleX + bubbleWidth, bubbleY, bubbleX + bubbleWidth, bubbleY + bubbleHeight, radius);
        ctx.arcTo(bubbleX + bubbleWidth, bubbleY + bubbleHeight, bubbleX, bubbleY + bubbleHeight, radius);
        ctx.arcTo(bubbleX, bubbleY + bubbleHeight, bubbleX, bubbleY, radius);
        ctx.arcTo(bubbleX, bubbleY, bubbleX + bubbleWidth, bubbleY, radius);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        // Dessiner le texte du chat à l'intérieur de la bulle, ligne par ligne
        ctx.fillStyle = "black";
        for (let i = 0; i < lines.length; i++) {
            ctx.fillText(lines[i], obj.pos.top + obj.w / 2, bubbleY + padding + (i + 1) * lineHeight - 3);
        }
    }
}
     const base = tileSize/50



     const setPosObj = (o, images, params, type) => {

      return o.map(obj => {
        
        const x = obj?.pack?.x == 0 ? 0 : obj?.pack?.x || obj.x
        const y = obj?.pack?.y == 0 ? 0 : obj?.pack?.y || obj.y
        const w = obj.w || 1
        const h = obj.h || 1
      
  
        var [x1, y1] = carToIso(x, y)
        var [x2, y2] = carToIso((x + w), y)
        var [x3, y3] = carToIso((x + w), (y + h))
        var [x4, y4] = carToIso(x, (y + h))
  
        const image_name = obj?.name
        
        const image = images?.find(i => i.name == image_name)
        const img_frame = image?.anim_img
        // bomb
        const icon = images?.find(i => i.name == obj.icon_name || i.name == obj.pack_icon_name)
        let iconObj = null

        const z = obj?.z == 0 ? 0 : obj?.z || image?.z == 0 ? 0 : image?.z || 1

           
        const img = image?.img
        

        let height = image?.h || 100
        let width = image?.w || 100
        let offY = image?.offY * base || 0
        let offX = image?.offX * base || 0

        
        if (obj.params?.packed) {
         
          height = height/1.3
          width = width/1.4
          if (obj.params?.place == "top") { 
            offY = offY - base*20
          } 
          if (obj.params?.place == "left") { 
            offY = offY - base*10
            offX = offX - base*25
          } 
          if (obj.params?.place == "right") { 
            offY = offY - base*10
            offX = offX + base*25
          } 
          if(icon) {
       
            if(icon.name == "elixir_icon" || icon.name == "review_state") {
               if (obj.params?.place == "top") iconObj = {width: base*icon.size, height: base*icon.size, offY:  base*(30 + icon.offY), offX: base*(5+ icon.offX)}
            } else {
              iconObj = {width: base*icon.size, height: base*icon.size, offY:  base*(40 + icon.offY), offX: base*(5+ icon.offX)}

            }
          }
        } else {
          if(icon) iconObj = {width: base*icon.size+8, height: base*icon.size+8, offY:base*(10), offX: base*10}
        }

        const w_pos = width*(tileSize/55.5)
        const h_pos = height*(tileSize/55.5)
    
        const pos = {
          x1: x1 + offX, x2: x2 +offX, x3: x3 +offX, x4: x4 +offX , 
          y1: y1 +  offY, y2: y2 +  offY, y3: y3 + offY, y4: y4 + offY,
          top: x3 - obj.w/2, bottom: y3 + offY - obj.h
        }
  
  
        return {...obj, pos, x, y, z, img, img_frame, height, width, h: h_pos, w: w_pos, type: obj?.v ? "hidden" : type, icon: icon?.img, iconObj, image}
       
      })
  }

      const updatePosDeck = (objs, offX, offY) => {

        return objs.map(obj => {
          if (obj.pos) {
            const x3 = obj.pos.x3 + offX
            const y3 = obj.pos.y3 + offY
            return {...obj, pos: {
            x1: obj.pos.x1 + offX, x2: obj.pos.x2 + offX, x3: x3, x4: obj.pos.x4 + offX, x5: obj.pos.x5 + offX, x6: obj.pos.x6 + offX, 
            y1: obj.pos.y1 + offY, y2: obj.pos.y2 + offY, y3: y3, y4: obj.pos.y4 + offY, y5: obj.pos.y5 + offY, y6: obj.pos.y6 + offY, 
            top: x3 - (obj.w/2), bottom: y3 - obj.h
          }} } else return obj
        })
    }


    const carToIso = (x, y) => {
        x *= tileSize;
        y *= tileSize;
        return [
          Math.round((x - y) * Math.sqrt(3) / 2 + canvasOffset ),
          Math.round((x + y) / 2 )
        ];
        }
      
      const isoToCar = (a, b) => {
         // Convert isometric (a, b) to cartesian coordinates
          var xMinusY = (a - canvasOffset) / Math.sqrt(3) * 2;
          var xPlusY = (b) * 2;
          var x = (xMinusY + xPlusY) / 2;
          var y = xPlusY - x;
          return [
            Math.floor(x / tileSize), // scaling is here too
            Math.floor(y / tileSize)
          ];
        }

     

     





    // const allMasteredCards = _userCards?.filter(u => u.next_date > new Date()).length
    // const allCardToDo =  _userCards?.length - allMasteredCards 

    
  
  // const audio = new Audio('/assets/fleur.mp3');
  // const playAudio = () => {
    
   
  //   !disco ? audio.play() : audio.pause()
  // };

  const money = ct.userWorkspace?.coins || 0



  const imageWithData = images.filter(el => el.deco && (!el.wp || el.wp == ct.workspace?.id) ).reverse().map(t => {
    const canBuy = t?.price <= (ct.userWorkspace?.coins ||0)
    const canUnlock = t?.unlock <= (ct.userWorkspace?.elixir ||0)
    const unlockedDeco = userDecoration?.find(ud => ud.type == "unlock" && ud.name == t.name);
    const isLockedByExilir = t.unlock ? !(!!unlockedDeco) : false;
    const isLockedByLvl = false
    const isPurchasable = !isLockedByExilir && !isLockedByLvl
    const isAvailable = canBuy && isPurchasable
    return {...t, canBuy, canUnlock, unlockedDeco, unlockedDeco, isLockedByExilir, isLockedByLvl, isPurchasable, isAvailable}
  })

  

  const renderDeco = (t) =>  <div 
             onClick={() => {
              t.isAvailable && setSelectedDeco(t)
              t.isLockedByExilir &&  ct.setShowInfo({title: "Débloquer un objet",  classContent: "bg-[#5b00b1] text-white", content: 
              <>
              <div className="text-white">Veux-tu débloquer cet objet ? Tu pourras l'acheter par la suite avec tes pièces.</div>
              <div className="flex mt-2 gap-4 justify-between items-center ">
                <div className="px-3 py-2 rounded-xl bg-white/10">Annuler</div>
                <div onClick={() => {
                    const nextElixir = (ct.userWorkspace?.elixir || 0) - t.unlock
                    const elements = imageWithData.filter(i => i.name == t.name || (t.group && i.group == t.group))
                   
                    if (nextElixir >= 0) {
                     
                      elements.forEach((e) => {
                        const newElement = {type: "unlock", cost: t.unlock, name: e.name, uwp: ct.userWorkspace?.id}
                       
                        setUserDecoration(ud => [...ud || [], newElement])
                        ct.fire.createDb('user_workspace_element', newElement)
                      })
                      ct.fire.updateUserWorkspace({...ct.userWorkspace, elixir: nextElixir}, ct)
                      
                   
               

                    } else {
                      ct.setAlerts([{title: "Tu n'as pas assez d'elixir pour acheter cet objet" }])
                      ct.setShowInfo()
                    }
                  
                }} className="px-3 bg-white justify-center grow text-purple-500 py-2 font-bold rounded-xl flex gap-2 items-center">Débloquer -{t.unlock} <img className='h-[22px] w-[22px]' src={'/images/deco/elixir.png'}/></div>
              </div>
              </>
            })
              !t.canBuy && ct.setAlerts([{title: "Tu n'as pas assez d'argent pour acheter cet objet" }])
            }} 
              className={` ${t.isAvailable ? "bg-gradient-to-b from-indigo-500  to-blue-500 border-black/70 " : " border-black/50  bg-indigo-800"} ${t.span == 2 ? "col-span-2"  :"" } flex flex-col justify-between items-center relative b ${t.edition ? "h-[70px]":"h-[100px]"}  border-2 border-b-4   p-1 rounded-xl`}>
                <div className={`${t.isAvailable ? "": "filter-gray filter  grayscale opacity-60"}`}>
                <div className="text-shadow font-bold capitalize text-[12px]">{t.fr}</div>
                <img className="w-[50%] mx-auto mb-4 max-h-[120px]" src={t.src}/>
                </div>
                {t.nb ? <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{t.nb}</div> : null}
                {t.isPurchasable && t.price ? <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{t.price} <img className='h-[12px] w-[12px]' src={'/images/UX/coins.png'}/></div> : null}
                {t.isLockedByExilir && <div className="text-shadow absolute bottom-2 self-end capitalize text-[12px] items-center flex gap-[4px] font-bold bg-black/20 rounded-xl text-purple-100 px-[4px] py-[1px]">{t.unlock} <img className='h-[12px] w-[12px]' src={'/images/deco/elixir.png'}/></div>}

                {/* {t.name == "plant" && <div className='px-2 rounded-full'>{cardBadPosition.length + cardToPlace.length}</div> */}
                
                </div>



  const todaysEvents =  props.todaysEvents

  const today = new Date();
  const dateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  const totalMemory = ct.userCards.filter(e => !e.archived)?.length
  const noMoreCard = ct.user.demo && totalMemory > 49


  const myEventNoRead = props.myEventNoRead

    const lvlExtension = ct.userWorkspace?.extLvl||0
    const territory_enoughCoin = money - priceExtension[lvlExtension].gold > 0
    const territory_enoughSeeds = _userCards?.filter(e => !e.archived)?.length >= priceExtension[lvlExtension]?.packed
    const territory_enoughElixir = ct.userWorkspace?.elixir >= priceExtension[lvlExtension].elixir

    const territory = {
      price: priceExtension[lvlExtension]?.gold,
      neededPack: priceExtension[lvlExtension]?.packed,
      enoughCoin:territory_enoughCoin,
      enoughSeeds: territory_enoughSeeds,
      enoughElixir: territory_enoughElixir,
      canUpgrade: territory_enoughCoin && territory_enoughSeeds && territory_enoughElixir
    } 


    const lockerScreen = props.lockerScreen
    const blocked = !lockerScreen.forest.access || !lockerScreen.forest.unlocked

    const smileys = [
      "😃", "😎", "😡", "😭", "😍", "🤔", "😱", "😈", "😴", "🎉",
      "😂", "😇", "😋", "😜", "😢", "🤯", "😅", "🤩", "😒", "😏",
      "🤮", "🥳", "😬", "😠", "😤", "❤️", "🔥", "💀", "🤡", "👌"
  ];



    const myAvatarDeco = userDecoration?.find(d => d.avatar_id === ct.user.id)
    const myAvatarObj = objs?.find(o => o.avatar_id === ct.user.id)


    const userOnScreen = useMemo(() => {
      return userDecoration.find(g => g.avatar_id == ct.user.id);
  }, [userDecoration, ct.user.id]);


    const [showTuto, setShowTuto] = useState(true)

    const lockScreen = <LockScreen little={true} title={"L'île de connaissance"} id={"forest"} goal={lockerScreen.forest.goal} current={lockerScreen.tt} ct={ct} /> 


    const blockedScreen = blocked && <div className="fixed z-[100] top-0 left-0 right-0 bg-gradient-to-b from-[#5a3591] text-white to-[#36206c] h-screen p-4 flex items-center h-full w-full">
        
    <div className="-mt-[100px] w-full">
      {lockScreen}
      {showTuto && <div className="fixed z-[101] top-0 left-0 right-0 bottom-0  "><InteractivePage bottomBar={true} pages={tutoForest} close={() => setShowTuto(false)} lockScreen={lockScreen} blocked={blocked} ct={ct}/></div>}
    </div>
   
  
  </div>

useEffect(() => {
  if (showAlchimie) {ct.setShowBottomBar(false)} else {ct.setShowBottomBar(!visited_uwp && !talkMode)}
  if (moveMode) {ct.setShowBottomBar(false)} else {ct.setShowBottomBar(!visited_uwp && !showAlchimie && !talkMode)}
  return () => {
    ct.setShowBottomBar(!visited_uwp && !talkMode)
  }
}, [showAlchimie, visited_uwp, moveMode, talkMode])




const [selectedEvo, setSelectedEvo] = useState()


const masteredCards = props.masteredCards

const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [msgs])



  const [usedBase, setUsedBase] = useState()

 
  // console.log('outerGround', outerGround)




  useEffect(() => {
    const waterBase = userDecoration?.filter(e => e.type == "ground" && e.name == "water")?.length
    const outerGround = userDecoration?.filter(e => e.type == "ground" && e.name != "water" && isOutGrid(e.x, e.y, 0))?.length
    const bonus = ((extLvl||0)+1)*9

    setUsedBase(outerGround  - (waterBase + bonus ))
  }, [userDecoration, extLvl])


const evolutions = [
  {name: "Fleur", n: 4, lvl: 1, pc: true,  eName: "seed#1_@@@_2_mastered", element: "/images/seeds/@@@-3.png"},
  {name: "Orchidée", n: 8, lvl: 2, eName: "orchidax-@@@", element: "/images/seeds/evo/orchidax-@@@.png", recipe: ["Fleur", "Fleur"]},
  {name: "Champignon", n: 16, lvl: 3, eName: "champ-@@@", element: "/images/seeds/evo/champ_@@@.png", recipe: ["Orchidée", "Orchidée"]},
  {name: "Jardin", n: 32, lvl: 4, eName: "forest-@@@", element: "/images/seeds/evo/forest-@@@.png", recipe: ["Champignon", "Champignon"]}
  ]


  console.log('props', props)

  if (ct.userWorkspace.goal_id == "roseta") {
    return <div className="bg-purple-500 text-center flex h-screen w-screen text-white fredoka items-center p-8">
      <div><img className="h-[200px] mx-auto" src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/img_app%2Fexemple%20forst.png?alt=media&token=4750674b-da76-40d6-bba8-83ebe73cac8f" />
      <div className="text-2xl font-semibold mb-2">L'ile de connaissance</div>
      Termine les cours de la pierre de Rosette et apprends tes premiers <span className="text-cyan-300 font-semibold">mots</span> dans les <span className="text-cyan-300 semibold">autres cours</span> pour accéder à l’île de connaissances
      </div>
    </div>
  }

    return (
     
     <div className="fixed  w-full bg-blue-400 overflow-hidden by h-screen max-h-screen z-90 sb">
      
       {blockedScreen}

       {showSeed && <div className="fixed top-0 left-0 text-center text-emerald-800 fredoka p-4 right-0 bottom-0  bg-white/90  z-[999]">
        <div className="absolute top-4 right-4" onClick={() => {
          setShowSeed(false)
          ct.setShowBottomBar(!visited_uwp)
        }} > <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>
      </div>
        <div className="m-8 mt-16">MindSeed participe à la reforestation avec </div>
        <img className="mx-auto h-20 my-4" src="https://img.edenprojects.org/content/background-motifs/Copy-of-World-Environment-Day-3.png?auto=compress%2Cformat&crop=focalpoint&fit=crop&fp-x=0.5&fp-y=0.5&h=630&q=80&w=1200&s=0af514c9f95e9081db0e61a88dc1b86f" />
        <div className="mt-2">Chaque mois, nous donnons 8 % de nos bénéfices à l'association Eden en fonction de l'activité des utilisateurs :</div>
        <div className="font-semibold mt-8">Plus tu plantes de souvenirs sur ton île de connaissances, plus tu sèmes de véritables graines dans la nature.</div>






</div>}



          {(!ct.loadedDecoration ) && (
            <div className="fixed top-0 left-0 text-white/70 fredoka right-0 bottom-0 flex items-center justify-center bg-black/90 z-[999]">
              <div>Chargement ...</div>
            </div>
          )}

          {(!imgLoaded  ) && (
            <div className="fixed top-0 left-0 text-white/70 fredoka right-0 bottom-0 flex items-center justify-center bg-black/90 z-[999]">
              <div>Chargement des images...</div>
            </div>
          )}

          {moveMode && <div className={`fixed fredoka bottom-0 text-sm pt-2  p-4 transition-all  text-white left-0 right-0 ${(selectedDeco?.name != "move" || selectedCard) ? "bg-[#2EA4C9]" : "bg-[#753dff]"}  `}>
            <div className="flex z-10 justify-end" onClick={() => {setMoveMode(false); setSelectedDeco(false)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div>
            <div className="flex -mt-6">

              {selectedDeco?.name != "move" ? 
              <div className="h-[50px] relative w-[50px] border-2 border-black/40 bg-white/10 rounded-xl p-1 mr-2">
              <img src={selectedDeck?.image?.src || selectedCard?.card?.img || selectedDeco?.src || images?.find(i => i.name == selectedDeco?.name)?.src || "images/seeds/green-2.png"} className=" scale-[1.1] absolute bottom-0 object-cover rounded-md" />
              </div> : <img src="/images/deco/move_icon.png" className="h-[60px] -ml-2" />}
              <div className="mr-[20px]">
                <div className="text-lg ">Mode édition</div>
                {selectedDeco?.name != "move" ? <div>Appuie sur une case pour placer l'élement</div> : <div>Appuie sur un élement pour le déplacer</div>}
              </div>
             
            </div>
            {moveMode && selectedDeco && selectedDeco?.name != "move" && <div className={` `}>
            
                  
              <div className="flex  grow w-full justify-end">
                {selectedDeco.price && <div 
                onClick={() => {
                  //sup
                  setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png"})}
                }
                 className="bg-white/10 border border-b-2 py-1 border-black/50 px-3 rounded-xl  text-white/80 flex gap-1 items-center text-sm mt-1 pr-1 ">Supprimer <span className="px-2 bg-black/20 rounded-xl flex gap-1"> + {selectedDeco.price || 0} <img src="/images/UX/coins.png" className="h-5"/></span> </div>}
              </div>
          
  
        </div>}
            </div>}


          {((hoverCard || hoverDeck || hoverContent || packCards) && !dragging && !showAlchimie && popoverPos &&  !ct.showDeckId) && 
          <Popover 
            userCards={_userCards} 
            card={hoverCard} 
            packCards={packCards}
            content={hoverContent}
            showElixir={true}
            deck_id={hoverDeck?.deck_id} 
            setShowDeckId={(e) => ct.setShowDeckId(e)} 
            pos={popoverPos} 
            cursor={popoverPos?.cursor} 
            setPopoverPos={setPopoverPos} 
            showAction={true} 
          
            context={ct}
            />}
    
       <canvas 
          ref={canvasRef}
          className={`${disco && "disco"} no-last-time bg-water p-4 top-0 sb left-0 cursor-pointer`}
          id="hexgrid" 

          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}

          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
         
          
          ></canvas>

          <div className="fixed top-2 fredoka left-0 right-0 flex gap-2 justify-between  p-2">
        
          {!moveMode && <div onClick={() => (ct.setShowMenu(true))} className={`text-shadow text-amber-50 px-3 py-1 flex items-center gap-2 rounded-md relative font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
                <img className='h-5 w-5' src={'/images/UX/menu.svg'}/>
            </div>}
            {!moveMode && <div className="flex gap-2">
            <div  className={`text-shadow text-amber-50 px-3 py-1 flex items-center  gap-2 rounded-md relative font-bold bg-blur `} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
                <div>{ct.userWorkspace?.coins || 0}</div>
                
                <img onClick={() => setShowAnimation(prev => !prev)} className='h-5 w-5' src={'/images/UX/coins.png'}/>
            </div>
           

            <div onClick={() => {
              ct.setShowInfo({title: "Les élixirs d'esprit",  classContent: "bg-[#5b00b1] text-white", content: <div>
              {/* <div>Qu'est-ce que c'est ?</div> */}
              <p className="text-sm">Tes graines de souvenirs, une fois arrivées à maturité (dans ta mémoire à long terme), produisent de l'élixir d'esprit..</p>
                <div className="p-2 text-sm my-2 rounded-xl bg-black/10 flex gap-2">Juste avant d'évoluer, les graines libèrent leur élixir
                <img className="h-10" src="/images/deco/elixir_icon.png"/>
                </div>

          
               <p className="text-sm mt-1"> En le récoltant, cela te permet de renforcer ton souvenir et t'aide à mémoriser la position de ces derniers.
                Ils te permettront de faire évoluer ton palais mental de bien des manières !</p>
              </div>})
            }} className={`text-shadow text-amber-50 px-3 py-1 flex items-center  gap-2 rounded-md relative font-bold bg-blur `} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
              <div>{ct.userWorkspace?.elixir || 0}</div>
              <img onClick={() => setShowAnimation( prev => !prev)} className='h-5 w-5' src={'/images/deco/elixir.png'}/>

            </div>

            <div onClick={() => console.log('ct user', ct.user)} className={`text-shadow text-amber-50 px-3 py-1 flex items-center  gap-2 rounded-md relative font-bold bg-blur `} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
              <div>{formatDate(new Date()) == ct.user.lds ? ct.user?.streak || 0 :  0 }</div>
              <svg width="16" height="22" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.9795 1.66653C10.9795 0.504426 9.48291 0.0664372 8.82373 1.02982C2.77637 9.86917 11.3701 10.2671 11.3701 14.564C11.3701 16.3037 9.94873 17.7115 8.20361 17.6885C6.48633 17.6665 5.12012 16.2349 5.12012 14.5176V10.3423C5.12012 9.28275 3.82764 8.76859 3.09717 9.53665C1.79004 10.9097 0.432617 13.2618 0.432617 16.1265C0.432617 21.2959 4.63818 25.5015 9.80762 25.5015C14.9771 25.5015 19.1826 21.2959 19.1826 16.1265C19.1826 7.81155 10.9795 6.70267 10.9795 1.66653Z" fill="#FF792E"/>
                </svg>
            </div>
            </div>}
            <div className="bg-black/10 absolute top-[50px] right-[10px] text-white rounded h-[30px] w-[36px] flex items-center justify-center"> <SoundToggleButton /></div>
            <div onClick={() => setShowAnimation(prev => !prev)} className="bg-black/10 absolute top-[90px] right-[10px] text-white rounded h-[30px] w-[36px] flex items-center justify-center">
            {!showAnimation ? 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" /></svg> 
            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
          </svg>
          }
</div>

          </div>
          {!visitedUWP && !moveMode && !talkMode && <div className="fixed top-[60px] left-2" >
            <div onClick={() => ct.setShowPop('mailbox')} className="py-[5px] px-[10px] rounded-md bg-black/40">
              {myEventNoRead?.length > 0 && <div className="bg-red-500 absolute heartbeat-sm -top-1 -right-1 h-[12px] w-[12px] rounded-full"></div>}
              <img src="images/deco/logo_letter.png" className="h-6 w-6" />
            </div>


            <div onClick={() => props.setShowBug(true)} className="py-[7px] px-[10px] flex justify-center items-center mt-2 text-white rounded-md bg-black/40"><img className="h-6" src="/images/deco/bug.png"/></div>
            <div onClick={() => setShowSeed(true)} className="py-[7px] px-[10px] flex justify-center items-center mt-2 text-white rounded-md bg-black/40"><img className="h-7 w-6" src="/images/UX/seed_hand.png"/></div>
            <div onClick={() => {
                        setSelectedDeco( { "name": "move", "fr": "Déplacer", "deco": true, "edition": true, "move": true, "src": "/images/deco/move_icon.png"})
                        setMoveMode(true)
                  }} className="py-[7px] flex justify-center items-center mt-2 text-white rounded-md bg-black/40"><img src={'/images/UX/move_icon.png'} className="h-5 scale-[1.7] my-1"/></div>

                  </div>}
            {guests.length > 0 && !talkMode && !moveMode && <div className="fixed top-[237px] left-2" > <div onClick={() => {
                       setTalkMode(prev => !prev)
                  }} className="py-[7px] w-[44px] flex justify-center items-center mt-2 relative text-white rounded-md bg-black/40">💬 <span className="bg-slate-600 px-1 absolute -right-1 -top-1 rounded-full text-xs">{guests.length}</span></div></div>}

          {visitedUWP && <div className="fixed top-0 left-0 text-white bg-indigo-600 right-0 p-3  gap-3 justify-between">
            <div className="flex gap-2 fredoka mb-1">
              {visitedUWP?.user_photo && <img src={visitedUWP?.user_photo} className="h-12 w-12 rounded-full object-cover" />}
              <div onClick={async() => {

                 const user = (await getDoc(doc(db, "users", visitedUWP.user_id))).data()
                 console.log('user', user)
                
              }}>
                
                <div className="opacity-[0.8] text-sm ">Forêt de</div>
                <div className="capitalize  text-xl -mt-1">{visitedUWP?.user_name}</div>
                {ct.user.admin && <div className="px-2 rounded-full bg-white/10 mt-2" onClick={async() => {
                    const user = (await getDoc(doc(db, "users", visitedUWP.user_id))).data()
              ct.setUser(user)
            }}>Impersonnate</div>}
              </div>
             
             
            </div>
            <div className="flex gap-2">
                <div 
                  onClick={() => {
                    ct.setShowInfo({icon: <img src="images/deco/logo_letter.png" className="w-6" />, title: "Ecrire un message",  classContent: "bg-[#5b00b1] text-white", 
                      content: <div onClick={(e) => e.stopPropagation()}>
                        <textarea id='msg-id' placeholder="Ecris ton message ici" className="border placeholder:italic placeholder:text-white/50 border-black/50 min-h-[100px] bg-white/20 rounded-xl p-2 w-full"></textarea>
                        <div className="flex justify-end">
                          <div onClick={() => {
                            if (document.getElementById('msg-id')?.value?.length > 1) 
                            {
                              const newEvent = {date: new Date(), box: true, target_user_id: visitedUWP?.user_id, user_name: ct.userWorkspace?.user_name, user_photo: ct.userWorkspace?.user_photo, type: "msg",  from: ct.userWorkspace?.id, target: visitedUWP?.id,  message: document.getElementById('msg-id')?.value || null}
                              addDoc(collection(db, "event"), newEvent);
                              ct.setAlerts([{title: "Message envoyé" }])

                            }

                            ct.setShowInfo()
                           

                          }} className="bg-white text-indigo-600 px-2 py-1 mt-2 rounded-xl" >Envoyer</div>
                        </div>
                        </div>
                    })}} 
              className="flex gap-2 rounded-xl border-2 border-b-4 px-3 py-1 items-center"><img src="images/deco/logo_letter.png" className="w-6" /></div>
                {todaysEvents?.length > 2 ? <div className="text-ledt text-xs grow ">Plus d'actions disponibles aujourd'hui, reviens demain !</div> : <div className="flex gap-2 grow">
                <div 
                    onClick={() => {
                      ct.setShowInfo({icon: <img src="images/deco/attack.png" className="w-6" />, title: "Piller le jardin",  classContent: "bg-[#5b00b1] text-white", 
                        content: <div onClick={(e) => e.stopPropagation()}>
                          <div className="bg-white/10 text-sm rounded my-2 px-2 py-1">Pille le jardin de {visitedUWP?.user_name} et obtiens 30 elixirs</div>
                          <textarea id='msg-steal' placeholder="Ajouter un message " className="border placeholder:italic placeholder:text-white/50 border-black/50 min-h-[40px] bg-white/20 rounded-xl p-2 w-full"></textarea>
                          <div className="flex justify-end">
                            <div onClick={() => {
                               const newEvent = {date: new Date(), box: true, target_user_id: visitedUWP?.user_id, user_name: ct.userWorkspace?.user_name, user_photo: ct.userWorkspace?.user_photo, type: "steal",  from: ct.userWorkspace?.id, target: visitedUWP?.id, elixir: 30, message: document.getElementById('msg-steal')?.value || null}
                               console.log('newEvent', newEvent)
                               updateDoc(doc(db, "user_workspace", ct.userWorkspace?.id), {elixir: (ct.userWorkspace?.elixir || 0) + 30});
                               ct.setUserWorkspace({...ct.userWorkspace, elixir: (ct.userWorkspace.elixir || 0) + 30})
                               ct.setShowInfo()
                               ct.setEvents([...ct.events || [], newEvent])
                               addDoc(collection(db, "event"), newEvent);
                               ct.setAlerts([{title: "Message envoyé" }])

                            }} className="bg-white text-indigo-600 px-3 border-2 border-b-4 py-1 mt-2 rounded-xl flex fredoka gap-2" >Piller <span className="flex gap-1 bg-indigo-500/20 px-1 rounded-xl">+ 30 <img className="h-5" src="/images/deco/elixir.png"/></span></div>
                          </div>
                          </div>
                      })}} 
                    className="flex gap-2 grow rounded-xl bg-white text-indigo-500 justify-center border-2 border-b-4 px-3 py-1"><img src="images/deco/attack.png" className="w-6" />Piller
                </div>
                <div 
                onClick={() => {
                  ct.setShowInfo({icon: <img src="images/deco/gift.png" className="w-6" />, title: "Envoyer un cadeau",  classContent: "bg-[#5b00b1] text-white", 
                    content: <div onClick={(e) => e.stopPropagation()}>
                      <div className="bg-white/10 text-sm rounded my-2 px-2 py-1">{visitedUWP?.user_name} obtiendra 30 elixirs grâce à toi. Tu en recevras 10.</div>
                      <textarea id="msg-gift" placeholder="Ajouter un message " className="border placeholder:italic placeholder:text-white/50 border-black/50 min-h-[40px] bg-white/20 rounded-xl p-2 w-full"></textarea>
                      
                      <div className="flex justify-end">
                        <div onClick={() => {
                          const newEvent = {date: new Date(), box: true, target_user_id: visitedUWP?.user_id, user_name: ct.userWorkspace?.user_name, user_photo: ct.userWorkspace?.user_photo, type: "gift",  from: ct.userWorkspace?.id, target: visitedUWP?.id, elixir: 30, message: document.getElementById('msg-gift')?.value}
                        
                          updateDoc(doc(db, "user_workspace", ct.userWorkspace?.id), {elixir: (ct.userWorkspace?.elixir || 0) + 10});
                          ct.setUserWorkspace({...ct.userWorkspace, elixir: (ct.userWorkspace.elixir || 0) + 10})
                          ct.setShowInfo()
                          ct.setEvents([...ct.events || [], newEvent])
                          addDoc(collection(db, "event"), newEvent);
                          ct.setAlerts([{title: "Message envoyé" }])
                         
                          }} className="bg-white text-indigo-600 px-3 border-2 border-b-4 py-1 mt-2 rounded-xl flex fredoka gap-2" >Envoyer le cadeau <span className="flex gap-1 bg-indigo-500/20 px-1 rounded-xl">+ 10 <img className="h-6" src="/images/deco/elixir.png"/></span></div>
                      </div>
                      </div>
                  })}} 
               className="flex gap-2 grow rounded-xl bg-white text-indigo-500 justify-center border-2 border-b-4 px-1 py-1"><img src="images/deco/gift.png" className="w-5" />Faire un cadeau
               </div>
               </div>}
              </div>
              {3 - (todaysEvents?.length || 0) !== 0 ? <div className="w-full  text-xs text-right mt-1">Il te reste {3 - (todaysEvents?.length || 0)}/3 actions aujourd'hui</div> : <div></div>}
            <Link to="/leaderboard">
            <div class="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>

            </Link>
           
          </div> }
        


           {(!selectedDeck && decorationMode && !selectedCard && !selectedDeco ) && !moveMode  &&  <div  className={`fredoka bg-gradient-to-b  ${decorationMode == "seeds" ? 'from-indigo-800  to-indigo-900 border-indigo-600' :  'from-indigo-800  to-indigo-900 border-green-600'}   z-20  transition fixed bottom-[50px] top-0 left-0 right-0  p-4`} 
           style={{
            backgroundColor: "#2b267d",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.06'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
           }}
           >
            <div className="flex z-10 gap-2 fixed">
              <div  className={`text-shadow text-amber-50 px-3 py-1 flex items-center   gap-2 rounded-md  font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
                  <div>{ct.userWorkspace?.coins || 0}</div>
                  
                <img className='h-5 w-5' src={'/images/UX/coins.png'}/>
              </div>
               <div className={`text-shadow text-amber-50 px-3 py-1 flex items-center   gap-2 rounded-md  font-bold text-xl`} style={{backgroundColor: "rgba(0,0,0,0.45)"}}>
                <div>{ct.userWorkspace?.elixir || 0}</div>
                <img className='h-5 w-5' src={'/images/deco/elixir.png'}/>

              </div>
              
            </div>
            
          {/* <div className="mb-4 mt-8">
             <div className="flex gap-2 mb-2">{decorationModes.map(e => <div onClick={() => setDecorationMode(e.name)} className={`${e.name == decorationMode ? "bg-black/50" : ""} rounded-xl px-2 relative text-shadow !text-center  mt-8 !text-md text-slate-100`}>{e.title}
             { Math.ceil(maxPack) > 0 && e.name =="seeds" && <div className={"px-2 absolute bg-red-500 text-xs text-white rounded-full  -top-1 -right-2"}>{Math.ceil(maxPack)}</div>}

             </div>)}

             </div>
            <h3 className=''>{decorationModes.find(el => el.name == decorationMode).title}</h3> 
            <div className='text-white text-xs pl-2'>{decorationModes.find(el => el.name == decorationMode).description}</div>
          </div> */}
     
         
          { decorationMode == "deco" && 
          <div className=" max-h-screen text-white pb-[300px] pt-[100px] overflow-scroll">
         
         
          {maxPack > 0 && <div className="p-3 mb-4 rounded-xl bg-black/20">
          <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Graine de connaissance</div> </div>
          <div className="text-xs mb-2 opacity-70">Ajoute tes connaissances dans ton palais pour voir temps réel l'état de ta mémoire</div>
          <div>
             {maxPack > 0 ? <div onClick={() => {
                  setSelectedDeco({type: "pc", uwp: ct.userWorkspace?.id, n: missingN(packed_seeds)[0] })
                }}
                className={`${maxPack > 0 ?  "bg-gradient-to-b  from-indigo-500  to-blue-500" : " opacity-60 bg-indigo-800 "} border-black/90 rounded-xl border-2 border-b-4 col-span-3 md:col-span-5 text-white   lg:col-span-8 overflow-hidden relative fredoka text-shadow flex items-center justify-between gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center border-2 my-4 p-1 rounded overflow-visible `}>
                          <div className="text-left font-base">Planter des graines<div className="text-left text-xs nunito">Pour muscler ta mémoire et ne rien oublier</div> </div>
                          <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[36px] h-[120px] " />
                          <img src="/images/seeds/green-2.png" className="absolute  opacity-[70%] right-[-10px] -top-[6px] h-[60px] " />
                          <img src="/images/seeds/blue-2.png" className="absolute  opacity-[70%] right-[40px] -top-[12px] h-[80px] " />
                          {maxPack >  0 ? <div className="text-shadow absolute -top-2 right-2 self-end capitalize text-[12px] items-center flex gap-[4px] bg-red-500 rounded-xl px-[8px] py-[1px]"><span className="text-[8px]">x</span> {Math.ceil(maxPack)} </div> : ''}
              </div> : <div className="text-sm opacity-[0.7] italic my-2 text-center">Aucune plante disponible pour le moment</div>}

                        
                    {/* <div onClick={() => {
                      packed_seeds.forEach(o => o.id && ct.fire.remove(o.id, "user_workspace_element"))
                        setUserDecoration(() => userDecoration.filter(ud => ud.type != "pc"))
                        }} className="p-2 text-center text-sm rounded-xl opacity-[0.7] text-white px-4 py-1">Replacer toutes mes graines <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-4 h-4 inline-block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                  </div> */}
              </div>
              </div>}

              <div className="p-3 mb-4 rounded-xl bg-black/20">
          <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Décoration</div> </div>
          {/* <div className="mt-2">Disponible</div> */}
          <div className="text-xs mb-2 opacity-70">Personnalise ton palais mental pour le rendre encore plus mémorable</div>
          <div className='grid grid-cols-4   md:grid-cols-5 lg:grid-cols-8 items-center gap-2 touch-scroll text-white mb-2  '>  
            {imageWithData.filter(t => !t.edition && t.isPurchasable && !t.admin && !t.terra).map(t => renderDeco(t))}
          </div>
          <br/>
          {/* <div className="mt-2">A débloquer</div> */}
          {/* <div className="text-xs mb-2 opacity-70">Utilise tes elixirs d'esprit pour déverrouiller de nouvelle décoration</div> */}
         
         
          </div>

          <div className="p-3 mb-4 rounded-xl bg-black/20">
          <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Terraformation {0 - usedBase} </div></div>
          <div className="text-xs mb-2 opacity-70">Change la forme de ton île</div>

          <div className='grid grid-cols-4   md:grid-cols-5 lg:grid-cols-8 items-center gap-2 touch-scroll text-white mb-2  '>  
          {imageWithData.filter(t => !t.edition && t.isPurchasable && !t.admin && t.terra)
          .map(t => t.name == "base" ? {...t, nb: 0 - usedBase} : t)
          .map(t => t.name == "water" ? {...t, src: "/images/deco/water-top.png"} : t)
          .filter(t => !(t.nb != null && t.nb <= 0))
          .map(t => renderDeco(t))}
          </div>
          
          </div>

          <div className="p-3 mb-4 rounded-xl bg-black/20">
          <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Décoration à débloquer</div></div>
          <div className="text-xs mb-2 opacity-70">Débloque de nouvelles décorations grâce aux élixirs.</div>

          <div className='grid grid-cols-4   md:grid-cols-5 lg:grid-cols-8 items-center gap-2 touch-scroll text-white mb-2  '>  
            {imageWithData.filter(t => !t.edition && !t.isPurchasable && !t.admin).map(t => renderDeco(t))}
          </div>
          
          </div>

  
          
          <div className="p-3 mb-4 rounded-xl bg-black/20">
          <div className="flex -mt-5 -ml-3 mb-2"><div className=" bg-[#3f39a5] px-3 rounded-xl ">Utilitaire</div> </div>
          
          {
            !hasHouse && <div onClick={() => {
              const image = images.find(e => e.name =="house_0")
              const newDeco = {uwp: ct.userWorkspace?.id, name: "house_0" }
              console.log('new Deco')
              setSelectedDeco(newDeco)
            }} className="bg-gradient-to-b from-indigo-500  to-blue-500  text-white   lg:col-span-8 relative fredoka text-shadow flex items-center gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center border-2 border-b-4 border-black/90  p-1 rounded-xl">
              <img className="h-[80px]" src="/images/seeds/house/house_0.png"/>
              Maison du savoir</div>
          }
          <div onClick={() => {
             const newCoins = (ct.userWorkspace?.coins || 0) - priceExtension[lvlExtension].gold
             const newElixir = (ct.userWorkspace?.elixir || 0) - priceExtension[lvlExtension].elixir
          
              if (territory.enoughCoin) {
                if (territory.enoughSeeds) {
                  if (ct.userWorkspace?.extLvl < 4 || !ct.userWorkspace?.extLvl ) {
                  ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: newCoins, elixir: newElixir, extLvl: (lvlExtension) + 1}, ct)
                   } else (ct.setUserWorkspace({...ct.userWorkspace, extLvl: 0}))
                } else {
                  ct.setAlerts([{title: "Tu n'as pas planté assez de graine :" + `${packed_seeds.length}/${territory.neededPack}` }])

                }
              
              } else {
                ct.setAlerts([{title: "Tu n'as pas assez d'argent pour agrandir ton palais" }])

              }
             
            //dev
             }} className={`${territory.canUpgrade ?  "bg-gradient-to-b from-indigo-500  to-blue-500" : " border-purple-800 opacity-60 bg-indigo-800"} col-span-3 md:col-span-5 text-white   lg:col-span-8 relative fredoka text-shadow flex items-center justify-between gap-4 px-3 font-bold text-[18px] h-[70px] fond-bold text-center  border-2 border-b-4 border-black/90  p-1 rounded-xl `}> 
             {territory.canUpgrade &&<div className="h-4 w-4 heartbeat-sm bg-red-500 rounded-full absolute -top-2 -right-2"></div>}
            <div>Extension du territoire<div className="text-left text-xs ">niv {ct.userWorkspace?.extLvl || 0} → niv {(ct.userWorkspace?.extLvl || 0) +1}</div> </div>
            <img src="/images/deco/upgrade_extension.png" className=" absolute opacity-[90%] right-[10px] -top-[8px] h-[60px] " />
            <div className="absolute bottom-2 flex right-2">
              <div className="text-shadow   self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{priceExtension[lvlExtension].elixir} <img className='h-[12px] w-[12px]' src={'/images/deco/elixir.png'}/></div>
              <div className="text-shadow   self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{priceExtension[lvlExtension].gold} <img className='h-[12px] w-[12px]' src={'/images/UX/coins.png'}/></div>
              {!territory.enoughSeeds && <div onClick={() => console.log('__usercard', _userCards)} className="text-shadow  self-end capitalize text-[12px] items-center flex gap-[4px] bg-black/20 rounded-xl px-[4px] py-[1px]">{packed_seeds?.length} + {_userCards.filter(e => !e.archived).length}/{priceExtension[lvlExtension].packed} <img className='h-[12px] scale-[3] -mt-2 w-[12px]' src={'/images/seeds/blue-2.png'}/></div>}
            </div>

          </div>
          </div>
          </div>
            }
           
             
           
            
           
            {/* {decorationMode == "seeds" && userCards?.filter(uc => !uc.x && uc.x != 0).map(uc => <div onClick={() => setSelectedCard(uc)} className={`${selectedCard?.id == uc.id ? "border-4 border-yellow-500" : ""}  bg-white w-[80px] rounded b border-2`}>
              <img src={uc.card?.img_400 || uc.card?.img } className="w-full h-[60px]"/>
              <div className="p-2 text-slate-600">{uc.card?.term} {uc.x}</div>
              </div>)} */}

              

            { decorationMode == "decks" && userDecks?.filter(ut => !ut.x && ut.x != 0).map(ut => <div onClick={() => setSelectedDeck(ut)} className={`${selectedDeck?.id == ut.id ? "border-4 border-yellow-500" : ""} text-center w-[160px] rounded b`}>
            <img src={ut.image?.src} className="w-full h-[60px]"/>
            <div className="p-2 bg-white/30 mt-2 rounded-full px-4 py-1 text-white ">{ut.deck?.name} {ut.x}</div>
            </div>)}

            
              
              <div className="mt-8">
                {/* <div className="p-4 mx-auto text-center  rounded-xl border-2 border-whiter/30 py-1 text-white" onClick={() => resetForest()}>Reset la forêt</div> */}
                {/* <div className="p-4 mx-auto text-center  rounded-xl border-2 border-white/10 py-1 text-white/60 lg:hover:scale-125" onClick={() => initForestObj(ct.userWorkspace?.id)}>Réinitialiser la forêt!</div> */}

              </div>
            <div className='mb-4 flex gap-4 items-center justify-between'>
          
              <div className='flex items-center gap-4'>
                <div>
             </div>

            {/* <div className='flex gap-3 rounded-xl px-3 py-1 items-center font-semibold text-white' style={{backgroundColor: 'rgba(0,0,0,0.4)'}}>{money} <img className='h-5 w-5' src={'/images/UX/coins.png'}/></div> */}
            </div>

            <div onClick={() => {setDecorationMode(!decorationMode); setSelectedDeco()}}   className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
           
            </div>

           

           
            {selectedDeco?.id == "deco" && <div className='flex gap-4 !bg-red-400'>
            
            {images.filter(el => el.deco).reverse().map(d => <div onClick={() => {(money - d.price > 0  || d.price == 0) && setSelectedDeco(d); document.body.style.cursor = `url('/${images.find(i => i.name == d.img).src}')` ;
}} className={`${decorationMode?.name == d.name ? "text-yellow-300" : "text-gray-200"} relative`}>
              <img src={images.find(i => i.name == d.img).src} className={` ${decorationMode?.name == d.name ? "border-yellow-200 scale-115 mb-1 border-4 -rotate-3" : (money - d.price > 0  || d.price == 0) ? " border-yellow-500" : "border-gray-500" } h-[80px] mb-1 bg-slate-800  border-2  rounded h-24 transition cursor-pointer lg:hover:scale-110 lg:hover:rotate-6`}/>
              {d?.displayName}
              <div>
              {d.price ? <div className='absolute  top-1 right-1 flex justify-end gap-2 rounded-xl px-2  items-center font-semibold text-white' style={{backgroundColor: 'rgba(0,0,0,0.45)'}}>{d.price} <img className='h-5 w-5' src={'/images/UX/coins.png'}/></div> : ""}
              </div>
              </div>)}
            </div>}
            {/* {selectedDeco.id == "plant" && <div className='flex gap-4'>
            <div className='flex gap-2 flex-wrap'>{cardBadPosition?.map(c => <div onClick={() => setEditorMode({card: c})} className='text-sm b bg-white rounded px-2 py-1'>{getTitle(c?.original_card.term)}</div>)}</div>
           <div className='flex gap-2 flex-wrap'>{cardToPlace?.map(c => <div onClick={() => setEditorMode({card: c})} className='text-sm b bg-white rounded px-2 py-1'>{getTitle(c.original_card.term)}</div>)}</div> 
          </div>} */}
          </div>}
            {(selectedCard || selectedDeck || selectedDeco) &&  selectedDeco?.name != "move" && !moveMode && <div  className={`absolute bg-white text-slate-500 flex items-center gap-2 ${topX == 50 ? "bottom-[130px]": "bottom-[80px]" }  left-2 right-2 rounded-xl p-2`}>
            
             
                <img src={selectedDeck?.image?.src || selectedCard?.card?.img || selectedDeco?.src || images?.find(i => i.name == selectedDeco?.name)?.src || "images/seeds/green-2.png"} className="h-[50px] w-[50px] rounded-md" />
                <div className="fredoka text-green-500 text-xl">
                  {moveMode && selectedDeco?.name != "move" ? <div className="flex -ml-16"><div className="flex bg-purple-100 text-purple-500 items-center text-sm gap-2 right-2 px-2 rounded-xl py-1 -mt-4"><img className="h-5" src="/images/deco/move_icon.png"/> Déplacer</div></div>: ""}
                {getTitle(selectedCard?.card?.term || null) || null}
               {selectedDeck?.deck?.name}
                 <div className="text-base font-[400] text-purple-500">{selectedDeco?.title}</div>
                {selectedDeco?.type == "pc" ? <div>Pack de graines  <span className="text-xs opacity-50 ">#{missingN(packed_seeds)[0]}</span></div>: selectedDeco?.fr }
                {selectedDeco?.type == "pc" && !selectedDeco?.bc_id && Math.ceil(maxPack) > 1 && <div className={"px-2 bg-red-500 text-white rounded-full absolute -top-1 -left-1"}>x {Math.ceil(maxPack)}</div>}
              
              <p className="text-sm italic text-slate-400">{  selectedDeco?.cons  || "Appuie sur une case libre pour placer l'élément"}</p>
              </div>
              <svg onClick={() => {ct.setSelectedPack(); setSelectedCard(); setSelectedDeco(); setMoveMode(); setDecorationMode()}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 self-start mr-2 mt-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

            </div>} 

          

            {!visitedUWP && (!selectedDeck && !selectedCard && !selectedDeco && !talkMode) &&<div className={`fixed h-1 z-10 ${ct.pb ? "bottom-[70px]" : "bottom-[60px]"}  left-0 right-0 text-center gap-2 flex justify-between p-2 items-end`}>
              <div id="left" className="w-[84px]">
              <div onClick={() => {setShowAlchimie(true)}} className='bg-yellow-400 mb-1  w-full lg:hover:bg-yellow-500 cursor-pointer border-b-4 border-2 border-black/50 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3'>
                 <div className="relative w-full flex justify-center max-h-[42px]"> <img src={'/images/deco/alchimie_logo.png'} className=" -top-4 z-10 relative  h-[70px] w-[70px] object-contain mx-auto " /></div>
                  <div className='px-2  py-1 text-[13px] text-white border-1 text-shadow font-semibold fredoka rounded-b-xl' style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>Alchimie</div>
                  <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
               </div>

                <div onClick={() => {setDecorationMode("deco"); setPopoverPos()}} className='bg-yellow-400   w-full lg:hover:bg-yellow-500 cursor-pointer border-b-4 border-2 border-black/50 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3'>
                 <div className="h-20 relative max-h-[42px]"> <img src={'/images/UX/deco_logo2.png'} className="absolute -top-4 z-10 relative -left-2 ml-2 h-[70px] pointer-events-none	 scale-[1]  w-[90px] object-contain mx-auto " /></div>
                  <div className='px-2  py-1 text-[13px] text-white border-1 text-shadow font-semibold fredoka rounded-b-xl' style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>Décoration</div>
                  <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
                  {maxPack >0  ? <div className='absolute heartbeat-sm -top-2 heartbeat-sm  bg-red-500 border-2 pulse-in border-red-600  -right-2 h-6 w-6 rounded-full text-white text-sm '>{Math.ceil(maxPack)}</div> : ""}
                  {territory.canUpgrade && !maxPack >0 ? <div className='absolute heartbeat-sm heartbeat-sm -top-2 bg-red-500 border-2 pulse-in border-red-600  -right-2 h-4 w-4 rounded-full text-white text-sm '></div> : ""} 
                </div>
    

                {/* {ct.userDecks?.filter(ud => !ud.x && ud.x != 0).length ? <div onClick={() => setDecorationMode("decks")} className='bg-amber-400 w-full lg:hover:bg-purple-500 cursor-pointer border-2 border-slate-700 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3  mt-4'>
                  <img src={'/images/seeds/green-4.svg'}  className="z-10 relative h-20  mx-auto -mt-[40px] " />
                  <div className='px-2  py-1 text-[12px] text-white border-1 text-shadow font-semibold fredoka rounded-b-xl' style={{backgroundColor: 'rgba(0,0,0,0.2)'}}>Pack de graine</div>
                  <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
                  <div className='absolute -top-2 bg-red-500 border-2 pulse-in border-red-600  -right-2 h-6 w-6 rounded-full text-white text-sm '>{ct.userDecks?.filter(ut => !ut.x && ut.x != 0).length}</div>
                </div> : <div></div>} */}

              {/* {userCards?.filter(uc => !uc.x && uc.x != 0).length ? <div onClick={() => setDecorationMode("seeds")} className='bg-purple-400 w-full lg:hover:bg-purple-500 cursor-pointer border-2 border-slate-700 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3  mt-4'>
                  <img src={'/images/seeds/green-4.svg'}  className="z-10 relative  h-20  mx-auto -mt-[40px] " />
                  <div className='px-2  py-1 text-white border-1 text-shadow font-semibold fredoka rounded-b-xl' style={{backgroundColor: 'rgba(0,0,0,0.2)'}}>Graines</div>
                  <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
                  <div className='absolute -top-2 bg-red-500 border-2 pulse-in border-red-600 -right-2 h-6 w-6 rounded-full text-white text-sm '>{userCards?.filter(uc => !uc.x && uc.x != 0).length}</div>
                </div> : <div></div>} */}

                {/* <div onClick={() => setDecorationMode(true)} className='bg-yellow-400 w-24 lg:hover:bg-yellow-500 cursor-pointer border-2 border-slate-700 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3 mb-4'>
                <img src={'https://cdn.glitch.global/05a95c69-798c-40bd-af28-39b03ba04135/Group%203507.svg?v=1678989683447'} className="z-10 relative h-24 mx-auto -mt-8" />
                <div className='px-1  py-1 text-sm text-white border-1 text-shadow font-semibold fredoka rounded-b-xl' style={{backgroundColor: 'rgba(0,0,0,0.2)', fontSize: 13}}>Arbre monde</div>
                <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
                <div className='absolute -top-2 bg-red-500 border-2 pulse-in border-red-600  -right-2 h-6 w-6 rounded-full text-white text-sm '></div>
                </div> */}
              </div>
            

              
              {(!selectedDeck && !selectedCard && deckData?.nextCards?.length) 
              ? 
              <div className="relative">


       

                {userCardFast?.length > 15 && 
                <div className="z-10 relative text-center rounded-md border-2 overflow-visible justify-between flex border-b-4 border-[#20254c]/60 bg-[#3fc796]  mb-1 text-shadow text-white font-bold fredoka b" onClick={() => {
                  
                  ct.setFastCards(userCardFast)
                }}>
                   <div className="h-[4px] absolute bg-white/20 rounded-full top-[2px] left-1 right-1"></div>
                  <div className="px-3 py-3 text-sm w-full  text-center">Révision rapide</div>
                  <div className="bg-black/10 w-[30px] rounded-r text-center flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                    </svg>

                    {/* <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /> */}
                  </svg>
                  </div>
                  </div>}
               

               {/* const date = user.last_connexion
               last: {getDate(ct.user.last_connexion).toDateString()}
               last dm: {getDate(ct.user?.last_dm)?.toDateString()}
               meme date: {getDate(ct.user.last_connexion).toDateString() == getDate(ct.user?.last_dm)?.toDateString() ? "oui" : "non"} */}

                {deckData.nextCards?.length > 9 && deckData.cardToLearn.length > 4 && !(dateOnly?.toDateString() == getDate(ct.userWorkspace?.last_dm)?.toDateString()) ? <div className="z-10 relative text-center rounded-md border-2 overflow-visible justify-between flex border-b-4 border-[#20254c]/70 bg-[#f1c517]  mb-1 text-shadow text-white font-bold fredoka b" onClick={() => {
                
               
                   ct.setUserWorkspace(u => ({...u, last_dm: dateOnly }))
                   updateDoc(doc(db, "user_workspace", ct.userWorkspace.id), {last_dm: dateOnly});
       
                  ct.setLessonOfTheDay(1)
                  ct.setShowCards(deckData.cardToLearn)
                  // const realCardsToLearn = getDeckData(ct, ct.nextCards?.slice(0,9), {nbCardToLearn: 4})
                  // console.log('next card to learn', ct.nextCards?.map(c => c.term))
                  // console.log('realCardsToLearn', realCardsToLearn)
                  // ct.setShowCards(realCardsToLearn.nextCards)
                }}>
                   <div className="h-[4px] absolute bg-white/20 rounded-full top-[2px] left-1 right-1"></div>
                  <div className="px-3 py-3 text-sm w-full  text-center">Leçon du jour</div>
                  <div className="bg-black/10 w-[30px] rounded-r text-center flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                  </svg>


                    {/* <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /> */}
                  </svg>
                  </div>
        </div>: <div onClick={async() => {
    



                      setLoadingMAJCards(true)
                      const cardToLearn = deckData.nextCards
                      const idsToLearnArray = [...new Set(cardToLearn.map(a => a.id))];
                      const updatedCard = await getCardsFromIDS(idsToLearnArray)
                                

                      const updatedCardToLearn = cardToLearn.map(e => {
                          const card = updatedCard.find(c => c.id === e.id); // Utiliser '===' pour la comparaison
                          return { ...card || e, user_card: e.user_card }; // Retourne la nouvelle carte mise à jour avec les informations de 'user_card'
                      });
                      setLoadingMAJCards(false)
                    
                     


                     ct.setShowCards(updatedCardToLearn)
                     deckData.nextCards.length ==0 && navigate('/home')

                  }} className="z-10 relative  rounded-md border-2 overflow-visible flex border-b-4 border-[#20254c] bg-[#6360ff] text-shadow text-white font-bold fredoka b">
                  <div className="h-[4px] absolute bg-white/20 rounded-full top-[2px] left-1 right-1"></div>
                  <div className="px-3 py-3 text-sm">{deckData?.nextCards?.length > 0 ? "Rafraîchir " +  deckData.nextCards?.length + " souvenir" + (deckData?.nextCards?.length > 1 ? "s" :"") : "Apprendre"} {loadingMAJCards && "..."}</div>
                  <div className="bg-[#7b8bff] w-[30px] rounded-r text-center flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                    {/* <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /> */}
                  </svg>
                  </div>
                  {/* { <div className="p-[5px] rounded-full bg-red-400 animate-pulse -top-1 -right-1 absolute"></div>}   */}
                  </div> }

                  {!userCardFast?.length > 30 && <div className="h-[18px]  fredoka text-white/80 font-bold text-[10px] pb-[2px] z-0 absolute bg-black rounded-t-md top-[-14px] left-0 right-0">
                {/* {allMasteredCards != _userCards?.length ? <><span className="text-green-300">{allMasteredCards}</span>/<span className="">{_userCards?.length}</span> </>  */}
                : 
                   <span className="text-green-200 text-[10px]">Nouvelle graine disponible !</span>}
                </div>}
              
              
                </div>: ct.nextCards?.filter(c => c.term).length && (!noMoreCard) ? 
                <div onClick={() => {
                  const realCardsToLearn = getDeckData(ct, ct.nextCards?.slice(0,9), {nbCardToLearn: 4})
                  console.log('next card to learn', ct.nextCards?.map(c => c.term))
                  console.log('realCardsToLearn', realCardsToLearn)
                  ct.setShowCards(realCardsToLearn.nextCards)}
                  } className="z-10 relative grow text-center  py-1  justify-center items-center h-[64px] rounded-xl border-2 overflow-visible  border-b-4 border-black/70 bg-[#0be08c] text-shadow text-white font-bold fredoka b">
                <div className="h-[8px] absolute bg-white/20 rounded-full top-[2px] left-1 right-0"></div>
                <div className="h-[9px] absolute bg-black/10 rounded-md bottom-[0px] left-0 right-0"></div>
                <div>Nouvelle leçon </div>
                <div className="flex justify-center mt-[2px]"><div className="bg-black/10  text-xs px-2 rounded-full ">+{ct.nextCards?.slice(0,4)?.length*3}xp</div></div>
                </div> : 
                <div></div>
                }
               
              <div id="right" className="w-[84px] ">
              <div onClick={() => {
                  setShowQuest(true);
                
                }} className='bg-yellow-400 w-full lg:hover:bg-yellow-500 cursor-pointer border-2 border-black/50 border-b-4 transition rounded-xl relative lg:hover:scale-110 lg:hover:-rotate-3'>
                  <img src={'/images/UX/treasure.png'} className="z-10 relative h-20 mx-auto -mt-8" />
                  <div className='px-2  py-1 text-[13px] text-white border-1 text-shadow font-semibold fredoka rounded-b' style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>Quêtes</div>
                  <div className='absolute top-1 bg-gradient-to-b from-white left-1 right-1 h-8 z-0 opacity-50 rounded-xl'></div>
                  {/* <div className='absolute -top-2 bg-red-500 border-2 pulse-in border-red-600  -right-2 h-6 w-6 rounded-full text-white text-sm '>3</div> */}
                  {showXP && <div className="xp-animation">+1xp</div>}
                </div>
              </div>
            </div>}

            
            <div className="absolute hidden bg-red-300 top-0 p-4">
              <div className="">{offX} - {offY} | {offX - startX} |  {gridX} - {gridY} | {dragging ? "yes" : "no"}</div>
            <div className="flex hidden gap-2">
            <div  onClick={() => setShowAnimation(prev => !prev)} className={`${showAnimation ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>animate</div>
            <div  onClick={() => setEditorMode(!editorMode)} className={`${editorMode ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>editor</div>
            <div  onClick={() => {setDisco(!disco);}} className={`${disco ? "bg-slate-900 text-white" : ""} px-2 rounded-xl py-1 b `}>disco</div>
            </div>
            </div>
            <Chronos ct={ct} type={"forest"}/>
            {showQuest && <Quest ct={ct} quit={() => setShowQuest(null)} />}
            

            {/* {showDeckId ? <DeckScreen mode={"forest"} close={() => setShowDeckId()} context={ct} deck_id={showDeckId}/> : <div/>} */}
           
     
            {showAlchimie && <AlchimieScreen 
            ct={ct}
            images={images}
            setShowAlchimie={setShowAlchimie}
            userDecoration={userDecoration}
            setUserDecoration={setUserDecoration}
            masteredCards={masteredCards} 
            packed_seeds={packed_seeds} 
            setSelectedDeco={setSelectedDeco} 
            selectedEvo={selectedEvo} 
            setSelectedEvo={setSelectedEvo} 
            evolutions={evolutions} 
            packed_seed={packed_seeds}
     />
    
  }

{talkMode && userOnScreen && !ct.showCards && <div className="fixed bottom-[0px]  z-[99] left-[0px] p-2 right-[0px] bg-slate-900/80 p-3">
  {/* {guests.map(g => <div>{g.name}: {g.chat}</div>)} */}
  <div className="flex gap-2 justify-end absolute right-2 z-20">
    <svg onClick={() => setTalkMode(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-left bg-black/50 rounded py-1 text-white mb-2 px-2 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg>
</div>  
  <div onClick={() => {console.log('msgs', msgs)}} className="text-white/80 fredoka text-xs pb-1  mr-[30px] overflow-scroll max-h-[100px]">
  {msgs.length && msgs.map((e, index) => (
        <div
          key={index}
          className="mb-1"
          ref={index === msgs.length - 1 ? lastMessageRef : null}
        >
          <span className="font-semibold text-white/90">{e.name?.split(' ')?.[0]}</span>: {e.chat}
        </div>
      ))}
  </div>
  <div></div>

  <div className="flex gap-2 py-2 text-xl overflow-x-auto whitespace-nowrap">{smileys.map(e => <div onClick={() => { updateDoc(doc(db, "users", ct.user.id), {chat: e, rmvc: new Date(new Date().getTime() + 3 * 1000)})}}>{e}</div>)}</div>


  <div className="relative">
  <textarea id='chatUser' className="rounded-xl bg-slate-900 border-white/30 h-[50px] w-full p-2 text-white border border-b-2 pr-12 "></textarea>
  <div id="send_btn" className="p-2 px-2 rounded-xl bg-purple-500/10 absolute right-2 top-2 text-white" onClick={() => {
    const chat = document.querySelector('#chatUser').value
    const input = document.querySelector('#chatUser')
    input.value = ""
    updateDoc(doc(db, "users", ct.user.id), {chat: chat, rmvc: new Date(new Date().getTime() + 5 * 1000)})

  }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
</svg>
</div>
  </div>
  
  </div>}


  {/* <div className="fixed bottom-[200px] text-xs right-[70px] bg-purple-500 p-4">
  

      <div>talkMode: {talkMode ? "oui" : "non"}</div>
      <div>On terrain deco: {userDecoration.find(g => g.avatar_id == ct.user.id) ? "oui" : "non"}</div>
      <div>userOnScreen: {userOnScreen ? "oui" : "non"}</div>


    </div> */}
    {talkMode && !userOnScreen ? <div className="fixed bottom-[0px] bg-black/80 text-white text-sm  left-[0px] right-[0px]  p-8">
     Clique sur une case vide pour faire apparaître ton avatar
    </div> : <div></div>}

  

  {/* <div className="fixed bottom-[200px] text-xs right-[70px] bg-purple-500 p-4">
    <div className="flex gap-2">lvl: {extLvl} -- {[0,1,2,3,4,5,6,7].map(e => <div onClick={() => ct.setUserWorkspace(prev => ({...prev, extLvl: e}))}>{e}</div>)}</div>
    <div>
      <div>selectedPack: {ct.selectedPack?.[0].term}</div>
      <div>Base: {bases.length}</div>
      <div>outerGround: {outerGround}</div>
      <div>usedBase: {usedBase}</div>
      <div>total: {availableBases}</div>
      <div>Avaitable: {availableBases}</div>
    </div>
    </div> */}
  {/* <div className="fixed bottom-[200px] text-xs right-[70px] bg-purple-500 p-4">{tileSize}</div> */}

      {/* <div className="fixed bottom-[200px] text-xs right-[70px] bg-purple-500 p-4 ">
        <div className="relative bg-red-400 p-2 rounded-xl h-[100px] w-[100px]">
          <div onClick={() => {
            setFuturSteps(myAvatarDeco.x, myAvatarDeco.y - 1, myAvatarDeco)
              //  setUserDecoration(prev => prev.map(d => d.avatar_id === ct.user.id ? {...d, x:myAvatarDeco.x, y: myAvatarDeco.y - 1 } : d))
          }} className="px-4 py-2 text-2xl absolute -top-2 left-5 rounded hover:scale-110 cursor-pointer">🔼</div>

          <div onClick={() => {
              setFuturSteps(myAvatarDeco.x + 1, myAvatarDeco.y, myAvatarDeco)
            // getTransitionForOneStep("right", userDecoration.find(d => d.avatar_id == ct.user.id))
          }} className="px-4 py-2 text-2xl absolute top-5 right-0 rounded hover:scale-110 cursor-pointer">➡️</div>
          <div onClick={() => {
               setFuturSteps(myAvatarDeco.x - 1, myAvatarDeco.y , myAvatarDeco)
            // getTransitionForOneStep("left", userDecoration.find(d => d.avatar_id == ct.user.id))
            }} className="px-4 py-2 text-2xl absolute top-5 left-0  rounded hover:scale-110 cursor-pointer">⬅️</div>

          <div onClick={() => {setFuturSteps(myAvatarDeco.x, myAvatarDeco.y + 1, myAvatarDeco)
            }} className="px-4 py-2 text-2xl absolute left-5 bottom-0 rounded hover:scale-110 cursor-pointer">⬇️</div>
        </div>
        <div onClick={() => {

          // setFuturSteps(0, 0, myAvatarDeco)

          }}>
  GO CENTER 

  <div onClick={() => {
    console.log('myAvatarObj', myAvatarObj)
    // setTotalDy(prev => prev+10)

    }}>MOVE CAM ({Math.round(offX)} / {Math.round(offY)}) -- {Math.round(totalDx)} / {Math.round(totalDy)}</div>
    <div>x1: {Math.round(myAvatarObj?.pos?.x1)} y1: {Math.round(myAvatarObj?.pos?.y1)}</div>
  
  
  <div className="mt-2 " onClick={() => {
    const foundObj = objs?.find(o => o.avatar_id === ct.user.id)
    console.log('foundObj', foundObj)
    
    }}>GET {guests.find(g => g.user_id == ct.user.id)?.chat}</div>
</div>
        </div> */}

      </div>
    );
  };
  
  export {ForestScreen};