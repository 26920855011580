
import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";
import {validCard, learn} from './learn/learn'

import { db } from "../../config.js";
const validSound = new Audio('/assets/valid_sound.mp3');
const badSound = new Audio('/assets/bad_sound.mp3');
//const newSound = new Audio('/assets/new.mp3');
const newSound = new Audio('/assets/valid_sound.mp3');
const MindMap = (props) => {
  const ct = props.context
  const mode = props.mode
  const [selectedCard, setSelectedCard] = useState()

//   function getClickPosition(event) {
//     var image = event.currentTarget;
//     console.log(image.getBoundingClientRect().y)
//     var xPosition = event.clientX - image.getBoundingClientRect().x;
//     var yPosition = event.clientY - image.getBoundingClientRect().y;
//     var width = image.clientWidth;
//     var height = image.clientHeight;
//     console.log('height', height)
//     console.log('image.offsetTop', image.offsetTop)
//     console.log('yPosition', yPosition)
//     var xPercent = Math.round((xPosition / width) * 100);
//     var yPercent = Math.round((yPosition / height) * 100);
//     console.log("Top: " + yPercent + "%, Left: " + xPercent + "%");

//     if (selectedCard) {
//         const _card = selectedCard
//         _card.original_card.mindcard = {pos:`${yPercent},${xPercent}`}
//         console.log("_card", _card)
//         updateCard(_card)
//         setSelectedCard()
//     }
// }



  async function getClickPosition(event) {
    var image = event.currentTarget;
    console.log(image.getBoundingClientRect().y)
    var xPosition = event.clientX - image.getBoundingClientRect().x;
    var yPosition = event.clientY - image.getBoundingClientRect().y;
    var width = image.clientWidth;
    var height = image.clientHeight;
    console.log('height', height)
    console.log('image.offsetTop', image.offsetTop)
    console.log('yPosition', yPosition)
    var xPercent = Math.round((xPosition / width) * 100);
    var yPercent = Math.round((yPosition / height) * 100);
    console.log("Top: " + yPercent + "%, Left: " + xPercent + "%");

    if (selectedCard) {


        console.log('selected_Car', selectedCard)
        const newCard = {id: selectedCard.id, deck_id: props.deck?.id , mindcard: {pos:`${yPercent},${xPercent}`}}
        const child_cards = await ct.fire.getChildCards(selectedCard)
        console.log('child_cards', child_cards)
        child_cards.forEach(c => {
            const newCard = {id: c.id, deck_id: props.deck?.id ,mindcard: {pos:`${yPercent},${xPercent}`}}
            ct.fire.updateCard(newCard)
        })
        console.log("newCard", newCard)
        ct.fire.updateCard(newCard)
       // updateCard(_card)
        //setSelectedCard()
    }
  }

  const cardInDeck = props.cardsInDecks.filter(c => c.deck_id == props.deck.id || !c.deck_id)
 

  return (
    <>
    <div id="mindcard" className='relative mb-2 max-w-[400px] '>
   
    <div className="absolute top-0 bottom-0 left-0 right-0 " onClick={getClickPosition}>
        {cardInDeck?.filter(c => c.mindcard).map(c => { 
         
            let color = "border-purple-400 bg-purple-200"
            let border = "border-2"
            let text = ""
            if (c.state == "to_learn" ) {
                color = "border-amber-500 bg-yellow-300 fredoka  boundEffet  h-[20px] w-[20px]"
                text = "?"
            }
            if (c.state == "to_review" ) {
                color = "border-purple-500 bg-indigo-300"
            }
            if (c.state == "mastered") {
                border = ""
                color = "border-green-300 bg-green-400/50"
                text = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-2 text-green-800 h-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            }

          
            if (mode == "exp" ) {
                if (c.state == "mastered") {
                    text = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  
                } else {
                color = "border-gray-300 bg-gray-400 border-purple-500 bg-indigo-300  h-[20px] w-[20px]"
                text = "?"
                }
            }
            if (mode == "comp") {
                border = "bg-black/50 border-4 border-yellow-900"
                color = "border-gray-300 bg-gray-400 border border-yellow-100 bg-transparent h-[16px] bg-yellow-50/20 w-[16px]"
            }
       
           
            return <div onClick={async() => {
                if (mode == "comp") {
                    console.log('vous avez cliqué sur ', c)
                    const valid = props.answer.id === c.id
                    console.log('Valid', valid)

                    if (valid) {validSound.play()} else {badSound.play()}

                    ct.setAlerts([{title: valid ? "Bonne réponse" : "Mauvaise réponse", content: valid ? "Vous avez validé la carte" : "Vous n'avez pas validé la carte", time: 1000, color: valid ? "" : "", icon: valid ? "👍" : "👎"}])
                    
                    const udpatedUserCard = await validCard(ct, props.answer.user_card, valid, 0)
                } else {
                    ct.setShowCards([c])
                }
            }} className={`dot ${border} b  flex items-center justify-center rounded-full absolute`} style={{
            top: `calc(${c.mindcard.pos.split(',')[0]}% - 18px)`, 
            left: `calc(${c.mindcard.pos.split(',')[1]}% - 18px)`,
            width: 30,
            height: 30,
            borderColor: "rgba(255, 255, 255, 0.4)",
        }}
            ><div className={` text-white text-shadow flex items-center justify-center rounded-full  border-2  ${color}`}>{text}</div></div>
            })}
    </div>
    <img src={props.deck?.mindcard?.img} className="rounded-md w-full object-contain"/> 
</div>

{props.edit && <div className='flex gap-4 my-4 flex-wrap'>{cardInDeck.map(c => <div className={`${selectedCard == c ? "bg-blue-500 text-white" : c.mindcard?.pos ? "bg-green-500" : ""} px-4 py-1 rounded-full`} onClick={() => {setSelectedCard(c); console.log(c)}}>{c.term}</div>)}</div>}
</>

  );
};

export {MindMap};

