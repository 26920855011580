import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../StateProvider";
import { capitalizeFirstLetter } from "../helperFunctions";
import { goals } from "../helperData";
import { sbCreate, sbUpdate, sbUpdateAndGet } from "../DbFunction";






const RenderGoal = ({ g, handleGoal, workspace, i, setSelectedGoal, selectedGoal, profile}) => {


return <div> 
        <div className="mb-4">
            {/* <div className="text-md mb-1">{g.name.replace("@lang", workspace?.name)}</div> */}
            <div onClick={() => setSelectedGoal(i)} className=" flex  transition z-10  bg-white/20 rounded-xl border-2 border-black/80 border-b-[6px] bg-gradient-to-r from-purple-700 to-purple-800">
                <div className="min-w-[70px] max-w-[70px] flex items-center justify-center rounded-l-xl" style={{backgroundColor: g.bgColor}}>
                    <img src={g.img} className="w-[80%]"/>
                </div>
                <div className="p-2 px-4 grow">
                    <div className="text-[16px] font-semibold">{g.courseName.replace("@lang", capitalizeFirstLetter(workspace?.name))}</div>
                    <div className="text-[12px]">{g.description.replace("@lang", workspace?.name)}</div>
                </div>
                {/* <div className="text-amber-300">→ {g.goal}</div> */}
            </div>
            {selectedGoal == i && <div className="bg-black/50 p-4  -mt-[8px] rounded-b-xl z-0">
            
            <div className="flex gap-2 my-2">Niveau : {g.lvl.map(lvl => <div className="bg-white/20 border px-2 rounded-xl">{lvl}</div>)}</div>
              <div className="text-sm text-justify">{g.content.replace("@lang", workspace?.name)}</div>
            
             <div className="flex justify-between mt-4 items-center my-2">
              <div className="px-2 py-1 text-white/90 rounded-xl border-2 text-sm items-center border-white/10 flex gap-2">Voir le tutoriel <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 text-white/40">
              <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
              </div>
              <div onClick={() => {handleGoal(g.goal_id, profile.id)}} className="bg-white px-6  py-1 border border-b-2 rounded-xl text-purple-500 font-semibold">Choisir →</div>
              </div> 
            </div>}
        </div>

      </div>
}

const SelectGoal = (props) => {
    const [selectedGoal, setSelectedGoal] = useState()
    const {profile, workspace, profileWorkspace, setProfileWorkspaces, setProfileWorkspace, setWorkspace, setProfile, setShowBottomBar} = useStateContext()
    const filteredGoals = goals?.filter(g => !g.open_only_to || g.open_only_to.includes(workspace?.old_id) || g.admin && profile?.admin)

    const [isCreating, setIsCreating] = useState(false);

    const handleGoal = async (goal_id) => {
      if (!profileWorkspace?.id && !isCreating) {
        try {
          setIsCreating(true);
          const newProfileWorkspace = {...profileWorkspace, goal_id: goal_id, created_time: new Date(), updated_time: new Date()}
          const newProfileWorkspaceWithId = await sbCreate("profiles_workspaces", newProfileWorkspace, setProfileWorkspace)
          setProfileWorkspaces(prev => [...prev || [], newProfileWorkspaceWithId])
        } finally {
          setIsCreating(false);
        }
      } else {  
        sbUpdateAndGet("profiles_workspaces", profileWorkspace?.id, {goal_id: goal_id, updated_time: new Date()}, setProfileWorkspace)
      }
    }

  
  return (
    <div id={"selectGoal"} className="w-full top-0 left-0 right-0 bottom-0 h-[100vh] fixed z-[60] text-left min-h-[100vh] max-h-[100vh] fredoka text-white bg-gradient-to-b to-[#5A3591] from-[#36206C] overflow-scroll ">
   
    
      <header className="h-[160px] flex items-center justify-center relative bg-[#34bdff]">
        <div className="absolute top-4 left-0 right-0  flex justify-center ml-6">
            <div onClick={() => {setProfileWorkspace()}} className="flex gap-2 rounded-full items-center mx-auto px-2 bg-black/20  border-black/50"> <img src={workspace?.img}  className="h-[20px] border border-black border-b-2 rounded-full -ml-1" /> Changer de langue</div>
        </div>
        <img src="/images/UX/arrow_man.png" className="h-[120px] absolute left-0 bottom-6 mx-auto"/>
        <div className=" pl-8 mb-3">

        <div className="text-xl text-center font-bold text-center  text-shadow">Choisis ta destination</div>
        </div>
        <div className=" h-[100px] absolute bottom-0 right-0 left-0" style={{
            backgroundImage: "url('/images/seeds/bottom-store.png')",
            backgroundRepeat: "repeat-x",
            backgroundPositionY: "bottom"
            }}></div>
        </header>
        <div className="h-[8px] bg-[#241256]"></div>
        <main className="p-8 px-6 pt-4 pt-2 pb-[60px] max-w-[600px] mx-auto">
      {filteredGoals?.filter(g => !g.open_only_to || g.open_only_to.includes(workspace?.old_id) || g.admin).map((g,i) => <RenderGoal profile={profile} workspace={workspace} i={i} setSelectedGoal={setSelectedGoal} selectedGoal={selectedGoal} g={g} handleGoal={handleGoal} />)}
      </main>    
    </div>
  );
};

export {SelectGoal};