import React, { useState, useEffect } from 'react';
import { supabase } from '../../V2/screens/testScreen';


const ProfilesInGroup = ({ groupId }) => {
  const [profilesInGroup, setProfilesInGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Chargement initial
    const loadProfilesInGroup = async () => {
      try {
        const { data, error } = await supabase
          .from('profiles_groups')
          .select(`
            *,
            profiles:profile_id (*)
          `)
          .eq('group_id', groupId);

        if (error) throw error;
        setProfilesInGroup(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    loadProfilesInGroup();

    // Écoute en temps réel
    const subscription = supabase
      .channel('profiles_groups_changes')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'profiles_groups',
          filter: `group_id=eq.${groupId}`
        },
        async (payload) => {
          // Charger uniquement le nouveau profil
          console.log('payload INSERT', payload);
          const { data, error } = await supabase
            .from('profiles_groups')
            .select(`
              *,
              profiles:profile_id (*)
            `)
            .eq('id', payload.new.id)
            .single();

          if (!error && data) {
            setProfilesInGroup(current => [...current, data]);
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'DELETE',
          schema: 'public',
          table: 'profiles_groups',
          filter: `group_id=eq.${groupId}`
        },
        (payload) => {
          console.log('payload deleted', payload);
          setProfilesInGroup(current =>
            current.filter(profile => profile.id !== payload.old.id)
          );
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'profiles_groups',
          filter: `group_id=eq.${groupId}`
        },
        async (payload) => {
          // Charger uniquement le profil mis à jour
          console.log('payload UPDATE', payload);
          const { data, error } = await supabase
            .from('profiles_groups')
            .select(`
              *,
              profiles:profile_id (*)
            `)
            .eq('id', payload.new.id)
            .single();

          if (!error && data) {
            setProfilesInGroup(current =>
              current.map(profile =>
                profile.id === payload.new.id ? data : profile
              )
            );
          }
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [groupId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 p-4 my-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">Erreur: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-slate-50 min-h-screen'>
    <div className="max-w-4xl mt-16 mx-auto p-4">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Membres du groupe
      </h2>

      {profilesInGroup.length === 0 ? (
        <div className="bg-gray-50 rounded-lg p-6 text-center">
          <p className="text-gray-500">Aucun membre dans ce groupe</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {profilesInGroup.map((profileGroup) => (
            <div
              key={profileGroup.id}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden"
            >
              <div className="p-5">
                {/* Avatar avec initiales */}
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold text-xl">
                    {profileGroup.profiles.full_name?.charAt(0)?.toUpperCase() || '?'}
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-semibold text-gray-800">
                      {profileGroup.profiles.name}
                    </h3>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      {profileGroup.role || "étudiant"}
                    </span>
                  </div>
                </div>

                {/* Informations du profil */}
                <div className="space-y-2">
                  <div className="flex items-center text-gray-600">
                    <svg 
                      className="h-5 w-5 mr-2" 
                      fill="none" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth="2" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span className="text-sm truncate">
                      {profileGroup.profiles.email}
                    </span>
                  </div>

                  {/* Ajoutez d'autres informations si nécessaire */}
                  {profileGroup.profiles.phone && (
                    <div className="flex items-center text-gray-600">
                      <svg 
                        className="h-5 w-5 mr-2" 
                        fill="none" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg>
                      <span className="text-sm">
                        {profileGroup.profiles.phone}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div></div>
  );
};

export default ProfilesInGroup;