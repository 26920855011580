import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, capitalizeFirstLetter, shuffleArray, transformText } from "../../utilities/helper/text.js";
import SuperText, { Cadena } from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { displayDuration, getCardsData, getCardsFromIDS, getDeckData } from '../../utilities/helper/helper.js';
import { learn } from '../components/learn/learn.js';
import { Liane } from '../components/liane.js';


function transformArray(arr) {
    const maxLength = Math.max(...arr.map(subArr => subArr?.length));
    const new_flat_array = [];
  
    for (let i = 0; i < maxLength; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (arr[j][i]) {
          new_flat_array.push(arr[j][i]);
        }
      }
    }
  
    return new_flat_array;
  }

const interleaveDecks = (cards) => {
    // Grouper les cartes par deck_id
    const groupedCards = {};
    cards.forEach(card => {
        if (!groupedCards[card.deck_id]) groupedCards[card.deck_id] = [];
        groupedCards[card.deck_id].push(card);
    });

    // Récupérer les clés (deck_id) et les mélanger
    const deckIds = Object.keys(groupedCards);
 
    // Intercaler les cartes
    const interleavedCards = [];
    let maxGroupLength = Math.max(...deckIds.map(deck_id => groupedCards[deck_id].length));
    for (let i = 0; i < maxGroupLength; i++) {
        deckIds.forEach(deck_id => {
            if (groupedCards[deck_id][i]) {
                interleavedCards.push(groupedCards[deck_id][i]);
            }
        });
    }

    return interleavedCards;
};

const fetchNextCards = ({decksIDS, cardMapGlobal, userCards, setNextCards}) => {
    const allCards = decksIDS?.map(deck_id => cardMapGlobal?.find(e => e.deck_id === deck_id)?.cards).filter(c => c != undefined)
   
    console.log('allCards', allCards)
    if (allCards?.length == 0 || allCards == undefined)   return
    const allId = transformArray(allCards);
    console.log('allId', allId)
    const learnedId = new Set(userCards?.map(uc => uc.card_id));
    const nextID = [];

      for (const id of allId) {
          if (!learnedId.has(id)) {
          nextID.push(id);

          if (nextID.length >= 10) {
              break;
          }
          }
      }
      console.log('nextID PERFECT 🎁', nextID)
      const setNextCardsI = (nextCards) => {
          setNextCards(interleaveDecks(nextCards))
         
      }
      nextID.length > 3 &&  getCardsFromIDS(nextID, setNextCardsI)

}


const VocabScreen = (props) => {
    const navigate = useNavigate()
    const [lastCall, setLastCall] = useState()
  
    const [showPreselection, setShowPreselection] = useState()
    const [showTheme, setShowTheme] = useState()
    const menu = props.menu
    const ct = props.ct

    const __cards =  ct.userCards.map(lc => lc.content).filter(c => c != undefined)


    // récupérer user_deck
    // ajouter la possibilité de choisir les decks 
    // 

    useEffect(() => {
        ct?.setShowBottomBar(!showPreselection)
    }, [showPreselection])
    useEffect(() => {
        ct?.setShowBottomBar(!showTheme)
    }, [showTheme])




const addUserDeck = (deck_id, ct) => {
    // Vérifiez si le deck existe déjà
    const exist = ct.userDecks.find(ud => ud.deck_id == deck_id);
    
    // Créez un nouvel objet userDeck
    const userDeck = {
        deck_id: deck_id,
        user_id: ct.user.id,
        wp_id: ct.workspace.id,
        selectedVoc: !exist?.selectedVoc ,
    };

    // Si le deck existe déjà, on le remplace, sinon on l'ajoute
    // 
    if (exist) {
        ct.setUserDecks(ud => ud.map(ud => ud.deck_id == deck_id ? userDeck : ud));
        console.log('userDeck updated', userDeck);
        updateDoc(doc(db, "user_deck", exist.id), userDeck)
       

    } else {
        ct.setUserDecks(ud => [...ud, userDeck]);
        console.log('userDeck added', userDeck);
       
        addDoc(collection(db, "user_deck"), userDeck)
    }

    // Affichez le nombre de decks sélectionnés
};

    const setArchived = async(card, ct) => {
        console.log('card', card)
        console.log('uc id', card.user_card?.id)
        // const exist = card.user_card?.id != undefined && ct.userCards.find(uc => uc.id == card.user_card.id)
        const exist =  ct.userCards.find(uc => uc.card_id === card.id);

        console.log('exist', exist)
        if (exist) {
            ct.setUserCards(uc => uc.map(c => uc.card_id === card.id ? {...c, archived: true} : c))
            updateDoc(doc(db, "user_cards", exist.id), {...exist, archived: true})
        } else {
           
            const userCard = {
                card_id: card.id,
                rank: card.rank || null,
                workspace_id: ct.workspace.id,
                user_id: ct.user.id,
                origin: "voc", 
                path: "perf",
                last_update: new Date(),
                created_date: new Date(),
                archived: true,
            }
            console.log('creaction user_card', userCard)
            
            const ref = await addDoc(collection(db, "user_cards"), userCard)
            ct.setUserCards(uc => [...uc, {...userCard, id: ref.id}])
            console.log('creaction user_card id', ref.id)
        }
        
      
    }

    const defaultDecks = ct.globalDecks?.filter(ud => ud.default)

    const vocDecks = ct.userDecks?.filter(deck => deck.selectedVoc)

    const selectedDecks = (vocDecks?.length > 0) ? vocDecks : defaultDecks;

    const decksIDS = vocDecks?.length > 0 ? selectedDecks?.map(ud => ud.deck_id): defaultDecks?.map(e => e.id)  
    


    useEffect(() => {

        if (!ct.cardMapGlobal) return
        if (ct.userWorkspace.goal_id != "perfect") return
        if (!decksIDS) return
       
        const next4 = ct.nextCards?.filter(e => ! ct.userCards.filter(c => c.archived).find(a => a.card_id == e.id)).map(e => ({...e, user_card: null})) || []
        console.log('les decks id on changés', decksIDS)
        console.log('next4', next4)
        console.log('ct.userWorkspace.goal_id', ct.userWorkspace.goal_id)
        if (next4.length > 3 && ct.userWorkspace.goal_id == "perfect") return
        console.log('les decks id on changés !!', decksIDS)
        fetchNextCards({decksIDS, cardMapGlobal: ct.cardMapGlobal, userCards: ct.userCards, setNextCards: ct.setNextCards})


    }, [decksIDS, ct.cardMapGlobal, ct.userCards, ct.userWorkspace.goal_id])
    // console.log('------ct.nextCards', ct.nextCards?.map(c => c.deck_id))

    const cardsInPublicDecks = __cards.filter(card => decksIDS?.includes(card.deck_id))


 

    const realCards = interleaveDecks(cardsInPublicDecks).filter(card => {
        const notContext = transformText(card.contexts && card.contexts[0]?.text)
        const googcontext = notContext?.includes(card.term?.toLowerCase())
        return googcontext
      }).filter(e => e.original_card)
     

    

   
    const realCardsData = getCardsData(ct, realCards)
  

    // const next4 = realCardsData?.filter(c => c.state == "to_learn" && !c.user_card.archived ).slice(0,4)

    const archivedCards = ct.userCards.filter(c => c.archived)
    const next4 = ct.nextCards?.filter(e => !archivedCards.find(a => a.card_id == e.id)).map(e => ({...e, user_card: null})) || []

    const deckButNoCards = decksIDS?.length && !next4?.length


   

    // console.log('realCardsToLearn', realCardsToLearn)
    // console.log('realCardsData', realCardsData)



 

    // console.log('ct', ct)

    // faire un système ou les cartes sont chargé en local basé sur lastUpdate
    // ajouter card dans user_card 

    const user_card_stacked = ct.userCards.filter(uc => uc.stacked && !uc.archived && !uc.unstack )

    console.log('user_card_stacked', user_card_stacked)
    const userCardPerf = ct.userCards.filter(uc => uc.path == "perf" && !uc.archived)
    const user_card_from_perfect = ct.userCards.filter(uc => uc.origin != "voc" && uc.origin && !uc.archived)



    const learndedCards = getCardsData(ct, __cards.filter(e => userCardPerf.find(uc => uc.card_id == e.id)))

   // Sort the learndedCards based on user_card next_date, with stacked cards at the end
    learndedCards.sort((a, b) => {
        // Check if either card is stacked
        if (a.user_card.stacked && !b.user_card.stacked) {
            return 1; // a is stacked, b is not; a should come after b
        } else if (!a.user_card.stacked && b.user_card.stacked) {
            return -1; // a is not stacked, b is; a should come before b
        } else {
            // Both are either stacked or not stacked, sort by next_date
            const dateA = new Date(a.user_card.created_date);
            const dateB = new Date(b.user_card.created_date);
            return dateA - dateB;
        }
    })
    //.filter(e => ct.userCards.find(e => e.archived && e.card_id == e.id));
    const stackedCards = realCardsData.filter(c => c.state == "to_learn" && !c.user_card.archived ).slice(0, 10)
    const realNext = [...learndedCards.filter(c => c.user_card.stacked && !c.user_card.unstack && !c.user_card.archived), ...next4].slice(0, 4)


    const realCardsToLearn = getDeckData(ct, realNext, {nbCardToLearn: 4})
    const realCardsToLearnNextCard = realCardsToLearn.nextCards?.slice(0, 12)



    const [way, setWay] = useState("liane")
    const ways = [
        {id: "liane", name: "Graines de connaissances", svg: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
      </svg>
      }, 
        {id: "theme", name: "Mes thématiques", svg: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
      </svg>
       }
    ]


    const newStackedWords = user_card_stacked.length
  

    return <div className="px-2 rounded-xl mb-2 ">


          <div>
            {way.id}
            <div className='flex justify-end items-center w-full '><div className={`flex my-4 -mt-0 justify-end  max-w-[300px] bg-black/20 px-2 p-1 rounded-xl  gap-2 text-sm text-center`}>{ways.map(w => <div  onClick={() => setWay(w.id)}  className={`flex flex-col items-center ${w.id == way ? "text-white/80" : "text-white/30" }`}>
                {w.svg}
                {/* <div className={`w-[60px] transition mt-1 h-[2px] rounded ${w.id == way ? "bg-white/80" : "bg-white/20"} `}></div> */}
                </div>)}</div></div>


                {/* <div>Cartes apprise</div>
            <div>{learndedCards.filter(e => !e.user_card.archived).map((e,i) => <div className={`${e.user_card?.stacked ? "text-yellow-500" : ""}`}>{i}- {e.term} - {e.state} - {displayDuration( e.user_card.created_date, new Date())}</div>)}</div> */}
           

          {way == "liane" && <div className='bg-[#351E69] p-4 rounded-xl'>
           
            {vocDecks.length == 0 && <div className='p-4 rounded-xl text-sm text-center bg-black/20 mb-2'>
                Choisis les thématiques pour personnaliser ton chemin d'apprentissage
                <div className='flex justify-center mt-2'><div className='bg-purple-500 px-6 rounded-xl py-1 border-2 border-b-4 border-black/20' onClick={() => {
                    setWay("theme"); 
                    setShowTheme(true); 
                   
                    }}>Choisir</div></div>
            </div> }
            {user_card_from_perfect.length == 0 && userCardPerf.length > 7  ? <div className='p-4 rounded-xl text-sm text-center bg-black/20'>
                <div className='flex gap-2 justify-center'>{props.menu?.filter(w => w.name != "Vocabulaire").map(w => <img className='h-6' src={w.icon} />)}</div>
                Apprends des mots dans les autres contextes pour les ajouter dans ton parcours d'apprentissage </div> : ""}
            {newStackedWords ? <div onClick={() => {console.log('user_card_stacked', user_card_stacked)}} className='p-3 mt-1 flex items-center gap-2 justify-center items-center text-center text-sm rounded-xl bg-black/20 relative'>
                {/* <div className='absolute top-2 right-2 text-slate-200'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </div> */}
                <span className='text-3xl  fredoka text-amber-400'>{newStackedWords} </span> 
                nouveau{newStackedWords > 1 && "x"} mot{newStackedWords > 1 && "s"} {newStackedWords > 1 ? "ont" : "a"} été ajouté{newStackedWords > 1 && "s"}</div> : ""}
              
            <Liane 
                popoverPos={props.popoverPos} 
                setPopoverPos={props.setPopoverPos} 
                decks={null}
                setHoverDeck={props.setHoverDeck}
                nbNextLocked={10}
                setShowPreselection={setShowPreselection}
                workspace={ct.workspace}
                setUserCardDeck={props.setUserCardDeck}
                setpackCards={props.setpackCards}
                filteredCards={[...learndedCards.filter(e => !e.user_card.archived)]}
                showCards={ct.showCards}
                context={ct}
            />
            </div>}


            {/* <div>NEXT</div>
            <div>{realNext.map((e,i) => <div className={`${e.user_card?.stacked ? "text-yellow-500" : ""}`}>{i}- {e.term}</div>)}</div>
---
           
        

            <div className='mt-4'>Prochaines cartes</div>
            <div>{stackedCards.map((e,i) => <div>{i}- {e.term}</div>)}</div>
             */}

{showPreselection && <div onClick={() => setShowPreselection()} className='absolute z-[30] flex justify-center items-center top-0 left-0 right-0 bottom-0 bg-purple-900/80 backdrop-blur-xl'>
                <div className='absolute top-0 left-0 right-0 p-4'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div>
                <div onClick={(e) => e.stopPropagation()} className='max-w-[700px] w-screen p-4'><div className='bg-slate-100 pb-2 rounded-xl overflow-hidden rounded text-slate-500 items-center'>
                    <div className='bg-white border-b p-4 text-center text-slate-500'>
                        <div className=''>LIS ATTENTIVEMENT</div>
                        <div className="text-slate-400 text-xs text-center">Supprime les mots que tu maîtrises déjà</div>

                        </div>

                     
            {realNext?.map(card => <div key={card.id} id={card.id} className={`${card.user_card?.archived ? "opacity-50" : ""} flex gap-2 p-4 py-3 justify-between `}>
                <div onClick={() =>  console.log('card term', card.term)}>{capitalizeFirstLetter(card.term)}</div>
                <div key={"bny" + card.id}  onClick={() => {
                  
                    setArchived(card, ct)
                    }} className='bg-slate-100/50 rounded-full p-1'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</div>

{/* <div className='bg-red-400' onClick={() => {
    console.log('---data', ct.userCards?.filter(e => e.card_id == card.id))
}}>{card.id} - test: {ct.userCards?.filter(e => e.card_id == card.id)?.length}/{ct.userCards.length}
</div> */}
                </div>)}
                {/* <div onClick={() => {
                    console.log('ct.nextCards', ct.nextCards)
                    console.log('ct.nextCards term', ct.nextCards.map(e => e.term))
                    console.log('next4.length', next4)
                }}>Recharger {ct.nextCards.length} -  4: {next4.length}</div> */}
            </div>
            <div className='flex justify-center'><div onClick={() =>  {
                setShowPreselection(false);
                ct.setShowCards(realCardsToLearnNextCard)
                }} className='mt-8 rounded-xl bg-purple-500 text-xl border-b-4 border-2 border-black/50 px-4 py-2 text-white'>Apprendre</div></div>
</div> </div>}
          
          
          {/* <div className='p-4 tex-lg text-center mb-1 bg-black/10 border-2 border-b-4 border-black/40 transition rounded-2xl overflow-hidden'>Mots appris</div> */}
          {way == "theme" && <div>
          {/* <div className='fixed bottom-[80px] left-0 right-0 flex justify-center'><div onClick={() => { decksIDS.length ? setShowPreselection(true) : setShowTheme(true)}} className=' p-3 px-5 tex-lg text-center mb-1 bg-black/10 border-2 border-b-4 border-black/70 transition bg-indigo-600 rounded-2xl overflow-hidden'>Apprendre du vocabulaire</div></div> */}

         {/* <div onClick={() => { }} className='p-4 tex-lg text-center mb-1 bg-black/10 border-2 border-b-4 border-black/40 transition rounded-2xl overflow-hidden'>120 mots appris</div> */}
         
    
          
          <div className='grid grid-cols-3 gap-2'>{
          selectedDecks?.map(userDeck => {

            const deck = !userDeck.deck_id ? userDeck : ct.globalDecks?.find(d => d.id == userDeck.deck_id)
            const masteredCards = realCardsData?.filter(c => c.state == "mastered" && c.deck_id == deck?.id)?.length
            const total = realCards?.filter(card => card.deck_id == deck?.id).length
            const progressCent = Math.round(masteredCards*100/total)
            if (!deck) return
            return <div className='p-2 rounded-xl border-2 border-b-4 border-black/50 bg-black/10 text-white'>
                <div className='text-sm'>{deck?.name}</div>
                <div className='h-2 mt-2 rounded-xl overflow-hidden w-full bg-black/30'><div style={{width: progressCent+'%'}} className='h-2 transition-all rounded-xl bg-amber-500'></div></div>
                <div className='text-xs text-white/30 text-right mt-1'>{masteredCards}/{total}</div>
                </div>
          })}</div>

<div className='flex justify-center'><div className='px-3 bg-white/10 my-4 rounded-full ' onClick={() => setShowTheme(true)}>Modifier mes thèmes</div></div>

   
           

            {showTheme && <div onClick={() => setShowTheme(false)} className='absolute flex justify-center items-center top-0 left-0 right-0 bottom-0 bg-purple-900/80 backdrop-blur-xl'>
                            <div className='absolute top-0 left-0 right-0 p-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
            </div>
                            <div onClick={(e) => e.stopPropagation()} className='max-w-[700px] w-screen p-4'>
                                <div className='pb-2 rounded-xl overflow-hidden rounded text-slate-500 items-center'>
                                    <div className='text-2xl text-center text-white mb-8'>Choisis les sujets que tu souhaites étudier</div>
                                <div className='p-4 text-center text-slate-500'>
                                <div className='flex gap-2 my-3 justify-center flex-wrap'>{ct.globalDecks?.map(deck => <div className={`${ct.userDecks.find(ud => ud.selectedVoc && ud.deck_id == deck.id) ? "bg-green-500" : "bg-red-500" } text-white px-2 rounded-xl bg-white/20`} onClick={() => {addUserDeck(deck.id, ct)}}>{deck.name}</div>)}</div>

                                    </div>

                                
                    
                        </div>
                        {!(vocDecks.length > 1) && <div className='text-center text-sm text-white/30'>Sélectionne au moins 2 thèmes ({vocDecks.length}/2)</div>}
                        <div className='flex justify-center'><div onClick={() =>  {
                    setShowTheme(false);
                    setWay("liane")
                    fetchNextCards({decksIDS, cardMapGlobal: ct.cardMapGlobal, userCards: ct.userCards, setNextCards: ct.setNextCards})

        }} className={`mt-8 ${vocDecks.length > 1 ? "bg-purple-500 " : "bg-slate-500 opacity-50"} rounded-full text-xl border-b-4 border-2 border-black/50 px-8 py-2 text-white`}>Valider</div></div>
            </div> </div>}

            </div>}

            

            {/* <div>NExt cards LEARN</div>            
            {realCardsToLearnNextCard?.slice(0,16).map(card => <div onClick={() => console.log('card',card)} className={`${card.user_card.archived ? "opacity-50" : ""}`}>{card.term} - {card.state}</div>)}
            <div className='mt-16'>NExt cards</div>            
            {realCardsData.map(card => <div onClick={() => console.log('card',card)} className={`${card.user_card.archived ? "opacity-50" : ""}  ${card.state == "to_learn" ? "text-yellow-500" : ""} `}>{card.term} - {card.state}</div>)} */}

          
          {/* <div className='mb-2 mt-4'>Apprendre par catégorie</div>
          {decks.map((deck, i) => {
                return <div key={i} className="p-4 flex justify-between mb-1 bg-black/10 border-2 border-b-4 border-black/40 transition rounded-2xl overflow-hidden">
                   
                    <div>
                        <div className=" text-lg font-bold">
                        {deck.name}
                        </div>
                        <div className="text-sm text-white/50">0/50</div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>


                </div>
            })} */}
            
          </div>
      </div>

};

export {VocabScreen, interleaveDecks};





