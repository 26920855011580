import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useStateContext } from "../StateProvider";

const Bubulle = (props) => {
    const {bubulle, setBubble } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const bubbleRef = useRef(null);
    const hideTimeoutRef = useRef(null);
    const [bubbleSize, setBubbleSize] = useState({ width: 0, height: 0 });

    // Mesurer la taille de la bulle après le rendu
    useLayoutEffect(() => {
        if (isVisible && bubbleRef.current) {
            const { offsetWidth, offsetHeight } = bubbleRef.current;
            setBubbleSize({ width: offsetWidth, height: offsetHeight });
        }
    }, [isVisible]);

    // Recalculer la position de la bulle
    useEffect(() => {
        const calculatePosition = () => {
            if (bubulle && bubbleRef.current) {
                const targetRect = bubulle.event?.target?.getBoundingClientRect();
                const _left = bubulle.left || targetRect.left
                const _top = bubulle.top || targetRect.top
                const _bottom = bubulle.bottom || targetRect.bottom
                const _width = bubulle.width || targetRect.width
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;
                const bubbleWidth = bubbleSize.width;
                const bubbleHeight = bubbleSize.height;
                const margin = 10; // Espace minimum par rapport aux bords
                const marginY = 90

               

                let placement = "top"
                let top = window.scrollY + _bottom + 5; // Par défaut, en dessous de l'élément
                let left = window.scrollX + _left + (_width / 2) - (bubbleWidth / 2);



                // Ajuster la position horizontale
                if (left < window.scrollX + margin) {
                    left = window.scrollX + margin;
                } else if (left + bubbleWidth > window.scrollX + viewportWidth - margin) {
                    left = window.scrollX + viewportWidth - bubbleWidth - margin;
                }

                // Ajuster la position verticale si la bulle dépasse en bas
                if (top + bubbleHeight > window.scrollY + viewportHeight - marginY) {
                    placement = "bottom"
                    // Positionner la bulle au-dessus de l'élément

                    console.log('position',  _top )
                    top = window.scrollY + _top - bubbleHeight;
                }

                // Si la bulle dépasse encore en haut, la positionner dans la fenêtre

                console.log('top', top)
      
                if (top < window.scrollY + marginY) {
                    top = window.scrollY + marginY;
                }
    
                setPosition({ top, left, placement, left_origin: window.scrollX + _left  });
            }
        };

        if (bubulle) {
            // Annuler le hideTimeout précédent s'il existe
            if (hideTimeoutRef.current) {
                clearTimeout(hideTimeoutRef.current);
                hideTimeoutRef.current = null;
            }

            if (!isVisible) {
                setIsVisible(true);
            }

            calculatePosition();

            // Recalculer la position lors du redimensionnement de la fenêtre
            window.addEventListener('resize', calculatePosition);
            return () => {
                window.removeEventListener('resize', calculatePosition);
            };
        } else if (isVisible) {
            // Commencer l'animation de disparition
            setIsVisible(false);

            // Démarrer un nouveau hideTimeout
            hideTimeoutRef.current = setTimeout(() => {
                setBubble(null);
                hideTimeoutRef.current = null;
            }, 200);
        }
    }, [bubulle, isVisible, bubbleSize, setBubble]);

    useEffect(() => {
        if (!isVisible) return;

        const handleClickOutside = (event) => {
            let path = event.path || (event.composedPath && event.composedPath());
            if (path && !path.includes(bubbleRef.current)) {
                hideBubble();
            }
        };

        const handleScroll = () => {
            hideBubble();
        };

        document.addEventListener("click", handleClickOutside, true); // Utilisation de la phase de capture
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("touchmove", handleScroll);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        };
    }, [isVisible]);

    const hideBubble = () => {
        setIsVisible(false);
        setBubble(null);
    };

    if (!bubulle && !isVisible) return null;


    // bg-gradient-to-b from-purple-500 to-indigo-500
    return (
        <>
        <div style={{
                transition: 'top 0.2s ease, left 0.4s ease, opacity 0.2s ease, transform 0.2s ease',
                left: position.left_origin,
                top: position.placement == "top" ? position.top - 8 : position.top + (bubbleRef.current?.getBoundingClientRect()?.height || 0) - 8,
            }} className={`fixed bubble-arrow rotate-[45deg] ${position.placement == "top" ? "bg-[#a855f6]  " : " non bg-purple-500"} h-4 w-4 `}>
                
            </div>
        <div
            ref={bubbleRef}
            className={`fixed z-[10] rounded-xl  bubble ${isVisible ? 'bubble--visible' : 'bubble--hidden'}`}
            style={{
                top: position.top,
                left: position.left,
                transition: 'top 0.2s ease, left 0.2s ease, opacity 0.2s ease, transform 0.2s ease',
            }}
        >
            {/* Contenu de la bulle */}
            <div className="bubble-content text-white border-1 rounded-xl">
                <div>{bubulle?.content}</div>
               
            </div>
            {/* Triangle */}
           
        </div>
        
        </>
    );

};

export { Bubulle };
