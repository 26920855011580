
import { collection, getDocs, limit, where, query, updateDoc, doc, deleteDoc, addDoc } from "@firebase/firestore";
import { dicoWPSupa, supabase } from "../V2/screens/testScreen";
import { db } from "../config";
import { getWeekNumberAndYear, transformDates, transformDatesToJS } from "./helperFunctions";
import { dicoOldCards, dicoOldGroups, dicoOldSchools } from "./data/dicoOldCards";



const fetchFireUserCards = async (user_id) => (await getDocs(query(collection(db, "user_cards"), where("user_id", "==", user_id), limit(6666)))).docs.map(doc => ({...doc.data(), id: doc.id}));
const fetchFireUserWorkspace = async (user_id) => (await getDocs(query(collection(db, "user_workspace"), where("user_id", "==", user_id),  limit(6666)))).docs.map(doc => ({...doc.data(), id: doc.id}));


const lotTransfert = async (target, data) => {
  const BATCH_SIZE = 50; // Taille de chaque lot
  let successCount = 0;
  let errorCount = 0;
  let results = {
    success: [],
    errors: []
  };

  console.log(`Début du transfert de ${data.length} éléments vers ${target}`);
  console.log(`Traitement par lots de ${BATCH_SIZE}`);

  for (let i = 0; i < data.length; i += BATCH_SIZE) {
    const batch = data.slice(i, i + BATCH_SIZE);
    const batchNumber = Math.floor(i / BATCH_SIZE) + 1;
    
    try {
      const { data: insertedData, error } = await supabase
        .from(target)
        .insert(batch)
        .select();

      if (error) {
        console.error(`⚠️ Erreur lot ${batchNumber}:`, error.message);
        
        // Tentative d'insertion individuelle pour le lot échoué
        for (const item of batch) {
          try {
            const { data: singleData, error: singleError } = await supabase
              .from(target)
              .insert([item])
              .select();

            if (singleError) {
              console.error(`❌ Échec insertion individuelle:`, singleError.message);
              errorCount++;
              results.errors.push({ item, error: singleError });
            } else {
              successCount++;
              results.success.push(singleData[0]);
            }
          } catch (e) {
            errorCount++;
            results.errors.push({ item, error: e });
            console.error('❌ Erreur inattendue:', e.message);
          }
        }
      } else {
        successCount += insertedData.length;
        results.success.push(...insertedData);
        console.log(`✅ Lot ${batchNumber} inséré (${insertedData.length} éléments)`);
      }
    } catch (batchError) {
      console.error(`❌ Erreur critique lot ${batchNumber}:`, batchError.message);
      errorCount += batch.length;
      results.errors.push(...batch.map(item => ({ item, error: batchError })));
    }

    // Affichage de la progression
    const progress = Math.min(100, Math.round((i + BATCH_SIZE) / data.length * 100));
    console.log(`📊 Progression: ${progress}%`);
  }

  const summary = {
    total: data.length,
    success: successCount,
    errors: errorCount,
    successRate: `${Math.round((successCount / data.length) * 100)}%`,
    results
  };

  console.log('📝 Résumé du transfert:');
  console.log(`Total: ${summary.total}`);
  console.log(`Succès: ${summary.success}`);
  console.log(`Erreurs: ${summary.errors}`);
  console.log(`Taux de réussite: ${summary.successRate}`);

  return summary;
};


function replaceKeywords(template, replacements) {
  return template?.replace(/@(\w+)/g, (match, key) => replacements[key] || match);
}

const getNotifications = async (user) => {
  console.log('getNotification 🟢')
  const currentDate = new Date();
  const q = query(
      collection(db, 'notifications'),
      where('user_id', '==', user.id),
      where("date", '>', currentDate)
    );

  //  const q = query(
  //     collection(db, 'notifications'),
  //     where("date", '<', currentDate),
  //     where('sended', "==", false)
  //   );
  const querySnapshot = await getDocs(q);
  const notifications = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('getNotification', notifications)


 return notifications
} 


const addNotifications = async (coach, user, workspace) => {
  if (!user.fcmToken) {
      console.log('Pas de token pas de notification 🔴', user)
      return 
  }


  const remind1 =  coach.notifications?.remind1.map(notif => replaceKeywords(notif, {name: user?.name, lang: workspace.name}))[(user?.remind1_i || 0) % coach.notifications?.remind1.length]
  const remind2 =  coach.notifications?.remind2.map(notif => replaceKeywords(notif, {name: user?.name, lang: workspace.name}))[(user?.remind2_i || 0) % coach.notifications?.remind2.length]
  const lastRemind =  coach.notifications?.lastRemind.map(notif => replaceKeywords(notif, {name: user?.name, lang: workspace.name}))[(user?.lastRemind || 0) % coach.notifications?.lastRemind.length]
  const notifications = [remind1, remind2, lastRemind]
 
  // supprimer toutes les notifications comeback de l'utilisateur
 
  const _notifications = await getNotifications(user)
  _notifications.forEach(async(n) => {
      const ref = await deleteDoc(doc(db, "notifications", n.id));
      console.log('notification supprimée 🔴')
  })

  

  let now = new Date();
  let day1 = new Date();
  day1.setDate(now.getDate() + 1); // Ajoute un jour
  day1.setHours(18, 0, 0, 0); // Règle l'heure à 18h

  let day2 = new Date();
  day2.setDate(now.getDate() + 2); // Ajoute deux jours
  day2.setHours(18, 0, 0, 0); // Règle l'heure à 18h

  let day5 = new Date();
  day5.setDate(now.getDate() + 5); // Ajoute cing jours
  day5.setHours(18, 0, 0, 0); // Règle l'heure à 18h

  const dates = [day1, day2, day5]
  const newNotifications = []
 
  notifications.forEach(async(n) => {
      const notif = {content: n, date: dates[notifications?.indexOf(n)], user_id: user.id, token: user.fcmToken, type: "comeback", sended: false, coach: coach.id, img: coach.img_round, title: coach.name}
      const ref = await addDoc(collection(db, 'notifications'), notif);
      newNotifications.push({...notif, id: ref?.id})
  })

  console.log('newNotifications', newNotifications)
  const lastConnexionDate = user?.last_connexion?.seconds ? new Date(user?.last_connexion.seconds * 1000) : (user?.last_connexion || new Date())
  const currentDate = new Date();
  const diffMilliseconds = currentDate.getTime() - lastConnexionDate.getTime();
  const diffHours = diffMilliseconds / (1000 * 60 * 60);
  const newUser = {
      ...user, 
      remind1_i: diffHours > 16 ? (user?.remind1_i || 0) + 1 :( user?.remind1_i || null) ,
      remind2_i: diffHours > 48 ? (user?.remind2_i || 0) + 1 : (user?.remind2_i || null),
      lastRemind_i: diffHours > 96 ? (user?.lastRemind_i || 0) + 1 : (user?.lastRemind_i || null),
      last_connexion: new Date(),
   }

   console.log('new user after notif', newUser)
   await updateDoc(doc(db, 'users', user.id), {
    remind1_i: newUser.remind1_i, 
    remind2_i: newUser.remind2_i, 
    lastRemind_i: newUser.lastRemind_i, 
    last_connexion: newUser.last_connexion
  })

   return newUser

}



const transfertFireUserWorkspace = async(profile, cards_length) => {

  try {
  const fire_user_id = profile?.old_id

  const userWorkspaces = await fetchFireUserWorkspace(fire_user_id)
  console.log('ancien userWorkspaces pour ' + profile.id , userWorkspaces)

  const defaultDate = new Date(1996, 5, 10); 

  const dataToInserts = userWorkspaces?.map(e => ({
    profile_id: profile.id, 
    workspace_id: dicoWPSupa.find(a => a.old_id == e.workspace_id)?.id,
    created_time: e.creation_date?.toDate() || defaultDate,
    updated_time: e.updatedDate?.toDate() || defaultDate, 
    tt_elixirs: e.cards_length*4 || 0,
    tt_xp: e.cards_length*4 || 0,
  
  })).filter(e => e.workspace_id)

  console.log('profile workspace to insert', dataToInserts)
  await lotTransfert("profiles_workspaces", dataToInserts)

  return dataToInserts
} catch (err) {
  console.error('Error transfertFireUserCard', err)
  sbCreate('logs', {type: "error", text: err.message || err.toString() || JSON.stringify(err) || err, created_time: new Date(), user_id: profile?.old_id })

}

}


const addXpToProfile = async(profile, gain_xp = 1, bonus =  0, setProfile) => {


  console.log(`addXpToProfile gain_xp: ${gain_xp}, bonus: ${bonus} `)
  // xp total
  // _xp sans bonus
  const today = new Date();
  const currentWeek = getWeekNumberAndYear(today);


  console.log('profile before', profile)

  const newProfile = {
    ...profile,
    tt_xp: (profile.tt_xp || 0) + gain_xp + bonus,
    week_table: {...profile.week_table, [currentWeek]: {xp: (profile.week_table?.[currentWeek]?.xp || 0) + gain_xp + bonus, _xp: (profile.week_table?.[currentWeek]?._xp || 0) + gain_xp}}
  }

  const currentWeekTable = profile.week_table?.[currentWeek] || {xp: gain_xp + bonus, _xp: gain_xp}
  const weekData = currentWeekTable ? {xp: currentWeekTable.xp + gain_xp + bonus, _xp: currentWeekTable._xp + gain_xp} : {xp: gain_xp + bonus, _xp: gain_xp}
  const { data, error } = await supabase
  .rpc('update_week_xp', {
    profile_id: profile.id,
    new_tt_xp: (profile.tt_xp || 0) + gain_xp + bonus ,
    week_key: currentWeek,
    week_data: weekData
  })

  console.log('data', data)
  if (error) {
    console.log('error', error)
  }
  console.log('newProfile', newProfile)
  setProfile(newProfile)
}

const addXpToProfileWorkspace = () => {

}


const transferFireUserCard = async(profile) => {

  try {
  const fire_user_id = profile.old_id

  // on récupère les userCard de fire ici
  const userCards = await fetchFireUserCards(fire_user_id)
  console.log('ancienne userCards pour ' + profile.id , userCards)


  const cardsToInserts = userCards.map(u => ({
    profile_id: profile.id, 
    card_id: dicoOldCards?.find(e => e.old_id == u.card_id)?.id, 
    workspace_id: dicoWPSupa.find(e => e.old_id == u.workspace_id)?.id , 
    old_id: u.id, // TEXT
    collected: u.collected,  // BOOL
    last_update: u.lastUpdate?.toDate(), // DATE
    archived: u.archived, // BOOL
    next_date: u.next_date?.toDate(), // DATE
    next_trigger: u.next_trigger, // TEXT
    triggers: u.triggers?.map(e => ({...e, next_date: e.next_date?.toDate()})), // JSONB
    lvl: u.lvl, // INT
    from: u.letter ? "letter" : null ,

  })).filter(u => u.card_id)

  // envoyer aussi les cartes type letters  

  console.log('UserCardsToInserts', cardsToInserts)
  await lotTransfert("profiles_cards", cardsToInserts)
  console.log('transfert terminé !')

  // les ajouter dans supa base

  console.log('transferFireUserCard', profile)
  return cardsToInserts
  } catch (err) {
    console.error('Error transfertFireUserCard', err)
    sbCreate('logs', {type: "error", text: err.message || err.toString() || JSON.stringify(err) || err, created_time: new Date(), user_id: profile?.old_id })

  }
  // lire depuis la db pour vérifier la lecture des triggers et les dates

}


async function getDaily(profile_id, date, set = () => {}) {
  console.log('getDaily',profile_id)
  // Formatage de la date en 'jj-mm-aa'
  const formattedDate = date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });

  // Recherche du daily existant
  const { data: existingDaily, error: fetchError } = await supabase
    .from('profiles_dailies')
    .select('*')
    .eq('profile_id', profile_id)
    .eq('day', formattedDate)
    .single();

  // Si une erreur survient pendant la recherche
  if (fetchError && fetchError.code !== 'PGRST116') {
    throw new Error(`Erreur lors de la recherche du daily: ${fetchError.message}`);
  }

  // Si le daily existe, on le retourne
  if (existingDaily) {
    set(existingDaily)
    return existingDaily;
  }

  // Si le daily n'existe pas, on le crée
  const { data: newDaily, error: insertError } = await supabase
    .from('profiles_dailies')
    .insert([
      {
        profile_id: profile_id,
        day: formattedDate,
        // Ajouter ici d'autres champs par défaut si nécessaire
      }
    ])
    .select()
    .single();

  // Si une erreur survient pendant la création
  if (insertError) {
    throw new Error(`Erreur lors de la création du daily: ${insertError.message}`);
  }

  set(newDaily)
  return newDaily;
}

const transfertFireUser = async (user) => {
  const profileToCreate = {
    name: user.name,
    blocked: false, // Valeur par défaut
    photo: user.photo || null,
    coach: user.coach || null,
    email: user.email,
    fcmToken: user.fcmToken || null,
    uid: user.uid,
    old_id: user.id,
    week_table: user.week_table || null,
    has_ios: user.ios ?? false,
    last_workspace_id: dicoWPSupa.find(e => e.old_id == user?.last_workspace_id)?.id || null, // modifier avec le nouvelle id
    last_connexion: new Date(),
    creation_date: user.updated?.toDate() || new Date(),
    last_class: user.class?.[0] || null,
    classes: user.class || [],
    lig_w: user.lig_w || null,
    lig_id: user.lig_id || null,
    lig_lvl: user.lig_lvl || null,
    last_class_id: user.class?.[0],    // mettre beta-test
  }

   const profile = await sbCreate("profiles", profileToCreate)
   console.log('profile', profile)
   console.log('user', user)

   try {
    const uniqueClass = user.class ?  [...new Set(user.class)] : []

    uniqueClass?.forEach(c => {
      // on créer les classes 
       console.log('c', c)

       const group_id = dicoOldGroups.find(e => e.old_id == c)?.id
       console.log('group_id', group_id)
       console.log('dicoOldGroups', dicoOldGroups)
       console.log('found', dicoOldGroups.find(e => e.old_id == c))
        
        sbCreate("profiles_groups", {
          profile_id: profile?.id,
          created_time: new Date(),
          group_id: group_id,
          updated_time: new Date(),
      })
    })
    } catch (err) {
      console.error('erreur lors de la création des classes', err)
      sbCreate('logs', {type: "error", text: err.message || err.toString() || JSON.stringify(err) || err, created_time: new Date(), user_id: user?.id })

    }
    profile?.id && user.id &&  await updateDoc(doc(db, 'users', user.id), {id: user.id, supa_try: profile?.id})
  return profile
}
const transfertFire = async (user, set, setState = () => {}) => {

  try {

  
  console.log('transfertFire', user)
  // on donne le bon format à l'utilisateur
  setState("Création du profile ...")
  const profile = await transfertFireUser(user)
  console.log('---- création du profile terminé', profile)
  setState("Transfert des souvenirs ...")
  const profiles_cards = await transferFireUserCard(profile)
  console.log(`---- [${profiles_cards?.length}] création des profiles cards terminé`)
  setState("Transfert des espaces des travail ...")
  const profiles_workspaces = await transfertFireUserWorkspace(profile, profiles_cards?.length)
  console.log(`---- [${profiles_workspaces?.length}] création des profiles workspace terminé`)

  const profileGoodFormat = transformDatesToJS(profile)
  console.log('update 2')
  profile?.id && await updateDoc(doc(db, 'users', user.id), {supa_profile_id: profile?.id || null})
  set(profileGoodFormat)
  return profileGoodFormat;

} catch (err) {
  console.log('err', err)
  console.log('err typeof', typeof err)
  console.log('JSON.stringify(err)', JSON.stringify(err))
  const errMsg = err.message || err.toString() || JSON.stringify(err) || err
  console.log('errMsg', errMsg)
  sbCreate('logs', {type: "error", text: err.message || err.toString() || JSON.stringify(err) || err, created_time: new Date(), user_id: user?.id })
  setState(JSON.stringify(err))
}
}

const getProfile = async (fireUser, set = () => {}) => {
    const old_id = fireUser?.id;
    console.log('getProfile 🟢', old_id)
    if (!old_id) return console.error('old_id is required');

    // on vérifie si l'utilisateur existe dans supa 

    const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('old_id', old_id)
        .single()


    if (!data) {
      console.log('AUCUN PROFILE TROUVE')
      // la fonction ne se lance qu'une seule fois par user après l'utilisateur reste bloqué en V3


      // si le profile de l'utilisateur n'existe pas dans supa on le créer à partir de user
        // 
      // on ajoute modifie l'utilisateur firebase avec isV3 = data.id 

      // récupère toutes ses userCards
      // on récupère toutes ces userWorkspaces
        // on récupère ses total coins à partir de xp_table (xp cumulé)
        // on récupère ses total elixirs à partir de xp_table (xp cumulé)
        // création date
    }

    if (data) {
      // si l'utilisateur existe on peut update l'utilsateur 
    }

    const dataGoodFormat = transformDatesToJS(data)

    if (error) {
        console.error(error);
        return null;
    }

    console.log('data', dataGoodFormat)
    set(dataGoodFormat)
    return dataGoodFormat;
};



const sbUpdate = async(field, id, updated_data, set = () => {}) => {
  console.log(`🟣 update ${field} #${id}`, updated_data)
  console.log(updated_data)
  const { id: _ = undefined, ...dataWithoutId } = updated_data;
  const { error, data} = await supabase
      .from(field)
      .update({...dataWithoutId })
      .eq('id', id);

  if (error) console.log('error sbUpdate', error);
  set(prev => ({...prev, ...updated_data}))
}

const sbUpdateAndGet = async(field, id, data, set = () => {}) => {
  console.log(`🟣 sbUpdateAndGet ${field} #${id}`, data)
  const { error, data: dataDb } = await supabase
      .from(field)
      .update({...data })
      .eq('id', id)
      .select(); // Ajoutez cette ligne

  if (error) console.log('error', error);
  console.log('dataDb', dataDb)
  set(prev => ({...prev, ...dataDb[0]})) // Notez le [0] car select retourne un tableau
}

const getProfileWorkspaces = async (profile_id) => {
  console.log(`🟢 getProfileWorkspace ${profile_id} `)
  // on récupère tout les profiles_workspaces avec profile_id trié par update_time
  const { data, error } = await supabase
      .from('profiles_workspaces')
      .select('*')
      .eq('profile_id', profile_id)
      .order('updated_time', { ascending: false })

  if (error) {
      console.log('error getProfileWorkspace', error);
      return null;
  }

  // on fait setProfileWorkspace sur le dernier en date
  if (data) {
      console.log('data getProfileWorkspace', data)
      return data;
  }

  return null;
}

const sbCreate = async(field, data, set = () => {}) => {
  console.log(`🟢 create ${field}`, data)
  const { id: _, ...dataWithoutId } = data  // On extrait et ignore l'id
  const { data: newData, error } = await supabase
      .from(field)
      .insert([{ ...dataWithoutId }])
      .select()
      .single();

  if (error) console.log('error', error);
  console.log('newData', newData)
  if (newData) set(prev => ({...prev, ...newData}))
  return newData;
}

const getWorkspaces = async(set = () => {}) => {
  console.log('getWorkspaces 🟢')
  let query = supabase
      .from('workspaces')
      .select(`*`)
      .eq('isPublic', true) 
  
  const { data, error } = await query;
  console.log('data', data)
  if (error) console.log('error', error);
  else set(data);
 
  return data
}


const getProfilesCards = async (workspace_id, profile_id, set) => {
    console.log(`getProfilesCards 🟢 #wp_${workspace_id} #p_${profile_id} `)

        try {
            const { data, error } = await supabase
              .rpc('get_learn_cards', {
                p_profile_id: profile_id,
                p_workspace_id: workspace_id
               
              });
      
            if (error) throw error;
            const dataGoodFormat = transformDates(data)
            
            console.log('Résultat:', dataGoodFormat);

            set(dataGoodFormat)
            
            // Traite les données ici
          } catch (error) {
            console.error('Erreur:', error.message);
          }
        };

        const sbAdd = async (field_id, data) => {
            const { data: newRecord, error } = await supabase
                .from(field_id)
                .insert([{...data}])
                .select()  
                .single(); 
        
            if (error) {
                throw error;
            }
        
            return newRecord.id; 
        }




const getNextCardsByTheme = async (workspace_id, profile_id, array_decks_id, set) => {
  console.log(`getNextCards 🟢 #wp_${workspace_id} #p_${profile_id} `, array_decks_id)

  try {
    const { data, error } = await supabase
      .rpc('get_cards_not_in_profile_with_decks', {
        p_workspace_id: workspace_id,
        p_profile_id: profile_id,
        p_array_decks_id: array_decks_id
      });

    if (error) throw error;
    const dataGoodFormat = data
    
    console.log('Résultat getNextCards:', dataGoodFormat);
    console.log('set', set);
    set(dataGoodFormat)
    
    // Traite les données ici
  } catch (error) {
    console.error('Erreur:', error.message);
  }
};


const getNextCards = async (workspace_id, profile_id, set = () => {}) => {
    console.log(`getNextCards 🟢 #wp_${workspace_id} #p_${profile_id} `)

    try {
      const { data, error } = await supabase
        .rpc('get_next_cards', {
          workspace_id: workspace_id,
          profile_id: profile_id
        });

      if (error) throw error;
      const dataGoodFormat = data
      
      console.log('Résultat nextCards:', dataGoodFormat);
      console.log('set', set);
      set(dataGoodFormat);
      return dataGoodFormat
      
      // Traite les données ici
    } catch (error) {
      console.error('Erreur:', error.message);
    }
  };

// all
// 20 nouvelles cartes (pour apprendre dans news)
// toutes les userCards à réviser (id, term, lvl, date, img, trad)
// tester avec toute mes souvenirs de toutes les langues (3000)

// foret 
// toutes les cartes dans la fenettre x et y de la forêt
// le userWorkspace (toutes les userDeco seront dans BaseJsonB et DecoJsonB)

// league
// toute les profiles avec leur xp_week, et leur streak

// home
// tout les decks et leur % 

export {getProfile,getWorkspaces, 
  getProfileWorkspaces, sbUpdateAndGet,
  addNotifications,
  addXpToProfile,
  addXpToProfileWorkspace,
  getDaily,
  getNextCardsByTheme, sbUpdate,sbCreate, getNextCards, getProfilesCards, sbAdd, transfertFire}