import { useEffect } from "react";
import { sbUpdate, sbUpdateAndGet } from "../DbFunction"
import { useStateContext } from "../StateProvider"
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';


const MiniWorkspace = ({wp}) => {
    const { setProfile, profile, setProfileWorkspaces,  setProfileWorkspace, profileWorkspaces} = useStateContext()
    const nav = useNavigate()
    const hasProfileWorkspace = profileWorkspaces.find(e => e.workspace_id == wp?.id)


// const uwp = userWorkspaces?.find(uwp => uwp.workspace_id == wp.id)

return <div className={`mt-4  rounded-md w-[90px] text-white capitalize` } 
onClick={() => {
    // sbUpdate("profiles", profile?.id, {last_workspace_id: wp?.id}, setProfile)
    if (hasProfileWorkspace) {
        console.log('on change de userWorkspace')
        // mettre à jour le workspace et on le récupère
        sbUpdateAndGet("profiles_workspaces", hasProfileWorkspace?.id, {workspace_id: wp.id, updated_time: new Date()}, setProfileWorkspace)
    } else {
        // on créer le userWorkspace
        console.log('création du userWorkspace', wp?.id)
        setProfileWorkspace({workspace_id: wp?.id, profile_id: profile?.id})
    }
     
    
    nav('/')
}}>
    <img src={wp.img}  className={`h-[60px] ${hasProfileWorkspace ? "!border-2 !border-yellow-500" : ""} cursor-pointer hover:scale-[1.2] transition-all hover:rotate-[15deg] mb-2 border-b-4 border-2 border-slate-900 mx-auto rounded-full ${wp?.uwp ? "border-white" : ""}`}/>
    {wp?.name} 
    </div>}




const SelectWorkspace = () => {
    const { workspaces, profile, setShowBottomBar, profileWorkspaces} = useStateContext()

    useEffect(() => {
        setShowBottomBar(false)
        return () => { setShowBottomBar(true)}
    }, [])

    return <div id={"selectWorkspace"} className="fixed top-0 left-0 right-0 bottom-0 z-[61]  text-white bg-gradient-to-b to-[#5A3591] from-[#36206C] overflow-scroll h-[100vh] p-8 text-center  text-white min-h-screen ">
        <h2 className="text-white text-base mb-8">Quelle langue souhaites-tu apprendre ?</h2>
        <div className="flex gap-2 justify-center  flex-wrap">{workspaces.sort((a,b) => a.order - b.order).filter(e => e.img).map(e => <MiniWorkspace wp={e}/>)}</div>
        </div>
}

export {SelectWorkspace}