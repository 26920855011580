import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, capitalizeFirstLetter } from "../../utilities/helper/text.js";
import { NewScreen } from './newScreen.js';
import { CultureG } from './cultureG.js';
import { StoryScreen } from './storyScreen.js';
import { VocabScreen } from './vocabScreen.js';
import { ChatScreen } from './🐈chatScreen.js';

// setText_50k(removeDigits(data).split(' '))
// setInt_50k(removeNoDigits(data).split('\n'))

const RedDot = ({className}) => <div className={`h-3 w-3 rounded-full bg-red-500 border border-black ${className} `} />


const LockScreen = ({title, description, id, goal, current, ct }) => {
  const _title = title 
  const _description = description ? description : ""
  return <div className='text-center p-4 fredoka text-white'>
    <img className='h-[100px] my-12 mb-6 mx-auto' src={"/images/UX/tuto/cool_seed.png"}/>
    <div className='font-semibold'>{_title}</div>
    <div className='mb-12'>{_description}</div>
    <Cadena id={id} goal={goal} current={current} ct={ct} />
  </div>
}

const Cadena = ({id, goal, current, ct}) =>{
  const locked = current >= goal
  const left = goal - current
  const cent = Math.round(current*100/goal)
  return <div className={`p-4 py-3 rounded-xl  ${!locked ? "bg-black/10 border-2 border-black/10" :""} `}>
    {!locked && <div>
  
      <div>
      Apprends encore {left} carte{left>1 && "s"} pour déverrouiller cette fonctionnalité</div>
      <div className='relative transition-all mt-2 h-2 bg-black/20 rounded-full w-full '>
      <div style={{width: cent+'%'}} className='h-2 bg-amber-500 rounded-full '></div>
      </div>
    </div>}
    {locked && <div className='px-4 border-2 border-b-4 py-2 border-black/80 rounded-xl bg-indigo-500 mt-4' onClick={() => {
       ct.setUserWorkspace(e => ({...e, [`unlock_${id}`]: true }))    
       updateDoc(doc(db, "user_workspace", ct.userWorkspace?.id), {[`unlock_${id}`]: true} )
      }}>Déverrouiller</div>}
    </div>
}



const removeNonLetters = (text) => {
  if (!text) return '';
  
  // Utiliser une expression régulière pour supprimer tout caractère qui n'est pas une lettre
  return text.replace(/[^a-zA-Z]/g, '');
};

const SuperText = React.memo(({ text, ct, cards, decks, imgContext, path, darkMode, trad, tradMode, colorText, className }) => {
  const [selectedWord, setSelectedWord] = useState(null);
  const [processedSentences, setProcessedSentences] = useState([]);

  // console.log('trad', trad)

  const tradArray = splitNumeredSentence(trad)
  const textArray = splitNumeredSentence(text?.trim())
  // console.log('trad', trad)

  // console.log('splitNumeredSentence', splitNumeredSentence(text))

  const grammarDecks = decks?.filter(e => e.presentation_mode === "Grammar");

  const blackList = [];
  const allInflexionsFromDecks = grammarDecks?.map(e => e.words?.toLowerCase().split(';')).flat();
  // console.log('allInflexionsFromDecks', allInflexionsFromDecks)

  useEffect(() => {
   
    if (colorText) {
      const processed = textArray.map(sentence => // Split each line into sentences
      sentence.replace(/\|/g, '').replace(/\n\n/g, '\n\n ').split(/(?![\r\n])\s/).filter(word => word !== ' ').map(word => ({
        word,
        cleanedWord: clean(word),
        hasSpace: word.includes('\n\n'),
        isInCards: !!findWord(ct.cards, clean(word)),
        isInDecks: !!allInflexionsFromDecks?.includes(clean(word)),
        isDel: !!blackList?.includes(clean(word)),
        isGram: !!allInflexionsFromDecks?.includes(clean(word)),
        isBlackList: !!blackList?.includes(clean(word)),
        inCards: !!findWord(ct.cards, clean(word)),
      }))
    ) 
  
    setProcessedSentences(processed);
    } else {
      const processed = textArray.map(sentence => // Split each line into sentences
      sentence.replace(/\|/g, '').replace(/\n\n/g, '\n\n ').split(/(?![\r\n])\s/).filter(word => word !== ' ').map(word => ({
        word,
        cleanedWord: clean(word),
        hasSpace: word.includes('\n\n'),
        isInCards: null,
        isInDecks: null,
        isDel: null,
        isGram: null,
        isBlackList: null,
        inCards: null,
      })))
      setProcessedSentences(processed);
    }
    
  }, [text, cards, decks, colorText]);

  const handleClick = (word, sentence, trad, e) => {
    const top = e.target.getBoundingClientRect().top + window.scrollY;
    const center = e.target.getBoundingClientRect().left + (e.target.offsetWidth / 2);


    setSelectedWord({ word, sentence });
    const cleanedWord = clean(word);
    const regex = new RegExp(`\\b${cleanedWord}\\b`, 'g');
    const cleanSentence = removeNonLetters(sentence)
    const index = textArray.findIndex(e => removeNonLetters(e).includes(cleanSentence))
    const card = findWord(ct.cards, cleanedWord);
    console.log('card !!', card)
    ct.setMagnifier(card ? {...card, sentence: sentence, path, sentence_trad: trad, top: top, imgContext: imgContext, word: cleanedWord, center: center} : {top: top, path, center: center, word: cleanedWord, sentence, imgContext: imgContext, sentence_trad: trad });
  };

  const getHighlightedSentence = (sentence, word) => {
    const cleanedWord = clean(word);
    const regex = new RegExp(`\\b${cleanedWord}\\b`, 'g');
    return sentence.replace(regex, `[${cleanedWord}]`);
  };

  // console.log('processedSentences', processedSentences)
  const green = darkMode ?"text-green-300" : "text-emerald-700"
  const decorationGreen = darkMode ?"decoration-green-300/50" : "decoration-emerald-700/50"

  
  return (//sentence.map(w => w.word).join(" ")
    <div className={className}>
      {processedSentences.map((sentence, sentenceIndex) => (
            <><span className={`${tradMode && "mt-2"}`} key={sentenceIndex}>
              {sentence.map(({ word, cleanedWord, isInCards, isInDecks, hasSpace }, wordIndex) => (
                <><span 
                  key={wordIndex} 
                  onClick={(e) => {
                   
                    handleClick(word, textArray[sentenceIndex], tradArray[sentenceIndex], e)

                  }}
                  className={`hover:text-yellow-500 ${isInCards ? `${green} ${decorationGreen}  ` : isInDecks ? green : ""} underline decoration-slate-400 underline-offset-4 decoration-dashed `}
                >
                  {word}
                </span>{" "}{hasSpace && <><br/><br/></>}</>
              ))}
            </span>
            {tradMode && <div className={`text-sm ${darkMode ? "text-white/50" : "text-black/40"} italic mb-2`}>{tradArray[sentenceIndex]}</div>}
            </>
          ))}
         
        </div>)
       
      
     
      {/* {selectedWord && (
        <div className="mt-4">
          <strong>Selected Word in Context:</strong> <br />
          {getHighlightedSentence(selectedWord.sentence, selectedWord.word)}
        </div>
      )} */}
   

}, (prevProps, nextProps) => {
  return prevProps.text?.length === nextProps.text?.length 
    && prevProps.cards === nextProps.cards
    && prevProps.tradMode === nextProps.tradMode 
    && prevProps.colorText === nextProps.colorText
    && prevProps.decks?.length === nextProps.decks?.length;

});

export default SuperText;













const PerfectScreen = (props) => {
    const navigate = useNavigate()
    const menu = props.menu
    const lockerScreen = props.lockerScreen

  const ct = props.context

  useEffect(() => {
    ct.setShowBottomBar(true)
}, [])



  const formatTree = (decks) => decks.map(deck => {

    const parent_deck = decks.find(d => d.id == deck.deck_id)
    const child_decks = deck.id && decks.filter(d => d.deck_id == deck.id)
    return {...deck, parent_deck, child_decks}
  })







const handlePerfectPath = (path) => {
    fire.updateUserWorkspace({...ct.userWorkspace, perfectPath: path} , ct)
}

const selectedPath = menu.find(m => m.pathName == ct.userWorkspace?.pathName)



const text = `
Él: Hola, ¿cómo estás? ¿Qué tal tu día? Ella: Hola, estoy bien, gracias. Mi día ha sido un poco estresante en el trabajo, pero nada fuera de lo normal. ¿Y el tuyo? Él: Pues, ha sido un día tranquilo. Fui al gimnasio por la mañana y luego hice algunas tareas en casa. ¿Tienes planes para esta noche? Ella: No, no tengo nada planeado. Estaba pensando en quedarme en casa y ver una película. ¿Te gustaría venir? Él: ¡Claro! Me encantaría. ¿Qué película tienes en mente? Ella: Estaba pensando en ver esa nueva comedia que salió la semana pasada. Dicen que es muy divertida. Él: Suena bien. ¿Quieres que lleve algo? Puedo pasar por la tienda y comprar algunas botanas. Ella: No te preocupes, tengo algunas cosas aquí en casa. Pero si quieres traer algo, no me opongo. Él: De acuerdo, traeré algunas bebidas entonces. ¿A qué hora quieres que llegue? Ella: ¿Te parece bien a las siete? Así tenemos tiempo de charlar un poco antes de la película. Él: Perfecto, estaré allí a las siete. ¿Quieres que pase por algo más en el camino? Ella: No, creo que con las bebidas será suficiente. Ah, y si puedes, trae un poco de hielo también. Él: Claro, no hay problema. Me aseguraré de traer suficiente hielo para las bebidas. Ella: Genial. Oye, ¿has hablado con Juan últimamente? Hace tiempo que no sé nada de él. Él: Sí, hablé con él hace un par de días. Está bien, ocupado con su nuevo trabajo, pero dice.
`

// Histoire
// Faire un formatage phrase par phrase. (pour simpléfier la traduction et bien la segmenter)

// Culture G, actualité 
// But présenter du texte 
// Action | "En savoir plus" : "Apprendre" : "Ne pas apprendre" : "Changer de thème"
// Faire Tinder ou Tiktok mode

// faire un générateur de phrase pour les RDV "Emilie la RH de truc avec MindSeed"

// culture G, ajouter 10 cartes culture G au format culture G 
// (titre (question), content en supereditor, image) 


// Helper function to clean text

// Main component

// console.log('menu', menu)

const currentCards = 400

    return <div className="px-2 rounded-xl mb-2 mt-4 ">
          <div>
            {!ct.userWorkspace?.pathName && menu.map(m => 
              <div onClick={() => {
                ct.fire.updateUserWorkspace({...ct.userWorkspace, pathName: m.pathName}, ct)
              }
                } className={`p-4 rounded-xl  ${m.ready ? "bg-white/30 border-black/80" : 'bg-white/10'} border-2 border-b-4 border-black/50 mb-2 flex gap-4`}>
               <img src={m.icon} className="h-[50px]"/>
            
                <div className="flex-grow">
                 
                  <div className="font-semibold fredoka">{m.name}</div>
                  <div className="text-sm">{m.description.replace("@lang", (ct.workspace?.name))}</div>
                  {(m.available) ? 
                  <div className='flex justify-end mt-2 -mb-2'><div className='bg-indigo-500/90 text-white border-2 border-b-4 fredoka border-indigo-900 rounded-xl px-4 py-1 my-2'>Commencer →</div></div> : ""
                  // <div className='flex'><div className='bg-slate-600/90 text-sm text-white rounded-xl px-3 py-1 opactity-50 my-2'>Vérouillé</div></div> 
                }

                  {/* {(m.beta) && <div className='flex'><div className='bg-amber-600/90 text-sm text-white rounded-xl px-2 my-2'>Beta disponible</div></div>} */}

                  {/* {(!menu.ready && !menu.beta) && <div className='flex'><div className='bg-blue-500 text-white rounded-xl px-2 my-2'>Bientôt disponible</div></div>} */}
                </div>
              </div>
            )}

   

            <div className="mt-0">

             
                {/* <div className="font-semibold mt-2">{selectedPath?.name}</div> */}
                {/* {selectedPath?.description?.replace("@lang", (ct.workspace?.name))} */}
                
                  
                 
                 
                  {selectedPath?.name == "Actualités" && (!lockerScreen.news.access || !lockerScreen.news.unlocked ? <LockScreen id={"news"} goal={lockerScreen.news.goal} current={lockerScreen.tt} ct={ct} /> : <NewScreen liane={props.liane} ct={ct}  />)}
                  {selectedPath?.name == "Immersion intéractive" && (!lockerScreen.chatbot.access  || !lockerScreen.chatbot.unlocked ? <LockScreen id={"chatbot"} goal={lockerScreen.chatbot.goal} current={lockerScreen.tt} ct={ct} /> :   <ChatScreen  liane={props.liane} ct={ct} />)}
                  {selectedPath?.name == "Vocabulaire" &&  <VocabScreen 
                    liane={props.liane} 
                    popoverPos={props.popoverPos} 
                    setPopoverPos={props.setPopoverPos} 
                    setHoverDeck={props.setHoverDeck}
                    setUserCardDeck={props.setUserCardDeck}
                    setpackCards={props.setpackCards}
                    menu={props.menu}
                    ct={ct}  />}
                  {selectedPath?.name == "Culture Générale" && (!lockerScreen.cultureG.access  || !lockerScreen.cultureG.unlocked ? <LockScreen id={"cultureG"} goal={lockerScreen.cultureG.goal} current={lockerScreen.tt} ct={ct} /> :  <CultureG liane={props.liane} ct={ct}  />)}
                  {selectedPath?.name == "Livres et histoires" && (!lockerScreen.book.access   || !lockerScreen.book.unlocked ? <LockScreen id={"book"} goal={lockerScreen.book.goal} current={lockerScreen.tt} ct={ct} /> :  <StoryScreen liane={props.liane} ct={ct}  />)}


            </div>


         
            
            
            {/* {selectedPath?.ready == false &&   <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">{selectedPath.beta ? "Beta Test" : "Coming Soon"}</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et sera bientôt disponible</div>
                </div>
              </div>} */}



            
          </div>
      </div>

};

export {PerfectScreen, Cadena, RedDot, LockScreen};