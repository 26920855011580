import { useEffect, useState } from "react"
import { useStateContext } from "../../StateProvider"
import { displayDuration, getCardToLearn, shuffleArray } from "../../helperFunctions"
import { SVG_close } from "../../svgs"
import { Flashcard } from "../flashcard/flashcard"
import { addElements, addXpToProfile, getNextCards, sbAdd, sbUpdate } from "../../DbFunction"
import { DailyMission } from "./DailyStreak"




const Header = ({setShowModal, newCards, discoverPoints, discoverPointsTotal, todoCards}) => {
         
        const {profile, setProfile} = useStateContext()

        return <div className={"p-2 flex gap-2 text-white justify-between items-center"}>
                <SVG_close onClick={() => {
                        setShowModal()
                        sbUpdate("profiles", profile.id, {blocked: true})
                        setProfile(prev => ({...prev, blocked: true}))
                  
                        }}/>
                {newCards ? <div className="text-amber-300 mt-1 text-center grow">
                        <div className="h-2 rounded-xl w-full bg-black/20 overflow-hidden ">
                                <div className="h-full game-btn bg-amber-500 rounded-xl transition-all" style={{width: `${discoverPoints*100/discoverPointsTotal}%`}}></div>
                        </div>
                        {/* Nouvelle carte {discoverPoints}/ {discoverPointsTotal} */}
                        </div> 
                        : <>
                        <div className="h-2 bg-white/20 transition-all grow rounded">
                              <div className="h-full game-btn bg-amber-500 duration-[700] rounded-xl transition-all" style={{width: `${todoCards?.filter(e => e.session.valid).length*100/todoCards?.length}%`}}></div>
                        </div>
                        {todoCards?.filter(e => e.session.valid).length} / {todoCards?.length}
                        </>}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4"><path fillRule="evenodd" d="M6.455 1.45A.5.5 0 0 1 6.952 1h2.096a.5.5 0 0 1 .497.45l.186 1.858a4.996 4.996 0 0 1 1.466.848l1.703-.769a.5.5 0 0 1 .639.206l1.047 1.814a.5.5 0 0 1-.14.656l-1.517 1.09a5.026 5.026 0 0 1 0 1.694l1.516 1.09a.5.5 0 0 1 .141.656l-1.047 1.814a.5.5 0 0 1-.639.206l-1.703-.768c-.433.36-.928.649-1.466.847l-.186 1.858a.5.5 0 0 1-.497.45H6.952a.5.5 0 0 1-.497-.45l-.186-1.858a4.993 4.993 0 0 1-1.466-.848l-1.703.769a.5.5 0 0 1-.639-.206l-1.047-1.814a.5.5 0 0 1 .14-.656l1.517-1.09a5.033 5.033 0 0 1 0-1.694l-1.516-1.09a.5.5 0 0 1-.141-.656L2.46 3.593a.5.5 0 0 1 .639-.206l1.703.769c.433-.36.928-.65 1.466-.848l.186-1.858Zm-.177 7.567-.022-.037a2 2 0 0 1 3.466-1.997l.022.037a2 2 0 0 1-3.466 1.997Z" clipRule="evenodd" /></svg>

        </div>
}


// quand on apprends une carte dans le TOEIC on ajoute pas les 2 triggers juste 1

// trie les cartes par triggers et ajoute session
const setSession = (cardsToLearns) => {
        return cardsToLearns?.sort((a,b) => a.profile_card.next_trigger.localeCompare(b.profile_card.next_trigger)).map((e,i) => {
                return {...e, session: {i: i, valid: false, count: 0}}
        })
}

//fais une fonction qui trouve l'index dans todoCards de la carte avec le plus petit count et valid false
const getIndex = (toDoCards) => {
        // Filtre d'abord les cartes avec valid false
        const invalidCards = toDoCards.filter(card => !card.session.valid);
        
        // Si aucune carte invalide, retourne -1
        if (invalidCards.length === 0) return -1;
        
        // Trouve la carte avec le plus petit count parmi les cartes invalides
        const minCount = Math.min(...invalidCards.map(card => card.session.count));
        
        // Retourne l'index original dans todoCards
        return toDoCards.findIndex(card => 
            !card.session.valid && card.session.count === minCount
        );
    }

    const updateCard = () => {
        // on update la carte dans todo
        // on update la carte dans learnedCards
        // on update la carte en db
}

// nouvelle cartes 
// si validation on créer 2 todoCartes
// libérer les graines que toutes les 4 plantes

const DiscoverCards = ({todoDiscover, addToDoCards, setTodoDiscover, setDiscoverPoints, randomCards}) => {
        const { nextCards, setNextCards, workspace, profile, learnedCards, setLearnedCards } = useStateContext()

        const [validedCards, setValidedCards] = useState(0)


        const passCard = (card) => { 
                setNextCards(prev => prev.filter(c => c.id != card.id))
                if (nextCards.length === 5) getNextCards(workspace.id, profile.id, setNextCards)
        }
        

        const handleValidCard = async(card) => {
               
                addToDoCards(card);
                setValidedCards(prev => prev + 1)
                passCard(card)
                console.log('validedCards', validedCards)
                if (validedCards == todoDiscover.length - 1) {
                        setTodoDiscover()
                        console.log('erminé !!')
                }
        }

        const handleArchived = async(card) => {
                // on créer une userCards
                const profile_card = {
                        workspace_id: workspace.id,
                        profile_id: profile.id,
                        card_id: card.id,
                        archived: true,
                }
                const id = await sbAdd("profiles_cards", profile_card)
                
                // setLearnedCards(prev => prev.map(e => e.card.id == card.id ? {...e, profile_card: profile_card} : e))
                setLearnedCards(prev => [...prev, {card: card, profile_card: {...profile_card, id: id}}])
                passCard(card)    
        }



        return <div className="flex justify-center ">
                <Flashcard setDiscoverPoints={setDiscoverPoints} workspace={workspace} randomCards={randomCards.filter(e => e.id != nextCards[0]?.id)} handleArchived={handleArchived} validCard={() => handleValidCard(nextCards[0])} card={nextCards[0]} mode={"discover"}/>
                {/* {nextCards.slice(0,4-validedCards)?.map(e => <div className="px-4 p-2  flex justify-between">{e.term} 
                <div onClick={() => {handleArchived(e)}}>Archiver</div> 
                <div onClick={() => {handleValidCard(e)}}>Ajouter</div></div>)} */}
        </div>
}


const LearnCards = () => {
        const {  setShowModal, profile, setTutoTriggers, profileWorkspace, daily, setDaily, setProfileWorkspace, setProfile,setShowBottomBar, learnedCards, setLearnedCards, cardsToRefresh, cardsToDiscover ,selectedNewCards, nextCards, workspace, bonusPoints, setBonusPoints } = useStateContext()
        const [todoCards, setTodoCards] = useState([])
        const cardsToLearns = setSession(cardsToRefresh)
        const [todoDiscover, setTodoDiscover] = useState([])
        const [index, setIndex] = useState(0)
        const currentCard = todoCards?.[index]
        const [randomCards, setRandomCards] = useState([])
        const [beforeCards, setBeforeCards] = useState()
        const [state, setState] = useState(0)


        useEffect(() => {
                console.log('--cardsToDiscover', cardsToDiscover)
                console.log('--cardsToLearns', cardsToLearns)
                const all = [...cardsToLearns || [], ...cardsToDiscover?.map(e => ({card: e, profile_card: null})) || []]
                console.log("all", all)
                setBeforeCards(all)
                setState(0)
        }, [])
        
        
        const updateProfileCard = (profile_card_id, data) => {
                console.log(data)
                if (data){
                        console.log(`updateProfileCard pour ${profile_card_id}`, data)
                        
                        setTodoCards(prev => prev.map(c => c.profile_card.id === profile_card_id ? {...c, profile_card: {...c.profile_card, ...data}} : c))
                        setLearnedCards(prev => prev.map(c => c.profile_card.id === profile_card_id ? {...c, profile_card: {...c.profile_card, ...data}} : c))
                        sbUpdate("profiles_cards", profile_card_id, data)
                }
        }

        useEffect(() => {
                // if (!currentCard) return
                const cards = learnedCards?.length > 7 ? learnedCards?.map(e => e.card)?.filter(e => e.id != currentCard?.card.id) || [] : learnedCards?.map(e => e.card)?.concat(nextCards).filter(e => e.id != currentCard?.card.id) || []
                let filteredCards = cards
                if (currentCard?.card?.img) {
 
                        filteredCards = cards.filter(e => e.img != null && e.id != currentCard?.card.id) || []
                } 

                console.log('filteredCards', filteredCards)
                console.log('currentCards', currentCard)
               

                const suffledCards = shuffleArray(filteredCards)?.slice(0,8)

                setRandomCards(suffledCards)
        }, [currentCard, learnedCards])
         
        useEffect(() => {
                setTodoCards(cardsToLearns) // liste de tout les exercices
                setTodoDiscover(cardsToDiscover)
                setShowBottomBar(false)
                return () => {
                        setShowBottomBar(true)
                }
        }, [])

        
        const validCard = ({valid, bonus}) => {
                console.log('validCard')
                const _toDoCards = [...todoCards.map(e => e.session.i == currentCard.session.i ? {...e, session: {...e.session, count: e.session.count + 1, valid: valid}} : e)]
                setTodoCards(prev => prev.map(e => e.session.i == currentCard.session.i ? {...e, session: {...e.session, count: e.session.count + 1, valid: valid}} : e))
                const newIndex = getIndex(_toDoCards)
                if (index == newIndex) {
                        setState(1)
                        sbUpdate("profiles", profile.id, {blocked: true})
                        setProfile(prev => ({...prev, blocked: true}))
                        if (!profile?.events?.tt_end_first_session) setTutoTriggers(prev => [...prev || [], "end_first_session" ])
                }

                if (newIndex == -1) {
                        setState(1)
                        sbUpdate("profiles", profile.id, {blocked: true})
                        setProfile(prev => ({...prev, blocked: true}))
                        if (!profile?.events?.tt_end_first_session) setTutoTriggers(prev => [...prev || [], "end_first_session" ])

                }
                console.log('validCard index', newIndex)
                setIndex(newIndex)
                // updateCard(valid, bonus)      
                console.log('profileWorkspace', profileWorkspace)
                const xpToAdd = (valid? 1:0) + (bonus||0)
                sbUpdate('profiles_workspaces', profileWorkspace?.id, {tt_xp: (profileWorkspace.tt_xp || 0) +xpToAdd})
                setProfileWorkspace(prev => ({...prev, tt_xp: (profileWorkspace.tt_xp || 0) +xpToAdd}))
                addXpToProfile(profile, valid ? 1 : 0, bonus == 1 ? 1 : 0, setProfile)
                console.log('daily', daily)
                sbUpdate('profiles_dailies', daily.id, {tt_xp: (daily.tt_xp || 0) + xpToAdd})
                setDaily(prev=> ({...prev, tt_xp: (daily.tt_xp || 0) + xpToAdd}))
                // console.log(`gain: valid: ${valid} bonus: ${bonus}`)
        }



        const addToDoCards = async (card, opt) => {
                console.log('card', card)

                // créer profile_card
                const triggers = [
                        {next_date: new Date(),lvl: 1},
                        {next_date: new Date(),lvl: 1},
                ]

                const profile_card = {
                        lvl: 1,
                        last_update: new Date(),
                        card_id: card.id,
                        collected: false,
                        next_date: new Date(),
                        next_trigger: "0",
                        profile_id: profile.id,
                        workspace_id: workspace.id,
                        triggers: triggers
                }

                console.log('new profile_card', profile_card)

                // ajouter en db
                const id = await sbAdd("profiles_cards", profile_card)
            

                 // ajouter dans todo
                setTodoCards(prev => [...prev || [], 
                        {card: card, profile_card: {...profile_card, id }, session: {i: todoCards?.length || 0, valid: false, count: 0, trigger: 0}},
                        {card: card, profile_card: {...profile_card, id, next_trigger: "1"}, session: {i: todoCards?.length + 1 || 1, valid: false, count: 0, trigger: 1}}
                ].sort((a, b) => a.session?.trigger - b.session?.trigger))



                // ajouter dans learnCard
                setLearnedCards(prev => [...prev, {card: card, profile_card: {...profile_card, id: id}}])
                console.log('   ', daily)
                addXpToProfile(profile, 2, 0, setProfile)
                sbUpdate('profiles_workspaces', profileWorkspace?.id, {tt_xp: (profileWorkspace.tt_xp || 0) + 2})
                setProfileWorkspace(prev => ({...prev, tt_xp: (profileWorkspace.tt_xp || 0) + 2}))
                sbUpdate('profiles_dailies', daily.id, {tt_xp: (daily.tt_xp || 0) + 2})
                setDaily(prev=> ({...prev, tt_xp: (daily.tt_xp || 0) + 2}))


                
        }


        const [discoverPoints, setDiscoverPoints] = useState(0)

        
       

    return <div onClick={(e) => {
        setShowModal(); 
        e.stopPropagation()
        
     


        }} className="bg-indigo-900/80 backdrop-blur-[4px] z-[80] fixed top-0 left-0 right-0 bottom-0">
                {/* {state == 0 && <div>
                        <DailyMission />
                        </div>} */}
                
                {state == 1 && <div className="p-8">
                        <div className="text-white mt-12 text-center ">Mise à jour des graines</div>
                        <div style={{maxHeight: "calc(100vh - 200px)"}} className={`mx-auto ${beforeCards?.length < 5 ? "max-w-[240px]" : ""}  pt-8 overflow-scroll`}>
                        <div  className={` ${beforeCards?.length < 5 ? "grid-cols-2 grid" : "flex flex-wrap"}   gap-2 gap-y-8`}>{beforeCards?.map(e => {
                        const lastVersion = learnedCards.find(ee => ee.card.id == e.card.id)
                        return <div className={`bg-[#502082] ${beforeCards?.length < 5 ? "" :"w-[70px]  max-w-[70px]"}  grow border-2 border-[#502082] text-white relative rounded-xl`}>
                                {e?.profile_card ? <div className={`${beforeCards?.length < 5 ? "px-3  -top-4" : "px-1 pr-2 text-[11px] -top-2"} items-center absolute rounded-xl flex gap-1 bg-indigo-500 text-white`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
                                </svg> Niv +1
                                {/* {e?.profile_card?.lvl || 0} → {lastVersion?.profile_card?.lvl || 0} */}
                                
                                </div>
                                : <div className="absolute -top-4 px-2 rounded-xl bg-[#ff7e00] text-white">Nouveau</div> }
                                <div className={`  ${beforeCards?.length < 5 ? "h-[100px]":"h-[50px]" } rounded-t-xl bg-emerald-500 w-full`}>{e.card?.img && <img src={e.card?.img} className="w-full rounded-t-xl h-full object-cover" />}</div>
                                <div className={` truncate ${beforeCards?.length < 5 ? "text-sm" : "text-[12px] max-w-[65px]"} py-1 pb-0 px-1 text-center fredoka`}>
                                {e?.card?.term}
                                <div className="text-purple-100/80 -mt-[1px] pb-[2px] text-[10px]">{displayDuration(new Date(), lastVersion?.profile_card.next_date) == "maintenant" ? "à revoir" :displayDuration(new Date(), lastVersion?.profile_card.next_date) }</div>
                                </div></div>})
                        }</div>
                        </div>
                </div>}
                {state == 0 && <div onClick={(e) => {e.stopPropagation()}} className="flex h-full flex-col">
                        {/* --/{todoDiscover?.length}<div className="flex gap-1 flex-wrap">{todoCards?.map(e => <div className={`${e.session.valid ? "bg-green-500" : "bg-red-500"} text-[8px]`}>#{e.session.i}{e.card.term.slice(0, 3)} - {e.profile_card.next_trigger}</div>)}</div> */}
                        <Header todoCards={todoCards} discoverPoints={discoverPoints} discoverPointsTotal={12} newCards={todoDiscover?.length} setShowModal={setShowModal} />
                        <div className=" grow h-full flex flex-col justify-center items-center">
                                {todoDiscover?.length && <DiscoverCards setDiscoverPoints={setDiscoverPoints} workspace={workspace} randomCards={randomCards} setTodoDiscover={setTodoDiscover}  addToDoCards={addToDoCards} todoDiscover={todoDiscover}/> }
                                {!todoDiscover?.length && currentCard && <Flashcard updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards}  mode={"learn"} validCard={validCard} profile_card={currentCard?.profile_card} card={currentCard?.card}/>}
                        </div>
                        {/* <div className="flex text-xs gap-1 flex-wrap" onClick={() => {
                                // console.log('toDo', learnedCards.map(e => displayDuration(e.profile_card.last_update)))
                                 console.log('toDo', todoCards)

                        }}>{todoCards?.map(e => <div className={`bg-white/80 ${e.profile_card?.next_trigger == 0 && " !bg-green-200 "} ${e.profile_card?.next_trigger == 1 && " !bg-purple-200 "} relative`}>
                                <div>{e.card.term}</div>
                                <div className="text-[8px]">t{e.profile_card?.next_trigger} - lvl{e.profile_card?.lvl} - s{e.session.valid ? "ui": "nn"}</div>
                                </div>)}</div> */}
                    
                </div> }

        </div>}


export {LearnCards}