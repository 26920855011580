




const SVG_close = ({ size = 24, onClick = () => {}, className = '' }) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={size} height={size} onClick={onClick} className={`cursor-pointer ${className}`} >
        <path  strokeLinecap="round"  strokeLinejoin="round"  d="M6 18L18 6M6 6l12 12"/>
      </svg>
    );
  };


  const SVG_load = ({ size = 24, onClick = () => {}, className = '' }) => {
    return (<svg width={size} className={`cursor-pointer text-white animate-spin ${className}`} height={size} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>)
  }


  export {SVG_close, SVG_load}



