


const HelloWord = () => {


    return <div className="p-8 text-center rounded-xl bg-blue-600 text-white m-8">
               
        </div>
}

export {HelloWord}