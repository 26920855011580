import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../config.js";
import { calculateAverageWithIncrease, differenceInDays, xpToGrade } from '../../utilities/helper/helper.js';

const ParticipationNote = (props) => {
    const ct = props.context
    // console.log('classes', ct.classes)
    // console.log('xp table', ct.userWorkspace?.xp_table)
    const arrayOfGrade = ct.userWorkspace?.xp_table?.map(exp => xpToGrade(exp.xp))
    // console.log('arrayOfGrade', arrayOfGrade)
    const diffDay = differenceInDays(new Date(), ct.user.updated?.toDate()|| new Date()) + 1
    // console.log('diffDay', diffDay)
    
    const contentParticipation = <div className='text-justify text-sm'>
    Elle permet d'évaluer ton investissement dans ta classe. 
    Ton professeur y a accès et peut s'en servir pour te noter. 
    Plus tu es régulier, plus tu gagneras de points
    <br/>
  
    <div className='bg-black/30 rounded flex justify-between px-2 py-1 mt-2'>Note de participation du jour : <span className='text-amber-100'>{xpToGrade(ct.lastXp)}/20</span></div>
  
    <div className='bg-black/30 rounded flex justify-between px-2 py-1 mt-2'>Note de participation : <span className='text-amber-500'>{Math.round(calculateAverageWithIncrease(arrayOfGrade, diffDay))}/20</span></div>
  
  </div>
 

  return (
    <div onClick={() => ct.setShowInfo({title: "Note de participation", classContent: "bg-purple-700 text-white", 
    content: contentParticipation})} 
    className={`py-2  relative pt-4 rounded-xl bg-black/20 text-white  relative pl-4 pr-10 w-full flex justify-between ${props.className}`}>
        <div className='text-white text-sm  absolute -top-2 -left-2 gap-2 rounded-full px-3  pl-2 flex bg-[#aa4aff] items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
            </svg>
            {ct.user?.class && ct.classes?.find(c => c.id == ct.user?.class[0])?.name}
        </div>
       <div> Ma note du jour</div>
       <div> {xpToGrade(ct.userDaily?.xp)}<span className='text-white/50'>/20</span></div>
       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
      </svg>

    </div>
  );
};

export {ParticipationNote};