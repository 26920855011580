import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { calculateAverageWithIncrease, categories, correctMistake, displayDate, displayDuration, missions, requestInfoMore, shuffleArray, xpToGrade } from '../../utilities/helper/helper.js';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
import { addBracket, capitalizeFirstLetter, clean, fetch50k, findWord, getLemma, getTextInParentheses, gpt, removeDuplicates, removeQuotes, removeTextInParentheses, soundValid, textSize, transformText } from '../../utilities/helper/text.js';
import { db } from '../../config.js';
import { learn } from './learn/learn.js';

import { reuleaux, helix} from 'ldrs'
import { findHint } from './learn/learnContainer.js';

reuleaux.register()



helix.register()

// Default values shown


const setRank = async (card, ct) => {
    let int_50k = ct.int_50k
    let text_50k = ct.text_50k
    if (!int_50k){
        console.log('pas de data on fetch')
        const data =  await fetch50k(ct)
        console.log('data', data)
        int_50k = data.int_50k
        text_50k = data.text_50k
    
         
    }
    
    // console.log('text_50k', text_50k) // affiche la liste text_50k
    // console.log('int_50k', int_50k)
    // console.log('inflexion', card.inflexions) // affiche les inflexions de la carte

    // Initialiser un tableau pour stocker les indices trouvés
    const indices = [];
    const occ = []
    const inflexionArray = [...new Set([...card.inflexions?.map(e => transformText(e)), transformText(card.term)])];
    // console.log('inflexionArray', inflexionArray)

  
    inflexionArray.forEach(inflexion => {

        const index = text_50k?.indexOf(transformText(inflexion));

        if (index !== -1) {
            indices.push(index);
            occ.push(int_50k[index])
        }
    });


    if (indices.length > 0) {
        const minIndex = Math.min(...indices);
        // console.log('Index le plus petit:', minIndex); 
        // console.log('1er occ', int_50k[minIndex])
        const occ_tt = occ.reduce((acc, val) => parseInt(acc) + parseInt(val), 0);
        console.log(card.term, occ_tt)
        
        const newCard = {...card, 
            occ: parseInt(int_50k[minIndex]),
            occ_tt: occ_tt,
            rank_occ: minIndex
        }


        console.log({
            occ: parseInt(int_50k[minIndex]),
            occ_tt: occ_tt,
            rank_occ: minIndex
        })

        return {
            occ: parseInt(int_50k[minIndex]),
            occ_tt: occ_tt,
            rank_occ: minIndex
        }
      
        
    } else {
        console.log('Aucune inflexion trouvée dans text_50k pour', card.term);
    }
}

function filterDefinitions(data) {
    const seenCategories = new Map();

    const filteredDefinitions = data?.definitions?.filter(def => {
        const { categorie, trad_fr } = def;

        // Vérifier si la catégorie a déjà été vue avec un mot similaire dans trad_fr
        if (seenCategories.has(categorie)) {
            const seenWords = seenCategories.get(categorie);
            const similarWords = trad_fr?.split(',')?.map(word => word.trim());

            if (similarWords) for (let word of similarWords) {
                if (seenWords.has(word)) {
                    return false; // Si un mot similaire a déjà été vu, ignorer cette définition
                }
            }

            // Ajouter les nouveaux mots similaires pour cette catégorie
            similarWords?.forEach(word => seenWords.add(word));
        } else {
            // Si la catégorie n'a pas été vue, ajouter et marquer les mots similaires
            const similarWords = new Set(trad_fr?.split(',')?.map(word => word.trim()));
            seenCategories.set(categorie, similarWords);
        }

        return true; // Garder cette définition
    });

    return filteredDefinitions 
}


const DisplayMore = ({more, little, classLittle}) => {

  
    const filteredDef = filterDefinitions(more)?.filter(def => {
        const freq = parseInt(def.freq?.replace('%', ''), 10)
         return !(freq && freq < 11) 
    })

    if (little) return <div className={'flex gap-2 justify-center text-black/60 text-xs text-center'}>{filteredDef?.map(def => <span className={classLittle ? classLittle : 'px-2 bg-black/10 opacity-50 border border-slate-400 text-slate-700 rounded-xl py-1 '}>{def.trad_fr}</span>)}</div>



    return <div onClick={( )=> console.log('more', more)}>
    {filteredDef?.map((def, i) => {
        return <div key={'def_'+i} className='mt-4 '>

            <div className=' text-sm opacity-60 '>{def.trad_fr} {cate.includes(def.categorie) && <span className='bg-black/10 rounded px-2 text-xs'>{def.categorie}</span>}</div>  
            <div className='border-l-2 border-[#e8e0ff] mt-1'>
            {renderColored(def.ex?.[0].text, "text-purple-500 ml-2", "text-purple-600  underline-offset-4 	")}
            {renderColored(def.ex?.[0].trad_fr, "text-slate-400 italic ml-2 text-xs", "text-gray-500  underline-offset-4 ")}
            </div>
        </div>
  })}</div>
} 


const addInflexions = async (term, lang, ct) => {


    const messages = [
     
        {"role": "user", "content": `
        Donne uniquemnent la réponse. 
        Donne toutes les inflexions qui existe pour le mot "${term}" en ${lang} sous la forme du liste séparé par des virgules, uniquement les formes du mot, sans déterminant ni article. 
        toutes les formes possibles du mots sans doublons. y compris les changements de genre et les accords uniquement s'il existe ! 
        N'invente pas de nouveau terme toute les inflexions doivent exister. Les mots doivent existé et correspondre au inflexion du mot "${term}". 
        `}
    
    ]

    const inflexionsPromesse = await gpt(messages, true, ct)
    const inflexions = removeQuotes(inflexionsPromesse.choices[0]?.message.content)
    const array = removeDuplicates(inflexions?.split(',').map(e => e.trim()))
    console.log('inflexions', inflexions)
    console.log('array', array)
    

    return array

    
}

const randomiseForm = (randomNumber) => {
    let perso = ""
    let form = ""

    if (randomNumber >= 6) {
        perso = `en utilisant l'un des pronom suivant au hasard (${shuffleArray(["nous", "vous", "ils/elles", "tu", "je", "je", "tu", "on", "il/elle", "il/elle"])[0]}))`
        console.log("perso", perso)
    }
    if (randomNumber >= 3 && randomNumber <= 5) {
       
        form = shuffleArray(["dans la forme interrogative", "dans la forme négative", "dans la forme formelle"])[0]
    }

    return {perso, form}
}


const completeCard = async (term, lang, ct) => {
    const randomNumber = Math.floor(Math.random() * 10) + 1;
    const {perso, form} = randomiseForm(randomNumber)
    const messages = [
        {"role": "system", "content": "Donne uniquemnent la réponse"},
        {"role": "user", "content": `
        Trouve une phrase simple et naturelle en ${lang} qui contient  le terme "${term}" ${perso} ${form}.
        Le terme doit être dans sa forme de base (lemma). 
        Si le terme est un verbe l'afficher à l'infinif, si c'est un nom au singulier etc.. 
        La phrase doit être correcte et naturelle et doit faire environt 7 à 12 mots.
        Le terme dans la phrases doit être entre [] (ex: this is the [term] to use.)
        Donne la traduction française entre () :
        `}
    
    ]
   // const trad = await gpt(`Traduit en français le term suivant : "${card.term}"`, false, ct)
    const contextData = await gpt(messages, true, ct, {temp: 0.7})
    console.log("contextData", contextData)
    const contextResult = removeQuotes(contextData?.choices[0]?.message.content)
    // console.log("contextResult", contextResult)
    const contextTrad = getTextInParentheses(contextResult)
    const contextText = removeTextInParentheses(contextResult)

   
   return  {text: contextText, trad: contextTrad}
}


const renderColored = (text, className, style) => {
    return <div
    className={className}
    dangerouslySetInnerHTML={{__html: text?.replace(/\[(.*?)\]/g, '[*$1*]').replace(/\[\*(.*?)\*\]/g, `<span class="${style}">$1</span>`)}}
    ></div>
}

const cate = ["nom", "verbe", "adjectif", "adverbe", "préposition", "conjonction", "interjection", "expression", "idiom", "autre"]
const addMore = async ({term, lang, ct}) => {
       
    const requestText = requestInfoMore(lang, term, false)
    
    console.log('requestText', requestText)
    const request =  [{ "role": "user", "content": requestText }]
    const requestData = await gpt(request, true, ct, {temp: 0.3})
    const data_content = requestData.choices[0]?.message?.content;
    return  JSON.parse(data_content)

}

const addCat = async({term, context, ct}) => {
    const text_request = `
    Pour le terme "${term}" dans la phrase "${context}".
    Donne la bonne catégorie parmis "${categories.map((c,i) => ({display: i + " - " +c.name_fr})).map(c => c.display).join(", ")}
    Donne uniquemnent le chiffre de la catégorie.
    `

    console.log('requestText', text_request)
    const request =  [{ "role": "user", "content": text_request }]
    const requestData = await gpt(request, true, ct, {temp: 0.3})
    const data_content = requestData.choices[0]?.message?.content;
    
    return  parseInt(data_content)


}


const AddHint = ({setHint, setCard, _userCard, card, setUserCards, hint}) =>{

    return <div><div className='mt-4 bg-pink-500 rounded-xl flex gap-2'>
                    <textarea id="hint-text" 
                    className='bg-transparent rounded-xl h-16  text-white w-full text-xs p-2' 
                    value={hint}
                    onChange={(e) => setHint(e.target.value)}/>  
                    
                    <div className='flex items-center bg-white/20 justify-center rounded h-12 w-12'>+</div>
                    </div>
                    <div className='flex justify-end -mt-3'><div className='bg-white text-pink-500 border-2 border-pink-500 rounded-full  px-4 ' onClick={async () => {
                     
                        const correctedHint = await correctMistake({sentence: hint})
                         setCard(e => ({...e, hint: correctedHint}))
                         console.log('hintText',hint)
                         console.log('correctedHint', correctedHint)
                         const cardSnapShot =  await updateDoc(doc(db, 'cards', card.id), { hint: correctedHint, lastUpdate: new Date()})
                         const userCard = await updateDoc(doc(db, 'user_cards', _userCard.id), {
                            'content.hint': correctedHint
                          });
                          setUserCards(uc => uc.id == _userCard?.id ? {...uc, content: {...uc.content, hint: correctedHint}} : uc)
                        
                      
                         setHint()
                    }}>Valider</div></div>
                </div>

}
const FlashcardContentLang = ({_card, ct, _userCard, imgContext, path, tradPrint, sentencePrint, setUserCards}) => {
    // ## APPRENDRE LA PHRASE
    // Ajouter le bouton apprendre la phrase

    const [card, setCard] = useState(_card)
    const [hint, setHint]= useState()

    const context = card.contexts?.length && card.contexts[0]
    const category = categories?.[card?.bc_id]
    

    return <div className='max-h-[500px]  overflow-scroll'>
        <div className='p-4 ' onClick={async() => {
           
            const data = await setRank(card, ct)
            console.log('data2', data)
            }}>
                  

                {card.img && <img className='rounded-xl max-h-[240px] object-cover w-full mx-auto' src={card.img_400 || card.img}/>}
                {!card.img && <div className={`bg-purple-500 ${textSize(card.term)} rounded-xl -mb-4 flex justify-center items-center h-[100px] text-white`}>{capitalizeFirstLetter(card.term)}</div>}

            </div>
            
            <div className='p-4'>
           
                {card.img && <div className={`${textSize(card.term)} text-center text-purple-500 `}>{capitalizeFirstLetter(card.term)}</div>}
                
                {context?.text && <div class="px-3 pl-3 py-[6px] bg-[#e8e0ff] mt-2 rounded-xl border border-[#eddfff]">
                    <div class="flex  items-start gap-2"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-[#7459c3]  mt-1  rounded-full w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"></path></svg>
                        {/* <div class="text-[#7459c3] font-semibold text-[16px] ">{context.text}</div> */}
                        {renderColored(context.text, "text-[#7459c3] font-semibold text-[16px] ", "text-purple-500")}
                        
                    </div>
                    <div class="text-black/40 text-xs italic ml-[24px]">{context.trad}</div>
                </div> }
                <div className='bg-indigo-100 mt-3 flex gap-2 rounded-xl items-center px-3 py-1 mb-2 text-sm'>
                        <img  src={imgContext} className='h-20 bg-cover object-cover w-20 -ml-2 rounded-xl' />
                        {/* <div>path: {path}</div> */}
                        <div>
                            <div className='text-indigo-500 text-sm'>{sentencePrint}</div>
                            {/* <div className='text-black/40 text-xs italic' >{tradPrint}</div> */}
                        </div>
                    </div>

                <DisplayMore more={card.more}/>


                {card.hint && <div className='bg-pink-500 text-white px-2 py-1 rounded-xl text-sm mt-4'>{card.hint}</div>}
               

                {hint && <AddHint setHint={setHint} setUserCards={setUserCards} hint={hint} card={card} setCard={setCard} _userCard={_userCard} />}
                

                {ct.user.admin && <div className='flex'><div className='mt-4 mb-0 px-4 border-2 text-slate-500 text-sm rounded-xl ' onClick={async() => {
                    const hint = await findHint({term: card?.term, lang: ct.workspace.name})
                    console.log('hint', hint)
                    setHint(hint)
                }}>Générer une astuce</div></div>}

                    



                {/* <div className={`inline-flex rounded-xl px-2 text-white mt-4 text-sm`} style={{backgroundColor: category?.color}} >{category?.name_fr}</div> */}

                {/* <div>occ_tt: {card.occ_tt}</div> */}

                
            </div>
            </div>
}

const NoExist = ({word, sentence, lemmaHandle, lemma, sentence_trad, loading}) => {

    

    // ## APPRENDRE LE MOT
    // Ajouter le mot en DB
    // Ajouter trad, contexte, more 
    // Ajouter src="culture G"

    // ## APPRENDRE LA PHRASE
    // Ajouter la phrase en DB avec la traduction
    // Créer exercice de phrase non imagé 

    const goodLemma = lemma && lemma != "..."
    let sentencePrint = sentence
    let tradPrint = sentence_trad
    if (sentence.split(' ')?.length > 20) {
        sentencePrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimmedText}...`
        tradPrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimedTrad}...`
    } 

    
    
    
    return <div className='bg-white/80 bg-blur-[3px] max-h-[500px] rounded-xl'>
   
        <div className='p-4'>
            {goodLemma && <div className='p-2 rounded-xl bg-purple-500/10'>
            {loading &&  <div className='flex justify-center mb-2'>
            <l-helix
                size="45"
                speed="2.5" 
                color="#6360FF" 
                ></l-helix>
                {/* <l-reuleaux
                size="24"
                stroke="5"
                stroke-length="0.15"
                bg-opacity="0.1"
                speed="2.5" 
                color="#6360FF" 
                ></l-reuleaux> */}
                </div>}
                {/* <div className={`${textSize(word)} text-center text-purple-500 `}>{capitalizeFirstLetter(word)}</div> */}
                {goodLemma && loading && <div className='text-center text-sm text-black/30 italic'>Création de la carte en cours </div>}
               
                {goodLemma && <div className='text-indigo-500 text-center'>{lemma}</div>}
            </div>}
            
            <div className='text-slate-500 text-center my-2'>
               
                {lemma == "..." && <div className='text-pink-500 '>Ce terme est un nom propre</div>}
              
            </div>
            <div class="">
                  <div
                        className='text-[#7459c3] font-semibold text-[14px]'
                        dangerouslySetInnerHTML={{__html: addBracket(sentencePrint, word)?.replace(/\[\*(.*?)\*\]/g, `<span class="${lemma == "..." ? "text-pink-500": "text-purple-500" }  px-1 bg-purple-200/40 rounded-md">$1</span>`)}}
                        ></div>
                <div class="text-[#7459c3] font-semibold text-[14px] ">{ }</div>
                <div class="text-black/40 text-xs italic">{tradPrint}</div>
            </div>
                       
          
        </div>

        {/* <div onClick={lemmaHandle}>Get lemma</div> */}
        {/* <div className='absolute fredoka bottom-0 flex flex-wrap justify-around items-center gap-2 h-[100px] p-4 bg-purple-500/10 left-0 right-0'>
            <div className='mx-auto bg-white border-2 border-b-4 rounded-xl px-3  p-2'>Apprendre le mot</div>
            <div className='mx-auto bg-white border-2 border-b-4 rounded-xl px-3 p-2'>Apprendre la phrase</div>
        </div> */}
        </div>

}

function getSurroundingWords(text, targetWord, trad) {

    const wordsText = text?.split(/\s+/);
    const wordsTrad = trad?.split(/\s+/);
    const targetIndexText = wordsText.findIndex(word => word.toLowerCase()?.includes(targetWord?.toLowerCase()));
    
    if (targetIndexText === -1) {
        return "Word not found in the text.";
    }

    const length = 8
    const start = Math.max(0, targetIndexText - length);
    const end = Math.min(wordsText.length, targetIndexText + length+1);

    let trimmedText = ''
    let trimedTrad = ''

    if (targetIndexText < length) {
        trimmedText = wordsText.slice(0, length*2).join(' ');
        trimedTrad = wordsTrad?.slice(0, length*2).join(' ');
    } else if (targetIndexText > wordsText.length -  length+1) {
        trimmedText = wordsText.slice(-length*2).join(' ');
        trimedTrad = wordsTrad?.slice(-length*2).join(' ');
    } else {
        trimmedText = wordsText.slice(start, end).join(' ');
        trimedTrad =  wordsTrad?.slice(start, end).join(' ');
    }

    return {
        trimmedText, trimedTrad
    }
}

const Magnifier = ({context, option, top, center}) => {
    const ct = context
   


    const [card, setCard] = useState(ct.magnifier)
    const {sentence, word, sentence_trad, imgContext, path} = ct.magnifier
    console.log('magnifier', ct.magnifier)

    // console.log('sentence', sentence)
    const [showIndex, setShowIndex] = useState(0)
    const [lemma, setLemma] = useState()
    const [loading, setLoading] = useState()
    const [notWord, setNotWord] = useState(false)
    const [seeCard, setSeeCard] = useState(ct.magnifier?.seeCard || false)
    const [contextUser, setContextUser] = useState()


    useEffect(() => {
        if (!card.id && word) lemmaHandle(word)
        if (!ct.magnifier?.seeCard) {
            ct.setUser(u => ({...u, tuto: {...ct.tuto, news_click: true}}))
            updateDoc(doc(db, "users", ct.user?.id), {tuto: {...ct.user.tuto || {}, news_click: true}})
            setSeeCard(ct.magnifier?.seeCard || false)
        }
      
    }, [])


    let sentencePrint = sentence
    let tradPrint = sentence_trad
    if (sentence?.split(' ')?.length > 16) {
        sentencePrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimmedText}...`
        tradPrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimedTrad}...`
    } 

    // setContextUser({tradPrint: tradPrint, sentencePrint: sentencePrint})

    console.log('sentence', sentence)
    console.log('word', word)
    console.log('sentencePrint', sentencePrint)

    const lemmaHandle = async(word) => {
        setLoading(true)
        console.log('word en trian de chanrgé', word)
       

        const __card = findWord(ct.cards, clean(word))
        console.log('__card', __card)
        const del_words= ct.fqlist?.del || []
        console.log('ct', ct.fqlist)
        console.log('__card', __card)
        console.log('del_words', del_words)

      
        if (__card) {
            
            setCard(__card)
            ct.setCards(cards => cards.map(card => card.id === __card.id ? {...card, inflexions: [...card.inflexions, word]} : card))
            setLoading(false)
            return
        }

        

        if (del_words.includes(clean(word))) {
           
            setLoading(false)
            ct.setMagnifier()
            return
        }



        const _lemma = await getLemma(word, ct.workspace.name, ct)
        setLemma(_lemma)
        const _card = findWord(ct.cards, clean(_lemma))
        console.log('lemma', lemma)
        
        if (_card) {
            setCard(_card)
            ct.setCards(cards => cards.map(card => card.id === _card.id ? {...card, inflexions: [...card.inflexions || [], word]} : card))
            const updated = await updateDoc(doc(db, 'cards', _card.id), {inflexions: [..._card.inflexions || [], word]});
            return
        }
      
        if (_lemma == "...") {
            ct.setfqlist(fq => ({...fq, del: [...fq?.del || [], word]}))
            setLoading(false)
            setNotWord(true)
            ct.setMagnifier()
            const newDels = [...del_words || [] , word]
        
            console.log('update de la liste del', ct.workspace?.name)
            console.log('newDels', newDels)
            const updated = await updateDoc(doc(db, "fq" , ct.workspace?.name), {del: newDels});
            return
        }


           

        if (!_card && _lemma != "..." ){ // si la carte existe en userCards ni dans le dico
                // rechercher la carte en ligne 
            const q = query(collection(db, 'cards'), where('workspace_id', "==", context.workspace?.id), where("term", "==", _lemma))
            const querySnapshot = await getDocs(q);
            const db_card = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))?.[0]
            const contextsText = db_card?.contexts?.[0]?.text && db_card?.contexts?.[0]?.trad

            console.log('contextsText', contextsText)
            if (!contextsText && db_card?.id) {
              
            }
            // on recherche en ligne 
            if (db_card?.id && contextsText) {

                
                
                setCard(db_card)
                 // si on trouve
                console.log('db_card', db_card)
                ct.setCards(cards => cards.map(card => card.id === db_card.id ? {...card, inflexions: [...card.inflexions || [], word]} : card))
                updateDoc(doc(db, 'cards', db_card.id), {inflexions: [...db_card.inflexions || [], word]});
                return 
            }
          
    
           
            const more = await addMore({term: _lemma, lang: ct.workspace.name, ct})
          
            setCard({
                id: "test",
                term: _lemma,
                more: more,
                cm: "p",
                model: "lang-en",
                workspace_id: context.workspace?.id,
                lastUpdate: new Date(),
              })

            const bc_id = await addCat({term: _lemma, context: more?.definitions?.[0]?.ex?.[0].text, lang: ct.workspace.name, ct})
            const contexts = await completeCard(_lemma, ct.workspace.name, ct)
            setLoading(false)
            const inflexions = await addInflexions(_lemma, ct.workspace.name, ct)
            const data = await setRank({...card, term: _lemma, inflexions: [...inflexions, word]}, ct)


            if (db_card?.id && !contextsText) {
                console.log('on modifie la carte existante')
                console.log('carte avant', db_card)
                const updatedCard = {...db_card, 
                    more: more, 
                    inflexions: [...db_card.inflexions || [], word],
                    lastUpdate: new Date(),
                    contexts: [contexts],
                    occ: data?.occ || null,
                    occ_tt: data?.occ_tt || null ,
                    rank_occ: data?.rank_occ || null,
                    bc_id: bc_id,
                    deck_id: db_card.deck_id == "draft" ? null : db_card.deck_deck_id
                }
                console.log('carte après', updatedCard)

                setCard(updatedCard)
                // si on trouve
                ct.setCards(cards => cards.map(card => card.id === db_card.id ? updatedCard : card))
                updateDoc(doc(db, 'cards', db_card.id), {
                    more: more, 
                    inflexions: [...db_card.inflexions || [], word],
                    lastUpdate: new Date(),
                    contexts: [contexts],
                    occ: data?.occ || null,
                    occ_tt: data?.occ_tt || null ,
                    rank_occ: data?.rank_occ || null,
                    bc_id: bc_id});
                return 

            }


            const newCard = {
                term: _lemma,
                more: more, 
                inflexions: [...inflexions, word],
                lastUpdate: new Date(),
                contexts: [contexts],
                cm: "p",
                model: "lang-en",
                workspace_id: context.workspace?.id,
                occ: data?.occ || null,
                occ_tt: data?.occ_tt || null ,
                rank_occ: data?.rank_occ || null,
                bc_id: bc_id
            }
          
            const cardId = await addDoc(collection(db, "cards"), newCard)
            setCard({...newCard, id: cardId.id})
            console.log('{...newCard, id: cardId.id}', {...newCard, id: cardId.id})
            context.setCards(cards => [...cards, {...newCard, id: cardId.id}])

        
          
            
        }
    }
   

    // ## PAS UN MOT
    // si le mots n'existe pas ou est un mot commun ajouter à la redlist 


    // ##AJOUT EN DB
    // CREER une nouvelle carte en db avec: more, inflexion, origin, rank

    // STATE
    // avec le user_card avec "Apprendre", "Reviser"

    const userCard = context.userCards.find(uc => uc.card_id == card.id)



    console.log('seeCard', seeCard)




   
  if (!seeCard) {
    let _top = top+28
    let _left = center - 150
    let switched = false
    const width = window.innerWidth
    const height = window.innerHeight
   
    if (_left < 20) _left = 20
    let _right = _left + 290
    let diff = width - _right
    if (diff < 20) {
        _right = width - 20
        _left = _left + diff - 20
    }
    if (height - _top < 120 ) {
        _top -= 140
        switched = true
    }

   




    

    return (
        <div onClick={() => ct.setMagnifier(false)} className='no-last-time px-4 flex flex-col items-center justify-center' style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 10, 0, 0.1)", zIndex: 999}}>
            {!switched && <div style={{top: _top - 10  , left: center - 20}} className='absolute '>
                
            <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10C0 10 7.935 0 11.5 0C15.065 0 23 10 23 10H0Z" fill="#FFFFFF"/>
                </svg>
            </div>}

            <div style={{top: _top, left: _left, right: _right}} className='w-[290px] text-center absolute bg-white z-[90]  rounded-xl fredoka mx-auto '>
              {/* sentence: {sentence} */}
              <hr/><hr/>
             
              {card.id ? <div>

                <div className='p-2 pr-4 relative'>
               
                    {showIndex == 0 && <div>
                        <div onClick={(e) => {e.stopPropagation(); setShowIndex(1)}} className='absolute right-2 top-2'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>
</div>

                        <div className='text-xl text-semibold'>{card.term}</div>
                        <DisplayMore classLittle={"text-slate-500 text-center border-b-2"} more={card.more} little={true}/>
                    </div>}

                    {showIndex == 1 && <div>
                        <div onClick={(e) => {e.stopPropagation(); setShowIndex(0)}} className='absolute left-2 top-2'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
</svg>
</div>

                     <div className='text-xs text-slate-500 mt-2 ml-4'>{tradPrint}</div>
                     </div>}
                    {/* <div className='text-slate-500 justify-center text-sm flex-wrap flex gap-x-2'>{card?.more?.definitions?.map(e => <div>{e.trad_fr}</div>)}</div> */}
                </div>
                <div className='flex bg-slate-200 w-full rounded-b-xl overflow-hidden'>
                    <div className='bg-[#10cb95]  p-2 px-4 text-white'>
                    Déjà connu
                </div>
                <div onClick={(e) => {e.stopPropagation(); setSeeCard(true)}} className='bg-purple-500 text-white grow p-2'>Apprendre</div>
                </div>
                </div> : <div className='text-slate-500 justify-start px-4 gap-3 py-2 flex justify-center items-center h-[90px]'>
                {loading && <>
                <l-helix
                    size="40"
                    speed="2.5" 
                    color="#6360FF" 
                    ></l-helix>
                 <div>
                    <div className='text-left text-sm text-indigo-600'>chargement ...</div>
                    <div className='text-left text-xs text-slate-500 '>{tradPrint}</div>
                 </div>
                 </> 
                 }
                 {notWord && <div>Ce mot n'est pas dans la liste </div>}</div>}
                             </div>
          
            {switched && <div style={{top: _top + 104  , left: center - 20}} className='absolute z-[0] rotate-[180deg]'>
                
                <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10C0 10 7.935 0 11.5 0C15.065 0 23 10 23 10H0Z" fill="#FFFFFF"/>
                    </svg>
                </div> }
        </div>
          
  )}
  
  
  return (
    <div id="backdrop" onClick={() => ct.setMagnifier(false)} className='no-last-time  flex flex-col items-center justify-center' style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}>
    <div className='max-w-[600px] fredoka mx-auto w-full '>
          
            <div className='fixed top-4 right-4 text-white z-[99]' onClick={() => ct.setMagnifier(false)}>

                         <div class="absolute top-4 right-4 text-white  bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>

            </div>

           
          
            {card.id &&  <div onClick={(e) => e.stopPropagation()} id="flashcard" className={`h-[600px]  relative fredoka w-[400px] border-2 bg-[#FFF7EA] rounded-xl flip flashcard `}>
   
             <FlashcardContentLang _card={card} ct={ct} imgContext={imgContext} path={path} sentencePrint={sentencePrint} tradPrint={tradPrint} _userCard={userCard} setUserCards={ct.setUserCards} />
             {/* <div>lvl: {userCard?.lvl}</div> */}
             {/* card_id: {card?.id} */}
             {userCard && <div onClick={() => console.log('userCard', userCard)} className='absolute bottom-0 flex gap-2  justify-center flex-wrap p-2 left-0 right-0 bg-black/10  rounded-b-2xl text-slate-500'>Tu as déjà appris ce mot</div>}

             {!userCard && card.id && card.id != "test" && <div className='absolute bottom-0 flex gap-2  justify-center flex-wrap p-2 left-0 right-0'>
            
               
                {/* <div onClick={async() => {
                const options = {
                    context: "", 
                    origin: "news", 
                    path: "perf",
                    archived: true
                }


                const newUserCard = await learn(ct, card, options); 
                soundValid.play()

                }} className='p-1 px-3 rounded-xl text-slate-500 bg-white text-cyan-600 border-2'>Je connais déjà</div> */}
                <div onClick={async() => {
                    const options = {
                        context: "", 
                        origin: "news", 
                        path: "perf",
                        stacked: true,
                        sentence: sentence, 
                        sentence_trad: sentence_trad,
                        imgContext: imgContext,
                    }

                 
                    // ajouter une liste de cards à aprendre 
                    // A partir de 4 on peut les ajouter à la lianne. 
                    // -- non on à déjà vu la carte, 
                    // Planter la graine dès la première fois. 
                    const newUserCard = await learn(ct, card, options);
                    console.log('newUserCard', newUserCard) 
                    ct.setMagnifier(false)
                    soundValid.play()
                    ct.setAlerts([{content: `La carte “${card.term}“ a été ajoutée dans ton parcours Vocabulaire`, time: 3000, icon: <img src="/images/UX/i_voc.png" className=" rounded-xl object-cover h-[60px] " />}])


                    }} className='p-1 px-3 rounded-xl border-2 border-b-4 border-purple-900 bg-purple-500 text-white'>Apprendre</div>
             </div>}
 
            </div>}
      
            <div onClick={(e) => e.stopPropagation()} className=''>
                {!card?.id && sentence && <NoExist lemma={lemma} loading={loading} word={word} lemmaHandle={() => lemmaHandle(word)} sentence_trad={sentence_trad} sentence={sentence} />}


            </div>

        
        </div>
    </div>
  );
};

export {Magnifier, DisplayMore, FlashcardContentLang, filterDefinitions};