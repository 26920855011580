import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { soundValid } from '../../../utilities/helper/text';

const IntroTuto = ({showTuto, setShowTuto}) => {
    const tutorials = [{
      name: "Tutoriel",
      screens: [
          {img: "/images/UX/tuto/tuto_0.svg"},
          {img: "/images/UX/tuto/tuto_1.svg"},
          {img: "/images/UX/tuto/tuto_2.svg"},
          {img: "/images/UX/tuto/tuto_3.svg"},
          {img: "/images/UX/tuto/tuto_4.svg"},
          {img: "/images/UX/tuto/tuto_5.svg"},
          {img: "/images/UX/tuto/tuto_6.svg"}
      ]
  }, {
      name: "Dialogue",
      screens: [
          {img: "/images/UX/tuto/tuto_dial_0.svg", c2a: "J'ai compris"}
      ]
  },
  {
      name: "Forest",
      screens: [
          {img: "/images/UX/tuto/tuto_forest.gif", c2a: "J'ai compris", content: {title: "Plantes tes premières graines", txt: "Lorsque tu as finis une leçon tu peux l’ajouter dans ton “jardin de connaissance”. Cela rendra ton apprentissage encore plus efficace"} },
      ]
  
  }, 
  {
      name: "La méthode FTMD",
      // compréhension
      // expression 
      // répétition
      // lien mnémo
  },
  {
      name: "Le contenu",
      // 1200 mots - 8
      // 80% 
      // 
  }
  
  ]
  
  
  
    const [currentTutoScreen, setCurrentTutoScreen] = useState(0)
    const [onLoadImg, setOnloadImg] = useState(false)
  
    const currentTuto = tutorials.find(tuto => tuto.name == showTuto)
    const currentScreen = tutorials.find(tuto => tuto.name == showTuto)?.screens[currentTutoScreen]
    const progressCent = ((currentTutoScreen+1) / currentTuto?.screens.length) * 100
  
  
    return <div id="backdrop" className='flex flex-col p-2  items-between justify-between'  style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}>
    <div className="header-modal text-center flex-wrap flex items-center w-full h-[30px] text-white gap-2 " > 
    { currentTuto?.screens?.length > 1  && <div className='h-[16px] rounded relative w-full bg-black/50 grow' >
    <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded transition-all ' style={{width: `${(progressCent)}%`}}></div>
    <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
    <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
    <div className='text-center text-shadow fredoka font-bold -mb-4 mt-[-20px]'><span className='text-white mr-[1px]'>{currentTutoScreen+1}</span>/{currentTuto.screens.length}</div>
  </div> }
  {currentTuto?.screens?.length > 1 && <div className='text-center fredoka w-full '>{tutorials?.find(tuto => tuto.name == showTuto).name}</div>}
  
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hidden">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
    </svg>
    
    </div>
    <div className='relative  max-w-[400px] mx-auto'>
       
        {tutorials.find(tuto => tuto.name == showTuto)?.screens?.map((s,i) => <img src={s.img} onLoadStart={() => setOnloadImg(false)} onLoad={(e) => {setOnloadImg(true)}} className={`mx-auto ${currentTutoScreen != i ? "h-0 w-0" : "max-h-[450px] object-cover w-full rounded-xl"}`} /> )}
        {currentScreen?.content && <div className='bg-purple-500 text-white text-center rounded-xl -mt-2 p-2 py-4 fredoka mb-2 '><h3 className='text-white'>{currentScreen.content.title}</h3><div>{currentScreen.content.txt}</div></div> }
        <div className={`flex ${ currentTuto?.screens?.length == 1 ? "justify-center" : "justify-between"} -mt-4`}>
        {currentTutoScreen != 0 ? <div className='px-4 py-2 text-2xl fredoka rounded-full bg-white/80 w-auto' onClick={() => {setCurrentTutoScreen(currentTutoScreen-1)}}>← </div> : <div/>}
        <div className={`${!onLoadImg ? "hidden" : "test"} px-4 py-2 novisible show-after-2s fredoka bg-green-400 text-2xl text-white rounded-full  w-auto cursor-pointer`} onClick={() => {soundValid.play() ; currentTutoScreen + 1 == tutorials.find(tuto => tuto.name == showTuto).screens.length ? setShowTuto(false): setCurrentTutoScreen(currentTutoScreen+1)}}>{currentTutoScreen + 1 != tutorials.find(tuto => tuto.name == showTuto).screens.length ? "Suivant →" : currentScreen.c2a || "Terminé"}</div>
        </div>
    </div>
    <div></div>
    </div>
  }

  
  export {IntroTuto}