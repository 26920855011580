import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, writeBatch, doc} from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';

import { db } from "../../../config.js";

import {learn, validCard, findOldestDateIndex} from '../../components/learn/learn.js'
import {replaceBracketsWithUnderLine} from '../../../utilities/helper/text'
import {displayDuration, displayDate} from '../../../utilities/helper/helper'
import { SuperEditor } from '../../../components/editor.js';
import { Dialog } from '../../components/dialog.js';
import { MindMap } from '../../components/mindMap.js';



const versionExt = "?&v=2"



const LearnDialog = (props) => {

    const ct = props.context

    const deck_id = ct.activeDeck 
    const deck = ct.decks?.find(d => d.id == deck_id)
    const [mode, setMode] = useState()
    const [completion, setCompletion] = useState()
    const userDeck = ct.userDecks.find(d => d.deck_id == deck_id)
    const [color, setColor] = useState({bg: "bg-yellow-50" , btn: "bg-yellow-500" })

   


    const modes = [
        {
            name: "first",
            title: "Découvrir", 
            desc: "Appuie sur les mots en jaunes pour les révéler"
        }
    ]

    useEffect(() => {
        ct.setShowBottomBar(false)
        return ct.setShowBottomBar(true)
    }, [])

    const image = deck?.dialog?.img


    const cardsInDecks = ct.cards?.filter(c => c.deck_id == deck?.id)?.map(card => {
        const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
       
        const user_card = {..._user_card, 
            next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
            triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
                {...t, next_date: new Date(t.next_date.seconds * 1000)}
                ) : t)}
        const state = !user_card?.next_date ? "to_learn" : user_card?.archived ? "archived" : user_card?.next_date > new Date() ? "mastered" : "to_review"
        const indexTrigger = user_card.next_date ? findOldestDateIndex(user_card?.triggers) : 42

        return {...card, user_card, state, indexTrigger}
    })

    const cardNotArchived = cardsInDecks.filter(cd => !cd.user_card.archived)

    
    

    const cardsToDiscover = cardNotArchived?.filter(c => !c.user_card || !c.user_card?.next_date)


    const inTwoMinute = new Date()
    const cardToReview = cardNotArchived?.filter(c => c.user_card?.next_date < new Date()).map(card => ({...card, indexTrigger: findOldestDateIndex(card.user_card?.triggers)}))
    const cardCompToDo = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Compréhension ")?.next_date  < inTwoMinute)
    const cardExpToDo = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Expression")?.next_date  < inTwoMinute)
  

    useEffect(() => {
        if (completion == 1) {
            console.log('ct', ct)
            if(!ct.user.tt_dia) {
                ct.setShowTuto('Dialogue')
                ct.fire.updateUser({...ct.user, tt_dia: true}, ct)
            }
        }
    }, [completion])

    const nextMode = () => {
        console.log('cardCompToDo', cardCompToDo.length)
        console.log('cardExpToDo', cardExpToDo.length)
        console.log('cardsToDiscover', cardsToDiscover.length)
        console.log('ct', ct)

      
       

      

        if (cardExpToDo?.length > 0) {
            setMode("exp")
            ct.setTriggerIndex(1)
            setColor({bg: "bg-purple-50", btn: "btn-purple-500"})
        }

        if (cardCompToDo?.length > 0) {
            setMode("comp")
            ct.setTriggerIndex(0)
            setColor({bg: "bg-blue-50", btn: "btn-blue-500"})
        }        

        if (cardsToDiscover.length > 0) {
            setMode("first")
            ct.setTriggerIndex(42)
            setColor({bg: "bg-yellow-50", btn: "btn-yellow-500"})
        }

        if ((cardsToDiscover.length + cardCompToDo?.length + cardExpToDo?.length) == 0) {
             ct.setActiveDeck(); 
             ct.setTriggerIndex();
             ct.setShowCards();
             ct.setStatusCards();
             ct.setShowPop('dailyMission')
        }      
    }

        useEffect(() => {
            if ((cardsToDiscover.length + cardCompToDo?.length + cardExpToDo?.length) == 0) {
                setMode("end")
                ct.setTriggerIndex()
                setColor({bg: "bg-green-50", btn: "btn-green-500"})
            }
        }, [cardsInDecks])

        useEffect(() => {
            //nextMode()
            return () => {
                ct.setTriggerIndex()
            }
        }, [])

        useEffect(() => {
           
            nextMode()
        }, [ct.userCards])


        const showEnd = (
            mode == "first" ? 
             cardsToDiscover.length == 0 ? true : false 
             :
            mode == "comp" ? 
             cardCompToDo.length == 0 ? true : false
             :
            mode == "exp" ?
             cardExpToDo.length == 0 ? true : false
             : true
             
        )
  const progressCent =   (cardsInDecks.length*3 - (cardCompToDo.length + cardExpToDo.length + cardsToDiscover.length*3)) / (cardsInDecks.length*3) * 100
  return (
    <div className={`absolute fredoka z-50 h-screen w-screen bottom-0 overflow-scroll  ${color.bg}`}>
       
        <header className={`fixed flex-wrap right-0 left-0 text-white z-10 ${color.bg} flex items-center gap-4 p-4 mb-2 overflow-hidden `}>
            <div className='text-slate-500' onClick={() => {ct.setShowCards(); ct.setActiveDeck(); ct.setTriggerIndex()}} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                </svg>
            </div>
            {(completion == 1 || mode != "first") ?  <div className='h-[16px] bg-black/10 grow relative rounded overflow-hidden'>
            <div className='h-[16px] rounded relative w-full bg-black/50 grow' >
                <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded transition-all ' style={{width: `${(progressCent)}%`}}></div>
                <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
                <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
                <div className='text-center text-shadow fredoka font-bold -mb-4 mt-[-20px]'><span className='text-amber-200 mr-[1px]'>{(cardsInDecks.length*3 - (cardCompToDo.length + cardExpToDo.length + cardsToDiscover.length*3))}</span>/{(cardsInDecks.length*3)}</div>
            </div> 
                <div className={`bg-amber-500  h-full absolute rounded-xl`} style={{width: Math.round(progressCent) + "%" }}></div>

                {/* <div className={`bg-purple-500  h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length + cardToReview?.length)/ cardsInDecks?.length * 100) + "%" }}></div> */}
                {/* <div className={`bg-green-500 h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length)/ cardsInDecks?.length * 100) + "%" }}></div> */}
            </div> : 
                <div className='grow'>...</div>
          }
            <div className='text-slate-500'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6  h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
            </div>

            <h1 className='text-[16px] w-full font-[500] text-center text-slate-500 mb-2 '>{deck?.name}</h1>

           
        </header>

     
       




        <div className='px-8 pt-[120px]'>
       

        {/* {cardsToDiscover.length} à Découvrir {cardCompToDo.length + cardExpToDo.length} à travailler
        tt {cardsToDiscover.length + cardCompToDo.length + cardExpToDo.length}
        done {cardCompDone.length + cardExpDone.length + cardsDiscovered.length } */}
        {image && <img src={image} alt="" className='-z-10 sm:h-12 w-full lg:h-[200px]  rounded-xl bg-purple-500 object-cover max-w-[200px] mx-auto' />}
        </div>
        
        <main className='mb-[100px] '>

    
        <div className='p-4 max-w-[700px] mx-auto '>
       
             <div className=' '></div>
            <Dialog mode={mode} setCompletion={setCompletion} deck={deck} cards={cardsInDecks} context={ct} lang={"en"} />
        

         
           
            <div>
          
            </div>
        
 
        </div>

        {/* <div className='flex flex-col gap-2 items-center justify-center'>
            <div onClick={() => addAlert({text: "hey"})} className='b text-blue-500 text-center px-4 py-1 border-b-4 border-2 border-blue-500 rounded-xl inline-block mx-auto'>Apprendre</div>
            <div onClick={() => deletedUserCards({text: "hey"})} className='b text-pink-500 text-center px-4 py-1 border-b-4 border-2 border-pink-500 rounded-xl inline-block mx-auto'>Recommencer</div>

        </div> */}
         
        </main>

        {showEnd && <div className={`fixed z-10 bottom-[0px] p-4 pb-8 right-0 left-0 bg-white left-0 right-0 `}>
        <div className='flex justify-end'><div onClick={() => nextMode()} className='b px-4 py-1 fredoka inline-block rounded-xl bg-green-500 text-white border-green-700 border border-b-4'>Suivant → </div></div>
        </div>}
        <div className={`fixed z-10 bottom-[0px]  left-0 right-0 `}>
          
        {mode == "first" && completion == 1 &&
        <div className={`text-center p-2 py-4 pb-safe ${color.bg} border-t novisible bg-[#fdf6cc] show-after-2s text-amber-600 fredoka`}>
        
                {/* <div className='flex justify-center mb-2'>
                    <div className='px-2 flex gap-2 items-center rounded-full bg-yellow-200'>Nouveau mots
                    <iconify-icon icon="bi:stars"></iconify-icon>

</div>
                    <div>{cardsInDecks.length - cardsToDiscover.length} / {cardsInDecks.length}</div>
                </div> */}
                <div>Appuie sur les mots surlignés</div>
         
            
        </div>
        }
        {mode == "comp" &&  <div className='bg-blue-50 text-center p-2 py-4 pb-safe  border-t text-blue-600 fredoka'>
        
        {/* <div className='flex justify-center mb-2'>
                <div className='px-2  flex gap-2 items-center rounded-full bg-blue-200/70 inline-block'>Trouve le sens
                <iconify-icon icon="bi:stars"></iconify-icon>
                </div>
                </div> */}
                   
        
                <div>Appuie sur les mots surlignés</div>
            
            
        </div>}

        {mode == "exp" &&  <div className='bg-purple-100 text-center p-2 py-4 pb-safe  border-t text-purple-600 fredoka'>
        {/* <div className='flex justify-center mb-2'>
                <div className='px-2  flex gap-2 items-center rounded-full bg-purple-200/70 inline-block'>Expression
                <iconify-icon icon="bi:stars"></iconify-icon>
                </div>
                </div>
                    */}
        
                <div>Complète les mots manquants.</div>
            
            
        </div>}
        {mode == "end" &&  <div className='bg-green-100 text-center  p-4 mb-8 text-green-600 fredoka'>
        
         <div className='text-md'>Félicitations, tu maîtrises tous les mots à apprendre du dialogue.</div>
        
           
        </div>}
         {completion == 1 || mode != "first" && <hr className='border-black/10   w-1/2 mx-auto'/>}
     
        </div>
    </div>
  );
};

export {LearnDialog};    
