import React, { useEffect, useState } from "react"
import { shuffleArray } from "../../helperFunctions";
import { mp3_bad, mp3_valid } from "../../helperData";
import { useStateContext } from "../../StateProvider";



   
    

// fait une fonction qui divise la phrase mot en partie
// si il y a plus de 4 mots il faut grouper les mots ensemble pour avoir maximum 6 parties
// le mot en paramètre doit être isolé
// il faut que les partie soit un peu pret de la même taille



const splitSentence = (sentence, word) => {

    const max = 6
    // Divise la phrase en mots
    let words = sentence.split(' ');
    
    // Si moins de 5 mots, retourne directement le tableau
    if (words.length <= max) {
        return words;
    }
    
    // Trouve l'index du mot spécial
    const wordIndex = words.indexOf(word);
    
    // Calcule combien de groupes sont nécessaires (en gardant le mot spécial isolé)
    const remainingWords = words.length - 1; // -1 pour le mot spécial
    const numberOfGroups = max - 1 ; // On veut 4 groupes + le mot spécial = 5 éléments
    const wordsPerGroup = Math.ceil(remainingWords / numberOfGroups);
    
    let result = [];
    let currentGroup = [];
    let groupCount = 0;
    
    // Parcourt tous les mots
    for (let i = 0; i < words.length; i++) {
        // Si c'est le mot spécial, l'ajoute isolément
        if (i === wordIndex) {
            if (currentGroup.length > 0) {
                result.push(currentGroup.join(' '));
                currentGroup = [];
                groupCount++;
            }
            result.push(word);
            continue;
        }
        
        currentGroup.push(words[i]);
        
        // Si le groupe actuel a atteint la taille cible
        if (currentGroup.length >= wordsPerGroup && groupCount < numberOfGroups - 1) {
            result.push(currentGroup.join(' '));
            currentGroup = [];
            groupCount++;
        }
    }
    
    // Ajoute le dernier groupe s'il reste des mots
    if (currentGroup.length > 0) {
        result.push(currentGroup.join(' '));
    }
    
    return result;
}


const SentenceBuilder =  React.memo(({sentence, word, validCard}) => {

   
    const [selectedWords, setSelectedWords] = useState([])
    const [selectableWords, setSelectableWords] = useState([])
    const [randomParts, setRandomParts] = useState([])
    const {workspace} = useStateContext()

    const correctParts = splitSentence(sentence, word)

    useEffect(() => {
        const _partsOfSentence = splitSentence(sentence, word)
        const _randomParts = shuffleArray(_partsOfSentence).map((e,i) => ({part: e, index: i}))
        setRandomParts(_randomParts)
        setSelectableWords(_randomParts)
    }, [sentence])

    useEffect(() => {
        if (selectedWords.length != correctParts.length) return
        const userSentence = selectedWords.map(e => e.part).join(' ')
        const goodSentence = correctParts.join(' ')
        if (userSentence == goodSentence) {
            validCard()
            mp3_valid.play()
        } else {
            mp3_bad.play()
        }
        console.log('userSentence', userSentence)
        console.log('goodSentence', goodSentence)

    }, [selectedWords])


    const undoPart = (part) => {
        setSelectedWords(prev => prev.filter(e => e.index !== part.index));
        setSelectableWords(prev => [...prev, part])
    }

    const undo = () => {
        // setSelectedWords([])
        // setSelectableWords(randomParts)

        setSelectedWords(prev => prev.slice(0, -1));
        setSelectableWords(prev => [...prev, selectedWords[selectedWords?.length-1]]) 
    }

    const handleClick = (e, i) => {
        setSelectedWords(prev => [...prev, {part: e.part, index: e.index, randomIndex: i}])
        setSelectableWords(prev => prev.filter(word => word != e))
    }


    return <div className="flex h-full flex-col">
            <div className={` ${workspace.lang == "ar-XA" ? "flex-row-reverse" : ""} mx-4  grow items-end  border-slate-300 pb-2 flex flex-wrap gap-1  pr-[28px] mb-8 border-slate-100 relative`}>
                {selectedWords?.map((e,i) => <span onClick={() => {undoPart(e)}} className={`${correctParts[i] == e.part ? "text-amber-900" : "text-red-500"} border-2 bg-amber-500/10 border-amber-500/50 border-b-2 px-2 rounded-xl`}> {e.part} </span>)}
     
                {selectedWords.length > 0 && <svg onClick={() => {undo()}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 text-slate-500 absolute right-0 bottom-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
                </svg>} 
                {selectedWords.length == 0 && correctParts.map((e,i) => <div onClick={() => handleClick(e, i)} key={i} className={`border-2 bg-white  border-amber-500/50 px-1 rounded-xl border-dashed text-transparent`}>{e}</div>)}

      
            </div>
           
            <div className="pattern-triangle mt-4 p-4 bg-[#ffeac6] grow h-full">
            <div className={`${workspace.lang == "ar-XA" ? "justify-end" : ""} flex gap-1 text  0 rounded-xl flex-wrap`}>
                {selectableWords.map((e,i) => <div onClick={() => handleClick(e, i)} key={i} className={`border-2 bg-white py-1 border-b-4 border-b-2 border-amber-500/50 px-2 rounded-xl`}>{e.part}</div>)}
            </div>
            </div>
        </div>
} , (prevProps, nextProps) => {
    return prevProps.sentence === nextProps.sentence;
  });

export {SentenceBuilder}