import { useEffect, useState } from "react"
import { brackSentence, displayDuration, evolveProfileCard, getAverageLvlTrigger, getNextTrigger, getTraduction, hasWordInBrackets, speak, transformDates, transformDatesToJS } from "../../helperFunctions"
import { QCM } from "../triggers/QCM"
import { DiscoverCard } from "./discoverCard"
import { FillSentence } from "../triggers/FillSentence"
import { boosters } from "../../helperData"
import { HighlightText } from "../triggers/HighlightText"
import { AudioBtn } from "../triggers/AudioBtn"




const WordDefinition = ({definitions}) => {

    const [state, setState] = useState(0)

    
    return <div className="text-left text-sm">{JSON.parse(definitions)?.definitions?.map(e => <div>
        <div className="mt-4 mb-2">{e.trad_fr} - <span className="italic text-xs italic opacity-[0.8]">{e.categorie}</span></div>
        {state == 1 && <div className="mb-2 text-indigo-700">{e.def}</div>}
        {state == 1 && e.ex.map(ex => <div className="pl-4">
        <div className="text-purple-500">{ex.text}</div>
        <div className="text-xs italic opacity-[0.8]">{ex.trad_fr}</div>
        </div>)}
        {state == 0 && <div className="pl-4">
        <div className="text-indigo-500">{e.ex[0].text}</div>
        <div className="text-xs italic opacity-[0.8]">{e.ex[0].trad_fr}</div>
        </div>}
        <div className="pl-4 mt-2 flex gap-2 text-xs">{e.syn.map(syn => <div className="bg-indigo-400/10 rounded-xl text-indigo-400 px-1">{syn}</div>)}</div>
        </div>)}    
    </div>
}



const ContextLang = ({className, text, trad, lang}) => {

    const [audioPlaying, setAudioPlaying] = useState(false)
    const [showTrad, setShowTrad] = useState(false)

    const handlePlay = async() => {
        setAudioPlaying(true)
        await speak({text: text, lang: lang})
        setAudioPlaying(false)
    }

    return <div className={className}>
                

    <div className="text-[#7459c3] font-semibold text-[16px]"><span className="font-[200] tracking-wider font-mono">❝</span>{text}<span className="font-[200] tracking-wider font-mono">❞</span></div>
    {showTrad && <div className="text-xs text-purple-900/50 mb-2">{trad}</div>}
    <div className="text-xs text-purple-900/50 flex gap-2 items-center">
    {!audioPlaying ? <svg onClick={handlePlay} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
    </svg> : <l-waveform
            
            size="16"
            speed="1.2" 
            color="#a17fc3" 
          ></l-waveform>}
    <div onClick={() => {setShowTrad(prev => !prev)}}>{showTrad ? "Cacher":'Voir'} la traduction</div>
    
        
    </div>


</div>
}

const BackCard = ({card, old_profile_card, profile_card, validCard, workspace}) => {

    console.log('profile_card', profile_card)
    const triggers = profile_card && profile_card?.triggers?.map(e => ({...e, displayDuration: displayDuration(new Date, e.next_date)}))

    return <div  className="bg-[#fff7ea] rounded-2xl relative h-full  ">
                <div className="overflow-auto h-full rounded-2xl  pb-[100px]">
                <img onClick={() => {console.log('card', card)}} src={card.img} className="max-h-[52%] mx-auto w-full object-cover  "/>

                <div className="p-4 text-amber-900/60">
                

                <ContextLang lang={workspace.lang} trad={card.context_fr} text={card.context} className={`px-3 ${workspace.lang == "ar-XA" ? "text-right" : ""} pl-3 py-[6px] text-left bg-[#e8e0ff]  -mt-8 relative  rounded-xl border border-[#eddfff]`}/>
                <div onClick={() => speak({text: card.term, lang: workspace.lang})} className="text-[#7459c3] mt-4 flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                        <path d="M7.557 2.066A.75.75 0 0 1 8 2.75v10.5a.75.75 0 0 1-1.248.56L3.59 11H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.59l3.162-2.81a.75.75 0 0 1 .805-.124ZM12.95 3.05a.75.75 0 1 0-1.06 1.06 5.5 5.5 0 0 1 0 7.78.75.75 0 1 0 1.06 1.06 7 7 0 0 0 0-9.9Z" />
                        <path d="M10.828 5.172a.75.75 0 1 0-1.06 1.06 2.5 2.5 0 0 1 0 3.536.75.75 0 1 0 1.06 1.06 4 4 0 0 0 0-5.656Z" />
                    </svg>
                    {card.term}
                </div>

                <WordDefinition definitions={card.definitions} />
                

                </div>
                </div>
                {profile_card && <div onClick={() => {
                    console.log('profile_card', profile_card)
                    console.log('old', old_profile_card)
                    console.log('triggers', triggers)
                    }} className="absolute overflow-visible pb-8 pattern-wiggle bg-[#fdf0d9] rounded-2xl text-amber-800/90 bottom-0 left-0  right-0 p-4">
                    
                    <div className="flex gap-2 grow">
                        {/* <div className="h-[40px] rounded-full min-w-[40px] border-2"></div> */}
                    
                        <div className="grow">
                        <div className="flex gap-2 items-center text-xs mb-2 ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 -mr-1">
                            <path d="M7.25 3.688a8.035 8.035 0 0 0-4.872-.523A.48.48 0 0 0 2 3.64v7.994c0 .345.342.588.679.512a6.02 6.02 0 0 1 4.571.81V3.688ZM8.75 12.956a6.02 6.02 0 0 1 4.571-.81c.337.075.679-.167.679-.512V3.64a.48.48 0 0 0-.378-.475 8.034 8.034 0 0 0-4.872.523v9.268Z" />
                        </svg>
                        <div className="w-[80px] text-left mr-2">
                            Compréhension
                        </div>
                        <div className="flex gap-1 grow">
                            {[...Array(triggers?.[0]?.lvl > 5 ? triggers?.[0]?.lvl : 5)]?.map((e,i) => <div key={i} className={` h-[5px] ${ triggers?.[0]?.lvl > i ? "bg-cyan-400": "bg-cyan-900/20"} rounded-full w-full grow max-w-[18px]`}></div>)}
                           
                        </div>
                        {triggers[0]?.displayDuration != "maintenant" && <div className="flex gap-1 items-center bg-amber-500/10 rounded-xl px-1 border border-black/10"    ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3">
                            <path fillRule="evenodd" d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z" clipRule="evenodd" />
                            </svg>
                        {triggers[0]?.displayDuration}</div>}

                        </div>

                        <div className="flex gap-2 items-center text-xs mb-2 ">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 -mr-1">
                                <path fillRule="evenodd" d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z" clipRule="evenodd" />
                            </svg>

                            <div className="w-[80px] text-left mr-2">
                                Expression
                            </div>
                            <div className="flex gap-1 grow">
                                {[...Array(triggers?.[1].lvl > 5 ? triggers?.[1].lvl : 5)]?.map((e,i) => <div key={i} className={`h-[5px] ${ triggers?.[1].lvl > i ? "bg-indigo-400": "bg-indigo-900/20"}  rounded-full w-full grow max-w-[18px]`}></div>)}
                            
                            </div>
                            
                            {triggers[1]?.displayDuration != "maintenant" && <div className="flex gap-1 items-center bg-amber-500/10 rounded-xl px-1 border border-black/10"    ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-3">
                            <path fillRule="evenodd" d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z" clipRule="evenodd" />
                            </svg>
                        {triggers[1]?.displayDuration}</div>}

                        </div>
                        </div>
                    </div>
                    
                </div>}

                <div className="flex justify-end absolute -bottom-4 left-0 right-4 ">
                    {<div className="bg-purple-500 py-2 px-10 text-white rounded-full border-2 border-b-4 border-black/60 game-btn" onClick={() => {validCard({valid: true})}}>Suivant <span className="ml-2"> →</span></div>}
                    </div>

                
              
                {/* <div className="flex gap-2 w-full mt-4 justify-between">
                    <div onClick={() => validCard({valid: false, bonus: 0})}>Faux</div>
                    <div onClick={() => validCard({valid: true, bonus: 0})}>Juste</div>
                </div> */}
    </div>
}



const WaiterOpen = ({setDifficulty, setState}) => {
    return  <div className="flex gap-2 w-full mt-4 justify-between">
    <div onClick={() => {setDifficulty(0); setState(2)} } className='button bg-[#1ba480] font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow'>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[25px] opacity-50 mx-auto ">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <div className='text-shadow-50 -mt-1  font-[400] text-[15px] mb-[6px]'>Je sais pas</div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>à revoir</div>
        </div>
        <div onClick={() => {setDifficulty(1); setState(2)} } className='button bg-[#1dbb88] font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow'>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[30px] opacity-50  mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round"d="M4.5 12.75l6 6 9-13.5" />
          </svg>
            <div className='text-shadow-50 -mt-1  font-[400]'>Je sais </div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>revoir dans 4j</div>
        </div>
        <div onClick={() => {setDifficulty(2); setState(2)} } className='button bg-[#18bdab] font-[400] px-1 pb-1 game-btn w-[100px] text-white  border-black/30 border-2 border-b-[4px] rounded-2xl grow'>
    
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[30px] opacity-50   mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
    </svg>
            <div className='text-shadow-50 -mt-1  font-[400]'>Trop facile</div>
            <div className='text-[11px] px-1 bg-black/10 rounded-full py-[-1px] font-sans text-shadow-50  -mb-[2px]  text-white/80'>revoir dans 6j</div>
        </div>
        {/* <div onClick={() => validCard({valid: false, bonus: 0})}>Faux</div>
        <div onClick={() => validCard({valid: true, bonus: 0})}>Juste</div> */}
    </div>
}








const TriggerExpression = ({card, profile_card, randomCards,  validCard, workspace, updateProfileCard}) => {

    const [state, setState] = useState(0)
    const [old_profile_card, setOldProfileCard] = useState(profile_card)
    const [newProfileCard, setNewProfileCard] = useState()
    const [isValid, setIsValid] = useState(false)


    useEffect(() => {
        setState(0)
        setOldProfileCard(profile_card)
        setNewProfileCard(profile_card)
        setIsValid(false)
    }, [card.id])

    const validCardBack = () => {
        // on update les profiles cards dans l'app
        updateProfileCard(newProfileCard.id, newProfileCard)
        // on passe la carte dans todoCards
        validCard({valid: isValid})
    }

    const validTrigger = (valid) => {
        // on met à jour le nouveau profil_card pour le back
        console.log('debug validTrigger ')

        const _newProfileCard = evolveProfileCard({profile_card, trigger: 1, valid: valid})
        // console.log("debug _newProfileCard", _newProfileCard)
        setNewProfileCard(_newProfileCard)
        setIsValid(valid > 0) 

        // si bonus on met à jour le profile card et on passe
        if (valid == 2) {
            updateProfileCard(newProfileCard.id, _newProfileCard)
            validCard({valid: true, bonus: 1})
        }
       

    }

    const brackedSentence = hasWordInBrackets(card.context) ? card.context : brackSentence({sentence: card.context, word: card.term})

    
    if (state == 1) return <BackCard workspace={workspace} randomCards={randomCards} card={card} old_profile_card={old_profile_card} profile_card={newProfileCard} validCard={validCardBack}/>
    return <div style={{backgroundColor: "rgb(235, 216, 255)"}} className="p-4 text-purple-600 rounded-2xl overflow-auto relative h-full flex flex-col justify-between pb-[200px]">

            <div onClick={() => {
                 updateProfileCard(profile_card.id, evolveProfileCard({profile_card, trigger: 1, valid: 1}))
                 validCard({valid: true})
               
            }} className="flex justify-center ">
                <div className="flex gap-2 bg-purple-500/10 px-2 text-sm rounded-xl items-center mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"></path></svg>
                    <div>Expression niv {old_profile_card?.triggers?.[1]?.lvl} - {newProfileCard?.triggers?.[1]?.lvl}</div>
                </div>
            </div>
            
            <div className="flex flex-col justify-center grow ">
                <div className="">
                    <div className="text-sm">Trouve le terme en {workspace.name}</div>
                    <div className="h-[180px] w-[220px] mx-auto my-4 bg-center bg-cover text-slate-500 rounded-xl relative" style={{backgroundImage: `url('${card.img}')`}}>
                        <div className="bg-gradient-to-b from-[#4a0382e6]/20 rounded-xl via-[#4a0382e6]/40 to-[#4a0382e6] p-2 h-full flex flex-col justify-end absolute bottom-0 left-0 right-0 rounded-b-xl">
                            <div className="text-white text-sm">{getTraduction(card)}</div>
                            <div className="text-white/90 text-[12px] italic">“{card.context_fr}”</div>
                        </div>   
                    </div>                           
                    <FillSentence speak={() => speak({text: card.term + "; . " + card.context, lang: workspace.lang})} inputClassName={"!bg-purple-300/50"} brackedSentence={brackedSentence} setState={setState} validCard={validTrigger} />
                </div>
            </div>
           

           

    </div>
}

const TriggerComprehension = ({card, profile_card, randomCards,  validCard, workspace, updateProfileCard }) => {

    const [state, setState] = useState(1)
    const [old_profile_card, setOldProfileCard] = useState(profile_card)
    const brackedSentence = hasWordInBrackets(card.context) ? card.context : brackSentence({sentence: card.context, word: card.term})
    const [difficulty, setDifficulty] = useState(1)
    const [audioMode, setAudioMode] = useState(false)
    const [newProfileCard, setNewProfileCard] = useState()
    const [isValid, setIsValid] = useState(false)



    useEffect(() => {
        if (!profile_card) return
        const triggers = transformDates(profile_card?.triggers)?.map(e => ({...e, displayDuration: displayDuration(new Date, e.next_date)}))

        if (triggers[0].lvl%2==0) {
            setAudioMode(true)
            
        } else {
            setAudioMode(true)
        }
    }, [profile_card])

    

    useEffect(() => {
        setState(1)
        setOldProfileCard(profile_card)
        setNewProfileCard(profile_card)
        setIsValid(false)
        console.log('changement de profil card', profile_card)
    }, [card.id])

   

    let nbRandom = 3
    if (difficulty == 0) nbRandom = 1
    if (difficulty == 2) nbRandom = 8

    const handleSpeak = () => {
        !audioMode && speak({text: card.term + ";. " + card.context, lang: workspace.lang})
    }

    const validCardBack = () => {
        // on update les profiles cards dans l'app
        updateProfileCard(newProfileCard?.id, newProfileCard)
        // on passe la carte dans todoCards
        validCard({valid: isValid})
    }

    const validTrigger = (valid) => {
         // on met à jour le nouveau profil_card pour le back
        const _newProfileCard = evolveProfileCard({profile_card, trigger: 0, valid: valid})
        console.log('_newProfileCard', _newProfileCard)
        setNewProfileCard(_newProfileCard)
        setIsValid(valid > 0)

        // si bonus on met à jour le profile card et on passe
        if (valid == 2) {
            updateProfileCard(_newProfileCard?.id, _newProfileCard)
            validCard({valid: true, bonus: 1})
        }
       

    }
    
    if (state == 3) return <BackCard workspace={workspace} randomCards={randomCards} card={card} old_profile_card={old_profile_card} profile_card={newProfileCard} validCard={validCardBack}/>

    return <div style={{backgroundColor: "rgb(214, 255, 238)"}} className="text-green-600 rounded-2xl overflow-auto gap-0 relative h-full flex flex-col  justify-start gap-0">
            
            <div className="pt-4">
                <div className="flex justify-center">
                <div onClick={() => {
                     updateProfileCard(profile_card.id, evolveProfileCard({profile_card, trigger: 0, valid: 1}))
                     validCard({valid: true})
                }} className="flex px-2 rounded-xl bg-green-500/20 gap-2 text-sm rounded-xl items-center mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 text-green-500/70">
                        <path d="M7.25 3.688a8.035 8.035 0 0 0-4.872-.523A.48.48 0 0 0 2 3.64v7.994c0 .345.342.588.679.512a6.02 6.02 0 0 1 4.571.81V3.688ZM8.75 12.956a6.02 6.02 0 0 1 4.571-.81c.337.075.679-.167.679-.512V3.64a.48.48 0 0 0-.378-.475 8.034 8.034 0 0 0-4.872.523v9.268Z" />
                    </svg>
                    <div>Compréhension</div>
                </div>
                </div>
                <div className="mt-4">Que veut dire ce mot ?</div>
            </div>
            {audioMode ? <div className="flex px-4 flex-col justify-center grow"><AudioBtn autoSpeak={true} speakText={card.term + "; . "+ card.context} className={"!border-emerald-900 h-[120px] w-[120px]"} color={"!from-emerald-500 !to-[#0c887a]"} card={card} lang={workspace.lang} /></div> : <div className="flex px-4 flex-col justify-center grow ">
                <div className="">
                    
                    <div className="mb-4 bg-green-300 h-[80px] rounded-xl mx-auto w-[140px] text-center text-green-800 text-[50px]">
                       ?

                     
                    </div>
                    <HighlightText brackedSentence={brackedSentence} className={"text-lg"} classNameText={"underline underline-offset-4 font-[500]  decoration-dashed text-[#119f87]"} />
                </div>
            </div>}
            <div className={" bg-input2 bottom-0 bg-[#84e8c6] transition-all border-t bg-input2 transition-all  p-3 px-2 rounded-b-xl left-0 right-0  "}>
                {state == 1 && <WaiterOpen setState={setState} setDifficulty={setDifficulty}/>}
                {state == 2 && <QCM handleSpeak={handleSpeak} setState={setState} elements={randomCards.slice(0, nbRandom)} field={"term"} goodElement={card} validCard={validTrigger} mode={"lang-comp"}/>}
            </div>

           

    </div>
}



const Flashcard = ({card, profile_card, setDiscoverPoints, validCard, mode, handleArchived, randomCards, workspace, updateProfileCard}) => {

    console.log('random cards', randomCards)
   

    return <div  className="mx-2 flashcard text-center rounded-2xl  bg-white text-slate-800 max-h-[500px] w-[300px] min-w-[300px] max-w-[500px]">
                {mode == "learn"  && profile_card?.next_trigger == 0 && <TriggerComprehension updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "learn"  && profile_card?.next_trigger == 1 && <TriggerExpression updateProfileCard={updateProfileCard} workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "back" && <BackCard workspace={workspace} randomCards={randomCards} card={card} profile_card={profile_card} validCard={validCard}/>}
                {mode == "discover" && <DiscoverCard setDiscoverPoints={setDiscoverPoints} workspace={workspace} randomCards={randomCards} handleArchived={handleArchived} card={card} profile_card={profile_card} validCard={validCard} />}
        </div>
}

export {Flashcard, BackCard}