import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, speak } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { quantum, waveform } from 'ldrs'
import { AudioReader } from '../components/quizzReader.js';
import {Howl, Howler} from 'howler';  
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
const key_1 = "3fb405dd620d40eea3c0604929d8089f"
quantum.register()



let currentAudio = null;

const pauseAudio = () => {
  if (currentAudio) {
    currentAudio.pause();
  }
};


const translate = async(text, lang_name) => {
  const prompt = `
  Donne juste la réponse. Traduit en ${lang_name} ce texte de manière naturelle comme si tu étais un ${lang_name}. (laisse les "||" est les "[1]" pour les phrases)
  Voici le texte à traduire : 
  ${text}
  `
  
  console.log('prompt', prompt);
  const request = [{ "role": "user", "content": prompt }];
  const requestData = await gpt(request, true, null); // Make sure gpt and ct are defined
  const data_content = requestData.choices[0]?.message?.content;
  console.log('data_content', data_content);

  return data_content

}



function groupWordsByTime(words) {
  let groupedWords = [];
  let currentGroup = [];
  let currentTime = 0;
  let coloredTime = 0;
  let sep = 850
  let coloredSep = 1000

  words?.forEach(wordObj => {
      if (wordObj.offset / sep >= currentTime + 1) {
          if (currentGroup.length > 0) {
              groupedWords.push(currentGroup);
              currentGroup = [];
          }
          currentTime = Math.floor(wordObj.offset / sep);
      }
      if (wordObj.offset / coloredSep >= coloredTime + 1) {
        coloredTime = Math.floor(wordObj.offset / coloredSep);
        wordObj.current = true;
    }
      currentGroup.push(wordObj.word);
  });

  if (currentGroup.length > 0) {
      groupedWords.push(currentGroup);
  }

  return groupedWords;
}

function findGroupByWordIndex(groups, wordIndex) {
  let currentIndex = 0;

  for (let i = 0; i < groups.length; i++) {
      let group = groups[i];
      
      if (wordIndex < currentIndex + group.length) {
          return group.map((word, idx) => ({
              word: word,
              // current: (currentIndex + idx == wordIndex)
          }));
      }

     
      
      currentIndex += group.length;
  }
  
  return null; // Si l'index est en dehors des limites
}

function getVoiceByIndex(voices, index) {
  return voices[index % voices.length];
}


let synthesizer = null


const synthesizeSpeech = async ({ text, voice = "fr-FR-VivienneMultilingualNeural", setWordIndex, setTsw, setPlaying, fact, lang,setProgressAudio, storage}) => {
 
  
  if (fact[`wt_${lang}`] && fact[`audio_${lang}`]) {
    const audioUrl = fact[`audio_${lang}`];
    const wordList = fact[`wt_${lang}`];

    // Play the audio and start word index updates
    setPlaying(true);
    setTsw(wordList);

    const audio = new Audio(audioUrl);
    currentAudio = audio; // Store the current audio object

    audio.addEventListener('play', () => {
      let startTime = Date.now();
    });

    audio.addEventListener('timeupdate', () => {
      let currentTime = audio.currentTime * 1000; // currentTime is in seconds, convert to milliseconds
      let duration = audio.duration * 1000; // duration is in seconds, convert to milliseconds

      // Calculate and set the progress percentage
      let progress = (currentTime / duration) * 100;
      setProgressAudio(progress);
  

      for (let i = 0; i < wordList.length; i++) {
        if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
          setWordIndex(i);
          break;
        }
      }

      if (currentTime >= wordList[wordList.length - 1].offset + wordList[wordList.length - 1].duration) {
        setPlaying(false); // Set playing to false when the audio finishes
      }
    });

    audio.addEventListener('ended', () => {
      setPlaying(false); // Set playing to false when the audio finishes
    });

    audio.play();
    return;


  }
  console.log('synthesizeSpeech', text)
  const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, "francecentral");
  const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();

  speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3;
  speechConfig.requestWordLevelTimestamps();

  

  let wordList = [];
  let startTime = null;
  const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

  synthesizer.wordBoundary = (s, e) => {
      wordList.push({
          word: e.text,
          offset: e.audioOffset / 10000, // Convert to milliseconds
          duration: e.duration / 10000 // Convert to milliseconds
      });
  };

  setWordIndex(0);
  const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="fr-FR">
      <voice name="${voice}">
          <prosody rate="0%" pitch="0%">
              <mstts:express-as style="cheerful">
                  ${text}
              </mstts:express-as>
          </prosody>
      </voice>
  </speak>`;

  console.log('synthesizer', synthesizer)

  synthesizer.speakSsmlAsync(
      ssml,
     async (result) => {
          if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {

              console.log('Synthesis finished.', result);
            
              setProgressAudio(0);
              const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
               //const audioUrl = URL.createObjectURL(audioBlob);
              const storageRef = ref(storage, `audio_facts/${lang}/${fact.id}.mp3`);
              await uploadBytes(storageRef, audioBlob);
              const audioUrl = await getDownloadURL(storageRef);
              
              
              
              if (wordList.length > 0) {
                
                  console.log("wordList", wordList);
                  console.log('fact UPDATED ! 🟢🟢🟢🟢', fact)
                  updateDoc(doc(db, "facts", fact.id), {
                    [`wt_${lang}`]: wordList ,
                    [`audio_${lang}`]: audioUrl
                  })
                  setPlaying(true);
                  setTsw(wordList);
                  
                  startTime = Date.now(); // Initialize start time when synthesis is completed

                  const intervalId = setInterval(() => {
                      let elapsedTime = Date.now() - startTime;
                      let currentTime = elapsedTime;

                      for (let i = 0; i < wordList.length; i++) {
                          if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
                              setWordIndex(i);
                              break;
                          }
                      }

                      if (currentTime >= wordList[wordList.length - 1].offset + wordList[wordList.length - 1].duration) {
                          clearInterval(intervalId);
                          setPlaying(false); // Set playing to false when the audio finishes
                      }
                  }, 10); // Check every 10 milliseconds

              } else {
                  console.warn('Word boundaries are not available.');
                  setPlaying(false); // Set playing to false if no word boundaries are available
              }
          } else {
              console.error('Speech synthesis canceled, ' + result.errorDetails);
              setPlaying(false); // Set playing to false in case of error
          }
          
      },
      (err) => {
          console.trace('err - ' + err);
          setPlaying(false); // Set playing to false in case of error
          
      }
  );
};

// const synthesizeSpeech = ({ text, voice = "fr-FR-VivienneMultilingualNeural", setWordIndex, setTsw, setPlaying }) => {
//   const speechConfig = sdk.SpeechConfig.fromSubscription(key_1, "francecentral");
//   const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();

//   speechConfig.speechSynthesisOutputFormat = sdk.SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3;
//   speechConfig.requestWordLevelTimestamps();

//   const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

//   let wordList = [];
//   let startTime = null;

//   synthesizer.wordBoundary = (s, e) => {
//       if (startTime === null) {
//           startTime = Date.now(); // Initialize startTime at the first word boundary
//       }
//       wordList.push({
//           word: e.text,
//           offset: e.audioOffset / 10000, // Convert to milliseconds
//           duration: e.duration / 10000   // Convert to milliseconds
//       });
//   };

//   setWordIndex(0);
//   const ssml = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xml:lang="fr-FR"> <voice name="${voice}"> <prosody rate="0%" pitch="0%"> <mstts:express-as style="cheerful"> ${text} </mstts:express-as> </prosody> </voice> </speak>`;
//   console.log('synthesizer', synthesizer)
//   synthesizer.speakSsmlAsync(
//       ssml,
//       (result) => {
//           if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
//               console.log('Synthesis finished.', result);
//               const audioBlob = new Blob([result.audioData], { type: 'audio/mp3' });
//               const audioUrl = URL.createObjectURL(audioBlob);

//               if (wordList.length > 0) {
//                   console.log("wordList", wordList);
//                   setPlaying(true);
//                   setTsw(wordList);

//                   const intervalId = setInterval(() => {
//                       let elapsedTime = Date.now() - startTime;
//                       let currentTime = elapsedTime;

//                       for (let i = 0; i < wordList.length; i++) {
//                           if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
//                               setWordIndex(i);
//                               break;
//                           }
//                       }

//                       if (currentTime >= wordList[wordList.length - 1].offset + wordList[wordList.length - 1].duration) {
//                           clearInterval(intervalId);
//                           setPlaying(false); // Set playing to false when the audio finishes
//                       }
//                   }, 10); // Check every 10 milliseconds

//               } else {
//                   console.warn('Word boundaries are not available.');
//                   setPlaying(false); // Set playing to false if no word boundaries are available
//               }
//           } else {
//               console.error('Speech synthesis canceled, ' + result.errorDetails);
//               setPlaying(false); // Set playing to false in case of error
//           }
//           synthesizer.close();
//       },
//       (err) => {
//           console.trace('err - ' + err);
//           setPlaying(false); // Set playing to false in case of error
//           synthesizer.close();
//       }
//   );
// };

const playAudioWithWordTracking = (audioUrl, wordList, setWordIndex) => {
  return new Promise((resolve) => {
      const audio = new Audio(audioUrl);
      setWordIndex(0)
      console.log('audio', audio)

     
          const currentTime = audio.currentTime * 1000; // Convert to milliseconds
          for (let i = 0; i < wordList.length; i++) {
              if (currentTime >= wordList[i].offset && currentTime < (wordList[i].offset + wordList[i].duration)) {
                  setWordIndex(i);
                  break;
              }
          }
    

    
  });
};
const voices = [
  "de-DE-FlorianMultilingualNeural",
  "de-DE-SeraphinaMultilingualNeural",
  "en-US-BrianMultilingualNeural",
  "fr-FR-VivienneMultilingualNeural"
] 




// V1 : Pouvoir lire voir des cartes facts et apprendre leur contenu
// Afficher une carte culture 
// - Faire un système de pile ou on la carte est vue et on peut la marquer comme apprise
// - Liker, partager une carte 
// Si la piste est terminé, montrer carte liké puis les autres.

// Faire un système de missions, lire 10 cartes, apprendre 10 mots
// Système de segmentation tiktok, tout les 30-40 caractère, puis traduction.

async function readSentences(sentences, lang) {
  for (const sentence of sentences) {
    await speak({ text: sentence, lang });
  }
}



const ImageSlider = ({ src, cent, className, active }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = useMemo(() => src, [src]);

  useEffect(() => {
    if (active) {
      const newIndex = Math.floor((cent / 100) * images.length);
      setCurrentIndex(newIndex % images.length);
    }
  }, [cent, images.length, active]);

  return (
    <div className={`${className} image-slider`}>
      <div>currentIndex: {currentIndex}</div>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={`image ${index === currentIndex ? 'active' : 'inactive'}`}
        />
      ))}
    </div>
  );
};




function supprimeNumerotationEtDivise(texte) {
  // Utiliser une expression régulière pour trouver les numérotations et les phrases
  const phrases = texte.split(/\[\d+\]\s*/).filter(Boolean);

  // Joindre les phrases avec " [] " comme séparateur
  return phrases.join(' [] ');
}
const splitWordsAndPunctuation = (sentence) => {
  return sentence.match(/[\wÀ-ÿ]+|[.,!?;:()]/g);
};


const ElFacto = ({fact, lang, facts, setFact, indexFact, ct, reelMode, index}) => {

  const storage = getStorage(app);

  const [tradMode, setTradMode] = useState(false)
  const [colorText, setColorText] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [darkMode, setDarkMode] = useState(false)
  const [wordIndex, setWordIndex] = useState(false)
  const [expended, setExpended] = useState(true)
  const [tsw, setTsw] = useState()
  const sentence = fact[`text_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
  const more = fact[`more_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')
  const splitSentence = splitWordsAndPunctuation( sentence + " " + more)
  const question = fact[`question_${lang}`] 
  const [progressAudio, setProgressAudio] = useState()



  const text = '<break time="1s"/>' + question + '<break time="0.7s"/>' + sentence + '<break time="0.7s"/>' + more 
  // const text = question

  const read = () => synthesizeSpeech({voice: getVoiceByIndex(voices, index), text  , setWordIndex, setTsw, setPlaying, storage, fact, lang, setProgressAudio}); 
  const groupWords = tsw && groupWordsByTime(tsw)

  const currentGroup = groupWords && findGroupByWordIndex(groupWords, wordIndex)
  
  let img = fact.img

  if (progressAudio > 20 && fact.img_1) {img = fact.img_1}
  if (progressAudio > 40 && fact.img_2) {img = fact.img_2}
  if (progressAudio > 60 && fact.img_3) {img = fact.img_3}


  // const currentGroup=  [{word: "Las", current: false}, {word: "abejas", current: false}, {word: "se", current: true}, {word: "comunican", current: false}]

  // split 1s 
 

  // const content = fact[`text_${lang}`]?.replace(/\[\d+\]/g, '').replace(/\|\|/g, "")

  return <div onClick={(e) => e.stopPropagation()} className='relative snap-center mb-8 text-slate-500 bg-white overflow-hidden rounded-xl   '>
    <div className={` ${expended ? 'overflow-hidden ': "overflow-auto pb-[30px]"}   h-[500px] `}>
    {fact.img ? <div onClick={() => {
      if(!playing){pauseAudio();read()}
      if(playing) {pauseAudio(); setPlaying(false)}
    }} className='relative'>
      <ImageSlider src={[fact.img, fact.img_1 || fact.img, fact.img_2 || fact.img, fact.img_3 || fact.img]} cent={progressAudio} active={playing} className={`object-cover rounded-xl  w-full ${expended ? 'h-[500px]': "h-[200px]"}`} />
      {/* <img className={`object-cover rounded-xl  w-full ${expended ? 'h-[500px]': "h-[200px]"}  `} src={img} /> */}
      <div className={`absolute left-0 right-0 flex items-center justify-center bottom-2 top-0 ${(!playing && expended) ? "bg-gradient-to-b from-black/10 to-black/50" : "" } `}>
           {(!playing && expended) ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-16 w-16 text-white bg-white/20 p-2 rounded-full">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
      </svg> : "" }

      </div>
      { playing && <div className='absolute bottom-[68px] left-0 right-0 h-[4px] bg-black/20'><div className='bg-indigo-500 h-[4px] rounded-xl transition-all' style={{width: (progressAudio)+"%"}}></div></div>}
      {!playing && expended&&  <div className='absolute p-4 bottom-[80px]'>
        <div className='bg-black/20 text-2xl font-semibold text-center backdrop-blur text-shadow text-white rounded-md p-1 px-3'>{fact[`question_${lang}`]}</div>
      </div>}
      <div onClick={(e) => {e.stopPropagation(); setExpended(!expended)}} className={`absolute z-[20] bottom-0 left-0 right-0 ${!expended ? "bg-white rounded-xl" : "bg-black/30 rounded-xl overflow-hidden backdrop-blur-xl text-white rounded-t-none" } pt-2 py-1  `}>
      <div className='flex justify-center'>
        {expended ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 scale-x-[1.6]">
  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6  scale-x-[1.6]">
  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
}
</div>
{expended && <div className='text-center pb-2'>Lire la carte </div>}
      </div>

    <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
    <div className={`relative mt-4 px-4 ${!expended ? "" : "pt-[200px]"}  flex justify-center`}>
    <div className='text-3xl pop flex justify-center transition-all text-center flex-wrap text-white font-semibold text-shadow px-1 rounded '>
    {playing && currentGroup?.map((e,index) => <div key={"w-"+index} className={`${e.current ? "bg-[#875CFF] rounded-md text-yellow-100" : ""} transition-all backdrop-blur bg-[#6360ff] rounded-md -ml-2 px-2 `}>{e.word}</div>)}

    </div>
  </div>
      {/* <div className='text-xl text-white font-semibold text-shadow px-1 rounded bg-amber-500'>{splitSentence[wordIndex]?.replace('.', "")}</div> */}
      </div>
</div> : <div className='h-[80px]'></div>}



    {/* {!expended && <div className={`bg-blue-500 flex gap-2 items-center text-white pt-5 px-2 py-1 rounded-xl`} onClick={() => {
      // const sentences = supprimeNumerotationEtDivise(fact[`more_${lang}`].replace(/\|/g, ''))
      // const sentencesFr = supprimeNumerotationEtDivise(fact[`more_fr`].replace(/\|/g, ''))
     
      // readSentences(sentences, ct.workspace.lang)
      // speak({ text: sentence, lang })
      read()
      
      console.log(sentence)
      // console.log(sentencesFr)
    }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
  </svg>
  <div className='rounded-xl bg-white/20 h-[10px] w-full'></div>
  </div>} */}

  {!expended ? <div className={`${!expended ? "bg-white" : "bg-black/20 backdrop-blur-xl" }   transition-all z-10 -mt-4 rounded-xl p-4 `}>

   {/* <div className='text-slate-500 mt-2 text-md'>{fact[`title_${lang}`]?.replace(/"/g, '')}</div> */}
   
    <div className=' text-purple-500 text-xl mb-4'>{fact[`question_${lang}`]?.replace(/"/g, '')}</div>
 
    
    <SuperText 
                        tradMode={tradMode} 
                        colorText={colorText}
                        text={fact[`text_${lang}`]} 
                        darkMode={darkMode}
                        imgContext={fact.img || fact.img_1}
                        trad={fact[`text_fr`]} 
                        decks={ct?.decks || []} 
                        cards={ct.cards}  
                        ct={ct}/>
    
    <SuperText 
                        tradMode={tradMode} 
                        className={"text-sm text-slate-400 mt-4"}
                        colorText={colorText}
                        text={fact[`more_${lang}`]} 
                        darkMode={darkMode}
                        imgContext={fact.img || fact.img_1}
                        trad={fact[`more_fr`]} 
                        decks={ct?.decks || []} 
                        cards={ct.cards}  
                        ct={ct}/>


    </div> : <div></div>}
    </div>
    
  
    {/* <div className='absolute bottom-0 right-0 left-0 flex justify-end p-2'>
      <div onClick={(e) => {
        e.stopPropagation()
        const indexFact = facts.indexOf(fact)
        setFact(facts[indexFact + 1])
        console.log('indexFact', indexFact)
        console.log('facts[indexFact + 1]', facts[indexFact + 1])
      }} className='bg-purple-500 text-white border-2 border-b-4 rounded-xl px-3 py-1 border-purple-600'>Suivant</div>
    </div>
    <div onClick={() => {setColorText(!colorText)}} className={`absolute bottom-4 left-4 ${colorText ? "text-emerald-600" : "text-slate-400 "} `}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
    </svg>
    </div> */}
  </div>
}



const CultureG = (props) => {
    const navigate = useNavigate()
    const [fact, setFact] = useState()
    const menu = props.menu
    const ct = props.ct
    


    useEffect(() => {
        console.log("CultureG loaded")
        loadFacts()
        loadDecks()
       
    }, [])

    useEffect(() => {
      ct.setShowBottomBar(!fact)
     
  }, [fact])

   
   const [articles, setActicles] = useState()
   const [facts, setFacts] = useState()
   const [article, setArticle] = useState()
   const [decks, setDecks] = useState()


   const loadDecks = async () => { 
    console.log('load decks 🟢🟢🟢')
    const q = query(collection(db, 'decks'), where("type", "==", "fact"))
    const querySnapshot = await getDocs(q);
    const _facts = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('loaded decks', _facts)
    setDecks(_facts)
   }

   const loadFacts = async () => { 
    console.log('load articles 🟢🟢🟢')
    const q = query(collection(db, 'facts'))
    const querySnapshot = await getDocs(q);
    const _facts = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('loaded articles', _facts)
    setFacts(_facts)
    const nonTranslatedFacts = facts?.filter(a => !a[`title_${lang}`] || !a[`question_${lang}`])
    nonTranslatedFacts?.forEach(element => {
      translateFact(element, lang, ct.workspace?.name)
    })
   }


const updateFacts = async (article, ct) => {
    console.log(article.id)
    const articleRef = doc(db, "facts", article.id);
    const new_fact = {
        ...article,
       
        [`text_${article.origin}`]: numberSentences(article[`text_origin`])
    }
    console.log('new_fact', new_fact)
    const done = await updateDoc(articleRef, new_fact)
    setFacts(art => [...art || [], new_fact])
    if (fact) setFact(new_fact)
}

      const addFact = async (article, ct) => {
      const articleRef = collection(db, "articles");

      console.log('article', article)
      let lang = "--"
      
      switch (article.lang) {
        case "spa":
            lang = "es";
            break;
        case "fra":
            lang = "fr";
            break;
        default:
            // Si aucune correspondance n'est trouvée, lang conserve la valeur "--"
            break;
    }
       
      const new_article = {
        img: article.image,
        origin: lang,
        url: article.url,
        src: article.source.title,
        author: article?.authors?.map( a => a.author?.name )?.join(","),
        dateTime: new Date(article.dateTime),
        src_id: article.uri,
        [`text_${lang}`]: numberSentences(article.body)
       ,
        [`title_${lang}`]: article.title};

      
      const done = await addDoc(articleRef, new_article)
      setActicles(art => [...art || [], new_article])
      
      }


      


    const translateFact = async (fact, lang_code, lang_name) => {
      const factRef = doc(db, "facts", fact.id);

      const new_fact = {
        ...fact,
        [`text_${lang}`]: await translate(fact.text_fr, lang_name),
        [`question_${lang}`]: await translate(fact.question_fr, lang_name),
        [`title_${lang}`]: await translate(fact.title_fr, lang_name),
        [`more_${lang}`]: await translate(fact.more_fr, lang_name),
      }
       console.log('new_fact', new_fact)
       const done  = await updateDoc(factRef, new_fact)
        console.log('done', done)
        setFacts(art => art.map(a => a.id == fact.id ? new_fact : a))
       return new_fact

    } 

   


    const [tradMode, setTradMode] = useState(false)
    const [colorText, setColorText] = useState(false)
    const [listFacts, setListFacts] = useState()
    const [selectedDeck, setSelectedDeck] = useState()

    let lang = ct.workspace?.lang?.split('-')[0]


    const userCards = ct.userCards?.filter(u => u.origin == "news")
  
  

    const [darkMode, setDarkMode] = useState(false)
    const [loadingTranslation, setLoadingTranslation] = useState(false)

    const nonTranslatedFacts = facts?.filter(a => !a[`title_${lang}`] || !a[`question_${lang}`] || !a[`more_${lang}`] )

    const [showLiane, setShowLiane] = useState(false)
    const [reelMode, setReelMode] = useState(false)




   
   


    return <div className="px-2 rounded-xl mb-2 ">

          <div>
           

            {nonTranslatedFacts?.length > 0 && <div className='flex'><div onClick={() => nonTranslatedFacts?.forEach(element => {
              translateFact(element, lang, ct.workspace?.name)
            })} className='rounded-xl px-2 bg-white/50 mb-2 '>Traduction de {nonTranslatedFacts?.length} cartes cultures </div></div>}

           



            <div className="">
            {/* <div className='p-4 bg-black/20 rounded-xl border-2 border-b-4 border-black/40 ' onClick={() => {
                console.log('userCards', userCards)
                setShowLiane(!showLiane)
            }}>
                <div className='flex gap-2 items-center justify-between'>
                    <div className='flex gap-2 items-center'>
                    <img src="/images/UX/i_voc.png" class="   transition h-[32px] "/>
                    <div className='text-lg'>{userCards?.length} mots appris</div>
                    </div>
                    <div>{!showLiane ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
}</div>
                </div>
                {showLiane && <div className='p-2 mt-4 rounded-xl bg-black/10'>
                    <div onClick={(e) => e.stopPropagation()} className='-mt-16'>
                    {props.liane}
                    </div>
                </div>}
            
            </div> */}
               {/* <div className='border-2 border-b-4 rounded-xl p-4 mb-2 '>Mes cartes cultures</div> */}
               <div>

     
     
    </div>


            
            <div>{decks?.map(d => <div onClick={() => {
              setFact(d)
              setListFacts(facts.filter(f => f.deck_id == d.id))
              setSelectedDeck(d)
            
            }} className='px-4 py-2 rounded-xl flex gap-2 justify-between items-center mt-2 text-white border-2 border-b-4 border-black/40'>
              <div>
                <div>{d.name}</div>
                <div className='text-white/50 text-sm'>{facts.filter(f => f.deck_id == d.id)?.length} cartes</div>
                </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
              </div>)}</div>

               {fact && <> <div className='text-white  backdrop-blur-xl  z-[200] fixed left-0 top-8 right-0' onClick={() => setFact()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                  </div><div onClick={() => setFact()} className={`absolute ${darkMode ? "bg-purple-700" : "backdrop-blur-[24px]"}  bg-black/50 p-2  top-0 left-0 right-0 z-[999] max-h-screen overflow-scroll bottom-0 `}>
                 
                  {/* <div className='text-white fixed top-8 left-8' onClick={(e) => {e.stopPropagation();setReelMode(!reelMode)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

                  </div> */}
                  <div className='p-4 flex justify-between items-center'>
                   

                    <svg onClick={() => setFact()}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    <div>{selectedDeck.name}</div>
                    <div className='w-6'></div>

                  </div>
                  <div className=' snap-y snap-mandatory overflow-y-scroll h-[88vh] rounded-xl flex-grow z-0 '>
                  {listFacts.map((fact,i) => <ElFacto index={i} reelMode={reelMode} fact={fact} lang={lang} setFact={setFact} facts={facts} ct={ct}/>)}

                  </div>
               
                </div></>}
        
                  <div>
              
                    {/* {facts?.map(a => {
                       
                        return <div  onClick={async() => {setFact(a)}} className='text-white p-3 flex gap-2 rounded-xl border-2 border-black/40 bg-white/10 mt-2'>
                        <img className='object-cover rounded-xl w-[67px] h-[67px]' src={a.img} />
                        <div>
                            <div className='max-h-[96px] overflow-hidden'>
                               
                            <div className='text-sm font-semibold'>{a[`title_${lang}`]}</div>
                            <div className=' text-xs '>{a[`title_fr`]}</div>
                            <div onClick={() => {translateFact(a, lang, ct.workspace?.name)}}>{!a[`title_${lang}`] && "translate"}</div>
                            </div>
                            <div className='flex flex-wrap items-center gap-2 justify-end mt-2'>
                                <div className='bg-white/10 rounded-xl text-sm px-2  '>{a.src}</div>
                            </div>
                            {(!a[`title_${lang}`] || splitNumeredSentence(a[`text_${lang}`]).length == 1) && <div onClick={(e) => {e.stopPropagation();translateFact(a, lang, ct.workspace?.name);}} className='px-2 mt-2 py-1 rounded bg-white/20'>Traduire en {ct.workspace?.name}</div>}
                        </div>
                        
                        </div>
                        })} */}


                  
                    
                  </div>

                    

            </div>
            
          </div>
      </div>

};

export {CultureG, synthesizeSpeech, voices, getVoiceByIndex, translate};





