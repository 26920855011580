import { useState, useEffect } from "react";

import {displayDuration} from '../../utilities/helper/helper.js'
import { addDoc, doc, writeBatch, onSnapshot, query, collection,limit, getDocs, where, updateDoc,getDoc, deleteDoc } from "@firebase/firestore";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { getStorage,getDownloadURL, ref, uploadBytes } from "firebase/storage";



import { db, app } from "../../config.js";

import { ImageUpload } from "../components/imageUpload.js";
import {displayDate} from '../../utilities/helper/helper.js'
import { QuizzReader } from "../components/quizzReader.js";
import { SuperEditor } from "../../components/editor.js";

const QuizzCreator = (props) => {

    

  
    const storage = getStorage(app);
    const ct = props.ct
    const [selectedTest, setSelectedTest] = useState()
    const [selectedSection, setSelectedSection] = useState()

    const init = async () => {
        const _tests = await ct.fire.getTests()
        setTests(_tests)   
       
    }

    const initPage =async (selectedTest_id) => {
            console.log('récupération des pages', selectedTest_id)
            const _pages = await ct.fire.getPages(selectedTest_id)
            console.log('récupération des _pages', _pages)
            setPages(_pages)
        
    }

    useEffect(() => {
        if (selectedTest?.id) {
           initPage(selectedTest.id)
        }
    }, [selectedTest?.id])
   

    useEffect(() => {
        ct.setShowBottomBar(false)
        init()

    }, [])

  

    const section_toiec = [
        {name: "Partie 1", id: "part_1", description: "For each question in this part, you will hear four statements about a picture in your test book. When you hear the statements, you must select the one statement that best describes what you see in the picture. Then find the number of the question on your answer sheet and mark your answer. The statements will not be printed in your test book and will be spoken only one time."},
        {name: "Partie 2", id: "part_2"},
        {name: "Partie 3", id: "part_3"},
        {name: "Partie 4", id: "part_4", audio: ""},
        {name: "Partie 5", id: "part_5"},
        {name: "Partie 6", id: "part_6"},
        {name: "Partie 7", id: "part_7"},
    ]
 

    const [pages, setPages] = useState([])
    const [tests, setTests] = useState()

    const userTest = {user_id: "", test_id: "", score: "", start_date: "", end_date: ""}
    const userPage = {user_id: "", page_id: "", score: "", answer: "", start_date: "", end_date: ""}
    const letters = ["A", "B", "C", "D", "E", "F"]

    const editPage = (newPage) => {
        console.log('newPage', newPage)

        const _newPage = {...newPage, inputs: newPage.inputs.map((e,i) => (e.letter ? e : {...e, letter: letters[i]}))}
        console.log('_newPage', _newPage)
       
        setPages(pages => pages.map(p => p.id == _newPage.id ? _newPage : p))
       updateDoc(doc(db, 'pages', _newPage.id), _newPage);  
    }

    const createPage = async (index_section) => {
        const _page = {content: "", audio: "", img: "", inputs: [{display: "A"}, {display: "B"}, {display: "C"}, {display: "D"}], index_section, test_id: selectedTest.id}
        const ref = await addDoc(collection(db, "pages"), _page)
        setPages(pages => [...pages, {..._page, id: ref.id}])
    }

    const createTOIEC = async () => {
        const newTOIEC = {name: "Test TOIEC", wp_id: "anglais-gb", sections: section_toiec, type: "test"}
        const ref = await addDoc(collection(db, "tests"), newTOIEC)

        setSelectedTest({...newTOIEC, id: ref.id})
        setTests(test => [...test || [], {...newTOIEC, id: ref.id}])
    }

    const updateTest = (newTest) => {
        console.log('newTest', newTest)
        setSelectedTest(newTest)
        setTests(tests => tests.map(test => test.id == newTest.id ? newTest : test))   
        updateDoc(doc(db, 'tests', newTest.id), newTest);  
    }

    const groupedPages = pages?.filter(p => p.index_section == selectedSection).sort((a,b) => (a.order || 0) - (b.order||0))
    //.sort((a,b) => (a.order || 0) - (b.order || 0))
    .reduce((r, item) => {
        const groupId = item.group_id || "alone"; // Utilisez "alone" comme clé par défaut si group_id n'existe pas
        r[groupId] = [...(r[groupId] || []), item];
        return r;
    }, {});

    const groupedPagesArray = Object.entries(groupedPages)
    .filter(([key, _]) => key !== "alone")
    .map(([_, value]) => value);

    const internaliseAudio = async (url, target = "toeic_audio_prep") => {
        const response = await fetch(url);
        const blob = await response.blob();
        const name = new Date().getTime();
        const storageRef = ref(storage, `${target}/${name}`);
        const snapshot = await uploadBytes(storageRef, blob);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('nouveau lien de l audio', downloadURL);
        return downloadURL;
      }



    async function convertOggToMp3(oggUrl) {
        const ffmpeg = new FFmpeg({ log: true });
            await ffmpeg.load();
            console.log('oggUrl', oggUrl)
        
            // Télécharger le fichier OGG
            const response = await fetch(oggUrl);
            const oggBuffer = await response.arrayBuffer();
            const oggUint8Array = new Uint8Array(oggBuffer); // Convertir en Uint8Array

            console.log('oggBuffer', oggBuffer)
            await ffmpeg.writeFile('input.ogg', oggUint8Array);

        
        
            // Écrire le fichier dans le système de fichiers virtuel de FFmpeg
            
        
            // Exécuter la commande pour convertir en MP3
            await ffmpeg.exec(['-i', 'input.ogg', 'output.mp3']);
        
            // Lire le fichier MP3 résultant
            const mp3Data = await ffmpeg.readFile('output.mp3');
            console.log('mp3Data', mp3Data)
            return URL.createObjectURL(new Blob([mp3Data], { type: 'audio/mpeg' }));
        }


  
    const editPages = (newArr, param) => newArr.forEach(page => editPage(page))

    

    const pageEdit = (page, index_page) =>  <div key={page.id+"edit"} className="my-4 border-2 p-4 mt-12 rounded-xl relative group">
    <div className="absolute top-1 right-1 text-red-500 hidden group-hover:block" onClick={async() => {await deleteDoc(doc(db, 'pages', page.id));  setPages(pages => pages.filter(p => p.id != page.id)) }}>Supprimer</div>
    <div className="mb-6 ">Question {index_page + 1} <span className="ml-12"></span>
    <input className="bg-red-100 w-[40px]" onBlur={(e) => editPage({...page, index_section: parseInt(e.target.value)})} defaultValue={page.index_section} />
    {page.content && page.content.length < 10 && !page.order && <span onClick={() => {editPage({...page, order: parseInt(page.content)})}} className="text-amber-500 px-2 border" > auto </span> }
    [--<input className="bg-yellow-100 w-[50px]" onBlur={(e) => editPage({...page, order: parseInt(e.target.value)})} defaultValue={page.order} />] <span  onClick={() => {editPage({...page, order: (parseInt(page.order||0)) + 1})}} className="" >+</span>
    </div>
    <input onBlur={(e) => editPage({...page, test_id: e.target.value})} placeholder="test_id"  className="w-full border-b text-xs text-amber-500" defaultValue={page.test_id} />

    <input onBlur={(e) => editPage({...page, group_id: e.target.value})} placeholder="group_id"  className="w-full border-b text-xs text-blue-500" defaultValue={page.group_id} />
    <input onBlur={(e) => editPage({...page, img: e.target.value})} placeholder="img_src"  className="w-full border-b text-right text-xs text-slate-300" defaultValue={page.img} />
    {!page.group_id && <div>
        <div className="flex gap-2">
            {page.img ? <img src={page.img} className="h-[80px] z-[999] hover:scale-[3] rounded" /> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" /></svg>}
            <input onBlur={(e) => editPage({...page, img: e.target.value})} placeholder="img_src"  className="w-full border-b text-right text-xs text-slate-300" defaultValue={page.img} />
        </div>
        <div className="flex gap-2">
            {page.audio ? <audio className="my-1 border-2 border-blue-500 rounded-full bg-blue-100 w-[500px]" src={page?.audio} controls></audio> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-slate-600 p-[8px] bg-slate-100 rounded-xl"><path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" /></svg>}
            <input onBlur={(e) => editPage({...page, audio: e.target.value})} placeholder="audio_src"   className="w-full border-b text-right text-xs text-slate-300" defaultValue={page.audio} />
            <div className="hover:bg-blue-600 px-2 py-1 rounded cursor-pointer bg-blue-500" onClick={async() => {
                
                const mp3Url = await convertOggToMp3(page.audio);
                console.log('mp3Url2', mp3Url)
                const newUrl = await internaliseAudio(mp3Url)
                console.log('newUrl', newUrl)
                editPage({...page, audio: newUrl})
                
                }}>Update to mp3</div>
        </div>
    </div>}
    <div className="hover:bg-blue-600 px-2 py-1 rounded cursor-pointer bg-blue-500" onClick={async() => {
                
                const mp3Url = await convertOggToMp3(page.audio);
                console.log('mp3Url2', mp3Url)
                const newUrl = await internaliseAudio(mp3Url)
                console.log('newUrl', newUrl)
                editPage({...page, audio: newUrl})
                
                }}>Update to mp3</div>
    <textarea onBlur={(e) => editPage({...page, content: e.target.value})} className="w-full  p-2 bg-slate-100 my-2 rounded-xl">{page.content}</textarea>
    <textarea onBlur={(e) => editPage({...page, content_reveal: e.target.value})} className="w-full  p-2 bg-cyan-100 my-2 rounded-xl">{page.content_reveal}</textarea>
    <SuperEditor  className="bg-yellow-50 rounded-xl my-2" content={page.richtText}  onChange={(e) => {  editPage({...page, richtText: e}) }} />   
    <div className="flex gap-2 flex-wrap">
        {page.inputs.map((input, index) => <div key={page.id+"input_"+index} className="flex-grow w-1/2 hover:bg-slate-100 cursor-pointer relative group flex gap-2 justify-between items-center border-2 p-2 rounded-xl">
            
            <div>
                <span className="mr-2 text-slate-400 text-sm">{index} -</span>
                <input  className="text-blue-700 tex-sm w-[250px]" placeholder="answer" defaultValue={input.answer} onBlur={(e) => editPage({...page, inputs: page.inputs.map(i => (i.display == input.display && i.letter == input.letter && i.reveal == input.reveal) ? {...i, answer: e.currentTarget.value} : i)})}/>
                <input  className="text-cyan-700 tex-sm text-left w-[250px]" placeholder="reveal" defaultValue={input.reveal} onBlur={(e) => editPage({...page, inputs: page.inputs.map(i => (i.display == input.display && i.letter == input.letter && i.reveal == input.reveal) ? {...i, reveal: e.currentTarget.value} : i)})}/>
   
            </div>
            <div 
            onClick={() => editPage({...page, inputs: page.inputs.map(i => (i.display == input.display && i.letter == input.letter && i.reveal == input.reveal) ? {...i, correct: !i.correct} : i)})} 
            className="h-[36px] border-2 border-slate-300 text-green-500 flex items-center justify-center rounded-full w-[36px]">{input.correct && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> } </div>
            <div onClick={() =>  editPage({...page, inputs: page.inputs.filter(i => i.display != input.display) })} className="absolute -top-2 -right-2 p-1  rounded-full hidden group-hover:bg-red-100 text-red-500 cursor-pointer hover:bg-red-400 hover:scale-110 group-hover:block"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /></svg></div>
        </div>
        )}
    </div>
</div>
  
  return (
    <div className={`fredoka h-screen overflow-scroll`}>
        <aside className="fixed border-r h-full p-8 top-0 bottom-0 left-0 w-[250px] bg-slate-50">
            {tests?.sort((a, b) => a.name.localeCompare(b.name)).map(test => <div className={`px-2 hover:bg-slate-200/50 ${selectedTest?.id == test.id ? "bg-slate-200" : ""}`} onClick={() => setSelectedTest(test)}>{test.name}</div>)}
            <div onClick={() => createTOIEC()}>Créer un TOIEC</div>
        </aside>
        <main className="ml-[250px]  p-8">
            <div className="text-xs text-slate-300 ">{selectedTest?.id}</div>
            <div onClick={() => {
               
                // console.log('pages', pages.map(p => ({c: p.content, rt: p.richtText, cr: p.content_reveal, a: p.inputs?.map(i => ({a: i.answer, r: i.reveal}))}) ))
                console.log('pages', pages.map(p => ({c: p.content})))

            } } className="text-xs text-slate-400 ">{pages.length} questions</div>
            
            <input onBlur={(e) => updateTest({...selectedTest, name: e.target.value})} placeholder="name" className="w-full mb-2 text-3xl font-bold" defaultValue={selectedTest?.name} />

            <div>
             {selectedTest?.sections?.map((section, index_section) => <div  className="bg-slate-50/50 ">
                <div className="w-full h-[4px] bg-slate-400 rounded mt-8 mb-8 "></div>
                <div className="flex gap-2 mb-4">
                    <input onBlur={(e) => updateTest({...selectedTest, sections: selectedTest?.sections.map(s => s == section ? {...section, name: e.target.value} : s)})} placeholder="name" className="grow mb-2 text-xl font-bold" defaultValue={section.name} />
                     <div onClick={() => setSelectedSection(index_section == selectedSection ? null : index_section)} className="px-4 py-1 rounded-xl bg-slate-100 text-slate-600 cursor-pointer inline-block border-2 border-b-4 mt-2"> {index_section != selectedSection ? "Voir" : "Réduire"} les {pages.filter((page) => page.index_section == index_section).length} questions </div>
                </div>
                {selectedSection == index_section && <div>
                    <textarea className="w-full  bg-slate-100 rounded-xl p-2" placeholder="description">{section.description}</textarea>
                    <div className="flex gap-2">
                                {section.audio ? <audio className="border-2 border-blue-500 rounded-full bg-blue-100 w-[500px] my-1" src={section?.audio} controls></audio> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-slate-600 p-[8px] bg-slate-100 rounded-xl"><path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" /></svg>}
                                <input placeholder="audio_src"   defaultValue={section.audio}  className="w-full border-b text-right text-xs text-slate-300"  />
                            </div>
                    <hr/>
                </div>}

               {selectedSection == index_section && pages?.filter(p => p.index_section == selectedSection && !p.group_id)?.sort((a,b) => (a.order || 0) - (b.order || 0)).map((page, index_page) =>  pageEdit(page, index_page))}
               {selectedSection == index_section && groupedPagesArray?.map((pagesArray, index_group) =>  <div>
                <div className="border-2 p-4 rounded-xl">
                    <div className="flex gap-2">
                        {pagesArray[0].img ? <img src={pagesArray[0].img} className="h-[80px] hover:scale-[8] z-100 bg-white rounded" /> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" /></svg>}
                        <input onBlur={(e) => editPages(pagesArray.map(page => ({...pagesArray[0], img: e.target.value}) ))} placeholder="img_src"  className="w-full border-b text-right text-xs text-slate-300" defaultValue={pagesArray[0].img} />
                    </div>
                    <div className="flex gap-2">
                        {pagesArray[0].audio ? <audio className="border-2 border-blue-500 rounded-full bg-blue-100 w-[500px] my-1 b" src={pagesArray[0]?.audio} controls></audio> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-slate-600 p-[8px] bg-slate-100 rounded-xl"><path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" /></svg>}
                        <input onBlur={(e) => editPages(pagesArray.map(page => ({...pagesArray[0], audio: e.target.value}) ))} placeholder="audio_src"   className="w-full border-b text-right text-xs text-slate-300" defaultValue={pagesArray[0].audio} />
                    </div>
                    <div className="border-l-2 border-blue-500">
                    {pagesArray.map((page, index_page) => pageEdit(page, index_page))}
                    </div>
                </div>
                
                </div>)}


                {/* {pages.filter((page) => page.index_section == index_section && page.index_section == selectedSection).map((page, index_page) => 
                   pageEdit(page, index_page)
                 )} */}
            {index_section == selectedSection && <div className="flex justify-end">
                <div onClick={() => createPage(index_section)} className="px-4 text-slate-600 border inline-block rounded-xl my-2 bg-slate-100 border-b-4 py-1 ">Ajouter une page +</div>
             </div>}
            </div>)}
            </div>
        </main>
        {/* <aside className="fixed border-r h-full top-0 bottom-0 right-0 w-[414px] bg-slate-50">
            {selectedTest && <QuizzReader key={selectedTest.id} mode={'training'}  pages={pages} test={selectedTest}/>}
        </aside> */}
        
        {/* <QuizzReader /> */}
   
  
    </div>
  );
};

export {QuizzCreator};