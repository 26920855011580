import { useState, useRef, useEffect } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, addDoc, updateDoc,deleteDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'




import { ImageUpload } from "../components/imageUpload.js";
import {displayDate, getCardsFromIDS, reduceCard, reduceUC} from '../../utilities/helper/helper'
import { coachs } from "../data/notification.js";

import { createClient } from '@supabase/supabase-js'
import TodoTest from "../components/TotoTest.js";
import AppSupa from "../../V3/authsupa.js";
import { transformDates, transformDatesToJS } from "../../V3/helperFunctions.js";
import { Link } from "react-router-dom";



const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const dicoDeck = [
  
    {
      "id": 8,
      "old_id": "53wJ7mdtOJiFH4KJx1We"
    },
    {
      "id": 9,
      "old_id": "54kxOJlA8qN19Q8E5dqP"
    },
    {
      "id": 10,
      "old_id": "5AxHPFn6nfA0QsXMfyrM"
    },
    {
      "id": 11,
      "old_id": "5BLmhnnm4lNnoeeU6dze"
    },
    {
      "id": 12,
      "old_id": "5YMPcP3aeM0v4Tlg7eXk"
    },
    {
      "id": 13,
      "old_id": "6ScLEL20fsIiVptRqv0U"
    },
    {
      "id": 14,
      "old_id": "6VrFXpa0LQJp2AiwHNGe"
    },
    {
      "id": 15,
      "old_id": "9xPHpt2rWMy4sfKrdotH"
    },
    {
      "id": 16,
      "old_id": "AI8xmHUWSqTAYKMlLBku"
    },
    {
      "id": 17,
      "old_id": "BEgBzRAIFSEWn9YgpAQr"
    },
    {
      "id": 18,
      "old_id": "C6MMrDTefawPCTsn85Jg"
    },
    {
      "id": 19,
      "old_id": "CQ2OILFZYK7fn5QIer5m"
    },
    {
      "id": 20,
      "old_id": "CVVWkDqaWI69uxHPLsnL"
    },
    {
      "id": 21,
      "old_id": "CxjhuJEkuNLEcYwzKDsf"
    },
    {
      "id": 22,
      "old_id": "FNS19VR6MG13Kl0GqjP3"
    },
    {
      "id": 23,
      "old_id": "IhO0pHCPflkIIktRo32q"
    },
    {
      "id": 24,
      "old_id": "KTlJMyBbRwhF52BqNNPX"
    },
    {
      "id": 25,
      "old_id": "NDNbqgiXRTlWkoNzFSvJ"
    },
    {
      "id": 26,
      "old_id": "NbeUC9aKpG3SNGzgKnju"
    },
    {
      "id": 27,
      "old_id": "TFkz7WBC2Nx1ZT9m0i78"
    },
    {
      "id": 28,
      "old_id": "TfFMZa2wtttlLAwUIMjJ"
    },
    {
      "id": 29,
      "old_id": "TgFhuCvNN0fWZhmJNaIQ"
    },
    {
      "id": 30,
      "old_id": "UfdpEYNEIYkEgsFQnR06"
    },
    {
      "id": 31,
      "old_id": "XWO7gfNzgkt3uXcSiUXv"
    },
    {
      "id": 32,
      "old_id": "c8cflNy2q4CjeHt74nPI"
    },
    {
      "id": 33,
      "old_id": "ccv8VDrWHJUKGDdr1iAd"
    },
    {
      "id": 34,
      "old_id": "cpy1SenB5eb01HhsyRR8"
    },
    {
      "id": 35,
      "old_id": "dGbJljTjxfcsbYNSs12w"
    },
    {
      "id": 36,
      "old_id": "dljXhMUFEXOvL7xLYlv1"
    },
    {
      "id": 37,
      "old_id": "ee6sAzLq4gSUJtHNTefZ"
    },
    {
      "id": 38,
      "old_id": "fIVB5tO5mvuIUghPILjL"
    },
    {
      "id": 39,
      "old_id": "fakhioUuF0GgXy4hYS7R"
    },
    {
      "id": 40,
      "old_id": "hWOLnSevlBgRjGzWli4e"
    },
    {
      "id": 41,
      "old_id": "iyHY0EOuEaMJCfXrQqMe"
    },
    {
      "id": 42,
      "old_id": "jSZ4BKTvL6jsiBvGQE6A"
    },
    {
      "id": 43,
      "old_id": "kF0Vf92FJBgx4ZUrY88i"
    },
    {
      "id": 44,
      "old_id": "kb58KCLAg5Nm8vwW0kRU"
    },
    {
      "id": 45,
      "old_id": "kkcr1PLeujAuxkqp4a4X"
    },
    {
      "id": 54,
      "old_id": "rt9yTjjKzJYExwTvPzBg"
    },
    {
      "id": 46,
      "old_id": "kpwvjbti3LIK7WlSaCOQ"
    },
    {
      "id": 47,
      "old_id": "lFlmp7SrOFjrO5rrY5in"
    },
    {
      "id": 48,
      "old_id": "mrfeIHG7KP9d30qSPqDQ"
    },
    {
      "id": 49,
      "old_id": "nOl9cxmhyl5kQArKs9h1"
    },
    {
      "id": 50,
      "old_id": "oVDtsG8QoJqkctD3TcON"
    },
    {
      "id": 51,
      "old_id": "pxBEmcl9c9iArGjNbZfM"
    },
    {
      "id": 52,
      "old_id": "r2VqXE5a9s86yAK7vi1u"
    },
    {
      "id": 53,
      "old_id": "roXo0jH1U3fwXpO7m9nk"
    },
    {
      "id": 55,
      "old_id": "tNkDJGVZ9VVEyeU098Yq"
    },
    {
      "id": 56,
      "old_id": "yjvM9M37gKuJtEvpxVly"
    }
  ]




const dicoWPSupa = [
  {
    "id": 97,
    "old_id": "international"
  },
  {
    "id": 1,
    "old_id": "anglais-gb"
  },
  {
    "id": 2,
    "old_id": "esp"
  },
  {
    "id": 78,
    "old_id": "Arabe"
  },
  {
    "id": 79,
    "old_id": "Coréen"
  },
  {
    "id": 80,
    "old_id": "Russe"
  },
  {
    "id": 81,
    "old_id": "Turc"
  },
  {
    "id": 82,
    "old_id": "allemand"
  },
  {
    "id": 85,
    "old_id": "chinois"
  },
  {
    "id": 87,
    "old_id": "hindi"
  },
  {
    "id": 88,
    "old_id": "italien"
  },
  {
    "id": 89,
    "old_id": "japonais"
  },
  {
    "id": 90,
    "old_id": "lingala"
  },
  {
    "id": 91,
    "old_id": "neerlandais"
  },
  {
    "id": 92,
    "old_id": "ourdou"
  },
  {
    "id": 93,
    "old_id": "portugais"
  },
  {
    "id": 94,
    "old_id": "roumain"
  },
  {
    "id": 95,
    "old_id": "ukrainien"
  },
  {
    "id": 96,
    "old_id": "wolof"
  }
]








// Styles en ligne pour simplifier





const functions = getFunctions(app);
const setNotification = httpsCallable(functions, 'setNotification');
const setNotificationFunction = httpsCallable(functions, 'setNotification');
const sendNotificationForUser = httpsCallable(functions, 'sendNotificationForUser');
const testgpt = httpsCallable(functions, 'gpt');
const fakeToken = "fEh4U_dF4T267-jsbK7nme:APA91bEZo9QHhLoaBasoEajfZy12LyVbFRuVxVlNAQ11N9AEwpQzeMQk7phj8g8rytktk2wieHOC0r8nAkd1jPFFGYC1DnbApNAQ6jGjRBwZrXz2RxYvZQb1REdo1kgVIWy7X3_nysdu"


const AudioRecorder = () => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // Demander l'accès au microphone
  const handleStartRecording = () => {
      if (isBlocked) {
          console.log('Permission Denied');
          return;
      }

      navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
              mediaRecorderRef.current = new MediaRecorder(stream);
              mediaRecorderRef.current.start();

              mediaRecorderRef.current.ondataavailable = (event) => {
                  audioChunksRef.current.push(event.data);
              };

              mediaRecorderRef.current.onstart = () => {
                  setIsRecording(true);
              };

              mediaRecorderRef.current.onstop = () => {
                  const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4' });
                  const audioUrl = URL.createObjectURL(audioBlob);
                  setAudioURL(audioUrl);
                  audioChunksRef.current = [];
              };

              setIsRecording(true);
          })
          .catch(() => {
              setIsBlocked(true);
          });
  };

  const handleStopRecording = () => {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
  };

  return (
      <div>
          <button onClick={handleStartRecording} disabled={isRecording}>
              Start Recording
          </button>
          <button onClick={handleStopRecording} disabled={!isRecording}>
              Stop Recording
          </button>
          {audioURL && <audio src={audioURL} controls />}
      </div>
  );
};

const TestScreen = (props) => {
  const ct = props.context

//   const [notif, setNotif] = useState()
//   useEffect(() => {
//     console.log("testScreen loaded")
   
//     console.clear()
// }, [])

// const messaging = getMessaging(app);      
// const vapidKey = "BCfw1h2IwNt4cj-YKrQtKrnZ8WatJwEvNCPoJXrmf-UmRWJfVvbVL7oiIrlOKow2tZx-TetGaoE54NM4bZzudOA"



// const getNotif = async() => {

//   const today = new Date()
//   // const queryRef = query(collection(db, "notifications"), where("user_id", "==", ct.user?.id));

//   const queryRef = query(collection(db, "notifications"), where("date", "<", today), where("sended", "==", false), limit(5000));
//   const snapshot = await getDocs(queryRef);
//   const _notif = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
//   console.log(_notif)
//   _notif.forEach((n,i) => {
//     console.log('n' + i, n.user_id)
//   })
//   console.log('user', ct.user?.id)
//   setNotif(_notif)
// }


// function checkMicrophoneAccess() {
//   const info = document.getElementById('info')
//   // Configuration pour demander l'accès au microphone uniquement
//   const constraints = { audio: true };

//   // Demander l'accès au microphone
//   navigator.mediaDevices.getUserMedia(constraints)
//   .then(function(stream) {
//       console.log("Accès au microphone accordé.");
//       info.innerHTML += "Accès au microphone accordé."
//       // Accès accordé
//       // Vous pouvez ici arrêter le stream si vous n'en avez plus besoin immédiatement
//       stream.getTracks().forEach(track => track.stop());
//   })
//   .catch(function(error) {
//     info.innerHTML += "Accès au microphone refusé."
//       console.error("Accès au microphone refusé:", error);
      
//       // Accès refusé ou une erreur s'est produite
//   });
// }


//  function requestPermission() {
//   const data = document.getElementById('data')
//   const info = document.getElementById('info')
//   const error = document.getElementById('error')
//   console.log('Requesting permission...');
//   info.innerHTML = ""
//   info.innerHTML += "init</br>"
//   console.log('')

//   askForIOS2()

//   if (window.isInWebView) {
//     // Appel une fonction native pour demander la permission
//     window.webkit.messageHandlers.requestNotificationPermission.postMessage({});
//     info.innerHTML += "init web view</br>"
//   }  else { 
//     info.innerHTML += "no web view</br>"

//   }
//   try {

//     if (window.myFcmToken) {
//       info.innerHTML += "myFcmToken !</br>"
//       console.log("FCM Token from native app:", window.myFcmToken);
//       info.innerHTML += "</br>-----</br>"
//       info.innerHTML += window.myFcmToken
//       info.innerHTML += "</br>-----</br>"
//       fire.updateUser({...ct.user, fcmToken: window.myFcmToken}, ct)
     
//       setNotificationFunction({ fcmToken: window.myFcmToken })
//       .then((result) => {
//         console.log("Notification set:", result);
//         info.innerHTML += "</br> NOTIFICATION ENVOYEE" 
//       })
//       .catch((error) => {
//         console.error("Error calling setNotification:", error);
//         info.innerHTML += "</br> BUG ENVOIE ENVOYEE" + JSON.stringify(error)
//       });
//       return
//   } else {
//     info.innerHTML += "myFcmToken... n'existe pas</br>"
//   }
//   if (!Notification) {
//     info.innerHTML += "pas de notif !</br>"
//     return
//   }
//   Notification.requestPermission().then(async(permission) => {
//     info.innerHTML += "init 2</br>"
//     if (permission === 'granted') {
//       info.innerHTML += "granted</br>"
//       console.log('Notification permission granted.');

//       const newSw = await navigator.serviceWorker.register( 'service-worker.js' );

// getToken(messaging, { vapidKey: vapidKey,  serviceWorkerRegistration:newSw }).then((currentToken) => {
//   console.log('Current token', currentToken);
//   const data = document.getElementById('data')
//   data.innerHTML = currentToken
//   fire.updateUser({...ct.user, fcmToken: currentToken}, ct)
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     console.log('CURRNET TOKEN', currentToken)
//     setNotificationFunction({ fcmToken: currentToken })
//     .then((result) => { console.log("Notification set:", result);
//     })
//     .catch((error) => {
//       console.error("Error calling setNotification:", error);
//     });
    
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     error.innerHTML += "No registration token available. "
//     // ...
//   }
// })
// .catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   error.innerHTML += "An error occurred while retrieving token"
//   // ...
// });
//     } else {
//       console.log('Notification permission NOT granted.');
//       info.innerHTML += "not granted"
//       error.innerHTML += "not granted notificaton"
//     }
//   })} catch (err) {
//     console.log('error', err)
//     error.innerHTML += err
//   }
// }
  
// function copyToClipboard(text) {
//   console.log(text)
//   navigator.clipboard.writeText(text).then(function() {
//       console.log('Text successfully copied to clipboard');
//   }).catch(function(err) {
//       console.error('Unable to copy text to clipboard', err);
//   });
// }

// const askForIOS = () => {
//   const info = document.getElementById('info')
//   const error = document.getElementById('error')
//   error.innerHTML = JSON.stringify(window?.webkit?.messageHandlers)
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.requestNotificationPermission) {
//     window.webkit.messageHandlers.requestNotificationPermission.postMessage(null);
    
//     info.innerHTML = 'WEBIT MODE'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }

// const askForIOS2 = () => {
//   const info = document.getElementById('info')
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
  
//     info.innerHTML = 'WEBIT MODE 2'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }

// const askForIOS3 = () => {
//   const info = document.getElementById('info')
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
  
//     info.innerHTML = 'WEBIT MODE 2'
//   } else {
   
//     console.log("Interface native non disponible");
//     info.innerHTML = 'pas de webkit'
//   }
// }


// const handleNotification = async () => {
//   const info = document.getElementById('info');
//   let _token = "";

//   // Demander la permission pour les iPhones
//   if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["push-permission-request"]) {
//     window.webkit.messageHandlers["push-permission-request"].postMessage(null);
//   }

//   if (window.myFcmToken) {
//     _token = window.myFcmToken; // Récupération du token iPhone
//   } else { // Pour Android
//     info.innerHTML += 'ANDROID // <br/> ';
//     const permission = await Notification.requestPermission();

//     if (permission === 'granted') {
//       info.innerHTML += 'GRANTED // <br/> ';
//       const newSw = await navigator.serviceWorker.register('service-worker.js');
//       try {
//         _token = await getToken(messaging, { vapidKey: vapidKey, serviceWorkerRegistration: newSw });
//         info.innerHTML += 'GET TOKEN // <br/> ' + _token;
//       } catch (error) {
//         console.error('Unable to get token', error);
//       }
//     } else {
//       console.log('Permission not granted for Notifications');
//     }
//   }
//   console.log('token', _token);
//   info.innerHTML += 'TOKEN == ' + _token;
//   fire.updateUser({ ...ct.user, fcmToken: _token }, ct);
//   fire.addNotifications(ct);
// };




// const handleButtonClick = async () => {
//   setNotificationFunction({ fcmToken: fakeToken })
//   .then((result) => {
//     console.log("Notification set:", result);
//   })
//   .catch((error) => {
//     console.error("Error calling setNotification:", error);
//   });
// }

// const SpeechRecognition =
//   window.SpeechRecognition || window.webkitSpeechRecognition;
// const SpeechGrammarList =
//   window.SpeechGrammarList || window.webkitSpeechGrammarList;
// const SpeechRecognitionEvent =
//   window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;



// const [speechResult, setSpeechResult] = useState()
// const [recording, setRecording] = useState()



// let globalRecognition; // Déclaration au niveau du module/component



// const go = () => {
//   const info = document.getElementById('info');
//   setRecording(true);

//   // Vérifiez la disponibilité de SpeechRecognition avec un support pour Safari via webkitSpeechRecognition
//   const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  
//   // Si SpeechRecognition n'est pas supporté, affichez un message et arrêtez l'exécution de la fonction
//   if (!SpeechRecognition) {
//     console.error("La reconnaissance vocale n'est pas supportée par ce navigateur.");
//     setSpeechResult('La reconnaissance vocale nest pas supportée par ce navigateur.')
//     setRecording(false);
//     info.innerHTML = "La reconnaissance vocale n'est pas supportée par ce navigateur.";
//     return;
//   }

//   var recognition = new SpeechRecognition();
//   globalRecognition = recognition; // Stockez l'objet recognition dans la variable globale

//   const langDATA = document.querySelector('#lang-data').value;
//   recognition.lang = langDATA;
//   recognition.continuous = true;
//   recognition.interimResults = false;
//   recognition.maxAlternatives = 1;

//   console.log('langDATA', langDATA);
//   setSpeechResult();

//   recognition.onresult = function(event) {
//     var speechResult = event.results[0][0].transcript;
//     console.log('Résultat reconnu : ' + speechResult);
//     setSpeechResult(speechResult);
//   };

//   recognition.onspeechend = function() {
//     recognition.stop();
//     setRecording(false);
//   };

//   recognition.onerror = function(event) {
//     console.error('Erreur de reconnaissance vocale : ', event.error);
//     setRecording(false);
//     setSpeechResult('Erreur de reconnaissance vocale', JSON.stringify(event.error))
//     info.innerHTML = "Erreur de reconnaissance vocal. "+ JSON.stringify(event.error);
//   };

//   recognition.onend = function() {
//     console.log('La reconnaissance vocale est terminée');
//     setRecording(false);
//   };

//   // Démarrez la reconnaissance vocale
//   try {
//     recognition.start();
//   } catch (e) {
//     console.error('Erreur lors du démarrage de la reconnaissance vocale:', e);
//     setRecording(false);
//     info.innerHTML = "Erreur lors du démarrage de la reconnaissance vocale"+ JSON.stringify(e);
//     setSpeechResult('Erreur lors du démarrage de la reconnaissance vocale')
//   }
// };

// const stopRecording = () => {
//   if (globalRecognition) {
//     console.log('stop')
//     globalRecognition.stop(); // Arrêtez l'objet recognition
//     setRecording(false); // Mettez à jour l'état pour refléter que l'enregistrement est arrêté
//   }
//   setRecording(false)
// };

const learnedId = new Set(ct.userCards?.map(uc => uc.card_id));
const allId = ct.cardMap || [];
// const nextID = allId.filter(id => !learnedId.has(id));
const nextID = [];

for (const id of allId) {
  if (!learnedId.has(id)) {
    nextID.push(id);

    if (nextID.length >= 10) {
      break;
    }
  }
}


// const nextCards = nextID.map(id => ct.cards?.find(c => c.id == id))
// console.log(nextCards)
const [nextCards, setNextCards] = useState()
const [cardLearned, setCardLearned] = useState()


// useEffect(() => {
//   console.log('setNextCards', nextID)
//   nextID.length && getCardsFromIDS(nextID)
// }, [nextID])

function toggleVersionIndexed() {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open('myDatabase', 1);
      
      request.onerror = () => reject('Erreur DB');
      
      request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains('settings')) {
              db.createObjectStore('settings');
          }
      };
      
      request.onsuccess = () => {
          const db = request.result;
          const tx = db.transaction('settings', 'readwrite');
          const store = tx.objectStore('settings');
          
          const getRequest = store.get('version');
          
          getRequest.onsuccess = () => {
              const currentVersion = getRequest.result || 'classic';
              const newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
              store.put(newVersion, 'version');
              resolve(newVersion);
          };
          
          tx.oncomplete = () => db.close();
      };
  });
}


const updateCardsInDB = async (cards) => {
  try {

    
    const batch = writeBatch(db);

    cards.forEach(card => {
      if (!card.id) {
        console.log('Cannot update card with no ID', card);
        
        return 
      } else {

      
      const cardRef = doc(db, 'user_cards', card.id);
      console.log('update card 🟣 : ' + card.user_id +" : ", card.id)
      batch.set(cardRef, card)
      }
    });

    await batch.commit();
    console.log('Cards successfully updated in the database');
  } catch (error) {
    console.error('Error updating cards in the database: ', error);
  }
};








const transformeUserCards = async (wp_id, cards_wihout_card) => {
  console.log('transformeUserCards 🟢', wp_id)
  const q = query(collection(db, 'cards'), where('workspace_id', "==", wp_id))


  const querySnapshot = await getDocs(q);

  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('TOUTES LES CARDS + ', wp_id, cards.length)
  const cardlearnedGood = cards_wihout_card.map(lc => {
    const cardFound = cards.find(c => c.id === lc.card_id)
    if (cardFound) {
      return {...lc, content: reduceCard
        (cardFound)}
     
    } else {
      console.error('Card not found', lc.card_id)
      return {...lc}
      
    }
  }).filter(c => c != null).map(uc => reduceUC(uc))
  console.log('cardlearnedGood II', cardlearnedGood)

  updateCardsInDB(cardlearnedGood.slice(0, 2000))
  setCardLearned(cardlearnedGood)

}

const dicoSchool = [
  {
    "id": 14,
    "old_id": "3IS"
  },
  {
    "id": 15,
    "old_id": "AFIP"
  },
  {
    "id": 16,
    "old_id": "Carrel"
  },
  {
    "id": 17,
    "old_id": "EPSI"
  },
  {
    "id": 18,
    "old_id": "IGEFI"
  },
  {
    "id": 19,
    "old_id": "ISCOM"
  },
  {
    "id": 20,
    "old_id": "ISTP"
  },
  {
    "id": 21,
    "old_id": "Jean-monnet"
  },
  {
    "id": 22,
    "old_id": "XEFI"
  },
  {
    "id": 23,
    "old_id": "pigier"
  },
  {
    "id": 24,
    "old_id": "suptertiaire"
  },
  {
    "id": 25,
    "old_id": "télécom-st"
  },
  {
    "id": 26,
    "old_id": "viva"
  }
]




const loadLearnedCards = async(wp_id, user_id) => {
  console.log('loadLearnedCards 🟢', wp_id, user_id)
  const q = query(collection(db, 'user_cards'), where('workspace_id', "==", wp_id))
  const querySnapshot = await getDocs(q);
  const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('loadLearnedCards ✅', cards)



  setCardLearned(cards) 
}




  const [wp, setWp] = useState()

  const cards_wihout_card = cardLearned?.filter(lc => !lc?.content)

  const rosettaText= "في يوم من الأيام، قررت الشمس أن تشرق في السماء الزرقاء وتعطي الضوء للعالم. الجبال الشامخة كانت واقفة بصمت، والشجر الأخضر يهتز مع النسيم. الأطفال يلعبون في الحقول ويضحكون بصوت عال. القمر ظهر في الليل ليضيء السماء، والنجوم كانت تلمع ببهاء. في الزاوية، كان هناك قط أسود يراقب المشهد بهدوء. الكلمات تتطاير في الهواء، والأحلام تتجسد في عقول الناس. الزهور تتفتح مع قدوم الربيع، والطيور تغني أعذب الألحان."
  const lettersToHighlight = ['ا', 'م', 'س', "ف", "ي"];

  const arabicLetters = {
    // lettre: [isolée, initiale, médiane, finale]
    'ا': ['ﺍ', 'ﺍ', 'ﺎ', 'ﺎ'],
    'ب': ['ﺏ', 'ﺑ', 'ﺒ', 'ﺐ'],
    'ت': ['ﺕ', 'ﺗ', 'ﺘ', 'ﺖ'],
    'ث': ['ﺙ', 'ﺛ', 'ﺜ', 'ﺚ'],
    'ج': ['ﺝ', 'ﺟ', 'ﺠ', 'ﺞ'],
    'ح': ['ﺡ', 'ﺣ', 'ﺤ', 'ﺢ'],
    'خ': ['ﺥ', 'ﺧ', 'ﺨ', 'ﺦ'],
    'د': ['ﺩ', 'ﺩ', 'ﺪ', 'ﺪ'],
    'ذ': ['ﺫ', 'ﺫ', 'ﺬ', 'ﺬ'],
    'ر': ['ﺭ', 'ﺭ', 'ﺮ', 'ﺮ'],
    'ز': ['ﺯ', 'ﺯ', 'ﺰ', 'ﺰ'],
    'س': ['ﺱ', 'ﺳ', 'ﺴ', 'ﺲ'],
    'ش': ['ﺵ', 'ﺷ', 'ﺸ', 'ﺶ'],
    'ص': ['ﺹ', 'ﺻ', 'ﺼ', 'ﺺ'],
    'ض': ['ﺽ', 'ﺿ', 'ﻀ', 'ﺾ'],
    'ط': ['ﻁ', 'ﻃ', 'ﻄ', 'ﻂ'],
    'ظ': ['ﻅ', 'ﻇ', 'ﻈ', 'ﻆ'],
    'ع': ['ﻉ', 'ﻋ', 'ﻌ', 'ﻊ'],
    'غ': ['ﻍ', 'ﻏ', 'ﻐ', 'ﻎ'],
    'ف': ['ﻑ', 'ﻓ', 'ﻔ', 'ﻒ'],
    'ق': ['ﻕ', 'ﻗ', 'ﻘ', 'ﻖ'],
    'ك': ['ﻙ', 'ﻛ', 'ﻜ', 'ﻚ'],
    'ل': ['ﻝ', 'ﻟ', 'ﻠ', 'ﻞ'],
    'م': ['ﻡ', 'ﻣ', 'ﻤ', 'ﻢ'],
    'ن': ['ﻥ', 'ﻧ', 'ﻨ', 'ﻦ'],
    'ه': ['ﻩ', 'ﻫ', 'ﻬ', 'ﻪ'],
    'و': ['ﻭ', 'ﻭ', 'ﻮ', 'ﻮ'],
    'ي': ['ﻱ', 'ﻳ', 'ﻴ', 'ﻲ'],
  };
  
  // Fonction pour déterminer la forme correcte de chaque lettre
  function getArabicForm(letter, prevLetter, nextLetter) {
    if (!arabicLetters[letter]) return letter; // Si la lettre n'est pas dans notre dictionnaire
    
    const isConnectedPrev = prevLetter && arabicLetters[prevLetter];
    const isConnectedNext = nextLetter && arabicLetters[nextLetter];
    
    if (isConnectedPrev && isConnectedNext) {
      return arabicLetters[letter][2]; // Médiane
    } else if (isConnectedPrev) {
      return arabicLetters[letter][3]; // Finale
    } else if (isConnectedNext) {
      return arabicLetters[letter][1]; // Initiale
    } else {
      return arabicLetters[letter][0]; // Isolée
    }
  }
  
  function highlightLetterArabic(letters, text) {
    const letterSet = new Set(letters);
  
    return text?.split('').map((char, index) => {
      const prevChar = text[index - 1];
      const nextChar = text[index + 1];
      const correctForm = getArabicForm(char, prevChar, nextChar);
  
      if (letterSet.has(char)) {
        return (
          <span key={index} style={{ color: 'pink' }}>
            {correctForm}
          </span>
        );
      } else {
        return <span key={index}>{correctForm}</span>;
      }
    });
  }


  const lotTransfert = async(target, data) => {
    const BATCH_SIZE = 50; // Taille de chaque lot
    let successCount = 0;
    let errorCount = 0;
    for (let i = 0; i < data.length; i += BATCH_SIZE) {
      const batch = data.slice(i, i + BATCH_SIZE);
      
      try {
        const { data, error } = await supabase
          .from(target)
          .insert(batch)
          .select();

        if (error) {
          console.error(`Erreur lot ${i/BATCH_SIZE + 1}:`, error);
          errorCount += batch.length;
          
          // Tentative d'insertion un par un pour le lot qui a échoué
          for (const user of batch) {
            try {
              const { data: singleData, error: singleError } = await supabase
                .from(target)
                .insert([user])
                .select();

              if (singleError) {
                console.error('Erreur utilisateur individuel:', user, singleError);
                errorCount++;
              } else {
                successCount++;
                errorCount--;
              }
            } catch (e) {
              console.error('Erreur insertion individuelle:', user, e);
            }
          }
        } else {
          successCount += batch.length;
          console.log(`Lot ${i/BATCH_SIZE + 1} inséré avec succès`);
        }
      } catch (batchError) {
        console.error(`Erreur lot ${i/BATCH_SIZE + 1}:`, batchError);
        errorCount += batch.length;
      }
    }

    console.log(`Transfert terminé: ${successCount} succès, ${errorCount} erreurs`);
  }

  

  const targetWP = {id: 82, old_id: "allemand"}

  const fetchDecks = async () => (await getDocs(query(collection(db, "decks"), limit(9999)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchUser = async () => (await getDocs(query(collection(db, "users"), limit(9999)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchCards = async () => (await getDocs(query(collection(db, "cards"), where("type", "==", "letter"), limit(10000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchUserCards = async () => (await getDocs(query(collection(db, "user_cards"), where("user_id", "==", ct.user.id), where('workspace_id', "==", targetWP.old_id), limit(10000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchWP= async () => (await getDocs(query(collection(db, "workspaces"), limit(10000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchClasses = async () => (await getDocs(query(collection(db, "class"), limit(10000)))).docs.map(doc => ({...doc.data(), id: doc.id}));
  const fetchSchools = async () => (await getDocs(query(collection(db, "schools"), limit(10000)))).docs.map(doc => ({...doc.data(), id: doc.id}));

  const [users, setUsers] = useState([]);
  const [decks, setDecks] = useState([]);
  const [cards, setCards] = useState([]);
  const [classes, setClasses] = useState([]);
  const [schools, setSchools] = useState([])
  const [userCards, setUserCards] = useState([]);
  const [sbCards, setsbCards] = useState([]);
  const [wps, setWps] = useState([]);
  const [sbwps,setsbWps] = useState([]);




  const supafetchCard = async() => {
    let query = supabase
    .from('cards')
    .select(`
        term, id, old_id
    `)
    .eq('workspace_id', targetWP.id) // Ajoute le filtre pour workspace_id = 88

    
    const { data, error } = await query;
    if (error) console.log('error', error);
    else setsbCards(data);
    console.log('data', data)
}

  const supafetchWps = async() => {
    let query = supabase
    .from('workspaces')
    .select(`
        term, id, old_id
    `).limit(3000).range(0, 4999) 
    const { data, error } = await query;
    if (error) console.log('error', error);
    else setsbWps(data);
    console.log('data', data)
}

  function toggleVersion() {
    let currentVersion = localStorage.getItem('new_version') || 'classic';
    let newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
    localStorage.setItem('new_version', newVersion);
    window.location.reload()
}

// Initialiser la valeur si elle n'existe pas
if (!localStorage.getItem('new_version')) {
    localStorage.setItem('new_version', 'classic');
}

const [version, setVersion] = useState('classic');


// Fonction pour récupérer la version
function getVersion() {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open('myDatabase', 1);

      request.onerror = () => reject('Erreur DB');

      request.onupgradeneeded = (event) => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains('settings')) {
              db.createObjectStore('settings');
          }
      };

      request.onsuccess = () => {
          const db = request.result;
          const tx = db.transaction('settings', 'readonly');
          const store = tx.objectStore('settings');

          const getRequest = store.get('version');

          getRequest.onsuccess = () => {
              resolve(getRequest.result || 'classic'); // valeur par défaut 'classic'
          };

          tx.oncomplete = () => db.close();
      };
  });
}



function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

function setCookie(name, value, days = 30) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

const currentVersion = getCookie('app_version') || 'classic';

window.toggleVersion = () => {
  const newVersion = currentVersion === 'classic' ? 'v2' : 'classic';
  setCookie('app_version', newVersion);
  window.location.reload(); // Recharge la page pour appliquer le changement
};

const handleVersionToggle = () => {

};

useEffect(() => {
    getVersion().then(setVersion);
}, []);


  
  return (
    <div className="w-full mt-[10px] max-h-[100vh] bg-yellow-50 overflow-scroll rounded-md relative p-4">
    {ct.user?.id}

    <div onClick={() => {
      toggleVersion()
    }} >Changement de version</div>


      <div onClick={() => {
        supafetchCard()
      }}>supa fetch ({sbCards.length} cartes)</div>
      <div className="bg-green-500 px-3 my-4 rounded" onClick={() => {
        console.log('chargement data')
        //  fetchUser().then(data => setUsers(data));
         fetchCards().then(data => setCards(data.filter(e => (e.img || e.img_400))));
         fetchClasses().then(data => setClasses(data));
         fetchSchools().then(data => setSchools(data));
        //  fetchUserCards().then(data => setUserCards(data));
        //  fetchUserCards().then(data => setUserCards(data));
        //  fetchWP().then(data => setWps(data));
        //  fetchDecks().then(data => setDecks(data));
        //  supafetchCard()
         
      }}>Charger data 
      <div>({users.length} users)</div> 
      <div>({cards.length} cartes)</div>
      <div>({decks.length} decks)</div>
      <div>({schools.length} schools)</div>
      <div>({classes.length} schools)</div>
      <div>({userCards.length} uc)</div>
      <div>---</div>
      <div>({sbCards.length}) sp cards</div>
      <div>sp workspaces</div>
       </div>


       <div className="px-2 bg-blue-500 py-2 rounded-xl text-white block" onClick={async() => {
         const dataToInsert = decks.filter(e => e.presentation_mode == "voc" || e.presentation_mode == "Grammar").map(u => ({
          name: u.name,
          old_id: u.id,
          original_id: u.original_id,
          img: u.img || u.mindcard?.img, // mindcard image
          s_id: u.s_id,
          content: JSON.stringify(u.mindcard) || JSON.stringify(u.dialog) || u.content, //dialogue 
          is_global: u.isGlobal || false,
          type: u.presentation_mode,
          workspace_id: dicoWPSupa.find(e  => e.old_id == u.workspace_id)?.id || 97
         }))

         console.log('dataToInsert', dataToInsert)

         const { data, error } = await supabase
         .from('decks')
         .insert(dataToInsert)
   
       if (error) {
         console.error('Error inserting users:', error)
       } else {
         console.log('All users have been added successfully')
       }
      }}>Transférer {decks.length} decks dans supabase</div>

      <div onClick={async() => {
         const wpToInsert = wps.filter(e => !(e.name == "esp" || e.name == "anglais-gb" || e.name == "Digi codage" || e.name == "Culture G" || e.name == "Javascript" || e.name == "international") ).map(u => ({
          name: u.name,
          lang: u.lang,
          old_id: u.id,
          img: u.img,
          lang_name: u.langName,
          order: u.order
         }))

         const { data, error } = await supabase
         .from('workspaces')
         .insert(wpToInsert)
   
       if (error) {
         console.error('Error inserting users:', error)
       } else {
         console.log('All users have been added successfully')
       }
      }}>Load WP {wps.length}</div>


<div onClick={async() => {
        const dataToInsert = schools.map(u => ({
          name: u.name,
          old_id: u.id
        }))

        console.log('dataToInsert', dataToInsert)

        
        lotTransfert("schools", dataToInsert)

        
      }
    } >transferer schools</div>

<div onClick={async() => {
        const dataToInsert = classes.map(u => ({
          code: u.code, 
          name: u.name,
          old_id: u.id,
          school_id: dicoSchool.find(e => e.old_id == u.school_id)?.id || 27
        })) 

        console.log('dataToInsert', dataToInsert)

        
        lotTransfert("groups", dataToInsert)

        
      }
    } >transferer classes</div>

      <div onClick={async() => {
        const cardsToInserts = userCards.map(u => ({
          profile_id: 550, 
          card_id: sbCards?.find(e => e.old_id == u.card_id)?.id, 
          workspace_id: targetWP.id, 
          
          old_id: u.id, // TEXT
          collected: u.collected,  // BOOL
          last_update: u.last_update?.toDate(), // DATE
          archived: u.archived, // BOOL
          next_date: u.next_date?.toDate(), // DATE
          next_trigger: u.next_trigger, // TEXT
          triggers: u.triggers.map(e => ({...e, next_date: e.next_date?.toDate()})), // TEXT
          lvl: u.lvl // INT
        })).filter(u => u.card_id)

        console.log('cardsToInserts', cardsToInserts)

        
        lotTransfert("profiles_cards", cardsToInserts)

        
      }
    } >transferer userCards</div>

      <div  className="px-2 bg-purple-500 my-2 rounded-xl text-white block" onClick={async() => {
       const cardsToInserts = cards.map(u => ({
        term: u.term || u.letter || u.sign,
        context: u.contexts?.[0].text,
        context_fr: u.contexts?.[0].trad,
        definitions: u.more,
        img: u.img_400 || u.img,
        rank_occ: u.rank_occ,
        occ_tt: u.occ_tt,
        rank: u.rank,
        // type: u.type,
        bc_id: u.bc_id,
        model: "letter",
        deck_id: dicoDeck.find(e  => e.old_id == u.deck_id)?.id,
        old_id: u.id,
        data: {
          ...u
        },
        original_card: u.original_card,
        inflexions: Array.isArray(u.inflexions) ? u.inflexions.join(',') : '',
        workspace_id: dicoWPSupa.find(e  => e.old_id == u.workspace_id)?.id || 98,
        
      }))
      console.log('cards', cards)
      console.log('cardsToInserts', cardsToInserts)
      
      const { data, error } = await supabase
      .from('cards')
      .insert(cardsToInserts)

    if (error) {
      console.error('Error inserting users:', error)
    } else {
      console.log('All users have been added successfully')
    }
      }}>Transférer les cartes</div>

<div onClick={async () => {

  
  try {
    const usersToInsert = users.map(u => ({
      name: u.name,
      blocked: u.blocked ?? false, // Valeur par défaut
      photo: u.photo || null,
      coach: u.coach || null,
      email: u.email,
      fcmToken: u.fcmToken || null,
      uid: u.uid,
      old_id: u.id,
      week_table: u.week_table || null,
      has_ios: u.ios ?? false,
      last_workspace_id: u.last_workspace_id || null,
      last_connexion: u.last_connexion?.toDate() || null,
      creation_date: u.updated?.toDate() || new Date(),
      last_class: u.class?.[0] || null,
      classes: u.class || [],
      lig_w: u.lig_w || null,
      lig_id: u.lig_id || null,
      lig_lvl: u.lig_lvl || null,
    }));

    // Diviser les utilisateurs en lots
   
    
  } catch (error) {
    console.error('Erreur générale:', error);
  }
}}>Transférer les profiles</div>

      test app: 

      <AppSupa />
      <TodoTest />

<div onClick={async () => {
        console.log('fonction testgpt')
        const prompt = [{ "role": "user", "content": "dis coucou"}]
      
        const result = await testgpt({prompt: prompt, chat: true, user_id: "ct.user?.id", fcmToken: "ct.user?.fcmToken"})
        console.log('DATA', result.data)
        }} >testgpt</div>


{<Link to={'/forest_mini'} className='bg-blue-600 border-b-4 border-2 border-blue-900 mb-2 px-4 py-2 rounded-xl inline-block'>forest mini</Link>}


   {/* <div className="text-4xl">{highlightLetterArabic(lettersToHighlight, rosettaText)}</div> */}

      {/* <RealTime ChatWithAudio /> */}
      <h1  className="text-xl" >Test 1.400</h1>

      <div onClick={async () => {
  try {
    const q = query(collection(db, 'user_workspace_element'), where('name', "==", "terre"));
    const querySnapshot = await getDocs(q);
    
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
      console.log('doc.ref', doc.ref)
    });

    await batch.commit();
    console.log('All temple_0 documents deleted successfully');
  } catch (error) {
    console.error('Error removing documents: ', error);
  }
}}>Remove all water</div>
      <div className="flex gap-2 text-sm flex-wrap">{ct.workspaces?.filter(w => w.img && w.order < 5)?.map(wp => <div  onClick={() => {
        setWp(wp)
        //props.loadNextCards(wp?.id, ct.setCardMap, ct.setCardMap)
        loadLearnedCards(wp?.id, ct.user?.id)
        }}>
        {wp.name}</div>)}</div>
      <div onClick={() => {
        console.log('click', )
        props.loadNextCards(ct.workspace?.id, ct.setCardMap)
    
      }
        }>LOAD MAP CARD - {ct.workspace?.id}</div>
      <div className="my-8 flex gap-2">
        <div>cardMap: {ct.cardMap?.length} |</div>
        <div onClick={() => {console.log("archived", cardLearned.filter(e => e.archived).map(uc => reduceUC(uc)))}}>learnedId: {cardLearned?.length} | </div>
        <div>nextID: {nextID?.length}</div> 
      </div>
      <div className="text-red-500 mb-4 pt-4 ">cards_wihout_card: {cards_wihout_card?.length}</div>
      {/* <div className="text-red-500 mb-4 pt-4 ">total_string: {JSON.stringify(cardLearned)?.length}</div> */}

      <div className="px-2 rounded-full bg-cyan-500 text-white" onClick={async () => {
        getCardsFromIDS(nextID, setNextCards)

         }}>GET NEXT CARDS</div>
      <div className="px-2 mt-2 mb-2 rounded-full bg-blue-500 text-white" onClick={() => { transformeUserCards(wp?.id, cards_wihout_card)}}>transformeUserCards ({cards_wihout_card?.length}) {wp?.id}</div>
      <div className="flex gap-2 mt-8 text-xs flex-wrap">{nextCards?.map(c => <div className="px-1 rounded bg-black/10">{c.term}</div>)}</div>
      
{/*   
      <div></div>
      <div className="p-2 text-blue-500 text-xl  border">{recording && "recording"}: {speechResult}</div>
      <div className="p-2 rounded-xl " onClick={() => go()}>GO</div>
      <div className="p-2 rounded-xl " onClick={() => stopRecording()}>Stop</div>
      <div onClick={() => {checkMicrophoneAccess()}}>checkMicrophoneAccess</div>
      <AudioRecorder />
      <div className="p-6" onClick={() => {getNotif()}}>Notification pour tout le monde {notif?.length} </div>

      <input defaultValue="en-US" id="lang-data"/>
      <audio src="https://firebasestorage.googleapis.com/v0/b/mindseed-425a8.appspot.com/o/toeic_audio_prep%2F1707826702826?alt=media&token=1a8a99c8-4313-4bc9-91be-4eafac4f8db8" controls></audio>
      <div onClick={async () => {
        console.log('data sended', {user_id: ct.user?.id, fcmToken: ct.user?.fcmToken})
        const result = await sendNotificationForUser({user_id: ct.user?.id, fcmToken: ct.user?.fcmToken})
        console.log('DATA', result.data)
        }} >sendNotificationForUser</div>
      <div onClick={askForIOS} className="px-2 py-1 rounded text-green-500 my-2  border ">Demander permission 1</div>
      <div onClick={askForIOS2} className="px-2 py-1 rounded text-blue-500 my-2 border " >Demander autorisation Iphone</div>
      <div onClick={askForIOS3} className="px-2 py-1 rounded text-blue-500 my-2 border " >Demander permission 3</div>
      <div id="info" className="text-blue-500">...</div>

      <div>mail: {ct.user?.email}</div>
      <div>User_id: {ct.user?.id}</div>
      <div>fmc : {ct.user?.fcmToken}</div>

      <div onClick={() => fire.addNotifications(ct, coachs[0], ct.user)} className="py-1 px-2 rounded border-2">Créer les 3 prochaines notifications</div>
      
      <div onClick={() => fire.getNotifications(ct)} className="">
        <div>Prochaines notifications prévues</div>
        {ct.notifications?.map(notif => <div className={`${notif.sended ? "text-green-500" : ""} p-2 rounded mt-2 border`}>{notif.content}</div>)}
      </div>


      <div className="px-2 py-1 rounded text-yellow-500 my-2 border " onClick={() => requestPermission()}>Demander les notification </div>
  
      <div id="error" className="text-red-500">...</div>
      <div  onClick={(e) => copyToClipboard(e.currentTarget.innerText)} id="data" className="text-sm hover:text-blue-500 text-green-500 text-wrap" style={{textWrap: "wrap"}}>--</div>
       */}
    </div>
  );
};

export {TestScreen, supabase, dicoWPSupa} ;