import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, writeBatch, doc} from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation } from 'react-router-dom';

import { db } from "../../../config.js";

import {learn, validCard, findOldestDateIndex} from '../../components/learn/learn.js'
import {replaceBracketsWithUnderLine} from '../../../utilities/helper/text'
import {displayDuration, displayDate} from '../../../utilities/helper/helper'
import { SuperEditor } from '../../../components/editor.js';
import { Dialog } from '../../components/dialog.js';
import { MindMap } from '../../components/mindMap.js';




const versionExt = "?&v=2"



const LearnMindCard = (props) => {

    const ct = props.context

    const deck_id = ct.activeDeck 
    const deck = ct.decks?.find(d => d.id == deck_id)
    const [mode, setMode] = useState()
    const [completion, setCompletion] = useState()
    const userDeck = ct.userDecks.find(d => d.deck_id == deck_id)

   


    const modes = [
        {
            name: "first",
            title: "Découvrir", 
            desc: "Appuie sur les mots en jaunes pour les révéler"
        }
    ]

    const _mode = modes.find(m => m.name == mode)
    

    useEffect(() => {
        ct.setShowBottomBar(false)
        return ct.setShowBottomBar(true)
    }, [])

    const image = deck?.dialog?.img


    const cardsInDecks = ct.cards?.filter(c => c.deck_id == deck?.id)?.map(card => {
        const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
       
        const user_card = {..._user_card, 
            next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
            triggers: _user_card?.triggers.map(t => t.next_date.seconds ? (
                {...t, next_date: new Date(t.next_date.seconds * 1000)}
                ) : t)}
        const state = !user_card?.next_date ? "to_learn" : user_card?.next_date > new Date() ? "mastered" : "to_review"
        const indexTrigger = user_card.next_date ? findOldestDateIndex(user_card?.triggers) : 42

        return {...card, user_card, state, indexTrigger}
    })

    
    

    const cardsToDiscover = cardsInDecks?.filter(c => !c.user_card || !c.user_card?.next_date)
    const inTwoMinute = new Date()
    //inTwoMinute.setMinutes(inTwoMinute.getMinutes() + 5);

    const cardToReview = cardsInDecks?.filter(c => c.user_card?.next_date < new Date()).map(card => ({...card, indexTrigger: findOldestDateIndex(card.user_card?.triggers)}))
    const cardMastered = cardsInDecks?.filter(c => c.user_card?.next_date > new Date())
    const cardComp = cardToReview?.filter(c => c.indexTrigger == 0)
    const cardExp = cardToReview?.filter(c => c.indexTrigger == 1)
    const nextCards = [...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardToReview || []].slice(0,20)
    // const nextCards = cardsToDiscover && cardsToDiscover[0] ?  [cardsToDiscover[0], cardsToDiscover[0], cardsToDiscover[0]] : []
    const cardCompToDo = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Compréhension ")?.next_date  < inTwoMinute)
    const cardExpToDo = cardToReview?.filter(c => c.user_card?.triggers?.find(t => t.name == "Expression")?.next_date  < inTwoMinute)
    
  

    const nextMode = () => {
        console.log('cardCompToDo', cardCompToDo.length)
        console.log('cardExpToDo', cardExpToDo)
        console.log('cardsToDiscover', cardsToDiscover.length)
        console.log('ct', ct)

        if (cardExpToDo?.length > 0) {
            setMode("exp")
            ct.setTriggerIndex(1)
        }

        if (cardCompToDo?.length > 0) {
            setMode("comp")
            ct.setTriggerIndex(0)
        }        

        if (cardsToDiscover.length > 0) {
            setMode("first")
            ct.setTriggerIndex(42)
        }

        if ((cardsToDiscover.length + cardCompToDo?.length + cardExpToDo?.length) == 0) {
            setMode("end")
            ct.setTriggerIndex()
        }
    }

        useEffect(() => {
            nextMode()
            return () => {
                ct.setTriggerIndex()
            }
        }, [])

        useEffect(() => {
            nextMode()
        }, [cardsInDecks])


        const progressCent =   (cardsInDecks.length*3 - (cardCompToDo.length + cardExpToDo.length + cardsToDiscover.length*3)) / (cardsInDecks.length*3) * 100


  return (
    <div className={`absolute bg-yellow-50 z-50 h-screen w-screen bottom-0 overflow-scroll  `}>
       
        <header className={`text-white relative flex items-center gap-4 p-4 mb-2 overflow-hidden `}>
            <Link className='text-slate-500' onClick={() => {ct.setActiveDeck(); ct.setTriggerIndex()}} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                </svg>
            </Link>
            {/* <div className='h-[10px] bg-slate-100 grow relative rounded-xl overflow-hidden'>
                <div className={`bg-purple-500  h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length + cardToReview?.length)/ cardsInDecks?.length * 100) + "%" }}></div>
                <div className={`bg-green-500 h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length)/ cardsInDecks?.length * 100) + "%" }}></div>
            </div> */}

            <div className='h-[14px] bg-black/10 grow relative rounded-full overflow-hidden'>
            <div className='h-[14px] rounded-full relative w-full bg-black/50 grow' >
                <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded-full transition-all ' style={{width: `${(progressCent)}%`}}></div>
                <div className='h-[4px] -mt-[12px] mx-2 rounded-full transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
                <div className='h-[4px] mt-[2px] mx-1 rounded-full transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
                {/* <div className='text-center text-shadow fredoka font-bold -mb-4 mt-[-20px]'><span className='text-amber-200 mr-[1px]'>{(cardsInDecks.length*3 - (cardCompToDo.length + cardExpToDo.length + cardsToDiscover.length))}</span>/{(cardsInDecks.length*3)}</div> */}
            </div> 
                <div className={`bg-amber-500  h-full absolute rounded-full`} style={{width: Math.round(progressCent) + "%" }}></div>

                {/* <div className={`bg-purple-500  h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length + cardToReview?.length)/ cardsInDecks?.length * 100) + "%" }}></div> */}
                {/* <div className={`bg-green-500 h-full absolute rounded-xl`} style={{width: Math.round((cardMastered?.length)/ cardsInDecks?.length * 100) + "%" }}></div> */}
            </div>
           
        </header>
        {/* <h1 className='text-[14px] text-center text-slate-500 mb-2 '>{deck?.name}</h1> */}




        {mode == "first"  &&
        <div className='p-4 mb-6 mx-0 text-center text-amber-600 fredoka'>
          
                <div>Appuie sur les  <span>{cardsToDiscover.length}</span > points jaunes pour découvrir le terme correspondant</div>
         
        </div>
        }

    {mode == "comp"  &&
        <div className='p-4 mb-6 mx-0 text-center text-amber-600 fredoka'>
    
    <div className=''>Appuie le point correspondant à</div>
    <div className='text-xl font-semibold inline-text'>“{cardCompToDo[0] && cardCompToDo[0].term}“</div>

        </div>
        }

{mode == "exp"  &&
        <div className='p-4 mb-6 mx-0 text-center text-amber-600 fredoka'>
    
                <div>Appuie sur les  <span>{cardExpToDo.length}</span > points violet pour trouver le terme correspondant</div>

        </div>
        }
       
        <div className='px-8'>
        {image && <img src={image} alt="" className='-z-10 sm:h-12 w-full lg:h-[200px]  rounded-xl bg-purple-500 object-cover max-w-[200px] mx-auto' />}
        </div>
        
        <main className='mb-[100px] '>

    
        <div className='p-4 max-w-[700px] mx-auto '>
             <div className=' '></div>
             <MindMap edit={false} mode={mode} answer={cardCompToDo[0]} cardsInDecks={cardsInDecks} context={ct} deck={deck} cards={cardsInDecks} />
            <div>
          
            </div>
        
 
        </div>

        {/* <div className='flex flex-col gap-2 items-center justify-center'>
            <div onClick={() => addAlert({text: "hey"})} className='b text-blue-500 text-center px-4 py-1 border-b-4 border-2 border-blue-500 rounded-xl inline-block mx-auto'>Apprendre</div>
            <div onClick={() => deletedUserCards({text: "hey"})} className='b text-pink-500 text-center px-4 py-1 border-b-4 border-2 border-pink-500 rounded-xl inline-block mx-auto'>Recommencer</div>

        </div> */}
         
        </main>
        {mode == "end" && <div className="bg-green-500 mx-4 text-center text-xl font-semibold p-4 rounded text-white fredoka">Toutes les cartes sont maitrisées 🎉</div>}

        <div className={`fixed hidden z-10 ${ct.pb ? "-bottom-[26px]": "-bottom-[36px]" } left-0 right-0 `}>
          
        {mode == "first"  &&
        <div className='bg-yellow-100 border-t-2 border-amber-400  p-4 mb-6 mx-0     text-amber-600 fredoka'>
           {cardsToDiscover.length == 0 ?   <div className='flex justify-end'><div onClick={() => nextMode()} className='b px-4 py-1 inline-block rounded-xl bg-white border border-b-4'>Suivant</div></div>  : 
            <>
                <div className='flex justify-between mb-2'>
                    <div className='px-2 rounded-full bg-yellow-200'>Découverte</div>
                   
                </div>
                <div>Appuie sur les  <span>{cardsToDiscover.length}</span > points jaunes pour découvrir le terme correspondant</div>
                <div className='flex justify-end mt-2'><div className={` ${true ? "bg-amber-500 text-white" : "bg-black/10 text-black/20"} font-bold px-4 p-2 text-center fredoka  rounded-xl `}>Continuer</div></div>
            </>
            }
        </div>
        }
        {mode == "comp" &&  <div className='bg-green-100 border-t-2 border-green-400 p-4 mb-8 text-green-600 fredoka'>
           {cardCompToDo.length == 0 ?   <div className='flex justify-end'><div onClick={() => nextMode()} className='b px-4 py-1 inline-block rounded-xl bg-white border border-b-4'>Suivant</div></div>  : 
            <>
                <div className='flex justify-between mb-2'>
                    <div className='px-2 rounded-full bg-green-200'>Compréhension</div>
                    <div>{cardsInDecks.length - cardCompToDo.length} / {cardsInDecks.length}</div>
                </div>
                <div className='flex flex-wrap gap-2 items-center'>Appuie le point correspondant à “<div className='text-2xl text-green-500 inline-text'>{cardCompToDo[0].term}“</div></div>
            </>
            }
        </div>}

        {mode == "exp" &&  <div className='bg-purple-100 border-t-2 border-purple-400 p-4 mb-8 text-purple-600 fredoka'>
           {cardExpToDo.length == 0 ?   <div className='flex justify-end'><div onClick={() => nextMode()} className='b px-4 py-1 inline-block rounded-xl bg-white border border-b-4'>Suivant</div></div>  : 
            <>
                <div className='flex justify-between mb-2'>
                    <div className='px-2 rounded-full bg-purple-200'>Expression</div>
                    <div>{cardsInDecks.length - cardExpToDo.length} / {cardsInDecks.length}</div>
                </div>
                <div>Trouve les termes correspondants</div>
            </>
            }
        </div>}
        {mode == "end" &&  <div className='bg-green-100 border-t-2 border-green-400 p-4 mb-8 text-green-600 fredoka'>
        
     

        
                    <div className='text-md'>Félicitations, tu maîtrises tous les mots à apprendre du dialogue.</div>
   
                <div className='flex justify-end'><div onClick={() => nextMode()} className='b px-4 py-1 inline-block font-medium rounded-xl bg-white border border-b-4 border-green-700 bg-green-500 text-white'>Terminer</div></div> 
               
     
           
        </div>}

     
        </div>
    </div>
  );
};

export {LearnMindCard};    



