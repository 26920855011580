import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { capitalizeFirstLetter } from '../../../utilities/helper/text';

const ModalWordLearned = ({ setShowModalWords, userCards, cards, setMagnifier}) => {
    const [showTrad, setShowTrad] = useState()
    const cardsOrdered = userCards?.map(c => cards?.find(card => card.id == c.card_id && card.model == "lang-en")).filter(c => c?.term).filter(c => !c.deck_id).sort((a, b) => (b.occ_tt || 0) - (a.occ_tt || 0))
  
  return <div id="backdrop" className='flex fredoka text-white flex-col items-between justify-between' onClick={() => {setShowModalWords(false)}} style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 101}}>
  <div onClick={(e) => e.stopPropagation()} className=" h-full overflow-scroll p-2 bg-gradient-to-b from-[#5422ce] to-[#391556]  ">
    <div className="mb-4 my-2 ">
  
      <div onClick={() => setShowModalWords(false)} className="absolute top-2 right-2 z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <img src="images/UX/crown.png" className="h-[36px] mt-8 mx-auto" />
      <h2 className="text-white  text-center mb-2">Liste des mots appris</h2>
      <div className="flex justify-center mb-8 px-2 py-1 rounded bg-white/10">
        <div className={`text-center text-sm text-white/70 grow w-[100px] inline-block mx-auto  rounded-md px-3 ${showTrad == true ? "bg-black/30" : "  "}`} onClick={() => {setShowTrad(true)}}>Traduction</div>
        <div className={`text-center text-sm text-white/70 grow w-[100px] inline-block mx-auto  rounded-md px-3 ${showTrad ? "  " : "bg-black/30" }`} onClick={() => {setShowTrad(false)}}>Terme</div>
        <div className={`text-center text-sm text-white/70 grow w-[100px] inline-block mx-auto  rounded-md px-3 ${showTrad == "image" ? "bg-black/30" : " "}`} onClick={() => {setShowTrad("image")}}>Image</div>
  
  
       
        {/* <div className="text-center text-white/70 inline-block mx-auto bg-white/10 rounded-md px-3" onClick={() => {setShowTrad(!showTrad)}}>Afficher les {showTrad? "Termes" : "Traductions"}</div>
        <div className="text-center text-white/70 inline-block mx-auto bg-white/10 rounded-md px-3" onClick={() => {setShowTrad(showTrad == "image" ? false : "image") }}>Afficher les {showTrad == "image" ? "termes" : "images"}</div> */}
  
      </div>
    </div>
    <div className="flex flex-wrap gap-1">
    {showTrad == "image" ? <div className="grid grid-cols-4 gap-4" >
      {cardsOrdered?.map(c => <img onClick={() => {
         setMagnifier({...c, sentence: null, sentence_trad: null, word: c.term, seeCard: true});
      }} src={c.img_400 || c.img} className="rounded"/>)}
      </div>
    : cardsOrdered?.map(c => <div 
      onClick={() => {
      setMagnifier({...c, sentence: null, sentence_trad: null, word: c.term, seeCard: true});
  }}  className="px-2 rounded-md bg-[#ffd34d] text-[#84390e] border-[#ea8e37] text-sm border-[1.4px] border-b-[3px] ">{ showTrad ? typeof c.trad === "string" && capitalizeFirstLetter(c.trad?.replace(/\([^)]*\)/g, "")) : capitalizeFirstLetter(c.term?.replace(/\([^)]*\)/g, ""))}</div>)}
    </div>
  
  </div>
  </div>
  }


  export {ModalWordLearned}