import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { db } from "../../../config.js";
import { calculateAverageWithIncrease, displayDate, displayDuration, missions, xpToGrade } from '../../../utilities/helper/helper.js';
import { WeekSreak } from '../weekStreak.js';
import { ParticipationNote } from '../participationNote.js';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";


const MailBox = (props) => {
    const ct = props.context
  


    function randomInRange(rangeString) {
        // Découper la chaîne pour obtenir les bornes de l'intervalle
        const [min, max] = rangeString?.split('-').map(Number);
    
        // Générer un nombre aléatoire dans cet intervalle
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    

    const getEvents = async (uwp, user_id) => {
        console.log('getEvents 🟢🟢🟢')
        const q = query(collection(db, 'event'), where("box", "==", true), where("target_user_id", "==", user_id), orderBy('date', 'desc'));
        const querySnapshot = await getDocs(q);
        const events = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))

        const q2 = query(collection(db, 'event'), where("box", "==", true), where("from", "==", uwp), orderBy('date',  'desc'));
        const querySnapshot2 = await getDocs(q2);
        const events2 = querySnapshot2.docs.map(doc => ({...doc.data(), id: doc.id}))
        const total = [...events, ...events2]
        ct.setEvents(total);
      }

    

    useEffect(() => {
        ct.setShowBottomBar(false)
        getEvents(ct.userWorkspace.id, ct.user.id)
        const noRead = ct.events?.filter(e => !e.read)?.filter(e => e.target_user_id==ct.user?.id)
        noRead?.forEach((e) => {
            updateDoc(doc(db, "event", e.id), {read: true});
        })
        ct.setEvents(ct.events?.map(e => e.read ? e : {...e, read: true}))
        return ct.setShowBottomBar(true)
        
    },[])



    const today= new Date()
    today.setHours(0, 0, 0, 0);
  
    // const mapDate = (e) => e.date instanceof Date ? {...e, date: e.date} : {...e, date: e.date.toDate()}
  
    // const todaysEvents = ct.events?.map(e => mapDate(e)).filter(e =>  e.from == ct.userWorkspace.id && e.date.setHours(0, 0, 0, 0) == today.getTime() )
   
    const myEvent = ct.events?.filter(e => e.target_user_id==ct.user?.id)

  
  return (
    <div className={`fixed fredoka overflow-y-scroll pb-[200px] top-2 border-2 border-black/70 !z-[110] left-2 right-2 bottom-2 bg-gradient-to-b from-indigo-800 to-indigo-700 rounded-2xl `}>
        <div className='max-w-[600px] fredoka mx-auto w-full '>
            <div className='fixed z-[99] top-4 right-4 text-white' onClick={() => ct.setShowPop()}>

                         <div class="absolute  top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>

            </div>
            <div className='text-white bg-black/20  flex pt-6 flex p-4 gap-4 items-center text-xl text-sm text-center'>
                <img src="/images/deco/mailbox.png" className='h-8' />
                <div>Boîte aux lettres</div>
            </div>

            <div className='p-0'>

            {!myEvent?.length && <div className='text-white  h-[300px] flex justify-center items-center text-center'>Vous n'avez pas encore reçu de message</div>}

            {myEvent?.map(ev => {
                return <div className='p-3 z-0 bg-indigo-900/50 text-white relative   justify-between  '>
                    <div>
                        <div className='text-xs text-white/80  flex flex-wrap gap-2 items-center mr-[40px]'>
                            {!ev.read && <div className='h-2 w-2 bg-red-400 rounded-full absolute top-1 right-1'></div>}
                            {ev.type == "gift" && <img src="images/deco/gift.png" className="w-5" />}
                            {ev.type == "steal" && <img src="images/deco/attack.png" className="w-5" />}
                            {ev.type == "msg" && <img src="images/deco/logo_letter.png" className="w-5" />}
                            <div className='font-semibold text-yellow-200/90 -mr-1'>{ev.user_name.split(' ')?.[0]} {ev.user_name.split(' ')?.[1]?.[0]}</div>
                            {ev.type == "gift" && <div>vous a offert un cadeau</div>}
                            {ev.type == "steal" && <div>a pillé votre forêt</div>}
                             </div>
                        <div className='text-purple-100'>{ev.message ? "“" + ev.message+ "“" : ""}</div>
                        <div className='flex justify-end w-full'>
                            {!ev.reward && ev.type == "gift" && <div onClick={() => {
                            
                                const udpatedEvent = {reward: true, open: true}
                                const updatedUWP = {elixir: (ct.userWorkspace?.elixir || 0) + ev.elixir}
                                ct.setEvents(ct.events.map(e => ev.id === e.id ? {...e, ...udpatedEvent} : e))
                                ct.setUserWorkspace({...ct.userWorkspace, ...updatedUWP})
                                updateDoc(doc(db, "event", ev.id), udpatedEvent);
                                updateDoc(doc(db, "user_workspace", ct.userWorkspace?.id), updatedUWP);
                              

                            }} className="bg-white text-indigo-600 px-3 border-2 border-b-4 py-1 mt-2 rounded-xl flex fredoka gap-2" >Récuperer <span className="flex gap-1 bg-indigo-500/20 px-1 rounded-xl">+ {ev.elixir} <img className="h-6" src="/images/deco/elixir.png"/></span></div>
                            }
                    </div>
                    </div>
                   
                    

                    <div className='text-xs absolute  top-4 text-white/60 right-2'>{displayDuration(ev.date?.toDate(), today)}</div>
                    </div>
            })}
         
        </div>
        </div>
    </div>
  );
};

export {MailBox};