const classic = {
    name: "classic",
    fields: [
        {id:"term", name: "terme", color: "green", left: true},
        {id:"def", name: "définition", color: "purple"},
        {id:"def_img", name: "Image", type: "img", color: "green", left: true},
    ],
    triggers: [
        {name: "terme", show: ["term"], consign: ["Trouve le recto"]},
    ]
}

const grammar = {
  name: "grammar",
  fields: [
      {id:"term", name: "terme", color: "green", left: true},
      {id:"def", name: "définition", color: "purple"},
      {id:"def_img", name: "Image", type: "img", color: "green", left: true},
  ],
  triggers: [
      {name: "terme", show: ["term"], consign: ["Complète la phrase"]},
  ]
}

const recto_verso = {
    
        name: "recto-verso",
        triggers: [
          {name: "recto"}, 
          {name: "verso"}
         ],
         fields: [
           {id: "term", name: "Recto", type: "text", color: "green",  left: true},
           {id: "def", name: "Verso", type: "text", color: "purple"}
         ]
       
}

const lang = {
    name: "lang-en",
    triggers: [
      {
        name: "Compréhension ", 
        consign: ["Que veut dire ce mot ?"], 
        show: ["context||term"], 
        color: "#0BBC75", bgColor: "#D6FFEE", 
        input: "comp",
        id: "c",
        alternative: [{
          id: "co",
          lvls: "1-3-5", 
          name: "Compréhension Orale",
          consign: ['Que veut dire ce mot'], 
          color: "#46A6FF",
          bgColor: "#d7e9ff", 
          show: ["context::audio||term::audio"], 
          input: "comp",
        }]
    }, 
      {name: "Expression", show: ["def_img", "trad", "context_trad", "context"], consign: ["Trouve le terme en @lang"], color: "#A146FF", bgColor: "#EBD8FF", input: "exp"}
     ],
     fields: [
       {id:"term", name: "Terme", type: "text", color: "green", left: true, audio: true},
       {id:"trad", name: "Traduction", type: "text", color: "purple", left: true},
       {id:"context", name: "Contexte", type: "text", color: "green", audio: true},
       {id:"def_img", name: "Image", type: "img", color: "green"},
       {id:"context_trad", name: "Traduction", type: "text", color: "purple"}
     ]
   }

const models = [classic, recto_verso,lang, grammar]


const _classic = {
  name: "classic", 
  has_preview: false, 
  has_first_mode: false, 
  default_model: "classic"
}

const dialog = {
  name: "dialog", 
  has_preview: true, 
  has_first_mode: true,
  default_model: "lang"
}

const mindcard = {
  name: "dialog", 
  has_preview: true, 
  has_first_mode: true,
  default_model: "lang"
}

export {models}
