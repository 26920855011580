import { useState } from "react"
import { useStateContext } from "../StateProvider"
import { SVG_close } from "../svgs"
import { toeicPaths } from "../helperData"
import { getNextCardsByTheme, sbUpdate } from "../DbFunction"


const ShowCategorieScreen = ({setShowCategorie}) => {

    const {setCardsToDiscover, setCardsToRefresh, setNextCards, workspace, profile,  profileWorkspace, setProfileWorkspace, setShowModal, nextCards} = useStateContext()

    const menu = [
        {name_fr: "Thématique", id: "theme"},
        {name_fr: "Parcours", id: "parcours"}
    ]

    const [selectedMenu, setSelectedMenu] = useState(menu[0])

    const addDeckToProfileWorkspace = (deck_id) => {
        setProfileWorkspace(prev => ({...prev, decks_ids: [...prev.decks_ids || [], deck_id] }))
    }

    const removeDeckFromProfileWorkspace = (deck_id) => {
        setProfileWorkspace(prev => ({...prev, decks_ids: prev.decks_ids.filter(d => d!== deck_id) }))
    }

    const handleClick = (deck_id) => {
        if (profileWorkspace.decks_ids?.includes(deck_id)) {
            removeDeckFromProfileWorkspace(deck_id)
        } else {
            addDeckToProfileWorkspace(deck_id)
        }
    }

    const handleValidate = () => {
        sbUpdate("profiles_workspaces", profileWorkspace.id, {decks_ids: profileWorkspace.decks_ids})
        setShowCategorie(false)
    }



    return <div className="absolute text-white p-8 pt-[100px] top-0 left-0 right-0 bottom-0 bg-purple-900/80 backdrop-blur-xl z-[61]">
        <div className="absolute top-4 left-4" onClick={() => {setShowCategorie(false)}}><SVG_close /></div>
        <div className="flex gap-2 justify-center mb-8">
            {/* {menu.map(e => <div className={`text-white ${selectedMenu.id == e.id ? "border-white/90" :"border-white/30"} px-2 border-b-2`} onClick={() => {setSelectedMenu(e)}}>{e.name_fr}</div>)} */}
        </div>
        <div class="text-2xl text-center text-white mb-8">Choisis les thématiques que tu souhaites étudier</div>
        {selectedMenu.id === "theme" && <div className="flex gap-2 flex-wrap justify-center">{toeicPaths.map(e => <div onClick={() => handleClick(e.id)} className={`text-white ${profileWorkspace.decks_ids?.includes(e.id) ? "bg-green-500" : "bg-white/20"} px-2 rounded-xl `}>{e.name}</div>)}</div>}
        {profileWorkspace.decks_ids?.length < 2 && <div className="text-white/40 text-xs text-center mt-4">Sélectionne au moins 2 thèmes <span>({profileWorkspace.decks_ids?.length}/2)</span></div>}
        <div className="fixed flex items-start justify-center bottom-0 left-0 right-0 h-[150px] ">
            <div onClick={() => {handleValidate()}} className={` ${profileWorkspace.decks_ids?.length > 1 ? "bg-purple-500" : "bg-slate-500 opacity-[0.6] pointer-events-none	"}  px-10 rounded-xl py-2 game-btn border-2 border-b-4 border-black/50 `}>Valider</div>
        </div>
    </div>
}


export {ShowCategorieScreen}