import { useState, useEffect } from "react";
import { db } from "../../config.js";
import { BrowserRouter as Router, Routes, Route, Link, useParams} from 'react-router-dom';
import { collection, addDoc, updateDoc, setDoc, getDoc,  where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";


import { ImageUpload } from "../components/imageUpload.js";
import {calculateAverageWithIncrease, differenceInDays, displayDate, displayDuration, xpToGrade} from '../../utilities/helper/helper'
import { getDayOfWeek } from "../components/weekStreak.js";


const getNotif = async(user_id) => {

  console.log('getNotif', user_id);
  const queryRef = query(collection(db, "notifications"), where("user_id", "==", user_id));
  const snapshot = await getDocs(queryRef);
  const _notif = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
  const sortedNotif = _notif.sort((a, b) => new Date(a.date) - new Date(b.date));
  return sortedNotif
 
}

const periods = [{display:"Depuis 1 an", day: 365}, {display:"Ce trimestre", day: 30*3}, {display:"Ce mois", day: 30}, {display: "Cette semaine", day: 7}]

const dataUWPAdmin = (uwp, selectedPeriod) => {
  const today = new Date();
  const periodDate = selectedPeriod ? new Date(today.getFullYear(), today.getMonth(), today.getDate() - selectedPeriod) : uwp.creation_date?.toDate()
  const score = uwp.total_xp
  const tillNextStreak = uwp.lastStreakDate?.toDate()
  tillNextStreak?.setDate(tillNextStreak?.getDate() +2)
  
//   console.log('uwp', uwp)
  const lastConnexion = uwp.updatedDate?.toDate() || uwp.lastStreakDate?.toDate()
  const lastConnexionM = (new Date()-lastConnexion)/(1000*60)
  const lastConnexionH = (new Date()-lastConnexion)/(1000*60*60)
  const firstDate = uwp.creation_date?.toDate()
  const xp_table_day = uwp.xp_table?.map((exp) => ({...exp, date: new Date(new Date(firstDate).setDate(firstDate.getDate() + exp.day)), dayString: getDayOfWeek(new Date(new Date(firstDate).setDate(firstDate.getDate() + exp.day)))}))
 
  const filtered_xp_table = xp_table_day?.filter(day => day.date > periodDate)
  const arrayOfGrade = filtered_xp_table?.map(exp => xpToGrade(exp.xp))
  const xpSum = filtered_xp_table?.reduce((acc, exp) => acc + exp.xp, 0)
  const tsSum = filtered_xp_table?.reduce((acc, exp) => acc + (exp.ts || 0), 0)


  const dateToCompare = firstDate > periodDate ? firstDate : periodDate
  const _diffDay = differenceInDays(new Date(), dateToCompare) +1
  const diffDay = _diffDay > 30 ? 30 : _diffDay 
  const note = Math.round(calculateAverageWithIncrease(arrayOfGrade, diffDay))
  const recurrence = Math.round(filtered_xp_table?.length*100/diffDay)
  const activity_session = Math.round((xpSum/12)/diffDay)
  const minPerDay = tsSum/60/7
  return {...uwp, score, lastConnexion, lastConnexionM, lastConnexionH, xp_table_day, filtered_xp_table, xpSum, tsSum, diffDay, note, recurrence, activity_session, minPerDay, tillNextStreak}
}

const renderUWPAdmin = (uwp, index, selectedPeriod, showScore = false) => {
  const {score, lastConnexion, lastConnexionM, lastConnexionH, xp_table_day, filtered_xp_table, xpSum, tsSum, diffDay, note, recurrence, activity_session, minPerDay, tillNextStreak} = uwp
  //   console.log('lastConnexion', lastConnexion)


    

 return <div key={uwp.id} id={uwp.id} to={`/forest?&uwp=${uwp.id}`} className={` bg-[#49199e]  border-black border-b-[5px] block px-2 rounded-xl p-2 mb-1 rounded  border `}>
          <div o className="flex items-center gap-4 justify-between" >
            <div className="flex items-center text-left gap-2 ">
                <div  className='ml-[2px] text-slate-400 rounded-md w-[16px]'>{index + 1}</div> 
                
                {uwp.user_photo ? <img src={uwp.user_photo} className='w-7 h-7 border-2 text-slate-400 bg-slate-600 rounded-full object-cover'/>  : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-slate-400 p-1 bg-[#07032538] rounded-full ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />


                </svg>}

                <div className={`text-left flex items-center text-yellow-200 ${uwp.user_name?.length > 16 ? "text-xs" : "text-sm"}  grow  lg:w-[200px] font-bold py-1 rounded-md capitalize`} style={{textWrap: "nowrap"}}><span className="!max-w-[130px]  inline-block overflow-hidden text-ellipsis">{uwp?.user_name}</span>  {uwp?.streak && tillNextStreak > new Date(Date.now()) && <span className="ml-2 text-amber-500 -mr-2">{uwp.streak} 🔥</span>}</div> 


            </div>


            <div className='flex gap-2 items-center '>
            
            <div className='flex gap-1 '>

            <div className='bg-[#07032538] mt-2 w-16 font-semibold text-right justify-end text-yellow-500 px-2 py-1 rounded-md flex gap-2 items-center'>{!showScore ? <div className="relative">{note > 20 ? 20 : note}/20 {note > 20 ? <div className="absolute text-xs top-4 text-amber-300 flex left-0">+{note-20} <span className="ml-1 text-[9px]">bonus</span></div>: ""}</div>  :  <>{score || 0} 
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.98361 10.3225L4.19624 9.85061L3.88307 9.43468L1.07138 5.70065C1.00192 5.59016 0.958859 5.38197 1.05927 5.06589C1.1729 4.94554 1.35612 4.85397 1.5136 4.83263L1.54366 4.83849L1.59248 4.84285L6.21197 5.25612L6.74437 5.30373L7.05239 4.87985L9.5628 1.42528C9.65585 1.36157 9.72678 1.28997 9.77867 1.22535C9.79318 1.20725 9.80659 1.18928 9.81891 1.17161C10.0003 1.01365 10.1894 0.985368 10.2947 1.00585C10.358 1.01817 10.3845 1.04229 10.3916 1.05058C10.3951 1.05464 10.4015 1.06316 10.4076 1.08242C10.414 1.10234 10.3958 0.978808 10.3916 1.05058L10.6235 2.04093L11.5147 5.94228L11.6304 6.44862L12.1405 6.60381L16.5669 7.95061L16.6137 7.96484L16.6618 7.97419L16.6668 7.97518C16.8387 8.05683 16.9488 8.20489 16.9925 8.4348C17.0252 8.60662 16.948 8.78139 16.8109 8.86995L16.7167 8.93079L14.5822 10.1494L14.5601 10.1621L14.5387 10.1759C13.8164 10.6424 13.1914 11.0433 12.8234 11.2138L12.288 11.4618L12.2975 12.0372L12.3713 16.4934C12.3554 16.7948 12.2067 16.9174 12.1185 16.9478L12.0736 16.9634L12.0306 16.9833C11.9899 17.0022 11.8455 17.0202 11.6536 16.9465C11.6035 16.8893 11.5513 16.843 11.5029 16.8064C11.47 16.7815 11.4374 16.7601 11.4063 16.7416L7.98976 13.8976L7.59623 13.57L7.10286 13.725L2.71326 15.1045L2.56244 15.1519C2.53637 15.1473 2.50706 15.1416 2.46772 15.134C2.4218 15.125 2.38454 15.1097 2.36 15.0953C2.34876 15.0886 2.34259 15.0836 2.34 15.0814L2.28036 14.9945L2.1962 14.9202C2.12699 14.859 2.03848 14.6904 2.14366 14.4063L3.98361 10.3225Z" fill="#FFCF4C"/>
              <path d="M10.3916 1.05058C10.3845 1.04229 10.358 1.01817 10.2947 1.00585C10.1894 0.985368 10.0003 1.01365 9.81891 1.17161C9.80659 1.18928 9.79318 1.20725 9.77867 1.22535C9.72678 1.28997 9.65585 1.36157 9.5628 1.42528L7.05239 4.87985L6.74437 5.30373L6.21197 5.25612L1.59248 4.84285L1.54366 4.83849L1.5136 4.83263C1.35612 4.85397 1.1729 4.94554 1.05927 5.06589C0.958859 5.38197 1.00192 5.59016 1.07138 5.70065L3.88307 9.43468L4.19624 9.85061L3.98361 10.3225L2.14366 14.4063C2.03848 14.6904 2.12699 14.859 2.1962 14.9202L2.28036 14.9945L2.34 15.0814C2.34259 15.0836 2.34876 15.0886 2.36 15.0953C2.38454 15.1097 2.4218 15.125 2.46772 15.134C2.50706 15.1416 2.53637 15.1473 2.56244 15.1519L2.71326 15.1045L7.10286 13.725L7.59623 13.57L7.98976 13.8976L11.4063 16.7416C11.4374 16.7601 11.47 16.7815 11.5029 16.8064C11.5513 16.843 11.6035 16.8893 11.6536 16.9465C11.8455 17.0202 11.9899 17.0022 12.0306 16.9833L12.0736 16.9634L12.1185 16.9478C12.2067 16.9174 12.3554 16.7948 12.3713 16.4934L12.2975 12.0372L12.288 11.4618L12.8234 11.2138C13.1914 11.0433 13.8164 10.6424 14.5387 10.1759L14.5601 10.1621L14.5822 10.1494L16.7167 8.93079L16.8109 8.86995C16.948 8.78139 17.0252 8.60662 16.9925 8.4348C16.9488 8.20489 16.8387 8.05683 16.6668 7.97518L16.6618 7.97419L16.6137 7.96484L16.5669 7.95061L12.1405 6.60381L11.6304 6.44862L11.5147 5.94228L10.6235 2.04093L10.3916 1.05058ZM10.3916 1.05058C10.3951 1.05464 10.4015 1.06316 10.4076 1.08242C10.414 1.10234 10.3958 0.978808 10.3916 1.05058Z" stroke="#FFBB00" stroke-width="2"/>
            </svg></>}</div>
            {/* <div className="text-slate-400 py-1 bg-slate-800/20 rounded-md items-center flex gap-1 text-sm p">
                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>

              </div> */}

            </div>
            
            </div>
            </div>
            <div className="flex pl-[50px] -mt-3 mb-1"><div className="px-2 py-[1px] text-xs text-slate-400 group-hover:hidden text-sm text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className={`${lastConnexionH > 24*5 || !lastConnexionH ? "bg-red-500" : lastConnexionH < 24*2 ? "bg-green-500" : "bg-white/50"} block h-2 w-2 rounded-full`}></span >{lastConnexionM < 8 ? "" :"Actif il y a "} {lastConnexion && lastConnexionM < 8 ? "En ligne" : displayDuration(lastConnexion,new Date()) || "--"} </div></div>

            <div onClick={() => {
              console.log('uwp', uwp);
              console.log('xp_table_day', xp_table_day)
              console.log('filtered_xp_table', filtered_xp_table)
              }} className="bg-black/50 text-sm rounded-md px-2 py-1">

              
           {/* {(new Date()-uwp.updatedDate?.toDate())/1000 < 60*5 ? <div className="flex pl-14 -mt-2"><div className="px-2  py-[1px] text-xs text-slate-400 group-hover:hidden text-sm text-right flex gap-2 bg-black/10 rounded-full items-center"> <span className="bg-green-500 block h-2 w-2 rounded-full"></span >En ligne</div></div> : 
           (new Date()-uwp.updatedDate?.toDate())/1000 < 60*180 ? : ""}
            */}
              <div className={`flex items-center justify-between gap-2 ${recurrence>60 ? "text-green-300": recurrence < 10 ? "text-red-300": ""}`}>
              <div className="flex items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-[14px] h-[14px]">
  <path fillRule="evenodd" d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z" clipRule="evenodd" />
</svg>


  Régularité: {recurrence}%</div>  <span className="opacity-50">{filtered_xp_table?.length}/{diffDay} jours</span></div>
              <div className={`flex items-center justify-between gap-2 ${xpSum>100 ? "text-green-300": xpSum < 30 ? "text-red-300": ""}`}>
             <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-[14px] h-[14px]">
  <path fillRule="evenodd" d="M9.58 1.077a.75.75 0 0 1 .405.82L9.165 6h4.085a.75.75 0 0 1 .567 1.241l-6.5 7.5a.75.75 0 0 1-1.302-.638L6.835 10H2.75a.75.75 0 0 1-.567-1.241l6.5-7.5a.75.75 0 0 1 .897-.182Z" clipRule="evenodd" />
</svg>

                Nb exercices: {activity_session} leçons/j </div><span className="opacity-50">{xpSum} exercices</span></div>
              <div className={`flex items-center justify-between gap-2 ${minPerDay>6 ? "text-green-300": minPerDay < 2 ? "text-red-300": ""}`}>
             <div className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-[14px] h-[14px]">
  <path fillRule="evenodd" d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z" clipRule="evenodd" />
</svg>



                Temps passé: {displayDuration( new Date(), new Date(Date.now() + (tsSum*1000/diffDay) ))}/j </div><span className="opacity-50">{displayDuration( new Date(), new Date(Date.now() + (tsSum*1000) ))}</span></div>

            </div>
            {/* <div onClick={async() => {
              const notifs = await getNotif(uwp.user_id)
              const _notif = notifs.length && notifs[0]
            
              if (_notif) {
                const dateIn30min = new Date()
                dateIn30min.setMinutes(dateIn30min.getMinutes() - 1);
                const notifRef = doc(db, 'notifications', _notif.id);
                const ref = await updateDoc(notifRef, {date: dateIn30min});
                console.log('ref udpated', ref)
              }
              console.log('notifs', _notif)
            }}>SEND NOTIFICATION NOW</div> */}
          </div>

}

const ClassScreen = (props) => {
  const ct = props.context
  const [userWorkspaces, setUserWorkspaces] = useState()
  const [selectedClass, setSelectedClass] = useState(ct.userWorkspace?.classes[0])
  const [selectedPeriod, setSelectedPeriod] = useState(7)
 

 console.log('ct.classes', ct.classes)
const adminClass = ct.classes?.filter(c => c.admin && c.admin.some(admin => admin?.user_id === ct.user?.id)).map(c => c.id);
let classesTotal = ct.userWorkspace?.classes ? [...ct.userWorkspace.classes, ...adminClass] : adminClass; 
console.log('adminClass', adminClass)
console.log('ct.userWorkspace?.classes', ct.userWorkspace?.classes)
// Remove duplicates
classesTotal = Array.from(new Set(classesTotal?.map(JSON.stringify))).map(JSON.parse);
if (ct.user?.id == "4hGyTyBi9ae0qpDW2Q2h") classesTotal = ct.classes?.map(c => c.id)
console.log('classesTotal', classesTotal)

  const getAllUserWorkspacesFromClass = async (classe_id) => {
    console.log('getAllUserWorkspacesFromClass 🟢', classe_id)
    const q = query(
      collection(db, 'user_workspace'), 
      where('classes', 'array-contains', classe_id) // Vérifie si classe_id est dans le tableau classes
    );
    const querySnapshot = await getDocs(q);
    const userWorkspaces = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})).map(uwp => !uwp.total_xp ? {...uwp, total_xp: 0} : uwp).filter(uwp => uwp.user_name)
    
    console.log('getAllUserWorkspacesFromClass', userWorkspaces)
    
    setUserWorkspaces(userWorkspaces?.map(uwp => dataUWPAdmin(uwp, selectedPeriod)))
} 

useEffect(() => {
  const _userWorkspaces = userWorkspaces?.map(uwp => dataUWPAdmin(uwp, selectedPeriod))
  setUserWorkspaces(_userWorkspaces)
}, [selectedPeriod])




  useEffect(() => {

    ct.setActiveScreen("admin")

    if (ct.workspace?.id) {
      ct.userWorkspace?.classes && getAllUserWorkspacesFromClass(selectedClass) 
    

    }
    
   
}, [ct.user?.last_workspace_id, ct.userWorkspace?.id])

console.log('ct userWorkspace', ct.userWorkspace)





  return (
    <div className="bg-[#2a0068] fredoka min-h-screen h-screen overflow-y-scroll">

      <div className='h-[200px] overflow-x-hidden bg-[#abffcc] py-8 '>
        <img src="/images/UX/seedcrew.png" className='h-[100px] mx-auto'/>
        <h1  className='mb-8 text-center text-3xl md:text-5xl pl-4 lg:pl-20'>Ma classe</h1>
      </div>

      <div className=' mx-auto text-white max-w-screen-md py-20 px-6'>
{/* <div className="block overflow-auto p-2 mb-8 -mt-16">
  <div className="flex p-2 bg-black/10 rounded-xl gap-2 ">
  {ct.userWorkspace?.classes && ct.userWorkspace?.classes?.map(classID => <div onClick={() => {getAllUserWorkspacesFromClass(classID); setSelectedClass(classID)}} className={`${classID == selectedClass ? "bg-white/30" : "bg-white/10 opacity-50"} min-w-max	 rounded py-1 px-2 text-center `}>{ct.classes?.find(c => c.id == classID)?.name}</div>)}
</div>
</div> */}

<div className="flex rounded-xl gap-2 -mt-16">
    <select 
      value={selectedClass} 
      onChange={(e) => {
        const classID = e.target.value;
        getAllUserWorkspacesFromClass(classID);
        setSelectedClass(classID);
      }}
      className="min-w-max rounded py-1 px-2 text-center bg-purple-500 text-white"
    >
      {classesTotal?.map(classID => (
        <option value={classID} key={classID} className="py-2">
          {ct.classes?.find(c => c.id == classID)?.name}
        </option>
      ))}
    </select>
  </div> 

<div className="text-white"> </div>
<select value={selectedPeriod} onChange={(e) => setSelectedPeriod(e.target.value)} className="px-2 rounded-md py-1 mb-4 mt-2 bg-purple-500 text-white">
  {periods.map(p => 
    <option key={p.day} value={p.day} className="bg-purple-500 text-white">
      {p.display}
    </option>
  )}
</select>  {userWorkspaces?.sort((a,b) => b.note - a.note)?.map((uwp, index) => renderUWPAdmin(uwp, index, selectedPeriod))}
  </div>
    </div>
  );
};

export {ClassScreen, renderUWPAdmin, dataUWPAdmin};