import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { displayDuration } from '../../utilities/helper/helper.js';


quantum.register()

waveform.register()



const RadioPlayer = ({ radio }) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.1);
  const audioRef = useRef(null);

  const togglePlay = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    const audio = audioRef.current;

    const handlePlay = () => {
      if (playing) {
        audio.play().catch((error) => {
          console.error('Error playing the audio:', error);
          setPlaying(false); // Pause if play fails
        });
      } else {
        audio.pause();
      }
    };

    const handleCanPlay = () => {
      if (playing) {
        audio.play().catch((error) => {
          console.error('Error playing the audio:', error);
          setPlaying(false);
        });
      }
    };

    const handleError = () => {
      console.error('Error with audio playback, attempting to restart...');
      audio.load(); // Reload the audio to attempt to fix playback issues
      if (playing) {
        setTimeout(() => audio.play().catch((error) => {
          console.error('Error playing the audio after reload:', error);
          setPlaying(false);
        }), 1000); // Try to play again after 1 second
      }
    };

    if (audio) {
      audio?.addEventListener('canplay', handleCanPlay);
      audio?.addEventListener('error', handleError);

      handlePlay(); // Initial play/pause based on `playing` state

      return () => {
        audio.removeEventListener('canplay', handleCanPlay);
        audio.removeEventListener('error', handleError);
      };
    }
  }, [playing]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  return (
    <div className={`px-4 py-1 ${playing ? "border-[#64ff9d] text-[#64ff9d]" : "text-white"} rounded-xl border flex justify-between mb-2`}>
      <div className=' flex gap-2 items-center '>{radio.name} {playing && <l-waveform
  size="20"
  stroke="3.5"
  speed="1" 
  color="#64ff9d" 
></l-waveform>}</div>
      <button onClick={togglePlay}>
        {playing ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
</svg>
 }
      </button>
      <audio className='hidden' ref={audioRef} controls>
        <source src={radio.src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      
    </div>
  );
};


// V1 : Pouvoir lire les articles français dans toutes les langues et appprendre les mots
// [X] Ajouter les meilleurs sources de d'articles FR
// [X] Traduire 20 articles FR en ES et en Anglais 
// [X] Lancer nouveau programme de regex

// [X] Afficher la bonne carte lors du clique avec la bonne traduction
// [] Ajouter en db la carte avec "more" 
// [] Design des cartes sans images
// [] Découper les phrases plus petites [:, [...], ...]
// [] Ajouter en db l'exemple avec "user_card"
// [] Pouvoir consulter les cartes dans "news"
// [] Pouvoir ajouter les cartes dans la forêt
// [] Automatiser traduction
// [] Ajouter les traductions en dessous des phrases
// [] Automatiser la recherche d'article fr
// [] Améliorer les regex des phrases


// V2 : Pouvoir lire des articles d'autres origines
// V3 : Pouvoir apprendre des phrases 




const readingTime = (text) => {
    if (!text) return '0 minutes';

    // Compter le nombre de caractères dans le texte
    const numCharacters = text.length;

    // Estimer le nombre de mots (moyenne de 5 caractères par mot)
    const numWords = numCharacters / 5;

    // Vitesse de lecture moyenne (225 mots par minute)
    const wordsPerMinute = 225;

    // Calculer le temps de lecture en minutes
    const timeInMinutes = numWords / wordsPerMinute;

    // Arrondir à la minute la plus proche
    const roundedTimeInMinutes = Math.round(timeInMinutes);

    return `${roundedTimeInMinutes} min `;
};







const NewScreen = (props) => {
    const navigate = useNavigate()
    const [lastCall, setLastCall] = useState()
    const menu = props.menu
    const ct = props.ct

   


    useEffect(() => {
        console.log("NewScreen loaded")
        loadArticles()
        checkLastCall()
    }, [])

   
   const [articles, setActicles] = useState()
   const [_articles, _setActicles] = useState()
   const [article, setArticle] = useState()


   const loadArticles = async () => { 
    console.log('load articles 🟢🟢🟢')
    const q = query(collection(db, 'articles'), orderBy('dateTime', 'desc'), limit(30))
    const querySnapshot = await getDocs(q);
    const _articles = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('loaded articles', _articles)
    setActicles(_articles)
   }

  const api_key = "18a1130a-5a57-4807-b32e-079e58bd5c23"
  const data = {
    "query": {
        "$query": {
            "$and": [
                {
                    "$or": [
                        {
                            "sourceUri": "lefigaro.fr"
                        },
                        { 
                            "sourceUri": "leparisien.fr"
                        },
                        {
                            "sourceUri": "sport24.lefigaro.fr"
                        },
                        {
                            "sourceUri": "lemonde.fr"
                        },
                        {
                            "sourceUri": "liberation.fr"
                        },
                        {
                            "sourceUri": "technologies.lesechos.fr"
                        },
                        {
                            "sourceUri": "lexpress.fr"
                        },
                        {
                            "sourceUri": "lepoint.fr"
                        },
                        {
                            "sourceUri": "bbc.com"
                        },
                        {
                            "sourceUri": "letudiant.fr"
                        }
                    ]
                },
                {
                    "lang": "fra"
                }
            ]
        },
        "$filter": {
            "forceMaxDataTimeWindow": "31",
            "dataType": [
                "news",
                "pr",
                "blog"
            ]
        }
    },
    "resultType": "articles",
    "articlesSortBy": "date",
    "apiKey": "18a1130a-5a57-4807-b32e-079e58bd5c23"
}

const url = "https://www.newsapi.ai/api/v1/article/getArticles"

const url2 = "https://gnews.io/api/v4/top-headlines?category=general&lang=fr&country=fr&max=10&apikey=a9a868a928a3fa73c86da2d6d62f53e1"


const checkLastCall = async(updated) => {
    const info = await getDoc(doc(db, "info", "mindseed"))
    
    let lastCall = info.data().lastRefreshFrNews
    const newDate = updated ? new Date() : lastCall.toDate() 
    
    
    updateDoc(doc(db, "info", "mindseed"), {lastRefreshFrNews: newDate})
    

    const isMoreThan6h = (lastCall.toDate().getTime() + 6 * 60 * 60 * 1000) < new Date().getTime() 
    const duration = displayDuration(lastCall.toDate(), new Date())

    console.log('lastCall', lastCall.toDate())
    console.log('isMoreThan6h', isMoreThan6h)
    console.log('duration', duration)
    setLastCall(newDate)
   

    return {
        lastCall: lastCall,
        duration: duration,
        isMoreThan6h: isMoreThan6h,
        
    }
}

const isMoreThan6h = (lastCall?.getTime() + 6 * 60 * 60 * 1000) < new Date().getTime() 

const fetch2 = async() => {
  
  const response = await fetch(url2, {
    method: "GET"})

if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
}

const responseData = await response.json();
console.log('response', response)
console.log('responseData', responseData)

}

async function fetchData() {

    const {lastCall} = await checkLastCall(true)
    console.log('lastCall', lastCall)
    const isMoreThan6h = (goodDate(lastCall).getTime() + 6 * 60 * 60 * 1000) < new Date().getTime() 
    setLastCall(goodDate(lastCall))

    if(!isMoreThan6h) return

    try {
        console.log('fetching article 🟢🟢🟢')
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const responseData = await response.json();
        _setActicles(responseData.articles?.results)
        responseData.articles?.results.slice(0,30).forEach(a => addArticle(a, ct))
        
       
        console.log(responseData.articles?.results);
    } catch (error) {
        console.error("Error:", error);
    }
}


const updateArticle = async (article, ct) => {
    console.log(article.id)
    const articleRef = doc(db, "articles", article.id);
    const new_article = {
        ...article,
       
        [`text_${article.origin}`]: numberSentences(article[`text_origin`])
    }
    console.log('new_article', new_article)
    const done = await updateDoc(articleRef, new_article)
    setActicles(art => [...art || [], new_article])
    if (article) setArticle(new_article)
}

    const addArticle = async (article, ct) => {
      const articleRef = collection(db, "articles");

      console.log('article', article)
      let lang = "--"
      
      switch (article.lang) {
        case "spa":
            lang = "es";
            break;
        case "fra":
            lang = "fr";
            break;
        default:
            // Si aucune correspondance n'est trouvée, lang conserve la valeur "--"
            break;
    }
       
      const new_article = {
        img: article.image,
        origin: lang,
        url: article.url,
        src: article.source.title,
        author: article?.authors?.map( a => a.author?.name )?.join(","),
        dateTime: new Date(article.dateTime),
        src_id: article.uri,
        [`text_${lang}`]: numberSentences(article.body)
       ,
        [`title_${lang}`]: article.title};

      console.log('new_article', new_article)
      const done = await addDoc(articleRef, new_article)
      setActicles(art => [...art || [], new_article])
      console.log('done', done)
      }


      const translate = async(text, lang_name) => {
        const prompt = `
        Donne juste la réponse. Traduit en ${lang_name} ce texte de manière naturelle comme si tu étais un ${lang_name}.  (laisse les "[1]" pour les phrases)
        Voici le texte à traduire : 
        ${text}
        `
        
        console.log('prompt', prompt);
        const request = [{ "role": "user", "content": prompt }];
        const requestData = await gpt(request, true, ct, {model: "gpt-4o-mini"}); // Make sure gpt and ct are defined
        const data_content = requestData.choices[0]?.message?.content;
        console.log('data_content', data_content);

        return data_content
     
      }


    const translate_article = async (article, lang_code, lang_name) => {
      const articleRef = doc(db, "articles", article.id);
      console.log('lang', lang_code)
      const new_article = {
        ...article,
        [`text_${lang}`]: await translate(article.text_fr, lang_name),
        [`title_${lang}`]: await translate(article.title_fr, lang_name),
      }
       console.log('new_article', new_article)
       const done  = await updateDoc(articleRef, new_article)
       console.log('done', done)
       setActicles(art => art.map(a => a.id == article.id ? new_article : a))
       return new_article

    } 

    // if (article) {
    //     console.log('article', article)
    //     // console.log('numberSentences', numberSentences(article[`text_fr`]))
    //     console.log('numberSentences', splitNumeredSentence(numberSentences(article[`text_fr`])))
    //     console.log('numberSentences2', splitNumeredSentence((article[`text_fr`])))
    // }

    const [langFilter, setLangFilter] = useState("fra")
    const [tradMode, setTradMode] = useState(false)
    const [colorText, setColorText] = useState(false)

    let lang = ct.workspace?.lang?.split('-')[0]


    const userCards = ct.userCards?.filter(u => u.origin == "news")

    const [darkMode, setDarkMode] = useState(false)
    const [loadingTranslation, setLoadingTranslation] = useState(false)

    const [showLiane, setShowLiane] = useState(false)

    const tuto = ct.user.tuto || {}

    useEffect(() => {
        if (article) {return ct.setShowBottomBar(false)} else {return ct.setShowBottomBar(true)}
    }, [article])

    const radios = [
        {name: "Sport", src: "http://27913.live.streamtheworld.com/CADENASER.mp3"},
        {name: "ES radio", src:"http://livestreaming.esradio.fm/stream64.mp3"},
       
        {name: "Carrol ", src: "http://27393.live.streamtheworld.com:3690/CARACOL_RADIOAAC_SC"},
        {name: "Radio Mágica", src: "http://playerservices.streamtheworld.com/pls/MAG_AAC.pls"},
        {name: "Blu Radio", src: "http://27263.live.streamtheworld.com/BLURADIO_SC"}
       
      ]


    

   const hidden = true
   const [showRadios, setShowRadios] = useState()
    

    return <div className="px-2 rounded-xl mb-2 ">

      {/* <div onClick={() => {fetch2()}} className='mt-16'>fetch2</div> */}
          <div>

            {/* <div className='p-4 bg-black/20 rounded-xl border-2 border-b-4 border-white/30 ' onClick={() => {
                console.log('userCards', userCards)
                setShowLiane(!showLiane)
            }}>
                <div className='flex gap-2 items-center justify-between'>
                    <div className='flex gap-2 items-center'>
                    <img src="/images/UX/i_voc.png" class="   transition h-[32px] "/>
                    <div className='text-lg'>{userCards?.length} mots appris</div>
                    </div>
                    <div>{!showLiane ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>
 : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
 <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
}</div>
                </div>
                {showLiane && <div className='p-2 mt-4 rounded-xl bg-black/10'>
                    <div onClick={(e) => e.stopPropagation()} className='-mt-16'>
                    {props.liane}
                    </div>
                </div>}
            
            </div> */}
                  {/* <div className=' text-white/70 mb-1 text-xs'>Obtiens 10xp pour débloquer cette fonctionnalité </div> */}

            {/* {ct.user.admin && <div onClick={() => setShowRadios(!showRadios)} className='px-3  py-1 bg-black/10 border-b-4 border-black/50 rounded-xl mt-8 mb-4 border-2'>
                {hidden && !showRadios ? <div className='flex gap-4 py-1 items-center'>
                  <img className='h-[45px] w-[40px] bg-yellow-100/10 rounded-xl p-2 ' src="/images/deco/lock.png" />
                  <div>
                  <div className='mb-2 text-sm'>Fonctionnalité cachée</div>
                  
                  <div className='h-2 rounded-full w-full bg-black/20 relative'>
                  <div className='h-2 absolute rounded-full w-1/2 bg-amber-500/90 '>

                  </div>
                  </div>
                  </div>
            
                </div> : <div className='flex gap-2 mb-4 items-center'>
                  <img className='h-[80px] -mt-4' src="/images/deco/radio.png" />
                  <div className='text-xl mt-2'>Ecouter la radio</div>
                </div>}
                 <div className='bg-back/10' onClick={(e) => e.stopPropagation()}>{showRadios && radios.map(r => <RadioPlayer radio={r}/>)}</div>
              </div>} */}


            {(isMoreThan6h) && <div className='flex my-4 justify-center '><div onClick={() => (fetchData())} className='bg-white/20 border rounded-xl px-2'>Charger les derniers articles</div></div>}
          {/* il y a {displayDuration(lastCall?.getTime())} */}

           

            <div className="">
                {/* <div onClick={() => {
                    articles.forEach(a => {
                    //    updateArticle(a, ct)
                       translate_article(a, lang, ct.workspace?.name)
                    })
                }}>Update all article</div> */}
               
                  {article && ct && <div onClick={() => console.log('article[`text_${lang}`]', article[`text_${lang}`])} className={`fixed ${darkMode ? "bg-purple-700" : "bg-white"}  top-0 left-0 right-0 z-[999] max-h-screen overflow-scroll bottom-0 `}>
                  <div className='fixed top-2 right-2 text-blue-900' onClick={() => setArticle()}>
                  <div class="absolute top-4 right-4 text-white z-40   bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>
                  </div>
                  
                    <div onClick={(e) => e.stopPropagation()} className='max-w-[700px] text-blue-500 p-4 pb-[80px] mx-auto'>
                      <img className='rounded-xl mx-auto mt-16 h-[240px] object-cover' src={article.img} />
                      <div className='flex text-white justify-end gap-2 mb-4 px-4 -mt-8 '>
                        <div className='bg-black/30 backdrop-blur-[20px] rounded-xl px-2 '>{article.src}</div>
                        <div className='bg-black/30 backdrop-blur-[20px] rounded-xl text-sm px-2  '>il y a {displayDuration(goodDate(article.dateTime), new Date())}</div>

                      </div>

                     <div></div>
                      {tuto.news_click != true && <div className='fixed bottom-0 p-4 right-0 left-0'>
                        <div className='px-4 py-4 flex gap-2 rounded-xl bg-blue-500 text-white'>
                        <img src={"/images/deco/hand.png"} className='h-[50px]'/> Clique sur un mot pour l'apprendre
                        </div>
                        </div>}
                      {/* <div>Apprends 3 autres mots pour créer une <u>graine de connaissance</u></div> */}
                   
                     
                      
                     { article[`title_${lang}`] && <div className='flex gap-2 flex-wrap'>
                        <div className={`mt-2 px-2 rounded-xl text-sm ${darkMode ? "bg-white/20" : "bg-blue-500/20"}`} onClick={() => setTradMode(!tradMode)}>{!tradMode ? "Afficher la traduction" : "Cacher la traduction"}</div>
                        <div className={`mt-2 px-2 rounded-xl text-sm ${darkMode ? "bg-white/20" : "bg-blue-500/20"}`} onClick={() => setColorText(!colorText)}>{!colorText ? "Colorer les mots appris" : "Affichage classique"}</div>
                     </div> }

                     {(loadingTranslation == article.id )&& <div className='text-center mt-8 px-4 rounded'>
                        <div className='italic text-black/50'>Cet article n'a pas encore été traduit en {ct.workspace?.name}.</div>
                        <div className='my-6 flex justify-center '><l-quantum
                            size="45"
                            speed="3.75" 
                            color="#6360FF" 
                            ></l-quantum></div>
                       <div className='text-indigo-500'> Traduction en cours ...</div>
                       
                        </div>}

                      <div className='font-semibold text-2xl mt-8 mb-2 text-slate-800'>{
                        <SuperText 
                        tradMode={tradMode} 
                        imgContext={article.img}
                        path={"news"}
                        colorText={colorText}
                        text={article[`title_${lang}`]?.replace(/"/g, '')} 
                        darkMode={darkMode}
                        trad={article[`title_fr`]?.replace(/"/g, '')} 
                        decks={ct?.decks || []} 
                        cards={ct.cards}  
                        ct={ct}/>
                     
                      }</div>
                    
                    {/* <div onClick={() => {
                        ct.setUser(u => ({...u, tuto: {}}))
                        updateDoc(doc(db, "users", ct.user?.id), {tuto: {...ct.user.tuto || {}, news_click: null}})
                        }}>reset tuto</div> */}


                   
                    <div className='text-slate-900'>
                      {article[`text_${lang}`] && <SuperText 
                        tradMode={tradMode} 
                        path={"news"}
                        colorText={colorText}
                        imgContext={article.img}
                        text={article[`text_${lang}`]} 
                        darkMode={darkMode}
                        trad={article[`text_fr`]} 
                        decks={ct?.decks || []} 
                        cards={ct.cards}  
                        ct={ct}/>}
                        </div>
                      
                
                      {/* <div
                        className='text-sm text-white/50'
                        dangerouslySetInnerHTML={{ __html: article.text_origin.replace(/\n/g, '<br>').replace(/\|/g, '')}}
                        ></div> */}

                        {/* <div
                        className='text-sm text-green-500/50'
                        dangerouslySetInnerHTML={{ __html: numberSentences(article.text_origin || "").replace(/\n/g, '<br>').replace(/\|/g, '')}}
                        ></div> */}

                      {/* <div
                        className='text-sm text-purple-500/50'
                        dangerouslySetInnerHTML={{ __html: article.text_fr.replace(/\n/g, '<br>').replace(/\|/g, '')}}
                        ></div> */}

                     

                      {/* <div onClick={() => addArticle(article, ct)}>Ajouter l'article</div>
                      <div onClick={() => updateArticle(article, ct)}>Update l'article</div>
                      <div onClick={() => translate_article(article, lang, ct.workspace?.name)}>Traduire en {lang}</div>
                         */}
                    

                      {/* {splitNumeredSentence(article.text_fr).map( (sentence,i) => {
                        const sentence_es = splitNumeredSentence(article.text_es)[i]
                        return <div className='mt-4'>
                            <div>{sentence_es}</div>
                            <div className='text-xs italic text-white/50'>{sentence}</div>
                        </div>
                      })} */}
           
                    </div>

                    </div>}
                  <div>
                  {/* <div onClick={() => fetchData()} className='px-2 rounded-md my-2 inline-block bg-white/20'>
                    Charger les articles 
                    </div> */}

                   {/* <div className='flex gap-2 my-2'> {["fra", "spa"].map(e => <div className={`${e == langFilter ? "bg-white/20" : "" } px-2 rounded-xl `} onClick={() => setLangFilter(e)}>{e}</div>)}
                    </div> */}
                   
                    {articles?.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)).map(a => {
                       
                        return <div onClick={async() => {
                            setArticle(a); 
                            console.log(a);
                            if(!a[`title_${lang}`]) {
                                console.log('il faut traduire l article !!')
                                setLoadingTranslation(a.id)
                                const data =  await translate_article(a, lang, ct.workspace?.name);
                                
                                setArticle(data)
                                setLoadingTranslation(false)
                            }
                            }} className='text-white p-4  rounded-xl border-2 border-b-4 border-black/30 bg-black/10 mt-2'>
                        <div className='flex gap-2'>
                        <img className='object-cover rounded-xl w-[76px] h-[76px]' src={a.img} />
                        <div>
                            <div className='max-h-[96px] overflow-hidden'>
                               
                            {/* <div>{splitNumeredSentence(a[`text_${lang}`]).length} - {splitNumeredSentence(a[`text_fr`]).length}</div> */}
                            <div className='text-sm font-semibold'>{a[`title_${lang}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')}</div>
                            <div className=' text-xs '>{a[`title_fr`]}</div>
                            </div>
                           
                            {/* {<div onClick={(e) => {e.stopPropagation();translate_article(a, lang, ct.workspace?.name);}} className='px-2 mt-2 py-1 rounded bg-white/20'>Traduire en {ct.workspace?.name}</div>} */}
                        </div>
                        </div>
                        <div className='flex flex-wrap items-center gap-2  mt-2'>
                            {/* <div className='bg-white/10 rounded-xl text-sm px-1  '>{splitNumeredSentence(a['text_fr']).length} phrases</div> */}

                                {a[`text_${lang}`] && <div className='bg-white/10 rounded-xl text-sm px-2  '>{readingTime(a[`text_${lang}`])}</div>}
                                <div className='bg-white/10 rounded-xl text-sm px-2  '>{a.src}</div>
                                <div className='bg-white/10 rounded-xl text-sm px-2  '>il y a {displayDuration(goodDate(a.dateTime), new Date())}</div>

                            </div>
                            {/* <div onClick={async(e) => {
                                e.stopPropagation()
                                   const docRef = doc(db, "articles", a.id);
                                   await deleteDoc(docRef);
                                   setActicles(a => a.filter(e => e.id != a.id))
                            }}>Supprimer</div> */}
                        
                        </div>
                        })}


                    {/* <div>---</div> */}
                    {/* {_articles?.filter(e => !articles.find(a => a.src_id == e.uri))?.map(a => {

                      return <div onClick={() => {setArticle(a); console.log(a)}} className='text-white p-4 flex gap-2 rounded border-2 border-white/20 bg-white/10 mt-2'>
                        <img className='object-cover rounded-xl w-[96px] h-[96px]' src={a.image} />
                        <div>
                          <div className='max-h-[96px] overflow-hidden'>
                            <div className='text-sm font-semibold'>{a.title}</div>
                          </div>
                          <div className='flex flex-wrap justify-end mt-2'>
                            <div className='bg-white/10 rounded-xl text-sm px-1  '>{a.source.title}</div>
                          </div>
                          <div onClick={(e) => {e.stopPropagation();addArticle(a, ct);}} className='px-2 py-1 rounded bg-white/20'>Ajouter l'article</div>
                        </div>
                       
                        </div>
                      })} */}
                  </div>

                    

            </div>
            
          </div>
      </div>

};

export {NewScreen};





