


const ProfilePage = () => {

    return <div>


        <div>Se deconnecter</div>
        <div>Rejoindre une classe</div>
    </div>
}

export {ProfilePage}