import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { sectionInfos } from '../quizzReader';
import { centToStars, getTotalFromTestId, quizList, quizList2 } from '../../../utilities/helper/helper';


const ModalTraining = ({setShowModalTraining, tests, userTests}) => {

    const [trainingView, setTrainingView] = useState()

   return   <div id="backdrop" className='flex fredoka text-white flex-col items-between justify-between' onClick={() => {setShowModalTraining(false)}} style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 50, 0, 0.5)", zIndex: 999}}>
  <div onClick={(e) => e.stopPropagation()} className="rounded-xl h-full overflow-scroll p-2 bg-gradient-to-b from-[#2b57b2] to-[#391556] border-4 border-b-[9px] border-black/90 ">
    <div className="mb-4 my-2 ">

      <div onClick={() => setShowModalTraining(false)} className="absolute top-2 right-2 z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700" >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <img src="images/UX/dumbel.png" className="h-[63px] mt-2 mx-auto" />
      <h2 className="text-white text-center mb-4 -mt-2">
        Préparation TOEIC
   
        <div className='text-white/80 text-sm'>{typeof trainingView === 'number' && "Entrainement partie "+trainingView}</div>
        </h2>

        <div className='flex gap-2 justify-center'>
          <div onClick={() => {setTrainingView(null)}} className={`grow text-center border-b ${trainingView != "white" ? "border-white" : "border-white/10"}`}>Entrainement</div>
          <div onClick={() => {setTrainingView("white")}} className={`grow text-center border-b ${trainingView == "white" ? "border-white" : "border-white/10"}`}>Examens</div>
        </div>
     
    </div>
    {trainingView == null ? <div className="">
     
     {sectionInfos?.map((s, i) => <div onClick={() => {setTrainingView(i+1)}} className="rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2">
      <span className="flex gap-1"><img src="images/UX/dumbel.png" className='h-10 w-10 -mb-2 -mt-2 -ml-2 rotate-[344deg]' />
        Partie {i + 1}</span> <span className=" bg-white/10 px-2 rounded-full text-base">{s.title}</span>
      </div>)}

      <div onClick={() => {setTrainingView("mini")}} className="rounded-[6px] border-2 border-b-4 mt-4 text-sm border-black/50 flex items-center gap-1 mt-1 px-3 py-2">
      <img src="images/UX/dumbel.png" className='h-10 w-10 -mb-2 -mt-2 -ml-2 rotate-[344deg]' />
        Complet <span className="ml-2 bg-white/10 px-2 rounded-full text-base">Mini TOEIC</span>
        </div>

      
  </div> : <div>
    <div className='flex'><div className="mb-2 rounded-md px-2 bg-white/20 border border-white/50" onClick={() => setTrainingView(null)}>← retour</div></div>
    {typeof trainingView === 'number' && <div>
      {quizList2.map(q => tests.find(t => t.id == q.id)).map((t,i) => {
            const userTest = userTests?.find(ut =>  ut.test_id == `training_part_${trainingView}_n_${i}`)
            const questions = userTest?.questions
            const total = getTotalFromTestId(userTest?.test_id)
            const cent = Math.round(questions?.filter(q => q.valid)?.length*100/total) || null
            return <div className={`rounded-[6px] ${userTest ? "bg-black/10" : "opacity-50"} flex justify-between gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`} onClick={() => {
             
              const questionToReview = questions?.filter(q => q.next_date && q.next_date < new Date())
             
              console.log('userTests!!', userTests)
   
      }}>
        <div>
          <div> Entrainement {i+1}</div>
          <div className='flex gap-2 mt-1'> <div className='text-xs bg-white/20 rounded-full px-2 py-[2px]'>{sectionInfos[trainingView-1||0].title}</div>
          <div className={`${userTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} {cent && <div>{cent}%</div>} </div>
          </div>
        </div>
      

      </div>})}
      </div>}

    {trainingView == "white" && <div>{quizList.map((t,i) => {
        const userTest = userTests?.find(ut =>  ut.test_id == `training_part_${trainingView}_n_${i}` || ut.test_id == t.id)
        
        const cent = Math.round(userTest?.toeic_score*100/995) || null
        return <div className={`${userTest ? "bg-black/10" : "opacity-50"} rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`}>
      
      <div>
        <div>TOEIC Blanc {i+1}</div>
        <div className='flex gap-1'>
        <div className={`${userTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} </div>
        <div className='text-white/50 bg-white/10 rounded-full px-1'><span className='text-amber-400'>{userTest?.toeic_score || "--"}</span>/995</div>
       
        </div>
      </div>
      </div>}
      )}</div>}
    {trainingView == "mini" && <div onClick={() => console.log('coucou')}>{tests.filter(t => t.mini).sort((a,b) => a.name - b.name).map(t => {
      const userTest = userTests?.find(ut =>  ut.test_id == t.id)
      const total = getTotalFromTestId(userTest?.test_id)
      const cent = Math.round(userTest?.questions?.filter(q => q.valid)?.length*100/total) || null

      return <div onClick={() => console.log('userTest', userTest)} className={`${userTest ? "" : "opacity-50"} rounded-[6px] flex gap-2 text-sm border-2 border-b-4 border-black/50 mt-1 px-3 py-2`}>
        <div className=''>
          {t.name.slice(3)}
          <div className={`${userTest ? "" : "text-white/80" } flex gap-1`}>{centToStars(cent/10)} {cent && <div>{cent}%</div>} </div>
          {/* <div className='flex mt-1'><div className='bg-white/20 rounded-full px-2 '>{questionsCorrect || "--"}/{total || '--'}  </div></div> */}
          </div>
        </div>})}</div>}
    </div>}
</div>
</div>}

export {
    ModalTraining
}