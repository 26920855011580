import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";

import { db } from "../../../config.js";
import { missions } from '../../../utilities/helper/helper.js';

const Quest = (props) => {
    const ct = props.ct
    const [mode, setMode] = useState("daily")
    const [reward, setReward] = useState()

    const dailyQuest = []
    const succes = [
        {name: "Apprend 50 mots", coin: 100, cents: ct.userCards.length/50*100}, 
        // {name: "Visite la forêt d'un ami", coin: 10, cents: 0}, 
        // {name: "Déplace 3 graines", coin: 10, cents: 0}, 
        // {name: "Regarde la vidéo tuto", coin: 40, cents: 0}, 
    ]

    const currentMission = missions[ct.userDaily?.mission_valided || 0]

    function randomInRange(rangeString) {
        // Découper la chaîne pour obtenir les bornes de l'intervalle
        if (!rangeString) return
        const [min, max] = rangeString?.split('-')?.map(Number);
    
        // Générer un nombre aléatoire dans cet intervalle
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    const validMission = () => {
        ct.fire.updateDaily({...ct.userDaily, mission_valided: ct.userDaily.mission_valided + 1 || 1}, ct)
        ct.setShowPop()
        ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: (ct.userWorkspace.coins || 0)+reward}, ct)
    }

    useEffect(() => {
        setReward(randomInRange(currentMission?.reward))
    },[])

    const chest = <div className=' text-white'>
    <div className='text-center flex flex-col justify-center items-center'>
        <img src={'/images/UX/treasure.png'} className="h-[90px] text-center mx-auto" />
        <div>Récompense obtenue</div>

        <div className='flex gap-2 font-semibold bg-black/30 rounded-xl mt-2 px-3 py-1'>{reward} <img className='h-5 w-5' src={'/images/UX/coins.svg'}/></div>
        <div onClick={() => validMission()} className='mt-4 bg-white text-blue-500 px-4 py-1 rounded-xl'>Valider la mission</div>
    </div>
</div>
 
  const cents = ((ct.userDaily?.xp||0)/currentMission?.xpNeeded)*100
  return (
    <div onClick={() => props.quit()} id="backdrop" className='fixed z-[20] top-0 left-0 flex flex-col justify-center  right-0 bottom-[60px] rounded p-4'>
        <div onClick={(e) => {e.stopPropagation()}} className='relative h-[70%]  text-white bg-gradient-to-b from-indigo-800  to-pink-900  my-4 fredoka font-bold rounded-xl border-2 border-blue-600 '>
            <img src={'/images/UX/treasure.png'} className="z-10 absolute -left-2 -top-8 h-20" />
            <div className={`text-center fredoka  bg-gradient-to-b from-purple-600  to-purple-700 bg-purple-500 text-2xl text-shadow p-4 rounded-t-xl fredoka`}>
                Quêtes
            </div>
            <div className='h-[4px] bg-purple-600'/>
            <div className='p-4 overflow-scroll'>
                <div className='flex gap-4'>
                    <h3 onClick={() => {setMode("daily")}} className={`text-shadow ${mode == "daily" ? "text-white/90" : "text-white/50"}`}>Quêtes du jour</h3>
                    <h3 onClick={() => {setMode("succes")}} className={`text-shadow ${mode == "succes" ? "text-white/90" : "text-white/50"}`}>Succès</h3>
                </div>
                
                {/* <div className='' onClick={() => {ct.fire.updateDaily({...ct.userDaily, dc: 0, xp: 20}, ct)}}>reset</div> */}
                {mode == "daily" && currentMission && 
                <div className='border-2  border-b-4 border-blue-900  bg-gradient-to-r from-indigo-400  to-purple-500 p-4 rounded-xl flex gap-2'>
                    <div className='grow text-shadow'>
                        <div>Gagner {currentMission?.xpNeeded} xp</div>
                        {cents < 100 ? <div className='bg-black/20 rounded-full h-[12px] mt-2 relative'>
                          
                            <div className='bg-yellow-400 absolute h-[12px] rounded-full' style={{width: `${cents > 100 ? 100 : cents}%`}}></div>
                            <div className='bg-yellow-100/50 absolute h-[4px] top-[2px] mx-2 rounded-full' style={{width: `calc(${cents > 100 ? 100 : cents}% - 12px)`}}></div>
                            <div className='text-shadow mx-auto absolute -top-[3px] text-sm left-[40%]'>{ct.userDaily?.xp || 0}/{currentMission?.xpNeeded}</div>
                        </div> : ct.userDaily?.dc > 0 ? <div className='italic text-green-100'>Mission terminée</div> : <div onClick={() => {ct.setShowInfo({title: 'Coffre rare', classContent: "bg-blue-500",  content: chest})}
                        } className='px-4 py-1 rounded-xl text-white text-shadow animate-bounce mt-1 inline-block -mb-1 bg-[#ffc503] border border-b-4 border-amber-700'>Récupérer la récompense</div>
                         }
                    </div>
                    <div className='bg-purple-900/80 rounded-md w-[60px] py-2 p-1 relative'>
                        <img src={'/images/UX/coins.svg'} className="mx-auto h-6 " />
                        <div className='text-shadow text-sm font-bold -mt-2 text-center'>{currentMission?.reward}</div>
                        {ct.userDaily?.dc > 0 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 bg-green-500 text-white rounded-full p-1 absolute -bottom-1 -right-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>}

                    </div>
                </div>}
                {mode == "succes" && <div>
                    {succes.map(s =>   <div className='border-2 mt-2 border-b-4 border-purple-900  bg-gradient-to-r from-indigo-400  to-purple-500 p-2 rounded-xl flex gap-2'>
                    <div className='grow text-shadow'>
                        <div>{s.name}</div>
                        <div className='bg-black/20 rounded-full h-[12px] -mb-1 mt-1 relative'>
                          
                            <div className='bg-yellow-400 absolute h-[12px] rounded-full' style={{width: `${s.cents > 100 ? 100 : s.cents}%`}}></div>
                            <div className='bg-yellow-100/50 absolute h-[4px] top-[2px] mx-2 rounded-full' style={{width: `calc(${s.cents > 100 ? 100 : s.cents}% - 12px)`}}></div>
                            {/* <div className='text-shadow mx-auto absolute -top-[3px] text-sm left-[40%]'>{ct.userDaily?.xp}/13</div> */}
                        </div>
                    </div>
                    <div className='bg-purple-900/80 rounded-md w-[50px] h-[45px] p-2'>
                        <img src={'/images/UX/coins.svg'} className="mx-auto h-6 " />
                        <div className='text-shadow font-bold -mt-2 text-center'>{s.coin}</div>
                    </div>
                </div>)}</div>}
             
            </div>
        </div>
    </div>
  );
};

export {Quest};