import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc, where } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate, shuffleArray, speak, stopSound } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { addImg, arabicLetters, click_sound2, comparerSansAccents, displayDuration, grammars, highlightLetterArabic, removePinyinAccents, validSound2 } from '../../utilities/helper/helper.js';
import { badSound, splitSentence, validSound } from '../components/learn/learnContainer.js';
import { getStorage } from 'firebase/storage';
import { ct } from '../../utilities/data.js';
import { LetterCard } from '../components/learn/letterCard.js';
import { updateUserXp } from '../components/learn/learn.js';


const normalize = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

const compositionEndEvent = new CompositionEvent('compositionend', {
    data: 'test',
  });

const Input = React.memo(({ props, good, setCorrect, setFalse, pass, handleAddXp, point, isSpeaking }) => {
    const [answer, setAnswer] = useState("");

    const handleKeyDown = (e) => {
        const key = e.key;
        if (key >= '1' && key <= '4') {
            insertToneAtCursor(key); // Applique la tonalité correspondante
            e.preventDefault(); // Empêche la saisie du chiffre dans l'input
        }
    };

    

    useEffect(() => {
        setAnswer("")
        const textarea = document.getElementById("pinyin-input");
        textarea.value= ""
        
    }, [good.print, isSpeaking])


    // Fonction pour convertir les chiffres de ton en accents (ex: "a1" -> "ā", etc.)
    const convertToTone = (input) => {
        const toneMap = {
            "a1": "ā", "a2": "á", "a3": "ǎ", "a4": "à",
            "e1": "ē", "e2": "é", "e3": "ě", "e4": "è",
            "i1": "ī", "i2": "í", "i3": "ǐ", "i4": "ì",
            "o1": "ō", "o2": "ó", "o3": "ǒ", "o4": "ò",
            "u1": "ū", "u2": "ú", "u3": "ǔ", "u4": "ù",
            "ü1": "ǖ", "ü2": "ǘ", "ü3": "ǚ", "ü4": "ǜ"  
        };
        return input.replace(/([aeiouv][1-4])/g, (match) => toneMap[match] || match);
    };

    // Fonction pour insérer une tonalité (1-4) et convertir en accent directement
    const insertToneAtCursor = (toneNumber) => {
        const textarea = document.getElementById("pinyin-input");
        const cursorPos = textarea.selectionStart;

        // Récupère la voyelle avant la position du curseur
        const textBeforeCursor = answer.substring(0, cursorPos);
        const textAfterCursor = answer.substring(cursorPos);
        
        // Vérifie le dernier caractère avant la position du curseur pour voir si c'est une voyelle valide
        const lastChar = textBeforeCursor[textBeforeCursor.length - 1];
        const toneInput = `${normalize(lastChar)}${toneNumber}`; // Combine voyelle + tonalité
        
        const converted = convertToTone(toneInput); // Applique la transformation
        
        if (converted !== toneInput) { // Si la conversion est réussie
            const newText = textBeforeCursor.slice(0, -1) + converted + textAfterCursor;
            setAnswer(newText); // Met à jour la réponse
        }
        textarea.focus()
    };


    

    useEffect(() => {
        console.log('good', good.print?.toLowerCase())
        const textarea = document.getElementById("pinyin-input");
        if (answer?.toLowerCase() == good.print?.toLowerCase()) {
           
            
            // textarea.blur();  // Force la perte de focus
            // setTimeout(() => {
            //     textarea.focus();  // Redonne le focus après un court délai
            // }, 0);

            textarea.value= ""
            textarea.dispatchEvent(compositionEndEvent);
            setAnswer()
            // textarea.blur();
           
            const inputEvent = new Event('input', { bubbles: true });
            textarea.dispatchEvent(inputEvent);

            handleAddXp(null, point, {element: textarea})
            setCorrect()
            return
        }

        if (answer?.length == good.print?.length && !comparerSansAccents(answer?.toLowerCase(), good.print?.toLowerCase())) {
           

              textarea.classList.add('wizz');

              badSound.play()
              setTimeout(() => {
                textarea.classList.remove('wizz');
              }, 300); // Durée de l'animation
          }
        


    }, [answer])

    return (
        <>
            <div className='flex gap-2 !font-mono  justify-between'>
                <div className="flex mt-8 items-center font-[100] flex-row gap-1 mb-2">
                    {["¯","´","ˇ","`"].map((e, i) =>  <button className='px-2 bg-black/10 text-[50px] rounded-xl border border-b-2 border-black/30 pt-2 w-[35px] h-[30px] relative' onClick={() => insertToneAtCursor(i+1)}><div className='absolute -top-[7px] left-[3px]'>{e}</div></button>)}
                    
                    {/* <div id="foc" onClick={() => {
                         const textarea = document.getElementById("pinyin-input");
                         textarea.focus()
                    }}>foc: {isSpeaking ? "oui" : "non"}</div> */}
                </div>
            </div>

            <div className=' flex justify-center  w-auto !font-mono tracking-widest  relative'>
                <div onClick={(e) => e.preventDefault()} className='!font-mono pointer-events-none	 text-white/40 mb-1  tracking-widest absolute bottom-0 left-0  text-2xl right-0'>{"_".repeat(good?.print?.length)}</div>
                <input
                id="pinyin-input"
                value={answer}
                onClick={(e) => {
                    console.log('e', e)
                    e.target.focus()
                }}
                key={good.print}
                autoFocus={true}
                autoComplete="off" 
                onKeyDown={handleKeyDown}
                autoCorrect="off"
                onChange={(e) => {setAnswer(e.target.value); }}
                style={{width: `calc(${good?.print?.length * 20}px + 20px)`}}
                className='bg-black/50 min-w-[150px] tracking-widest text-amber-200  !outline-none text-2xl border-2 border-b-4 rounded-xl border-black/90 text-center  h-[50px] px-[10px] mx-auto'
                placeholder={""}
            /></div>
            <div className='text-white/70 mt-4 text-sm' onClick={() => {pass()}}>Passer </div>
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.good.print === nextProps.good.print &&  prevProps.isSpeaking === nextProps.isSpeaking 

});


function mergeSpacesWithPrevious(arr) {
    const result = [];
  
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === " " && result.length > 0) {
        // Ajouter l'espace à l'élément précédent
        result[result.length - 1] += " ";
      } else {
        // Ajouter l'élément actuel à la liste des résultats
        result.push(arr[i]);
      }
    }
  
    return result;
  }


  function splitPhrase(phrase) {
    // Diviser la phrase en mots tout en conservant les espaces
    const words = phrase.split(' ');
    const result = [];
    const maxElements = 5; // Nombre maximum d'éléments
    let totalElements = 0;
  
    // Compter le nombre d'espaces (qui seront des éléments dans le résultat)
    const spaceElements = words.length - 1;
  
    // Calculer la longueur totale des mots
    const totalWordLength = words.reduce((sum, word) => sum + word.length, 0);
  
    // Initialiser le nombre de segments pour chaque mot
    let segmentsPerWord = words.map((word) => {
      if (word.length <= 3) {
        return 1; // Ne pas diviser les mots courts
      } else if (word.length <= 6) {
        return 2; // Diviser en 2 parties pour les mots de longueur moyenne
      } else {
        return 3; // Diviser en 3 parties pour les mots longs
      }
    });
  
    // Calculer le nombre total d'éléments initial (segments + espaces)
    totalElements = segmentsPerWord.reduce((sum, segments) => sum + segments, 0) + spaceElements;
  
    // Ajuster les segments pour ne pas dépasser le nombre maximum d'éléments
    while (totalElements > maxElements) {
      // Trouver l'index du mot avec le plus grand nombre de segments
      let maxSegments = Math.max(...segmentsPerWord);
      let index = segmentsPerWord.findIndex((segments) => segments === maxSegments && segments > 1);
  
      if (index === -1) {
        break; // Impossible de réduire davantage
      }
  
      segmentsPerWord[index] -= 1;
      totalElements -= 1;
    }
  
    // Diviser les mots en segments en fonction de segmentsPerWord
    words.forEach((word, wordIndex) => {
      const numSegments = segmentsPerWord[wordIndex];
      const wordLength = word.length;
  
      
      if (numSegments === 1) {
        result.push(word);
      } else {
        const partSize = Math.ceil(wordLength / numSegments);
        const splits = [];
  
        let index = 0;
        for (let i = 0; i < numSegments - 1; i++) {
          let part = word.slice(index, index + partSize);
          splits.push(part);
          index += partSize;
        }
        // Ajouter le reste du mot au dernier segment
        splits.push(word.slice(index));
  
        // Fusionner les segments si un segment a moins de 2 lettres
        for (let i = 0; i < splits.length; i++) {
          if (splits[i].length < 2 && i > 0) {
            splits[i - 1] += splits[i];
            splits.splice(i, 1);
            i--;
          }
        }
  
        result.push(...splits);
      }

      

  
      // Ajouter un espace après chaque mot sauf le dernier
      if (wordIndex < words.length - 1) {
        result.push(' ');
      }
    });
  
    return result;
  }
  
  
  

  
  
  
  
  // Exemple d'utilisation :
  const phrases = [
    "やすみたい",
    "هل تتكلم العربية؟",
    "어디에 있어?",
    "Как тебя зовут?"
  ];
  phrases.forEach((phrase) => {
    // console.log(`Phrase originale: "${phrase}"`);
    // console.log("Résultat du split:", splitPhrase(phrase));
    // console.log('---');
  });
  
 
  

const Split = React.memo(({ props, good, workspace_id, setCorrect, setFalse, pass, handleAddXp, point, isSpeaking }) => {
    const [answer, setAnswer] = useState("");


    const [randomized, setRandomized] = useState([])

  

    phrases.forEach((phrase) => {
        console.log(`Phrase originale: "${phrase}"`);
        console.log("Résultat du split:", splitPhrase(phrase));
        console.log('---');
      });

    


    // Fonction pour convertir les chiffres de ton en accents (ex: "a1" -> "ā", etc.)
   

    // Fonction pour insérer une tonalité (1-4) et convertir en accent directement

    const correct =  good?.print
    const [selectedIndex, setSelectedIndex] = useState([])


    const handleClick = (i) => {
        setSelectedIndex((prev) =>
            prev.includes(i)
                ? prev.filter((index) => index !== i) // Retire l'index si déjà présent
                : [...prev, i] // Ajoute l'index s'il n'est pas présent
        );
    };


    // on prend les props, on les split aussi puis on filtrer les deux premières qui sont pas dans notre answerArray

    
  
    const answerArray = selectedIndex.map(e => randomized[e]).join("")

  
    useEffect(() => {
        const splitted = mergeSpacesWithPrevious(splitPhrase(correct.trim()))
        const falseArray = props.map(e => mergeSpacesWithPrevious(splitPhrase(e.print))).flat().filter(e => e.length != 1).filter(e => !splitted.includes(e)).slice(0, 2)
        console.log('splitted',splitted)
        
        setRandomized(shuffleArray(splitted.concat(falseArray)))
        setSelectedIndex([])



       
      }, [good.print])

     


    useEffect(() => {
        const splitted = mergeSpacesWithPrevious(splitPhrase(correct.trim()))
        const answer_field = document.querySelector('#answer_field')
        console.log('answerArray', answerArray)
        console.log('correct', correct)
        if (answerArray?.toLocaleLowerCase().trim() == correct?.toLocaleLowerCase().trim()) {


            handleAddXp(null, point, {element: answer_field})
            setCorrect();
        } else if(splitted.length == selectedIndex.length) {
            answer_field.classList.add('wizz-text');

            badSound.play()
            setTimeout(() => {
                answer_field.classList.remove('wizz-text');
            }, 300); // Durée de l'animation
        }

    }, [answerArray])
    

    return (
        <>
        


            {/* <div>{good.print}</div> */}
            <div id="answer_field" className='flex mb-6  text-shadow justify-center items-center min-h-[40px] text-center border-b-2 border-white/40 text-2xl px-4 text-center text-shadow gap-2 relative w-full flex-wrap !font-mono  '>{answerArray}
            <div onClick={() => setSelectedIndex(e => {
                const newArray = [...e]; // Créer une copie du tableau
                newArray.pop(); // Supprimer le dernier élément
                return newArray; // Mettre à jour l'état avec la nouvelle copie
                })}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${answerArray.length ? "opacity-[0.7]" : "opacity-0"} transition-all  absolute top-2 right-0 h-6`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
                    </svg>

                    </div>
                    </div>

            <div className={`flex gap-2 text-${workspace_id} text-shadow text-lg flex-wrap !font-mono justify-center`}>
                {randomized?.map((e, i) => (
                    <div
                        key={i}
                        onClick={() => handleClick(i)}
                        className={`${
                            selectedIndex.includes(i)
                                ? 'opacity-[0.4] border-black/70'
                                : 'border-black/90'
                        } px-4 rounded-xl py-1 border-2 border-b-4  game-btn`}
                    >
                        {e}
                    </div>
                ))}
                
            </div>

            <div onClick={() => {pass()}} className='mt-8 text-white/50 text-center'>Passer</div>

        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.good.print === nextProps.good.print &&  prevProps.isSpeaking === nextProps.isSpeaking 

});





const QCM = React.memo(({ props, good, setCorrect, setFalse, pass,  handleAddXp, point, workspace_id }) => {

    const [randomizedArray, setRandomizedArray] = useState();

    useEffect(() => {
        const _randomizedArray = shuffleArray([...props, good]);
        setRandomizedArray(_randomizedArray);
    }, [props, good]); // Ajoute props et good comme dépendances

    // console.log('good', good);
    // console.log('props', props);
    console.log('focus props', props?.map(p => p.print));
    console.log('focus randomizedArray 2', randomizedArray?.map(p => p.print));
    // trouver d'autre son
    // ya 

    return (
        <div >
            <div className='grid grid-cols-2 gap-2'>
                {randomizedArray?.map((e,i) => (
                    <div
                        key={`${i}_${e?.print}_${e?.good}`}
                        onClick={(a) => {
                            const just = (e?.print === good.print);
                            if (just) {
                                handleAddXp(a, point)
                                setCorrect();
                              
                            } else {
                                handleAddXp(a, -point)
                                setFalse();
                               
                            }
                        }}
                        className={`${(e?.print === good.print) && ''} bg-[#5a37af] text-${workspace_id} font-sans  text-xl backdrop-blur game-btn p-4 rounded-xl border-2 border-b-4 border-black/50`}
                    >
                        {e?.print} 
                    </div>
                ))}
            </div>
            <div onClick={() => {pass()}} className='mt-4 text-white/50 text-center'>Passer</div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.good.print === nextProps.good.print; // Le composant se re-render uniquement si `good` change
});

export { QCM, Input, Split };

