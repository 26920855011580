import { useState, useEffect } from "react";

import {displayDuration} from '../../utilities/helper/helper.js'
import { addDoc, doc, writeBatch, query, collection,limit, getDocs, where, updateDoc,getDoc, deleteDoc } from "@firebase/firestore";

import { db } from "../../config.js";

import { ImageUpload } from "../components/imageUpload.js";
import {displayDate} from '../../utilities/helper/helper.js'
import { formatDate } from "../components/learn/learn.js";

const detectDuplicateEmails = async (set) => {
  try {
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(usersRef);

    // Créer un objet pour stocker la fréquence d'apparition des emails
    const emailCounts = {};
    const duplicateUsers = [];

    // Parcourir tous les documents utilisateur
    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const email = userData.email;

      if (email) { 
        // Incrémenter le compteur d'occurrences pour chaque email
        if (emailCounts[email]) {
          emailCounts[email].count++;
          emailCounts[email].users.push({ id: doc.id, ...userData, date: userData.last_connexion?.toDate() });
        } else {
          emailCounts[email] = { count: 1, users: [{ id: doc.id, ...userData, date: userData.last_connexion?.toDate() }] };
        }
      }
    });

    // Trouver tous les utilisateurs ayant un email dupliqué
    for (const email in emailCounts) {
      if (emailCounts[email].count > 1) {
        duplicateUsers.push({ email, users: emailCounts[email].users });
      }
    }

    // Afficher les utilisateurs ayant des emails dupliqués
    if (duplicateUsers.length > 0) {
      console.log("Utilisateurs avec des emails dupliqués :", duplicateUsers);
    } else {
      console.log("Aucun email dupliqué trouvé.");
    }

    set(duplicateUsers)

    return duplicateUsers;
  } catch (error) {
    console.error("Erreur lors de la détection des emails dupliqués :", error);
  }
};

const AdminSchool = (props) => {

  const ct = props.ct


  const modes = [
    {
      id: "classes", 
      title: "Groupes",
      description: "Liste de tous les groupes"
  }, 
    {
      id: "dash", 
      title: "Dashboard",
      description: ""  
  },
    {
        id: "user", 
        title: "Utilisateurs",
        description: "Liste de tous les utilisateurs"
    }, 
    {
        id: "uwp", 
        title: "Utilisateurs par cours",
        description: "Liste tous les utilisateurs de Viva Mundi pour le cours selectionné"  
    }
]

const [mode, setMode] = useState(modes[0])
const [workspaces, setWorkspaces] = useState()
const [workspace, setWorkspace] = useState()
const [classes, setClasses] = useState()
const [infoDailies, setInfoDailies] = useState()
const [schools, setSchools] = useState()


const addClasses = async () => {
  try {
  const className = document.querySelector('#class_name')
  const classCode = document.querySelector('#class_code')
  const classSchool = document.querySelector('#class_school')
  const newClass = {name: className.value, code: classCode.value, school_id: classSchool.value, admin: [{user_id: ct.user.id, role: "admin"}]}
  console.log('new class', newClass)
  const docRef = await addDoc(collection(db, "class"), newClass);
  setClasses([...classes, {...newClass, id: docRef.id}])
  } catch {}
}

const updateClasses = async () => {

}

function sumAllXP(weekTable) {
  let totalXP = 0;
  
  for (let week in weekTable) {
      if (weekTable[week].hasOwnProperty('xp')) {
          totalXP += weekTable[week].xp;
      }
  }
  
  return totalXP;
}

function sumDays() {

}

const getDailies = async (uwp_id) => {
  console.log('uwp_id', uwp_id)
  const _dailies =  await ct.fire.getDailies(uwp_id)
  console.log('_dailies', _dailies)
  setInfoDailies({uwp_id: uwp_id, dailies: _dailies})
}
const today = new Date();
const yesterday = new Date(today);

const todayJM = formatDate(today)
yesterday.setDate(today.getDate() - 1);
const yesterdayJM = formatDate(yesterday)
const [users, setUsers] = useState([])
const [userWorkspaces, setUserWorkspaces] = useState()

const renderUser = (user) => <div onClick={() => console.log('user', user)} className={` ${userWorkspaces.filter(uwp => uwp.user_id == user.id).length && !userWorkspaces.filter(uwp => uwp.user_id == user.id && uwp.workspace_id == user.last_workspace_id ).length ? "bg-red-500": "bg-red-0" } p-2  mb-2 border border-amber-300/20 rounded-xl `}>
  <div className="flex gap-2 items-center mb-2">
    <div>{user.name} {user.streak && (user.lds == yesterdayJM || user.lds == todayJM) ? <span className={`${user.lds != todayJM && "opacity-50"} text-amber-500 bg-amber-100 px-2 rounded-full text-sm ml-2`}>{user.streak} 🔥</span> : ""}
</div>
    <div className="text-slate-500 text-sm grow ">{user.email}</div>
   
    <div>{user.last_workspace_id}</div>
   

    <div className="text-slate-400 text-sm text-right">Dernière connexion il y a {displayDuration(new Date(user.last_connexion?.seconds*1000), new Date())}</div>
    <div className="text-amber-600 font-bold px-3 bg-amber-100 rounded-full">{sumAllXP(user.week_table)} xp</div>
  </div>
  <div>
    <div className="text-slate-500 text-[6px] grow -mt-2">{user.id}</div>
    {/* <div className="text-slate-500 text-[6px] grow ">uid:  {user.uid}</div> */}
    </div>
  <div className="flex my-1"><div className="text-slate-500 border px-1 rounded text-sm ">{user.class && classes.find(c => c.id == user.class[0])?.name}</div></div>
  
  {/* <div>{user.last_workspace_id}</div> */}
  <div>
    <div className="gap-2 flex flex-wrap ">{userWorkspaces?.filter(uwp => uwp.user_id == user.id).map(uwp => <div onClick={() => {console.log('uwp', uwp)}}  className={` ${user.last_workspace_id == uwp.workspace_id ? "bg-blue-500 text-white" : "bg-white/40"} px-2 py-[4px] items-center  mb-1 flex gap-2 rounded border`}>
      <img src={workspaces?.find(w => w.id == uwp.workspace_id).img} className="h-[20px] rounded-full"/>
      <div className="capitalize">{workspaces?.find(w => w.id == uwp.workspace_id).name} <div className="text-[8px]">{uwp.goal_id}</div></div>
      <div className="text-sm text-slate-500">{displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpend*1000) ))}</div>
      </div>)}</div>
  </div>

  <div className="text-red-500 text-right" onClick={() => {deleteUser(user)}}>Supprimer</div>
  </div>

const renderUserWorkspace = (uwp) => {
  const user = users.find(u => u.id == uwp.user_id)
  if (!user) return
return <div  className="p-2 mb-2 border-t group " onClick={() => getDailies(uwp?.id)}>
<div className="flex gap-2 justify-between items-center" >

  <div className="flex gap-2" >
    <img src={workspaces?.find(w => w.id == uwp.workspace_id).img} className="h-[20px] mt-1 rounded-full"/>
    <div>
      <div>{user.name} {uwp.streak && uwp.lastStreakDate.toDate() > new Date(Date.now() - 24 * 60 * 60 * 1000) && <span className="text-amber-500">{uwp.streak} 🔥</span>}</div>
      <div className="text-slate-500 text-sm grow ">{user.email}</div>
      <div className="text-sm px-2 text-slate-500 rounded-xl inline-block bg-slate-200">{uwp.classes && classes.find(c => c.id == uwp.classes[0]).name}</div>
    </div>
   
  </div>
  <div className="text-right">
    <div className="text-slate-400 group-hover:block hidden text-sm text-right">le {displayDate(uwp.updatedDate?.toDate())}</div>

    <div className="text-slate-400 group-hover:hidden text-sm text-right">Dernière connexion il y a {displayDuration(uwp.updatedDate?.toDate(), new Date())}</div>
    <div className="text-sm text-slate-500">Temps passé : 
    {displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpend*1000) ))} 
     <span className="ml-1 text-green-500">{displayDuration( new Date(), new Date(Date.now() + (uwp.timeSpendForest*1000) ))}</span>
    </div>


  </div>
  
</div>
{uwp.id == infoDailies?.uwp_id && <div onClick={() => console.log('uwp', uwp)} className="w-full bg-slate-100 p-4 mt-2 rounded border">
      {infoDailies?.dailies?.length && infoDailies?.dailies?.map(d => <div className="flex gap-2 justify-between">
        <div className="flex gap-3 mb-1">
          <div className="w-[36px] text-amber-500">{d.new}</div>
          <div className="w-[36px] text-pink-500">{d.errors}</div>
          <div className="w-[36px] text-purple-500">{d.up}</div>
          <div className="w-[36px] text-amber-700">{d.xp ? d.xp + "xp" : ""}</div>
          <div className="w-[36px] text-slate-700">{d.xp ? d.xp + "xp" : ""}</div>
          <div className="w-[100px] text-sm px-2 text-slate-500 rounded-xl inline-block bg-slate-200">{displayDuration( new Date(), new Date(Date.now() + (d.ts*1000)))}</div>
        </div>
        <div className="flex gap-3">
          <div className="w-[36px] text-sky-500">{d.id}</div>
          <div className="w-[36px] text-sky-500">{d.mission_valided}</div>
          <div className="w-[100px] text-right">{displayDate(d.date?.toDate()).slice(0,6)}</div>
        </div>
      </div>)}
      </div>}

{/* <div>{user.last_workspace_id}</div> */}
{/* <div >
  <div className="px-2 py-[4px] items-center bg-white/40 mb-1 flex gap-2 rounded border">
  
    <div className="capitalize">{workspaces?.find(w => w.id == uwp.workspace_id).name}</div>
    </div>
</div> */}

{/* <div className="text-red-500 text-right" onClick={() => {deleteUser(uwp)}}>Supprimer</div> */}
</div>
}

function getUsersByUpdatedDate(users, startDate, endDate) {
  return users?.filter(user => {
    if (user.updated && user.updated.seconds) {
      const updatedDate = new Date(user.updated.seconds * 1000 + user.updated.nanoseconds / 1000000);
     
      return updatedDate >= startDate && updatedDate <= endDate;
    }
    return false;
  });
}




console.log('db', db)
const fetchSchools = async () =>{
  try {
    const q = query(collection(db, 'schools'))
    const querySnapshot = await getDocs(q);
    const _objs = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('getUserWorkspaceElements _objs', _objs)

    const schoolsRef = collection(db, "schools");
  // const q = query(schoolsRef, limit(1000));
  // const querySnapshot = await getDocs(q);
  // return querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

  }
  catch (e) {

    console.log('errrey 1', e)
  }
  
} 
  
const fetchClasses = async () =>{
  try {
    const q = query(collection(db, 'class'))
    const querySnapshot = await getDocs(q);
    const _objs = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
    console.log('classes', _objs)
   return await getDocs(query(collection(db, "class"))).docs?.map(doc => ({...doc.data(), id: doc.id})); 
  }
  catch (e) {
    console.log('errrey fetchClasses', e)
    console.log('db', db)
  }
  
} 
  
const fetchUser = async () =>{
  try {
    const docs1 = await getDocs(query(collection(db, "users"), limit(5000))).docs?.map(doc => ({...doc.data(), id: doc.id}))
    const docs = await getDocs(query(collection(db, "users"), limit(5000))).docs?.map(doc => ({...doc.data(), id: doc.id}))
    return docs ; 
  }
  catch (e) {
    console.log('errrey 3', e)
  }
  
} 
  
const fetchUserWorkspace = async () =>{
  try {
return await getDocs(query(collection(db, "user_workspace"), limit(5000))).docs.map(doc => ({...doc.data(), id: doc.id})); 
  }
  catch (e) {
    console.log('errrey 4', e)
  }
  
} 
  



// const fetchSchools = async () => {}
// const fetchClasses = async () => {}
// const fetchUser = async () => {}
// const fetchUserWorkspace = async () => {}


const deleteUser = async(user) => {
  try {


  console.log('deleteUser 🟢', user)
  console.log('user id', user.id)
  const batch = writeBatch(db);
  // deletes user_cards where user_card.user_id == user.id
  const q = query( collection(db, "user_cards"), where("user_id", "==", user.id))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    console.log('delete user_card')
    deleteDoc(doc.ref);
  });

  const qDeck = query( collection(db, "user_deck"), where("user_id", "==", user.id))
  const querySnapshotDeck = await getDocs(qDeck)
  querySnapshotDeck.forEach((doc) => {
    console.log('delete user_deck')
    deleteDoc(doc.ref);
  });


  const uwps = await getDocs(query(collection(db, "user_workspace"), where("user_id", "==", user.id))).docs?.map(doc => ({...doc.data(), id: doc.id}))

  const q1 = query(collection(db, 'user_workspace'), where('user_id', "==", user.id))
  const querySnapshot1 = await getDocs(q1);
  const _userWorkspaces = querySnapshot1.docs.map(doc => ({...doc.data(), id: doc.id}))
  console.log('_userWorkspaces', _userWorkspaces)

  console.log('uwps', uwps)
  _userWorkspaces?.forEach(uwp => {
    console.log('delete user wp', uwp)
    batch.delete(doc(db, "user_workspace", uwp.id));
  })

   batch.delete(doc(db, "users", user.id));
   batch.commit();

  } catch (e) {

  }
}

useEffect(() => {
    if (workspace && db) {
        fetchUser().then(data => setUsers(data));
        fetchUserWorkspace().then(data => setUserWorkspaces(data));

    }  
}, [workspace, db]);


  useEffect(() => {
      async function fetchWorkspaces() {
        try {
        const retrievedWorkspaces = await ct.fire.getWorkspaces();
        setWorkspaces(retrievedWorkspaces);
        setWorkspace(retrievedWorkspaces[0]);
        } catch {}
      }
  
      fetchWorkspaces();
    }, []);


  useEffect(() => {
    console.clear()
    ct.setActiveScreen("admin")
    console.log("AdminSchool loaded")
    fetchSchools().then(data => setSchools(data))
    fetchClasses().then(data => setClasses(data));
}, [])

const [duplicateUser, setDuplicateUser] = useState()

const blacklist = [
  "366loganphoto@gmail.com", 
  "a-.gayet@hotmail.fr", 
  "abret.56@gmail.com", 
  "adamsebti05@gmail.com", 
  "adrien.telitchko@etu.emse.fr", 
  "ali.hamie@etu.emse.fr", 
  "alixpetitjean26@gmail.com", 
  "allanramanakasina362@gmail.com", 
  "ambre.gaudriot@gmail.com", 
  "anais.charroin@gmail.com", 
  "anais.dubois5@outlook.fr", 
  "anashennouz2001@gmail.com", 
  "anissa.elkmr@gmail.com", 
  "anna.vayssiere@gmail.com", 
  "anthos13fr@gmail.com", 
  "antonjeru007@gmail.com", 
  "arnault.conte@gmail.com", 
  "aro.andrianavah@gmail.com", 
  "arthur.grossaulle@outlook.fr", 
  "atynafassi2@gmail.com", 
  "aurelien.lavayssiere@gmail.com", 
  "aurelienjeanneau@gmail.com", 
  "aymeric.eyer@gmail.com", 
  "aymeric69690@gmail.com", 
  "barbierthibaut39100@gmail.com", 
  "bastiengarnier17@gmail.com", 
  "bastiengibert94@gmail.com", 
  "brunao.camara@gmail.com", 
  "cameron18334@gmail.com", 
  "chenhaosunshine@gmail.com", 
  "chloe.dunaud@gmail.com", 
  "clairevanruy@gmail.com", 
  "clara.taix01@gmail.com", 
  "clement.godard@hotmail.fr", 
  "clement.goron@etu.emse.fr", 
  "clement.leszczak1@gmail.com", 
  "cortobukala@gmail.com", 
  "d.chereault@gmail.com", 
  "daskalavikki@gmail.com", 
  "deviennepro@gmail.com", 
  "dietsch.arnaud@gmail.com", 
  "e.alyaume@gmail.com", 
  "elias.ibrahim972@gmail.com", 
  "elorasalmi@gmail.com", 
  "eric.ta2404@gmail.com", 
  "etienne.prin@gmail.com", 
  "eve.tugaut@gmail.com", 
  "ezzaibouh.moad@gmail.com", 
  "f.fournier24@gmail.com", 
  "faiz.mohammadmahmood@etu.emse.fr", 
  "fatima.sghyar@etu.emse.fr", 
  "fatoumata01balde210@gmail.com", 
  "faustine.ciminato@outlook.fr", 
  "felixrault@gmail.com", 
  "flavie.carroncp@gmail.com", 
  "g.gourdin12@gmail.com", 
  "gbedossoujunior@gmail.com", 
  "greglegris@gmail.com", 
  "haddadfatimaezzahra62@gmail.com", 
  "hicham56a@gmail.com", 
  "iliesghiar@gmail.com", 
  "ilyasszrira@gmail.com", 
  "imy.mech@gmail.com", 
  "isabelle280166@gmail.com", 
  "isabuisson1@free.fr", 
  "iziquel.alan62@gmail.com", 
  "jade.melliti@hotmail.fr", 
  "jolylaura72@gmail.com", 
  "juan-1020@hotmail.es", 
  "julien93700js@gmail.com", 
  "kanduy99@gmail.com", 
  "kevinguilloux.kg@gmail.com", 
  "kiminopanda132@gmail.com", 
  "l.bimane@orange.fr", 
  "lentz.matheo@gmail.com", 
  "leo.habouzit@yahoo.com", 
  "lili212.morel@gmail.com", 
  "livia.belo@ieee.org", 
  "louannlec@gmail.com", 
  "m.cadici@orange.fr", 
  "ma.peigney@hotmail.com", 
  "mariannesables@gmail.com", 
  "marionrouvillois@gmail.com", 
  "maxime.pellet69@gmail.com", 
  "melinette.zaber@gmail.com", 
  "merabet.kpro@gmail.com", 
  "minh-hoang.huynh@outlook.fr", 
  "mottez.julien@gmail.com", 
  "nayel.benabdesadok@etu.emse.fr", 
  "noesehab@gmail.com", 
  "octave.dup@gmail.com", 
  "pablito.colin003@gmail.com", 
  "pacphile@gmail.com", 
  "pau.beranger@gmail.com", 
  "pepette.sushi12@gmail.com", 
  "pololuc01@gmail.com", 
  "poncetmartin2003@gmail.com", 
  "rampon.tim129@gmail.com", 
  "rbenoit42@gmail.com", 
  "romane.a.99@hotmail.fr", 
  "ryan.lehmann@etu.emse.fr", 
  "sacha8205@gmail.com", 
  "sachafutelot@gmail.com", 
  "sailor.bouvet@gmail.com", 
  "salloum.anne@gmail.com", 
  "sarah71160st@gmail.com", 
  "sarah_dridi@outlook.fr", 
  "sayfjouini89@gmail.com", 
  "smaxime420@gmail.com", 
  "souad9898@gmail.com", 
  "test3232@gmail.com", 
  "test324123@gmail.com", 
  "test3242@gmail.com", 
  "test88@gmail.com", 
  "test9234@gmail.com", 
  "test964@gmail.com", 
  "theo.gachet.pro@outlook.fr", 
  "thomas.daillyol@gmail.com", 
  "thomas.techer@etu.emse.fr", 
  "tigroulucas@gmail.com", 
  "tomcarrepro@gmail.com", 
  "totohostettler@gmail.com", 
  "touillier.j@gmail.com", 
  "tristan.panhelleux@gmail.com", 
  "tristan007.lefebvre@gmail.com", 
  "typhanie.cortay@gmail.com", 
  "v.goussault@gmail.com", 
  "valouclea25@gmail.com", 
  "charlesdevienne@gmail.com",
  "vboufaroua@gmail.com", 
  "vinirocca8@gmail.com", 
  "virgile.bouchon@gmail.com", 
  "yahyaouaryicf@gmail.com", 
  "melanie.muller1051@gmail.com", 
  "severine.salvy@gmail.com", 
  "lmatsimouna.afpam@gmail.com", 
  "antonin08260@gmail.com", 
  "maxbrehon@wanadoo.fr", 
  "victoriamas1702@icloud.com", 
  "maxime.francois.m@gmail.com", 
  "fannymunier4@gmail.com", 
  "cameron18334@gmail.com", 
  "clara.lage1401@gmail.com", 
  "hartokaxaos@gmail.com", 
  "liguoricamille@gmail.com", 
  "louis.brauer@itech.fr", 
  "mateo.peillon@gmail.com", 
  "totohostettler@gmail.com", 
  "villard.lou.ann@gmail.com", 
  "florence.montagnon@hotmail.fr", 
  "gueno.decl@gmail.com", 
  "alice.lebreton018@gmail.com", 
  "lyonshahane@gmail.com", 
  "zanini.marine@gmail.com"]

  
  return (
    <div className={`fredoka h-screen overflow-scroll`}>
    <header className='p-4 fixed right-0 left-0 bg-white flex gap-2 border-b z-10 justify-between text-slate-400 items-center'>

        <div className="text-xl text-slate-500">Administration des cours</div>
        <div>{ct.user?.name}</div>
        {/* <div className="flex gap-2 items-center">
            <div className="flex gap-2  px-4 rounded ">
                <img src="images/icons/icon_rounded.png" className="h-[42px]"/> 
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <div className="flex gap-2  px-4 rounded ">
                <img src="https://d9hhrg4mnvzow.cloudfront.net/info.vivamundi.fr/20942887-viva-mundi-logo-rvb-1.svg" className="-mb-[16px] h-[50px]"/> 

            </div>
        </div>    */}
    </header>
    <aside className="fixed mt-[70px] overflow-scroll pb-[300px] left-0 text-slate-500 bg-amber-100/10 w-[200px] border-r p-4 h-screen">
        {!workspace && workspaces?.filter(w => w.img).map(wp => 
            <div onClick={() => setWorkspace(wp)} className={"capitalize p-2 rounded-md flex items-center gap-2"}><img src={wp.img} className="h-[24px] rounded-full" />{wp.name}</div>)}
        {workspace && <div className="capitalize flex gap-2 justify-between items-center text-md cursor-pointer" onClick={async() =>{
            setWorkspace()
        }}>
         <div className="flex items-center gap-2"><img src={workspace?.img} className="h-[30px] rounded-full" />{workspace?.name} </div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
             </svg>
        </div>}
        <hr className="my-8"/>
        {modes.map(m => <div onClick={() => {setMode(m)}} className={`${mode.title == m.title ? "bg-amber-500/10 text-amber-500" : ""} p-2 rounded-md hover:bg-amber-400/10`}>{m.title}</div>)}
     
    </aside>
    <main className="ml-[200px] pb-[200px] mt-[90px] p-8">
      <div onClick={() => {
        // console.log('users', users)
        // console.log('userWorkspaces', userWorkspaces)
        // userWorkspaces.filter(uwp => !uwp.classes).forEach((uwp) => {
        //   const user = users.find(u => u.id == uwp.user_id)
        //   if (user?.class) {
        //    // console.log("user", user.class[0])   
        //     ct.fire.updateDb('user_workspace', {...uwp, classes: user.class})
        //   }
         
        // })
        // Fais un fonction qui elève tout les hints des cartes



      }} className="">TEST: {userWorkspaces?.filter(uwp => !uwp.classes)?.length}</div>
        <div className="mb-8">
            <h1>{mode.title}</h1>
            <h2>{mode.description}</h2>
        </div>

        {mode.id == "dash" &&<div> 
          <div className="flex flex-wrap gap-2">
            Nombre d'utilisateur : {users?.length} <br/>
            Nombre uwp: {userWorkspaces?.length}
          </div>
          <div onClick={() => detectDuplicateEmails(setDuplicateUser)}>detectDuplicateEmails</div>
          <div>{duplicateUser?.map(e => <div>
            <div className="mt-4">{e.email}</div>
            {e.users.map(u => <div className="flex gap-2 " onClick={() => console.log('e', u)}>{u.email} <span onClick={() => {deleteUser(u)}}>supprimer</span></div>)}
          </div>)}</div>
          <h2 className="mb-2 mt-8">Mes écoles </h2>
          <div className="flex gap-1 flex-wrap">
            {schools?.map(s => <div className="px-2 border-blue-500 border py-1 rounded-full"><input className="text-blue-500" value={s.id}/></div>)}
            </div>


        <div>
          <h2 className="mb-2 mt-8">Mes classes</h2>
          <div className="bg-slate-100 px-2 py-1">
          <input className=" bg-transparent text-[4px] text-gray-400" value={"id"} /> 
          <input className=" bg-transparent text-blue-500" value={"nom"} /> 
          <input className=" bg-transparent text-slate-500"  value={"code"} /> 
          <input className=" bg-transparent text-slate-500"  value={"id_school"} />
          <input className=" bg-transparent text-slate-500"  value={"id_admin"} />
          </div> 
         {classes.map((c, index) => (
  <div className="border-b px-2 mb-2">
    {index}
    <input className="bg-transparent text-[4px] text-gray-400" value={c.id} /> 
    <input className="bg-transparent text-blue-500" value={c.name} /> 
    <input className="bg-transparent text-slate-500"  value={c.code} /> 
    <input className="bg-transparent text-sm text-gray-400"  value={c.school_id} />
    <input 
      className="bg-transparent text-sm text-gray-400" 
      defaultValue={c.admin && c.admin[0]?.user_id} 
      onBlur={(e) => {
        const newAdmin = [{user_id: e.target.value, role: "admin"}];
        updateDoc(doc(db, 'class', c.id), { admin: newAdmin });
      }} 
    />
  </div>
))}
          
          <div className="flex gap-2 p-4 bg-purple-50 rounded-xl border-2 border-purple-500 mt-12">
            <input id="class_name" className="border-b-2 border-purple-500" placeholder="nom de la classe" />
            <input id="class_school" className="border-b-2 border-purple-500" placeholder="id de l'école" />
            <input id="class_code" className="border-b-2 border-purple-500" placeholder="code de la classe"/>
            <div className="px-2 rounded-full bg-purple-500 text-white py-1 " onClick={() => addClasses()}>Ajouter une classes</div>
          </div>



          </div>
        </div>
        }
          <div className="" onClick={() => {
              const pigier = "TxzkNHFKdlQPERJlwm1E"
              const emse = "I0pumuc1LESkFuq1A9z0"
              fetchUser()
              const userByClass = users?.filter(user => user.class && user.class.includes(emse));
              console.log('userByClass', userByClass)
              const startDate = new Date('2024-04-18T00:00:00');
              const endDate = new Date('2024-04-20T09:00:00');
              const userByDate = getUsersByUpdatedDate(users, startDate,endDate);
              console.log('userByDate', userByDate)
              userByDate?.forEach((user, i) => {
                console.log(i + user.email, user.class && user.class[0])
                if (!user.class) {
                  console.log('pas de classes! ')

                //  ct.fire.updateDb('user', {...user, class: ["I0pumuc1LESkFuq1A9z0"]});
                } 
              })
              console.log('---')
              userByDate?.forEach((user, i) => {
                console.log(user.email)
              })

        }}>voir les usrs</div>

        {/* user android: {users.filter(u => !u.ios && !blacklist.includes(u.email) && !u.email.includes("test") && u.email.includes('emse')).map((e,i) => <div>{e.email}</div>)} */}


        {mode.id == "user" && users?.sort((a, b) => !a.last_connexion ? 1 : !b.last_connexion ? -1 : b.last_connexion.seconds - a.last_connexion.seconds || b.last_connexion.nanoseconds - a.last_connexion.nanoseconds)
        .map(user => renderUser(user))}


      
      

        

        {mode.id == "uwp" && userWorkspaces.filter(c => c.classes?.includes("pl9OB8CkOjWBoNwVawBi"))
        .sort((a, b) => !a.lastStreakDate ? 1 : !b.lastStreakDate ? -1 : b.lastStreakDate.seconds - a.lastStreakDate.seconds || b.lastStreakDate.nanoseconds - a.lastStreakDate.nanoseconds)
        .map(uwp => renderUserWorkspace(uwp))}



  

    </main>
    </div>
  );
};

export {AdminSchool};