import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, updateDoc, setDoc, getDoc, where, writeBatch, query, orderBy, doc, limit, getDocs } from "firebase/firestore";
// import { AudioConfig, SpeechConfig, SpeechRecognizer, PronunciationAssessmentConfig } from '@azure/cognitiveservices-speech-sdk';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';


import { db } from "../../config.js";
import {gpt, numberSentences, speak, stopSound} from '../../utilities/helper/text'
import { SuperEditor } from '../../components/editor.js';
import { markdownToHtml } from '../../utilities/helper/helper.js';

import { bouncy , waveform, momentum} from 'ldrs'
import SuperText from './perfectScreen.js';
import { AudioReader } from '../components/quizzReader.js';
import { DynamicSVG, InteractiveDialog } from '../components/interactiveDialog.js';

momentum.register()
waveform.register()
bouncy.register()









// face: degout, smile, neutral, laught, big_smile, afraid, angry, shooked, sed, ambigous simple, blink, 

const scenarios0 = [
  {
    name: "Conversations libres", 
    id: "free",
    emo: "💬"
  },
  {
    name: "Flirter dans un café",
    description: "Flirter dans un café",
    steps: ["Aller au boulevard des Champs-Élysées", "Trouver un bouquet de films et séances", "Choisir un film ou une séance", "Prendre votre carte de crédit ou votre carte bancaire pour payer", "Payer le film ou la séance", "Trouver votre siège", "Regarder le film ou la séance", "Sortir du cinéma", "Retourner à la maison"],
    emo: "🎬",
  },
  {
    name: "Aller au cinéma",
    description: "Visitez le cinéma pour découvrir des films, séances et spectacles à votre convenance.",
    steps: ["Aller au boulevard des Champs-Élysées", "Trouver un bouquet de films et séances", "Choisir un film ou une séance", "Prendre votre carte de crédit ou votre carte bancaire pour payer", "Payer le film ou la séance", "Trouver votre siège", "Regarder le film ou la séance", "Sortir du cinéma", "Retourner à la maison"],
    emo: "🎬"
  },
  {
    name: "Commander au restaurant",
    emo: "🍽️"
  },
  {
    name: "Faire un tour de la ville",
    emo: "🏙️"
  },
  {
    name: "Découvrir un nouveau lieu",
    emo: "🗺️"
  },
  {
    name: "Réserver un hôtel",
    emo: "🏨"
  },
  {
    name: "Payer en caisse",
    emo: "💳"
  },
  {
    name: "Passer un entretien",
    emo: "💼"
  },
  {
    name: "Se présenter à quelqu'un",
    emo: "🙋"
  },
  {
    name: "Faire une réservation pour un hôtel",
    emo: "🏨"
  },
  {
    name: "Flirter par message",
    emo: "💌"
  }
];


const getSc = async (wp_id, set, wp_id2 = "français") => {
  console.log('getSc 🟢', wp_id)
  const q = query(
    collection(db, 'scenarios'),
    where('wp_id', 'in', [wp_id, wp_id2])
  );
  const querySnapShot = await getDocs(q);
  const _uw = querySnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  console.log('_uw', _uw)
  set(_uw)
  return _uw;
}
function scrollToBottom() {
  const element = document.getElementById('scroll');
  if (element) {
    element.scrollTo({
      top: element.scrollHeight - 250,
      behavior: 'smooth'
    });
  }
}


const referenceText = 'Hello how are you ?'; // Replace with the appropriate reference text








const Message = ({mess, darkMode, selectedChat, ct, classUser, classOther, currentUserChat, messages, setMessages, vocalMode}) => {

  const [showTrad, setShowTrad] = useState(false)
  const [revealMsg, setRevealMsg] = useState(true)
  const [dataSpeak, setDataSpeak] = useState()
  const [isSpeaking, setIsSpeaking] = useState(false)

  const [currentMess, setCurrentMess] = useState(mess)
  const [loadingTrad, setLoadingTrad] = useState(false)
  const [loadingReformulation, setloadingReformulation] = useState(false)

  useEffect(() => {
    setCurrentMess(mess)
  }, [mess])

  useEffect(() => {

      setRevealMsg(!vocalMode)
  
  }, [vocalMode])


  const handleReformulation = async () => {

    console.log('inject msg')
    console.log('mess', mess)
    console.log('messages', messages)
    setloadingReformulation(true)
    const query = [
      {"role": "user", "content": `Donne uniquement la réponse. Reformule ce message dans un ${ct.workspace.name} naturel "${mess.content}"`}
  ]
    const newText = await gpt(query, true, ct)
    console.log('newText', newText)
    const updatedMessages = messages.map(m => m == mess ? {...m, ia_updated:  newText.choices[0]?.message.content} : m)

    setMessages(updatedMessages);
    setloadingReformulation(false)
    // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})




 
 
  }


  const handleTrad = async() => {

    if (!currentMess.trad) {
      console.log('pas de traduction !')
      const query = [
        {"role": "user", "content": `Donne uniquement la réponse. traduit "${mess.content}" en français`}
    ]
      setLoadingTrad(true)
      const tradData = await gpt(query, true, ct)
      setLoadingTrad(false)
      const tradText = tradData.choices[0]?.message.content
      console.log('tradText', tradText)
      setCurrentMess({...currentMess, trad: tradText})
      const updatedMessages = messages.map(m => m == mess ? {...m, trad: tradText } : m)
      setMessages(updatedMessages);
      setShowTrad(true)
      // updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: updatedMessages})
      


    } else {
      setShowTrad(t =>!t)
      console.log('trad !!??')
    }
    
  }


  const maxCharacter = mess.content?.length

  // ajoute mess.ug user generated pour ajoute les actions ['reformulation']

  return <div onClick={() => {
    console.log('selectedChat', selectedChat)
  }} className={`${mess.role == "user" ? "justify-end text-white ml-4" : "mr-4"} transition-all relative flex gap-2`}>
       <div className=''>
      <div className={`flex transition-all relative ${mess.role == "user" ? "justify-end text-white "  : ""}`}><div className={`rounded-xl border-[2px] ${mess.role == "user" ? classUser:classOther } ${mess.role == "user" ? 
      mess.ia_updated ? `text-xs pb-2  
        ${darkMode ? " text-white/80 bg-white/20 border-transparent": "text-blue-500  bg-white/70"}  
        text-right justify-end self-end` : `self-end rounded-tr-[2px] bg-blue-500 border-blue-700 text-right justify-end` : `rounded-tl-[2px] w-full ${vocalMode ? "pr-8" : ""}  ${darkMode ? "bg-[#5e00c9]  text-white border-black/20":" bg-white text-slate-700"}  `} min-w-[40px] max-w-[90vw] px-[8px] py-[6px] overflow-hidden border-b-[4px] mb-3 `}>
      {/* <SuperEditor className="text-xl pb-0" onBlur={() => {console.log('ee')}} content={markdownToHtml(mess.content)?.replaceAll('\n', '<br/> ---')} /> */}
      
        {vocalMode && <div>
          <div className='flex items-center gap-2'>
        {/* <div>{dataSpeak?.["_duration"]}s</div> */}
          <div className='' onClick={async() => {
            if (isSpeaking) {
              stopSound()
              setIsSpeaking(false)
            return
          }
            setIsSpeaking(true)
            const dataSpeak = await speak({text: removeEmojis(mess.content), lang: ct.workspace?.lang, setDataSpeak })
            setIsSpeaking(false)
            console.log(dataSpeak)
          }
            }>
              {isSpeaking ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
</svg>
: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
              </svg>}
</div>
<div className={`h-3 ${isSpeaking ? "animate-pulse" : ""}  w-full bg-white/50 rounded-full`}></div>


          </div>
          <div className='text-xs text-transparent h-[5px] max-h-[5px] pr-8 '>
            {!revealMsg && mess.content?.slice(0, maxCharacter < 70 ? maxCharacter : 70) + '...'}
            <div className='absolute right-2 top-2 text-white/70' onClick={() => {setRevealMsg(t => !t)}} ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 transition-all ${revealMsg ? "rotate-[180deg]" : ""}`}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
</div>
            </div>
          </div>}
        {revealMsg &&  <SuperText 
                        vocalMode={false} 
                        colorText={false}
                        text={numberSentences(mess.content)} 
                        imgContext={selectedChat?.bg}
                        trad={numberSentences(mess.trad)} 
                        decks={[]} 
                        cards={ct.cards}  
                        ct={ct}/>}

          {!vocalMode && <div id="helper" className={`${mess.role == "user" ? "justify-end" : ""} flex mt-2 items-center`}>
            {isSpeaking ? <l-waveform size="18" speed="1.6"   color="#80746B4D" ></l-waveform> : <svg onClick={async() => {
              setIsSpeaking(true)
              await speak({text: removeEmojis(mess.content), lang: ct.workspace?.lang})
              setIsSpeaking(false)
              }}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
              <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
            </svg>}
              {mess.ug ? !mess.ia_updated && <div className='px-2 text-xs opacity-[0.7] rounded-xl' onClick={async() => {handleReformulation() }} >Reformuler {loadingReformulation && "..."} </div> : <div onClick={() => handleTrad()} className={`${showTrad ? "bg-amber-100/30" : ""} px-2 text-xs opacity-[0.7] rounded-xl`}>Voir la traduction  {loadingTrad && "..."}</div>}
            </div>}
            {currentMess.trad && showTrad && <div className={`transition-all text-slate-500 ${darkMode ? "bg-black/30- text-white/50-" : "bg-slate-100/60- text-slate-500-"} text-xs italic   p-2 pt-1 mt-2 -mx-2 -mb-2   ${vocalMode ? "!-mr-8  " : ""}`}>{mess.trad}</div>}

          </div>

        </div>
        {mess.ia_updated && <div className='bg-emerald-500 text-right pl-6 relative rounded-xl border-[2px] border-black/20 border-b-[4px] mb-3 -mt-[20px] p-2 pt-1  -mx-2 -mb-2'>
        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-4 absolute top-1 left-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
          {mess.ia_updated}
          </div>}

    
 

       

    
          
           </div>
  </div>}






function removeEmojis(text) {
  return text.replace(
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u2600-\u26FF\u2700-\u27BF\ufe0f]/gu, ''
  );
}


const ChatScreen = (props) => {
  const [prompt, setPrompt] = useState()
  const [preprompt, setPreprompt] = useState("Réponds en allemand et corrige les fautes")
  const [answers, setAnswers] = useState([])
  const [messages, setMessages] = useState([])
  const [selectedChat, setSelectedChat] = useState()
  const [showInfo, setShowInfo] = useState()

  const [darkMode, setDarkMode] = useState(true)
  const [scenarios, setScenario] = useState([])

  const [userChats, setUserChats] = useState()

  const ct = props.context || props.ct
  const currentUserChat = userChats?.find(uc => uc.chat_id === selectedChat?.id)

  function countNewLines(input) {
    let matches = input?.match(/\n/g);
    return matches ? matches.length : 0;
  }

  const createUserChat = () => {
    console.log('createUserChat 🟢🟢🟢🟢🟢')
    const data = {
      workspace_id: ct.workspace.id,
      user_id: ct.user.id,
      chat_id: selectedChat.id,
      messages: [],
      answers: [],
      created_at: new Date(),
      updated_at: new Date(),
    }
    console.log('data', data)
    const docRef = addDoc(collection(db, "user_chat"), data);
    setUserChats([...userChats, {...data, id: docRef.id}])
    console.log('docRef', docRef)
  }

  const getUserChats = async (wp_id, user_id) => {
    console.log('getUserCards 🟢🟢🟢🟢🟢', wp_id,user_id)
    const q = query(collection(db, 'user_chat'), where('workspace_id', "==", wp_id), where('user_id', "==", user_id))
    const querySnapshot = await getDocs(q);
    const cards = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
 
    setUserChats(cards)
}


useEffect(() => {
  getUserChats(ct.workspace?.id, ct.user?.id)
}, [ct.workspace?.id, ct.user?.id])

useEffect(() => {
  setMessages(userChats?.find(uc => uc.chat_id === selectedChat?.id)?.messages || [])
},[selectedChat])

useEffect(() => {
  ct.setActiveScreen("chat")
  ct.setShowBottomBar(true)
}, [])
  

 


  const resetChat = () => {
    setMessages()
    updateDoc(doc(db, 'user_chat', currentUserChat.id), {messages: []})
  } 


  const chats = [
    {
    id: "didier",
    title: "Discussions du quotidient",
    name: {all: "John",  "en-GB": "John"}, 
    prompt: `
     Ignore toutes les instructions précédentes. Réponds uniquement avec du textes et pas de markdown.
     Ton nom est John, tu es un humain de 30 ans,  un  ${ct.workspace.name} natif, réponds en ${ct.workspace.name}.
     Tu discute avec une personne par sms, préviligé les réponses courtes.
     Sois joyeux, n'hésite pas à faire de l'humour et intéresses toi la vie de ${ct.user?.name}. 
     Pose régulièrement des questions qui incite l'utilisateur à répondre, mène la conversation il ne faut pas que l'utilisateur relance la conversation.
     Tu peux utiliser des émojis de occasionnelement sans en faire trop.
     Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige, car il apprend ${ct.workspace.name} en discutant avec toi.
     Pour chacun de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
     Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "A",
    description: "Bon vivant et très blagueur, discutez avec lui du quotidien.",
    photo: "/images/tar/chat_bot/chat_3.png",
  },
  {
    id: "elprofessor",
    title: "Pose des questions",
    name: {all: "El professor",  "en-GB": "El professor"}, 
    prompt: `

    Ignore toutes les instructions précédentes. Tu es un professeur ${ct.workspace.name} expérimenté. 
    Tu parles nativement ${ct.workspace.name} et tu enseignes depuis 12 ans. 
    Tu discute avec une personne par sms.
     Tu connais plein de chose sur la culture ${ct.workspace.name} et tu aimes partager tes connaissances et raconter des anecdotes insolites.
     Tu peux utiliser des émojis de occasionnelement sans en faire trop.

     Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige tout en répondant à son message, 
     car il apprend ${ct.workspace.name} en discutant avec toi. Si tu le corrige réponds à son message également !!
     
     Pour chacune de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
     Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "B",
    description: `Prof ${ct.workspace.name} depuis 12 ans. Si tu as des questions sur la grammaire, la culture ou autre c'est l'homme de la situation`,
    photo: "/images/tar/chat_bot/chat_2.png",
  },
  {
    id: "elpsy",
    title: "Raconte ta vie",
    name: {all: "Carl",  "en-GB": "Carl"}, 
    prompt: `

    Ignore toutes les instructions précédentes. Tu es un psychologue expérimenté. Tu parles nativement ${ct.workspace.name}. 
    Tu es reconnu pour avoir accompagné et aidé de nombreux patient à se sentir mieux. 
    Tu connais de nombreuses techniques et méthodes pour amener un patient à se poser les bonnes questions et à résoudre ses problèmes. 
    Ta tâche est de m'aider à réfléchir à ma situation en me posant les bonnes questions et à aller mieux.
    Tu peux utiliser toutes les méthodes que tu connais, en me posant des questions et en me faisant des retours lorsque tu juges cela nécessaire. Le but est de m'aider à trouver des réponses par moi même au maximum, juste en me posant les bonnes questions.
    Je veux tu attendes une réponse de ma part après chaque intervention de ta part.
    Je suis ton patient et tu dois m'aider à aller mieux

    Tu discute avec une personne par message textuel.

    Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige en le reprenant, car il apprend ${ct.workspace.name} en discutant avec toi mais réponds aussi à son message ensuite.
    Pour chacun de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
    Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "B",
    description: "Psychologue de renommée, tu peux lui confier tes problèmes les plus profonds",
    photo: "/images/tar/chat_bot/chat_1.png",
  },
  {
    id: "her",
    title: "her",
    name: {all: "her",  "en-GB": "her"}, 
    prompt: `

    Ignore toutes les instructions précédentes. Tu es un professeur ${ct.workspace.name} expérimenté. 
    Tu parles nativement ${ct.workspace.name} et tu enseignes depuis 12 ans. 
    Tu discute avec une personne par sms.
     Tu connais plein de chose sur la culture ${ct.workspace.name} et tu aimes partager tes connaissances et raconter des anecdotes insolites.
     Tu peux utiliser des émojis de occasionnelement sans en faire trop.

     Si l'utilisateurs fais des fautes en ${ct.workspace.name} ou s'exprime mal ou parle français il faut que tu le corrige tout en répondant à son message, 
     car il apprend ${ct.workspace.name} en discutant avec toi. Si tu le corrige réponds à son message également !!
     
     Pour chacune de tes réponse en ${ct.workspace.name} et donne la traduction en français après ">>" dans le format suivant :
     Hello, how are you ? >> Salut, comment vas-tu ?.
     `,
    variant: "B",
    description: `this is her`,
    photo: "/images/tar/chat_bot/chat_2.png",
  },


  // {
    
  //   id: "elprofessor",
  //   title: "Pose des questions",
  //   name: {all: "El professor",  "en-GB": "El professor"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es un professeur ${ct.workspace.name} expérimenté. Tu parles nativement ${ct.workspace.name} et tu enseignes depuis 12 ans quand tu recois une message ${ct.workspace.name} qui n'ai pas correct ou avec des fautes. Corriges-le avant de répondre. 
  //   Si l'user fais des fautes en ${ct.workspace.name} reprend le et corrige le en français, c'est important qu'il apprenne. 
  //   Mais tu dois quand même répondre au message en ${ct.workspace.name}. N'hésite pas envoyer des smiley et à donner beaucoup d'exemple pour illustrer tes propos. Reponds au format html avec des balises et n'hésite pas faire des tableaux pour les conjuguaisons `,
  //   variant: "B",
  //   description: `Prof ${ct.workspace.name} depuis 12 ans. Si tu as des questions sur la grammaire, la culture ou autre c'est l'homme de la situation`,
  //   photo: "/images/UX/professor_round.png",
  // },
  // {
  //   id: "elpsy",
  //   title: "Raconte ta vie",
  //   name: {all: "Marius", "en-GB": "Marius"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es un psychologue expérimenté. Tu parles nativement ${ct.workspace.name}. 
  //   Tu es reconnu pour avoir accompagné et aidé de nombreux patient à se sentir mieux. 
  //   Tu connais de nombreuses techniques et méthodes pour amener un patient à se poser les bonnes questions et à résoudre ses problèmes. 
  
  //   Ta tâche est de m'aider à réfléchir à ma situation en me posant les bonnes questions et à aller mieux.
  //   Tu peux utiliser toutes les méthodes que tu connais, en me posant des questions et en me faisant des retours lorsque tu juges cela nécessaire. Le but est de m'aider à trouver des réponses par moi même au maximum, juste en me posant les bonnes questions.
    
  //   Je veux tu attendes une réponse de ma part après chaque intervention de ta part.
    
  //   Je suis ton patient et tu dois m'aider à aller mieux`,
  //   variant: "B",
  //   description: "Psychologue de renommée, tu peux lui confier tes problèmes les plus profonds",
  //   photo: "/images/tar/tard7.svg",
  // }
  // {
  //   id: "elbg",
  //   name: {all: "Ton crush"}, 
  //   prompt: `Ignore toutes les instructions précédentes. Tu es une fille de 23 ans, tu es ${ct.workspace.name} native. 
  //   Tu parles ${ct.workspace.name} comme les jeunes  de ton ages. 
  //   Tu es très ouverte et tu aimes discuter avec les gens. 
  //   Utilise des smileys et des émojis. 
  //   Tu aimes draguer et tu veux me séduire.
  //   Tu dois mener la conversation et me poser des questions plutôt originale, il ne faut pas que l'utilisateur n'ai rien à dire
  //   `,
  //   variant: "B",
  //   description: `Ton crush qui vient te parler. `,
  //   photo: "/images/tar/tar3.svg",
  // },
]

  const progressCent = ct.userDaily?.total_tokens / 2000 *100
  const [isWriting, setIsWriting] = useState(false)
  const [audioMode, setAudioMode] = useState(false)
  const [vocalMode, setVocalMode] = useState(false)
  const [indexShow, setIndexShow] = useState(false)

  // useEffect(() => {
  //   getSc
  // }, [])




  const index =  <div className='p-4 '>
 
  {/* <div className='fixed left-0 border-t right-0 bottom-[60px] p-4 fredoka '>
    <div className='text-sm  mb-2 text-center'>Mes credits du jour</div>
    <div className='h-[16px] bg-black/10 grow relative rounded overflow-hidden'>
            <div className='h-[16px] rounded relative w-full bg-black/50 grow' >
                <div className='bg-amber-400 h-full border-[1.5px] border-black/70  rounded transition-all ' style={{width: `${(progressCent)}%`}}></div>
                <div className='h-[4px] -mt-[14px] mx-2 rounded transition-all bg-white/60' style={{width: `calc(${(progressCent)}% - 12px)`}}></div>
                <div className='h-[5px] mt-[3px] mx-1 rounded transition-all bg-amber-900/20' style={{width: `calc(${progressCent}% - 14px)`}}></div>
                <div className='text-center text-shadow text-white fredoka font-bold -mb-4 mt-[-20px]'><span className='text-amber-200 mr-[1px]'>{ct.userDaily?.total_tokens || 0}</span>/2000</div>
            </div> 
                <div className={`bg-amber-500  h-full absolute rounded-xl`} style={{width: Math.round(progressCent) + "%" }}></div>
         </div>
  </div> */}
   {indexShow == "free" && <div>
    <div onClick={() => {setIndexShow('index')}} className='mb-2'>← Retour</div> 
    {/* <div className='text-white text-center mb-4'>Choisis avec qui tu souhaites discuter</div> */}
    </div>}
  {indexShow != "free" && scenarios?.filter(e => e.wp_id == ct.workspace?.id)?.map((e,i) => {
    const img_name = `/images/tar/chat_bot/chat_${i%3 + 1}.png`
    return   <div 
    style={{backgroundImage: `url(${e.bg})`}}
    onClick={() => {
      if (e.id == "free") {setIndexShow("free")}
       else {
        const tradChat = scenarios.find(x => x.id == e.original)?.content
        setSelectedChat({...e, tradContent: tradChat})
        setShowInfo(true)
      }
    

      }} className={`p-3 ${e.id == "free" ? "bg-blue-900/20" : "bg-black/20"} text-shadow relative overflow-hidden bg-center bg-cover border-black/70  mb-2 flex gap-2 rounded-xl border-2 border-b-4`}>
        <div className='absolute top-0 z-0 left-0 right-0 bottom-0 bg-indigo-500/50'></div>
       
        {/* <div className='absolute -top-2 -left-1 bg-indigo-500/50 rounded-full h-[40px] w-[40px] flex text-xl items-center justify-center'>{e.emo}</div> */}
        <div className='relative z-10'> 
          <DynamicSVG  src={"/images/UX/peeps/head/"+e.face+".svg"} className=" -mt-2  relative  h-[40px] w-[40px] text-[#0e0c3e]"/>
          <img  src={"/images/UX/peeps/face/Calm.svg"} className='h-[78%] absolute top-[12%] left-[29%]' />
          <img  src={`/images/UX/peeps/body/${e.body}.svg`} className='h-[40px] absolute top-[82%] left-[-9%]' />


        </div>
  
        <div className='relative z-20 font-bold truncate' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
  {e.name}
</div>
       
      </div>
  })}
  
  {indexShow == "free" && chats.map(chat => {
    const userChat = userChats?.find(e => e.chat_id == chat.id)
    return <div 
    onClick={() => {setSelectedChat(chat); !userChat && setShowInfo(true)}} 
    className={`p-3 ${userChat ? "bg-blue-900/20" : "bg-black/20"}  border-black/90  mb-2 flex gap-2 rounded-xl border-2 border-b-4`}>
      <img src={chat.photo} className='h-[54px] rounded-full' />
      <div>
        <div className='fond-bolder'>{chat.name[`${ct.workspace?.lang}`] || chat.name.all}</div>
        {!userChat && <div className='text-white/80 text-xs'>{chat.description}</div>}
        { userChat&& <div className='text-white/80'>Continuer la discussion</div>}
      </div>
      </div>
  })}
   </div>


  const [selectedMode, setSelectedMode] = useState()

  const mode = [{
    id: "interactif",
    name: "Dialogue interactif",
    desc: "Découvre le dialogue et choisis les bonnes réponses."
  }, 
  {
    id: "expression",
    name: "Prononciation",
    desc: "Travaille ton expression orale et écrite en répondant de manière guidée.",
    // soon: true,
  }, 
  {
    id: "open",
    name: "Discussion libre",
    desc: "Discute librement à l'oral ou à l'écrit. C’est le meilleur moyen pour apprendre à converser dans une discussion réelle."
  }
]
   


  const info = selectedChat && <div style={{backgroundImage: `url('${selectedChat.bg}')`}}  className={`h-screen bg-center  bg-cover fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} p-4 flex flex-col text-center gap-2 items-center justify-center`}>
     <div onClick={() => setSelectedChat()} className='w-full fixed top-4 left-4 text-left text-slate-500'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-6 text-white ">
  <path fillRule="evenodd" d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z" clipRule="evenodd" />
</svg>
 </div>
    <div className='absolute pointer-events-none z-[-1] top-0 left-0 right-0 bottom-0 bg-[#3c2777b3]'></div>
     {/* <img src={selectedChat.photo} className='h-[60px] mt-10'/> */}
     {/* <h2 className={`${darkMode ? "text-white" : ""}`}>{selectedChat?.name?.[`${ct.workspace?.lang}`] || selectedChat?.name?.all}</h2> */}
     <div className={`${darkMode ? "text-white/60" : "text-slate-500"}  text-center`}>{selectedChat?.description}</div>
     <div className='text-white' onClick={() => {console.log('selectedChat', selectedChat)}}>Choisis ton mode d'apprentissage</div>
     <div>{mode.map(e => <div onClick={() => {setSelectedMode(e.id);setShowInfo(false)}} className={` ${e.soon ? "opacity-50 pointer-events-none	" : ""} px-3 bg-indigo-600/50 backdrop-blur-xl  game-btn border-2 border-b-4 border-black/70 py-2 pb-4 rounded-xl mt-2`}>
      <div className='mb-1 font-semibold'>{e.name}</div>
      <div className='text-xs text-white/70'>{e.desc}</div>
      </div>)}</div>
     {/* {currentUserChat && <div className={`${darkMode ? "text-red-300/90" : "text-red-900 "} text-center my-4 `} onClick={( ) => resetChat()}>Effacer la discussion</div>}
     {!currentUserChat ? <div onClick={() => {setShowInfo(); createUserChat()}} className='px-4 bg-purple-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Commencer à discuter</div> : 
     <div onClick={() => setShowInfo()} className='px-4 bg-emerald-500 border-2 border-b-4 border-black/40 text-xl text-white py-1 rounded-xl mt-16'>Reprendre la discussion</div>} */}
  </div>  

  const chat = selectedChat && <div id="scroll" className={`h-screen fixed top-0 left-0 right-0 bottom-0 ${darkMode ? "bg-gradient-to-b from-[#370e57] to-[#2a0449]" : "bg-yellow-50"} z-[10] overflow-scroll `}>
 
 
 
  <header className={`flex ${darkMode ? "bg-[#270742]/80/10 bg-blur-xl border-black/30" :"bg-[#fefdf3]" } items-center z-10 fixed top-0 right-0 left-0 items-center  px-2 py-4 justify-between gap-2`}>
     <div className='flex  gap-2 items-center'>
     <svg onClick={() => setSelectedChat()}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className=" p-2 min-h-[40px] min-w-[40px] -ml-[6px] h-[40px] w-[40px]">
      <path fillRule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clipRule="evenodd" />
    </svg>

        {/* <img src={selectedChat.photo} className='h-[36px]'/> */}
        <div className={`${darkMode ? "text-white" : ""} text-sm`}>{selectedChat?.name?.[`${ct.workspace?.lang}`] || selectedChat?.name}</div>
        <span onClick={() =>setShowInfo(true)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-5 w-5 ${darkMode ? "text-white/50" : "text-slate-500"} mt-1 `}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>
</span>
        {/* <div className='text-slate-300'>[{ct.userWorkspace.total_tokens}] | {ct.userDaily?.total_tokens}</div> */}
       
     </div>
    
     <div className='grow flex justify-end'>
          <div className='flex bg-black/20 border-2 border-black/40 overflow-hidden rounded-xl gap-1'>
            <svg onClick={() => {setVocalMode(false)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 p-[3px] px-[5px] rounded-md ${!vocalMode ? "text-indigo-100 bg-black/30" : "text-white/40"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
            </svg> 
            <svg onClick={() => {setVocalMode(true)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-6 p-[3px] px-[5px] rounded-md ${vocalMode ? "text-indigo-100 bg-black/30" : "text-white/40"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
            </svg>
          </div>
      </div>
     


     

     </header>
     <div className='fixed top-0 left-0 right-0 h-[100px] bg-gradient-to-b from-black/70 via-black/50 to-black/10'></div>
     <div className={`${darkMode ? "" : "bg-yellow-50"} overflow-hidden  max-h-screen h-screen `}>

     <InteractiveDialog workspace={ct.workspace} user={ct.user} selectedMode={selectedMode} ct={ct} lang={ct.workspace?.lang} vocalMode={vocalMode} setShowBottomBar={e => ct.setShowBottomBar(e)}  selectedChat={selectedChat} trad={JSON.parse(selectedChat.tradContent)} content={JSON.parse(selectedChat.content)}  />
     


    

      
       {messages?.map((mess) => <Message selectedChat={selectedChat} messages={messages} vocalMode={vocalMode} currentUserChat={currentUserChat} setMessages={setMessages} ct={ct} darkMode={true} mess={mess}/>)}
      
     </div>


     {false && <div className={`fixed left-0 ${darkMode ? "bg-[#370e57]" : "bg-yellow-50"} pt-2 right-0 bottom-[50px] pb-1 transition-all px-4 mb-4 ${!isWriting && !audioMode ? "pr-[80px]":  "pr-[2px]"} `}>
     {audioMode && <svg onClick={() => setAudioMode()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`h-10 ${darkMode ? "text-blue-100" : "text-blue-500"}  rounded-full absolute -left-0 bottom-6`}>
  
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />


      </svg>}
      

     
    </div>}
  </div>

  const beta = <div></div>



  useEffect(() => {
    ct.workspace?.id && getSc(ct.workspace?.id, setScenario)
  }, [ct.workspace?.id])

  return (
    <div className='fredoka pb-[100px]'>
    
      {ct.user.admin || ct.user.beta || true ? <div>
        
       {!selectedChat && index}
       { selectedChat && showInfo && info}
       { selectedChat && !showInfo && chat}

       </div> : <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">Coming soon</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et sera bientôt disponible !</div>
                </div>
              </div>}
      
    </div>
  );
};

export {ChatScreen, Message};

