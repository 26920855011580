import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, getDocs, query, limit} from "firebase/firestore";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { db } from "../../../config.js";
import { calculateAverageWithIncrease, missions, xpToGrade } from '../../../utilities/helper/helper.js';
import { WeekSreak } from '../weekStreak.js';
import { ParticipationNote } from '../participationNote.js';

const DailyMission = (props) => {
    const ct = props.context
    const [isEnded, setIsEnded] = useState(false)
    const [reward, setReward] = useState()
    const currentMission = missions[ct.userDaily?.mission_valided || 0]

    function randomInRange(rangeString) {
        // Découper la chaîne pour obtenir les bornes de l'intervalle
        const [min, max] = rangeString?.split('-').map(Number);
    
        // Générer un nombre aléatoire dans cet intervalle
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    

    useEffect(() => {
        ct.setShowBottomBar(false)
        currentMission?.reward && setReward(randomInRange(currentMission?.reward))
        return ct.setShowBottomBar(true)
        
    },[])

    useEffect(() => {
        ct.setLastXp(ct.userDaily?.xp)
        setIsEnded(ct.userDaily?.xp >= currentMission?.xpNeeded)
    }, [900])



const firstDate = ct.user?.updated?.toDate() || new Date()


const xpValues = [1, 6, 10, 12, 36, 50,75, 96];
const grades = xpValues.map(xp => xpToGrade(xp));





console.log(' ct.userWorkspace',  ct.userWorkspace)
console.log(' firstDate',  firstDate)


// Filtrer les éléments de xp_table_day pour la semaine actuelle


const validMission = () => {
    ct.fire.updateDaily({...ct.userDaily, mission_valided: ct.userDaily.mission_valided + 1 || 1}, ct)
    ct.setShowPop("recapSession")
    ct.fire.updateUserWorkspace({...ct.userWorkspace, coins: (ct.userWorkspace.coins || 0)+reward}, ct)
}

const chest = <div className=' text-white'>
    <div className='text-center flex flex-col justify-center items-center'>
        <img src={'/images/UX/treasure.png'} className="h-[90px] text-center mx-auto" />
        <div>Récompense obtenue</div>

        <div className='flex gap-2 font-semibold bg-black/30 rounded-xl mt-2 px-3 py-1'>{reward} <img className='h-5 w-5' src={'/images/UX/coins.svg'}/></div>
        <div onClick={() => validMission()} className='mt-4 bg-white text-blue-500 px-4 py-1 rounded-xl'>Valider la mission</div>
    </div>
</div>
  
  return (
    <div id="dailyMission" className={`fixed fredoka top-0 flex flex-col items-center justify-center !z-[110] left-0 right-0 bottom-0 bg-gradient-to-b from-[#a728d0] via-purple-700 to-purple-700`}>
        <div className='max-w-[600px] mx-auto w-full'>
            <div className='fixed top-4 right-4 text-white' onClick={() => ct.setShowPop()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div className=' p-8 rounded flex flex-col items-center flex-wrap'>
                <div className='mb-4 mx-auto fixed text-center top-4 text-white flex gap-2'>Mission du jour
            </div>
            {/* <div className='w-full text-center text-white/50 '>{currentMission?.name}</div> */}

                <div className='flex items-center mb-8 justify-center !h-[180px] !w-[180px]'> 
                <CircularProgressbar
                            value={ct.lastXp*100/currentMission?.xpNeeded || 0}
                            className='absolute circularProgress transition !h-[180px]'
                            styles={buildStyles({
                            strokeLinecap: 'butt',
                            strokeWidth: 2,
                            textSize: '0px',
                            pathColor: "#ffb25d",
                            textColor: 'red',
                            pathTransitionDuration: 0.5,
                            strokeLinecap: 'round', 
                            trailColor: '#00000078',
                            backgroundColor: 'red',
                            transform: 'rotateX(30deg)'
                            })}
                            strokeWidth={12}

                    />
                     <div className='text-xs text-white/50' >
                        {isEnded ? <div className='flex gap-2 items-center'>
                            <span className='fredoka text-2xl text-white font-semibold'>+ 1 </span>
                            <img src="images/deco/red_flamme.svg" className='h-[58px]'/>
                        </div> 
                        : <div>
                            <div className='mt-4 ml-2'><span className='font-semibold text-3xl text-[#ffb25d]'>{ct.userDaily?.xp}</span></div>
                            <div className='ml-[2px] '>/ {currentMission?.xpNeeded} xp</div>
                        </div>}
                    </div>
                </div>

                {isEnded && <WeekSreak context={ct} />}



                <ParticipationNote context={ct}  />

                

                <div className='px-4 py-2 mt-6 mb-12 relative pt-4 text-white rounded-xl bg-black/20 w-full flex flex-wrap wrap justify-between items-center '>
                
                   <div className='text-white text-sm  absolute -top-2 -left-2 gap-2 rounded-full px-3  pl-2 flex bg-[#aa4aff] items-center'>
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                    </svg>

                   <div> Récompense</div>
                    </div>
                    <div>
                        <div className={`mr-4 text-amber-300 font-semibold ${currentMission?.color}`}>{currentMission?.chestName}</div>
                      
                    </div>
                   <div className='flex gap-2  items-center'> 
                        <img src={'/images/UX/treasure.png'} className="h-[42px] scale-[1.6]" />
                      
                   </div>
                   {isEnded && <div onClick={() => ct.setShowInfo({title: 'Coffre rare', classContent: "bg-blue-500",  content: chest})} className='flex justify-center -mb-6 w-full mt-4 font-semibold left-0 right-0'>
                    <div className='bg-white text-[#9300ff] px-4 py-2 text-xl rounded-full'>{"Obtenir la récompense →"}</div>
                </div>}
                </div>

                {/* {
                    isEnded ? 
                    <div onClick={() => ct.setShowInfo({title: 'Coffre rare', classContent: "bg-blue-500",  content: chest})} className='flex justify-center fixed bottom-16 font-semibold left-0 right-0'>
                    <div className='bg-white text-[#9300ff] px-4 py-2 text-xl rounded-full'>{"Obtenir la récompense →"}</div>
                </div>
                :  */}
                <div onClick={() => ct.setShowPop("recapSession")}  className={`flex justify-center  ${!isEnded ? "bottom-16" : "bottom-10"} fixed   left-0 right-0`}>
                    <div className={` ${!isEnded ? "bg-white text-[#9300ff] " : "text-white/70 mt-4"} px-4 py-2 text-xl rounded-full`}>Continuer →</div>
                </div>
                {/* } */}

               
                          
            </div>

          
        </div>
    </div>
  );
};

export {DailyMission};