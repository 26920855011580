import { createContext, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, getDocs, query, limit} from "firebase/firestore";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { db } from "../../../config.js";
import { calculateAverageWithIncrease, missions, xpToGrade } from '../../../utilities/helper/helper.js';
import { WeekSreak } from '../weekStreak.js';
import { ParticipationNote } from '../participationNote.js';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../utilities/helper/text.js';
const cards = [
    {
        status: "sup",
    }
    , 
    {
        status: "toReview"
    }
    , 
    {
        status: "new"
    }
    ,
    {
        status: "new"
    }

]

const statuTxt = {"new": "Nouveau", "toReview": "A rafraichir", "sup": "Niv Supérieur !"}

const MiniCard = ({card, userCard, status}) => { 
   


    const context = card.contexts?.length && card.contexts[0].text
    // const context = "Ayer, elegiste un color oscuro para tu habitación. Ayer, elegiste un color oscuro para tu habitación.  Ayer, elegiste un color oscuro para tu habitación.  Ayer, elegiste un color oscuro para tu habitación."
    return <div className={` ${status == "archived" ? "opacity-50" : ""}  relative text-white rounded-xl w-full bg-purple-900 border-2 border-b-4 border-purple-900 h-[130px] pb-4`}>
    <div className='relative z-0'>
        {!card.img && !card.img_400 ? <div className='max-w-full h-[90px] bg-gradient-to-b from-blue-500 to-purple-600 w-full rounded-xl flex items-center justify-center bg-blue-500'><img className='h-[120px] w-[100px] mb-[30px]' src="images/seeds/green-2.png" /></div> : <img className='rounded-xl mx-auto object-cover  max-w-full h-[90px] w-full' src={card.img_400 || card.img}/>}
       
    </div>
    <div className='p-2 rounded-xl h-[50px] flex items-center justify-center -mt-4 relative z-10 bg-purple-900 '>
        <div className='text-center text-sm max-h-[42px] text-ellipsis overflow-hidden  semibold'>{capitalizeFirstLetter(card.term)}</div>
    </div>
    {/* <div className='text-xs text-center max-h-[42px] text-ellipsis  overflow-hidden text-purple-500 italic '>{context}</div> */}
    <div className={`absolute -top-2 -right  text-white text-[11px] flex justify-center`}>
        {status == "new" && <div className='px-2 bg-purple-500 border border-purple-500 text-white text-amber-600 font-semibold inline-block rounded-full '>Nouveau <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} fill="currentColor" className="w-3 h-3 ml-0 mx-1 inline-block">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
</svg>
</div> }
    {status == "archived" && <div className='px-2 bg-slate-100 border border-slate-400 text-slate-500 text-slate-600 font-semibold inline-block rounded-full '>Archivé <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} fill="currentColor" className="w-3 h-3 ml-0 mx-1 inline-block">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
    </svg>
    </div> }
        </div>
    </div>
}

const RecapSession = (props) => {
    const ct = props.context
    const navigate = useNavigate()


    useEffect(() => {
        ct.setShowBottomBar(false)
        return ct.setShowBottomBar(true)
        
    },[])

    useEffect(() => {
        ct.setLastXp(ct.userDaily?.xp)
    }, [900])

    


  return (
    <div id="dailyMission" className={`fixed fredoka top-0 flex flex-col items-center justify-center !z-[110] left-0 right-0 bottom-0 bg-gradient-to-b from-[#a728d0] via-purple-700 to-purple-700`}>
        <div className='max-w-[600px] mx-auto w-full'>
            <div className='fixed top-4 right-4 text-white'  onClick={() => {ct.setShowPop(); ct.setStatusCards()}} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div className=' p-4 rounded flex flex-col items-center flex-wrap'>
                <div className='mb-4 mx-auto fixed text-center top-4 text-white flex gap-2 fredoka'>
            </div>
         
               {/* <div className='my-10'>+ 20 xp pièce d'or</div> */}
                
               {/* <div>Nouvelle cartes </div> */}
               <div className='text-white mb-4 text-white/70 -mt-8'>Nouvelles cartes disponibles !</div>
               <div className='grid grid-cols-2 max-w-[230px]  mx-auto gap-2 py-4  pt-2 max-h-[75vh] overflow-auto'>
                {ct.statusCards?.map((c,i) => <MiniCard card={c.card} userCard={c.userCard} status={c.status}/>)}

               </div>
               
               <div onClick={() => {
                ct.setShowPop(); 
               
                navigate('/forest')
                // setSelectedDeco({type: "pc", uwp: ct.userWorkspace?.id, n: missingN(packed_seeds)[0] })


            }} className='flex justify-center mt-8  fixed bottom-[80px] font-semibold left-0 right-0'>
                    <div className='bg-white text-[#9300ff] px-4 py-2 text-xl rounded-full border-2 border-b-[6px] border-purple-300'>Planter les graines</div>
                    
                </div>
             

                <div onClick={() => {ct.setShowPop(); ct.setStatusCards()}} className='flex justify-center fixed bottom-8 left-0 right-0'>
                    <div className='text-white/50 px-4 py-2 text-xl rounded-full'>Passer </div>
                </div>
                          
            </div>

          
        </div>
    </div>
  );
};

export {RecapSession};