import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc } from "firebase/firestore";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { db } from "../../config.js";
import { sectionInfos } from './quizzReader.js';
import { centToStars, displayDuration, getDeckData, quizList } from '../../utilities/helper/helper.js';


const formatDecks = (decks) =>  decks?.reduce((acc, deck) => {
  if (deck.parent_id) {

    const parentDeck = decks.find(d => d.id === deck.parent_id);

    if (parentDeck) {
      if (!parentDeck.childs) {
        parentDeck.childs = [];
      }
      const isChildAlreadyAdded = parentDeck.childs.some(child => child.id === deck.id);
      if (!isChildAlreadyAdded) {
        parentDeck.childs.push(deck);
      }
    }
  } else {
    // Ajouter les decks sans parent_id directement à acc
    if (!acc.some(d => d.id === deck.id)) {
      acc.push(deck);
    }
  }
  return acc;
}, []);

const Liane = React.memo((props) => {
     console.log('liane')
    const ct = props.context




    const setPopoverPos = props.setPopoverPos
    const setHoverDeck = props.setHoverDeck
    const setUserCardDeck = props.setUserCardDeck
    const setpackCards = props.setpackCards
    const decks = props.decks


    const formattedDecks = formatDecks(decks)


    

    const filteredCardsWithState = props?.filteredCards?.map((card) => {
      const _user_card = ct.userCards?.find(uc => uc.card_id == card.id)
      const user_card = {..._user_card, 
        next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
        triggers: _user_card?.triggers?.map(t => t.next_date.seconds ? (
            {...t, next_date: new Date(t.next_date.seconds * 1000)}
            ) : t)}
      const state = !user_card?.next_date ? "to_learn" : user_card?.archived ? "archived" : user_card?.next_date > new Date() ? "mastered" : "to_review"
      const indexTrigger = user_card.next_date ? user_card?.next_trigger : 42
      return {...card, user_card, state, indexTrigger}
    })



    console.log('props.filteredCards', props.filteredCards)
    const contextLessCards = useMemo(() => {
      return filteredCardsWithState?.sort((a, b) => a.state === 'archived' && b.state !== 'archived' ? -1 : 1).reduce((acc, card, index) => {        const chunkIndex = Math.floor(index / 4);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = []; // start a new chunk
        }
        acc[chunkIndex].push(card);
        return acc;
      }, []);
    }, [props.filteredCards]);

    
  
  
   const miniTOEIC = ct.tests?.filter(c => c.mini ).sort((a, b) => a.name.localeCompare(b.name))


   const lianeContent = []


   let i = 0
   let firstPack = false
   let hasStackedLesson = false
   let index_deck = 0

   let index_lesson = 0
   let index_side = 0
   let index_quiz = 0
  /// let index_white_quiz = 0
   let index_part = 1
   contextLessCards?.map((pack, index) => {
    if (index%3==1) {
      let indexTOEIC = (quizList.length + (props.index-3))%quizList.length
      let context = formattedDecks?.filter(deck => deck.presentation_mode == "Dialogue")?.[index_deck]
      let quiz = ct.userWorkspace?.goal_id == 'TOEIC' && {id: quizList[indexTOEIC]?.id, random: true, id_train: `training_part_${index_part%8}_n_${props.index}`, part: index_part%8, indexTOEIC: indexTOEIC}
      if (i%6==2) context = formattedDecks?.filter(deck => deck.presentation_mode == "MindCard")?.[index_deck]
      
      if (quiz) {
        lianeContent.push({type: "quiz", part: index_part%8, quiz})
        index_part++;
        index_quiz++;
        context = null
      }

      if (context) {
        lianeContent.push({type: "quiz", context: context})
        index_deck++
      }
     
      lianeContent.push({type: "no-context", pack: pack})
    } else {
      lianeContent.push({type: "no-context", pack: pack})
    }
 }) 

 console.log('next_card !!!! ✨✨✨✨✨', ct.nextCards)

 if(ct.userWorkspace?.goal_id == "perfect") {
  lianeContent.push({type: "no-context", state: "new", pack: null})

}
 for (let pas = 0; pas < props.nbNextLocked; pas++) {
  console.log('next unlocked', props.nbNextLocked)

  lianeContent.push({type: "no-context", pack: null})
 }


 if (ct.userWorkspace.goal_id == 'TOEIC') lianeContent.push({type: "quiz", part: "all", quiz: miniTOEIC[props.index]})




  return <div>
 

<div className="flex flex-col mb-12 gap-2 items-center flex-wrap mx-auto justify-center fredoka mt-20">


    {lianeContent.map((content, index) => {
  
  let quiz = content.quiz
  let context = content.context
  let left = 0
  let right = 0
  let lesson = null
  let whiteQuiz = null
  let top = 0
  let bg
  let deg = -29
  let d3 = '0px, 0px, 0px'
  if(i == 12) i = 0
  if (i == 3 || i == 9) {

   
    lesson = formattedDecks?.filter(deck => deck.presentation_mode == "Grammar")?.[index_lesson]
    
    index_side++
    if (ct.userWorkspace.goal_id == 'TOEIC') {
      whiteQuiz = quizList[props.index]
      if (index_side%4 ==0) {
        lesson = null
        index_quiz++
        
       
      } else { 
        whiteQuiz = null 
        index_lesson++
      }
    } else { index_lesson++}
   
  }

 
   i++

  switch(i-1) {
    case 0: {left = 0; right = 0; top= -15; deg= 19; d3= '9px, 2px, 0px'; break}
    case 1: {left = 100; right = 0; top = -20; deg= 34; d3= '9px, 1px, 0px'; break}
    case 2: {left = 200; right = 0; top = -15; deg= 65; d3= '28px, 3px, 0px'; break}
    case 3: {left = 250; right = 0; top = 0; deg= 300; d3= '-42px, -4px, -5px'; break}
    case 4: {left = 200; right = 0; top = 0; deg=323;d3= '-50px, -3px, -5px'; break}
    case 5: {left = 100; right = 0; top =-15; deg=329; d3= '-30px, -5px, -5px';break}
    case 6: {left = 0; right = 0; top= -15; deg=331;d3= '-30px, -7px, -5px';break}

    case 7: {left = 0; right = 100; top = -20; deg=337; d3= '-33px, -6px, -5px'; break}
    case 8: {left = 0; right = 200; top = -20; deg=291; d3= '-33px, -9px, -5px';  break}
    case 9: {left = 0; right = 250; top = 0; deg=243; d3= '-34px, -12px, -5px';  break}
    case 10: {left = 0; right = 200; top = 0; deg= 219; d3= '-31px, -12px, 0px'; break}
    case 11: {left = 0; right = 100; top = -15; deg= 211;d3= '-33px, -9px, -5px'; break}
    case 12: {left = 0; right = 0; top = -15;break}
  }

  let cards = content.type === "no-context" ? content.pack : null;
  if (context) {
    cards = ct.cards?.filter(c => c.deck_id === context.id || context?.childs?.find(d => d.id === c.deck_id));
  }

  const mapCardData = (card) => {
    const _user_card = ct.userCards?.find(uc => uc.card_id === card.id);
    const user_card = {
      ..._user_card,
      next_date: _user_card?.next_date?.seconds ? new Date(_user_card.next_date.seconds * 1000) : _user_card?.next_date,
      triggers: _user_card?.triggers?.map(t => t.next_date.seconds ? ({ ...t, next_date: new Date(t.next_date.seconds * 1000) }) : t),
    };
    return { ...card, user_card, state: determineState(user_card), indexTrigger: user_card.next_date ? user_card?.next_trigger : 42 };
  };

  const determineState = (user_card) => {
    if (!user_card?.next_date) return "to_learn";
    if (user_card?.archived) return "archived";
    return user_card?.next_date > new Date() ? "mastered" : "to_review";
  };

  const cardsInDecks = cards?.map(mapCardData);
  const cardsInLesson = lesson ? ct.cards?.filter(c => c.deck_id === lesson.id || lesson?.childs?.find(d => d.id === c.deck_id))?.map(mapCardData) : [];

  const noArchivedCards = cardsInDecks?.filter(c => !c.user_card.archived);
  const noArchivedCardsLesson = cardsInLesson?.filter(c => !c.user_card.archived);
  const cardsToDiscover = noArchivedCards?.filter(c => !c.user_card || !c.user_card?.next_date);
  const cardToReview = noArchivedCards?.filter(c => c.user_card?.next_date < new Date());

  const cardToReviewComp = cardToReview?.filter(c => c.user_card?.triggers[0]?.next_date < new Date());
  const cardToReviewExp = cardToReview?.filter(c => c.user_card?.triggers[1]?.next_date < new Date());

  const todo = cardsToDiscover?.length + cardToReview?.length;
  const stacked_card = cardsInDecks?.filter(c => c.user_card.stacked && !c.user_card.unstack).length
  const scoreLesson = noArchivedCardsLesson?.map(c => c.user_card.lvl || 0).reduce((acc, val) => acc + val, 0);

  
const cardToPlace = noArchivedCards?.filter(c => (!c.user_card.x && c.user_card.x != 0) && c.user_card.next_date)
let state = "locked"
switch(todo) {
case 4: bg = "bg-purple-500/20"; break;
case 3: bg = "bg-purple-500/40"; break;
case 2: bg = "bg-purple-500/60"; break;
case 1: bg = "bg-purple-500/90"; break;
case 0: bg = "bg-p-200";  break;
}



if (todo == 0) {
state = "mastered"
}
if (todo > 0) {
firstPack++

if (firstPack == 1 && cardsToDiscover?.length > 0) {
  state = "new"
}
}

if (cardToReview?.length-stacked_card > 0) {state = "review"}

if (stacked_card > 0 && !hasStackedLesson) {
  state = "new"
  hasStackedLesson = true
}
if (stacked_card == 4) {
  state = "stacked"
  hasStackedLesson = true
}

if (content.state == "new" && !hasStackedLesson) {
  state = "new"
}



// console.log('percent', percent)
const percentLesson = scoreLesson/(noArchivedCardsLesson?.length*6)*100



let lvlTree = null
let stateTree = "r"
if (percentLesson > 0) lvlTree = "tree_0"
if (percentLesson > 15) lvlTree = "tree_1" // 1 - half discovered
if (percentLesson > 33) lvlTree = "tree_2" // 2 - 100% discovered
if (percentLesson > 45) lvlTree = "tree_3" // 3 - lvl 3
if (percentLesson > 60) lvlTree = "tree_4" // 4
if (percentLesson == 100) lvlTree = "tree_5" // 5
if (todo == 0) stateTree = "m"
const image_name = "/images/seeds/"+lvlTree+"_"+stateTree+".svg"



// let nextCards = getDeckData(ct, cards)?.nextCards 
let nextCards =   [...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [], ...cardToReviewComp || [], ...cardToReviewExp || []].slice(0,20)
if (ct.workspace?.classic || ct.userWorkspace?.goal_id == "TOEIC") {
nextCards = [...cardsToDiscover?.slice(0,4) || [], ...cardsToDiscover?.slice(0,4) || [],  ...cardToReviewComp || []]
}

const lessonHTML = lesson && <div 
className={`absolute  ${ i == 4 ? "left-0 -top-10" : "-top-10 right-0"} flex flex-col items-center`}>
<div 
onClick={(e) => {
  const posY = e.currentTarget.getBoundingClientRect().top - 20
  const posX = 50
  setPopoverPos({left: posX , top: posY + 70})
  setHoverDeck(lesson)
  setUserCardDeck(lesson.userCards)
}}
className={`w-[56px] h-[56px] rounded-full scale-x-[1.4] ${!lvlTree ? "bg-pink-500/20 border-pink-400" : stateTree == "m" ? "bg-green-700 border-green-300" : "bg-gray-500/20 border-purple-300/10"} border-[2px] border-b-[8px] flex items-center justify-center text-pink-100/60 `}>
  {lvlTree ? <img src={image_name} className="pb-[60px]" style={{transform: "scaleX(1.6) scaleY(2.4) translate3d(2px, -4.6px, 11px)"}}/> :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8"><path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" /></svg>
              }


</div>
<h3 className="fredoka  mt-4 max-w-[140px] leading-5	 text-[14px] text-center text-white/60 ">{lesson.name}</h3>

</div>

const whiteTestHTML = whiteQuiz && <div 
className={`absolute -top-16 ${ i == 4 ? "left-0 -top-10" : "top-2 right-0"} flex flex-col items-center`}>
{/* {circularLesson} */}
<div 
// onClick={() => {setPopoverPos(); ct.setSelectedQuiz({id: whiteQuiz.id, mode: `white`, fullVersion: true});}}

onClick={(e) => {
  console.log('Quizzzzzz', quiz)
  console.log('user_tests', ct.userTests)
  console.log('ct', ct)
  const userTest = props.userTests?.find(ut => ut.test_id == whiteQuiz.id)
  console.log('userTest', userTest)
  setHoverDeck(null)

  const contentPOP =  <div className='border-2 w-full relative text-center fredoka border-b-4 z-20 border-indigo-600 text-white bg-gradient-to-b from-purple-500 to-indigo-500 p-4 rounded-2xl'>
    <div className='font-semibold text-lg mb-2'>TOEIC Blanc {props.index + 1}</div>
    <div className='flex items-center justify-center gap-2'>
      <div className='bg-white/10 rounded-full px-4 py-1 -mt-2 mb-2'>{userTest?.questions?.filter(q => q.valid).length}/{200}</div>
      <div className='bg-white/10 rounded-full px-4 py-1 -mt-2 mb-2'>Score:  {userTest?.toeic_score}</div>

    </div>
   
    <div onClick={() => {
      setPopoverPos(); ct.setSelectedQuiz({id: whiteQuiz.id, mode: `white`, fullVersion: true})
     
      ;}} className='text-purple-500 bg-white  px-4 py-1 border border-b-2  rounded-xl fredoka  text-center'>{questionToReview?.length ? `Commencer` : 'Commencer'}</div>
    
    </div>
  const posY = e.currentTarget.getBoundingClientRect().top - 20
  const posX = 50
  setPopoverPos({content: contentPOP, left: posX , top: posY + 70, cursor: e.currentTarget.getBoundingClientRect().left - 45})
}}
className={`w-[56px] h-[56px] rounded-full scale-x-[1.4] ${!lvlTree ? "bg-pink-500/20 border-pink-400" : stateTree == "m" ? "bg-green-700 border-green-300" : "bg-gray-500/20 border-purple-300/10"} border-[2px] border-b-[8px] flex items-center justify-center text-pink-100/60 `}>

 <img src="images/UX/gold_cup.png" className='h-[80px] mb-12'/>
 <div className='bg-white/20 px-2 text-white rounded-full mt-4 absolute top-6 text-xs'>{ct.userTests?.find(ut => ut.test_id == whiteQuiz.id)?.toeic_score}</div>



</div>
{centToStars(Math.round(ct.userTests?.find(ut => ut.test_id == whiteQuiz.id)?.toeic_score*100/995)/10)}
<h3 className="fredoka  mt-1 max-w-[120px] leading-5	 text-[16px] text-center text-white/60 ">Test blanc</h3>
{/* <div>{ct.userTests?.find(ut => ut.test_id == whiteQuiz.id)?.questions?.length}</div> */}

</div>

const contextHTML = context && <div onClick={(e) => {

const posY = e.currentTarget.getBoundingClientRect().top - 20
const posX = 50
setPopoverPos({left: posX , top: posY + 70})
setHoverDeck(context)
console.log('deck', context)
setUserCardDeck(context.userCards)
}}
style={{marginLeft: left*1, marginRight: right*1, marginTop: top}} 
className={`${state == "mastered" ? "scale-[0.8]" : ""} w-[48px] h-[48px] relative z-10 bg-[#7134ea] rounded-full  border-[#34283f] border-2 border-b-8 flex items-center justify-center text-white/50`}>
    {todo == 0 ? cardToPlace == 0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>
: <img src={"/images/seeds/flower_blue.png"}  className={`scale-[2]`} /> :
  todo < cardsInDecks.length ? <img src={"/images/seeds/bud_blue.png"}  className={`scale-[2] `} style={{transform: 'translate3d(0px, -3px, 0px) scaleX(1.5) scaleY(1.4)'}} /> :

state == "locked" ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
</svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
</svg>

}    

</div>

// const questions = quiz && ct.userTests?.find(ut =>  ut.test_id == quiz.id_train || ut.test_id == quiz.id)?.questions
const questions = quiz && ct.userTests?.find(ut => ut.test_id == quiz.id_train || ut.test_id == quiz.id)?.questions

const questionToReview = questions?.filter(q => q.next_date && q.next_date < new Date())
const questionsCorrect = questions?.filter(q => q.valid)

const totalQuestion = content.part != "all" ? (sectionInfos[quiz?.part - 1]?.nb > 12 ? 12 : sectionInfos[quiz?.part - 1]?.nb) : sectionInfos[quiz?.part - 1]?.nb || 26

const quizzHTML = quiz && <div onClick={(e) => {
console.log('Quizzzzzz', quiz)
setHoverDeck(null)

const contentPOP =  <div className='border-2 w-full relative text-center fredoka border-b-4 z-20 border-indigo-600 text-white bg-gradient-to-b from-purple-500 to-indigo-500 p-4 rounded-2xl'>
  <div className='font-semibold text-lg mb-2'>{content.part == "all" ? "Mini TOEIC" : "Entrainement Partie "+ content.part}</div>
  {/* <div className='flex items-center justify-center'><div className='bg-white/10 rounded-full px-4 py-1 -mt-2 mb-2'>{questionsCorrect?.length || 0}/{totalQuestion}</div></div> */}
  {/* {totalQuestion == questions?.length && questions?.length != questionsCorrect && !questionToReview?.length && <div>Prochaine révision dans {displayDuration(new Date(), questions?.filter(q => !q.valid)[0].next_date)}</div>} */}
 
  {/* {(questionToReview?.length > 0 || questions?.length != totalQuestion) && <div onClick={() => {
    setPopoverPos(); 
    ct.setSelectedQuiz({id: quiz.id, id_train: quiz.id_train, mode: `${content.part}`, fullVersion: content.part == "all", filteredCards: questionToReview})
   
    ;}} className='text-purple-500 bg-white  px-4 py-1 border border-b-2  rounded-xl fredoka  text-center'>{questionToReview?.length ? `${questionToReview?.length} question${questionToReview?.length > 1 ? "s" : ""} à revoir` : 'Commencer'}</div>
  } */}
  {/* nb:{questions?.length} */}
  
  <div onClick={() => { setPopoverPos();  ct.setSelectedQuiz({id: quiz.id, id_train: quiz.id_train, mode: `${content.part}`, fullVersion: content.part == "all", filteredCards: questionToReview});}} className='text-purple-500 bg-white  px-4 py-1 border border-b-2  rounded-xl fredoka  text-center'>{questions?.length ? "Recommencer" : "Commencer"}</div>
  </div>
const posY = e.currentTarget.getBoundingClientRect().top - 20
const posX = 50
setPopoverPos({content: contentPOP, left: posX , top: posY + 70, cursor: e.currentTarget.getBoundingClientRect().left - 45})
}}

style={{marginLeft: left*1, marginRight: right*1, marginTop: top}} 
className={`${state == "mastered" ? "scale-[0.8]" : ""} ${content.part == "all" ? "bg-[#c94eff] text-white scale-[1.3] top-2 right-2" : " bg-[#7134ea] text-white/60"} w-[48px] h-[48px] relative z-10 rounded-full  border-[#34283f] border-2 border-b-8 flex items-center justify-center `}>
 {content.part == "all" ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
  </svg> : <img src="images/UX/dumbel.png" className='rotate-[344deg]' />}

{/* {questions?.length == totalQuestion && <div className='bg-white border-2 rounded-full text-purple-500 h-[20px] w-[20px] -right-1 -bottom-2 absolute'>
  {questionToReview?.length == 0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
<path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>}

</div>} */}
{questionsCorrect && <div className='absolute 9scale-y-[0.55] 9scale-x-[0.5] -bottom-3 -right-[2px] rounded-full flex items-center text-amber-500 px-1 pr-0 text-sm bg-gray-800'>
  {Math.round(questionsCorrect?.length*100/totalQuestion/2)/10}
{/* {centToStars(9)} */}
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 -mr-1 h-5">
  
<path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
</svg>
</div>}
</div>

const path =  <div style={{transform: `rotate(${deg}deg) translate3d(${d3})`,  marginLeft: left, marginRight: right, marginTop: 0}} className={`bg-white/10 opacity-75 top-0 rounded-full z-0 w-[50px] h-[12px] absolute`}></div>


// console.log('context ', context) 
// RENDER DES CARTES CLASSIQUES
if (!quiz && !context) return  <div className={`relative pack_${state} bg-white-300/10 rounded `}>
{lessonHTML}
{whiteTestHTML}

{/* {state != "locked" && circular} */}
{/* {popUpTuto} */}

<div 
  onClick={(e) => {

    console.log('ct uw', ct.userWorkspace)

    // faire fonction personnalisée si il y a une carte stacked mais moins que 4
    if (stacked_card > 0 && stacked_card < 4 || content.state == "new") {
      props?.setShowPreselection && props?.setShowPreselection(true)
      if(ct.userWorkspace?.goal_id == "perfect") return 
    }
    setpackCards(nextCards) 
  
 
    const posY = e.currentTarget.getBoundingClientRect().top - 30
    const posX = 50
    setPopoverPos({left: posX , top: posY + 70, cursor: e.currentTarget.getBoundingClientRect().left - 45})
    ct.setSelectedPack(content.pack)
    console.log('content', content)
  }} 

  style={{marginLeft: left, marginRight: right, marginTop: top}} 
  className={`w-[40px] ${state == "mastered" ? "scale-[0.7]" : ""}  ${state == "new" ? "boundEffetC bg-purple-600 border-[#ffd600] text-[#ffd600]" : state == "stacked" ? "bg-indigo-600 border-[#ffd600] text-[#ffd560]": state == "locked" ? "bg-[#5f2d9a] border-[#34283f] text-white/40" :  "border-none  text-white/40"} z-10 relative h-[40px] rounded-full z-99  border-2 border-b-[6px] flex items-center justify-center `}>

  {/* // className={`w-[40px] ${state == "mastered" ? "scale-[0.7]" : ""}  ${state == "new" ? "boundEffetC bg-purple-600 border-[#ffd600] text-[#ffd600]" : state == "locked" ? "bg-[#5f2d9a] border-[#34283f] text-white/40" : state == "review" ? "bg-blue-500 border-blue-900 boundEffetC" : cardToPlace == 0 ? "bg-[#830eff] border-[#34283f] text-white" : "bg-purple-600 border-[#34283f] text-white/40"} z-10 relative h-[40px] rounded-full z-99  border-2 border-b-[6px] flex items-center justify-center `}> */}
  {todo == 0 ? 
      // cardToPlace == 0 ? <svg id="check" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" /></svg> : <img src={"/images/seeds/flower.svg"}  className={`${index == 0 && !ct.user?.tt_for && todo == 0 ?' heartbeat': 'scale-[1.6]'} `} /> :
      <img src={"/images/seeds/flower.png"}  className={`scale-[1.6] `} /> : 

  // state == "review" ? <svg id="rewind" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  // <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
  // </svg> : 
  state == "review" ? <div className='relative'><img src={"/images/seeds/flower.png"}  className={`grayscale`} /><img className='absolute -top-2 -right-1 h-[20px] w-[20px]' src="/images/seeds/review.png" /></div> : 

  state == "new" ? 
   <svg id="star" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"> <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg> 
  : 
  state == "stacked" ? 
  <svg id="star" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"> <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" /></svg> 
  
   : 
  <svg id="lock" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4  h-4">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
  </svg>}
</div>
{path} 
{/* <div className="absolute"> test {nextCards.length}</div> */}

</div>



if (quiz || context) return <div className="relative  bg-white-300/10 rounded ">

  

{lessonHTML}
{whiteTestHTML}

{/* {circular} */}

{contextHTML}

{quizzHTML}

{path} 

</div>
// LES PACKS DE GRAINES

})}
 

  </div>
</div>
}, (prevProps, nextProps) => {
  // console.log("prevProps.showCards", prevProps.showCards?.length)
  // console.log("nextProps.showCards", nextProps.showCards?.length)
  // console.log("diff", prevProps.showCards === nextProps.showCards )
  // Vérifie si les props clés sont égaux
  return prevProps.showCards?.length === nextProps.showCards?.length
  && prevProps.userTests === nextProps.userTests
  && prevProps.decks?.length === nextProps?.decks?.length
  && prevProps?.workspace?.name === nextProps?.workspace?.name
  && prevProps?.cards?.length === nextProps?.cards?.length
  && prevProps?.nextCards?.length === nextProps?.nextCards?.length
  && prevProps?.filteredCards?.length === nextProps?.filteredCards?.length

  // && prevProps.decks === nextProps.decks 

        //  prevProps.popoverPos === nextProps.popoverPos &&
        //  prevProps.decks === nextProps.decks;
        
       
  // Ajoutez d'autres vérifications de props selon les besoins
});

export {Liane, formatDecks};