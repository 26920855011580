import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../config.js";

const ProgressBar = ({value, value2, total, height}) => {

  const cent = (value / total) * 100
  const cent2 = ((value +value2) / total) * 100

  return (
    <div className="w-full bg-gray-100/50 h-2 rounded-md overflow-hidden relative" style={{height: `${height}px`}}>
        
        {cent ? <div className="border transition-all ease-out duration-500 w-full h-full bg-purple-500 rounded-md absolute" style={{width: `${cent2}%`}}></div> : "" }
        {cent ? <div className="border transition-all ease-out duration-500 w-full h-full bg-green-500 rounded-md absolute" style={{width: `${cent}%`}}></div> : "" }
        {cent ? <div className="border transition-all ease-out duration-500 w-full h-full bg-white  opacity-30 rounded-md absolute" style={{width: `calc(${cent2}% - 10px)`, margin: "2px 4px", height: `25%`}}></div> : ""}
    </div>
  );
};

export {ProgressBar};