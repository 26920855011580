import  React, { createContext, useMemo, useContext, useState, useEffect, useRef} from 'react';
import { collection, addDoc, doc, getDocs, query, updateDoc, getDoc, orderBy, limit, deleteDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions, httpsCallable } from "firebase/functions";

import { db, app } from "../../config.js";
import {fire} from '../🔥db.js'
import { useNavigate } from "react-router-dom";
import { findWord, clean, gpt, numberSentences, splitNumeredSentence, sentenceEndRegex, goodDate } from "../../utilities/helper/text.js";
import SuperText from './perfectScreen.js';

import { quantum, waveform } from 'ldrs'
import { displayDuration } from '../../utilities/helper/helper.js';


const StoryScreen = (props) => {
    const navigate = useNavigate()
    const [lastCall, setLastCall] = useState()
    const menu = props.menu
    const ct = props.ct



    useEffect(() => {
        console.log("StoryScreen loaded")

    }, [])

 

    const books = [
      {name: "Pride and Prejudice", author: "Jane Austen", description: "A classic novel that explores themes of love, social class, and prejudice."},
      {name: "Les Misérables", author: "Victor Hugo", description: "An epic French novel that follows several characters, including Jean Valjean, through the social and political upheavals of early 19th century France."},
      {name: "Moby Dick", author: "Herman Melville", description: "An epic tale of Captain Ahab's obsessive quest to capture the great white whale, Moby Dick."},
      {name: "Alice's Adventures in Wonderland", author: "Lewis Carroll", description: "A fantastical story following Alice as she falls down a rabbit hole and discovers a strange and wondrous world."},
      {name: "Dracula", author: "Bram Stoker", description: "A gothic horror novel telling the story of Count Dracula and his attempt to move from Transylvania to England."},
      {name: "The Adventures of Sherlock Holmes", author: "Arthur Conan Doyle", description: "A collection of short stories featuring the famous detective Sherlock Holmes and his friend, Dr. Watson."},
      {name: "The Picture of Dorian Gray", author: "Oscar Wilde", description: "A philosophical novel about a young man whose portrait ages in his place, allowing him to live a life of debauchery without apparent consequence."},
      {name: "Frankenstein", author: "Mary Shelley", description: "The story of Victor Frankenstein, a scientist who creates a living creature from dead body parts."},
      {name: "Don Quixote", author: "Miguel de Cervantes", description: "A satirical novel about the adventures of the noble but deluded Don Quixote and his faithful squire, Sancho Panza."},
      {name: "Treasure Island", author: "Robert Louis Stevenson", description: "A classic adventure novel about the search for hidden treasure and pirates, seen through the eyes of young Jim Hawkins."}
  ];



    return <div className="px-2 rounded-xl mb-2 mt-8 ">
          {ct.user.admin ? <div>

           <div className='grid grid-cols-1 gap-2 justify-center max-w-[700px]'>
            {books.map(book => <div className='p-2 gap-2 flex  bg-blue-900/40  border-black/50 rounded-xl border-2 border-b-4 '>
              <img className='h-[30px] rounded -ml-1' src={book.img || "/images/UX/i_book.png"} />
              <div>
                <div className='font-semibold text-sm'>{book.name}</div>
                <div className='text-white/80'>{book.author}</div>
              </div>
            </div>)}
            </div>
            
          </div> : <div className={`p-4 rounded-xl mt-4 bg-black/30 mb-2 flex gap-2`}>
               <img src="/images/UX/time_logo.png" className="h-[80px]"/>
            
                <div className="flex-grow">
                  <div className="font-semibold fredoka">Coming soon</div>
                  <div className="text-sm">Cette fonctionnalité est en cours de développement et sera bientôt disponible !</div>
                </div>
              </div>}
      </div>

};

export {StoryScreen};





