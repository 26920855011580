import { getAuth } from "firebase/auth";
import { checkAuth } from "../utilities/auth";
import { useEffect, useRef, useState } from "react";
import { app } from "../config";
import { useStateContext } from "./StateProvider";
import { supabase } from "../V2/screens/testScreen";
import { addNotifications, getDaily, getNextCards, getNextCardsByTheme, getProfile, getProfileWorkspace, getProfileWorkspaces, getProfilesCards, getWorkspaces, sbUpdate, transfertFire, transfertFireUser } from "./DbFunction";
import { HelloWord } from "./HelloWorld";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { BottomBar, TopBar } from "./components/bottomBar";
import { Forest } from "./pages/Forest";
import { Home } from "./pages/Home";
import { League } from "./pages/League";
import { SelectWorkspace } from "./pages/SelectWorkspace";
import { getCardToLearn, toggleVersion } from "./helperFunctions";
import { WordsSelection } from "./components/modal/wordsSelection";
import { LearnCards } from "./components/modal/learnCards";
import { SelectGoal } from "./pages/SelectGoal";
import { Bubulle } from "./components/bubulle";
import { LandingScreen } from "../V2/screens/landingScreen";
import { LoggingScreen } from "./pages/Loggin";
import { ShowCategorieScreen } from "./pages/ShowCategorie";
import { SVG_load } from "./svgs";
import { NotificationScreen } from "./pages/NotificationScreen";
import { Alert } from "./components/Alerts";
import { JoinGroup } from "./components/modal/joinGroup";
import { ProfilePage } from "./pages/Profile";
import { coachs } from "./data/notification";
import ProfilesInGroup from "./pages/AdminGroup";






const App = ({props}) => {

    const auth = getAuth(app);
    const { 
        user, setUser, 
        profile, setProfile,
        workspaces, setWorkspaces,
        setWorkspace, workspace,
        nextCards, setNextCards,
        setDaily, daily,
        learnedCards, setLearnedCards,
        selectedDecks, setSelectedDecks,
        showCategorie, setShowCategorie,
        profileWorkspace, setProfileWorkspace,
        profileWorkspaces, setProfileWorkspaces,
        showModal, setShowModal,
        selectedNewCards, setSelectedNewCards,
        cardsToRefresh, setCardsToRefresh,
        cardsToDiscover, setCardsToDiscover,
        alert, setAlert,
        bubulle, setBubble,
        showBottomBar, showTopBar,
        
     } = useStateContext()
    const [isLoadingUser, setIsLoadingUser] = useState(true);

    const firstRender = useRef(true);
    const [loadingProfileWorkspace, setLoadingProfileWorkspace] = useState(true)
    const now = new Date();

    console.log('app')

    useEffect(() =>{
        if (firstRender.current) {
            firstRender.current = false;
        
            checkAuth(auth, user, setUser, () => null, setIsLoadingUser);
            getWorkspaces(setWorkspaces)
        }
      }, [])
    
    
   const isFirstRender = useRef(true);


    useEffect(() => {

        user?.id && getProfile(user, setProfile);
       
    }, [user?.id])

    


    useEffect(() => {
        if (profile?.goal_id == "perfect") {
            
        }
    }, 
    [profile?.goal_id])

     useEffect(() => {
        console.log('profileWorkspace?.workspace_id loaded', profileWorkspace?.workspace_id)
        profileWorkspace?.workspace_id && setWorkspace(workspaces.find(e => e.id == profileWorkspace?.workspace_id))
        }, [profileWorkspace?.workspace_id])


    const refreshCards = async (profile, profileWorkspace ) => {
        const workspace_id = profileWorkspace?.workspace_id
        if (workspace_id && profile?.id && profileWorkspace?.goal_id ) {
            console.log('refreshCards triggers:', {
                workspaceId: workspace_id,
                goalId: profileWorkspace?.goal_id,
                profileWorkspaceId: profileWorkspace?.workspace_id,
                profileWorkspace: profileWorkspace
            });

            console.log('get cards pour ', workspace?.name)

           
            if (profileWorkspace?.goal_id == "learn") {
                
               const data = await getNextCards(workspace_id, profile.id)
               setNextCards(data)
            }
            if (profileWorkspace?.goal_id == "perfect") {
               await getNextCardsByTheme(workspace_id, profile?.id, profileWorkspace?.decks_ids, setNextCards)
            }
              
              await getProfilesCards(workspace_id, profile.id, setLearnedCards)
        }
    } 


    useEffect(() => {
        refreshCards(profile, profileWorkspace)
    }, [profile?.id,profileWorkspace?.workspace_id, profileWorkspace?.goal_id, profileWorkspace?.id, profileWorkspace?.decks_ids])




    const initProfilWorkspace = async(profile_id) => {
        const _profileWorkspaces = await getProfileWorkspaces(profile_id)
        setProfileWorkspace(_profileWorkspaces[0])
        setProfileWorkspaces(_profileWorkspaces)
        setLoadingProfileWorkspace(false)
    }
 
    useEffect(() => {
        if (profile?.id) {
            initProfilWorkspace(profile?.id)
        }
    }, [profile?.id])


    useEffect(() => {
        if (user?.fcmToken && workspace?.id) {
       
          if (user.coach != null && workspace.id) {
            console.log('useEff #3 notif')
            addNotifications(coachs[user.coach], user, workspace);}
        }
      }, [user?.fcmToken, workspace?.id])

    

    // gérer les notifications

    const activeScreen = ""

    let screenToShow = null
    if (!profile?.last_workspace_id) {screenToShow = "select_workspace"}
    // meme chose pour les coachs 
    // meme chose pour le parcours
    // choix du mode classic ou jeux (expliqué que c'est plus efficace)


    console.log('nextCards', nextCards)
    console.log('now', now)
    console.log('profile', profile)


    useEffect(() => {
        console.log('good ?', profile?.id && !daily)
        console.log('daily', daily)
        profile?.id && !daily && getDaily(profile?.id, new Date(), setDaily)
    }, [profile?.id])



    useEffect(() => {
        const decks_ids = profileWorkspace?.decks_ids || []
        console.log('profile?.goal_id', profile?.goal_id)
        console.log('decks_ids', decks_ids?.length)
        if (profileWorkspace?.goal_id == "perfect" && decks_ids?.length < 1) {
            setShowCategorie(true)
        }
    },
    [profileWorkspace?.goal_id, profileWorkspace?.decks_ids])


    const showAlertNewVersion = user && !profile //&& !user.supa_profile_id (lors de la création de compte la fonction fire est lancé)
    const logged = (user?.id && profile?.id)

    const hasCoach = (profile?.coach === 0 || profile?.coach === 1) 
    const [state, setState] = useState()

    const Modal = () => {

        const [loading, setLoading] = useState()
        return (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black/70 z-[62]">
                 <div className="flex flex-col items-center justify-center space-y-4 p-6 ">
                    <div className="bg-yellow-100 text-slate-900 overflow-hidden rounded-xl">
                    <img src="/images/fun_login.png" className="w-full object-bottom	 object-contain bg-green-300 h-[180px]" />
                    <div className="p-4">
                        <div className=" font-semibold mb-4">
                        Hey {user?.name}, bienvenue sur la nouvelle version de MindSeed
                        </div>
                        <div className=" mb-2">Voici les grandes nouveautés :</div>
                        <div className="pl-2 text-sm">
                            <div className="space-y-2">
                            <div className="">→ Mise à jour du système d'apprentissage</div>
                            <div className="">→ Refonte du Palais Mental</div>
                            <div className="">
                            → Tes élixirs, tes médailles d'XP et ton jardin ont été réinitialisés
                            </div>
                           
                        </div>
                        <div className="mt-2 text-[#7058b7]">
                             Une refonte globale de la plateforme est en cours, reviens régulièrement pour voir les nouveautés
                            </div>
                        </div>
                        <div className="my-4 text-center text-slate-400 italic text-sm">{state}</div>
                        <div className="flex justify-center">
                          
                            {loading ? <div className="flex mt-6 text-purple-900 items-center gap-2">
                                <div>Chargement en cours...</div>
                                <SVG_load size={16} className="!text-purple-900" />
                            </div>: <button onClick={async() => {
                                setLoading(true)
                                await transfertFire(user, setProfile, setState)
                                setLoading(false)
                                }} className="mt-6 flex gap-2 items-center mx-auto px-6 py-2 bg-[#7058b7] border-2 border-b-4 border-black/70 game-btn text-white rounded-xl hover:bg-blue-700 transition-colors">
                            Commencer 
                            </button>}
                        </div>
                        </div>
                    </div>
                </div>
            </div>)
        }

        console.log('loadingProfileWorkspace', loadingProfileWorkspace)
        console.log('profileWorkspace', profileWorkspace)
        console.log('profile', profile)

    return <div className="fredoka min-h-screen" style={{background: "linear-gradient(180deg, #5A3591 0%, #36206C 100%)"}}>
    
        <Router>
            {loadingProfileWorkspace && isLoadingUser && <div className="fixed bg-purple z-[63] top-0 left-0 right-0 bottom-0 flex items-center text-white justify-center">Chargement ...</div>}
            {!loadingProfileWorkspace && !profileWorkspace?.goal_id && <SelectGoal />} 
            {!loadingProfileWorkspace && !profileWorkspace?.workspace_id && <SelectWorkspace />}
            {showCategorie && <ShowCategorieScreen setShowCategorie={setShowCategorie} />}
            {showAlertNewVersion && <Modal />}
            {profile?.id && !hasCoach && <NotificationScreen />}
            {alert && <Alert alert={alert} setAlert={setAlert} />}
            {!user?.id && <LoggingScreen user={user} setUser={setUser} />}
            {profile?.id && !profile?.last_class_id && <JoinGroup />}
          
            
  
            <Bubulle bubulle={bubulle} setBubble={setBubble} />
            <Routes>
                <Route path="/hello" element={<HelloWord />} />
                <Route path="/forest" element={<Forest />} />
                <Route path="/ProfilesInGroup" element={<ProfilesInGroup groupId={119} />} />
                <Route path="/*" element={<Forest />} />
                <Route path="/home" element={<Home />} />
                <Route path="/profil" element={<ProfilePage />} />
                <Route path="/join_group" element={<JoinGroup choice={true} />} />
                <Route path="/league" element={<League />} />
                
            </Routes>
            {showBottomBar && logged && <BottomBar />}
            {showTopBar && logged && <TopBar auth={auth} />}
            {showModal == "learnCards" &&  logged && <LearnCards />}
            

        </Router>

    


         
  
       

       
    </div>
}

export default App;